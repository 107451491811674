import common from "../FormInputCommons.module.scss";
import React from "react";
import clsx from "clsx";
import { v4 } from "uuid";
import { FormInputLabel } from "../FormInputLabel";
import { FormInputSubText } from "../FormInputSubText";
import {
  FormInputCustomStyleProperties,
  FormInputProperties,
} from "../FormInputTypes";

const CustomizablePasswordField = ({
  field,
  form,
  labelText,
  helpText,
  disabled,
  tabIndex,
  placeholder,
  lightColor,
  inputClassName,
  labelClassName,
  validationClassName,
  helpTextClassName,
}: FormInputProperties & FormInputCustomStyleProperties) => {
  const name = field.name;
  const id = field?.name + "-" + v4();
  const meta = form.getFieldMeta(name);
  return (
    <div>
      <FormInputLabel
        labelText={labelText}
        labelClassName={labelClassName}
        id={id}
        lightColor={lightColor}
      />
      <>
        <input
          {...field}
          name={name}
          type={"password"}
          className={clsx(common.input, inputClassName)}
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          tabIndex={tabIndex}
        />
        <FormInputSubText
          meta={meta}
          helpText={helpText}
          helpTextClassName={helpTextClassName}
          validationClassName={validationClassName}
        />
      </>
    </div>
  );
};

export const PasswordField = (props: FormInputProperties) => {
  return <CustomizablePasswordField {...props} />;
};
