import React from "react";
import {
  ColumnConfig,
  GeldanlagenTableConfig,
  VersicherungenTableConfig,
} from "components/user-portal/contract/ContractDataConfig";
import { TextCell } from "components/user-portal/contract/ContractTableCells";

interface ContractTableHeaderProps {
  config: GeldanlagenTableConfig | VersicherungenTableConfig;
}

export const ContractTableHeader: React.FC<ContractTableHeaderProps> = ({
  config,
}) => {
  return (
    <>
      {Object.entries(config).map(
        ([key, columnConfig]: [string, ColumnConfig]) => {
          return (
            <TextCell key={key} {...columnConfig} value={columnConfig.header} />
          );
        }
      )}
    </>
  );
};
