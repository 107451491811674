import React from "react";
import { useUpdateSelf } from "api/useSelf";
import EditProfileDialog from "components/user-portal/user-profile/dialogs/EditProfileDialog";
import EditIdentificationForm from "components/user-portal/user-profile/dialogs/identification/EditIdentificationForm";
import { KundeResponse } from "types/backend/KundeResponse";

interface EditIdentificationDialogProps {
  kunde: KundeResponse;
  open: boolean;
  onClose: () => void;
}

const EditIdentificationDialog: React.FC<EditIdentificationDialogProps> = ({
  kunde,
  open,
  onClose,
}) => {
  const formId = "identificationForm";
  const mutation = useUpdateSelf();

  return (
    <EditProfileDialog
      onClose={onClose}
      open={open}
      formId={formId}
      mutation={mutation}
    >
      <EditIdentificationForm
        formId={formId}
        kunde={kunde}
        mutation={mutation}
      />
    </EditProfileDialog>
  );
};

export default EditIdentificationDialog;
