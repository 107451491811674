import styles from "components/user-portal/contract/edit-dokumente-dialog/EditDokumenteDialog.module.scss";

import React, { FunctionComponent, useState } from "react";
import { Modal } from "react-responsive-modal";
import { useAddDokumente, useDeleteDokument } from "api/useVertraege";
import { Button } from "components/form-inputs/Button/Button";
import { EditDokumenteEntry } from "components/user-portal/contract/edit-dokumente-dialog/EditDokumenteDokumentEntry";
import { EditDokumenteResult } from "components/user-portal/contract/edit-dokumente-dialog/EditDokumenteResult";
import { STRINGS } from "language/de_DE/strings";
import { DeleteResult } from "types/backend/DeleteResult";
import { Dokument } from "types/backend/Dokument";
import { Field, Form, Formik } from "formik";
import { FileDrop } from "components/form-inputs/FileDrop/FileDrop";

interface AddedDokumente {
  files: File[];
}

interface EditDokumenteDialogProps {
  open: boolean;
  onClose: () => void;
  dokumente: Dokument[];
  kid: number;
  vertragId: number;
}

export const EditDokumenteDialog: FunctionComponent<
  EditDokumenteDialogProps
> = ({ open, onClose, dokumente, kid, vertragId }) => {
  const [dokumentsToDelete, setDokumentsToDelete] = useState<Dokument[]>([]);
  const [deleteResult, setDeleteResult] = useState<DeleteResult[]>([]);
  const addDokumentMutation = useAddDokumente();

  const onDeleteFinished = (result: DeleteResult) => {
    setDeleteResult((prevState) => [...prevState, { ...result }]);
  };
  const deleteDokumentMutation = useDeleteDokument(onDeleteFinished);

  const handleClose = () => {
    onClose();
  };

  const resetDialog = () => {
    addDokumentMutation.reset();
    deleteDokumentMutation.reset();
    setDeleteResult([]);
    setDokumentsToDelete([]);
  };

  const handleSave = async (addedDokumente: AddedDokumente) => {
    if (addedDokumente.files.length > 0) {
      addDokumentMutation.mutate({
        kid: kid,
        vertragId: vertragId,
        files: addedDokumente.files,
      });
    }
    dokumentsToDelete.map((dokumentToDelete) => {
      return deleteDokumentMutation.mutate({
        kid: kid,
        vertragId: vertragId,
        dokument: dokumentToDelete,
      });
    });
  };

  const onMarkDokumentForDelete = (dokument: Dokument) => {
    setDokumentsToDelete((prevArray: Dokument[]) => [...prevArray, dokument]);
  };

  const isUpdateResultAvailable = (): boolean => {
    return (
      addDokumentMutation.isError ||
      addDokumentMutation.isSuccess ||
      (deleteResult.length > 0 && !deleteDokumentMutation.isLoading)
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      center
      onAnimationEnd={resetDialog}
    >
      <h2>{STRINGS.vertrag.documents.dialog.header}</h2>
      {!isUpdateResultAvailable() && (
        <>
          {dokumente.map(
            (dokument) =>
              !dokumentsToDelete.includes(dokument) && (
                <EditDokumenteEntry
                  key={dokument.id}
                  dokument={dokument}
                  kid={kid}
                  vertragId={vertragId}
                  onMarkForDelete={onMarkDokumentForDelete}
                />
              )
          )}
          <Formik
            initialValues={{ files: [] }}
            onSubmit={handleSave}
            enableReinitialize={true}
          >
            {() => {
              return (
                <Form>
                  <input className={styles.hidden} />
                  <div>
                    <Field component={FileDrop} name="files" />
                  </div>
                  <div className={styles.buttonContainer}>
                    <Button
                      label={STRINGS.vertrag.documents.dialog.abort}
                      primary={false}
                      onClick={handleClose}
                    />
                    <Button
                      label={STRINGS.vertrag.documents.dialog.save}
                      type="submit"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
      {isUpdateResultAvailable() && (
        <>
          <EditDokumenteResult
            deleteResult={deleteResult}
            addDokumentMutation={addDokumentMutation}
            deleteDokumentMutation={deleteDokumentMutation}
          />
          <div className={styles.buttonContainer}>
            <Button
              label={STRINGS.vertrag.documents.dialog.ok}
              onClick={handleClose}
            />
          </div>
        </>
      )}
    </Modal>
  );
};
