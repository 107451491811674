import styles from "components/user-portal/user-profile/UserProfileItem.module.scss";

import React from "react";

interface UserProfileMultiItemProps {
  label: string;
  values: string[];
}

const UserProfileMultiItem: React.FC<UserProfileMultiItemProps> = ({
  label,
  values,
}) => {
  return (
    <div className={styles.userProfileItem}>
      <div className={styles.label}>{label}:</div>
      <div>
        {values.map((value) => {
          return (
            <div key={value} className={styles.value}>
              {value || "-"}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserProfileMultiItem;
