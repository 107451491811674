import React, { ReactElement, ReactNode, useEffect, useState } from "react";

const getMillisecondsTick = () => new Date().getTime();

interface FlashMessageProps {
  children: ReactNode;
  onHide?: () => void;
  duration?: number;
  persistOnHover?: boolean;
}

const FlashMessage = ({
  children,
  onHide,
  duration = 5000,
  persistOnHover = true,
}: FlashMessageProps): ReactElement | null => {
  const [isVisible, setIsVisible] = useState(true);
  let timerId: number | null = null;
  let remainingTime = duration;
  let timeStarted = getMillisecondsTick();

  useEffect(() => {
    resumeTimer();

    return () => {
      if (timerId) {
        window.clearTimeout(timerId);
      }

      onHide?.();
    };
  }, []);

  const resumeTimer = () => {
    if (timerId) {
      window.clearTimeout(timerId);
    }

    timeStarted = getMillisecondsTick();
    timerId = window.setTimeout(() => setIsVisible(false), remainingTime);
  };

  const pauseTimer = () => {
    if (!persistOnHover || !timerId) {
      return;
    }

    clearTimeout(timerId);
    remainingTime -= getMillisecondsTick() - timeStarted;
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div onMouseEnter={pauseTimer} onMouseLeave={resumeTimer}>
      {children}
    </div>
  );
};

export default FlashMessage;
