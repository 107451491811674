import styles from "components/molecules/InsuranceComparisonCustomerInfo.module.scss";

import React, { FunctionComponent } from "react";
import moment from "moment";
import { Box } from "components/atoms/Box";
import { Button } from "components/form-inputs/Button/Button";
import { Text } from "components/atoms/Text";
import { STRINGS } from "language/de_DE/strings";

export type InsuranceComparisonCustomerInfoProperties = {
  comparisonInfo: {
    birthday: Date;
  };
};

export const InsuranceComparisonCustomerInfo: FunctionComponent<
  InsuranceComparisonCustomerInfoProperties
> = ({ comparisonInfo }) => {
  return (
    <Box flex inline className={styles.infoBox}>
      <Box flex column>
        <Box flex inline className={styles.infoRow}>
          <Text inline size={"small"}>
            {
              STRINGS.insuranceComparison
                .insuranceComparisonCustomerInfoBirthDayLabel
            }
          </Text>
          <Text inline size={"small"}>
            {moment(comparisonInfo.birthday).format("DD.MM.YYYY")}
          </Text>
        </Box>
      </Box>
      <Button
        label={
          STRINGS.insuranceComparison.insuranceComparisonCustomerInfoButtonLabel
        }
        primary={false}
        className={styles.button}
      />
    </Box>
  );
};
