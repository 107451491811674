import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { KUNDE_SELF_KEY, useSelf } from "api/useSelf";
import useAsyncEffect from "hooks/useAsyncEffect";
import useAwsHubEvent from "hooks/useAwsHubEvent";
import useUserLogin from "hooks/useUserLogin";
import { KundeResponse } from "types/backend/KundeResponse";
import { isRegistrationDataValid } from "../utils/userRegistrationUtil";

export type UserStateContextType = {
  isLoggedIn: boolean | null;
  kunde: KundeResponse | undefined;
  isLoadingKunde: boolean;
  isLoadingKundeSuccess: boolean;
  isLoadingKundeError: boolean;
  reloadUser: () => Promise<void>;
  isRequiredKundenDataMissing: boolean;
};

const UserStateContext = createContext<UserStateContextType>({
  isLoggedIn: null,
  kunde: undefined,
  isLoadingKunde: false,
  isLoadingKundeSuccess: false,
  isLoadingKundeError: false,
  reloadUser: async () => {},
  isRequiredKundenDataMissing: false,
});

type Props = {
  children: ReactNode;
};

const UserStateContextProvider = ({ children }: Props): ReactElement => {
  const queryClient = useQueryClient();
  const { isLoggedIn } = useUserLogin();
  const { onUserLoggedOut, onUserLoggedIn } = useAwsHubEvent();
  const [isLoggedInState, setIsLoggedInState] = useState<boolean | null>(null);
  const [kunde, setKunde] = useState<KundeResponse | undefined>();
  const {
    data: kundeData,
    refetch,
    isLoading: isLoadingKunde,
    isSuccess: isLoadingKundeSuccess,
    isError: isLoadingKundeError,
  } = useSelf(Boolean(isLoggedInState));
  const [isRequiredKundenDataMissing, setRequiredKundenDataMissing] =
    useState(false);

  const reloadUser = useCallback(async () => {
    const { data: refetchedKunde } = await refetch();
    setKunde(refetchedKunde);
  }, [refetch]);

  // Fetch login state from cognito and store it in local state
  useAsyncEffect(async () => {
    // Bind to user login/logout and change local state
    onUserLoggedIn(() => setIsLoggedInState(true));
    onUserLoggedOut(() => {
      setIsLoggedInState(false);
      setKunde(undefined);
      setRequiredKundenDataMissing(false);
      queryClient.removeQueries(KUNDE_SELF_KEY);
    });
    // Get current state once
    const currentLoginState = await isLoggedIn();
    if (currentLoginState !== isLoggedInState) {
      setIsLoggedInState(currentLoginState);
    }
  });

  useEffect(() => {
    setKunde(kundeData);
    setRequiredKundenDataMissing(!isRegistrationDataValid(kundeData));
  }, [kundeData]);

  useEffect(() => {
    if (isLoggedInState) {
      reloadUser();
    }
  }, [isLoggedInState, reloadUser]);

  return (
    <UserStateContext.Provider
      value={{
        isLoggedIn: isLoggedInState,
        kunde,
        isLoadingKunde,
        isLoadingKundeSuccess,
        isLoadingKundeError,
        reloadUser,
        isRequiredKundenDataMissing,
      }}
    >
      {children}
    </UserStateContext.Provider>
  );
};

const useUserState = (): UserStateContextType => {
  return useContext(UserStateContext);
};

export default useUserState;

export { UserStateContext, UserStateContextProvider };
