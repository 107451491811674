import styles from "components/organisms/AuthenticationCodeForm.module.scss";

import React, {
  ReactElement,
  SyntheticEvent,
  useContext,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";
import FlashMessage from "components/flash-message/FlashMessage";
import SmallHint from "components/form/SmallHint";
import LoadingOverlay from "components/loading-overlay/LoadingOverlay";
import AuthenticationCodeInput from "components/molecules/AuthenticationCodeInput";
import AuthenticationCodeFormContext from "components/step-wizard/components/AuthenticationCodeFormContext";
import StepContainer from "components/step-wizard/components/StepContainer";
import useUserLogin from "hooks/useUserLogin";
import useUserRegistration from "hooks/useUserRegistration";
import useUserUpdate from "hooks/useUserUpdate";
import { STRINGS } from "language/de_DE/strings";
import redirectAfterLogin from "utils/redirectAfterLogin";

type AuthenticationCodeFormProperties = {
  isActive: boolean;
  previousStep?: () => void;
  nextStep?: () => void;
  checkAdditionalData?: boolean;
};

const AuthenticationCodeForm = ({
  isActive,
  nextStep,
  checkAdditionalData,
}: AuthenticationCodeFormProperties): ReactElement => {
  const navigate = useNavigate();
  // This is definitely set so non-null-assertion is okay here
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { email, password } = useContext(AuthenticationCodeFormContext)!;
  const [authError, setAuthError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showResendMessage, setShowResendMessage] = useState(false);
  const { confirmRegistration } = useUserRegistration();
  const { resendConfirmationCode } = useUserUpdate();
  const { loginUser, rememberUser } = useUserLogin();
  const amountDigits = 6;

  const determineAuthenticationErrorMessage = (errorCode: string | null) => {
    if (errorCode === "CodeMismatchException") {
      return STRINGS.account.authenticationCode.codeMismatch;
    }
    return STRINGS.account.authenticationCode.genericCodeError;
  };

  const handleAuthenticationCodeChange = async (code: string) => {
    if (code.length !== amountDigits) {
      return;
    }

    setAuthError("");
    setIsSubmitting(true);

    const result = await confirmRegistration(email, code);
    if (!result.ok) {
      setAuthError(determineAuthenticationErrorMessage(result.code));
      setIsSubmitting(false);
      return;
    }

    const loginResult = await loginUser(email, password);
    if (!loginResult.ok) {
      setIsSubmitting(false);
      return;
    }

    const { phone_number } = loginResult.data.attributes;
    if (checkAdditionalData && !phone_number) {
      nextStep?.();
      return;
    }

    await rememberUser();

    if (!checkAdditionalData && nextStep) {
      nextStep();
      return;
    }
    redirectAfterLogin(navigate);
  };

  const handleCodeResend = async (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const result = await resendConfirmationCode(email);
    if (!result.ok) {
      setAuthError(result.message);
      return;
    }

    setShowResendMessage(true);
  };

  return (
    <StepContainer>
      <Text size={"large"} inline className={styles.introductionText}>
        {STRINGS.account.authenticationCode.introduction}
      </Text>

      <LoadingOverlay isVisible={isSubmitting} />

      <Box flex column className={styles.content}>
        <Text size={"xLarge"} inline className={styles.headline}>
          {STRINGS.account.authenticationCode.title}
        </Text>

        {authError !== null && <div className={styles.error}>{authError}</div>}

        {showResendMessage && (
          <FlashMessage onHide={() => setShowResendMessage(false)}>
            <div className={styles.resendCodeHint}>
              {STRINGS.account.authenticationCode.resendCodeHint}
            </div>
          </FlashMessage>
        )}

        <div className={styles.authenticationCodeInputContainer}>
          <AuthenticationCodeInput
            amountDigits={amountDigits}
            onAuthenticationCodeChange={handleAuthenticationCodeChange}
            focusFirstInput={isActive}
          />
        </div>

        <SmallHint>
          {STRINGS.account.authenticationCode.resendCodeQuestion} <br />
          <a onClick={handleCodeResend}>
            {STRINGS.account.authenticationCode.resendCodeAction}
          </a>
        </SmallHint>
      </Box>
    </StepContainer>
  );
};

export default AuthenticationCodeForm;
