import { DependencyList, useEffect } from "react";

const useAsyncEffect = async (
  effect: () => Promise<void | boolean>,
  deps: DependencyList = []
): Promise<void> => {
  useEffect(() => {
    (async () => {
      await effect();
    })();
  }, deps);
};

export default useAsyncEffect;
