import React, { FunctionComponent, ReactNode } from "react";
import { STRINGS } from "../../../../language/de_DE/strings";
import { InsuranceBoxes } from "../InsuranceBoxes";

const sectionStrings = STRINGS.insurancePage.insuranceBenefitBoxes;

type InsuranceBenefitBoxesProps = {
  insuranceName: string;
  children: ReactNode;
};

export const InsuranceBenefitBoxes: FunctionComponent<
  InsuranceBenefitBoxesProps
> = ({ children }) => (
  <InsuranceBoxes
    title={sectionStrings.title}
    titlePart1={sectionStrings.titlePart1}
    titlePart2={sectionStrings.titlePart2}
    titlePart3={sectionStrings.titlePart3}
  >
    {children}
  </InsuranceBoxes>
);
