import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import DescriptionImage0 from "gfx/images/insurance-images/bauherrenhaftpflicht_0.jpg";
import DescriptionImage1 from "gfx/images/insurance-images/bauherrenhaftpflicht_1.jpg";
import DescriptionImage2 from "gfx/images/insurance-images/bauherrenhaftpflicht_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Bau­herren­haft­pflicht";

export const bauherrenhaftpflichtProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: DescriptionImage0,
  introduction:
    "Die Bau­herren­haft­pflicht springt für dich als Hausbauer ein, wenn dritte Personen oder deren Besitz durch dein Bauvorhaben oder auf deiner Baustelle zu Schaden kommen.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title:
        "... ein Passant von umstürzenden/herabfallenden Teilen deines Bauprojektes verletzt wird.",
      text: icon1,
    },
    {
      title: "... das Nachbarhaus beschädigt wird.",
      text: icon1,
    },
    {
      title: "... ein Auto zertrümmert wird.",
      text: icon1,
    },
    {
      title: "... ein Kind in eine Baugrube deiner Bauunternehmung fällt.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine eine " + productName + "?",
      paragraphs: [
        "Die Bauherrenhaftpflichtversicherung bietet dir Schutz gegen das Risiko von Haftungsansprüchen Dritter – vom Baubeginn bis zur Abnahme.",
        "Sie ist in jedem Fall wichtig, auch wenn du Architekten, Bauunternehmer oder Bauhandwerker mit dem Bau beauftragst.",
        "Denn bereits vom ersten Spatenstich an haftest du als Bauherr für Schäden, die andere Personen im Zusammenhang mit dem Bau erleiden.",
      ],
      image: DescriptionImage1,
    },

    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Als Bauherr musst du dich persönlich um die Baustelle kümmern und davon ausgehende Gefahren vermeiden oder beseitigen.",
        "Du haftest daher mit deinem Privatvermögen in unbegrenzter Höhe für alle Schäden, die Dritten in Zusammenhang mit deinem Bauvorhaben entstehen können.",
        "Daher ist dir eine Bauherrenhaftpflichtversicherung zu empfehlen, sobald du selbst zum Bauherrn wirst.",
        "Dies beinhaltet sowohl den Neubau als auch größere Umbaumaßnahmen und Sanierungen an bestehenden Gebäuden.",
      ],
      image: DescriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Personen-, Sach- und Vermögensschäden gegenüber Dritten im Rahmen der Bautätigkeiten ",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Wenn einem Arbeiter auf der Baustelle Schaden zugefügt wird – hierbei handelt es sich um einen Arbeitsunfall und die Unfallversicherung springt ein.",
        "Wenn du dein Haus ohne die Hilfe von Fachunternehmen selbst planst und baust.",
        "Wenn Schäden am Rohbau entstehen - hierfür gibt es separate Bau­leist­ungs­- und Feu­er­roh­bau­ver­si­che­rungen.",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  checklist: [
    {
      title: "Abwehr unberechtigter Forderungen:",
      text: "Die Versicherungen prüfen, inwiefern für die Schadenersatzforderungen ein berechtigter Anspruch durch die Betroffenen besteht und wehren unberechtigte oder zu hohe Forderungen kostenlos für dich ab. Du erhältst daher mit Abschluss einer Bauherrenhaftpflichtversicherung auch einen passiven Rechtsschutz.",
    },
  ],

  link: RouteUrls.bauherrenHaftpflicht,
};
