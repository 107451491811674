import styles from "components/user-portal/user-add-contract/AddContractWizardStepProgress.module.scss";
import React, { ReactElement } from "react";
import clsx from "clsx";

interface AddContractWizardStepProgressProps {
  numberOfSteps: number;
  activeStep: number;
}

export const AddContractWizardStepProgress = ({
  numberOfSteps,
  activeStep,
}: AddContractWizardStepProgressProps): ReactElement => {
  return (
    <div className={styles.stepProgress}>
      <div className={styles.progressBar} />
      <ul className={styles.progressNum}>
        {[...Array(numberOfSteps)].map((e, index) => (
          <li
            key={index}
            className={clsx(
              styles.step,
              activeStep - 1 === index ? styles.active : ""
            )}
          >
            {index + 1}
          </li>
        ))}
      </ul>
    </div>
  );
};
