import React, { Dispatch, FunctionComponent, ReactNode, useState } from "react";
import "@brainhubeu/react-carousel/lib/style.css";
import Carousel, {
  centeredPlugin,
  slidesToShowPlugin,
} from "@brainhubeu/react-carousel";
import styles from "./MyNormCarousel.module.scss";
import clsx from "clsx";
import useMatchMedia from "hooks/useMatchMedia";
import appBreakpoints from "config/pageBreakpoints";
import teaserVergleichen from "gfx/images/startpage/teaser_vergleichen.png";
import teaserInformieren from "gfx/images/startpage/teaser_informieren.png";
import teaserVerwalten from "gfx/images/startpage/teaser_verwalten.png";
import teaserAbschliessen from "gfx/images/startpage/teaser_abschliessen.png";
import { STRINGS } from "../../../../../language/de_DE/strings";
import PageSection from "../../../../page-section/PageSection";

const sectionStrings = STRINGS.startpage.carousel;

type CarouselSubsectionProps = {
  image: string;
  headline: string;
  children: ReactNode;
};

const CarouselSubsection: FunctionComponent<CarouselSubsectionProps> = ({
  image,
  children,
  headline,
}) => (
  <div className={styles.itemWrapper}>
    <div className={styles.itemImage}>
      <img src={image} alt={""} />
    </div>
    <div className={styles.itemText}>
      <h2>{headline}</h2>
      <div>{children}</div>
    </div>
  </div>
);

const InformierenSubsection: FunctionComponent = () => (
  <CarouselSubsection
    image={teaserInformieren}
    headline={sectionStrings.pages.information.headline}
  >
    {sectionStrings.pages.information.prose}
  </CarouselSubsection>
);

const VergleichenSubsection: FunctionComponent = () => (
  <CarouselSubsection
    image={teaserVergleichen}
    headline={sectionStrings.pages.comparison.headline}
  >
    {sectionStrings.pages.comparison.prose}
  </CarouselSubsection>
);
const VerwaltenSubsection: FunctionComponent = () => (
  <CarouselSubsection
    image={teaserVerwalten}
    headline={sectionStrings.pages.administer.headline}
  >
    {sectionStrings.pages.administer.prose}
  </CarouselSubsection>
);
const AbschliessenSubsection: FunctionComponent = () => (
  <CarouselSubsection
    image={teaserAbschliessen}
    headline={sectionStrings.pages.complete.headline}
  >
    {sectionStrings.pages.complete.prose}
  </CarouselSubsection>
);

const possibleCarouselPages = [
  {
    title: sectionStrings.pages.information.title,
    component: InformierenSubsection,
  },
  {
    title: sectionStrings.pages.comparison.title,
    component: VergleichenSubsection,
  },
  {
    title: sectionStrings.pages.administer.title,
    component: VerwaltenSubsection,
  },
  {
    title: sectionStrings.pages.complete.title,
    component: AbschliessenSubsection,
  },
];

type CarouselNavigationProps = {
  pageNumber: number;
  setPageNumber: Dispatch<number>;
};

const CarouselNavigation: FunctionComponent<CarouselNavigationProps> = ({
  pageNumber,
  setPageNumber,
}) => {
  const getStaticPageNumberSetter = (page: number) => () => setPageNumber(page);
  const isMobile = useMatchMedia(`(max-width: ${appBreakpoints.md})`);

  return (
    <div className={styles.navigation}>
      {possibleCarouselPages.map((page, index) => (
        <button
          onClick={getStaticPageNumberSetter(index)}
          key={page.title + "-button"}
          className={clsx(
            styles.navigationItem,
            pageNumber % possibleCarouselPages.length === index
              ? styles.active
              : styles.inactive
          )}
        >
          {isMobile && <>{index + 1}</>}
          {!isMobile && <>{page.title}</>}
        </button>
      ))}
    </div>
  );
};

export const MyNormCarousel: FunctionComponent = () => {
  const [pageNumber, setPageNumber] = useState(0);

  return (
    <PageSection contentClassName={styles.section}>
      <h1>
        {sectionStrings.titlePart1}
        <span className={styles.highlighted}>{sectionStrings.titlePart2}</span>
      </h1>
      <CarouselNavigation
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
      <Carousel
        value={pageNumber}
        onChange={setPageNumber}
        slides={possibleCarouselPages.map((page) => (
          <page.component key={page.title} />
        ))}
        plugins={[
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 1,
            },
          },
          {
            resolve: centeredPlugin,
          },
        ]}
      />
    </PageSection>
  );
};
