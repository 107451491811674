import React, { ReactElement } from "react";
import { createOptions } from "../../../../../utils/formInputUtils";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { AddContractSelection } from "./base/AddContractSelection";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { VertragTyp } from "../../../../../types/backend/VertragTyp";
import { shouldDisplayField } from "./util/displayInputUtil";

const options = createOptions(STRINGS.vertrag.visualAidTypes);

const requiredTypes: VertragTyp[] = ["KRANKENZUSATZVERSICHERUNG"];

export const SehhilfeSelection = ({
  vertragTyp,
}: AddContractInputBaseType): ReactElement | null => {
  if (!shouldDisplayField(vertragTyp, requiredTypes)) return null;
  return (
    <AddContractSelection
      name="ambulantSehhilfe"
      options={options}
      labelText={STRINGS.addContract.step2.ambulantVisualAidLabel}
    />
  );
};
