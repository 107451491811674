import styles from "pages/hausratversicherung/HausratversicherungVergleichAbschlussStep3.module.scss";

import React, { ReactElement } from "react";
import Page from "components/page/Page";
import { HausratversicherungVergleichAbschlussStep3Template } from "components/templates/HausratversicherungVergleichAbschlussStep3Template";
import { selectedInsuranceMock } from "mockData/insuranceBadgeDataMock";

export const HausratversicherungVergleichAbschlussStep3 = (): ReactElement => {
  return (
    <Page contentClassName={styles.pageWrapper}>
      <HausratversicherungVergleichAbschlussStep3Template
        selectedInsurance={selectedInsuranceMock}
      />
    </Page>
  );
};
