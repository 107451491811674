import React, { ReactElement } from "react";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { useVertragMapping } from "./useVertragMapping";
import { shouldDisplayField } from "./util/displayInputUtil";
import { AddContractNumberInput } from "./base/AddContractNumberInput";

export const ZielsparsummeInput = ({
  vertragTyp,
}: AddContractInputBaseType): ReactElement | null => {
  const { sparenTypes } = useVertragMapping();
  const displayedTypes = sparenTypes.filter((t) => t !== "BAUSPARVERTRAG");

  if (!shouldDisplayField(vertragTyp, displayedTypes)) {
    return null;
  }
  return (
    <AddContractNumberInput
      name="zielsparsumme"
      labelText={STRINGS.addContract.step2.targetValueLabel}
    />
  );
};
