import React from "react";
import UserPortal from "components/user-portal/UserPortal";
import { UserMessageBoxContent } from "./UserMessageBoxContent";

const UserMessageBox: React.FC = () => {
  return (
    <UserPortal>
      <UserMessageBoxContent />
    </UserPortal>
  );
};

export default UserMessageBox;
