import styles from "components/user-portal/user-add-contract/UserAddContractWorkflow.module.scss";

import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useCreateVertrag } from "api/useVertraege";
import { RouteUrls } from "config/routes";
import useUserState from "hooks/useUserState";
import PortalContentHeader from "../portal-content/PortalContentHeader";
import { STRINGS } from "../../../language/de_DE/strings";
import StepWizard from "react-step-wizard";
import { WizardStep } from "../../step-wizard/components/WizardStep";
import { Button } from "components/form-inputs/Button/Button";
import { Form, Formik, FormikProps, setNestedObjectValues } from "formik";
import { AddContractWizardStepProgress } from "./AddContractWizardStepProgress";
import { AddContractFormStep1 } from "./form-sections/AddContractFormStep1";
import { AddContractFormStep2 } from "./form-sections/AddContractFormStep2";
import {
  AddContractFormType,
  createEmptyAddContractFormValues,
} from "./form-sections/AddContractFormType";
import { PopUpMessage } from "../../molecules/PopUpMessage";
import { createVertragRequest } from "../../../api/requests/createVertrag";
import { addContractStep1ValidationSchema } from "./form-sections/addContractStep1ValidationSchema";

export const UserAddContractWorkflow = (): ReactElement => {
  const navigate = useNavigate();
  const { kunde } = useUserState();
  const [isPopUpOpen, setIsPopUpOpen] = useState(true);
  const { mutate: createVertrag, isSuccess, isError } = useCreateVertrag();

  const [activeStep, setActiveStep] = useState<number>(1);
  useEffect(() => {
    setIsPopUpOpen(true);
  }, [isSuccess, isError]);

  const handleClosePopUp = () => {
    setIsPopUpOpen(false);
    if (isSuccess) navigate(RouteUrls.userPortalInsurance);
  };

  const initialValues = createEmptyAddContractFormValues();

  const handleSubmit = (formValues: AddContractFormType) => {
    const request = createVertragRequest(formValues);
    createVertrag({ kid: kunde?.kid, ...request });
  };

  const determineValidation = () => {
    if (activeStep === 1) return addContractStep1ValidationSchema;
    return undefined;
  };

  return (
    <>
      <PortalContentHeader title={STRINGS.addContract.addContractHeader} />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={determineValidation()}
        enableReinitialize={true}
      >
        {(formProps) => {
          return (
            <Form>
              <StepWizard
                isHashEnabled={false}
                transitions={{}}
                onStepChange={(stepChange) =>
                  setActiveStep(stepChange.activeStep)
                }
              >
                <WizardStep>
                  {({ nextStep, currentStep }) => (
                    <AddContractContainer
                      nextStep={nextStep}
                      currentStep={currentStep}
                      title={STRINGS.addContract.step1.title}
                      formProps={formProps}
                    >
                      <AddContractFormStep1 formProps={formProps} />
                    </AddContractContainer>
                  )}
                </WizardStep>
                <WizardStep>
                  {({ nextStep, previousStep, currentStep }) => (
                    <AddContractContainer
                      nextStep={nextStep}
                      currentStep={currentStep}
                      previousStep={previousStep}
                      title={STRINGS.addContract.step2.title}
                      formProps={formProps}
                    >
                      <AddContractFormStep2
                        formProps={formProps}
                        activeStep={activeStep}
                      />
                    </AddContractContainer>
                  )}
                </WizardStep>

                <WizardStep>
                  {({ previousStep, currentStep }) => (
                    <AddContractContainer
                      currentStep={currentStep}
                      previousStep={previousStep}
                      formProps={formProps}
                    />
                  )}
                </WizardStep>
              </StepWizard>
            </Form>
          );
        }}
      </Formik>
      <PopUpMessage
        open={isPopUpOpen && isSuccess}
        onClose={handleClosePopUp}
        title={STRINGS.addContract.form.success.title}
        message={STRINGS.addContract.form.success.message}
      />
      <PopUpMessage
        open={isPopUpOpen && isError}
        onClose={() => setIsPopUpOpen(false)}
        title={STRINGS.addContract.form.error.title}
        message={STRINGS.addContract.form.error.message}
      />
    </>
  );
};

interface AddContractContainerProps {
  title?: string;
  nextStep?: () => void;
  previousStep?: () => void;
  currentStep?: number;
  formProps: FormikProps<AddContractFormType>;
}

const AddContractContainer = ({
  title,
  nextStep,
  previousStep,
  currentStep,
  formProps,
  children,
}: PropsWithChildren<AddContractContainerProps>): ReactElement => {
  const numberOfSteps = 2;

  const onContinue = async () => {
    const validationErrors = await formProps.validateForm();
    if (Object.keys(validationErrors).length > 0) {
      formProps.setTouched(setNestedObjectValues(validationErrors, true));
      return;
    }
    nextStep?.();
  };

  const onBack = () => {
    const step1Fields: Partial<AddContractFormType> = (({
      typ,
      customTyp,
      institut,
      customInstitut,
      produktTyp,
    }: Partial<AddContractFormType>) => ({
      typ,
      customTyp,
      institut,
      customInstitut,
      produktTyp,
    }))(formProps.values);
    formProps.resetForm();
    formProps.setFieldValue("typ", step1Fields.typ);
    formProps.setFieldValue("customTyp", step1Fields.customTyp);
    formProps.setFieldValue("institut", step1Fields.institut);
    formProps.setFieldValue("customInstitut", step1Fields.customInstitut);
    formProps.setFieldValue("produktTyp", step1Fields.produktTyp);
    previousStep?.();
  };

  return (
    <div className={styles.addContractContainer}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.wizardProgress}>
          <AddContractWizardStepProgress
            numberOfSteps={numberOfSteps}
            activeStep={currentStep || 0}
          />
        </div>
      </div>
      <div>{children}</div>
      <div className={styles.controlsContainer}>
        {previousStep ? (
          <Button
            label={STRINGS.addContract.controls.backButtonLabel}
            className={styles.backButton}
            onClick={onBack}
          />
        ) : (
          <div />
        )}
        {nextStep && currentStep !== numberOfSteps ? (
          <Button
            label={STRINGS.addContract.controls.continueButtonLabel}
            onClick={onContinue}
          />
        ) : (
          <div />
        )}
        {currentStep === numberOfSteps && (
          <Button
            type="submit"
            label={STRINGS.addContract.controls.submitLabel}
          />
        )}
      </div>
    </div>
  );
};
