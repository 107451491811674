import React, { FunctionComponent } from "react";
import styles from "./InsuranceChecklist.module.scss";
import { STRINGS } from "../../../language/de_DE/strings";

import TextAccordion from "../../accordion/TextAccordion";
import { TitledBulletpointType } from "../../../content/productTreeDefinition";
import PageSection from "../../page-section/PageSection";

const sectionStrings = STRINGS.insurancePage.checklist;

type ChecklistProps = {
  bullets: TitledBulletpointType[];
};

export const InsuranceChecklist: FunctionComponent<ChecklistProps> = ({
  bullets,
}) => (
  <PageSection background={"grey"} contentClassName={styles.section}>
    <h1 className={styles.sectionTitle}>
      <span className={styles.highlighted}>{sectionStrings.title}</span>
    </h1>
    {bullets.map((entry: TitledBulletpointType) => (
      <TextAccordion
        key={entry.title}
        {...{ title: entry.title, content: entry.text }}
      />
    ))}
  </PageSection>
);
