import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import insuranceWorkDisabilityImage from "gfx/images/insurance-images/berufsunfaehigkeit_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/berufsunfaehigkeit_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/berufsunfaehigkeit_2.jpg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Berufs­unfähig­keitsversicherung";

export const berufsunfaehigkeitProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: insuranceWorkDisabilityImage,
  introduction:
    "Eine Berufsunfähigkeitsversicherung zahlt dir eine Rente, wenn du deinen Beruf aus gesundheitlichen Gründen nicht mehr ausüben kannst und schützt dich dadurch vor finanziellen Folgen.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Die Berufsunfähigkeitsversicherung (BU) versichert den zuletzt ausgeübten Beruf und zahlt dir einen vereinbarten monatlichen Betrag aus, wenn du diesen Beruf nicht mehr ausüben kannst.",
        "Wegen welcher Erkrankung Du Deinen Job nicht mehr ausüben kannst, spielt dabei keine Rolle.",
        "Die meisten Versicherer zahlen, wenn Du – ärztlich nachgewiesen – zu mindestens 50 Prozent berufsunfähig bist.",
        "Achtung: Wie viel du vorher verdient hast spielt keine Rolle. Deine Berufsunfähigkeitsrente wird bei Abschluss der Versicherung festgelegt.",
      ],
      image: descriptionImage1,
    },

    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Früher gingen Medizin und Wissenschaft davon aus, dass stressspezifische Erkrankungen wie das Burnout oder die Depression nur in sozialen und helfenden Berufsfeldern wie der Pflege, dem Lehramt und der Erziehung auftreten. Heute ist klar, dass neben jeder Berufsgruppe auch schon Studierende und Auszubildende von diesen Krankheitsbildern betroffen sein können. Neben der Psyche kann eine Berufsunfähigkeit auch durch Gelenkserkrankungen oder Unfälle zustande kommen.",
        "Statistisch wird in Deutschland jeder 4. Arbeitnehmer im Laufe seines Lebens berufsunfähig, sodass eine Berufsunfähigkeitsversicherung für jedermann wichtig ist. Denn solltest du berufsunfähig werden, kann deine finanzielle Absicherung meist unzureichend sein, sodass eine große finanzielle Lücke entsteht. Diese Lücke kann mit einer Berufsunfähigkeitsrente geschlossen werden.",
        "Dabei kann es sich lohnen, schon früh eine Berufsunfähigkeitsversicherung abzuschließen, da du noch fitter und gesünder bist, deinen Gesundheitsstatus für die Zukunft festhältst und von einem geringeren Versicherungsbeitrag profitierst. Für Studierende gibt es die Möglichkeit, in den ersten Jahren mit 30-40% des monatlichen Gesamtbeitrages einzusteigen.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Wenn du deinen Beruf oder keine Tätigkeit deiner bisherigen Lebensstellung nicht mehr ausführen kannst",
        "Wenn du mindestens 6 Monate absehbar am Stück durch einen medizinischen Gutachter als berufsunfähig attestiert wurdest",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Bei schneller Genesung oder wenn deine Berufsunfähigkeit nicht nachweisbar ist.",
        "Wenn du deine Berufsunfähigkeit absichtlich herbeiführst oder sie infolge eines Suizidversuchs auftritt",
        "Wenn du falsche Angaben beim Abschluss deiner Versicherung gemacht oder bereits bestehende Krankheiten verschwiegen hast",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  checklist: [
    {
      title: "Gesundheitsfragen",
      text: "Gerade auf die Gesundheitsfragen beim Abschluss einer Berufsunfähigkeitsversicherung solltest du ein besonderes Augenmerk legen. Hier ist es sinnvoll, dass du dir einen fachkundigen Berater an die Seite nimmst, der sich Zeit nimmt, deine Situation prüft, mit der Antragsprozess durchläuft und schaut, was für deine individuelle Situation am",
    },
  ],

  link: RouteUrls.berufsunfaehigkeitsversicherung,
};
