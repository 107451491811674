import ML from "gfx/images/team-images/ML.jpg";
import LJ from "gfx/images/team-images/LJ.jpg";
import SG from "gfx/images/team-images/SG.jpg";
import VZ from "gfx/images/team-images/VZ.jpg";
import TD from "gfx/images/team-images/TD.jpg";

export const team = [
  {
    image: VZ,
    name: "Victor Zibold",
    position: "",
    email: "",
    text: "",
  },
  {
    image: ML,
    name: "Michael Lange",
    position: "",
    email: "",
    text: "",
  },
  {
    image: TD,
    name: "Thorsten Dylla",
    position: "",
    email: "",
    text: "",
  },
  {
    image: LJ,
    name: "Louis Jacob",
    position: "",
    email: "",
    text: "",
  },
  {
    image: SG,
    name: "Simon Gehring",
    position: "",
    email: "",
    text: "",
  },
];
