import styles from "components/form-inputs/ToggleSwitch/ToggleSwitch.module.scss";

import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";

type ToggleSwitchProperties = {
  labelText: string;
};

export const ToggleSwitch: FunctionComponent<ToggleSwitchProperties> = ({
  labelText,
}) => {
  return (
    <Box flex inline>
      <label className={styles.switch}>
        <input type="checkbox" />
        <span className={clsx([styles.slider, styles.round])} />
      </label>
      <Box flex className={styles.labelText}>
        <Text inline size={"xSmall"}>
          {labelText}
        </Text>
      </Box>
    </Box>
  );
};
