import { Translations } from "@aws-amplify/ui-components";

const errorMessageTranslations: Record<
  string | keyof typeof Translations,
  string
> = {
  [Translations.EMPTY_USERNAME]: "E-Mail darf nicht leer sein",
  [Translations.EMPTY_EMAIL]: "E-Mail darf nicht leer sein",
  ["UsernameExistsException"]: "E-Mail bereits vergeben",
};

export { errorMessageTranslations };
