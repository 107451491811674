import styles from "components/navigation-bar/MobileNavigationOverlay.module.scss";

import React, { ReactElement } from "react";
import clsx from "clsx";
import { isActivePage } from "components/navigation-bar/isActivePage";
import NavigationItemLink from "components/navigation-bar/NavigationItemLink";
import { dashboardSidebarItems } from "config/dashboardSidebarItems";
import { navigationItems } from "config/navigationItems";
import { RouteUrls } from "config/routes";
import useUserState from "hooks/useUserState";
import { STRINGS } from "language/de_DE/strings";

interface Props {
  isVisible: boolean;
}

const MobileNavigationOverlay = ({ isVisible }: Props): ReactElement => {
  const { isLoggedIn } = useUserState();

  return (
    <div
      className={clsx(
        styles.mobileNavigationOverlay,
        isVisible && styles.visible
      )}
    >
      <div className={styles.navigationItems}>
        {navigationItems.map((link) => (
          <NavigationItemLink
            key={link.url}
            to={link.url}
            className={styles.navigationItem}
            isActive={isActivePage(link.url)}
            isMobile
          >
            {link.Icon && <NavigationIcon Icon={link.Icon} />}
            {link.title}
          </NavigationItemLink>
        ))}
      </div>

      <div className={styles.navigationItemSeparator}>
        <div className={styles.line} />
      </div>

      <div className={styles.navigationItems}>
        {isLoggedIn && (
          <>
            {dashboardSidebarItems.map((link) => (
              <NavigationItemLink
                key={link.url}
                to={link.url}
                className={clsx(styles.navigationItem)}
                isActive={isActivePage(link.url)}
                isMobile
              >
                {link.Icon && <NavigationIcon Icon={link.Icon} />}
                {link.title}
              </NavigationItemLink>
            ))}
            <div className={styles.navigationItemSeparator} />
            <NavigationItemLink
              to={RouteUrls.logOut}
              className={styles.navigationItem}
              isMobile
            >
              {STRINGS.navigation.logout}
            </NavigationItemLink>
          </>
        )}
        {!isLoggedIn && (
          <NavigationItemLink
            to={RouteUrls.logIn}
            className={styles.navigationItem}
            isMobile
          >
            {STRINGS.navigation.login}
          </NavigationItemLink>
        )}
      </div>
    </div>
  );
};

interface NavigationIconProps {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

const NavigationIcon = ({ Icon }: NavigationIconProps): ReactElement => {
  return (
    <div className={styles.navigationIcon}>
      <Icon />
    </div>
  );
};

export default MobileNavigationOverlay;
