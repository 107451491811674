import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/fahrrad_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/fahrrad_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/fahrrad_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Fahr­rad­versicherung";

export const fahrradProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Eine Fahrradversicherung sichert dich bei Diebstahl gegen den finanziellen Schaden ab. Versichert sind dann alle Fahrräder des Haushalts, vom Mountainbike bis zum Pedelec.",

  headerButtons: [
    {
      label: "Vergleichen",
      link: RouteUrls.fahrradversicherungVergleich,
    },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title: "... dein Fahrrad doch mal vom Laternenpfahl gestohlen wurde.",
      text: icon1,
    },
    {
      title:
        "... dein Fahrrad mangelhaft verarbeitet wurde und z. B. schon innerhalb der ersten zwei Jahre anfängt zu rosten.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine " + productName + "?",
      paragraphs: [
        "Du möchtest früh morgens zur Arbeit fahren, aber dein Fahrrad fehlt? Oder du wolltest nur kurz ein Rezept beim Arzt abholen und hast dein Fahrrad angeschlossen, kommst wieder aus der Praxis und das Hinterrad fehlt?",
        "Das sind beides Fälle, in denen dich eine Fahrradversicherung in der Regel schützen würde und für die Reparaturen aufkommt.",
        "Generell kannst du dein Fahrrad zum einen über eine Hausratsversicherung versichern lassen, bzw. bei deiner Hausratsversicherung als Add-on hinzufügen.",
        "Zum anderen kannst du aber auch eine Fahrradversicherung abschließen, denn meistens ist bei der Hausratsversicherung nur dein Fahrrad auf deinem Grundstück oder in deiner Wohnung versichert.",
      ],
      image: descriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Eine Fahrradversicherung lohnt sich für alle die, die ein etwas teureres Fahrrad haben, auf dieses angewiesen sind und mit dem Fahrrad zur Arbeit fahren.",
        "Wenn du ein E-Bike oder ein Lastenrad hast, lohnt sich besonders für Dich eine Versicherung.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Schäden, die durch einen Unfall, Sturz oder durch unsachgemäße Handhabung entstehen",
        "Schäden, die durch Vandalismus an deinem Fahrrad entstehen",
        "Schäden, die durch Fehler im Material- oder bei der Produktion entstanden sind, kontaktiere hier aber am besten zuerst den Hersteller",
        "Verschleißschäden, jedoch meist nicht, wenn diese an deinem Reifen oder deiner Kette entstehen",
      ],
      titleNotAccepted: "Wann zahlt die Versicherung NICHT?",
      notAccepted: [
        "Wenn du dein Fahrrad nicht richtig abschließt, oder du ein unzureichendes Schloss hast. Achte daher bitte genau auf das Kleingedruckte und welche Schlösser zulässig sind und welche nicht.",
        "Wenn Anbauteile an deinem Fahrrad zusätzlich angebracht wurden. So kann es sein, dass nachträglich angebaute Navis, spezielle Sattel, Carbonrahmen oder angebrachte Lenker nicht mitversichert sind.",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  checklist: [
    {
      title: "Fahrradversicherung und Hausratsversicherung",
      text: "Es kann sein, dass dein Fahrrad schon in deiner Hausratsversicherung inkludiert ist. Prüfe aber hier bitte genau die Konditionen. Denn meistens ist das Fahrrad nur auf deinem Grundstück oder in deiner Wohnung (bzw. im Keller) versichert.",
    },
    {
      title: "Nachtklausel",
      text: "Eine Nachtklausel besagt meistens, dass dein Fahrrad nur tagsüber außerhalb deiner vier Wände versichert ist. Wenn es daher zwischen 22 Uhr und 6 Uhr entwendet wird, kann es sein, dass deine Versicherung nicht greift. Eventuell kann man nachweisen, dass du mit dem Fahrrad zur Bar oder zu Freunden gefahren bist und es ordnungsgemäß angeschlossen hast.",
    },
    {
      title: "Schlösser",
      text: "Ein angeschlossenes Fahrrad ist und bleibt die Mindestvoraussetzung, wenn du im Schadenfall eine Erstattung von deiner Versicherung erwartest.",
    },
  ],

  link: RouteUrls.fahrradversicherung,
};
