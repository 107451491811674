import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import DescriptionImage0 from "gfx/images/insurance-images/reisegepaeck_0.jpg";
import DescriptionImage1 from "gfx/images/insurance-images/reisegepaeck_1.jpg";
import DescriptionImage2 from "gfx/images/insurance-images/reisegepaeck_2.jpg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Reise­gepäck­versicherung";

export const reisegepaeckProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: DescriptionImage0,
  introduction:
    "Eine Reise­gepäck­versicherung schützt dich, wenn dein Gepäck unterwegs abhandenkommt, gestohlen oder beschädigt wird. Wenn du gerne auf Reisen bist, sollte diese Versicherung nicht in deiner Absicherung fehlen.",

  headerButtons: [
    { label: "Vergleichen", link: RouteUrls.reisegepaeckversicherungVergleich },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  longDescriptions: [
    {
      title: "Was ist eine " + productName + " ?",
      paragraphs: [
        "Reist du viel oder stehst du auch manchmal mit einem mulmigen Gefühl an dem Gepäckband und hast Angst, dass dein Koffer verloren gegangen ist? In einer solchen Situation kann dir die Reisegepäckversicherung Sicherheit geben. ",
        "Eine Reisegepäckversicherung schützt dich, wenn dein Gepäck unterwegs abhandenkommt, gestohlen oder beschädigt wird.",
        "Dabei wird in den meisten Fällen der Zeitwert des Gepäckinhaltes durch die Versicherung erstattet",
        "So kannst du noch im Urlaubsort gemütlich Ersatz kaufen oder dir noch schnell deinen verloren gegangenen Anzug auf der Geschäftsreise ersetzten.",
        "Oft bieten Versicherer eine solche Gepäckversicherung in ganzen Paketen mit einer Auslandskrankenversicherung oder einer Reiserücktrittsversicherung an.",
      ],
      image: DescriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + " ?",
      paragraphs: [
        "Besonders sinnvoll ist eine Reisegepäckversicherung dann, wenn du viel reist. Sie kann sich aber auch dann lohnen, wenn du in Länder mit besonders hohen Diebstahlraten reist.",
        "Empfehlenswert ist, dass du deine Versicherung bereits im Voraus abschließt, da einige Versicherungsgesellschaften aufgrund festgelegter Wartezeiten sonst keinen Schutz mehr für deine Reise anbieten können.",
      ],

      image: DescriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "einen Verlust, einen Diebstahl oder eine Beschädigung an deinem Gepäck",
        "Schäden durch Feuer, Überschwemmung oder Sturm an deinem Reisegepäck",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Verlust von größeren Summen von Bargeld oder sehr teure Accessoires",
        "Verlust von amtlichen Dokumenten wie Personalausweis oder Reisepass",
        "Schäden die du durch Vorsatz an deinem Gepäck verursachst",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.reisegepaeckversicherung,
};
