import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/rechtsschutz_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/rechtsschutz_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/rechtsschutz_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Rechts­schutz­versicherung";

export const rechtsschutzProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Eine Rechts­schutz­versicherung hilft dir dabei, deine Ansprüche vor Gericht durchzusetzen – ohne dabei Angst vor dem finanziellen Ruin haben zu müssen.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title:
        "... du dich gegen Rechtsstreits mit Arbeitgeber oder Vermieter absichern willst.",
      text: icon1,
    },
    {
      title:
        "... du dich gegen Rechtsstreits absichern willst, die sich aus dem Straßenverkehr ergeben.",
      text: icon1,
    },
    {
      title:
        "... du dich gegen weitere Rechtsstreitigkeiten absichern willst, welche nicht höchstpersönlich (z. B. Scheidung, Erbrecht) sind.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine " + productName + " ?",
      paragraphs: [
        "Der Abschluss einer Rechtsschutzversicherung verschafft dem Versicherten die Gewissheit, ungeachtet seiner finanziellen und gesellschaftlichen Situation einen bestimmten Rechtsanspruch vor Gericht zu verteidigen.",
        "Im Falle eines Rechtstreits übernimmt die Rechtsschutzversicherung alle wichtigen Gebührenblöcke für den Versicherten. Anwalts- und Gerichtshonorare, Entschädigungen für Zeugen, Reisekosten, Honorare für Sachverständiger und Gerichtsvollzieher.",
        "Dabei werden die Kosten stets bis zu einer festgelegten Versicherungssumme übernommen, welche zu Beginn des Vertragsverhältnisses festgelegt wird.",
        "Eine Rechtsschutzversicherung ermöglicht somit vielen Menschen, dass sie vor Gericht für ihr Recht kämpfen können.",
      ],
      image: descriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + " ?",
      paragraphs: [
        "Eine Rechtsschutzversicherung ergibt Sinn für Menschen, die in einem der genannten Felder eine Streitigkeit vermuten – es diese aktuell aber noch nicht gibt.",
        "Eine Rechtsschutzversicherung stellt eine nützliche Präventivvorsorge für Streitigkeiten dar, bei denen eine Partei beabsichtigt, ihre Ansprüche vor Gericht durchzusetzen.",
        "Zusätzlich solltest du sicherstellen, dass du einen Rechtsschutz nicht auch aus anderer Quelle, wie zum Beispiel der Mitgliedschaft in einer Gewerkschaft, beziehen kannst.",
      ],

      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Rechtliche Auseinandersetzungen mit dem Arbeitgeber",
        "Auseinandersetzungen aufgrund von regelmäßiger Teilnahme am Straßenverkehr",
        "Auseinandersetzungen bei nicht-erbrachten Versicherungsleistungen",
        "Auseinandersetzungen mit dem Vermieter",
        "Auseinandersetzungen mit Dritten im öffentlichen Raum",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Auseinandersetzungen, die zum Abschluss der Versicherung schon laufen",
        "Scheidung",
        "Erbrechtliche Auseinandersetzungen",
        "Streit beim Hausbau, Grundstück oder Baufinanzierung",
        "Spekulative Kapitalanlagen",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.rechtsschutzversicherung,
};
