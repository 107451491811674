import { Auth } from "aws-amplify";
import { CodeDeliveryDetails } from "amazon-cognito-identity-js";
import {
  ApiResponse,
  getErrorCode,
  getErrorMessage,
} from "hooks/useUserRegistration";
import createUsernameFromEmail from "utils/auth/createUsernameFromEmail";

type ForgotPasswordResponse = {
  CodeDeliveryDetails: CodeDeliveryDetails;
};

const confirmNewPassword = async (
  email: string,
  code: string,
  password: string
): Promise<ApiResponse<string>> => {
  const username = createUsernameFromEmail(email);
  try {
    const result = await Auth.forgotPasswordSubmit(username, code, password);
    return {
      ok: true,
      data: result,
    };
  } catch (error) {
    return {
      ok: false,
      message: getErrorMessage(error as Error),
      code: getErrorCode(error as Error),
    };
  }
};

const forgotPasswordUser = async (
  email: string
): Promise<ApiResponse<CodeDeliveryDetails>> => {
  const username = createUsernameFromEmail(email);
  try {
    const forgotPasswordResult: ForgotPasswordResponse =
      await Auth.forgotPassword(username);
    return {
      ok: true,
      data: forgotPasswordResult.CodeDeliveryDetails,
    };
  } catch (error) {
    return {
      ok: false,
      message: getErrorMessage(error as Error),
      code: getErrorCode(error as Error),
    };
  }
};

type UseUserForgotPasswordReturnType = {
  forgotPasswordUser: (
    email: string
  ) => Promise<ApiResponse<CodeDeliveryDetails>>;
  confirmNewPassword: (
    email: string,
    code: string,
    password: string
  ) => Promise<ApiResponse<string>>;
};

const useUserForgotPassword = (): UseUserForgotPasswordReturnType => {
  return {
    forgotPasswordUser,
    confirmNewPassword,
  };
};
export default useUserForgotPassword;
