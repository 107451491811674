import { RouteUrls } from "config/routes";
import { ReactComponent as icon2015 } from "gfx/icons/icon2015.svg";
import { ReactComponent as icon2094 } from "gfx/icons/icon2094.svg";

export const navigationItems = [
  {
    title: "BedarfsCheck",
    url: RouteUrls.demandCalculator,
    Icon: icon2015,
  },
  {
    title: "Versichern und Vorsorgen",
    url: RouteUrls.insurances,
    Icon: icon2094,
  },
];
