import styles from "components/user-portal/user-profile/dialogs/item/EditableUserProfileItem.module.scss";

import React, { ReactElement } from "react";
import { BooleanSelect } from "../../../../form-inputs/Selections/BooleanSelect";
import { Field } from "formik";
import { TextField } from "../../../../form-inputs/TextField/TextField";
import {
  Option,
  SingleSelect,
} from "../../../../form-inputs/Selections/SingleSelect";
import { NumberField } from "components/form-inputs/NumberField/NumberField";
import { DateField } from "../../../../form-inputs/DateField/DateField";
import { PhoneInputField } from "../../../../form-inputs/PhoneInputField/PhoneInputField";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { isValidPhoneNumber } from "react-phone-number-input";

type EditableFieldProps = {
  fieldName: string;
  labelText?: string;
};

type EditableUserProfileTextfieldProps = EditableFieldProps & {
  required?: boolean;
  formatFn?: (value: string) => string;
};

const validateRequired = (
  required?: boolean,
  value?: string,
  labelText?: string
) => {
  if (!required) return;
  if (value === "" || value === undefined) {
    return STRINGS.formInputs.validation.required(labelText);
  }
};

export const EditableUserProfileTextfield = ({
  fieldName,
  labelText,
  required,
  formatFn,
}: EditableUserProfileTextfieldProps): ReactElement => {
  return (
    <div className={styles.userProfileItem}>
      <div className={styles.labelContainer}>
        <div className={styles.label}>{labelText}:</div>
      </div>
      <div className={styles.inputContainer}>
        <Field
          component={TextField}
          name={fieldName}
          formatFn={formatFn}
          validate={(value?: string) =>
            validateRequired(required, value, labelText)
          }
        />
      </div>
    </div>
  );
};

type EditableUserProfileSingleSelectProps = EditableFieldProps & {
  options: Option[];
};

export const EditableUserProfileSingleSelect = ({
  labelText,
  fieldName,
  options,
}: EditableUserProfileSingleSelectProps): ReactElement => {
  return (
    <div className={styles.userProfileItem}>
      <div className={styles.labelContainer}>
        <div className={styles.label}>{labelText}:</div>
      </div>
      <div className={styles.inputContainer}>
        <Field
          component={SingleSelect}
          name={fieldName}
          options={options}
          withNoneOption
        />
      </div>
    </div>
  );
};

type EditableUserProfilePhoneNumberProps = EditableFieldProps & {
  required?: boolean;
};

export const EditableUserProfilePhoneNumber = ({
  labelText,
  fieldName,
  required,
}: EditableUserProfilePhoneNumberProps): ReactElement => {
  return (
    <div className={styles.userProfileItem}>
      <div className={styles.labelContainer}>
        <div className={styles.label}>{labelText}:</div>
      </div>
      <div className={styles.inputContainer}>
        <Field
          component={PhoneInputField}
          name={fieldName}
          label={labelText}
          validate={(value?: string) => {
            if (!value && required)
              return STRINGS.formInputs.validation.required(labelText);
            if ((value === "" || value === undefined) && !required) return;
            if (!isValidPhoneNumber(value || "")) {
              return STRINGS.formInputs.validation.phone(labelText);
            }
          }}
        />
      </div>
    </div>
  );
};

export const EditableUserProfileBoolean = ({
  labelText,
  fieldName,
}: EditableFieldProps): ReactElement => {
  return (
    <div className={styles.userProfileItem}>
      <div className={styles.labelContainer}>
        <div className={styles.label}>{labelText}:</div>
      </div>
      <div className={styles.inputContainer}>
        <Field
          component={BooleanSelect}
          name={fieldName}
          inputClassName={styles.profileSelect}
        />
      </div>
    </div>
  );
};

type EditableUserProfileDateFieldProps = EditableFieldProps & {
  minDate?: Date;
  required?: boolean;
};

export const EditableUserProfileDateField = ({
  labelText,
  fieldName,
  minDate,
  required,
}: EditableUserProfileDateFieldProps): ReactElement => {
  return (
    <div className={styles.userProfileItem}>
      <div className={styles.labelContainer}>
        <div className={styles.label}>{labelText}:</div>
      </div>
      <div className={styles.inputContainer}>
        <Field
          component={DateField}
          name={fieldName}
          minDate={minDate}
          validate={(value?: string) =>
            validateRequired(required, value, labelText)
          }
        />
      </div>
    </div>
  );
};

type EditableUserProfileNumberFieldProps = EditableFieldProps & {
  integersOnly?: boolean;
};

export const EditableUserProfileNumberField = ({
  labelText,
  fieldName,
  integersOnly,
}: EditableUserProfileNumberFieldProps): ReactElement => {
  return (
    <div className={styles.userProfileItem}>
      <div className={styles.labelContainer}>
        <div className={styles.label}>{labelText}:</div>
      </div>
      <div className={styles.inputContainer}>
        <Field
          component={NumberField}
          name={fieldName}
          integersOnly={integersOnly}
        />
      </div>
    </div>
  );
};
