import { Hub } from "aws-amplify";
import EventBus from "utils/EventBus";

enum ConsumerHubEvents {
  logIn = "login",
  logOut = "logout",
}

type HubEvent = {
  channel: string;
  payload: {
    event: string;
    data?: unknown;
    message?: string;
  };
  source: string;
  patternInfo?: string[];
};

const hubConsumerEventBus = new EventBus();

const registerAwsHubEventHandler = (): void => {
  Hub.listen("auth", (data: HubEvent) => {
    switch (data.payload.event) {
      case "signIn":
        hubConsumerEventBus.dispatch(ConsumerHubEvents.logIn);
        break;
      case "signOut":
        hubConsumerEventBus.dispatch(ConsumerHubEvents.logOut);
        break;
    }
  });
};

type ConsumerEventHandler = () => void;
type UseAwsHubEventReturnType = {
  onUserLoggedOut: (consumerEvent: ConsumerEventHandler) => void;
  onUserLoggedIn: (consumerEvent: ConsumerEventHandler) => void;
};

const useAwsHubEvent = (): UseAwsHubEventReturnType => {
  return {
    onUserLoggedIn: (consumerEvent: ConsumerEventHandler) => {
      hubConsumerEventBus.register(ConsumerHubEvents.logIn, consumerEvent);
    },
    onUserLoggedOut: (consumerEvent: ConsumerEventHandler) => {
      hubConsumerEventBus.register(ConsumerHubEvents.logOut, consumerEvent);
    },
  };
};

export default useAwsHubEvent;

export { registerAwsHubEventHandler };
