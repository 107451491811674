import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
} from "react";
import { VertragSparanlage } from "../types/backend/VertragSparanlage";
import { VertragKapitalanlage } from "../types/backend/VertragKapitalanlage";
import { VertragVersicherungKfz } from "../types/backend/VertragVersicherungKfz";
import { VertragVersicherung } from "../types/backend/VertragVersicherung";
import { Score } from "../types/backend/Score";
import { useGeldanlagen, useVersicherungen } from "../api/useVertraege";
import useUserState from "./useUserState";
import { useScore } from "../api/useScore";

export type PortalState = {
  geldanlagen: Array<VertragSparanlage | VertragKapitalanlage> | undefined;
  versicherungen:
    | Array<VertragVersicherungKfz | VertragVersicherung>
    | undefined;
  score: Score | undefined;
};

export const PortalStateContext = createContext<PortalState>({
  geldanlagen: [],
  versicherungen: [],
  score: {
    vermoegensplanung: 0,
    vorsorge: 0,
    absicherung: 0,
    total: 0,
  },
});

export const PortalStateContextProvider = ({
  children,
}: PropsWithChildren): ReactElement => {
  const { kunde } = useUserState();
  const { data: score } = useScore(kunde?.kid);
  const { data: geldanlagen } = useGeldanlagen(kunde?.kid);
  const { data: versicherungen } = useVersicherungen(kunde?.kid);

  return (
    <PortalStateContext.Provider value={{ geldanlagen, versicherungen, score }}>
      {children}
    </PortalStateContext.Provider>
  );
};

export const usePortalState = (): PortalState => {
  return useContext(PortalStateContext);
};
