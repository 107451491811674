import common from "components/form-inputs/FormInputCommons.module.scss";

import React, { ReactElement } from "react";
import clsx from "clsx";
import PhoneInput, { getCountryCallingCode } from "react-phone-number-input";
import { v4 } from "uuid";
import { FormInputLabel } from "../FormInputLabel";
import { FormInputSubText } from "../FormInputSubText";
import {
  FormInputCustomStyleProperties,
  FormInputProperties,
} from "../FormInputTypes";

const parseValue = (value: any): string => {
  if (value === undefined || value === null || value === "") {
    return `+${getCountryCallingCode("DE")}`;
  }
  if (value[0] === "+") return value;
  return "+" + value;
};

const CustomizablePhoneInputField = ({
  field,
  form,
  labelText,
  helpText,
  disabled,
  tabIndex,
  placeholder,
  lightColor,
  inputClassName,
  labelClassName,
  validationClassName,
  helpTextClassName,
}: FormInputProperties & FormInputCustomStyleProperties): ReactElement => {
  const id = field.name + "-" + v4();
  const meta = form.getFieldMeta(field.name);

  const handleChange = (e?: string) => {
    form.setFieldValue(field.name, e || "");
  };

  return (
    <div>
      <FormInputLabel
        labelText={labelText}
        labelClassName={labelClassName}
        id={id}
        lightColor={lightColor}
      />
      <PhoneInput
        id={id}
        {...field}
        defaultCountry={"DE"}
        international
        placeholder={placeholder}
        className={clsx(common.phoneInput, inputClassName)}
        value={parseValue(field.value)}
        onChange={handleChange}
        disabled={disabled}
        tabIndex={tabIndex}
      />
      <FormInputSubText
        meta={meta}
        helpText={helpText}
        helpTextClassName={helpTextClassName}
        validationClassName={validationClassName}
      />
    </div>
  );
};

export const PhoneInputField = (props: FormInputProperties) => {
  return <CustomizablePhoneInputField {...props} />;
};
