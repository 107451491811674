import styles from "components/user-portal/contract/ContractDataConfig.module.scss";

import React, { ReactNode } from "react";
import clsx from "clsx";
import { YieldPercentValue } from "components/user-portal/contract/ContractTableCells";
import { STRINGS_INSTITUTES } from "language/de_DE/institutes";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { ProduktKategorie } from "types/backend/ProduktKategorie";
import { formatCurrency, formatEnum, valueOrEmpty } from "utils/formatUtils";
import { formatKundeName } from "utils/kundeUtils";
import { VertragBase } from "../../../types/backend/VertragBase";

export const getContractDataConfig = (
  produktKategorie: ProduktKategorie
): VersicherungenTableConfig | GeldanlagenTableConfig => {
  switch (produktKategorie) {
    case "KAPITALANLAGEN":
      return kapitalanlagenTableConfig;
    case "SPAREN":
      return sparanlagenTableConfig;
    case "KFZ_VERSICHERUNGEN":
      return versicherungenKfzTableConfig;
    default:
      return versicherungenTableConfig;
  }
};

export interface ColumnConfig {
  fixedWidth?: boolean;
  header?: string | number | ReactNode | undefined;
  headerCard?: string;
  value?: (vertrag: any, kunde: KundeResponse) => ReactNode | string;
  valueCard?: (
    vertrag: any,
    kunde: KundeResponse
  ) => ReactNode | string | undefined;
  hideInCard?: boolean;
  className?: string;
}

export interface VersicherungenTableConfig {
  col1: ColumnConfig;
  col2: ColumnConfig;
  col3: ColumnConfig;
  col4: ColumnConfig;
  col5: ColumnConfig;
}

export interface GeldanlagenTableConfig extends VersicherungenTableConfig {
  col6: ColumnConfig;
}

const geldanlagenTableConfig: GeldanlagenTableConfig = {
  col1: {
    className: clsx(styles.investments, styles.col1),
  },
  col2: {
    className: clsx(styles.investments, styles.col2),
    fixedWidth: true,
  },
  col3: {
    className: clsx(styles.investments, styles.col3),
  },
  col4: {
    className: clsx(styles.investments, styles.col4),
  },
  col5: {
    className: clsx(styles.investments, styles.col5),
  },
  col6: {
    className: clsx(styles.investments, styles.col6),
  },
};

export const getVertragTypDisplayValue = (vertrag: VertragBase) => {
  if (!vertrag.typ || vertrag.typ === "SONSTIGE_VERTRAEGE") {
    return vertrag.customTyp || STRINGS.vertrag.typ.SONSTIGE_VERTRAEGE;
  }
  let displayValue = undefined;
  if (vertrag.produktTyp === "VERSICHERUNG") {
    displayValue = formatEnum(vertrag.typ, STRINGS.vertrag.typ.versicherungen);
  }
  if (vertrag.produktTyp === "GELDANLAGE") {
    displayValue = formatEnum(vertrag.typ, STRINGS.vertrag.typ.geldanlagen);
  }

  return displayValue || vertrag.typ;
};

const kapitalanlagenTableConfig: GeldanlagenTableConfig = {
  ...geldanlagenTableConfig,
  col1: {
    ...geldanlagenTableConfig.col1,
    header: STRINGS.vertrag.investments.cardHeaders.assetType,
    value: getVertragTypDisplayValue,
  },
  col2: {
    ...geldanlagenTableConfig.col2,
    header: STRINGS.vertrag.investments.cardHeaders.assetName,
    value: (vertrag) => valueOrEmpty(vertrag.bezeichnung),
  },
  col3: {
    ...geldanlagenTableConfig.col3,
    header: STRINGS.vertrag.investments.cardHeaders.assetIdentification,
    value: (vertrag) => valueOrEmpty(vertrag.wertpapierNummer),
  },
  col4: {
    ...geldanlagenTableConfig.col4,
    header: STRINGS.vertrag.investments.cardHeaders.acquisitionValue,
    value: (vertrag) => formatCurrency(vertrag.kaufwert, ""),
  },
  col5: {
    ...geldanlagenTableConfig.col5,
    header: STRINGS.vertrag.investments.cardHeaders.currentValue,
    value: (vertrag) => formatCurrency(vertrag.wert, ""),
  },
  col6: {
    ...geldanlagenTableConfig.col6,
    header: STRINGS.vertrag.investments.cardHeaders.winLoss,
    value: (vertrag) => (
      <div>
        <div className={styles.winLoss}>
          {formatCurrency(vertrag.ertrag, "")}
        </div>
        <YieldPercentValue value={vertrag.ertragProzent} />
      </div>
    ),
  },
};

const sparanlagenTableConfig: GeldanlagenTableConfig = {
  ...geldanlagenTableConfig,
  col1: {
    ...geldanlagenTableConfig.col1,
    header: STRINGS.vertrag.investments.cardHeaders.type,
    value: getVertragTypDisplayValue,
  },
  col2: {
    ...geldanlagenTableConfig.col2,
    header: STRINGS.vertrag.investments.cardHeaders.name,
    value: (vertrag) => valueOrEmpty(vertrag.bezeichnung),
  },
  col3: {
    ...geldanlagenTableConfig.col3,
    header: STRINGS.vertrag.investments.cardHeaders.deposit,
    value: (vertrag) =>
      formatEnum(vertrag.beitragsIntervall, STRINGS.vertrag.interval),
  },
  col4: {
    ...geldanlagenTableConfig.col4,
    header: STRINGS.vertrag.investments.cardHeaders.saveValue,
    value: (vertrag) => formatCurrency(vertrag.beitrag, ""),
  },
  col5: {
    ...geldanlagenTableConfig.col5,
    header: STRINGS.vertrag.investments.cardHeaders.currentValue,
    value: (vertrag) => formatCurrency(vertrag.wert, ""),
  },
  col6: {
    ...geldanlagenTableConfig.col6,
    header: STRINGS.vertrag.investments.cardHeaders.targetValue,
    value: (vertrag) => formatCurrency(vertrag.zielwert, ""),
    valueCard: (vertrag) => (
      <div>
        <div className={styles.targetValue}>
          {formatCurrency(vertrag.zielwert, "")}
        </div>
        <div>&nbsp;</div>
      </div>
    ),
  },
};

const versicherungenTableConfig: VersicherungenTableConfig = {
  col1: {
    header: STRINGS.vertrag.productCards.headers.type,
    fixedWidth: true,
    value: getVertragTypDisplayValue,
    hideInCard: true,
    className: clsx(styles.insurances, styles.col1),
  },
  col2: {
    header: STRINGS.vertrag.productCards.headers.insured,
    value: (_, kunde) => formatKundeName(kunde),
    className: clsx(styles.insurances, styles.col2),
  },
  col3: {
    header: STRINGS.vertrag.productCards.headers.institute,
    fixedWidth: true,
    value: (vertrag) =>
      formatEnum(
        vertrag.institut,
        STRINGS_INSTITUTES.vertrag.institut,
        vertrag.institut
      ),
    className: clsx(styles.insurances, styles.col3),
  },
  col4: {
    header: STRINGS.vertrag.productCards.headers.amount,
    value: (vertrag) => formatCurrency(vertrag.beitrag),
    valueCard: (vertrag) =>
      `${formatCurrency(vertrag.beitrag)} ${formatEnum(
        vertrag.beitragsIntervall,
        STRINGS.vertrag.interval
      )}`,
    className: clsx(styles.insurances, styles.col4),
  },
  col5: {
    header: STRINGS.vertrag.productCards.headers.amountYearly,
    value: (vertrag) => formatCurrency(vertrag.beitragProJahr),
    className: clsx(styles.insurances, styles.col5),
  },
};

const versicherungenKfzTableConfig: VersicherungenTableConfig = {
  ...versicherungenTableConfig,
  col2: {
    ...versicherungenTableConfig.col2,
    header: STRINGS.vertrag.productCards.headers.vehicleManufacturerAndType,
    headerCard: STRINGS.vertrag.productCards.headers.vehicleManufacturer,
    value: (vertrag) =>
      `${vertrag.kfzHersteller} ${
        vertrag.kfzBezeichnung ? `(${vertrag.kfzBezeichnung})` : ""
      }`,
    valueCard: (vertrag) => valueOrEmpty(vertrag.kfzHersteller),
  },
};
