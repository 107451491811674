import React from "react";
import AuthenticatedPage from "components/AuthenticatedPage";
import PortalContent from "components/user-portal/user-overview/UserOverview";

const UserOverviewPage = () => (
  <AuthenticatedPage>
    <PortalContent />
  </AuthenticatedPage>
);

export default UserOverviewPage;
