import styles from "components/molecules/InsuranceRating.module.scss";

import React, { FunctionComponent } from "react";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";
import { STRINGS } from "language/de_DE/strings";

export type InsuranceRatingProperties = {
  rating: number;
  ratingMax?: number;
};

export const InsuranceRating: FunctionComponent<InsuranceRatingProperties> = ({
  rating,
  ratingMax = 5,
}) => {
  return (
    <Box flex inline>
      <Box flex className={styles.ratingTitle}>
        <Text inline size={"small"}>
          {STRINGS.insuranceComparison.insuranceComparisonInsuranceRatingLabel}
        </Text>
      </Box>
      <Box flex className={styles.ratingValues}>
        <Text inline size={"small"}>{`${rating}/${ratingMax}`}</Text>
      </Box>
    </Box>
  );
};
