import React, { FunctionComponent } from "react";
import styles from "./OurMission.module.scss";
import { STRINGS } from "../../../../language/de_DE/strings";
import ourMissionIMG from "gfx/images/ourmission.jpg";
import PageSection from "../../../page-section/PageSection";

const sectionStrings = STRINGS.aboutUs.overview;

export const OurMission: FunctionComponent = () => (
  <PageSection>
    <div className={styles.section}>
      <div className={styles.text}>
        <h1 className={styles.title}>
          <span className={styles.highlighted}>
            {sectionStrings.titleFirstLine}
          </span>
          {sectionStrings.titleSecondLine}
        </h1>
        {sectionStrings.paragraphs.map((paragraph: string) => (
          <p key={paragraph}>{paragraph}</p>
        ))}
      </div>
      <img
        src={ourMissionIMG}
        alt={sectionStrings.titleSecondLine}
        className={styles.image}
      />
    </div>
  </PageSection>
);
