import React from "react";
import {
  EditableUserProfileDateField,
  EditableUserProfileSingleSelect,
  EditableUserProfileTextfield,
} from "components/user-portal/user-profile/dialogs/item/EditableUserProfileItem";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { createOptions } from "utils/formInputUtils";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";
import { KundeRequest } from "../../../../../types/backend/KundeRequest";

const identificationOptions = createOptions(
  STRINGS.profile.identification.identificationTypes
);

interface EditIdentificationFormProps {
  formId: string;
  kunde: KundeResponse;
  mutation: UseMutationResult<unknown, unknown, KundeRequest>;
}

const EditIdentificationForm: React.FC<EditIdentificationFormProps> = ({
  formId,
  kunde,
  mutation,
}) => {
  return (
    <Formik
      initialValues={{ ...(kunde || {}) }}
      onSubmit={(values) => mutation.mutate(values)}
      enableReinitialize={true}
    >
      {() => {
        return (
          <Form id={formId}>
            <EditableUserProfileSingleSelect
              fieldName="identifizierungen.0.ausweisart"
              options={identificationOptions}
              labelText={STRINGS.profile.identification.identificationType}
            />
            <EditableUserProfileTextfield
              fieldName="identifizierungen.0.behoerde"
              labelText={STRINGS.profile.identification.authority}
            />
            <EditableUserProfileTextfield
              fieldName="identifizierungen.0.ausweisnummer"
              labelText={STRINGS.profile.identification.number}
            />
            <EditableUserProfileDateField
              fieldName="identifizierungen.0.austellungsdatum"
              labelText={STRINGS.profile.identification.issuedAt}
            />
            <EditableUserProfileDateField
              fieldName="identifizierungen.0.gueltigkeitsdatum"
              labelText={STRINGS.profile.identification.validUntil}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditIdentificationForm;
