import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import DescriptionImage0 from "gfx/images/insurance-images/hausgrundbesitzerhaftpflicht_0.jpg";
import DescriptionImage1 from "gfx/images/insurance-images/hausgrundbesitzerhaftpflicht_1.jpg";
import DescriptionImage2 from "gfx/images/insurance-images/hausgrundbesitzerhaftpflicht_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Haus- und Grund­besitzer­haft­pflicht";

export const hausgrundbesitzerhaftpflichtProductTree: ProductTreeInsuranceType =
  {
    title: productName,
    image: DescriptionImage0,
    introduction:
      "Wenn ein Mieter auf nicht gefegtem Laub ausrutscht und sich verletzt oder ein Bauarbeiter bei Sanierungen von einem gelösten Dachziegel getroffen wird, bist du als Haus- und Grundstücksbesitzer für den daraus entstandenen Schaden verantwortlich. Die Haus- und Grundbesitzerhaftpflichtversicherung bewahrt Hausherren vor finanziellen Schäden, begleicht berechtigte Schadensersatzforderungen und wehrt wiederum unberechtigte Forderungen ab.",

    headerButtons: [
      { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
      { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
    ],

    insuranceSituation: [
      {
        title:
          "... ein Mieter auf nicht gefegtem Laub ausrutscht und sich verletzt.",
        text: icon1,
      },
      {
        title:
          "... ein Besucher auf einem unbebauten Grundstück in ein tiefes Loch fällt und mit einer schweren Verletzung gerettet werden muss.",
        text: icon1,
      },
      {
        title: "... ein Postbote auf einer verschmutzten Treppe ausrutscht.",
        text: icon1,
      },
    ],

    longDescriptions: [
      {
        title: "Was ist eine " + productName + "?",
        paragraphs: [
          "Als Eigentümer fremdgenutzter Immobilien bist du verpflichtet, bestimmten Auflagen nachzukommen. Dazu gehören das ordnungsgemäße Instandhalten, Ausleuchten, Reinigen und Schneeräumen.",
          "Werden Personen geschädigt, weil du deinen Eigentümerpflichten nicht nachgekommen bist, musst du mit deinem gesamten Vermögen dafür haften.",
          "Die Haus- und Grundbesitzerhaftpflichtversicherung ist daher vergleichbar mit einer Privathaftpflichtversicherung für Häuser und Grundstücke. Kommt eine Dritte Person auf dem Grundstück deiner Immobilie oder deines Bauprojektes zu Schaden, werden die daraus entstandenen finanziellen Folgen durch die Haus- und Grundbesitzerhaftpflicht getragen.",
        ],
        image: DescriptionImage1,
      },
      {
        title: "Wer braucht eine " + productName + "?",
        paragraphs: [
          "Diese Absicherung ist für dich sinnvoll, wenn du Immobilien vermietest, diese jemandem überlässt oder unbebaute Grundstücke besitzt. Zu diesen Immobilien gehören beispielsweise Mehrfamilienhäuser, Einliegerwohnungen, vermietete Wohnhäuser, leerstehende Gewerbebauten oder unbebaute Grundstücke.",
        ],

        image: DescriptionImage2,
      },
    ],

    insuranceBenefits: [
      {
        titleAccepted: "Die " + productName + " deckt in der Regel:",
        accepted: [
          "Personen-, Sach- und Vermögensschäden von Mietern und Besuchern",
        ],
        titleNotAccepted: "Was wird NICHT versichert?",
        notAccepted: [
          "Schäden bei gewerblich genutzten Immobilien",
          "Schäden bei eigengenutzten Immobilien",
        ],
      },
    ],

    insuranceCompanies: [
      { companyName: "DEVK", image: devkLogo },
      { companyName: "Württembergische", image: wurttLogo },
      { companyName: "Allianz", image: allianzLogo },
      { companyName: "AllSecur", image: allSecurLogo },
      { companyName: "HDI", image: hdiLogo },
      { companyName: "R+V", image: ruvLogo },
      { companyName: "LVM", image: lvmLogo },
    ],

    checklist: [
      {
        title: "Abwehr unberechtigter Forderungen:",
        text: "Die Versicherungen prüfen, inwiefern für die Schadenersatzforderungen ein berechtigter Anspruch durch die Betroffenen besteht und wehren unberechtigte oder zu hohe Forderungen kostenlos für dich ab. Du erhältst daher mit Abschluss einer Haus- und Grundbesitzer­haftpflicht­versicherung auch einen passiven Rechtsschutz.",
      },
      {
        title: "Hohe Ver­si­che­rungs­sum­me:",
        text: "Kommt auf deinem versicherten Grundstück eine Person zu Schaden, können die Schadenerstzansprüche schnell in die Millionenhöhe steigen. Da du als Eigentümer mit deinem Privatvermögen haftest und mögliche Lücken in deinem Versicherungsschutz selbst bezahlen musst, sollte die Versicherungssumme deiner Haus- und Grundbesitzer­haftpflicht­versicherung mindestens 3 Millionen Euro abdecken.",
      },
      {
        title: "Bauarbeiten für 100.000 Euro:",
        text: "Werden für deine Immobilie Sanierungen oder Umbaumaßnahmen fällig, werden Schäden bei manchen Versicherungen durch die Haus- und Grundbesitzerhaftpflichtversicherung im festgelegten Rahmen abgesichert. Eine Bauherrenhaftpflichtversicherung erlischt in der Regel mit Abschluss der Bauarbeiten und deckt dann weder Schäden bei späteren Sanierungen noch bei Umbauarbeiten.",
      },
      {
        title: "Pho­to­vol­ta­ik­an­la­ge:",
        text: "Mit der zunehmenden Installation von Photovoltaikanlagen auf Privatimmobilien steigt auch die Unfallwahrscheinlichkeit. Insbesondere bei Stürmen und starken Unwettern können schnell Personen- und Sachschäden entstehen, für welche du haften musst. Achte daher stets auf die Haftung durch die Handwerker – ansonsten solltest du über eine Haus- und Grundbesitzer­haftpflicht­versicherung nachdenken.",
      },
      {
        title: "Allmählichkeitsschäden:",
        text: "Entstehen Schäden als Spätfolge von tropfenden Wasserleitungen, sind die entstandenen Allmählichkeitsschäden insbesondere bei alten Verträgen nicht immer mitversichert. Achte daher stets auf einen angemessenen Versicherungsschutz und aktualisiere deine Versicherung, wenn dies nicht enthalten ist.",
      },
    ],

    link: RouteUrls.hausGrundbesitzerHaftpflicht,
  };
