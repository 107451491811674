import React, { FunctionComponent } from "react";
import PageSection from "../page-section/PageSection";
import styles from "./Quotes.module.scss";

type Quote = {
  text: string;
  logo: string;
  logoAlt: string;
};

type Props = {
  quotes: Quote[];
};

export const Quotes: FunctionComponent<Props> = ({ quotes }) => (
  <PageSection contentClassName={styles.section}>
    {quotes.map((quote) => (
      <div key={quote.text} className={styles.quote}>
        <img src={quote.logo} className={styles.logo} alt={quote.logoAlt} />
        <div className={styles.text}>{quote.text}</div>
      </div>
    ))}
  </PageSection>
);
