import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/riesterrente_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/riesterrente_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/riesterrente_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Riester-Rente";

export const riesterrenteProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Die Riester-Rente ist eine durch staatliche Zulagen geförderte, grundsätzlich privat finanzierte Rente in Deutschland. Sie hilft dir auf dem Weg zur finanziellen Unabhängigkeit.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title: "... wenn du ein geringes Einkommen hast oder arbeitslos bist.",
      text: icon1,
    },
    {
      title:
        "... wenn du auf ein Eigenheim sparst oder einen Immobilienkredit abbezahlen must.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Die Riester-Rente ist eine staatlich geförderte Form der privaten Altersvorsorge.",
        "Wer regelmäßig mindestens 4 % seines Einkommens einzahlt, erhält vom Staat Zulagen zu seinen Beiträgen und Steuervorteile.",
        "Dabei steht jedem Einzahler eine Grundzulage in Höhe von 175 € zu.",
        "Im Alter können sich Riester-Sparer dann eine lebenslange Rente auszahlen lassen.",
      ],
      image: descriptionImage1,
    },

    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Die Riester-Rente lohnt sich vor allem bei geringerem Einkommen.",
        "Um die volle Förderung zu erhalten, muss man nur einen geringen Beitrag einzahlen. Die Förderquote, d.h. der Anteil der staatlichen Förderung am Eigenbeitrag, ist somit vergleichsweise hoch.",
        "Außerdem kann sich die Riester-Rente Familien mit vielen Kindern besonders lohnen, da für jedes Kind eine weitere Zulage fällig wird.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Wenn du ein geringes Einkommen hast, da deine Förderquote dann besonders hoch ist",
        "Wenn du besonders viel verdienst, da du dann Steuern sparen kannst",
        "Wenn du viele Kinder hast, da du dann einen besonders hohen Zuschlag bekommst",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Wenn du eine hohe Rendite erwartest",
        "Wenn du noch vor deiner Rente an dein eingezahltes Kapital möchtest",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.riesterrente,
};
