import React, { ReactElement } from "react";
import { useFormikContext } from "formik";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { VertragTyp } from "../../../../../types/backend/VertragTyp";
import { validateNumber, validateRequiredOnTypes } from "./util/validationUtil";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { AddContractFormType } from "../AddContractFormType";
import { shouldDisplayField } from "./util/displayInputUtil";
import { AddContractNumberInput } from "./base/AddContractNumberInput";

const requiredTypes: VertragTyp[] = ["PFLEGEVERSICHERUNG"];

type LeistungRentePflegeType = {
  name:
    | "leistungRentePflege1"
    | "leistungRentePflege2"
    | "leistungRentePflege3"
    | "leistungRentePflege4"
    | "leistungRentePflege5";
  pflegeStufe: number;
};

export const LeistungRentePflege = ({
  vertragTyp,
  activeStep,
  name,
  pflegeStufe,
}: AddContractInputBaseType & LeistungRentePflegeType): ReactElement | null => {
  const { values } = useFormikContext<AddContractFormType>();

  const validate = () => {
    if (activeStep !== 2) return;
    return (
      validateRequiredOnTypes(
        requiredTypes,
        STRINGS.addContract.step2.pensionCareError,
        vertragTyp,
        values[name]
      ) || validateNumber(values[name])
    );
  };

  if (!shouldDisplayField(vertragTyp, requiredTypes)) return null;
  return (
    <AddContractNumberInput
      name={name}
      labelText={STRINGS.addContract.step2.pensionCareLabel(pflegeStufe)}
      validate={validate}
    />
  );
};
