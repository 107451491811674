import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { DemandCalculatorInputFormType } from "./DemandCalculatorInputFormType";
import styles from "./DemandCalculatorInputForm.module.scss";
import {
  DemandCalculatorInputField,
  DemandCalculatorSelectField,
} from "./DemandCalculatorInputField";
import { STRINGS } from "../../../language/de_DE/strings";
import { NumberField } from "../../form-inputs/NumberField/NumberField";
import { DateField } from "../../form-inputs/DateField/DateField";

interface DemandCalculatorInputFormAssetsProps {
  formProps: FormikProps<DemandCalculatorInputFormType>;
}

export const DemandCalculatorInputFormAssets = ({
  formProps,
}: DemandCalculatorInputFormAssetsProps): ReactElement => {
  return (
    <div className={styles.formGrid}>
      <DemandCalculatorInputField
        name="verkehrswertImmobilie"
        label={STRINGS.demandCalculator.assets.marketValueProperty}
        component={NumberField}
        integersOnly
      />
      <DemandCalculatorInputField
        name="verbindlichkeitenImmobilie"
        label={STRINGS.demandCalculator.assets.liabilitiesProperty}
        component={NumberField}
        integersOnly
      />
      <DemandCalculatorInputField
        name="anlageVermoegen"
        label={STRINGS.demandCalculator.assets.capitalAssets}
        component={NumberField}
        integersOnly
      />
      <DemandCalculatorInputField
        name="sonstigeVermoegenswerte"
        label={STRINGS.demandCalculator.assets.otherAssets}
        component={NumberField}
        integersOnly
      />
      <DemandCalculatorInputField
        name="sonstigeDarlehen"
        label={STRINGS.demandCalculator.assets.loans}
        component={NumberField}
        integersOnly
      />
      <DemandCalculatorInputField
        name="konsumentenKredite"
        label={STRINGS.demandCalculator.assets.consumerCredits}
        component={NumberField}
        integersOnly
      />
      <DemandCalculatorInputField
        name="genommenerDispositionskredit"
        label={STRINGS.demandCalculator.assets.dispositionCredit}
        component={NumberField}
        integersOnly
      />
      <DemandCalculatorSelectField
        name="investitionsZiel"
        label={STRINGS.demandCalculator.assets.investmentGoal}
        booleanSelect
      />
      {formProps.values.investitionsZiel && (
        <>
          <DemandCalculatorInputField
            name="investitionsZielWunschbetrag"
            label={STRINGS.demandCalculator.assets.targetSavingsAmount}
            component={NumberField}
            integersOnly
          />
          <DemandCalculatorInputField
            name="investitionsZielZeitpunkt"
            label={STRINGS.demandCalculator.assets.targetSavingsDate}
            component={DateField}
            integersOnly
          />
        </>
      )}
      <DemandCalculatorSelectField
        name="eigeneImmobilieGeplant"
        label={STRINGS.demandCalculator.assets.ownPropertyIntended}
        booleanSelect
      />
      {formProps.values.eigeneImmobilieGeplant && (
        <>
          <DemandCalculatorInputField
            name="eigeneImmobilieWert"
            label={STRINGS.demandCalculator.assets.costOfProperty}
            component={NumberField}
            integersOnly
          />
          <DemandCalculatorInputField
            name="eigeneImmobilieErwerbZeitpunkt"
            label={STRINGS.demandCalculator.assets.intendedDateOfAcquisition}
            component={DateField}
            integersOnly
          />
        </>
      )}
      <DemandCalculatorSelectField
        name="ersatzInvestition"
        label={STRINGS.demandCalculator.assets.replacementInvestment}
        booleanSelect
      />

      {formProps.values.ersatzInvestition && (
        <>
          <DemandCalculatorInputField
            name="ersatzInvestitionBetrag"
            label={STRINGS.demandCalculator.assets.replacementInvestmentValue}
            component={NumberField}
            integersOnly
          />
          <DemandCalculatorInputField
            name="ersatzInvestitionZeitpunkt"
            label={STRINGS.demandCalculator.assets.replacementInvestmentDate}
            component={DateField}
            integersOnly
          />
        </>
      )}
    </div>
  );
};
