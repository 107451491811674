import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/mietkaution_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/mietkaution_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/mietkaution_2.jpg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";
import icon1 from "gfx/icons/icon1796.svg";

const productName = "Mietkautionsversicherung";

export const mietkautionsversicherungProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Eine Mietkautionsversicherung kann alle Kautionsarten ersetzten, sodass du als Mieter dem Vermieter lediglich deine Versicherung vorlegen musst, nicht aber mehrere Monatsmieten Kaution leisten musst, die Vermieter oft verlangen. ",

  headerButtons: [
    { label: "Vergleichen", link: RouteUrls.mietkautionsversicherungVergleich },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title: "... du Liquidität sparen möchtest.",
      text: icon1,
    },
    {
      title:
        "... du aktiv investierst und davon ausgehst, dass du mit deiner Barkaution eine höhere Rendite erwirtschaftest als dich die Mietkaution kostet.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine " + productName + "?",
      paragraphs: [
        "Die Mietkaution ist eine Sicherheitsleistung, die der Mieter zu Beginn eines Mietverhältnisses beim Vermieter hinterlegt.",
        "Mit dieser Mietsicherheit sichert sich der Vermieter aus dem Mietverhältnis entstehende Forderungen ab",
        "Anstatt hierfür eine Barkaution zu leisten, kannst du eine Mietkautionsversicherung abschließen.",
      ],
      image: descriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Wenn du umziehst, kann das oft viel Geld kosten, da Kosten wie Umzugskosten, Kosten für neue Möbel, etc. anfallen.",
        "Das kann deine Liquidität manchmal gefährden, sodass es in diesen Fällen besonders sinnvoll ist, eine Mietkautionsversicherung abzuschließen.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Vorteile einer Mietkautionsversicherung",
      accepted: [
        "Es wird deine Mietkaution übernommen",
        "Die Kautionsversicherung ist unabhängig von einer Bank. Ein Kautionskonto muss nicht eröffnet werden",
        "Deine Bonität wird nicht beeinträchtigt",
      ],
      titleNotAccepted: "und die Nachteile ",
      notAccepted: [
        "Nicht alle Vermieter akzeptieren eine Mietkautionsversicherung",
        "Entsteht während der Laufzeit der Bürgschaft kein Schaden, zahlt der Mieter unterm Strich drauf (durch Zahlung der jährlichen Prämie)",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.mietkautionsversicherung,
};
