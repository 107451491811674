import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/kapitalleben_0.jpg";

export const kapitallebenProductTree: ProductTreeInsuranceType = {
  title: "Kapitallebensversicherung",
  image: descriptionImage0,
  introduction:
    "Diese Versicherungen ist besonders gut für dich geeignet, da sie dich in jeder Situation vollumfänglich absichert",

  link: RouteUrls.kapitallebensversicherung,
};
