import styles from "components/navigation-bar/NavigationItemLogoutButton.module.scss";

import React, { ComponentProps, ReactElement } from "react";
import NavigationItemButton from "components/navigation-bar/NavigationItemButton";
import NavigationItemLink from "components/navigation-bar/NavigationItemLink";
import { RouteUrls } from "config/routes";
import useMatchMedia from "hooks/useMatchMedia";

type Props = {
  className?: ComponentProps<typeof NavigationItemLink>["className"];
};

const NavigationItemLogoutButton = ({ className }: Props): ReactElement => {
  const isSmallerScreen = useMatchMedia("(max-width: 1170px)");

  if (isSmallerScreen) {
    return (
      <NavigationItemLink to={RouteUrls.logOut} className={className}>
        <div className={styles.smallLogoutButton}>Logout</div>
      </NavigationItemLink>
    );
  }

  return (
    <NavigationItemButton
      to={RouteUrls.logOut}
      title="Logout"
      buttonClass={styles.desktopNavigationLogoutButton}
    />
  );
};

export default NavigationItemLogoutButton;
