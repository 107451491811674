import styles from "pages/demand-calculator/DemandCalculatorPage.module.scss";
import React, { ReactElement } from "react";
import { DemandCalculator } from "../../components/demand-calculator/DemandCalculator";
import Page from "components/page/Page";
import PageSection from "../../components/page-section/PageSection";

const DemandCalculatorPage = (): ReactElement => {
  return (
    <Page contentClassName={styles.demandCalculator}>
      <PageSection>
        <DemandCalculator />
      </PageSection>
    </Page>
  );
};

export default DemandCalculatorPage;
