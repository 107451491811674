import styles from "components/templates/HausratversicherungVergleichAbschlussStep2Template.module.scss";

import React, { ReactElement, useRef } from "react";
import clsx from "clsx";
import { FormikProps } from "formik";
import moment from "moment";
import { Box } from "components/atoms/Box";
import { Button } from "components/form-inputs/Button/Button";
import {
  HausratversicherungAbschlussStep2Form,
  Step2FormType,
} from "pages/hausratversicherung/forms/HausratversicherungAbschlussStep2Form";
import { InsuranceBadge } from "components/molecules/InsuranceBadge";
import {
  InsuranceCompletionSteps,
  InsuranceCompletionStepsProperties,
} from "components/molecules/InsuranceCompletionSteps";
import UnstyledLink from "components/unstyle-link/UnstyledLink";
import { RouteUrls } from "config/routes";
import { STRINGS } from "language/de_DE/strings";
import { InsuranceBadgeData } from "types/InsuranceData/InsuranceBadgeData";

export type HausratversicherungVergleichAbschlussStep2Properties = {
  selectedInsurance: InsuranceBadgeData;
};

export const HausratversicherungVergleichAbschlussStep2Template = ({
  selectedInsurance,
}: HausratversicherungVergleichAbschlussStep2Properties): ReactElement => {
  const completionProps: InsuranceCompletionStepsProperties = {
    steps: {
      names: [
        STRINGS.insuranceAbschluss.insuranceBreadcrumbs.breadcrumbStep1,
        STRINGS.insuranceAbschluss.insuranceBreadcrumbs.breadcrumbStep2,
        STRINGS.insuranceAbschluss.insuranceBreadcrumbs.breadcrumbStep3,
        STRINGS.insuranceAbschluss.insuranceBreadcrumbs.breadcrumbStep4,
      ],
    },
    currentStep: 2,
    dividerClass: styles.divider,
  };

  const formRef = useRef<FormikProps<Step2FormType>>(null);

  const handleSubmit = async () => {
    if (formRef.current) {
      const values = formRef.current.values;
      if (values.damageAmount < values.damages.length) {
        values.damages = values.damages.slice(0, values.damageAmount);
      }
      if (values.schaden === "false") {
        values.damages = [];
        values.damageAmount = 0;
      }
      if (values.startDate === "true") {
        if (moment().day() === 5 || moment().day() === 6) {
          values.startDateInput = moment().weekday(8).format("YYYY-MM-DD");
        } else {
          values.startDateInput = moment().add(1, "days").format("YYYY-MM-DD");
        }
      }

      formRef.current
        .validateForm()
        // eslint-disable-next-line no-console
        .then(() => formRef.current?.isValid && console.log(values));
    }
  };

  return (
    <Box flex column className={styles.insuranceStepBox}>
      <Box className={styles.insuranceBadgeBox}>
        <InsuranceBadge insuranceBadgeData={selectedInsurance} />
      </Box>
      <InsuranceCompletionSteps {...completionProps} />
      <Box flex className={styles.previousDamageBox}>
        <HausratversicherungAbschlussStep2Form ref={formRef} />
      </Box>
      <Box flex inline>
        <UnstyledLink to={RouteUrls.hausratversicherungVergleichErgebnis}>
          <Button
            label={STRINGS.insuranceAbschluss.backButtonLabel}
            className={clsx(styles.button, styles.buttonBack)}
          />
        </UnstyledLink>
        <UnstyledLink to={RouteUrls.hausratversicherungVergleichAbschlussStep3}>
          <Button
            label={STRINGS.insuranceAbschluss.proceedButtonLabel}
            type={"submit"}
            onClick={() => handleSubmit()}
            className={styles.button}
          />
        </UnstyledLink>
      </Box>
    </Box>
  );
};
