import styles from "./HausratversicherungAbschlussStep3Form.module.scss";

import React, { ChangeEvent, forwardRef, useState } from "react";
import { Field, Form, Formik, FormikProps } from "formik";
import { Box } from "components/atoms/Box";
import { Checkbox } from "components/form-inputs/Checkbox/Checkbox";
import { TextField } from "components/form-inputs/TextField/TextField";
import { Text } from "components/atoms/Text";
import { STRINGS } from "language/de_DE/strings";
import { hausratTarifeComparisonStep3Schema } from "utils/validators/hausrat/hausratTarifeComparisonStep3";

// eslint-disable-next-line @typescript-eslint/ban-types
export type HausratversicherungAbschlussStep3FormProperties = {};

export type Step3FormType = {
  ibanInput: string | null;
  mandate: boolean;
};

export const HausratversicherungAbschlussStep3Form = forwardRef<
  FormikProps<Step3FormType>,
  HausratversicherungAbschlussStep3FormProperties
  // eslint-disable-next-line no-empty-pattern
>(({}, reference) => {
  const initialValues: Step3FormType = {
    ibanInput: "",
    mandate: false,
  };

  const [iban, setIban] = useState<string>("");

  const formatIban = (
    value: string,
    properties: FormikProps<Step3FormType>
  ) => {
    iban.length < value.length &&
      setIban(value.replace(/\W/gi, "").replace(/(.{4})/g, "$1 "));
    iban.length > value.length && setIban(value);
    properties.setFieldValue("ibanInput", value);
  };

  return (
    <Box className={styles.formBox}>
      <Text size={"xLarge"} inline className={styles.headline}>
        {STRINGS.insuranceAbschluss.bankAccountStep.headlineBankAccount}
      </Text>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        enableReinitialize={true}
        innerRef={reference}
        validationSchema={hausratTarifeComparisonStep3Schema}
      >
        {(properties) => (
          <Form>
            <Box flex column>
              <Text size={"large"}>
                {STRINGS.insuranceAbschluss.bankAccountStep.iban}
              </Text>
              <Field
                as={TextField}
                name={"ibanInput"}
                inputName={"ibanInput"}
                placeholder={
                  STRINGS.insuranceAbschluss.bankAccountStep.ibanPlaceholder
                }
                inputClassName={styles.ibanInputField}
                value={iban}
                changeEvent={(e: ChangeEvent<HTMLInputElement>) => {
                  formatIban(
                    e.target.value.toString().slice(0, 27),
                    properties
                  );
                }}
              />
              <Text
                size={"xSmall"}
                inline
                className={styles.ibanInputFieldDescription}
              >
                {
                  STRINGS.insuranceAbschluss.bankAccountStep
                    .ibanInputDescription
                }
              </Text>
              <Box flex column className={styles.checkboxBox}>
                <Field
                  component={Checkbox}
                  name={"mandate"}
                  inputClass={styles.radio}
                  boxClass={styles.checkboxItem}
                  labelText={
                    STRINGS.insuranceAbschluss.bankAccountStep.debitMandate
                  }
                  value={"false"}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
});

HausratversicherungAbschlussStep3Form.displayName =
  "HausratversicherungAbschlussStep3Form";
