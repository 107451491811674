import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import {
  faLifeRing,
  faMartiniGlass,
  faPersonBiking,
} from "@fortawesome/free-solid-svg-icons";
import { InsuranceOption } from "types/insuranceComparisonData/Options";

export const insuranceOptionsMock: Array<InsuranceOption> = [
  {
    icon: faPersonBiking,
    title: "Fahrraddiebstahl",
    price: 9.2,
    description: "für Diebstahl deiner Fahrräder außerhalb deiner Wohnung",
    toolTipData: {
      icon: faCircleQuestion,
      content: "Fahrraddiebstahl ist versichert",
    },
  },
  {
    icon: faMartiniGlass,
    title: "Glasbruch",
    price: 11.4,
    description: "z. B. an Fenstern, Vasen oder Kochfeldern",
    toolTipData: {
      icon: faCircleQuestion,
      content: "Glasbruch is versichert",
    },
  },
  {
    icon: faLifeRing,
    title: "Elementarschäden",
    price: 8.97,
    description: "z. B. durch Überschwemmung",
    toolTipData: {
      icon: faCircleQuestion,
      content: "Elementarschäden sind versichert",
    },
  },
];
