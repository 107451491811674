import userIcon from "gfx/icons/icon1797.svg";
import digitalIcon from "gfx/icons/icon1798.svg";
import worldInHandIcon from "gfx/icons/icon1738.svg";

/**
 * Features of the insurance manager on about-us.
 */
export const USPs = [
  {
    img: worldInHandIcon,
    title: "Unabhängig",
    text: "Als dein unabhängiger Versicherungsmanager vertreten wir deine Interessen und treffen die Tarifauswahl allein anhand deiner Bedürfnisse.",
  },
  {
    img: digitalIcon,
    title: "Digital",
    text: "Bei uns kannst du alle deine Verträge auch gänzlich digital managen.",
  },
  {
    img: userIcon,
    title: "Individuell",
    text: "Du und deine Situation sind einzigartig. Wir jeden Fall individuell und unterstützen dich dabei, deine Finanzsituation nachhaltig zu verbessern.",
  },
];
