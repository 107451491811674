import styles from "components/step-wizard/login/LoginStepWizard.module.scss";

import React, { ReactElement, useState } from "react";
import StepWizard from "react-step-wizard";
import AuthenticationCodeForm from "components/organisms/AuthenticationCodeForm";
import LoginForm from "components/organisms/LoginForm";
import AuthenticationCodeFormContext from "components/step-wizard/components/AuthenticationCodeFormContext";
import { WizardStep } from "components/step-wizard/components/WizardStep";
import LoginWizardContext, {
  LoginWizardContextState,
  LoginWizardContextType,
} from "components/step-wizard/login/LoginWizardContext";
import LoginAdditionalDataForm from "../../organisms/LoginAdditionalDataForm";

const LoginStepWizard = (): ReactElement => {
  const [wizardContextState, setWizardContextState] =
    useState<LoginWizardContextState>({
      email: "",
      password: "",
    });

  const wizardProviderValue: LoginWizardContextType = {
    state: wizardContextState,
    setState: setWizardContextState,
  };

  return (
    <LoginWizardContext.Provider value={wizardProviderValue}>
      <StepWizard isHashEnabled={false}>
        <WizardStep>
          {({ nextStep, goToStep }) => (
            <div className={styles.formWrapper}>
              <LoginForm nextStep={nextStep} goToStep={goToStep} />
            </div>
          )}
        </WizardStep>
        <WizardStep>
          {({ isActive, previousStep, nextStep }) => (
            <AuthenticationCodeFormContext.Provider
              value={{
                email: wizardProviderValue.state.email,
                password: wizardProviderValue.state.password,
              }}
            >
              <AuthenticationCodeForm
                previousStep={previousStep}
                nextStep={nextStep}
                isActive={isActive ?? false}
                checkAdditionalData={true}
              />
            </AuthenticationCodeFormContext.Provider>
          )}
        </WizardStep>
        <WizardStep>
          {() => (
            <div className={styles.formWrapper}>
              <LoginAdditionalDataForm />
            </div>
          )}
        </WizardStep>
      </StepWizard>
    </LoginWizardContext.Provider>
  );
};

export default LoginStepWizard;
