import "react-responsive-modal/styles.css";
import "App.scss";

import React, { ReactElement } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { registerAwsHubEventHandler } from "hooks/useAwsHubEvent";
import { UserStateContextProvider } from "hooks/useUserState";
import configureAwsCognito from "utils/configureAwsCognito";
import configureLocale from "utils/configureLocale";
import DevelopmentHint from "components/development-hint/DevelopmentHint";
import { Analytics } from "components/analytics/Analytics";
import { CookiesProvider } from "react-cookie";
import ScrollToTop from "./utils/scrollToTop";
import { PortalStateContextProvider } from "hooks/usePortalState";
import { MyNormRoutes } from "routes/MyNormRoutes";

configureLocale();
configureAwsCognito();
registerAwsHubEventHandler();

const queryClient = new QueryClient();

const App = (): ReactElement => {
  return (
    <>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <UserStateContextProvider>
            <PortalStateContextProvider>
              <Router>
                <Analytics>
                  <MyNormRoutes />
                  <ScrollToTop />
                </Analytics>
              </Router>
            </PortalStateContextProvider>
          </UserStateContextProvider>
        </QueryClientProvider>
        <DevelopmentHint />
      </CookiesProvider>
    </>
  );
};

export default App;
