import styles from "components/navigation-bar/NavigationItemButton.module.scss";

import React, { ReactElement } from "react";
import clsx from "clsx";
import { Button } from "components/form-inputs/Button/Button";
import { Link, LinkProps } from "react-router-dom";

type Props = {
  title: ReactElement | string;
  isActive?: boolean;
  buttonClass?: string;
} & LinkProps;

const NavigationItemButton = ({
  title,
  isActive,
  buttonClass,
  ...rest
}: Props): ReactElement => (
  <Link
    {...rest}
    className={clsx(
      styles.navigationItemButton,
      rest.className,
      isActive && styles.active
    )}
  >
    <Button primary label={title} className={buttonClass} />
  </Link>
);

export default NavigationItemButton;
