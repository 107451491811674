import React from "react";
import UserOverviewContent from "components/user-portal/user-overview/UserOverviewContent";
import UserPortal from "components/user-portal/UserPortal";

const UserOverview: React.FC = () => {
  return (
    <UserPortal>
      <UserOverviewContent />
    </UserPortal>
  );
};

export default UserOverview;
