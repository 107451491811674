export enum RouteUrls {
  homepage = "/",
  notFound = "/not-found",

  userPortalStart = "/portal",
  userPortalInsurance = "/meine-versicherungen",
  userPortalProfile = "/mein-profil",
  userPortalInvestments = "/meine-geldanlagen",
  userPortalMessageBox = "/nachrichten",
  userPortalManagement = "/verwaltung",
  userPortalContact = "/kontakt",
  userAddContract = "/add-contract",
  insurances = "/versicherungen",
  demandCalculator = "/bedarfsrechner",
  datenschutz = "/datenschutz",
  impressum = "/impressum",
  AGB = "/AGB",
  erstInformation = "/erstinformation",
  nachhaltigkeit = "/nachhaltigkeit",
  cookiePolicy = "/cookie-policy",
  aboutUs = "/ueber-uns",

  logIn = "/login",
  logOut = "/logout",
  register = "/register",
  forgotPassword = "/forgot-password",
  additionalRegistrationData = "/register-extra",

  //insurances
  hausratversicherung = "/hausratversicherung",
  wohngebaeudeversicherung = "/wohngebaeudeversicherung",
  kunstversicherung = "/kunstversicherung",

  privatHaftpflicht = "/privathaftpflicht",
  berufsHaftpflicht = "/berufshaftpflicht",
  bauherrenHaftpflicht = "/bauherren-haftpflicht",
  hausGrundbesitzerHaftpflicht = "/hausgrundbesitzerhaftpflicht",
  tierhalterHaftpflicht = "/tierhalterhaftpflicht",
  jagdHaftpflicht = "/jagdhaftpflicht",
  gewaesserschadenHaftpflicht = "/gewaesserschadenhaftpflicht",
  betreiberHaftpflicht = "/betreiberhaftpflicht",
  luftfahrzeugHaftpflicht = "/luftfahrzeugHaftpflicht",
  wasserfahrzeugHaftpflicht = "/wasserfahrzeugHaftpflicht",

  kfzversicherung = "/kfzversicherung",
  motorradversicherung = "/motorradversicherung",
  mopedversicherung = "/mopedversicherung",
  fahrradversicherung = "/fahrradversicherung",

  auslandsreisekrankenversicherung = "/auslandsreisekrankenversicherung",
  reiseruecktrittsversicherung = "/reiseruecktrittsversicherung",
  reisegepaeckversicherung = "/reisegepaeckversicherung",

  langzeitreiseversicherung = "/langzeitreiseversicherung",
  langzeitreisekrankenversicherung = "/langzeitreisekrankenversicherung",
  langzeitreisehaftpflichtversicherung = "/langzeitreisehaftpflichtversicherung",
  langzeitreiseunfallversicherung = "/langzeitreiseunfallversicherung",

  erntehelferkrankenversicherung = "/erntehelferkrankenversicherung",

  tierkrankenversicherung = "/tierkrankenversicherung",
  rechtsschutzversicherung = "/rechtsschutzversicherung",

  gkv = "/GKV",
  pkv = "/PKV",
  pflegepflichtversicherung = "/pflegepflichtversicherung",
  pflegezusatzversicherung = "/pflegezusatzversicherung",
  krankenhaustagegeld = "/krankenhaustagegeld",
  krankenhauszusatz = "/krankenhauszusatz",
  krankentagegeld = "/krankentagegeld",

  berufsunfaehigkeitsversicherung = "/berufsunfaehigkeitsversicherung",
  dienstunfaehigkeitsversicherung = "/dienstunfaehigkeitsversicherung",
  erwerbsunfaehigkeitsversicherung = "/erwerbsunfaehigkeitsversicherung",
  invaliditaetsversicherung = "/invaliditaetsversicherung",
  risikolebensversicherung = "/risikolebensversicherung",
  unfallversicherung = "/unfallversicherung",
  sterbegeldversicherung = "/sterbegeldversicherung",

  altersvorsorge = "/altersvorsorge",
  privaterentenversicherung = "/privaterentenversicherung",
  kapitallebensversicherung = "/kapitallebensversicherung",
  riesterrente = "/riesterrente",
  rueruprente = "/rueruprente",

  pferdehaftpflicht = "/pferdehaftpflichtversicherung",
  hundehaftpflicht = "/hundehaftpflichtversicherung",

  drohnenversicherung = "/drohnenversicherung",
  hochzeitsversicherung = "/hochzeitsversicherung",
  mietkautionsversicherung = "/mietkautionsversicherung",

  startkapitalausbildung = "/startkapitalausbildung",
  aufbauliquiditaetsreserve = "/aufbauliquiditaetsreserve",
  ersatzinvestitionsachwerte = "/ersatzinvestitionsachwerte",
  ueberschuldungsrisiko = "/ueberschuldungsrisiko",
  zinsaenderungsrisiko = "/zinsaenderungsrisiko",
  instandhaltungimmo = "/instandhaltungimmo",
  eigenkapitalimmofinanzierung = "/eigenkapitalimmofinanzierung",
  investitionswunsch = "/investitionswunsch",

  //insurance comparison
  hausratversicherungVergleichIndex = "/hausratversicherung/vergleich-index",
  hausratversicherungVergleichErgebnis = "/hausratversicherung/vergleich-ergebnis",
  hausratversicherungVergleichAbschlussStep2 = "/hausratversicherung/vergleich-abschluss-2",
  hausratversicherungVergleichAbschlussStep3 = "/hausratversicherung/vergleich-abschluss-3",

  // Campaigns
  reiseVersicherungCampaign = "/reiseversicherung",

  // Covomo Iframe Urls
  jagdhaftpflichtVergleich = "/jagdhaftpflicht/vergleich",
  gewaesserschadenhaftpflichtVergleich = "/gewaesserschadenhaftpflicht/vergleich",

  mopedversicherungVergleich = "/mopedversicherung/vergleich",
  fahrradversicherungVergleich = "/fahrradversicherung/vergleich",

  sterbegeldversicherungVergleich = "/sterbegeldversicherungVergleich",

  reiseVersicherungVergleich = "/reiseversicherung/vergleich",
  auslandsreisekrankenversicherungVergleich = "/auslandsreisekrankenversicherung/vergleich",
  reiseruecktrittsversicherungVergleich = "/reiseruecktrittsversicherung/vergleich",
  reisegepaeckversicherungVergleich = "/reisegepaeckversicherung/vergleich",

  langzeitreisekrankenversicherungVergleich = "/langzeitreisekrankenversicherung/vergleich",
  erntehelferkrankenVergleich = "/erntehelferkranken/vergleich",

  hundehaftpflichtVergleich = "/hundehaftpflicht/vergleich",
  pferdehaftpflichtVergleich = "/pferdehaftpflicht/vergleich",

  drohnenversicherungVergleich = "/drohnenversicherung/vergleich",
  hochzeitsversicherungVergleich = "/hochzeitsversicherung/vergleich",
  mietkautionsversicherungVergleich = "/mietkautionsversicherung/vergleich",
}
