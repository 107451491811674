import styles from "components/user-portal/score/Score.module.scss";
import React, { ReactElement } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Box } from "components/atoms/Box";
import "react-circular-progressbar/dist/styles.css";

interface ScoreProps {
  totalScore: number;
}

export const Score = ({ totalScore }: ScoreProps): ReactElement => {
  return (
    <Box className={styles.scoreCircleContainer}>
      <Box className={styles.scoreCircle}>
        <CircularProgressbar
          value={totalScore}
          text={`${totalScore}%`}
          styles={buildStyles({
            strokeLinecap: "butt",
            textColor: "var(--score-line-color)",
            pathColor: "var(--score-line-color)",
          })}
        />
      </Box>
    </Box>
  );
};
