import styles from "components/user-portal/contract/ContractCard.module.scss";

import React, { ReactElement, ReactNode } from "react";
import clsx from "clsx";
import {
  ColumnConfig,
  GeldanlagenTableConfig,
  getContractDataConfig,
  getVertragTypDisplayValue,
  VersicherungenTableConfig,
} from "components/user-portal/contract/ContractDataConfig";
import { KundeResponse } from "types/backend/KundeResponse";
import { VertragKapitalanlage } from "types/backend/VertragKapitalanlage";
import { VertragSparanlage } from "types/backend/VertragSparanlage";
import { VertragVersicherung } from "types/backend/VertragVersicherung";
import { VertragVersicherungKfz } from "types/backend/VertragVersicherungKfz";

type ContractCardProps = {
  vertrag:
    | VertragKapitalanlage
    | VertragSparanlage
    | VertragVersicherung
    | VertragVersicherungKfz;
  kunde: KundeResponse;
  className?: string;
};

const ContractCard = ({
  vertrag,
  kunde,
  className,
}: ContractCardProps): ReactElement => (
  <div className={clsx(styles.cardContainer, className)}>
    <div className={styles.cardHeader}>
      <span className={styles.headline}>
        {getVertragTypDisplayValue(vertrag)}
      </span>
    </div>
    <ContractCardContent
      vertrag={vertrag}
      kunde={kunde}
      config={getContractDataConfig(vertrag.produktKategorie)}
    />
  </div>
);

export default ContractCard;

type ContractCardContentProps = {
  vertrag:
    | VertragSparanlage
    | VertragKapitalanlage
    | VertragVersicherung
    | VertragVersicherungKfz;
  kunde: KundeResponse;
  config: GeldanlagenTableConfig | VersicherungenTableConfig;
};

const ContractCardContent = ({
  vertrag,
  kunde,
  config,
}: ContractCardContentProps): ReactElement => {
  return (
    <div className={styles.cardContent}>
      {Object.entries(config).map(
        ([key, columnConfig]: [string, ColumnConfig]) => {
          if (columnConfig.hideInCard) return null;
          return (
            <ContractCardEntry
              key={key}
              label={columnConfig.headerCard || columnConfig.header}
              value={
                columnConfig.valueCard?.(vertrag, kunde) ||
                columnConfig.value?.(vertrag, kunde)
              }
            />
          );
        }
      )}
    </div>
  );
};

interface ContractCardEntryProps {
  label?: string | number | ReactNode;
  value?: string | number | ReactNode;
}

const ContractCardEntry = ({
  label,
  value,
}: ContractCardEntryProps): ReactElement => {
  return (
    <div className={styles.cardEntry}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
};
