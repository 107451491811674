import { ReactElement } from "react";
import { StepWizardChildProps } from "react-step-wizard";

interface WizardStepProps extends Partial<StepWizardChildProps> {
  children: (props: Partial<StepWizardChildProps>) => ReactElement;
}

export const WizardStep = ({
  children,
  ...props
}: WizardStepProps): ReactElement => children(props);
