import React, { ReactElement } from "react";
import Page from "components/page/Page";
import { OurMission } from "components/molecules/about-us/mission/OurMission";
import { Team } from "components/molecules/about-us/team/Team";
import { USP } from "components/molecules/about-us/usp/USP";
import { LookingForYou } from "components/molecules/about-us/looking-for-you/LookingForYou";
import { OurPromise } from "components/molecules/about-us/our-promise/OurPromise";

export const AboutUs = (): ReactElement => {
  return (
    <Page>
      <OurMission />
      <Team />
      <USP />
      <OurPromise />
      <LookingForYou />
    </Page>
  );
};
