import React, { ReactElement } from "react";
import { TextField } from "../../../../../form-inputs/TextField/TextField";
import { FormInputProperties } from "../../../../../form-inputs/FormInputTypes";

export const AddContractTextInput = ({
  field,
  form,
  ...props
}: FormInputProperties): ReactElement => {
  return <TextField field={field} form={form} {...props} />;
};
