import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/privatrente_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/privatrente_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/privatrente_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Private Rentenversicherung";

export const privaterentenProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Eine private Rentenversicherung garantiert dir eine lebenslange Rente und kann somit finanzielle Lücken schließen, welche bei einem langen Leben entstehen können.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title:
        "... du zusätzlich zu den gängigen, staatlichen Altersvorsorgeverträgen für deine Rente vorsorgen möchtest.",
      text: icon1,
    },
    {
      title: "... du Kapital für das Alter ansparen möchtest.",
      text: icon1,
    },
    {
      title:
        "... du schon früh für dein Kind sparen und vom Zinseszinseffekt profitieren möchtest.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Die private Rentenversicherung ist eine Ergänzung zu deiner gesetzlichen Rente.",
        "Die gesetzlichen Vorsorgestrukturen werden aufgrund der sogenannten Rentenlücke (Differenz zwischen Gehalt und Rente) höchstwahrscheinlich nicht ausreichen, um dich im Alter zu versorgen.",
        "Personen, die in Elternzeit, zeitweise arbeitslos oder in Teilzeit waren, erhalten eine noch niedrigere Rente als andere.",
        "Um diese Rentenlücke zu füllen, kann man privat in eine Rentenversicherung investieren und jeden Monat kleine Beträge für die Rente auf die Seite legen. So lässt sich auch im Alter der gewohnte Lebensstandard halten!",
      ],
      image: descriptionImage1,
    },

    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Viele verschiedene Dinge können den Betrag deiner gesetzlichen Rente mindern: häufige Jobwechsel, Arbeiten im Ausland, Teilzeit, Arbeitslosigkeit oder Elternzeit sind alles Gründe, weswegen deine gesetzliche Rente geringer ausfallen kann als geplant.",
        "Daher ist die private Rentenversicherung für Jedermann geeignet – egal ob Berufseinsteiger, Angestellt oder Selbstständig.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.privaterentenversicherung,
};
