import { ProduktTyp } from "../../../../types/backend/ProduktTyp";
import { VertragTyp } from "../../../../types/backend/VertragTyp";
import { BeitragsIntervall } from "../../../../types/backend/BeitragsIntervall";
import { Risikoart } from "../../../../types/backend/Risikoart";
import { Verwendungszweck } from "../../../../types/backend/Verwendungszweck";
import { Pflegeleistung } from "../../../../types/backend/Pflegeleistung";
import { Gebuehrenordnung } from "../../../../types/backend/Gebuehrenordnung";
import { StationaereUnterkunft } from "../../../../types/backend/StationaereUnterkunft";
import { SehhilfeAmbulant } from "../../../../types/backend/SehhilfeAmbulant";
import { AuszahlungPrognoseType } from "./inputs/AuszahlungPrognoseInputGroup";

export type AddContractFormType = {
  vertragsnummer: string;
  produktTyp: ProduktTyp | "";
  typ: VertragTyp | "";
  customTyp: string;
  institut: string;
  customInstitut: string;
  beitrag: string;
  beitragsIntervall: BeitragsIntervall | "";
  vertragslaufzeit: string;
  nameVersicherter: string;
  files: Array<File>;
  vertragsname: string;
  isin: string;
  kaufwert: string;
  einzahlung: BeitragsIntervall | "";
  sparbetrag: string;
  zielsparsumme: string;
  aktuellerWert: string;
  verwendungszweck: Verwendungszweck | "";
  bezeichnung: string;
  bausparsumme: string;
  auszahlungEinmaligPrognose: string;
  auszahlungLaufendPrognose: string;
  auszahlungPrognoseType: AuszahlungPrognoseType | "";
  leistungRenteErwerbsunfaehigkeit: string;
  leistungRenteBerufsunfaehigkeit: string;
  risikoart: Risikoart | "";
  pflegeleistung: Pflegeleistung | "";
  risikoWert: string;
  leistungKapitalUnfall: string;
  leistungKapitalTodesfall: string;
  kfzHersteller: string;
  kfzBezeichnung: string;
  kfzBeitragKasko: string;
  leistungRentePflege1: string;
  leistungRentePflege2: string;
  leistungRentePflege3: string;
  leistungRentePflege4: string;
  leistungRentePflege5: string;
  krankentagegeld: string;
  ambulantGebuehrenordnung: Gebuehrenordnung | "";
  ambulantErstattung: boolean;
  ambulantHeilpraktiker: boolean;
  ambulantHufeland: boolean;
  ambulantSehhilfe: SehhilfeAmbulant | "";
  ambulantVorsorge: boolean;
  stationaerGebuehrenordnung: Gebuehrenordnung | "";
  stationaerUnterkunft: StationaereUnterkunft | "";
  stationaerKrankenhauswahl: boolean;
  stationaerChefarzt: boolean;
  zahnGebuehrenordnung: Gebuehrenordnung | "";
  zahnbehandlung: boolean;
  zahnanspruch: string;
  zahnersatz: boolean;
  zahnersatzOhneVorleistung: boolean;
  zahnimplantate: boolean;
  zahnKeineStaffelung: boolean;
  zahnKieferorthopaedie: boolean;
};

export const createEmptyAddContractFormValues = (): AddContractFormType => {
  return {
    einzahlung: "",
    sparbetrag: "",
    zielsparsumme: "",
    produktTyp: "VERSICHERUNG",
    vertragsnummer: "",
    typ: "",
    customTyp: "",
    institut: "",
    customInstitut: "",
    beitrag: "",
    beitragsIntervall: "",
    vertragslaufzeit: "",
    nameVersicherter: "",
    files: [],
    vertragsname: "",
    isin: "",
    kaufwert: "",
    aktuellerWert: "",
    verwendungszweck: "",
    auszahlungEinmaligPrognose: "",
    auszahlungLaufendPrognose: "",
    auszahlungPrognoseType: "",
    bezeichnung: "",
    bausparsumme: "",
    leistungRenteErwerbsunfaehigkeit: "",
    leistungRenteBerufsunfaehigkeit: "",
    risikoart: "",
    pflegeleistung: "",
    risikoWert: "",
    leistungKapitalUnfall: "",
    leistungKapitalTodesfall: "",
    kfzHersteller: "",
    kfzBezeichnung: "",
    kfzBeitragKasko: "",
    leistungRentePflege1: "",
    leistungRentePflege2: "",
    leistungRentePflege3: "",
    leistungRentePflege4: "",
    leistungRentePflege5: "",
    krankentagegeld: "",
    ambulantGebuehrenordnung: "",
    ambulantErstattung: false,
    ambulantHeilpraktiker: false,
    ambulantHufeland: false,
    ambulantSehhilfe: "",
    ambulantVorsorge: false,
    stationaerGebuehrenordnung: "",
    stationaerUnterkunft: "",
    stationaerKrankenhauswahl: false,
    stationaerChefarzt: false,
    zahnGebuehrenordnung: "",
    zahnbehandlung: false,
    zahnanspruch: "",
    zahnersatz: false,
    zahnersatzOhneVorleistung: false,
    zahnimplantate: false,
    zahnKeineStaffelung: false,
    zahnKieferorthopaedie: false,
  };
};
