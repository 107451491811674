import styles from "components/demand-calculator/input-form/DemandCalculatorInputField.module.scss";

import React, { ReactElement } from "react";
import { Field } from "formik";
import {
  Option,
  SingleSelect,
} from "../../form-inputs/Selections/SingleSelect";
import { BooleanSelect } from "../../form-inputs/Selections/BooleanSelect";

interface DemandCalculatorInputFieldProps {
  name: string;
  label: string;
  component: unknown;
  integersOnly?: boolean;
  maxDate?: Date;
}

export const DemandCalculatorInputField = ({
  name,
  label,
  component,
  integersOnly,
  maxDate,
}: DemandCalculatorInputFieldProps): ReactElement => {
  return (
    <>
      <label className={styles.label}>{label}</label>
      <div className={styles.inputContainer}>
        <Field
          component={component}
          inputClassName={styles.input}
          name={name}
          integersOnly={integersOnly}
          maxDate={maxDate}
        />
      </div>
    </>
  );
};

interface DemandCalculatorSelectFieldProps {
  name: string;
  label: string;
  options?: Option[];
  withNoneOption?: boolean;
  booleanSelect?: boolean;
}

export const DemandCalculatorSelectField = ({
  name,
  label,
  options,
  withNoneOption,
  booleanSelect,
}: DemandCalculatorSelectFieldProps): ReactElement => {
  return (
    <>
      <label className={styles.label}>{label}</label>
      <div className={styles.inputContainer}>
        <Field
          component={booleanSelect ? BooleanSelect : SingleSelect}
          name={name}
          options={options}
          withNoneOption={withNoneOption}
        />
      </div>
    </>
  );
};
