import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/moped_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/moped_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/moped_2.jpg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Moped- und Rollerversicherung";

export const mopedrollerProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Eine Moped­versicherung schützt dich, wenn du mit deinem Moped, Roller oder Mofa einen Unfall verur­sachst, durch den Dritten Schäden entstehen. Die Versicherung ist in Deutschland gesetzlich vorgeschrieben.",

  headerButtons: [
    { label: "Vergleichen", link: RouteUrls.mopedversicherungVergleich },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  longDescriptions: [
    {
      title: "Was ist eine " + productName + "?",
      paragraphs: [
        "Die Haftpflicht­versicherung haftet bei Personen-, Sach- und Vermögensschäden, die du mit deinem Moped, Roller oder Mofa verursacht. Da es bei Personenschäden schnell teuer werden kann, ist diese Versicherung gesetzlich vorgeschrieben.",
        "Zusätzlich kann eine Teilkasko abgeschlossen werden. Diese deckt auch Schäden ab die an deinem Fahrzeug entstehen, z.B. durch durch Hagel oder Dieb­stahl",
      ],
      image: descriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "In Deutschland ist jeder Halter eines angemeldeten Mopeds, Roller oder Mofas gesetzlich dazu verpflichtet eine gültige Haft­pflichtv­ersicherung vorzuweisen.",
        "Nach § 6 des Pflicht­versicherungsgesetz macht man sich sogar strafbar, sollte man ohne gültiges Versicherungs­kennzeichen angetroffen werden.",
        "Diese deckt bei einem Unfall jedoch nur Schäden von anderen Verkehrs­teilnehmern.",
        "Wenn du darüber hinaus dein eigenes Fahrzeug schützen willst, kannst du zusätzlch eine Teil­kasko­versicherung abschließen.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Schäden, von anderen Verkehrsteilnehmern.",
        "Eine Teilkaskoversicherung deckt auch Schäden am eigenen Fahrzeug, wie z.B Diebstahl, Kurzschluss, Brand, Naturgewalten und Zusammenstoß mit Tieren.",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Schäden, die im Zusammenhang mit genehmigten oder auch ungenehmigten Rennen entstehen",
        "Schäden, die vorsätzlich entstehen",
        "Schäden, die während des privaten Transports passieren, wie ein Sturz auf einem Anhänger",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],
  link: RouteUrls.mopedversicherung,
};
