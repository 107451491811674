import React from "react";
import {
  EditableUserProfileBoolean,
  EditableUserProfileDateField,
  EditableUserProfileNumberField,
} from "components/user-portal/user-profile/dialogs/item/EditableUserProfileItem";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";
import { KundeRequest } from "../../../../../types/backend/KundeRequest";

interface EditAssetsFormProps {
  formId: string;
  kunde: KundeResponse;
  mutation: UseMutationResult<unknown, unknown, KundeRequest>;
}

const EditAssetsForm: React.FC<EditAssetsFormProps> = ({
  formId,
  kunde,
  mutation,
}) => {
  return (
    <Formik
      initialValues={{ ...(kunde || {}) }}
      onSubmit={(values) => mutation.mutate(values)}
      enableReinitialize={true}
    >
      {(formProps) => {
        return (
          <Form id={formId}>
            <EditableUserProfileNumberField
              fieldName="verkehrswertImmobilie"
              labelText={STRINGS.profile.assets.marketValueProperty}
              integersOnly
            />
            <EditableUserProfileNumberField
              fieldName="verbindlichkeitenImmobilie"
              labelText={STRINGS.profile.assets.liabilitiesProperty}
              integersOnly
            />
            <EditableUserProfileNumberField
              fieldName="sonstigeVermoegenswerte"
              labelText={STRINGS.profile.assets.otherAssets}
              integersOnly
            />
            <EditableUserProfileNumberField
              fieldName="capitalAssets"
              labelText={STRINGS.profile.assets.capitalAssets}
              integersOnly
            />
            <EditableUserProfileNumberField
              fieldName="sonstigeDarlehen"
              labelText={STRINGS.profile.assets.loans}
              integersOnly
            />
            <EditableUserProfileNumberField
              fieldName="konsumentenKredite"
              labelText={STRINGS.profile.assets.consumerCredits}
              integersOnly
            />
            <EditableUserProfileNumberField
              fieldName="genommenerDispositionskredit"
              labelText={STRINGS.profile.assets.dispositionCredit}
              integersOnly
            />
            <EditableUserProfileBoolean
              fieldName="investitionsZiel"
              labelText={STRINGS.profile.assets.investmentGoal}
            />
            {formProps.values.investitionsZiel && (
              <>
                <EditableUserProfileNumberField
                  fieldName="investitionsZielWunschbetrag"
                  labelText={STRINGS.profile.assets.targetSavingsAmount}
                  integersOnly
                />
                <EditableUserProfileDateField
                  fieldName="investitionsZielZeitpunkt"
                  labelText={STRINGS.profile.assets.targetSavingsDate}
                  minDate={new Date()}
                />
              </>
            )}
            <EditableUserProfileBoolean
              fieldName="eigeneImmobilieGeplant"
              labelText={STRINGS.profile.assets.ownPropertyIntended}
            />
            {formProps.values.eigeneImmobilieGeplant && (
              <>
                <EditableUserProfileNumberField
                  fieldName="eigeneImmobilieWert"
                  labelText={STRINGS.profile.assets.costOfProperty}
                  integersOnly
                />
                <EditableUserProfileDateField
                  fieldName="eigeneImmobilieErwerbZeitpunkt"
                  labelText={STRINGS.profile.assets.intendedDateOfAcquisition}
                  minDate={new Date()}
                />
              </>
            )}
            <EditableUserProfileBoolean
              fieldName="ersatzInvestition"
              labelText={STRINGS.profile.assets.replacementInvestment}
            />
            {formProps.values.ersatzInvestition && (
              <>
                <EditableUserProfileNumberField
                  fieldName="ersatzInvestitionBetrag"
                  labelText={STRINGS.profile.assets.replacementInvestmentValue}
                  integersOnly
                />
                <EditableUserProfileDateField
                  fieldName="ersatzInvestitionZeitpunkt"
                  labelText={STRINGS.profile.assets.replacementInvestmentDate}
                  minDate={new Date()}
                />
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditAssetsForm;
