import "react-phone-number-input/style.css";
import styles from "components/organisms/RegistrationForm.module.scss";

import React, { FunctionComponent, useContext, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import createKunde from "api/requests/createKunde";
import { useUpdateSelf } from "api/useSelf";
import { Box } from "components/atoms/Box";
import { Button } from "components/form-inputs/Button/Button";
import { TextField } from "components/form-inputs/TextField/TextField";
import { Text } from "components/atoms/Text";
import { PopUpMessage } from "components/molecules/PopUpMessage";
import StepContainer from "components/step-wizard/components/StepContainer";
import RegistrationWizardContext, {
  RegistrationWizardContextType,
} from "components/step-wizard/registration/RegistrationWizardContext";
import { RouteUrls } from "config/routes";
import useTabIndex from "hooks/useTabIndex";
import useUserRegistration from "hooks/useUserRegistration";
import useUserState from "hooks/useUserState";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { AdditionalRegistrationDataFormValues } from "types/registration/AdditionalRegistrationDataFormValues";
import {
  createNewKundeRequest,
  createUpdateKundeRequest,
} from "utils/kundeUtils";
import redirectAfterLogin from "utils/redirectAfterLogin";
import { additionalRegistrationDataFormSchema } from "utils/validators/auth/registrationForm";
import { DateField } from "../form-inputs/DateField/DateField";

interface AdditionalRegistrationDataFormProperties {
  nextStep?: () => void;
}

const AdditionalRegistrationDataForm: FunctionComponent<
  AdditionalRegistrationDataFormProperties
> = () => {
  const navigate = useNavigate();
  const registrationContext = useContext(RegistrationWizardContext);
  const { kunde, reloadUser } = useUserState();
  const { getTabIndex } = useTabIndex();
  const { deleteUser } = useUserRegistration();
  const { mutate } = useUpdateSelf();
  const [displayErrorDialog, setDisplayErrorDialog] = useState(false);

  const continueAfterError = () => {
    setDisplayErrorDialog(false);
    navigate(RouteUrls.logIn);
  };

  const executeCreateKunde = async (
    registrationContext: RegistrationWizardContextType | undefined,
    values: AdditionalRegistrationDataFormValues
  ) => {
    if (!registrationContext) return;
    const request = createNewKundeRequest(registrationContext.state, values);
    try {
      await createKunde(request);
      await reloadUser();
      redirectAfterLogin(navigate);
    } catch (e) {
      await deleteUser();
      setDisplayErrorDialog(true);
    }
  };

  const executeUpdateUser = (
    kunde: KundeResponse,
    values: AdditionalRegistrationDataFormValues
  ) => {
    mutate(createUpdateKundeRequest(kunde, values), {
      onSuccess: async () => {
        await reloadUser();
        redirectAfterLogin(navigate);
      },
      onError: () => setDisplayErrorDialog(true),
    });
  };

  const setupInitialFormValues = (
    kunde?: KundeResponse
  ): AdditionalRegistrationDataFormValues => {
    const address =
      kunde && kunde.adressen && kunde.adressen.length > 0
        ? kunde.adressen[0]
        : undefined;
    return {
      city: address?.ort || "",
      street: address?.strasse || "",
      postalCode: address?.plz || "",
      birthday: kunde?.geb || "",
      birthPlace: kunde?.gebOrt || "",
    };
  };

  return (
    <StepContainer>
      <Text size={"xLarge"} bold inline className={styles.introductionText}>
        {STRINGS.account.registration.additional.introduction}
      </Text>
      <Text size={"large"} bold inline className={styles.introductionText}>
        {STRINGS.account.registration.additional.introductionSubtext}
      </Text>
      <Formik
        initialValues={setupInitialFormValues(kunde)}
        onSubmit={async (values: AdditionalRegistrationDataFormValues) => {
          if (kunde) {
            executeUpdateUser(kunde, values);
          } else {
            await executeCreateKunde(registrationContext, values);
          }
        }}
        enableReinitialize
        validationSchema={additionalRegistrationDataFormSchema}
      >
        {() => {
          return (
            <Box>
              <Form>
                <Box flex column className={styles.registerBox}>
                  <Text size={"xLarge"} inline className={styles.title}>
                    {STRINGS.account.registration.title}
                  </Text>
                  <Box flex column className={styles.inputsBox}>
                    <Box flex column>
                      <Field
                        component={TextField}
                        name={"street"}
                        labelText={
                          STRINGS.account.registration.additional
                            .inputStreetLabel
                        }
                        placeholder={
                          STRINGS.account.registration.additional
                            .inputStreetPlaceholder
                        }
                        tabIndex={getTabIndex()}
                      />
                    </Box>
                    <Box flex column>
                      <Field
                        component={TextField}
                        name={"postalCode"}
                        labelText={
                          STRINGS.account.registration.additional
                            .inputPostalCodeLabel
                        }
                        placeholder={
                          STRINGS.account.registration.additional
                            .inputPostalCodePlaceholder
                        }
                        tabIndex={getTabIndex()}
                      />
                    </Box>
                    <Box flex column>
                      <Field
                        component={TextField}
                        name={"city"}
                        labelText={
                          STRINGS.account.registration.additional.inputCityLabel
                        }
                        placeholder={
                          STRINGS.account.registration.additional
                            .inputCityPlaceholder
                        }
                        tabIndex={getTabIndex()}
                      />
                    </Box>
                    <Box flex column>
                      <Field
                        component={DateField}
                        name={"birthday"}
                        labelText={
                          STRINGS.account.registration.additional
                            .inputBirthdayLabel
                        }
                        placeholder={
                          STRINGS.account.registration.additional
                            .inputBirthdayPlaceholder
                        }
                        tabIndex={getTabIndex()}
                      />
                    </Box>
                    <Box flex column>
                      <Field
                        component={TextField}
                        name={"birthPlace"}
                        labelText={
                          STRINGS.account.registration.additional
                            .inputBirthPlaceLabel
                        }
                        placeholder={
                          STRINGS.account.registration.additional
                            .inputBirthPlacePlaceholder
                        }
                        tabIndex={getTabIndex()}
                      />
                    </Box>
                  </Box>
                  <Button
                    type="submit"
                    className={styles.submitButton}
                    primary
                    label={
                      STRINGS.account.registration.additional.finishedButton
                    }
                  />
                </Box>
              </Form>
              <PopUpMessage
                open={displayErrorDialog}
                title={STRINGS.account.registration.error.title}
                message={STRINGS.account.registration.error.message}
                onClose={continueAfterError}
              />
            </Box>
          );
        }}
      </Formik>
    </StepContainer>
  );
};

export default AdditionalRegistrationDataForm;
