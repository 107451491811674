import React, { FunctionComponent } from "react";
import styles from "./RegisterNow.module.scss";
import teaserImage from "gfx/images/startpage/teaser_register_now.png";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { RouteUrls } from "../../../../../config/routes";
import { Button } from "../../../../form-inputs/Button/Button";
import UnstyledLink from "../../../../unstyle-link/UnstyledLink";
import PageSection from "../../../../page-section/PageSection";

const sectionStrings = STRINGS.startpage.registerNow;

export const RegisterNow: FunctionComponent = () => (
  <PageSection background={"blue"} contentClassName={styles.section}>
    <h1>{sectionStrings.title}</h1>
    <div className={styles.subtitle}>{sectionStrings.subTitle}</div>
    <div className={styles.imageBox}>
      <img
        className={styles.img}
        src={teaserImage}
        alt={sectionStrings.altText}
      />
    </div>
    <UnstyledLink to={RouteUrls.register} className={styles.buttonLink}>
      <Button
        label={sectionStrings.registerButtonLabel}
        className={styles.registerNowButton}
      />
    </UnstyledLink>
  </PageSection>
);
