import styles from "components/templates/HausratversicherungVergleichErgebnisTemplate.module.scss";

import React, { FunctionComponent, useState } from "react";
import { Box } from "components/atoms/Box";
import { Button } from "components/form-inputs/Button/Button";
import { Text } from "components/atoms/Text";
import { ToggleSwitch } from "components/form-inputs/ToggleSwitch/ToggleSwitch";
import { FilterBox, FilterBoxOptions } from "components/molecules/FilterBox";
import { InsuranceComparisonBox } from "components/molecules/InsuranceComparisonBox";
import { InsuranceComparisonCustomerInfo } from "components/molecules/InsuranceComparisonCustomerInfo";
import {
  InsuranceComparisonFilterOption,
  InsuranceComparisonFilterOptionName,
} from "components/molecules/InsuranceComparisonFilterOption";
import { InsuranceDataBox } from "components/molecules/InsuranceDataBox";
import { InsuranceOptionCard } from "components/molecules/InsuranceOptionCard";
import UnstyledLink from "components/unstyle-link/UnstyledLink";
import { RouteUrls } from "config/routes";
import { STRINGS } from "language/de_DE/strings";
import { ComparisonOptions } from "types/insuranceComparisonData/ComparisonOptions";
import { HausratVersicherungComparisonResponse } from "types/insuranceComparisonData/Hausratversicherug/HausratVersicherungComparisonResponse";
import {
  InsuranceDeductibleOption,
  InsuranceFilter,
  InsuranceOption,
  InsurancePaymentOption,
} from "types/insuranceComparisonData/Options";

export type InsuranceComparisonResultTemplate = {
  pageTitle: string;
  subtitle?: string;
};

export type HausratversicherungVergleichErgebnisTemplateProperties = {
  templateData: InsuranceComparisonResultTemplate;
  insuranceOptions: Array<InsuranceOption>;
  insuranceFilter: Array<InsuranceFilter>;
  insurancePaymentOption: Array<InsurancePaymentOption>;
  insuranceDeductibleOption: Array<InsuranceDeductibleOption>;
  sortByPrice: Array<FilterBoxOptions>;
  sortByRating: Array<FilterBoxOptions>;
  comparisonResult: HausratVersicherungComparisonResponse;
  comparisonOptions: ComparisonOptions;
  onComparisonInfoChange: (options: ComparisonOptions) => void;
};

export const HausratversicherungVergleichErgebnisTemplate: FunctionComponent<
  HausratversicherungVergleichErgebnisTemplateProperties
> = ({
  templateData,
  insuranceOptions,
  insuranceFilter,
  insurancePaymentOption,
  insuranceDeductibleOption,
  sortByPrice,
  sortByRating,
  comparisonResult,
  comparisonOptions,
  onComparisonInfoChange,
}) => {
  const [options, setOptions] = useState<ComparisonOptions>({
    ...comparisonOptions,
  });

  const handleOptionChange = (
    field: string,
    option: InsurancePaymentOption | InsuranceDeductibleOption
  ) => {
    setOptions({ ...options, [field]: option.value });
  };

  const searchAgain = () => {
    onComparisonInfoChange(options as ComparisonOptions);
  };

  return (
    <Box flex column>
      <Box>
        <Box flex inline>
          <h1 className={styles.title}>
            {templateData.pageTitle} {templateData.subtitle && "- "}
          </h1>
          {templateData.subtitle && (
            <Box className={styles.subtitle}>
              <Text inline size={"xLarge"} bold>
                {templateData.subtitle}
              </Text>
            </Box>
          )}
        </Box>
        <Box>
          <InsuranceComparisonCustomerInfo
            comparisonInfo={{
              birthday: new Date(comparisonOptions.geburtsdatum),
            }}
          />
        </Box>
        <Box flex inline>
          <Box flex column>
            <Box flex column className={styles.optionCardBox}>
              <Text inline size={"small"}>
                {STRINGS.insuranceComparison.insuranceComparisonOptionsHeadline}
              </Text>
              {insuranceOptions.map((option, index) => (
                <InsuranceOptionCard key={index} option={option} />
              ))}
            </Box>
            <Box flex column className={styles.filterBox}>
              <Text size={"small"}>
                {STRINGS.insuranceComparison.insuranceComparisonFilterHeadline}
              </Text>
              <Box flex column className={styles.toggleSwitchBox}>
                {insuranceFilter.map((item, index) => (
                  <ToggleSwitch labelText={item.content} key={index} />
                ))}
              </Box>
            </Box>
            <Box flex column className={styles.filterOptionBox}>
              <Text size={"small"}>
                {
                  STRINGS.insuranceComparison
                    .insuranceComparisonPaymentOptionHeadline
                }
              </Text>
              <Box flex column>
                <InsuranceComparisonFilterOption
                  filterOptions={insurancePaymentOption}
                  contentName={InsuranceComparisonFilterOptionName.ZAHLWEISE}
                  selection={options.zahlweise || "MONATLICH"}
                  onChange={handleOptionChange}
                />
              </Box>
            </Box>
            <Box flex column className={styles.filterOptionBox}>
              <Text size={"small"}>
                {
                  STRINGS.insuranceComparison
                    .insuranceComparisonDeductibleOptionHeadline
                }
              </Text>
              <Box flex column>
                <InsuranceComparisonFilterOption
                  filterOptions={insuranceDeductibleOption}
                  contentName={
                    InsuranceComparisonFilterOptionName.SELBSTBETEILIGUNG
                  }
                  selection={options.selbstbeteiligung || "UNWICHTIG"}
                  onChange={handleOptionChange}
                />
              </Box>
            </Box>
            <Box flex>
              <UnstyledLink
                to={{
                  pathname: RouteUrls.hausratversicherungVergleichErgebnis,
                }}
                state={options}
              >
                <Button
                  label={STRINGS.hausrat.hausratversicherungReStartComparison}
                  type={"submit"}
                  onClick={searchAgain}
                />
              </UnstyledLink>
            </Box>
          </Box>
          <Box flex column>
            <Box flex inline className={styles.sortFilterBox}>
              <Box className={styles.sortFilterBoxTitle}>
                <Text inline size={"small"}>
                  {
                    STRINGS.insuranceComparison
                      .insuranceComparisonSortingHeadline
                  }
                </Text>
              </Box>
              <FilterBox
                inputName={"priceFilter"}
                inputLabel={
                  STRINGS.insuranceComparison.insuranceComparisonSortByPrice
                }
                options={sortByPrice}
                boxClass={styles.listFilter}
              />
              <FilterBox
                inputName={"ratingFilter"}
                inputLabel={
                  STRINGS.insuranceComparison.insuranceComparisonSortByRating
                }
                options={sortByRating}
                boxClass={styles.listFilter}
              />
            </Box>
            {comparisonResult.tarife.map((result, index) => (
              <Box
                flex
                className={styles.insuranceDataBoxComponent}
                key={index}
              >
                <InsuranceDataBox insuranceData={result} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box flex className={styles.insuranceComparisonComponent}>
        <InsuranceComparisonBox />
      </Box>
    </Box>
  );
};
