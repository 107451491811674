import React, { FunctionComponent } from "react";
import styles from "./InsuranceActionBox.module.scss";
import UnstyledLink from "../../../../unstyle-link/UnstyledLink";
import { Button } from "../../../../form-inputs/Button/Button";
import { InsuranceBox } from "../../InsuranceBox/InsuranceBox";

type ActionBoxProps = {
  image: string;
  title: string;
  bullets: string[];
  action: string;
  actionHref: string;
};

export const InsuranceActionBox: FunctionComponent<ActionBoxProps> = ({
  image,
  title,
  bullets,
  action,
  actionHref,
}) => (
  <InsuranceBox image={image} title={title}>
    <div className={styles.boxContent}>
      <ul className={styles.bulletPoints}>
        {bullets.map((bullet) => (
          <li key={bullet}>{bullet}</li>
        ))}
      </ul>

      <UnstyledLink to={actionHref} className={styles.actionButtonLink}>
        <Button className={styles.actionButton} label={action} />
      </UnstyledLink>
    </div>
  </InsuranceBox>
);
