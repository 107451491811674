import React, { ReactElement } from "react";
import { ContractToggleableContent } from "components/user-portal/contract/ContractToggleableContent";
import useUserState from "hooks/useUserState";
import { STRINGS } from "language/de_DE/strings";
import { ProduktKategorie } from "types/backend/ProduktKategorie";
import { usePortalState } from "../../../hooks/usePortalState";

const UserInsuranceContracts = (): ReactElement => {
  const { kunde } = useUserState();
  const { versicherungen } = usePortalState();

  const produktKategories: ProduktKategorie[] = [
    "SACH_UND_HAFTPFLICHTVERSICHERUNGEN",
    "KFZ_VERSICHERUNGEN",
    "KRANKENVERSICHERUNGEN",
    "LEBENS_UND_RENTENVERSICHERUNGEN",
    "SONSTIGE",
  ];
  if (!kunde) return <></>;
  return (
    <ContractToggleableContent
      title={STRINGS.vertrag.header}
      vertraege={versicherungen || []}
      kunde={kunde}
      produktKategories={produktKategories}
    />
  );
};

export default UserInsuranceContracts;
