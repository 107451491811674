import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/auslandsreisekranken_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/auslandsreisekranken_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/auslandsreisekranken_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Reise­kranken­versicherung";

export const auslandsreisekrankenProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Eine Auslands­reise­kranken­versicherung schützt dich bei Auslands­aufenthalten vor hohen Behandlungs­kosten im Falle einer Krank­heit oder eines Unfalls.",

  headerButtons: [
    {
      label: "Vergleichen",
      link: RouteUrls.auslandsreisekrankenversicherungVergleich,
    },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title: "... du im Ausland zum Arzt gehen musst – ambulant und stationär.",
      text: icon1,
    },
    {
      title: "... du im Ausland Arznei oder Heilmittel kaufen musst.",
      text: icon1,
    },
    {
      title: "... du im Ausland geborgen oder transportiert werden musst.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine " + productName + "?",
      paragraphs: [
        "Du bist schon einmal auf einer Reise krank geworden oder musstest von einem Arzt behandelt werden?",
        "Dann weißt du, dass bei Auslands­aufenthalten kaum auf eine Auslands­reise­kranken­versicherung zu verzichten ist – gerade da die gesetzliche Kranken­kasse nur Kosten in jenen Staaten übernimmt, mit welchen einen Sozial­abkommen mit der Bundes­republik Deutschland besteht.",
        "Die Auslands­reise­kranken­versicherung schützt dich somit davor, hohe, unvorhergesehene Krankheits­kosten auf der Reise selbst tragen zu müssen. Außerdem werden Kosten für einen potentiellen, medizinisch notwendigen Rück­transport übernommen.",
      ],
      image: descriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Eine Auslands­reise­kranken­versicherung ist sinnvoll für jeden, der ins EU-Ausland reisen möchte – egal ob Single oder verheiratet, jung oder alt.",
        "Da immer unvorher­sehbare Dinge passieren können, empfehlen wir unabhängig von der Reise­dauer den Abschluss einer Auslands­reise­kranken­versicherung – gerade da es nicht überall üblich ist, dass ein Teil der ärztlichen Behandlungs­kosten erstattet wird.",
      ],

      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Ärztliche Behandlungen",
        "Stationäre Kranken­haus­aufenthalte",
        "Such-, Rettungs- und Bergungs­kosten",
        "Krankenrücktransporte",
        "Schmerz­stillende Zahn­behandlungen",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Kuren oder Reha-Maßnahmen",
        "Vorsorge­untersuchungen",
        "Schwanger­schaften und Geburten (meist) unkonkret",
        "Über­führung im Todes­fall nur bis zu gewisser Höchst­grenze",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],
  link: RouteUrls.auslandsreisekrankenversicherung,
};
