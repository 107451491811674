import React, { ReactElement } from "react";

import type { Props as PageProps } from "components/page/Page";
import Page from "components/page/Page";
import { RouteUrls } from "config/routes";
import { Navigate } from "react-router-dom";
import useUserState from "hooks/useUserState";
import { LOGIN_REDIRECT_STATE } from "../pages/login/LoginPage";
import { useLocation } from "react-router";

type Props = PageProps;

const AuthenticatedPage = (props: Props): ReactElement | null => {
  const { isLoggedIn } = useUserState();
  const location = useLocation();
  // Authentication not yet done
  if (isLoggedIn === null) {
    return null;
  }

  if (isLoggedIn) {
    return <Page {...props} />;
  }

  return (
    <Navigate
      to={RouteUrls.logIn}
      state={{ [LOGIN_REDIRECT_STATE]: location.pathname }}
    />
  );
};

export default AuthenticatedPage;
