import React from "react";
import { RouteUrls } from "config/routes";
import { Route } from "react-router-dom";

import CovomoIframePage from "pages/comovo-iframe/CovomoIframePage";
import { STRINGS } from "language/de_DE/strings";

const covomoLinks = [
  {
    routeUrl: RouteUrls.mopedversicherungVergleich,
    title: STRINGS.covomoLinkName.mopedVersicherungVergleich,
    covomoLink:
      "https://www.covomo.de/rollerversicherung-vergleich/?r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D&type_of_vehicle_scooter=1",
  },
  {
    routeUrl: RouteUrls.fahrradversicherungVergleich,
    title: STRINGS.covomoLinkName.fahrradVersicherungVergleich,
    covomoLink:
      "https://www.covomo.de/fahrradversicherung-vergleich/?r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D",
  },
  {
    routeUrl: RouteUrls.gewaesserschadenhaftpflichtVergleich,
    title: STRINGS.covomoLinkName.gewaesserschadenhaftpflichtVergleich,
    covomoLink:
      "https://www.covomo.de/gewaesserschadenhaftpflicht-vergleich/?r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D",
  },
  {
    routeUrl: RouteUrls.jagdhaftpflichtVergleich,
    title: STRINGS.covomoLinkName.jagdhaftpflichtVergleich,
    covomoLink:
      "https://www.covomo.de/jagdhaftpflicht-vergleich/?r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D",
  },

  {
    routeUrl: RouteUrls.reiseVersicherungVergleich,
    title: STRINGS.covomoLinkName.reiseVersicherungVergleich,
    covomoLink:
      "https://reisevergleich.covomo.de/?product_subtypes=2,3,4,6&r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D&theme=embedded&direction=2&age=40&value=500&purpose=3&contract_type=2",
  },
  {
    routeUrl: RouteUrls.auslandsreisekrankenversicherungVergleich,
    title: STRINGS.covomoLinkName.reiseVersicherungVergleich,
    covomoLink:
      "https://reisevergleich.covomo.de/?product_subtypes=6&r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D&theme=embedded&direction=2&contract_type=1&travel_persons=1&destination_region=1",
  },
  {
    routeUrl: RouteUrls.reiseruecktrittsversicherungVergleich,
    title: STRINGS.covomoLinkName.reiseVersicherungVergleich,
    covomoLink:
      "https://reisevergleich.covomo.de/?product_subtypes=3,4&r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D&theme=embedded&direction=2&age=40&value=500&purpose=3&contract_type=2",
  },
  {
    routeUrl: RouteUrls.reisegepaeckversicherungVergleich,
    title: STRINGS.covomoLinkName.reiseVersicherungVergleich,
    covomoLink:
      "https://reisevergleich.covomo.de/?product_subtypes=2&r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D&theme=embedded&direction=2&contract_type=2&travel_persons=1&destination_region=1",
  },
  {
    routeUrl: RouteUrls.erntehelferkrankenVergleich,
    title: STRINGS.covomoLinkName.erntehelferkrankenVergleich,
    covomoLink:
      "https://www.covomo.de/erntehelfer-app/?r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D",
  },

  {
    routeUrl: RouteUrls.pferdehaftpflichtVergleich,
    title: STRINGS.covomoLinkName.pferdehaftpflichtVergleich,
    covomoLink:
      "https://www.covomo.de/pferdehaftpflichtversicherung-vergleich/?r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D",
  },
  {
    routeUrl: RouteUrls.hundehaftpflichtVergleich,
    title: STRINGS.covomoLinkName.hundehaftpflichtVergleich,
    covomoLink:
      "https://www.covomo.de/hundehaftpflichtversicherung-app/?r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D",
  },
  {
    routeUrl: RouteUrls.drohnenversicherungVergleich,
    title: STRINGS.covomoLinkName.drohnenversicherungVergleich,
    covomoLink:
      "https://www.covomo.de/drohnen-versicherung-vergleich/?r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D",
  },

  {
    routeUrl: RouteUrls.langzeitreisekrankenversicherungVergleich,
    title: STRINGS.covomoLinkName.langzeitreisekrankenversicherungVergleich,
    covomoLink:
      "https://reisevergleich.covomo.de/?r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D&theme=embedded&direction=2&age=23&value=500&purpose=4&contract_type=2&product_subtypes=6&duration=120",
  },
  {
    routeUrl: RouteUrls.sterbegeldversicherungVergleich,
    title: STRINGS.covomoLinkName.sterbegeldversicherungVergleich,
    covomoLink:
      "https://www.covomo.de/sterbegeldversicherung-vergleich/?r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D",
  },
  {
    routeUrl: RouteUrls.hochzeitsversicherungVergleich,
    title: STRINGS.covomoLinkName.hochzeitsversicherungVergleich,
    covomoLink:
      "https://www.covomo.de/hochzeitsversicherung/?r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D",
  },
  {
    routeUrl: RouteUrls.mietkautionsversicherungVergleich,
    title: STRINGS.covomoLinkName.mietkautionsversicherungVergleich,
    covomoLink:
      "https://www.covomo.de/mietkautionsversicherung-vergleich/?r=eyJhZmZpbGlhdGVfaWQiOiI1OTE0OTA2NDU1IiwiYSI6IjU5MTQ5MDY0NTUifQ%3D%3D",
  },
];

const generateComovoRoutes = () => {
  return covomoLinks.map((link) => (
    <Route
      path={link.routeUrl}
      key={link.routeUrl}
      element={
        <CovomoIframePage covomoLink={link.covomoLink} title={link.title} />
      }
    />
  ));
};

export const comovoRoutes = generateComovoRoutes();
