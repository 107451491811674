import styles from "components/organisms/ForgotPasswordCodeForm.module.scss";

import React, { ReactElement, useContext } from "react";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";
import AuthenticationCodeInput from "components/molecules/AuthenticationCodeInput";
import StepContainer from "components/step-wizard/components/StepContainer";
import ForgotPasswordWizardContext from "components/step-wizard/forgot-password/ForgotPasswordWizardContext";
import { STRINGS } from "language/de_DE/strings";

export type ForgotPasswordCodeFormProperties = {
  isActive: boolean;
  previousStep?: () => void;
  nextStep?: () => void;
};

const ForgotPasswordCodeForm = ({
  isActive,
  nextStep,
}: ForgotPasswordCodeFormProperties): ReactElement => {
  const { setState: updateForgotPasswordState, state: forgotPasswordState } =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(ForgotPasswordWizardContext)!;
  const amountDigits = 6;

  const handleForgotPasswordConfirmChange = async (code: string) => {
    if (code.length !== amountDigits) {
      return;
    }

    updateForgotPasswordState({ ...forgotPasswordState, code });
    nextStep?.();
  };

  return (
    <StepContainer>
      <Text size={"large"} inline className={styles.introductionText}>
        {STRINGS.account.forgotPasswordCode.introduction1}{" "}
        {forgotPasswordState.awsResult?.DeliveryMedium === "SMS"
          ? STRINGS.account.forgotPasswordCode.introductionSMS
          : STRINGS.account.forgotPasswordCode.introductionEmail}{" "}
        {STRINGS.account.forgotPasswordCode.introduction2}
      </Text>

      <Box flex column className={styles.content}>
        <Text size={"xLarge"} inline className={styles.headline}>
          {STRINGS.account.forgotPasswordCode.title}
        </Text>

        <div className={styles.forgotPasswordCodeInputContainer}>
          <AuthenticationCodeInput
            amountDigits={amountDigits}
            onAuthenticationCodeChange={handleForgotPasswordConfirmChange}
            focusFirstInput={isActive}
          />
        </div>
      </Box>
    </StepContainer>
  );
};

export default ForgotPasswordCodeForm;
