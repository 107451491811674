import { VertragTyp } from "../../../../../../types/backend/VertragTyp";
import * as yup from "yup";
import { STRINGS } from "../../../../../../language/de_DE/strings";

export const validateNumber = async (value: any, message?: string) => {
  if (value === "") return;
  const isNumber = await yup.number().isValid(value);
  if (!isNumber) return message || STRINGS.formInputs.numberError;
};

export const validateRequiredOnTypes = (
  vertragTypes: VertragTyp[],
  message: string,
  vertragTyp: VertragTyp | "",
  value?: string | number
) => {
  if (!vertragTyp) return;
  if (!value && vertragTypes.includes(vertragTyp)) return message;
};
