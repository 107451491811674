import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import DescriptionImage0 from "gfx/images/insurance-images/wohngebaeude_0.jpg";
import DescriptionImage1 from "gfx/images/insurance-images/wohngebaeude_1.jpg";
import DescriptionImage2 from "gfx/images/insurance-images/wohngebaeude_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Wohn­gebäude­versicherung";

export const wohngebaeudeProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: DescriptionImage0,
  introduction:
    "Manchmal genügt ein heftiges Unwetter - und der Familie fehlt das Dach über dem Kopf. Eine Wohngebäudeversicherung schützt dich vor dem finanziellen Ruin und erstattet Kosten für Reparaturarbeiten bis hin zum Wiederaufbau deines Gebäudes.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title:
        "... Schäden am Gebäude durch Feuer, Leitungswasser, Blitz, Hagel oder Sturm verursacht wurden.",
      text: icon1,
    },
    {
      title:
        "... aufgrund eines Schadens dein Gebäude unbewohnbar wird und Kosten für die Unterbringung anfallen.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine Wohngebäudeversicherung?",
      paragraphs: [
        "Die Wohngebäudeversicherung schützt den Eigentümer eines Hauses vor den finanziellen Folgen eines Sachschadens. Versichert ist das gesamte Gebäude einschließlich aller fest eingebauten Gegenstände.",
      ],
      image: DescriptionImage1,
    },
    {
      title: "Wer braucht eine Wohngebäudeversicherung?",
      paragraphs: [
        "Jeder Immobilienbesitzer sollte eine Wohngebäudeversicherung abschließen. Eine Versicherungspflicht gibt es seit dem Jahr 1994 nicht mehr.",
      ],

      image: DescriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Kosten für Schäden am Wohngebäude",
        "Kosten für Folgeschäden wie bei Überspannung",
        "Kosten für Anbauten wie Garage oder Gartenhäuschen",
        "Aufräum- und Wiederherstellungsarbeiten außerhalb deines Gebäudes",
        "Kosten für Unterbringung nach einem Schaden",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Schäden, welche vorsätzlich herbeigeführt wurden",
        "Schäden an beweglichen Sachen",
        "Schäden an gemieteten, gepachteten Gebäuden",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  checklist: [
    {
      title: "Versicherungssumme",
      text: "Als Hausbesitzer versicherst  du  deine  Immobilie nicht mit einer fest vereinbarten Versicherungssumme in Euro, sondern vereinbarst  die Risikodeckung in der Regel zum gleitenden Neuwert. Diese Vorgehensweise stellt sicher, dass deine  Immobilie trotz Inflationseffekten und laufend steigenden Baukosten ausreichend versichert ist. Dazu berechnen die Versicherer die Versicherungssumme als fiktiven Wert 1914 in Mark. Über den jährlich ermittelten Baupreisindex lässt sich der Wert 1914  auf die Höhe der aktuellen Baukosten umrechnen.",
    },
    {
      title: "Elementarschadenversicherung",
      text: "Im Rahmen der Gebäudeversicherung besteht die Möglichkeit neben den üblichen Gefahren Brand, Blitzschlag, Explosion, Implosion, Leitungswasser, Rohrbruch, Frost, Sturm, Hagel etc.  zusätzlich gegen Elementarschäden vorzusorgen. Die Elementarschadenversicherung kann dann Schäden am Gebäude abdecken, welche zum Beispiel durch Überschwemmungen  durch Starkregen, Hochwasser, Rückstau, Schneedruck, Erdbeben, Lawinen, Vulkanausbrüche, Erdrutsch verursacht wurden.",
    },
    {
      title: "Photovoltaikanlage",
      text: "Die Photovoltaikanlage kann entweder über die Gebäudeversicherung oder über eine eigenständige Police geschützt werden. Wird darüber Strom ins öffentliche Netz eingespeist, empfiehlt sich zudem eine Absicherung des Ertragsausfalls. Das schützt vor dem Risiko finanzieller Einbußen  infolge eines versicherten Sachschadens.",
    },
  ],

  link: RouteUrls.wohngebaeudeversicherung,
};
