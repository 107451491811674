import axios from "axios";
import api from "api/index";
import { appConfig } from "config/appConfig";
import { KundeResponse } from "types/backend/KundeResponse";
import { isOk } from "utils/axiosHelpers";

const fetchKunde = async (): Promise<KundeResponse> => {
  const url = `${appConfig.apiUrl}/api/kunden/self`;
  const header = await api.getHeaderWithAuthorization();
  const result = await axios.get<KundeResponse>(url, {
    headers: header,
    validateStatus: isOk,
  });
  return result.data;
};
export default fetchKunde;
