import React, { ReactElement } from "react";

import Page from "components/page/Page";
import RegistrationStepWizard from "components/step-wizard/registration/RegistrationStepWizard";
import { RouteUrls } from "config/routes";
import useAsyncEffect from "hooks/useAsyncEffect";
import useUserLogin from "hooks/useUserLogin";
import { useNavigate } from "react-router-dom";
import PageSection from "../../components/page-section/PageSection";

const RegisterPage = (): ReactElement => {
  const { isLoggedIn } = useUserLogin();
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    if (await isLoggedIn()) {
      navigate(RouteUrls.userPortalStart);
    }
  });

  return (
    <Page>
      <PageSection>
        <RegistrationStepWizard />
      </PageSection>
    </Page>
  );
};

export default RegisterPage;
