import styles from "./HausratversicherungAbschlussStep2Form.module.scss";

import React, { ChangeEvent, forwardRef } from "react";
import { Field, Form, Formik, FormikProps } from "formik";
import moment from "moment";
import { Box } from "components/atoms/Box";
import { Checkbox } from "components/form-inputs/Checkbox/Checkbox";
import { Text } from "components/atoms/Text";
import { FilterBox } from "components/molecules/FilterBox";
import { STRINGS } from "language/de_DE/strings";
import { hausratTarifeComparisonStep2Schema } from "utils/validators/hausrat/hausratTarifeComparisonStep2";
import { TextField } from "../../../components/form-inputs/TextField/TextField";
import { DateField } from "../../../components/form-inputs/DateField/DateField";

// eslint-disable-next-line @typescript-eslint/ban-types
type HausratversicherungAbschlussStep2FormProperties = {};

export type Step2FormType = {
  schaden: string | undefined;
  damageAmount: number;
  damages: Array<string>;
  startDate: string | undefined;
  startDateInput: string;
};

export const HausratversicherungAbschlussStep2Form = forwardRef<
  FormikProps<Step2FormType>,
  HausratversicherungAbschlussStep2FormProperties
  // eslint-disable-next-line no-empty-pattern
>(({}, reference) => {
  const initialValues: Step2FormType = {
    schaden: undefined,
    damageAmount: 0,
    damages: [],
    startDate: undefined,
    startDateInput: moment().format("YYYY-MM-DD"),
  };

  return (
    <Box className={styles.formBox}>
      <Text size={"xLarge"} inline className={styles.headline}>
        {
          STRINGS.insuranceAbschluss.previousInsuranceStep
            .headlinePreviousDamage
        }
      </Text>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        enableReinitialize={true}
        innerRef={reference}
        validationSchema={hausratTarifeComparisonStep2Schema}
      >
        {(properties) => (
          <Form>
            <Box flex column>
              <Text size={"medium"}>
                {
                  STRINGS.insuranceAbschluss.previousInsuranceStep
                    .sublinePreviousDamage
                }
              </Text>
              <Box flex column className={styles.checkboxBox}>
                <Field
                  component={Checkbox}
                  name={"schadenFalse"}
                  inputClass={styles.radio}
                  boxClass={styles.checkboxItem}
                  style={"rounded"}
                  radioGroupName={"schaden"}
                  radio
                  labelText={
                    STRINGS.insuranceAbschluss.previousInsuranceStep
                      .previousDamageNo
                  }
                  value={"false"}
                />
              </Box>
              <Box flex column className={styles.checkboxBox}>
                <Field
                  component={Checkbox}
                  name={"schadenTrue"}
                  inputClass={styles.radio}
                  boxClass={styles.checkboxItem}
                  style={"rounded"}
                  radioGroupName={"schaden"}
                  radio
                  labelText={
                    STRINGS.insuranceAbschluss.previousInsuranceStep
                      .previousDamageYes
                  }
                  value={"true"}
                />
              </Box>
              {properties.values.schaden === "true" && (
                <Box flex column className={styles.previousDamageBoxWrapper}>
                  <Text size={"medium"} inline>
                    {
                      STRINGS.insuranceAbschluss.previousInsuranceStep
                        .previousDamageCount
                    }
                  </Text>
                  <Box flex className={styles.previousDamageBox}>
                    <Field
                      name={"damageAmount"}
                      inputName={"damageAmount"}
                      as={FilterBox}
                      inputLabel={
                        STRINGS.insuranceAbschluss.previousInsuranceStep
                          .previousDamageInputLabel
                      }
                      options={[
                        { id: "1", name: "1" },
                        { id: "2", name: "2" },
                        { id: "3", name: "3" },
                        { id: "4", name: "4" },
                        { id: "5", name: "5" },
                      ]}
                      withLabelReplace
                      boxClass={styles.filterBoxBox}
                      contentPosition={"left"}
                      onChange={properties.handleChange}
                      withFormik
                    />
                  </Box>
                  {properties.values.damageAmount > 0 && (
                    <Text size={"medium"} inline>
                      {
                        STRINGS.insuranceAbschluss.previousInsuranceStep
                          .previousDamageYearSum
                      }
                    </Text>
                  )}
                  {Array.from(
                    { length: properties.values.damageAmount },
                    (_, i) => (
                      <Box flex key={i} className={styles.previousDamageBox}>
                        <Field
                          name={`damages[${i}].year${i}`}
                          inputName={`damages[${i}].year${i}`}
                          as={FilterBox}
                          inputLabel={
                            STRINGS.insuranceAbschluss.previousInsuranceStep
                              .previousDamageYearInputLabel
                          }
                          options={[
                            { id: "1", name: moment().year() },
                            { id: "2", name: moment().year() - 1 },
                            { id: "3", name: moment().year() - 2 },
                            { id: "4", name: moment().year() - 3 },
                            { id: "5", name: moment().year() - 4 },
                          ]}
                          withLabelReplace
                          boxClass={styles.filterBoxBox}
                          contentPosition={"left"}
                          onChange={(e: ChangeEvent<unknown>) =>
                            properties.handleChange(e)
                          }
                          withFormik
                        />
                        <Field
                          component={TextField}
                          name={`damages[${i}].amount`}
                          placeholder={
                            STRINGS.insuranceAbschluss.previousInsuranceStep
                              .previousDamageAmountPlaceholder
                          }
                          inputClassName={styles.amountInYear}
                        />
                      </Box>
                    )
                  )}
                </Box>
              )}
              <Text size={"xLarge"} inline className={styles.headline}>
                {
                  STRINGS.insuranceAbschluss.previousInsuranceStep
                    .headlineInsuranceBegin
                }
              </Text>
              <Box flex column className={styles.checkboxBox}>
                <Field
                  component={Checkbox}
                  name={"startNow"}
                  inputClass={styles.radio}
                  boxClass={styles.checkboxItem}
                  style={"rounded"}
                  radioGroupName={"startDate"}
                  radio
                  labelText={
                    STRINGS.insuranceAbschluss.previousInsuranceStep
                      .insuranceBeginNow
                  }
                  value={"true"}
                />
              </Box>
              <Box flex column className={styles.checkboxBox}>
                <Field
                  component={Checkbox}
                  name={"startToDate"}
                  inputClass={styles.radio}
                  boxClass={styles.checkboxItem}
                  radioGroupName={"startDate"}
                  radio
                  labelText={
                    STRINGS.insuranceAbschluss.previousInsuranceStep
                      .insuranceBeginToDate
                  }
                  value={"false"}
                />
              </Box>
              {properties.values.startDate === "false" && (
                <Field
                  component={DateField}
                  name={"startDateInput"}
                  inputClassName={styles.dateField}
                  placeholder={
                    STRINGS.insuranceAbschluss.previousInsuranceStep
                      .insuranceBeginInputPlaceholder
                  }
                />
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
});

HausratversicherungAbschlussStep2Form.displayName =
  "HausratversicherungAbschlussStep2Form";
