import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import DescriptionImage0 from "gfx/images/insurance-images/hausrat_0.jpg";
import DescriptionImage1 from "gfx/images/insurance-images/hausrat_1.jpg";
import DescriptionImage2 from "gfx/images/insurance-images/hausrat_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Haus­rat­versicherung";

export const hausratProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: DescriptionImage0,

  introduction:
    "Eine Hausratversicherung empfiehlt sich dann für dich, wenn du deinen ersten eigenen Haushalt gründen oder in eine Wohnung ziehen und deinen Besitz, wie zum Beispiel Möbel oder elektrische Geräte, absichern möchtest.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title: "... es in deiner Wohnung brennt und deine Möbel Schaden nehmen.",
      text: icon1,
    },
    {
      title: "... jemand bei dir einbricht und dein Eigentum entwendet.",
      text: icon1,
    },
    {
      title:
        "... du einen Wasserschaden hast und deine elektronischen Geräte kaputt gehen.",
      text: icon1,
    },
    {
      title:
        "... ein Blitz einschlägt und dein Fernseher und Computer aufgrund der Überspannung beschädigt werden.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine " + productName + "?",
      paragraphs: [
        "Eine Hausratversicherung versichert dein gesamtes bewegliches Eigentum, welches sich innerhalb deiner eigenen vier Wände befindet – also deinen Hausrat.",
        "Zu den typischen Beispielen gehören Möbel, Kleidung, elektronische Geräte, Bücher, Teppiche, Lampen, Werkzeug.",
        "Deine zu zahlende Versicherungsprämie wird dann durch weitere Faktoren, wie die gewählten Zusatzbausteine, das Leistungspaket deines gewählten Anbieters und besonders wertvolle Einrichtungsgegenstände und Wertsachen beeinflusst.",
      ],
      image: DescriptionImage1,
    },
    {
      title: "Wer braucht eine  " + productName + "?",
      paragraphs: [
        "Eine Hausratversicherung ist grundsätzlich jedem Haushalt zu empfehlen.",
        "Wohnst du mit jemandem zusammen, könnt ihr euch eine Hausratversicherung teilen, da eine Hausratversicherung   stets das gesamte bewegliche Eigentum eines Haushalts bis zur versicherten Höchstgrenze absichert.",
      ],

      image: DescriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Schäden durch Feuer, Blitzschlag, Einbruchdiebstahl/Raub, Sturm und Hagel, Leitungswasser, Überspannung",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Schäden, welche vorsätzlich herbeigeführt wurden",
        "die volle Schadenshöhe bei Fahrlässigkeit",
        "die volle Schadenshöhe bei Unterdeckung",
      ],
    },
  ],

  actionBoxes: [
    {
      title: "Baustein: Glasbruch",
      bullets: [
        {
          text: "Der Baustein Glasbruch versichert je nach Versicherer Glasschäden im Hausrat (z.B. Glasoberflächen von Tischen, Induktionsflächen und Duschkabinen) oder am Gebäude.",
        },
        {
          text: "Achte insbesondere beim Gebäudeglasschutz darauf, ob deine Wohngebäudeversicherung nicht bereits Glasschäden an Fenstern, Türen und Balkonen beinhaltet, um unnötige Kosten zu vermeiden.",
        },
      ],
      image: icon1,
      action: "Jetzt Angebot einholen",
      actionHref: RouteUrls.notFound,
    },
    {
      title: "Baustein: Fahrraddiebstahl",
      bullets: [
        {
          text: "Fahrräder sind meistens nur anteilig mit in der Hausratversicherung enthalten und übersteigen daher schnell den Basis-Versicherungsschutz.",
        },
        {
          text: "Wenn du ein besonders teures Fahrrad oder E-Bike hast, solltest du auf eine Zusatzversicherung achten. ",
        },
      ],
      image: icon1,
      action: "Jetzt Angebot einholen",
      actionHref: RouteUrls.notFound,
    },
    {
      title: "Baustein: Extremwetter",
      bullets: [
        {
          text: "Während Schäden durch Leitungswasser heute eigentlich zum Basisschutz gehören, sind Schäden durch Extremwetter wie Starkregen und Überschwemmungen nicht enthalten. ",
        },
        {
          text: "Wohnst du in gefährdeten Gebieten oder warst bereits von derartigen Schäden betroffen, kann sich der Zusatzbaustein für dich lohnen.",
        },
      ],
      image: icon1,
      action: "Jetzt Angebot einholen",
      actionHref: RouteUrls.notFound,
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  checklist: [
    {
      title: "Sind Fahrräder ebenfalls versichert?",
      text: "Fahrräder sind auch heute noch nicht immer im Versicherungsschutz deiner Hausratversicherung enthalten. Daher solltest du bei der Wahl deiner Versicherung stets prüfen, ob dein Fahrrad mitversichert ist – insbesondere bei teuren E-Bikes.",
    },
    {
      title: "Was bedeutet Unterversicherung?",
      text: "Wenn der Wert deines versicherten Hausrats größer als die Versicherungssumme ist, liegt eine Unterversicherung vor. Im Schadenfall heißt das, dass die Versicherung den entstandenen Schaden nur anteilig deckt. Einige Versicherer bieten zwar einen Unterversicherungsverzicht an und zahlen damit stets bis zur vereinbarten Versicherungssumme – alle darüber hinaus entstandenen Kosten musst du jedoch selbst tragen. Achte daher darauf, dass du über einen ausreichenden Versicherungsschutz verfügst.",
    },
    {
      title: "Sind nur Sachen innerhalb des Hauses versichert?",
      text: "Eine Außenversicherung beschreibt den Versicherungsschutz deines Hausrats außerhalb deiner eigenen vier Wände. Voraussetzung ist, dass der Hausrat nur temporär aus der Wohnung entfernt wurde, z. B. wenn du im Urlaub bist. Einige Versicherer bieten standardmäßig eine Außenversicherung mit an – manchmal musst du hierfür zusätzliche Kosten in Kauf nehmen. Prüfe daher stets, ob der anteilige Versicherungsschutz ausreicht.",
    },
    {
      title:
        "Wie können besonders wertvolle Gegenstände und Wertsachen versichert werden?",
      text: "Hast du besonders wertvolle Gegenstände wie Münzen, Schmucksachen, Kunst und Bargeld in deiner Wohnung, zählen diese mit zum Hausrat. Die meisten Versicherer setzen dabei auf Höchstgrenzen, sodass lediglich 20 % des Versicherungsschutzes maximal auf Wertsachen entfallen können. Achte daher vor Abschluss der Versicherung auf deine gewählte Versicherungssumme und schließe ggf. eine Zusatzversicherung für deine Wertsachen ab.",
    },
  ],

  link: RouteUrls.hausratversicherung,
};
