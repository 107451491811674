import { isAdditionalRegistrationDataValid } from "utils/validators/auth/registrationForm";
import { KundeResponse } from "../types/backend/KundeResponse";

export const isRegistrationDataValid = (kunde: KundeResponse | undefined) => {
  if (!kunde) {
    return true;
  }
  if (!kunde.adressen || !kunde.adressen[0]) {
    return false;
  }
  const { strasse, ort, plz } = kunde.adressen[0];
  return isAdditionalRegistrationDataValid({
    street: strasse,
    city: ort,
    postalCode: plz,
    birthday: kunde.geb || "",
    birthPlace: kunde.gebOrt || "",
  });
};
