import styles from "components/user-portal/user-profile/ProfileCard.module.scss";

import React, { ReactNode, useEffect, useState } from "react";
import appBreakpoints from "config/pageBreakpoints";
import useMatchMedia from "hooks/useMatchMedia";
import useUserState from "hooks/useUserState";
import AccordionBox from "../../accordion/AccordionBox";
import { ReactComponent as EditIcon } from "gfx/icons/clipboardText.svg";

interface ProfileCardProps {
  title: string;
  onEdit?: () => void;
  className?: string;
  children?: ReactNode;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  title,
  onEdit,
  className,
  children,
}) => {
  const { kunde } = useUserState();
  const isMobile = useMatchMedia(`(max-width: ${appBreakpoints.md})`);
  const [accordionOpen, setAccordionOpen] = useState(true);

  useEffect(() => {
    if (!isMobile) {
      setAccordionOpen(true);
    }
  }, [isMobile]);

  const handleHeaderClick = () => {
    if (!isMobile) {
      return;
    }
    setAccordionOpen(!accordionOpen);
  };

  const handleEditClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (onEdit) onEdit();
  };

  return (
    <div className={className}>
      {kunde && (
        <AccordionBox
          title={title}
          actionIcon={
            onEdit ? (
              <a
                role="button"
                href="#"
                className={styles.editButton}
                onClick={handleEditClick}
              >
                <EditIcon />
              </a>
            ) : (
              <></>
            )
          }
          isOpen={accordionOpen}
          onHeaderClick={handleHeaderClick}
          toggleable={isMobile}
          styleClasses={{
            accordionClass: isMobile
              ? styles.accordionMobile
              : styles.accordion,
            titleClass: styles.title,
            contentClass: styles.content,
          }}
        >
          {children}
        </AccordionBox>
      )}
    </div>
  );
};

export default ProfileCard;
