import React, { ReactElement } from "react";
import styles from "./DemandCalculatorInputForm.module.scss";
import {
  DemandCalculatorInputField,
  DemandCalculatorSelectField,
} from "./DemandCalculatorInputField";
import { STRINGS } from "../../../language/de_DE/strings";
import { DateField } from "../../form-inputs/DateField/DateField";
import { earliestBirthday } from "../useDemandCalculatorFormValues";
import { NumberField } from "../../form-inputs/NumberField/NumberField";
import { createOptions } from "../../../utils/formInputUtils";
import { FormikProps } from "formik";
import { DemandCalculatorInputFormType } from "./DemandCalculatorInputFormType";

const familienstandOptions = createOptions(
  STRINGS.profile.personalData.maritalStatusTypes
);
const wohnsituationOptions = createOptions(
  STRINGS.profile.personalData.livingSituationTypes
);

interface DemandCalculatorInputFormPersonalProps {
  formProps: FormikProps<DemandCalculatorInputFormType>;
}

export const DemandCalculatorInputFormPersonal = ({
  formProps,
}: DemandCalculatorInputFormPersonalProps): ReactElement => {
  return (
    <div className={styles.formGrid}>
      <DemandCalculatorInputField
        name="geburtstag"
        label={STRINGS.demandCalculator.personal.birthday}
        component={DateField}
        maxDate={earliestBirthday}
      />
      <DemandCalculatorSelectField
        name="familienstand"
        label={STRINGS.demandCalculator.personal.maritalStatus}
        options={familienstandOptions}
        withNoneOption
      />
      <DemandCalculatorInputField
        name="anzahlKinder"
        label={STRINGS.demandCalculator.personal.numberOfChildren}
        component={NumberField}
        integersOnly
      />
      <DemandCalculatorSelectField
        name="wohnsituation"
        label={STRINGS.demandCalculator.personal.livingSituation}
        options={wohnsituationOptions}
        withNoneOption
      />
      <DemandCalculatorInputField
        name="wohnflaeche"
        label={STRINGS.demandCalculator.personal.livingSpace}
        component={NumberField}
      />
      <DemandCalculatorSelectField
        name="fremdgenutzteImmobilien"
        label={STRINGS.demandCalculator.personal.rentedProperty}
        booleanSelect
      />
      {formProps.values.fremdgenutzteImmobilien && (
        <>
          <DemandCalculatorInputField
            name="immobilieBaujahr"
            label={STRINGS.demandCalculator.personal.yearOfConstruction}
            component={NumberField}
            integersOnly
          />
          <DemandCalculatorInputField
            name="neubauSummeImmobilie"
            label={STRINGS.demandCalculator.personal.initialConstructionCosts}
            component={NumberField}
            integersOnly
          />
        </>
      )}
    </div>
  );
};
