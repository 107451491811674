import styles from "components/form-inputs/Button/Button.module.scss";

import React, { FunctionComponent, ReactNode } from "react";
import clsx from "clsx";

export type ButtonProperties = {
  label: string;
  primary?: boolean;
  backgroundColor?: string;
  className?: string;
  id?: string;
  children?: ReactNode;
  type?: "button" | "submit";
  onClick?: () => void;
  form?: string;
  disabled?: boolean;
};

export const Button: FunctionComponent<ButtonProperties> = ({
  label,
  primary = true,
  backgroundColor,
  className,
  id,
  children,
  type = "button",
  onClick,
  form,
  disabled,
  ...properties
}) => {
  return (
    <button
      type={type}
      className={clsx(primary && styles.btnPrimary, className)}
      style={{ backgroundColor }}
      id={id}
      {...properties}
      onClick={onClick}
      form={form}
      disabled={disabled}
    >
      {label}
      {children}
    </button>
  );
};
