import React from "react";
import { UserInvestmentContracts } from "components/user-portal/user-investments/UserInvestmentContracts";
import UserPortal from "components/user-portal/UserPortal";

const UserInvestments: React.FC = () => {
  return (
    <UserPortal>
      <UserInvestmentContracts />
    </UserPortal>
  );
};

export default UserInvestments;
