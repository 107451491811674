import styles from "components/molecules/InsuranceComparisonFilterOption.module.scss";

import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";
import {
  InsuranceDeductibleOption,
  InsurancePaymentOption,
} from "types/insuranceComparisonData/Options";

export type InsuranceComparisonFilterOptionProperties = {
  filterOptions: Array<InsurancePaymentOption | InsuranceDeductibleOption>;
  contentName: InsuranceComparisonFilterOptionName;
  selection: string | undefined;
  onChange: (
    field: string,
    value: InsurancePaymentOption | InsuranceDeductibleOption
  ) => void;
};

export enum InsuranceComparisonFilterOptionName {
  ZAHLWEISE = "zahlweise",
  SELBSTBETEILIGUNG = "selbstbeteiligung",
}

export const InsuranceComparisonFilterOption: FunctionComponent<
  InsuranceComparisonFilterOptionProperties
> = ({ filterOptions, contentName, selection, onChange }) => {
  const handleSelectionChange = (
    option: InsurancePaymentOption | InsuranceDeductibleOption
  ) => {
    onChange(contentName, option);
  };

  return (
    <Box flex className={styles.filterBox}>
      {filterOptions.map((option, index) => (
        <label className={clsx(styles.item)} tabIndex={index} key={index}>
          <input
            type={"radio"}
            name={contentName}
            onChange={() => handleSelectionChange(option)}
            checked={selection === option.value}
          />
          <span className={styles.spanWrapper} />
          <Box className={styles.textWrapper}>
            <Text size={"xSmall"}>{option.content}</Text>
          </Box>
        </label>
      ))}
    </Box>
  );
};
