import { Amplify } from "aws-amplify";
import { appConfig } from "config/appConfig";

const configureAwsCognito = (): void => {
  Amplify.configure({
    aws_cognito_region: appConfig.region,
    aws_user_pools_id: appConfig.userPoolId,
    aws_user_pools_web_client_id: appConfig.webClientId,
  });
};

export default configureAwsCognito;
