import React, { FunctionComponent } from "react";
import "@brainhubeu/react-carousel/lib/style.css";
import Carousel, {
  centeredPlugin,
  infinitePlugin,
  slidesToShowPlugin,
} from "@brainhubeu/react-carousel";
import styles from "./InsuranceCompanyCarousel.module.scss";
import { STRINGS } from "../../../language/de_DE/strings";
import { InsuranceCompanySlide } from "../../../content/productTreeDefinition";
import PageSection from "../../page-section/PageSection";

const sectionStrings = STRINGS.insurancePage.companyCarousel;

type InsuranceSlideProps = InsuranceCompanySlide;

type InsuranceCompanyCarouselProps = {
  insurances: InsuranceSlideProps[];
};

const InsuranceSlide: FunctionComponent<InsuranceSlideProps> = ({
  companyName,
  image,
}) => (
  <img
    src={image}
    alt={companyName}
    title={companyName}
    className={styles.companyLogo}
  />
);

export const InsuranceCompanyCarousel: FunctionComponent<
  InsuranceCompanyCarouselProps
> = ({ insurances }) => {
  return (
    <PageSection>
      <h1 className={styles.title}>{sectionStrings.title}</h1>
      <div className={styles.subtitle}>{sectionStrings.subTitle}</div>
      <Carousel
        slides={insurances.map((insurance) => (
          <InsuranceSlide {...insurance} key={insurance.companyName} />
        ))}
        offset={10}
        itemWidth={221}
        plugins={[
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 3,
            },
          },
          {
            resolve: centeredPlugin,
          },
          {
            resolve: infinitePlugin,
          },
        ]}
      />
    </PageSection>
  );
};
