import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/motorrad_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/motorrad_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/motorrad_2.jpg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Motorradversicherung";

export const motorradProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Eine Motorradhaftpflicht­versicherung schützt dich, wenn du mit deinem Bike einen Unfall verur­sachst, durch den Dritten Schäden entstehen. Die Versicherung ist in Deutschland für Motorradbesitzer gesetzlich vorgeschrieben.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  longDescriptions: [
    {
      title: "Was ist eine " + productName + "?",
      paragraphs: [
        "Eine Motorradversicherung besteht meistens aus 1 bis 3 Bausteinen (Haftpflicht-, Teilkasko-, Vollkaskoversicherung) ",
        "Die Haftpflichtversicherung haftet bei Personen-, Sach- und Vermögensschäden, die du mit deinem Motorrad verursacht. Da es bei Personenschäden schnell teuer werden kann, ist diese Versicherung gesetzlich vorgeschrieben.",
        "Die Kaskoversicherung ist eine freiwillige Versicherung gegen Diebstahl und Schäden am eigenen Fahrzeug. Grundsätzlich unterscheidet man dabei zwischen einer Teilkasko- und Vollkaskoversicherung.",
      ],
      image: descriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "In Deutschland ist jeder Halter eines angemeldeten Motorrads oder Moped gesetzlich dazu verpflichtet eine gültige Haftpflichtversicherung vorzuweisen. ",
        "Nach § 6 des Pflichtversicherungsgesetz macht man sich sogar strafbar, sollte man ohne gültiges Versicherungskennzeichen angetroffen werden. ",
        "Diese deckt bei einem Unfall jedoch nur Schäden von anderen Verkehrsteilnehmern.",
        "Wenn du darüber hinaus dein eigenes Fahrzeug schützen willst, kannst du eine Teil- oder Vollkaskoversicherung abschließen.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Schäden, von anderen Verkehrsteilnehmern. Eine Teil- und Vollkaskoversicherung deckt auch Schäden am eigenen Motorrad oder Moped.",
        "Schäden, die bei Zusammenstößen mit Tieren entstehen. (Achtung: Manchmal gilt dies nur bei Kollisionen mit Haarwild wie z. B. Hirsch oder Reh)",
        "Schäden, an allerlei Motorradzubehör wie einem Gepäckkoffer",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Schäden, die außerhalb des Saisonzeitraums anfallen. Für diese Zeit musst du eine Zusatzversicherung abschließen",
        "Schäden, die im Zusammenhang mit genehmigten oder auch ungenehmigten Rennen entstehen",
        "Schäden, die vorsätzlich entstehen",
        "Schäden, die während des privaten Transports passieren, wie ein Sturz auf einem Anhänger",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],
  link: RouteUrls.motorradversicherung,
};
