import styles from "components/user-portal/contract/edit-dokumente-dialog/EditDokumenteEntry.module.scss";

import React, { FunctionComponent } from "react";
import { faFileDownload, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import fetchDokumentFile from "api/requests/fetchDokumentFile";
import { Dokument } from "types/backend/Dokument";

interface EditDokumenteEntryProps {
  dokument: Dokument;
  kid: number;
  vertragId: number;
  onMarkForDelete: (dokument: Dokument) => void;
}

export const EditDokumenteEntry: FunctionComponent<EditDokumenteEntryProps> = ({
  dokument,
  kid,
  vertragId,
  onMarkForDelete,
}) => {
  const handleDownload = async () => {
    const file = await fetchDokumentFile(kid, vertragId, dokument.id);
    if (!file) return;
    saveAs(file, dokument.name);
  };

  const handleDelete = () => {
    onMarkForDelete(dokument);
  };

  return (
    <div className={styles.documentEntry}>
      <div className={styles.documentEntryRow}>
        <div className={styles.fileName}>{dokument.name}</div>
        <div className={styles.iconButton} onClick={handleDownload}>
          <FontAwesomeIcon icon={faFileDownload} />
        </div>
        <div className={styles.iconButton} onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrashCan} />
        </div>
      </div>
    </div>
  );
};
