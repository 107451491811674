import * as yup from "yup";
import { STRINGS } from "language/de_DE/strings";

export const forgetPasswordFormSchema = yup.object().shape({
  email: yup
    .string()
    .email(
      STRINGS.formInputs.validation.email(
        STRINGS.account.forgotPassword.inputEmailLabel
      )
    )
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.forgotPassword.inputEmailLabel
      )
    ),
});

export const forgetPasswordConfirmFormSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, STRINGS.account.forgotPasswordConfirm.inputPasswordValidation)
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
      STRINGS.account.forgotPasswordConfirm.inputPasswordValidation
    )
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.forgotPasswordConfirm.inputPasswordLabel
      )
    ),
});
