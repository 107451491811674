import React, { ReactElement } from "react";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { shouldDisplayField } from "./util/displayInputUtil";
import { AddContractNumberInput } from "./base/AddContractNumberInput";

export const ZahnanspruchInput = ({
  vertragTyp,
}: AddContractInputBaseType): ReactElement | null => {
  if (!shouldDisplayField(vertragTyp, ["KRANKENZUSATZVERSICHERUNG"])) {
    return null;
  }
  return (
    <AddContractNumberInput
      name="zahnanspruch"
      labelText={STRINGS.addContract.step2.dentalClaimLabel}
    />
  );
};
