import React, { FunctionComponent, ReactElement } from "react";
import { STRINGS } from "../../../../language/de_DE/strings";
import { InsuranceBoxes } from "../InsuranceBoxes";

const sectionStrings = STRINGS.insurancePage.comparison;

type InsuranceBenefitComparisonBoxesProps = {
  children: ReactElement[];
};

export const InsuranceBenefitComparisonBoxes: FunctionComponent<
  InsuranceBenefitComparisonBoxesProps
> = ({ children }) => (
  <InsuranceBoxes
    title={sectionStrings.title}
    titlePart1={sectionStrings.titlePart1}
    titlePart2={sectionStrings.titlePart2}
    titlePart3={sectionStrings.titlePart3}
  >
    {children}
  </InsuranceBoxes>
);
