import styles from "components/templates/HausratversicherungVergleichAbschlussStep2Template.module.scss";

import React, { ReactElement, useRef } from "react";
import clsx from "clsx";
import { FormikProps } from "formik";
import { Box } from "components/atoms/Box";
import { Button } from "components/form-inputs/Button/Button";
import {
  HausratversicherungAbschlussStep3Form,
  Step3FormType,
} from "pages/hausratversicherung/forms/HausratversicherungAbschlussStep3Form";
import { InsuranceBadge } from "components/molecules/InsuranceBadge";
import {
  InsuranceCompletionSteps,
  InsuranceCompletionStepsProperties,
} from "components/molecules/InsuranceCompletionSteps";
import UnstyledLink from "components/unstyle-link/UnstyledLink";
import { RouteUrls } from "config/routes";
import { STRINGS } from "language/de_DE/strings";
import { InsuranceBadgeData } from "types/InsuranceData/InsuranceBadgeData";

export type HausratversicherungVergleichAbschlussStep3Properties = {
  selectedInsurance: InsuranceBadgeData;
};

export const HausratversicherungVergleichAbschlussStep3Template = ({
  selectedInsurance,
}: HausratversicherungVergleichAbschlussStep3Properties): ReactElement => {
  const completionProps: InsuranceCompletionStepsProperties = {
    steps: {
      names: [
        STRINGS.insuranceAbschluss.insuranceBreadcrumbs.breadcrumbStep1,
        STRINGS.insuranceAbschluss.insuranceBreadcrumbs.breadcrumbStep2,
        STRINGS.insuranceAbschluss.insuranceBreadcrumbs.breadcrumbStep3,
        STRINGS.insuranceAbschluss.insuranceBreadcrumbs.breadcrumbStep4,
      ],
    },
    currentStep: 3,
    dividerClass: styles.divider,
  };

  const formRef = useRef<FormikProps<Step3FormType>>(null);

  const handleSubmit = async () => {
    if (formRef.current) {
      const values = formRef.current.values;

      formRef.current
        .validateForm()
        // eslint-disable-next-line no-console
        .then(() => formRef.current?.isValid && console.log(values));
    }
  };

  return (
    <Box flex column className={styles.insuranceStepBox}>
      <Box className={styles.insuranceBadgeBox}>
        <InsuranceBadge insuranceBadgeData={selectedInsurance} />
      </Box>
      <InsuranceCompletionSteps {...completionProps} />
      <Box flex className={styles.previousDamageBox}>
        <HausratversicherungAbschlussStep3Form ref={formRef} />
      </Box>
      <Box flex inline>
        <UnstyledLink to={RouteUrls.hausratversicherungVergleichAbschlussStep2}>
          <Button
            label={STRINGS.insuranceAbschluss.backButtonLabel}
            className={clsx(styles.button, styles.buttonBack)}
          />
        </UnstyledLink>
        <Button
          label={STRINGS.insuranceAbschluss.proceedButtonLabel}
          type={"submit"}
          onClick={() => handleSubmit()}
          className={styles.button}
        />
      </Box>
    </Box>
  );
};
