type AppConfiguration = {
  apiUrl: string;
  frontendUrl: string;
  developmentHint: string | false;
  region: string;
  userPoolId: string;
  webClientId: string;
  matomoAnalytics?: MatomoConfig;
};

type MatomoConfig = {
  enabled: boolean;
  matomoUrl: string;
  siteId: number;
};

const prodConfiguration: AppConfiguration = {
  apiUrl: "https://api.mynorm.de",
  frontendUrl: "https://mynorm.de",
  developmentHint: false,
  region: "eu-central-1",
  userPoolId: "eu-central-1_WDas5kH5D",
  webClientId: "1giiiv3ba82t6jra7t5g6knclu",
  matomoAnalytics: {
    enabled: true,
    matomoUrl: "https://mynorm.matomo.cloud/",
    siteId: 1,
  },
};

const stageConfiguration: AppConfiguration = {
  apiUrl: "https://stage-api.mynorm.de",
  frontendUrl: "https://stage.mynorm.de",
  developmentHint: "STAGING VERSION",
  region: "eu-central-1",
  userPoolId: "eu-central-1_FvOGymUx9",
  webClientId: "3gemqafp2h09kjae3n72174lnv",
};

const devConfiguration: AppConfiguration = {
  apiUrl: "https://dev-api.mynorm.de",
  frontendUrl: "https://dev.mynorm.de",
  developmentHint: "DEVELOPMENT VERSION",
  region: "eu-central-1",
  userPoolId: "eu-central-1_FvOGymUx9",
  webClientId: "3gemqafp2h09kjae3n72174lnv",
  matomoAnalytics: {
    enabled: true,
    matomoUrl: "https://mynorm.matomo.cloud/",
    siteId: 2,
  },
};

const localConfiguration: AppConfiguration = {
  apiUrl: "https://dev-api.mynorm.de",
  frontendUrl: "http://localhost:3000",
  developmentHint: "LOCAL VERSION",
  region: "eu-central-1",
  userPoolId: "eu-central-1_FvOGymUx9",
  webClientId: "3gemqafp2h09kjae3n72174lnv",
  matomoAnalytics: {
    // Enable for debugging purposes.
    enabled: false,
    matomoUrl: "https://mynorm.matomo.cloud/",
    siteId: 2,
  },
};

const localBackendConfiguration: AppConfiguration = {
  apiUrl: "http://localhost:8080",
  frontendUrl: "http://localhost:3000",
  developmentHint: "LOCAL BACKEND VERSION",
  region: "eu-central-1",
  userPoolId: "eu-central-1_FvOGymUx9",
  webClientId: "3gemqafp2h09kjae3n72174lnv",
};

enum AppEnvironment {
  PRODUCTION = "PRODUCTION",
  STAGING = "STAGING",
  DEVELOPMENT = "DEVELOPMENT",
  LOCAL = "LOCAL",
  LOCAL_BACKEND = "LOCAL_BACKEND",
}

const getAppConfig = (): AppConfiguration => {
  switch (process.env.REACT_APP_MYNORM_ENV as AppEnvironment) {
    case AppEnvironment.PRODUCTION:
      return prodConfiguration;
    case AppEnvironment.STAGING:
      return stageConfiguration;
    case AppEnvironment.DEVELOPMENT:
      return devConfiguration;
    case AppEnvironment.LOCAL:
      return localConfiguration;
    case AppEnvironment.LOCAL_BACKEND:
      return localBackendConfiguration;
    default:
      return localConfiguration;
  }
};

export const appConfig = Object.freeze(getAppConfig());
