import React, { ReactElement, useEffect } from "react";
import { useFormikContext } from "formik";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { VertragTyp } from "../../../../../types/backend/VertragTyp";
import { validateNumber, validateRequiredOnTypes } from "./util/validationUtil";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { AddContractFormType } from "../AddContractFormType";
import { shouldDisplayField } from "./util/displayInputUtil";
import { AddContractNumberInput } from "./base/AddContractNumberInput";
import { RadioGroup } from "../../../../form-inputs/RadioGroup/RadioGroup";

export type AuszahlungPrognoseType = "einmalig" | "laufend";

const requiredTypes: VertragTyp[] = [
  "BASISRENTE",
  "BAV_LEBENSVERSICHERUNG",
  "BAV_RENTENVERSICHERUNG",
  "BEAMTENPENSION",
  "GESETZLICHE_RENTENVERSICHERUNG",
  "LEBENSVERSICHERUNG",
  "RIESTERRENTE",
  "RISIKOLEBENSVERSICHERUNG",
];

type AuszahlungPrognoseInputType = {
  defaultValue?: AuszahlungPrognoseType;
};

export const AuszahlungPrognoseInputGroup = ({
  vertragTyp,
  activeStep,
  defaultValue,
}: AddContractInputBaseType &
  AuszahlungPrognoseInputType): ReactElement | null => {
  const { values, setFieldValue } = useFormikContext<AddContractFormType>();

  useEffect(() => {
    if (!values.auszahlungPrognoseType) {
      setFieldValue("auszahlungPrognoseType", defaultValue || "laufend");
      return;
    }
    const value =
      values.auszahlungLaufendPrognose || values.auszahlungEinmaligPrognose;
    if (values.auszahlungPrognoseType === "einmalig") {
      setFieldValue("auszahlungLaufendPrognose", "");
      setFieldValue("auszahlungEinmaligPrognose", value);
    }
    if (values.auszahlungPrognoseType === "laufend") {
      setFieldValue("auszahlungEinmaligPrognose", "");
      setFieldValue("auszahlungLaufendPrognose", value);
    }
  }, [
    values.auszahlungPrognoseType,
    values.auszahlungEinmaligPrognose,
    values.auszahlungLaufendPrognose,
    setFieldValue,
    defaultValue,
  ]);

  const validate = () => {
    if (activeStep !== 2) return;
    const value =
      values.auszahlungPrognoseType === "laufend"
        ? values.auszahlungLaufendPrognose
        : values.auszahlungEinmaligPrognose;
    return (
      validateRequiredOnTypes(
        requiredTypes,
        STRINGS.addContract.step2.expectedPayoutError,
        vertragTyp,
        value
      ) || validateNumber(value)
    );
  };

  if (!shouldDisplayField(vertragTyp, requiredTypes)) return null;
  return (
    <div>
      <RadioGroup
        name="auszahlungPrognoseType"
        labelText={STRINGS.addContract.step2.expectedPayoutGroupLabel}
        options={[
          {
            label: STRINGS.addContract.step2.expectedPayoutGroupRecurringLabel,
            value: "laufend",
          },
          {
            label: STRINGS.addContract.step2.expectedPayoutGroupOneTimeLabel,
            value: "einmalig",
          },
        ]}
      />
      {values.auszahlungPrognoseType === "laufend" && (
        <AddContractNumberInput
          name="auszahlungLaufendPrognose"
          validate={validate}
        />
      )}
      {values.auszahlungPrognoseType === "einmalig" && (
        <AddContractNumberInput
          name="auszahlungEinmaligPrognose"
          validate={validate}
        />
      )}
    </div>
  );
};
