import React, { FunctionComponent } from "react";
import styles from "./InsuranceLongDescription.module.scss";

import { STRINGS } from "../../../language/de_DE/strings";
import clsx from "clsx";
import { LongDescriptionBox } from "../../../content/productTreeDefinition";

const sectionStrings = STRINGS.insurancePage.longDescription;

type InsuranceLongDescriptionProps = {
  descriptions: LongDescriptionBox[];
  insuranceName: string;
};

type ParagraphProps = {
  texts: string[];
  className?: string;
};

const Paragraphs: FunctionComponent<ParagraphProps> = ({
  texts,
  className,
}) => (
  <>
    {texts.map((paragraph: string) => (
      <p key={paragraph} className={clsx(styles.description, className)}>
        {paragraph}
      </p>
    ))}
  </>
);

export const InsuranceLongDescription: FunctionComponent<
  InsuranceLongDescriptionProps
> = ({ descriptions, insuranceName }) => {
  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.upperSectionWrapper}>
        <img
          src={descriptions[0].image}
          alt={descriptions[0].title}
          className={styles.upperImage}
        />
        <section className={styles.upperSection}>
          <h1 className={styles.sectionTitle}>
            <span className={styles.highlighted}>
              {sectionStrings.upperSectionTitlePart1}
            </span>
            {insuranceName}
            {sectionStrings.upperSectionTitlePart2}
          </h1>
          <Paragraphs texts={descriptions[0].paragraphs} />
        </section>
      </div>
      {descriptions.length > 1 && (
        <div className={styles.lowerSectionWrapper}>
          <section className={styles.lowerSection}>
            <div className={styles.lowerText}>
              <h1 className={styles.sectionTitle}>
                <span className={styles.highlighted}>
                  {sectionStrings.lowerSectionTitlePart1}
                </span>
                {insuranceName}
                {sectionStrings.upperSectionTitlePart2}
              </h1>
              <Paragraphs texts={descriptions[1].paragraphs} />
            </div>
            <img
              src={descriptions[1].image}
              alt={descriptions[1].title}
              className={styles.lowerImage}
            />
          </section>
        </div>
      )}
    </div>
  );
};
