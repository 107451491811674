import styles from "components/user-portal/portal-sideboard/PortalSideboardHeader.module.scss";

import React, { ReactElement } from "react";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";

interface PortalSideboardHeaderProps {
  text: string;
}

export const PortalSideboardHeader = ({
  text,
}: PortalSideboardHeaderProps): ReactElement => {
  return (
    <Box className={styles.text}>
      <Text inline size={"medium"}>
        {text}
      </Text>
    </Box>
  );
};
