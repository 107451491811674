import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/krankentagegeld_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/krankentagegeld_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/krankentagegeld_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Kranken­tage­geld­versicherung";

export const krankentagegeldProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Deine Krankentagegeldversicherung zahlt einen festgelegten Betrag, um dein ausgebliebenes Einkommen auszugleichen, wenn du krank bist.",

  insuranceSituation: [
    {
      title:
        "... du kein Krankengeld von der gesetzlichen Krankenversicherung bekommst.",
      text: icon1,
    },
    {
      title:
        "... du besorgt bist, dass das Krankengeld aus der gesetzlichen Krankenversicherung nicht ausreichend ist.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Eine Krankentagegeld-Versicherung ist eine freiwillige Zusatzversicherung für Angestellte, Freiberufler und auch Selbstständige.",
        "Solltest du krank sein und weder Geld vom Arbeitgeber noch von der gesetzlichen Krankenkasse erhalten, lassen sich mit dieser Versicherung mögliche Einkommensausfälle ausgleichen oder mindern.",
        "Es ist dabei abzugrenzen vom Krankengeld, welches eine Regelleistung der gesetzlichen Krankenversicherung ist.",
      ],
      image: descriptionImage1,
    },

    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "In der Regel bietet sich eine Krankentagegeld-Versicherung an, wenn du kein Krankengeld von deiner gesetzlichen Krankenkasse bekommst.",
        "Des Weiteren sollte man, selbst wenn man Krankengeld bekommt, überprüfen, ob dieses ausreicht, um die eigenen Lebenshaltungskosten zu decken – gerade bei höheren Nettoeinkommen kann es eine Differenz zum Krankengeld geben.",
        "Besonders interessant ist eine Krankentagegeld-Versicherung daher für privat versicherte Arbeitnehmer, gesetzlich versicherte Selbstständige und privat versicherte Selbstständige.",
        "",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: ["Vollständige Arbeitsunfähigkeit (via Nachweis per Attest)"],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: ["Teilweise Arbeitsunfähigkeit"],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.krankentagegeld,
};
