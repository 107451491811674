import styles from "components/molecules/ToolTip.module.scss";

import React, { FunctionComponent } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";

export type ToolTip = {
  content: string;
  icon: IconProp;
};

export type ToolTipProperties = {
  toolTipData: ToolTip;
};

export const ToolTip: FunctionComponent<ToolTipProperties> = ({
  toolTipData,
}) => {
  return (
    <Box flex>
      <Box className={styles.hasToolTip}>
        <Text inline size={"xSmall"} className={styles.toolTip}>
          {toolTipData.content}
        </Text>
        <FontAwesomeIcon
          icon={toolTipData.icon}
          className={styles.toolTipIcon}
        />
      </Box>
    </Box>
  );
};
