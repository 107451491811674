import React, { FunctionComponent, ReactNode } from "react";
import styles from "./InsuranceBoxes.module.scss";
import clsx from "clsx";
import PageSection from "../../page-section/PageSection";

type InsuranceBoxesProps = {
  title: string;
  titlePart1: string;
  titlePart2: string;
  titlePart3: string;
  children: ReactNode;
};

export const InsuranceBoxes: FunctionComponent<InsuranceBoxesProps> = ({
  title,
  titlePart1,
  titlePart2,
  titlePart3,
  children,
}) => (
  <PageSection>
    <h1 className={styles.sectionTitle}>
      {title}
      <span className={styles.highlighted}>{titlePart1}</span> {titlePart2}
      <span className={styles.highlighted}>{titlePart3}</span>
    </h1>
    <div
      className={clsx(styles.boxes, {
        [styles.singular]: React.Children.count(children) === 1,
      })}
    >
      {children}
    </div>
  </PageSection>
);
