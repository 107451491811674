import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/privatkunst_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/privatkunst_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/privatkunst_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Kunstversicherung";

export const privatkunstProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Mit einer Kunstversicherung kannst du sowohl private Kunstsammlungen als auch Stücke in Museen und Galerien versichern.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title: "... ein Kunstwerk beim Transport Schaden nimmt.",
      text: icon1,
    },
    {
      title: "... ein Kunstwerk bei der Restauration beschädigt wird.",
      text: icon1,
    },
    {
      title: "... ein Kunstwerk gestohlen wird.",
      text: icon1,
    },
    {
      title:
        "... wenn eine von vielen weiteren Situationen eintritt, welche die Versicherung nicht explizit ausschließt.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Egal, ob selbst gestaltetes Kunstwerk oder Ausstellungsstück in der Galerie: Mit einer Kunstversicherung können verschiedene Arten von Kunstwerken, Ausstellungsstücken und sogar ganze Sammlungen versichert werden.",
        "Dabei geht die Kunstversicherung über die Grenzen einer normalen Hausratversicherung hinaus – da bei dieser oft Entschädigungsgrenzen zum Tragen kommen.",
        "Da in der Regel jedes Kunstwerk individuell versichert wird, hängt das Risiko stark von der Zusammensetzung der Sammlung oder der Einzelwerte ab. Somit sind auch die Kosten der Kunstversicherung stark von dieser Zusammensetzung abhängig.",
      ],
      image: descriptionImage1,
    },

    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Eine Kunstversicherung ist besonders sinnvoll für private Sammler, Restauratoren, Galleristen, Kunsthändler und Museen.",
        "Genauso sinnvoll kann sie für Künstler und Menschen sein, die ihre Wohnung gerne mit Kunstwerken, Teppichen oder Antiquitäten schmücken.",
        "Dabei können beispielsweise geerbte Kunstgegenstände schnell ein Grund für eine Kunstversicherung werden.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Alle Schäden, die nicht explizit ausgeschlossen sind (Allgefahrendeckung)",
        "Verlust oder Beschädigung bei Restaurationen",
        "Transportschäden",
        "Wertminderung",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Krieg, Sturmflut und Kernenergie",
        "Haustiere, Schädlinge und Ungeziefer",
        "Abnutzung und Verschleiß",
        "Klimatische Einwirkungen, Luftfeuchtigkeit, Trockenheit und Licht",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.kunstversicherung,
};
