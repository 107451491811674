import styles from "components/atoms/Box.module.scss";

import React, {
  ForwardedRef,
  forwardRef,
  FunctionComponent,
  ReactNode,
} from "react";

export type BoxProperties = {
  children?: ReactNode;
  className?: string;
  column?: boolean;
  flex?: boolean;
  inline?: boolean;
  onClick?: () => void;
  tabIndex?: number;
};

const NormalBox: FunctionComponent<BoxProperties> = ({
  children,
  className,
  flex = false,
  inline = false,
  column = false,
  onClick,
  tabIndex,
}) => {
  const classNames = Object.entries({
    [styles.flex]: flex && !inline,
    [styles.inline]: inline && !flex,
    [styles.inlineFlex]: inline && flex,
    [styles.column]: flex && column,
    [className || "nothing"]: className !== undefined,
  })
    .filter(([, value]) => value)
    .map(([name]) => name);

  return (
    <div className={classNames.join(" ")} onClick={onClick} tabIndex={tabIndex}>
      {children}
    </div>
  );
};

const RefBox = forwardRef<HTMLDivElement, BoxProperties>(
  (
    {
      children,
      className,
      flex = false,
      inline = false,
      column = false,
      onClick,
      tabIndex,
    },
    ref
  ) => {
    const classNames = Object.entries({
      [styles.flex]: flex && !inline,
      [styles.inline]: inline && !flex,
      [styles.inlineFlex]: inline && flex,
      [styles.column]: flex && column,
      [className || "nothing"]: className !== undefined,
    })
      .filter(([, value]) => value)
      .map(([name]) => name);

    return (
      <div
        className={classNames.join(" ")}
        onClick={onClick}
        tabIndex={tabIndex}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

export const Box: FunctionComponent<
  BoxProperties & { reference?: ForwardedRef<HTMLDivElement> }
> = ({
  children,
  className,
  flex = false,
  inline = false,
  column = false,
  onClick,
  tabIndex,
  reference,
}) => {
  if (reference)
    return (
      <RefBox
        ref={reference}
        className={className}
        flex={flex}
        inline={inline}
        column={column}
        onClick={onClick}
        tabIndex={tabIndex}
      >
        {children}
      </RefBox>
    );
  return (
    <NormalBox
      className={className}
      flex={flex}
      inline={inline}
      column={column}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      {children}
    </NormalBox>
  );
};

RefBox.displayName = "RefBox";
