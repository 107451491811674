import React, { FunctionComponent, ReactNode } from "react";
import clsx from "clsx";
import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";

import styles from "./InsuranceBenefitBox.module.scss";

type BenefitBoxProps = {
  titleAccepted: string;
  titleNotAccepted: string;
  acceptedItem: string[];
  notAcceptedItem: string[];
  insuranceName: string;
};

type ListItemProps = {
  isPositive: boolean;
  children: ReactNode;
};

const ListItem: FunctionComponent<ListItemProps> = ({
  isPositive,
  children,
}) => (
  <li
    className={clsx(styles.listItem, {
      [styles.positive]: isPositive,
      [styles.negative]: !isPositive,
    })}
  >
    <span className={styles.listIcon}>
      {isPositive ? (
        <IoCheckmarkSharp size={"2em"} />
      ) : (
        <IoCloseSharp size={"2em"} />
      )}
    </span>
    <span>{children}</span>
  </li>
);

export const InsuranceBenefitBox: FunctionComponent<BenefitBoxProps> = ({
  titleAccepted,
  titleNotAccepted,
  acceptedItem,
  notAcceptedItem,
}) => (
  <>
    <div className={styles.box}>
      <div className={styles.boxContent}>
        <ul className={styles.bulletPoints}>
          <h3 className={styles.boxTitle}>{titleAccepted}</h3>
          {acceptedItem.map((bullet) => (
            <ListItem key={bullet} isPositive={true}>
              <li key={bullet}>{bullet}</li>
            </ListItem>
          ))}
        </ul>
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.boxContent}>
        <ul className={styles.bulletPoints}>
          <h3 className={styles.boxTitle}>{titleNotAccepted}</h3>
          {notAcceptedItem.map((bullet) => (
            <ListItem key={bullet} isPositive={false}>
              <li key={bullet}>{bullet}</li>
            </ListItem>
          ))}
        </ul>
      </div>
    </div>
  </>
);
