import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import DescriptionImage0 from "gfx/images/insurance-images/startkapitalAusbildung_0.jpg";

const productName = "Startkapitalhilfe Ausbildung";

export const startkapitalausbildungProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: DescriptionImage0,
  introduction:
    "Eine qualifizierte Ausbildung soll nicht an fehlenden finanziellen Mitteln scheitern. Eltern sollten so früh wie möglich anfangen, Geld dafür zurückzulegen, um mit überschaubaren monatlichen Sparraten eine finanzielle Starthilfe zur Erreichung der Ausbildungsziele ihrer Kinder zu schaffen.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  link: RouteUrls.startkapitalausbildung,
};
