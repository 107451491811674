import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";
import descriptionImage0 from "gfx/images/insurance-images/ruerup_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/ruerup_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/ruerup_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Rürup-Rente";

export const rueruprenteProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Deine Rürup-Rente (auch Basisrente genannt) ist eine private Altersvorsorge, welche staatlich gefördert wird und vor allem für Selbstständige ohne gesetzliche Absicherung interessant ist.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title:
        "... du als Selbstständiger oder Freiberufler nicht unter die Rentenversicherungspflicht fällst und eigenständig für dein Alter vorsorgen musst.",
      text: icon1,
    },
    {
      title:
        "... du ein planbares, hohes Einkommen hast und du somit von steuerlichen Vorteilen Gebrauch machen möchtest.",
      text: icon1,
    },
    {
      title:
        "... du Geld zur Seite legen möchtest, welches du bis zu deiner Rente nicht mehr benötigst.",
      text: icon1,
    },
    {
      title:
        "... du dir zusätzlich zu deiner Rentenleistung durch die Deutsche Rentenversicherung eine weitere Altersvorsorge aufbauen möchtest, um der Rentenlücke zu entgehen.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Eine Basis- oder Rürup-Rente ist ein Produkt zur privaten Altersvorsorge. Somit schließt man seinen Vertrag auch nicht mit dem Staat, sondern einem privaten Versicherer. Nach einer initialen Ansparphase kannst du dir im Alter eine monatliche Rentenleistung auszahlen lassen. Eine Einmalauszahlung ist bei dieser Vorsorgeform nicht möglich.",
        "Wichtig hierbei: auf das eingezahlte Geld kann man bis zum Renteneintritt (frühestens ab 62) erst einmal nicht mehr zugreifen. Der Vorsorgevertrag ist weder kündbar, beleihbar, auf Angehörige übertragbar noch vererbbar.",
        "Typische Anlageformen bei der Rürup-Rente sind klassische und fondsgebundene Rentenversicherungen.",
      ],
      image: descriptionImage1,
    },

    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Sinnvoll ist die Rürup-Rente vor allem für Selbstständige und Freiberufler mit einem planbaren, hohen Einkommen. Da man sich in diesen Berufsschichten von der Rentenversicherungspflicht befreien lassen kann, kann die Rürup-Rente das perfekte Substitut sein – und gleichzeitig noch steuerliche Vorteile liefern, die man sich bei der Steuererklärung über den Sonderausgabenabzug sichern kann.",
        "Auch Angestellte, die perspektivisch mit dem Gedanken spielen, einer hauptberuflichen Selbstständigkeit nachzugehen, können von einer Basisrente profitieren.",
        "Ungeeignet ist die Rürup-Rente vor allem für junge Unternehmer und Studenten, da diese nicht von steuerlichen Vorteilen profitieren können.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.rueruprente,
};
