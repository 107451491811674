import styles from "./TravelCampaign.module.scss";

import React from "react";
import Page from "components/page/Page";
import { CampaignHeader } from "../../../components/campaigns/CampaignHeader/CampaignHeader";
import { STRINGS } from "../../../language/de_DE/strings";
import headerImage from "gfx/images/campaigns/convertkit-5SWgIJSsVJo-unsplash.jpg";
import insureNowImage from "gfx/images/campaigns/Gruppe 2101.jpg";
import { RouteUrls } from "../../../config/routes";
import PageSection from "../../../components/page-section/PageSection";
import { Quotes } from "../../../components/quotes/Quotes";
import { CampaignInsureNow } from "../../../components/campaigns/CampaignInsureNow/CampaignInsureNow";
import {
  CampaignInsuranceEnumeration,
  InsuranceEnumerationDescription,
} from "../../../components/campaigns/CampaignInsuranceEnumeration/CampaignInsuranceEnumeration";
import { CampaignUsp } from "../../../components/campaigns/CampaignUsp/CampaignUsp";
import icon1 from "gfx/images/campaigns/icon-1.svg";
import icon2 from "gfx/images/campaigns/icon-2.svg";
import icon3 from "gfx/images/campaigns/icon-3.svg";
import icon4 from "gfx/images/campaigns/icon-4.svg";

const sectionStrings = STRINGS.campaigns.reise;

const enumeratedInsurances: InsuranceEnumerationDescription[] =
  sectionStrings.insuranceComparison.insurances.map(
    (
      insuranceDescription: Omit<InsuranceEnumerationDescription, "buttonLink">
    ) => {
      return {
        ...insuranceDescription,
      };
    }
  );
enumeratedInsurances[0].buttonLink =
  RouteUrls.reiseruecktrittsversicherungVergleich;
enumeratedInsurances[1].buttonLink =
  RouteUrls.auslandsreisekrankenversicherungVergleich;
enumeratedInsurances[2].buttonLink =
  RouteUrls.reisegepaeckversicherungVergleich;

const mynormCampaignUsps = [
  {
    title: sectionStrings.usps.fast.title,
    prose: sectionStrings.usps.fast.prose,
    image: icon1,
  },
  {
    title: sectionStrings.usps.quality.title,
    prose: sectionStrings.usps.quality.prose,
    image: icon2,
  },
  {
    title: sectionStrings.usps.individual.title,
    prose: sectionStrings.usps.individual.prose,
    image: icon3,
  },
  {
    title: sectionStrings.usps.consulting.title,
    prose: sectionStrings.usps.consulting.prose,
    image: icon4,
  },
];

const TravelCampaignPage = () => {
  return (
    <Page contentClassName={styles.content}>
      <CampaignHeader
        title={sectionStrings.title}
        image={headerImage}
        checklistItems={sectionStrings.checklistItems}
        buttonLink={RouteUrls.reiseVersicherungVergleich}
      />
      <PageSection background={"blue"} contentClassName={styles.boldClaim}>
        {sectionStrings.boldClaim}
      </PageSection>
      <Quotes quotes={sectionStrings.quotes} />
      <CampaignInsuranceEnumeration
        insurances={enumeratedInsurances}
        title={sectionStrings.insuranceComparison.title}
      />
      <CampaignInsureNow
        title={sectionStrings.insureNow.title}
        prose={sectionStrings.insureNow.prose}
        checklistItems={sectionStrings.insureNow.checklistItems}
        checklistIntroduction={sectionStrings.insureNow.checklistIntroduction}
        image={insureNowImage}
        buttonLink={RouteUrls.reiseVersicherungVergleich}
      />
      <CampaignUsp usps={mynormCampaignUsps} />
    </Page>
  );
};
export default TravelCampaignPage;
