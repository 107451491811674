import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import insuranceIncapacitatedImage from "gfx/images/insurance-images/dienstunfaehigkeit_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/dienstunfaehigkeit_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/dienstunfaehigkeit_2.jpg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Dienst­unfähigkeits­versicherung";

export const dienstunfaehigkeitProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: insuranceIncapacitatedImage,
  introduction:
    "Eine Dienstunfähigkeitsversicherung zahlt dir eine Rente, wenn du dein Dienstverhältnis aus gesundheitlichen Gründen nicht mehr ausüben kannst und schützt dich dadurch vor finanziellen Folgen. Sie ist also eine Berufsunfähigkeitsversicherung für Beamte.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Eine Dienstunfähigkeitsversicherung versichert dein zuletzt ausgeübtes Dienstverhältnis und zahlt dir einen vereinbarten monatlichen Betrag aus, wenn du deinen Dienst nicht mehr ausüben kannst.",
        "Die Versicherung ist daher grundsätzlich mit einer Berufsunfähigkeitsversicherung vergleichbar und spezialisiert sich auf Personen in einem Dienstverhältnis, wie Mitarbeiter von Behörden und Verwaltungen, Polizisten, Lehrer, Soldaten und Förster.",
        "Wegen welcher Erkrankung Du Deinen Job nicht mehr ausüben kannst, ist dabei in der Regel nicht relevant. Die meisten Versicherer zahlen, wenn Du – ärztlich nachgewiesen – zu mindestens 50 Prozent dienstunfähig bist.",
        "Achtung: Wie viel du vorher verdient hast spielt keine Rolle. Deine Dienstunfähigkeitsrente wird bei Abschluss der Versicherung festgelegt.",
      ],
      image: descriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Die Dienstunfähigkeitsversicherung spezialisiert sich auf Beamte, da diese meist nicht unter den Schutz einer Berufsunfähigkeitsversicherung fallen. Bist du selbst als Mitarbeiter von Behörden und Verwaltungen, Polizist, Lehrer, Soldat oder Förster in einem Beamtenverhältnis, ist die Dienstunfähigkeitsversicherung genau für dich gemacht.",
        "Statistisch wird in Deutschland jeder 4. Arbeitnehmer im Laufe seines Lebens berufsunfähig, sodass eine Berufsunfähigkeitsversicherung für jedermann wichtig ist. Denn solltest du dienstunfähig werden, kann deine finanzielle Absicherung meist unzureichend sein, sodass eine große finanzielle Lücke entsteht. Diese Lücke kann mit einer Berufsunfähigkeitsrente geschlossen werden.",
        "Dabei kann es sich lohnen, schon früh eine Dienstunfähigkeitsversicherung abzuschließen, da du noch fitter und gesünder bist, deinen Gesundheitsstatus für die Zukunft festhältst und von einem geringeren Versicherungsbeitrag profitierst.",
      ],
      image: descriptionImage2,
    },
  ],
  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Wenn du deinen Beruf oder keine Tätigkeit deiner bisherigen Lebensstellung nicht mehr ausführen kannst",
        "Wenn du in den letzten 6 Monaten für mindestens 3 Monate dienstunfähig warst und in den kommenden 6 Monaten durch einen Amtsarzt als nicht voll dienstfähig attestiert wurdest",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Bei schneller Genesung oder wenn deine Dienstunfähigkeit nicht nachweisbar ist.",
        "Wenn du deine Dienstunfähigkeit absichtlich herbeiführst oder sie infolge eines Suizidversuchs auftritt",
        "Wenn du falsche Angaben beim Abschluss deiner Versicherung gemacht oder bereits bestehende Krankheiten verschwiegen hast",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  checklist: [
    {
      title: "Gesundheitsfragen",
      text: "Gerade auf die Gesundheitsfragen beim Abschluss einer Berufsunfähigkeitsversicherung solltest du ein besonderes Augenmerk legen. Hier ist es sinnvoll, dass du dir einen fachkundigen Berater an die Seite nimmst, der sich Zeit nimmt, deine Situation prüft, mit der Antragsprozess durchläuft und schaut, was für deine individuelle Situation am",
    },
  ],
  link: RouteUrls.dienstunfaehigkeitsversicherung,
};
