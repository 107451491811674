import React, { ReactElement, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  ContractCardView,
  ContractListView,
} from "components/user-portal/contract/ContractViews";
import PortalContentHeader from "components/user-portal/portal-content/PortalContentHeader";
import appBreakpoints from "config/pageBreakpoints";
import { RouteUrls } from "config/routes";
import useMatchMedia from "hooks/useMatchMedia";
import { KundeResponse } from "types/backend/KundeResponse";
import { ProduktKategorie } from "types/backend/ProduktKategorie";
import { VertragKapitalanlage } from "types/backend/VertragKapitalanlage";
import { VertragSparanlage } from "types/backend/VertragSparanlage";
import { VertragVersicherung } from "types/backend/VertragVersicherung";
import { VertragVersicherungKfz } from "types/backend/VertragVersicherungKfz";

interface ContractPageContentProps {
  title: string;
  vertraege: Array<
    | VertragSparanlage
    | VertragKapitalanlage
    | VertragVersicherung
    | VertragVersicherungKfz
  >;
  kunde: KundeResponse;
  produktKategories: ProduktKategorie[];
}

export const ContractToggleableContent = ({
  title,
  vertraege,
  kunde,
  produktKategories,
}: ContractPageContentProps): ReactElement => {
  const [displayAsList] = useState(true);
  const isMobile = useMatchMedia(`(max-width: ${appBreakpoints.md})`);

  return (
    <>
      <PortalContentHeader
        title={title}
        headerActionProps={{
          text: "",
          icon: faPlus,
          routeUrl: RouteUrls.userAddContract,
        }}
      />

      {displayAsList && !isMobile && (
        <ContractListView
          vertraege={vertraege}
          kunde={kunde}
          produktKategories={produktKategories}
        />
      )}
      {(!displayAsList || isMobile) && (
        <ContractCardView vertraege={vertraege} kunde={kunde} />
      )}
    </>
  );
};
