import { I18n } from "aws-amplify";
import { default as dateFnsLocaleGerman } from "date-fns/locale/de";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { errorMessageTranslations } from "content/registration";

const configureLocale = (): void => {
  // React Datepicker
  registerLocale("de", dateFnsLocaleGerman);
  setDefaultLocale("de");

  // AWS cognito
  I18n.setLanguage("de");
  I18n.putVocabulariesForLanguage("de", errorMessageTranslations);
};

export default configureLocale;
