import styles from "components/logo/LogoImage.module.scss";

import React, { FunctionComponent } from "react";
import logoPrimaryImage from "../../gfx/images/mynorm-logos/Logo_myNORM_blue.svg";
import logoWhiteImage from "../../gfx/images/mynorm-logos/Logo_myNORM_white.svg";
import logoBlackImage from "../../gfx/images/mynorm-logos/Logo_myNORM_black.svg";
import clsx from "clsx";
import { STRINGS } from "../../language/de_DE/strings";

type LogoImageProps = {
  size: "small" | "large";
  color: "primary" | "white" | "black";
};

function getImgSrc(color: "primary" | "white" | "black"): string {
  if (color === "primary") return logoPrimaryImage;
  if (color === "white") return logoWhiteImage;
  if (color === "black") return logoBlackImage;
  else return logoPrimaryImage;
}

const LogoImage: FunctionComponent<LogoImageProps> = ({ size, color }) => {
  const imgSrc = getImgSrc(color);
  return (
    <img
      className={clsx(styles.logo, {
        [styles.small]: size === "small",
        [styles.large]: size === "large",
      })}
      src={imgSrc}
      alt={STRINGS.companyName}
    />
  );
};

export default LogoImage;
