import React, { ReactElement } from "react";
import { LegalDoc } from "./LegalDoc";

export const ErstInformation = (): ReactElement => {
  return (
    <LegalDoc title={"Erstinformation"}>
      <div>
        <h2>
          Information nach § 15 Versicherungsvermittlungsverordnung (VersVermV)
          sowie § 12 Finanzanlagenvermittlungsverordnung (FinVermV)
        </h2>

        <div>
          <h2>1. Firma und Anschrift:</h2>
          <div>
            <div>
              <strong>myNORM GmbH</strong>
            </div>
            <div>Rendsburger Straße 14</div>
            <div>30659 Hannover</div>
            <div>Deutschland</div>
          </div>
          <div>
            <div>Handelsregister-Nr.: HRB 222889</div>
            <div>Ust-ID: DE 349354244</div>
          </div>
        </div>

        <div>
          <h2>2. Geschäftsführer:</h2>
          <div>
            <div>Michael Rublack und Dr. Thorsten Dylla</div>
          </div>
        </div>

        <div>
          <h2>3. Status des Vermittlers gemäß Gewerbeordnung:</h2>
          <div>
            <div>
              Wir, die myNORM GmbH, sind als Makler für Versicherungen im Sinne
              des § 34d Abs. 1 Gewerbeordnung (GewO) sowie
              Finanzanlagenvermittler gemäß § 34f GewO tätig. Du kannst uns im
              Vermittlerregister finden unter:
            </div>
            <div>
              <p>D-QNQL-QNRXP-59 (§ 34 d Abs. 1 GewO)</p>
              <p>D-F-133-YLPZ-51 (§34f Abs.1 GewO)</p>
            </div>
            <div>
              <p>Die zuständigen Aufsichtsbehörde ist die:</p>
              <div>
                <div>
                  <strong>Industrie- und Handelskammer Hannover</strong>
                </div>
                <div>Schiffgraben 49</div>
                <div>30175 Hannover</div>
                <div>Deutschland</div>
              </div>
            </div>
          </div>
          <p>
            Im Vermittlerregister sind wir unter der Nummer D-AAAA-AAA0-00
            registriert. Bei Interesse können Sie die Angaben bei der
            gemeinsamen Registerstelle überprüfen:
          </p>
          <div>
            <h3>Registerstelle</h3>
            <div>Deutscher Industrie- und Handelskammertag (DIHK) e.V.</div>
            <div>Breite Straße 29</div>
            <div>10178 Berlin</div>
            <div>Deutschland</div>
          </div>
        </div>

        <div>
          <h2>4. Vergütung:</h2>
          <div>
            <div>
              Für die Vermittlung und die Betreuung von Versicherungsverträgen
              erhalten wir in der Regel eine Provision, die von den
              Versicherungsunternehmen an uns ausgezahlt, aber wirtschaftlich
              von Ihnen getragen werden, da sie in den Versicherungsprämien
              enthalten sind.
            </div>
            <div>
              Bei Bedarf und nach Vereinbarung mit unseren Kunden werden wir
              auch auf Basis anderer Vergütungsmodelle tätig, z. B. in Form von
              Honorarvereinbarungen oder von Kombinationen der
              Vergütungsmodelle.
            </div>
            <div>
              Über die vorgenannten Vergütungen hinaus erhalten wir keine
              anderen Zuwendungen.
            </div>
          </div>
        </div>

        <div>
          <h2>5. Schlichtungsstellen für außergerichtliche Streitbeilegung:</h2>
          <div>
            <h3>Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO:</h3>
            <div>
              Die Europäische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit, die Sie hier finden können:
            </div>
            <div>
              <a href="https://ec.europa.eu/consumers/odr">
                ec.europa.eu/consumers/odr
              </a>
            </div>

            <div>
              <h3>Schlichtungsstellen:</h3>
              <div>
                Zur außergerichtlichen Beilegung von Streitigkeiten zwischen
                Versicherungsnehmer und Vermittler im Zusammenhang mit der
                Vermittlung von Versicherungsverträgen die folgenden
                unabhängigen Schlichtungsstellen gemäß § 214 VVG angerufen
                werden.
              </div>
              <p>
                <h4>Versicherungsombudsmann e.V.</h4>
                <div>Postfach 08 06 32</div>
                <div>10006 Berlin</div>
                <div>
                  <a href="https://www.versicherungsombudsmann.de">
                    www.versicherungsombudsmann.de
                  </a>
                </div>
              </p>
              <p>
                <h4>Ombudsmann für private Kranken- und Pflegeversicherung</h4>
                <div>Kronenstraße 13</div>
                <div>10117 Berlin</div>
                <div>
                  <a href="https://www.pkv-ombudsmann.de">
                    www.pkv-ombudsmann.de
                  </a>
                </div>
              </p>
              <p>
                <h4>Ombudsfrau der privaten Bausparkassen</h4>
                <div>Postfach 30 30 79</div>
                <div>10730 Berlin</div>
                <div>
                  <a href="https://www.bausparkassen.de">
                    www.bausparkassen.de
                  </a>
                </div>
              </p>
            </div>
          </div>
        </div>

        <div>
          <h2>
            6. Informationen über Emittenten und Anbieter, zu deren
            Finanzanlagen Vermittlungs- oder Beratungsleistungen angeboten
            werden:
          </h2>
          <div>
            <div>
              Vermittelt und beraten wird zu Finanzanlagen aus der gesamten
              Breite des in Deutschland bestehenden Marktes soweit dies im
              Rahmen der behördlichen Zulassung als
              Finanzanlagenvermittler/-berater gem. § 34 f GewO zulässig ist.
            </div>
          </div>
        </div>

        <div>
          <h2>
            7. Informationen über die Vergütung bei der Finanzanlagenberatung
            und –vermittlung:
          </h2>
          <div>
            <div>
              Im Zusammenhang mit der Anlageberatung oder -vermittlung erfolgt
              die Vergütung ausschließlich durch Provisionen durch Produktgeber
              oder Depotbank, welche auch behalten werden dürfen.
            </div>
          </div>
        </div>

        <div>
          <h2>
            8. Information zur Teilnahme am Streitbeilegungsverfahren gemäß § 36
            Verbraucherstreitbeilegungsgesetz:
          </h2>
          <div>
            In Versicherungsangelegenheiten nehmen wir an einem verpflichtenden
            Streitbeilegungsverfahren vor den oben unter Ziffer fünf genannten
            Verbraucherschlichtungsstellen teil. In der Anlagevermittlung nehmen
            wir daran auf freiwilliger Basis teil.
          </div>
        </div>

        <div>
          <h2>9. Beteiligungen</h2>
          <div>
            Die myNORM GmbH besitzt keine direkten oder indirekten Beteiligungen
            von über 10 % an den Stimmrechten oder am Kapital eines
            Versicherungsunternehmens. Darüber hinaus besitzt kein
            Versicherungsunternehmen eine direkte oder indirekte Beteiligung an
            den Stimmrechten oder am Kapital der myNORM GmbH.
          </div>
        </div>
      </div>
    </LegalDoc>
  );
};
