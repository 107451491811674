import styles from "components/unstyle-link/UnstyledLink.module.scss";

import React, { ReactElement } from "react";
import clsx from "clsx";
import { Link, LinkProps } from "react-router-dom";

type Props = LinkProps;

const UnstyledLink = (props: Props): ReactElement => (
  <Link {...props} className={clsx(styles.unstyledLink, props.className)} />
);

export default UnstyledLink;
