import styles from "components/loading-overlay/LoadingOverlay.module.scss";

import React, { ReactElement } from "react";
import clsx from "clsx";
import { BounceLoader } from "react-spinners";

interface LoadingOverlayProps {
  isVisible: boolean;
}

const LoadingOverlay = ({ isVisible }: LoadingOverlayProps): ReactElement => (
  <div className={clsx(styles.loadingOverlay, isVisible && styles.visible)}>
    <div className={styles.loader}>
      <BounceLoader size={200} color="#ffffff" />
    </div>
  </div>
);

export default LoadingOverlay;
