import common from "components/form-inputs/FormInputCommons.module.scss";

import React, { useMemo } from "react";
import Select, { SingleValue } from "react-select";
import { STRINGS } from "../../../language/de_DE/strings";
import clsx from "clsx";
import { v4 } from "uuid";
import { FormInputLabel } from "../FormInputLabel";
import {
  FormInputCustomStyleProperties,
  FormInputProperties,
} from "../FormInputTypes";
import { FormInputSubText } from "../FormInputSubText";

export type Option = {
  value?: string | boolean | null;
  label: string;
  options?: Option[];
};

export const noneOption: Option = {
  label: STRINGS.formInputs.noneOption,
  value: "",
};

type SingleSelectProperties = FormInputProperties & {
  options: Option[];
  withNoneOption?: boolean;
};

const CustomizableSingleSelect = ({
  field,
  form,
  labelText,
  helpText,
  disabled,
  tabIndex,
  placeholder,
  lightColor,
  options,
  withNoneOption,
  inputClassName,
  labelClassName,
  validationClassName,
  helpTextClassName,
}: SingleSelectProperties & FormInputCustomStyleProperties) => {
  const id = field.name + "-" + v4();
  const meta = form.getFieldMeta(field.name);

  const selectOptions = useMemo(() => {
    return withNoneOption ? [noneOption, ...options] : [...options];
  }, [withNoneOption, options]);

  const handleChange = (newValue: SingleValue<Option>) => {
    form.setFieldValue(field.name, newValue?.value);
    return field.onChange;
  };

  const selectedValue = (value: any): Option => {
    let result = selectOptions.find(
      (o) => o.value === value || o.options?.find((o1) => o1.value === value)
    );
    if (result === undefined) {
      return withNoneOption ? noneOption : { label: "", value: "" };
    }
    if (result.value) return result;
    result = result.options?.find((o) => o.value === value);
    if (result) return result;
    return withNoneOption ? noneOption : { label: "", value: "" };
  };

  return (
    <div>
      <FormInputLabel
        labelText={labelText}
        labelClassName={labelClassName}
        id={id}
        lightColor={lightColor}
      />
      <Select
        {...field}
        value={selectedValue(field.value)}
        onChange={(newValue) => handleChange(newValue)}
        options={selectOptions}
        placeholder={placeholder || STRINGS.formInputs.pleaseSelectOne}
        noOptionsMessage={() => STRINGS.formInputs.noOptions}
        className={clsx(common.selectInput, inputClassName)}
        menuPortalTarget={document.body}
        tabIndex={tabIndex}
        isDisabled={disabled}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
      />
      <FormInputSubText
        meta={meta}
        helpText={helpText}
        helpTextClassName={helpTextClassName}
        validationClassName={validationClassName}
      />
    </div>
  );
};

export const SingleSelect = (props: SingleSelectProperties) => {
  return <CustomizableSingleSelect {...props} />;
};
