import React, { ReactElement } from "react";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { useVertragMapping } from "./useVertragMapping";
import { AddContractSelection } from "./base/AddContractSelection";
import { createVertragBeitragIntervalOptions } from "../../../../../utils/vertragUtils";
import { shouldDisplayField } from "./util/displayInputUtil";
import { VertragTyp } from "../../../../../types/backend/VertragTyp";

const beitragsIntervalOptions = createVertragBeitragIntervalOptions();

export const BeitragIntervallInput = ({
  vertragTyp,
}: AddContractInputBaseType): ReactElement | null => {
  const { versicherungenTypes } = useVertragMapping();
  const requiredTypes: VertragTyp[] = [
    "SONSTIGE_VERTRAEGE",
    ...versicherungenTypes,
  ];

  if (!shouldDisplayField(vertragTyp, requiredTypes)) return null;
  return (
    <AddContractSelection
      name={"beitragsIntervall"}
      options={beitragsIntervalOptions}
      labelText={STRINGS.addContract.step2.amountIntervallLabel}
    />
  );
};
