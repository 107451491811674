import styles from "components/user-portal/user-profile/Profile.module.scss";

import React, { ReactElement, useState } from "react";
import EditIdentificationDialog from "components/user-portal/user-profile/dialogs/identification/EditIdentificationDialog";
import ProfileCard from "components/user-portal/user-profile/ProfileCard";
import UserProfileItem from "components/user-portal/user-profile/UserProfileItem";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { formatDate, formatEnum } from "utils/formatUtils";

interface IdentificationCardProps {
  kunde: KundeResponse;
}

export const IdentificationCard = ({
  kunde,
}: IdentificationCardProps): ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <ProfileCard
      title={STRINGS.profile.identification.header}
      className={styles.profileCard}
      onEdit={() => setIsDialogOpen(true)}
    >
      <UserProfileItem
        label={STRINGS.profile.identification.identificationType}
        value={formatEnum(
          kunde.identifizierungen[0]?.ausweisart,
          STRINGS.profile.identification.identificationTypes
        )}
      />
      <UserProfileItem
        label={STRINGS.profile.identification.authority}
        value={kunde.identifizierungen[0]?.behoerde}
      />
      <UserProfileItem
        label={STRINGS.profile.identification.number}
        value={kunde.identifizierungen[0]?.ausweisnummer}
      />
      <UserProfileItem
        label={STRINGS.profile.identification.issuedAt}
        value={formatDate(kunde.identifizierungen[0]?.austellungsdatum)}
      />
      <UserProfileItem
        label={STRINGS.profile.identification.validUntil}
        value={formatDate(kunde.identifizierungen[0]?.gueltigkeitsdatum)}
      />
      <EditIdentificationDialog
        kunde={kunde}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </ProfileCard>
  );
};
