import styles from "components/user-portal/user-profile/dialogs/personal-data/EditPersonalDataForm.module.scss";

import React from "react";
import {
  EditableUserProfileBoolean,
  EditableUserProfileDateField,
  EditableUserProfileNumberField,
  EditableUserProfileSingleSelect,
  EditableUserProfileTextfield,
} from "components/user-portal/user-profile/dialogs/item/EditableUserProfileItem";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { formatEnum } from "utils/formatUtils";
import { createOptions } from "utils/formInputUtils";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";
import { KundeRequest } from "../../../../../types/backend/KundeRequest";

interface EditProfileFormProps {
  formId: string;
  kunde: KundeResponse;
  mutation: UseMutationResult<unknown, unknown, KundeRequest>;
}

const familienstandOptions = createOptions(
  STRINGS.profile.personalData.maritalStatusTypes
);

const wohnsituationOptions = createOptions(
  STRINGS.profile.personalData.livingSituationTypes
);

const EditPersonalDataForm: React.FC<EditProfileFormProps> = ({
  formId,
  kunde,
  mutation,
}) => {
  return (
    <Formik
      initialValues={{ ...(kunde || {}) }}
      onSubmit={(values) => mutation.mutate(values)}
      enableReinitialize={true}
    >
      {(formProps) => {
        return (
          <Form id={formId}>
            <div className={styles.nameContainer}>
              {`${formatEnum(
                kunde.anrede,
                STRINGS.profile.personalData.salutationTypes
              )} ${kunde.titel} ${kunde.vorname} ${kunde.name}`}
            </div>
            <EditableUserProfileTextfield
              fieldName="gebOrt"
              labelText={STRINGS.profile.personalData.birthPlace}
              required
            />
            <EditableUserProfileDateField
              fieldName="geb"
              labelText={STRINGS.profile.personalData.birthday}
              required
            />
            <EditableUserProfileSingleSelect
              fieldName="familienstand"
              options={familienstandOptions}
              labelText={STRINGS.profile.personalData.maritalStatus}
            />
            <EditableUserProfileNumberField
              fieldName="anzahlKinder"
              labelText={STRINGS.profile.personalData.numberOfChildren}
              integersOnly
            />
            <EditableUserProfileSingleSelect
              fieldName="wohnsituation"
              options={wohnsituationOptions}
              labelText={STRINGS.profile.personalData.livingSituation}
            />
            <EditableUserProfileNumberField
              fieldName="wohnflaeche"
              labelText={STRINGS.profile.personalData.livingSpace}
            />
            <EditableUserProfileBoolean
              fieldName="fremdgenutzteImmobilien"
              labelText={STRINGS.profile.personalData.rentedProperty}
            />
            {formProps.values.fremdgenutzteImmobilien && (
              <>
                <EditableUserProfileNumberField
                  fieldName="immobilieBaujahr"
                  labelText={STRINGS.profile.personalData.yearOfConstruction}
                  integersOnly
                />
                <EditableUserProfileNumberField
                  fieldName="neubauSummeImmobilie"
                  labelText={
                    STRINGS.profile.personalData.initialConstructionCosts
                  }
                  integersOnly
                />
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditPersonalDataForm;
