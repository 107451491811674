import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/jagdhaftpflicht_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/jagdhaftpflicht_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/jagdhaftpflicht_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Jagdhaftpflicht";

export const jagdhaftpflichtProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Eine Jagdhaftpflichtversicherung schützt dich, wenn du anderen während deiner Jagdtätigkeit Schaden zufügst. Die Jagdhaftpflichtversicherung ist in Deutschland für Jäger und Jagdaufseher gesetzlich vorgeschrieben.",

  headerButtons: [
    { label: "Vergleichen", link: RouteUrls.jagdhaftpflichtVergleich },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title:
        "... sich auf dem Schießstand ungewollt ein Schuss löst und jemanden verletzt",
      text: icon1,
    },
    {
      title:
        "... du auf einer Treib- oder Drückjagd jemanden durch einen Querschläger triffst",
      text: icon1,
    },
    {
      title:
        "... dein Jagdhund in Ausbildung während der Jagd Hühner vom angrenzenden Bauernhof reißt",
      text: icon1,
    },
    {
      title:
        "... beim Bau eines neuen Hochsitzes Benzin austritt und das betroffene Erdreich saniert werden muss",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine " + productName + " ?",
      paragraphs: [
        "Wenn du als Jäger in Deutschland auf die Pirsch gehen möchtest, dann brauchst du eine Jagdhaftpflichtversicherung.",
        "Solltest du dabei nämlich einen Personen-, Sach- oder Vermögensschaden verursachen, bist du persönlich unbegrenzt dafür haftbar zu machen und die Jagdhaftpflichtversicherung springt ein.",
        "Dazu zählen auch Schäden, die durch die Verwendung von Schusswaffen entstehen können.",
        "Häufig sind in dieser Police auch die Schäden mitversichert, die zugelassene Jagdhunde verursachen. ",
      ],
      image: descriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + " ?",
      paragraphs: [
        "Jäger sind gesetzlich dazu verpflichtet, eine entsprechende Haftpflichtversicherung abzuschließen.",
        "Wenn du kein Jäger bist, brauchst du auch keine Jagdhaftpflichtversicherung – so einfach!",
      ],

      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Personen-, Sach- und Vermögensschäden, die durch Unfälle bei Tätigkeiten oder Unterlassungen entstehen, die in unmittelbarer oder mittelbarer Verbindung mit der Jagd stehen",
        "Forderungsausfalldeckung für Personen- und Sachschäden, die dir durch einen Dritten zugefügt wurden und von diesem nicht gedeckt werden können",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: ["Schäden, die du vorsätzlich herbeigeführt hast"],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  checklist: [
    {
      title: "Abwehr unberechtigter Forderungen",
      text: "Die Versicherungen prüfen, inwiefern für die Schadenersatzforderungen ein berechtigter Anspruch durch die Betroffenen besteht und wehren unberechtigte oder zu hohe Forderungen kostenlos für dich ab. Du erhältst daher mit Abschluss einer Jagdhaftpflichtversicherung auch einen passiven Rechtsschutz.",
    },
    {
      title: "Jagen im Ausland",
      text: "Solltest du im Ausland jagen wollen, bieten einige Versicherer diesen Schutz im Rahmen ihrer Jagdhaftpflichtversicherung mit an. Einige Länder erfordern jedoch spezifische Zusatzversicherungen – achte daher stets vor Antritt einer Jagdreise auf einen angemessenen Versicherungsschutz und lokale Besonderheiten!",
    },
    {
      title: "Jagdhunde",
      text: "Als Besitzer muss du für alle Schäden haften, die durch deinen Jagdhund entstehen können. Die meisten Versicherer versichern heute standardmäßig bis zu drei Jagdhunde in der Jagdhaftpflichtversicherung. Dabei handelt es sich aber noch nicht um den Regelfall. Achte daher vor Abschluss einer Versicherung und bei Anschaffung eines neuen Jagdhundes stets auf einen angemessenen Schutz deiner Versicherung.",
    },
    {
      title: "Forderungsausfalldeckung",
      text: "Wirst du auf der Jagd durch einen anderen Jäger verletzt und dieser kann nicht für den entstandenen Schaden aufkommen, helfen einige Versicherer dir  im Rahmen einer Forderungsausfalldeckung.",
    },
  ],

  link: RouteUrls.jagdHaftpflicht,
};
