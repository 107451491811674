import styles from "components/demand-calculator/DemandCalculator.module.scss";

import React, { ReactElement, useEffect, useState } from "react";
import { DemandCalculatorInputForm } from "./input-form/DemandCalculatorInputForm";
import { BedarfsCheckResponse } from "../../types/backend/BedarfsCheckResponse";
import { FinanzthemaResult } from "../../types/backend/FinanzthemaResult";
import { STRINGS } from "../../language/de_DE/strings";
import { DemandCalculatorResultAccordion } from "./DemandCalculatorResultAccordion";
import { Button } from "../form-inputs/Button/Button";
import { ProductTreeInsuranceType } from "../../content/productTreeDefinition";
import { useDemandCalculatorFormValues } from "./useDemandCalculatorFormValues";
import {
  mapEssentialProductPropositions,
  mapImportantProductPropositions,
  mapRecommendedProductPropositions,
} from "./util/productPropositionUtil";

type ResultType = "essential" | "important" | "recommended";

export type ProductProposition = {
  rang: number;
  product?: ProductTreeInsuranceType;
  sollWert?: number;
  ratio?: number;
  optimumSollWert?: number;
};

export const DemandCalculator = (): ReactElement => {
  const formId = "demandCalculatorFormId";
  const [finanzthemaResults, setFinanzthemaResults] = useState<
    FinanzthemaResult[]
  >([]);
  const [isPristine, setIsPristine] = useState(true);
  const [isResetted, setIsResetted] = useState(false);
  const [openAccordion, setOpenAccordion] = useState<ResultType>("essential");
  const initialValues = useDemandCalculatorFormValues();
  const [essentialProducts, setEssentialProducts] = useState<
    ProductProposition[]
  >([]);
  const [importantProducts, setImportantProducts] = useState<
    ProductProposition[]
  >([]);
  const [recommendedProducts, setRecommendedProducts] = useState<
    ProductProposition[]
  >([]);

  useEffect(() => {
    setEssentialProducts(mapEssentialProductPropositions(finanzthemaResults));
    setImportantProducts(mapImportantProductPropositions(finanzthemaResults));
    setRecommendedProducts(
      mapRecommendedProductPropositions(finanzthemaResults)
    );
  }, [finanzthemaResults]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [openAccordion]);

  useEffect(() => {}, [isPristine, isResetted]);

  return (
    <div className={styles.demandCalculator}>
      <div className={styles.inputPane}>
        <DemandCalculatorInputForm
          formId={formId}
          onSubmit={(response: BedarfsCheckResponse, formValues) => {
            setFinanzthemaResults(response.finanzthemenResults);
            setIsPristine(false);
            if (JSON.stringify(formValues) !== JSON.stringify(initialValues)) {
              setIsResetted(false);
            }
          }}
          onReset={() => {
            setIsPristine(true);
            setFinanzthemaResults([]);
            setOpenAccordion("essential");
            setIsResetted(true);
          }}
        />
      </div>

      <div className={styles.outputPane}>
        {(isPristine || isResetted) && (
          <div>
            <h1>
              {STRINGS.demandCalculator.result.header}{" "}
              <span className={styles.emphasized}>
                {STRINGS.demandCalculator.result.headerEmphasis}
              </span>
            </h1>
            <h2>{STRINGS.demandCalculator.result.subHeader}</h2>
            <Button
              label={STRINGS.demandCalculator.control.calculate}
              form={formId}
              type="submit"
              onClick={() => setIsResetted(false)}
            />
          </div>
        )}
        {!isResetted && (
          <div className={styles.resultContainer}>
            <DemandCalculatorResultAccordion
              title={STRINGS.demandCalculator.result.essentialTitle}
              productPropositions={essentialProducts}
              isOpen={openAccordion === "essential"}
              onHeaderClick={() => setOpenAccordion("essential")}
            />
            <DemandCalculatorResultAccordion
              title={STRINGS.demandCalculator.result.importantTitle}
              productPropositions={importantProducts}
              isOpen={openAccordion === "important"}
              onHeaderClick={() => setOpenAccordion("important")}
            />
            <DemandCalculatorResultAccordion
              title={STRINGS.demandCalculator.result.recommendedTitle}
              productPropositions={recommendedProducts}
              isOpen={openAccordion === "recommended"}
              onHeaderClick={() => setOpenAccordion("recommended")}
            />
          </div>
        )}
      </div>
    </div>
  );
};
