import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import DescriptionImage0 from "gfx/images/insurance-images/gewaesserschadenhaftpflicht_0.jpg";
import DescriptionImage1 from "gfx/images/insurance-images/gewaesserschadenhaftpflicht_1.jpg";
import DescriptionImage2 from "gfx/images/insurance-images/gewaesserschadenhaftpflicht_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Gewässer­schadenhaft­pflicht";

export const gewaesserschadenhaftpflichtProductTree: ProductTreeInsuranceType =
  {
    title: productName,
    image: DescriptionImage0,
    introduction:
      "Wer mit Öl heizt, trägt eine große Verantwortung. Sickert Öl aus deinen Tanks und verseucht das Grundwasser, können die Rettungs­maßnahmen und die Folgeschäden schnell mehrere 100.000 Euro kosten – und deine Existenz als Besitzer bedrohen.",

    headerButtons: [
      {
        label: "Vergleichen",
        link: RouteUrls.gewaesserschadenhaftpflichtVergleich,
      },
      { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
    ],

    insuranceSituation: [
      {
        title:
          "... Öl aus deinem undichten Öltank entweicht und Erdreich und/oder Grundwasser verseucht werden.",
        text: icon1,
      },
      {
        title:
          "... beim Befüllen deines Öltanks durch das Tankfahrzeug Öl austritt und Schäden am Grundwasser verursacht werden.",
        text: icon1,
      },
    ],

    longDescriptions: [
      {
        title: "Was ist eine " + productName + "?",
        paragraphs: [
          "Wer mit Öl heizt, trägt eine große Verantwortung. Öltanks haben oft ein Fassungsvermögen von mehreren Tausend Litern. Sickert Öl aus den Tanks und verseucht das Grundwasser, können die Rettungsmaßnahmen und die Folgeschäden schnell mehrere 100.000 Euro kosten.",
        ],
        image: DescriptionImage1,
      },
      {
        title: "Wer braucht eine " + productName + "?",
        paragraphs: [
          "Aufgrund der aufwändigen Maßnahmen zur Behebung von Schäden durch ausgetretenes Öl ist eine entsprechende Versicherung für jeden Besitzer von Öltanks und Ölheizungen unverzichtbar.",
          "Hast du keine derartigen Einrichtungen, brauchst du meist auch keine Gewässer­schaden-­Haft­pflicht­versicherung - so einfach!",
        ],

        image: DescriptionImage2,
      },
    ],

    insuranceBenefits: [
      {
        titleAccepted: "Die " + productName + " deckt in der Regel:",
        accepted: [
          "Schäden durch Öl, das aus undichten Tanks entweicht und das Grund­wasser verseucht.",
          "Schäden, wenn beim Befüllen durch das Tankfahrzeug Öl ins Erdreich sickert.",
        ],
        titleNotAccepted: "Was wird NICHT versichert?",
        notAccepted: [
          "Schäden, welche vorsätzlich von dir oder Mitversicherten verursacht werden",
          "Schäden an der Öltankanlage selbst",
          "Schäden, welche durch die bewusste Verletzung von den jeweils geltenden Gewässer­schutz­vorschriften entstehen.",
        ],
      },
    ],

    insuranceCompanies: [
      { companyName: "DEVK", image: devkLogo },
      { companyName: "Württembergische", image: wurttLogo },
      { companyName: "Allianz", image: allianzLogo },
      { companyName: "AllSecur", image: allSecurLogo },
      { companyName: "HDI", image: hdiLogo },
      { companyName: "R+V", image: ruvLogo },
      { companyName: "LVM", image: lvmLogo },
    ],

    checklist: [
      {
        title: "Abwehr unberechtigter Forderungen:",
        text: "Die Versicherungen prüfen, inwiefern für die Schadenersatzforderungen ein berechtigter Anspruch durch die Betroffenen besteht und wehren unberechtigte oder zu hohe Forderungen kostenlos für dich ab. Du erhältst daher mit Abschluss einer Gewässerschaden-Haftpflichtversicherung auch einen passiven Rechtsschutz.",
      },
      {
        title: "Übernahme weiterer Kosten:",
        text: "Neben den eigentlichen Kosten zur Behebung der entstandenen Schäden können weitere Folgekosten auf dich zukommen. Hierzu zählen Aufwände für mögliche Sanierungen und Gutachter. Ist dir dieser Schutz wichtig, bieten einige Versicherer die Übernahme weiterer Kosten an.",
      },
    ],

    link: RouteUrls.gewaesserschadenHaftpflicht,
  };
