import React from "react";
import UserPortal from "components/user-portal/UserPortal";

const UserManagement: React.FC = () => {
  return (
    <UserPortal>
      <div>Verwaltung</div>
    </UserPortal>
  );
};

export default UserManagement;
