import styles from "components/organisms/ForgotPasswordForm.module.scss";

import React, { ReactElement, useContext, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";
import LoadingOverlay from "components/loading-overlay/LoadingOverlay";
import StepContainer from "components/step-wizard/components/StepContainer";
import ForgotPasswordWizardContext from "components/step-wizard/forgot-password/ForgotPasswordWizardContext";
import useTabIndex from "hooks/useTabIndex";
import useUserForgotPassword from "hooks/useUserForgotPassword";
import { STRINGS } from "language/de_DE/strings";
import redirectAfterLogin from "utils/redirectAfterLogin";
import { forgetPasswordConfirmFormSchema } from "utils/validators/auth/forgetPasswordForm";
import { Button } from "../form-inputs/Button/Button";
import { PasswordField } from "../form-inputs/PasswordField/PasswordField";

const ForgotPasswordConfirmForm = (): ReactElement => {
  const navigate = useNavigate();
  const { state: forgotPasswordState } =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(ForgotPasswordWizardContext)!;
  const { confirmNewPassword } = useUserForgotPassword();
  const { getTabIndex } = useTabIndex();
  const [forgotPasswordError, setForgotPasswordError] = useState<string | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <StepContainer>
      <LoadingOverlay isVisible={isSubmitting} />
      <Formik
        initialValues={{ password: "" }}
        onSubmit={async (values) => {
          setIsSubmitting(true);
          const { email, code } = forgotPasswordState;
          const result = await confirmNewPassword(email, code, values.password);

          if (!result.ok) {
            setForgotPasswordError(result.message);
            setIsSubmitting(false);
            return;
          }

          redirectAfterLogin(navigate);
        }}
        enableReinitialize
        validationSchema={forgetPasswordConfirmFormSchema}
      >
        <Form>
          <Box flex column className={styles.forgotPasswordBox}>
            <Text size={"xLarge"} className={styles.title} inline>
              {STRINGS.account.forgotPasswordConfirm.title}
            </Text>

            {forgotPasswordError !== null && (
              <Box flex column className={styles.formError}>
                {forgotPasswordError}
              </Box>
            )}

            <Box flex column>
              <Field
                component={PasswordField}
                name={"password"}
                labelText={
                  STRINGS.account.forgotPasswordConfirm.inputPasswordLabel
                }
                placeholder={
                  STRINGS.account.forgotPasswordConfirm.inputPasswordPlaceholder
                }
                helpText={
                  STRINGS.account.forgotPasswordConfirm.inputPasswordHelp
                }
                tabIndex={getTabIndex()}
              />
              <Button
                primary
                label={
                  STRINGS.account.forgotPasswordConfirm
                    .forgotPasswordConfirmButtonLabel
                }
                type={"submit"}
                className={styles.submitButton}
              />
            </Box>
          </Box>
        </Form>
      </Formik>
    </StepContainer>
  );
};

export default ForgotPasswordConfirmForm;
