import React, { ReactElement } from "react";
import UserPortal from "components/user-portal/UserPortal";
import { UserAddContractWorkflow } from "components/user-portal/user-add-contract/UserAddContractWorkflow";

const UserAddContract = (): ReactElement => {
  return (
    <UserPortal>
      <UserAddContractWorkflow />
    </UserPortal>
  );
};

export default UserAddContract;
