import { RouteUrls } from "../config/routes";

type BulletpointType = {
  text: string;
};

export enum ProductTreeType {
  HAUS_EIGENTUM_UND_FREIZEIT = "HAUS_EIGENTUM_UND_FREIZEIT",
  GESUNDHEIT_PFLEGE_EXISTENZ = "GESUNDHEIT_PFLEGE_EXISTENZ",
  VORSORGE_UND_RENTE = "VORSORGE_UND_RENTE",
  REISE_TIER_UND_FREIZEIT = "REISE_TIER_UND_FREIZEIT",
}

export enum InsuranceNames {
  HAUSRATVERSICHERUNG = "HAUSRATVERSICHERUNG",
  WOHNGEBAEUDEVERSICHERUNG = "WOHNGEBAEUDEVERSICHERUNG",
  KUNSTVERSICHERUNG_PRIVAT = "KUNSTVERSICHERUNG_PRIVAT",

  PRIVAT_HAFTPFLICHT = "PRIVAT_HAFTPFLICHT",
  BERUFSHAFTPFLICHT = "BERUFSHAFTPFLICHT",
  BAUHERREN_HAFTPFLICHT = "BAUHERREN_HAFTPFLICHT",
  HAUS_GRUNDBESITZER_HAFTPFLICHT = "HAUS_GRUNDBESITZER_HAFTPFLICHT",
  TIERHALTER_HAFTPFLICHT = "TIERHALTER_HAFTPFLICHT",
  JAGD_HAFTPFLICHT = "JAGD_HAFTPFLICHT",
  GEWAESSERSCHADEN_HAFTPFLICHT = "GEWAESSERSCHADEN_HAFTPFLICHT",
  BETREIBER_HAFTPFLICHT = "BETREIBER_HAFTPFLICHT",
  LUFTFAHRZEUG_HAFTPFLICHT = "LUFTFAHRZEUG_HAFTPFLICHT",
  WASSERFAHRZEUG_HAFTPLICHT = "WASSERFAHRZEUG_HAFTPLICHT",

  KFZ_VERSICHERUNG = "KFZ_VERSICHERUNG",
  MOTORRAD_MOPED_VERSICHERUNG = "MOTORRAD_MOPED_VERSICHERUNG",
  MOPED_ROLLER_VERSICHERUNG = "MOPED_ROLLER_VERSICHERUNG",
  FAHRRADVERSICHERUNG = "FAHRRADVERSICHERUNG",

  AUSLANDSREISE_KRANKENVERSICHERUNG = "AUSLANDSREISE_KRANKENVERSICHERUNG",
  REISERUECKTRITTVERSICHERUNG = "REISERUECKTRITTVERSICHERUNG",
  REISEGEPAECKVERSICHERUNG = "REISEGEPAECKVERSICHERUNG",

  LANGZEITREISE_VERSICHERUNG = "LANGZEITREISE_VERSICHERUNG",
  LANGZEITREISE_KRANKENVERSICHERUNG = "LANGZEITREISE_KRANKENVERSICHERUNG",
  LANGZEITREISE_HAFTPFLICHTVERSICHERUNG = "LANGZEITREISE_HAFTPFLICHTVERSICHERUNG",
  LANGZEITREISE_UNFALLVERSICHERUNG = "LANGZEITREISE_UNFALLVERSICHERUNG",

  ERNTEHELFERKRANKEN_VERSICHERUNG = "ERNTEHELFERKRANKEN_VERSICHERUNG",

  TIERKRANKENVERSICHERUNG = "TIERKRANKENVERSICHERUNG",
  RECHTSSCHUTZVERSICHERUNG = "RECHTSSCHUTZVERSICHERUNG",

  GESETZLICHE_KRANKENVERSICHERUNG = "GESETZLICHE_KRANKENVERSICHERUNG",
  PRIVATE_KRANKENVERSICHERUNG = "PRIVATE_KRANKENVERSICHERUNG",
  PFLEGEPFLICHTVERSICHERUNG = "PFLEGEPFLICHTVERSICHERUNG",

  PFLEGEZUSATZVERSICHERUNG = "PFLEGEZUSATZVERSICHERUNG",
  KRANKENHAUS_TAGEGELD = "KRANKENHAUS_TAGEGELD",
  KRANKENHAUS_ZUSATZ = "KRANKENHAUS_ZUSATZ",
  KRANKEN_TAGEGELD = "KRANKEN_TAGEGELD",

  BERUFSUNFAEHIGKEITS_VERSICHERUNG = "BERUFSUNFAEHIGKEITS_VERSICHERUNG",
  ERWERBSUNFAEHIGKEITS_VERSICHERUNG = "ERWERBSUNFAEHIGKEITS_VERSICHERUNG",
  DIENSTUNFAEHIGKEITS_VERSICHERUNG = "DIENSTUNFAEHIGKEITS_VERSICHERUNG",
  INVALDITAETUNFAEHIGKEITS_VERSICHERUNG = "INVALDITAETUNFAEHIGKEITS_VERSICHERUNG",
  RISIKOLEBENSVERSICHERUNG = "RISIKOLEBENSVERSICHERUNG",
  UNFALLVERSICHERUNG = "UNFALLVERSICHERUNG",
  STERBEGELDVERSICHERUNG = "STERBEGELDVERSICHERUNG",

  ALTERSVORSORGE = "ALTERSVORSORGE",
  PRIVATE_RENTENVERSICHERUNG = "PRIVATE_RENTENVERSICHERUNG",
  KAPITAL_LEBENSVERSICHERUNG = "KAPITAL_LEBENSVERSICHERUNG",
  RIESTER_RENTE = "RIESTER_RENTE",
  RUERUP_RENTE = "RUERUP_RENTE",

  STARTKAPITAL_AUSBILDUNG = "STARTKAPITAL_AUSBILDUNG",

  PFERDE_HAFTPFLICHT = "PFERDE_HAFTPFLICHT",
  HUNDE_HAFTPFLICHT = "HUNDE_HAFTPFLICHT",

  DROHNEN_VERSICHERUNG = "DROHNEN_VERSICHERUNG",
  HOCHZEITS_VERSICHERUNG = "HOCHZEITS_VERSICHERUNG",
  MIETKAUTIONS_VERSICHERUNG = "MIETKAUTIONS_VERSICHERUNG",

  AUFBAU_LIQUIDITAETSRESERVE = "AUFBAU_LIQUIDITAETSRESERVE",
  UEBERSCHULDUNGS_RISIKO = "UEBERSCHULDUNGS_RISIKO",
  ZINSAENDERUNGS_RISIKO = "ZINSAENDERUNGS_RISIKO",
  INSTANDHALTUNG_IMMO = "INSTANDHALTUNG_IMMO",
  EIGENKAPITAL_IMMO_FINANZIERUNG = "EIGENKAPITAL_IMMO_FINANZIERUNG",
  ERSATZINVESTITION_SACHWERTE = "ERSATZINVESTITION_SACHWERTE",
  INVESTITIONS_WUNSCH = "INVESTITIONS_WUNSCH",
}

export type TitledBulletpointType = BulletpointType & {
  title: string;
};

type Box = {
  image: string;
  title: string;
};

export type ComparisonBox = Box & {
  accepted: {
    title: string;
    items: string[];
  };
  notAccepted: {
    title: string;
    items: string[];
  };
};

export type ActionBox = Box & {
  bullets: BulletpointType[];
  action: string;
  actionHref: string;
};

export type BenefitBox = {
  titleAccepted: string;
  titleNotAccepted: string;
  accepted: string[];
  notAccepted: string[];
};

export type LongDescriptionBox = {
  title: string;
  paragraphs: string[];
  image: string;
};

export type InsuranceCompanySlide = {
  companyName: string;
  image: string;
};

export type HeaderButton = {
  label: string;
  link: string;
};

export type ProductTreeInsuranceType = {
  /**
   * The title of the insurance or product (i. e. "Hausratversicherung")
   */
  title: string;
  /**
   * Header image for the insurance detail page. Should be a URL.
   */
  image: string;
  introduction?: string;
  headerButtons?: HeaderButton[];
  /**
   * An optional list with picture dedicated pictures for each bullet.
   */
  insuranceSituation?: TitledBulletpointType[];
  insuranceBenefits?: BenefitBox[];
  /**
   * An optional list of FAQ-style bullets.
   */
  checklist?: TitledBulletpointType[];
  /**
   * A list of action cards, each with a linked button.
   */
  actionBoxes?: ActionBox[];
  /**
   * A list of boxes to compare different tariffs or insurance benefits.
   */
  comparisonBoxes?: ComparisonBox[];
  /**
   * A larger two-fold description for the product, containing images.
   */
  longDescriptions?: LongDescriptionBox[];
  /**
   * A list of insurance companies that offer this product.
   */
  insuranceCompanies?: InsuranceCompanySlide[];
  /**
   * The link to the detail-page of this product.
   */
  link: RouteUrls;

  /**
   * The term used for describing the recommended insurance/investment sum
   */
  recommendedSumLabel?: string;
  /**
   * The interval of the insurance/investment sum
   */
  recommendedSumInterval?: string;
};
/**
 * Dummy bulletpoint. Should be removed soon.
 */
export const emptyBulletpoint: BulletpointType = {
  text: "Informationen werden noch hinzugefügt",
};
