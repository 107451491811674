import { format } from "date-fns";
import { parsePhoneNumber } from "react-phone-number-input";
import { STRINGS } from "language/de_DE/strings";

export const formatEnum = (
  value: string | undefined,
  enumValues: Record<string, string>,
  defaultValue?: string
): string => {
  if (!value) return defaultValue || "-";
  return enumValues[value] || defaultValue || "-";
};

export const formatBoolean = (value?: boolean) => {
  return value
    ? STRINGS.profile.booleanOptions.yes
    : STRINGS.profile.booleanOptions.no;
};

const getDefaultValue = (defaultValue?: string): string => {
  if (defaultValue === undefined || defaultValue === null) {
    return "-";
  }
  return defaultValue;
};

export const formatDate = (date?: string, defaultValue?: string): string => {
  if (!date) return getDefaultValue(defaultValue);
  return format(Date.parse(date), "dd.MM.yyyy");
};

export const formatTime = (date?: string, defaultValue?: string): string => {
  if (!date) return getDefaultValue(defaultValue);
  return format(Date.parse(date), "HH:mm");
};

export const formatPhone = (value?: string, defaultValue?: string): string => {
  if (!value) return getDefaultValue(defaultValue);
  if (value[0] !== "+") value = "+" + value;
  const phoneNumber = parsePhoneNumber(value, "DE");
  if (!phoneNumber || !phoneNumber.isValid())
    return getDefaultValue(defaultValue);

  const international = phoneNumber?.formatInternational();
  const first = international
    ?.slice(0, international?.lastIndexOf(" ") + 1)
    .trim();
  const second = international
    ?.slice(international?.lastIndexOf(" "), international?.length)
    .trim();
  const blankPosition = second.length < 7 ? 0 : second.length === 7 ? 3 : 4;
  return `${first} ${
    second.slice(0, blankPosition) +
    " " +
    second.slice(blankPosition, second.length).trim()
  }`;
};

export const formatIban = (iban: string, defaultValue?: string): string => {
  if (!iban) return defaultValue || iban;
  return iban.replaceAll(" ", "").replace(/.{4}/g, "$& ").trim();
};

export const formatCurrency = (
  value?: number | string,
  defaultValue?: string,
  digits?: number
): string => {
  if (value === undefined || value === null) {
    return getDefaultValue(defaultValue);
  }
  const rawNumber = typeof value === "string" ? Number(value) : value;
  const fractionDigits = digits || digits === 0 ? digits : 2;
  return rawNumber.toLocaleString("de-DE", {
    style: "currency",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    currency: "EUR",
  });
};

export const valueOrEmpty = <T>(value: T | undefined): T | "" => {
  if (value === undefined || value === null) return "";
  return value;
};
