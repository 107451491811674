import React, { FunctionComponent, ReactNode, useEffect } from "react";
import { useLocation } from "react-router";
import { appConfig } from "config/appConfig";
import {
  createInstance,
  MatomoProvider,
  useMatomo,
} from "@jonkoops/matomo-tracker-react";
import MatomoTracker from "@jonkoops/matomo-tracker";
import useUserState from "hooks/useUserState";
import { formatKundeName } from "utils/kundeUtils";

type HasChildrenProps = {
  children: ReactNode;
};

function getMatomoInstance(): MatomoTracker | null {
  if (appConfig.matomoAnalytics?.enabled) {
    return createInstance({
      urlBase: appConfig.matomoAnalytics.matomoUrl,
      siteId: appConfig.matomoAnalytics.siteId,
      /*
       measure outbound links and downloads
       might not work accurately on SPAs because new links (dom elements) are created dynamically without a server-side page reload.

       as a fix, we use the function enableLinkTracking of the Matomo context
       */
      linkTracking: false,
      configurations: {
        disableCookies: true,
        setRequestMethod: "POST",
      },
    });
  } else {
    return null;
  }
}

const matomoInstance = getMatomoInstance();

export const Analytics: FunctionComponent<HasChildrenProps> = ({
  children,
}) => {
  if (matomoInstance === null) {
    return <>{children}</>;
  }
  return (
    <MatomoProvider value={matomoInstance}>
      <Tracking>{children}</Tracking>
    </MatomoProvider>
  );
};

const Tracking: FunctionComponent<HasChildrenProps> = ({ children }) => {
  const { trackPageView, enableLinkTracking, pushInstruction, trackEvent } =
    useMatomo();
  const { pathname } = useLocation();
  const { kunde } = useUserState();

  enableLinkTracking();

  // Track Page views
  useEffect(() => {
    trackPageView();
  }, [pathname]);

  // Track Kunde Loading
  useEffect(() => {
    if (kunde?.kid) {
      pushInstruction("setUserId", kunde?.kid);
      trackEvent({
        category: "Authentication",
        action: "Login",
        name: formatKundeName(kunde),
      });
    }
  }, [kunde?.kid]);

  return <>{children}</>;
};
