import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/langzeitreise_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/langzeitreise_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/langzeitreise_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Lang­zeit­reise­­versicherung";

export const langzeitreiseProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Ohne eine zusätzliche Reise­versicherung trägst du als Studenten, Au-Pair oder beim Work-and-Travel ein enormes Kostenrisiko. In diesen Fällen bieten wir eine spezielle Auslands­reise­versicherung für dich an.",

  headerButtons: [
    {
      label: "Vergleichen",
      link: RouteUrls.langzeitreisekrankenversicherungVergleich,
    },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title:
        "... du während deines Auslands­aufenthaltes krank wirst und zum Arzt musst.",
      text: icon1,
    },
    {
      title: "... dir Medikamente verschrieben werden.",
      text: icon1,
    },
    {
      title:
        "... du wegen einer schweren Verletzung ambulant oder stationär ins Kranken­haus musst.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine " + productName + "?",
      paragraphs: [
        "Eine normale Auslands­kranken­versicherung gilt meist nur für eine Reise für bis zu zwei Monaten.",
        "Bist du aber länger im Ausland unterwegs, benötigst du einen Lang­zeit­schutz.",
        "Für diesen Fall gibt es eine Langzeit-Auslands­reise­kranken­versicherung",
      ],
      image: descriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Ein längerer Auslandsaufenthalt ist immer ein Abenteuer.",
        "Trotz aller Vorfreude solltest du deine gesundheitliche Absicherung nicht vernachlässigen.",
        "Denn wer als Backpacker durch die Welt reist, wegen eines neuen Jobs, als Student oder Austauschschüler längere Zeit im Ausland lebt, ist meistens nicht durch die Krankenversicherung im Heimatland abgesichert.",
      ],

      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Stationäre und ambulante Behandlungen",
        "Schmerzstillende Zahnbehandlung und Reparaturen von Zahnersatz",
        "Arznei und Medizinische Hilfsmittel",
        "Behandlungen im Zusammenhang mit Schwangerschaften",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Vorsorgeuntersuchungen",
        "Besondere Heilbehandlungen z.B. Massagen",
        "Psychotherapie",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.langzeitreiseversicherung,
};
