import axios from "axios";
import api from "api/index";
import { appConfig } from "config/appConfig";
import { ProduktTyp } from "types/backend/ProduktTyp";
import { VertragKapitalanlage } from "types/backend/VertragKapitalanlage";
import { VertragSparanlage } from "types/backend/VertragSparanlage";
import { VertragVersicherung } from "types/backend/VertragVersicherung";
import { VertragVersicherungKfz } from "types/backend/VertragVersicherungKfz";
import { isOk } from "utils/axiosHelpers";

export const fetchVersicherungen = async (
  kid?: number
): Promise<Array<VertragVersicherung | VertragVersicherungKfz> | undefined> => {
  return fetchVertraege<VertragVersicherung | VertragVersicherungKfz>(
    "VERSICHERUNG",
    kid
  );
};

export const fetchGeldanlagen = async (
  kid?: number
): Promise<Array<VertragSparanlage | VertragKapitalanlage> | undefined> => {
  return fetchVertraege<VertragSparanlage | VertragKapitalanlage>(
    "GELDANLAGE",
    kid
  );
};

const fetchVertraege = async <T>(
  produktTyp: ProduktTyp,
  kid?: number
): Promise<Array<T> | undefined> => {
  if (!kid) return undefined;
  const url = `${appConfig.apiUrl}/api/kunden/${kid}/vertraege?produktTyp=${produktTyp}`;
  const header = await api.getHeaderWithAuthorization();
  const result = await axios.get<Array<T>>(url, {
    headers: header,
    validateStatus: isOk,
  });
  return result.data;
};
