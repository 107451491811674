import React, { ReactElement } from "react";
import { Field } from "formik";
import { DateField } from "components/form-inputs/DateField/DateField";

interface AddContractDateInputProps {
  name: string;
  labelText: string;
}

export const AddContractDateInput = ({
  name,
  labelText,
}: AddContractDateInputProps): ReactElement => {
  return <Field component={DateField} name={name} labelText={labelText} />;
};
