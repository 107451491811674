import styles from "components/step-wizard/login/LoginStepWizard.module.scss";

import React, { ReactElement, useState } from "react";
import StepWizard from "react-step-wizard";
import ForgotPasswordCodeForm from "components/organisms/ForgotPasswordCodeForm";
import ForgotPasswordConfirmForm from "components/organisms/ForgotPasswordConfirmForm";
import ForgotPasswordForm from "components/organisms/ForgotPasswordForm";
import { WizardStep } from "components/step-wizard/components/WizardStep";
import ForgotPasswordWizardContext, {
  ForgotPasswordWizardContextState,
  ForgotPasswordWizardContextType,
} from "components/step-wizard/forgot-password/ForgotPasswordWizardContext";

const ForgotPasswordStepWizard = (): ReactElement => {
  const [wizardContextState, setWizardContextState] =
    useState<ForgotPasswordWizardContextState>({
      email: "",
      password: "",
      code: "",
    });

  const wizardProviderValue: ForgotPasswordWizardContextType = {
    state: wizardContextState,
    setState: setWizardContextState,
  };

  return (
    <ForgotPasswordWizardContext.Provider value={wizardProviderValue}>
      <StepWizard isHashEnabled={false}>
        <WizardStep>
          {({ nextStep }) => (
            <div className={styles.formWrapper}>
              <ForgotPasswordForm nextStep={nextStep} />
            </div>
          )}
        </WizardStep>
        <WizardStep>
          {({ isActive, previousStep, nextStep }) => (
            <ForgotPasswordCodeForm
              previousStep={previousStep}
              nextStep={nextStep}
              isActive={isActive ?? false}
            />
          )}
        </WizardStep>
        <WizardStep>
          {() => (
            <div className={styles.formWrapper}>
              <ForgotPasswordConfirmForm />
            </div>
          )}
        </WizardStep>
      </StepWizard>
    </ForgotPasswordWizardContext.Provider>
  );
};

export default ForgotPasswordStepWizard;
