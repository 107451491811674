import styles from "components/message/Toast.module.scss";

import React from "react";
import clsx from "clsx";

interface ToastProps {
  text: string;
  type?: "success" | "error";
}

export const Toast: React.FC<ToastProps> = ({ text, type = "success" }) => {
  return (
    <div
      className={clsx(styles.toast, {
        [styles.success]: type === "success",
        [styles.error]: type === "error",
      })}
    >
      {text}
    </div>
  );
};
