import styles from "components/page/Page.module.scss";

import React, { FunctionComponent, ReactElement, ReactNode } from "react";
import clsx from "clsx";
import NavigationBar from "components/navigation-bar/NavigationBar";
import appBreakpoints from "config/pageBreakpoints";
import useMatchMedia from "hooks/useMatchMedia";
import { Footer } from "../footer/Footer";

export type Props = {
  children: ReactNode;
  darkPage?: boolean;
  contentClassName?: string;
};

const Page: FunctionComponent<Props> = ({
  contentClassName,
  darkPage,
  children,
}): ReactElement => {
  const isMobile = useMatchMedia(`(max-width: ${appBreakpoints.md})`);

  return (
    <div className={clsx(styles.page, darkPage ? styles.darkPage : null)}>
      <NavigationBar />
      <main
        className={clsx(
          styles.content,
          contentClassName,
          isMobile ? styles.contentMobile : ""
        )}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Page;
