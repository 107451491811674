import React from "react";
import AuthenticatedPage from "components/AuthenticatedPage";
import PortalContent from "components/user-portal/user-insurance/UserInsurance";

const UserInsurancePage = () => (
  <AuthenticatedPage>
    <PortalContent />
  </AuthenticatedPage>
);

export default UserInsurancePage;
