import style from "components/atoms/Text.module.scss";

import React, { FunctionComponent, ReactNode } from "react";
import clsx from "clsx";

type Size = "xxSmall" | "xSmall" | "small" | "medium" | "large" | "xLarge";

export type TextProperties = {
  children: ReactNode;
  size: Size;
  bold?: boolean;
  italic?: boolean;
  inline?: boolean;
  className?: string;
};

const classNames = ({
  bold,
  italic,
  size,
}: {
  bold: boolean;
  italic: boolean;
  size: Size;
}) =>
  Object.entries({
    [style.bold]: bold,
    [style.italic]: italic,
    [style[size]]: true,
  })
    .filter(([, value]) => value)
    .map(([name]) => name)
    .join(" ");

export const Text: FunctionComponent<TextProperties> = ({
  children,
  size,
  bold = false,
  italic = false,
  inline = false,
  className,
}) => {
  return inline ? (
    <span className={clsx([classNames({ bold, italic, size }), className])}>
      {children}
    </span>
  ) : (
    <p className={clsx(classNames({ bold, italic, size }), className)}>
      {children}
    </p>
  );
};
