import styles from "components/user-portal/portal-sideboard/DashboardStatsContainer.module.scss";

import React, { ReactElement } from "react";
import { Box } from "components/atoms/Box";
import { Score } from "../score/Score";
import { Text } from "../../atoms/Text";
import { ScoreProgressBar } from "./ScoreProgressBar";
import { STRINGS } from "../../../language/de_DE/strings";
import { usePortalState } from "../../../hooks/usePortalState";

export const DashboardStatsContainer = (): ReactElement => {
  const { score } = usePortalState();

  const handleScore = (score?: number): number => {
    if (score === undefined || score === null) return 0;
    return Math.round(score * 100);
  };

  return (
    <Box className={styles.dashboardStatsContainer}>
      <Box className={styles.dashboardStats}>
        <div className={styles.totalScoreContainer}>
          <Score totalScore={handleScore(score?.total)} />
        </div>
        <Box>
          <Text inline size={"small"} className={styles.barTitle}>
            {STRINGS.sideboard.score.security}
          </Text>
          <ScoreProgressBar
            score={handleScore(score?.absicherung)}
            backgroundColorClass={styles.progressBar1Color}
          />
          <Text inline size={"small"} className={styles.barTitle}>
            {STRINGS.sideboard.score.provision}
          </Text>
          <ScoreProgressBar
            score={handleScore(score?.vorsorge)}
            backgroundColorClass={styles.progressBar2Color}
          />
          <Text inline size={"small"} className={styles.barTitle}>
            {STRINGS.sideboard.score.wealthPlanning}
          </Text>
          <ScoreProgressBar
            score={handleScore(score?.vermoegensplanung)}
            backgroundColorClass={styles.progressBar3Color}
          />
        </Box>
      </Box>
    </Box>
  );
};
