import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/hochzeit_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/hochzeit_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/hochzeit_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Hoch­zeits­versicherung";

export const hochzeitsversicherungProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Wir drücken dir die Daumen, dass du den schönsten Tag deines Lebens ohne Probleme feiern kannst. Trotzdem kommt manchmal etwas dazwischen, weswegen eine Hochzeit verschoben werden muss und genau dann, sichert dich die Hochzeitsversicherung ab.",

  headerButtons: [
    {
      label: "Vergleichen",
      link: RouteUrls.hochzeitsversicherungVergleich,
    },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title: "... die Hochzeit aufgrund von Krankheit verschoben werden muss.",
      text: icon1,
    },
    {
      title:
        "... einer der Dienstleister (Caterer, Photographen, etc.) insolvent wird.",
      text: icon1,
    },
    {
      title:
        "... du die Hochzeitsfeier aufgrund eines Unfalls verschieben musst.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Die Organisation einer Hochzeitsfeier erfordert meist viel Arbeit und kostet eine Menge Geld.",
        "Mit einer speziellen Hochzeitsversicherung kannst du deine Feierlichkeiten und sogar die Kosten deiner Gäste absichern und deine Hochzeit anschließend nachholen.",
      ],
      image: descriptionImage1,
    },

    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Eine Hochzeitsversicherung ist für alle sinnvoll, die den Feierlichkeiten entspannt entgegenblicken wollen.",
        "Viele Paare nehmen jedoch auch einen Kredit für ihre Feierlichkeiten auf.",
        "Gerade in solchen Fällen macht eine Hochzeitsversicherung Sinn, da Sie einen vor finanziellen Risiken bewahren kann, wenn die Hochzeit verschoben werden muss.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Erkrankung des Paares und naher Angehöriger",
        "Unfall oder Tod",
        "Insolvenz des Caterers",
        "Übernachtungs- und Reisekosten der Gäste",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: ["Arbeitsplatzverlust"],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.hochzeitsversicherung,
};
