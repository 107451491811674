import styles from "components/user-portal/user-profile/Profile.module.scss";

import React, { ReactElement, useState } from "react";
import EditBankingAccountDialog from "components/user-portal/user-profile/dialogs/banking-account/EditBankingAccountDialog";
import ProfileCard from "components/user-portal/user-profile/ProfileCard";
import UserProfileItem from "components/user-portal/user-profile/UserProfileItem";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { formatEnum, formatIban } from "utils/formatUtils";

interface BankingAccountCardProps {
  kunde: KundeResponse;
}

export const BankingAccountCard = ({
  kunde,
}: BankingAccountCardProps): ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <ProfileCard
      title={STRINGS.profile.bankingAccount.header}
      className={styles.profileCard}
      onEdit={() => setIsDialogOpen(true)}
    >
      <UserProfileItem
        label={STRINGS.profile.bankingAccount.accountType}
        value={formatEnum(
          kunde.bankverbindungen[0]?.kontoArt,
          STRINGS.profile.bankingAccount.accountTypes
        )}
      />

      <UserProfileItem
        label={STRINGS.profile.bankingAccount.owner}
        value={kunde.bankverbindungen[0]?.inhaber}
      />
      <UserProfileItem
        label={STRINGS.profile.bankingAccount.institut}
        value={kunde.bankverbindungen[0]?.institut}
      />
      <UserProfileItem
        label={STRINGS.profile.bankingAccount.iban}
        value={formatIban(kunde.bankverbindungen[0]?.iban)}
      />
      <UserProfileItem
        label={STRINGS.profile.bankingAccount.bic}
        value={kunde.bankverbindungen[0]?.bic}
      />
      {kunde.bankverbindungen[0]?.kontoArt === "DEPOT" && (
        <UserProfileItem
          label={STRINGS.profile.bankingAccount.depot}
          value={kunde.bankverbindungen[0]?.depot}
        />
      )}
      <EditBankingAccountDialog
        kunde={kunde}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </ProfileCard>
  );
};
