import styles from "components/user-portal/user-profile/Profile.module.scss";

import React, { ReactElement, useState } from "react";
import EditPersonalDataDialog from "components/user-portal/user-profile/dialogs/personal-data/EditPersonalDataDialog";
import ProfileCard from "components/user-portal/user-profile/ProfileCard";
import UserProfileItem from "components/user-portal/user-profile/UserProfileItem";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import {
  formatBoolean,
  formatCurrency,
  formatDate,
  formatEnum,
} from "utils/formatUtils";
import { formatKundeName } from "utils/kundeUtils";

interface PersonalDataCardProps {
  kunde: KundeResponse;
}

export const PersonalDataCard = ({
  kunde,
}: PersonalDataCardProps): ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <ProfileCard
      title={STRINGS.profile.personalData.header}
      onEdit={() => setIsDialogOpen(true)}
      className={styles.profileCard}
    >
      <UserProfileItem
        label={STRINGS.profile.personalData.customerName}
        value={`${formatEnum(
          kunde.anrede,
          STRINGS.profile.personalData.salutationTypes
        )} ${formatKundeName(kunde)}`.trim()}
      />
      <UserProfileItem
        label={STRINGS.profile.personalData.customerNumber}
        value={kunde.kid}
      />
      <UserProfileItem
        label={STRINGS.profile.personalData.birthPlace}
        value={kunde.gebOrt}
      />
      <UserProfileItem
        label={STRINGS.profile.personalData.birthday}
        value={formatDate(kunde.geb)}
      />
      <UserProfileItem
        label={STRINGS.profile.personalData.maritalStatus}
        value={formatEnum(
          kunde.familienstand,
          STRINGS.profile.personalData.maritalStatusTypes
        )}
      />
      <UserProfileItem
        label={STRINGS.profile.personalData.numberOfChildren}
        value={kunde.anzahlKinder}
      />
      <UserProfileItem
        label={STRINGS.profile.personalData.livingSituation}
        value={formatEnum(
          kunde.wohnsituation,
          STRINGS.profile.personalData.livingSituationTypes
        )}
      />
      <UserProfileItem
        label={STRINGS.profile.personalData.livingSpace}
        value={kunde.wohnflaeche}
      />
      <UserProfileItem
        label={STRINGS.profile.personalData.rentedProperty}
        value={formatBoolean(kunde.fremdgenutzteImmobilien)}
      />
      {kunde.fremdgenutzteImmobilien && (
        <>
          <UserProfileItem
            label={STRINGS.profile.personalData.yearOfConstruction}
            value={kunde.immobilieBaujahr}
          />
          <UserProfileItem
            label={STRINGS.profile.personalData.initialConstructionCosts}
            value={formatCurrency(kunde.neubauSummeImmobilie, undefined, 0)}
          />
        </>
      )}
      <EditPersonalDataDialog
        kunde={kunde}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </ProfileCard>
  );
};
