import styles from "components/user-portal/contract/ContractTable.module.scss";

import React, { PropsWithChildren, ReactElement, useState } from "react";
import { getContractDataConfig } from "components/user-portal/contract/ContractDataConfig";
import { ContractTableHeader } from "components/user-portal/contract/ContractTableHeader";
import { ContractTableRow } from "components/user-portal/contract/ContractTableRow";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { ProduktKategorie } from "types/backend/ProduktKategorie";
import { VertragKapitalanlage } from "types/backend/VertragKapitalanlage";
import { VertragSparanlage } from "types/backend/VertragSparanlage";
import { VertragVersicherung } from "types/backend/VertragVersicherung";
import { VertragVersicherungKfz } from "types/backend/VertragVersicherungKfz";
import { formatEnum } from "utils/formatUtils";
import AccordionBox from "../../accordion/AccordionBox";
import { ReactComponent as icon1796 } from "gfx/icons/icon1796.svg";
import { ReactComponent as icon2016 } from "gfx/icons/icon2016.svg";
import { ReactComponent as icon2021 } from "gfx/icons/icon2021.svg";
import { ReactComponent as icon2018 } from "gfx/icons/icon2018.svg";
import { ReactComponent as icon2019 } from "gfx/icons/icon2019.svg";
import { ReactComponent as icon2020 } from "gfx/icons/icon2020.svg";
import { ReactComponent as icon2022 } from "gfx/icons/icon2022.svg";

interface ContractAccordionProps {
  vertraege: Array<
    | VertragVersicherung
    | VertragVersicherungKfz
    | VertragKapitalanlage
    | VertragSparanlage
  >;
  kunde: KundeResponse;
  produktKategorie: ProduktKategorie;
}

const getIcon = (product: ProduktKategorie) => {
  switch (product) {
    case "KAPITALANLAGEN":
      return icon2020;
    case "KFZ_VERSICHERUNGEN":
      return icon2018;
    case "KRANKENVERSICHERUNGEN":
      return icon2016;
    case "LEBENS_UND_RENTENVERSICHERUNGEN":
      return icon2019;
    case "SACH_UND_HAFTPFLICHTVERSICHERUNGEN":
      return icon2022;
    case "SPAREN":
      return icon2021;
    case "SONSTIGE":
      return icon1796;
    default:
      return icon1796;
  }
};

export const ContractAccordion = ({
  vertraege,
  kunde,
  produktKategorie,
}: PropsWithChildren<ContractAccordionProps>): ReactElement => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const filteredVertraege = vertraege.filter(
    (vertrag) => vertrag.produktKategorie === produktKategorie
  );

  if (filteredVertraege.length === 0) return <></>;
  return (
    <AccordionBox
      Icon={getIcon(produktKategorie)}
      title={formatEnum(produktKategorie, STRINGS.vertrag.productCategory)}
      styleClasses={{
        accordionClass: styles.contractAccordion,
        titleClass: styles.contractTitle,
        subtitleClass: styles.contractSubtitle,
        contentClass: styles.contentDivider,
        iconClass: styles.contractIcon,
      }}
      toggleable
      isOpen={isAccordionOpen}
      onHeaderClick={() => setIsAccordionOpen(!isAccordionOpen)}
    >
      <ContractTable
        vertraege={filteredVertraege}
        kunde={kunde}
        produktKategorie={produktKategorie}
      />
    </AccordionBox>
  );
};

interface ContractTableProps {
  vertraege: Array<
    | VertragVersicherung
    | VertragVersicherungKfz
    | VertragKapitalanlage
    | VertragSparanlage
  >;
  kunde: KundeResponse;
  produktKategorie: ProduktKategorie;
}

const ContractTable = ({
  vertraege,
  produktKategorie,
  kunde,
}: PropsWithChildren<ContractTableProps>): ReactElement => {
  const tableConfig = getContractDataConfig(produktKategorie);

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            <ContractTableHeader config={tableConfig} />
          </tr>
        </thead>
        <tbody>
          {vertraege.map((vertrag) => {
            return (
              <ContractTableRow
                key={vertrag.id}
                vertrag={vertrag}
                kunde={kunde}
                config={tableConfig}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
