import styles from "components/navigation-bar/NavigationItemUserIcon.module.scss";

import React, { ReactElement } from "react";
import clsx from "clsx";
import { BiUser } from "react-icons/bi";
import { isActivePage } from "components/navigation-bar/isActivePage";
import { RouteUrls } from "config/routes";

const NavigationItemUserIcon = (): ReactElement => (
  <BiUser
    className={clsx(
      styles.navigationItemUserIcon,
      isActivePage(RouteUrls.userPortalStart) && styles.activeIcon
    )}
  />
);

export default NavigationItemUserIcon;
