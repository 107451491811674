import "react-phone-number-input/style.css";
import styles from "components/organisms/RegistrationForm.module.scss";

import React, { FunctionComponent, useContext, useState } from "react";
import { Field, Form, Formik } from "formik";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Link } from "react-router-dom";
import { Box } from "components/atoms/Box";
import { Button } from "components/form-inputs/Button/Button";
import { TextField } from "components/form-inputs/TextField/TextField";
import { Text } from "components/atoms/Text";
import SmallHint from "components/form/SmallHint";
import StepContainer from "components/step-wizard/components/StepContainer";
import RegistrationWizardContext, {
  UserGender,
} from "components/step-wizard/registration/RegistrationWizardContext";
import { RouteUrls } from "config/routes";
import useTabIndex from "hooks/useTabIndex";
import useUserRegistration from "hooks/useUserRegistration";
import { STRINGS } from "language/de_DE/strings";
import { registrationFormSchema } from "utils/validators/auth/registrationForm";
import { PasswordField } from "../form-inputs/PasswordField/PasswordField";
import { PhoneInputField } from "../form-inputs/PhoneInputField/PhoneInputField";
import { createOptions } from "../../utils/formInputUtils";
import { RadioGroup } from "components/form-inputs/RadioGroup/RadioGroup";

type FormData = {
  email: string;
  password: string;
  phoneNumber: string;
  gender: UserGender;
  firstName: string;
  lastName: string;
};

interface RegistrationFormProperties {
  nextStep?: () => void;
}

const genderOptions = createOptions(STRINGS.account.registration.genderTypes);

const RegistrationForm: FunctionComponent<RegistrationFormProperties> = ({
  nextStep,
}) => {
  const { setState: updateRegistrationState } =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(RegistrationWizardContext)!;
  const [authError, setAuthError] = useState<string | null>(null);
  const { getTabIndex } = useTabIndex();
  const { registerUser } = useUserRegistration();
  return (
    <StepContainer>
      <Text size={"large"} inline className={styles.introductionText}>
        {STRINGS.account.registration.introduction}
      </Text>
      <Formik
        initialValues={{
          email: "",
          password: "",
          gender: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
        }}
        onSubmit={async (values) => {
          setAuthError(null);
          const formikData: FormData = {
            email: values.email,
            password: values.password,
            gender: values.gender as UserGender,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
          };

          const res = await registerUser(formikData);
          if (!res.ok) {
            setAuthError(`${res.message}`);
            return;
          }

          const awsResult = res.data;
          updateRegistrationState({ ...formikData, awsResult });
          nextStep?.();
        }}
        enableReinitialize
        validationSchema={registrationFormSchema}
      >
        {() => (
          <Form>
            <Box flex column className={styles.registerBox}>
              <Text size={"xLarge"} inline className={styles.title}>
                {STRINGS.account.registration.title}
              </Text>

              {authError !== null && (
                <Box flex column className={styles.formErrorContainer}>
                  <Text size="medium" className={styles.formError} inline>
                    {authError}
                  </Text>
                </Box>
              )}

              <Box flex column className={styles.inputsBox}>
                <Box flex column>
                  <RadioGroup
                    name="gender"
                    options={genderOptions}
                    labelText={STRINGS.account.registration.inputGenderLabel}
                    required
                  />
                </Box>
                <Box flex column>
                  <Field
                    component={TextField}
                    name="firstName"
                    labelText={STRINGS.account.registration.inputFirstNameLabel}
                    placeholder={
                      STRINGS.account.registration.inputFirstNamePlaceholder
                    }
                    tabIndex={getTabIndex()}
                  />
                </Box>
                <Box flex column>
                  <Field
                    component={TextField}
                    name="lastName"
                    labelText={STRINGS.account.registration.inputLastNameLabel}
                    placeholder={
                      STRINGS.account.registration.inputLastNamePlaceholder
                    }
                    tabIndex={getTabIndex()}
                  />
                </Box>
                <Box flex column>
                  <Field
                    component={TextField}
                    name="email"
                    labelText={STRINGS.account.registration.inputEmailLabel}
                    placeholder={
                      STRINGS.account.registration.inputEmailPlaceholder
                    }
                    tabIndex={getTabIndex()}
                  />
                </Box>
                <Box flex column>
                  <Field
                    component={PasswordField}
                    name="password"
                    labelText={STRINGS.account.registration.inputPasswordLabel}
                    placeholder={
                      STRINGS.account.registration.inputPasswordPlaceholder
                    }
                    helpText={STRINGS.account.registration.inputPasswordHelp}
                    tabIndex={getTabIndex()}
                  />
                </Box>
                <Box>
                  <Field
                    component={PhoneInputField}
                    name="phoneNumber"
                    labelText={
                      STRINGS.account.registration.inputMobileNumberLabel
                    }
                    placeholder={
                      STRINGS.account.registration.inputMobileNumberPlaceholder
                    }
                    tabIndex={getTabIndex()}
                    validate={(value?: string) => {
                      if (!isValidPhoneNumber(value || "")) {
                        return STRINGS.formInputs.validation.phone(
                          STRINGS.account.registration.inputMobileNumberLabel
                        );
                      }
                    }}
                  />
                </Box>
              </Box>
              <Button
                type="submit"
                className={styles.submitButton}
                primary
                label={STRINGS.account.registration.registerButtonLabel}
              />

              <SmallHint>
                {STRINGS.account.registration.registerHint1}
                <br />
                {STRINGS.account.registration.registerHint2}{" "}
                <Link to={RouteUrls.logIn}>
                  {STRINGS.account.registration.registerHint3}
                </Link>
              </SmallHint>
            </Box>
          </Form>
        )}
      </Formik>
    </StepContainer>
  );
};

export default RegistrationForm;
