import React, { ReactElement } from "react";
import { STRINGS } from "../../language/de_DE/strings";
import { LegalDoc } from "./LegalDoc";

export const Impressum = (): ReactElement => {
  return (
    <LegalDoc title={"Impressum"}>
      <div>
        <h2>Verantwortlich für den Inhalt dieser Webseite gemäß § 5 TMG</h2>
        <div>
          <div>myNORM GmbH</div>
          <div>Rendsburger Straße 14</div>
          <div>30659 Hannover</div>
          <div>Deutschland</div>
        </div>
      </div>

      <div>
        <h2>Kontakt</h2>
        <div>
          <div>Tel: {STRINGS.footer.contact.phone}</div>
          <div>E-Mail: {STRINGS.footer.contact.email}</div>
        </div>
      </div>

      <div>
        <h2>Geschäftsführer</h2>
        <div>
          <div>Michael Rublack und Dr. Thorsten Dylla</div>
          <div>Handelsregister-Nr.: HRB 222889</div>
          <div>Ust-ID: DE 349354244</div>
        </div>
      </div>

      <div>
        <h2>Verantwortlich i.S.d. § 18 Abs. 2 MStV</h2>
        <div>
          <div>Michael Rublack</div>
          <div>c/o myNORM GmbH</div>
          <div>Rendsburger Straße 14</div>
          <div>30659 Hannover</div>
          <div>Deutschland</div>
        </div>
      </div>

      <div>
        <h2>Aufsichtsbehörde für die Erlaubnis nach § 34 d und § 34 f GwO</h2>
        <div>
          <div>Industrie- und Handelskammer Hannover</div>
          <div>Schiffgraben 49</div>
          <div>30175 Hannover</div>
          <div>Deutschland</div>
        </div>
      </div>

      <div>
        <h2>Versicherungsvermittlerregister</h2>
        <div>
          <div>Registerstelle</div>
          <div>Deutscher Industrie- und Handelskammertag (DIHK) e.V.</div>
          <div>Breite Straße 29</div>
          <div>10178 Berlin</div>
          <div>Deutschland</div>
        </div>
        <div>
          <div>Tel: +49 (0) 30 20308-0</div>
          <a href="https://www.vermittlerregister.info">
            www.vermittlerregister.info
          </a>
        </div>
        <div>
          <div>Registriert unter:</div>
          <div>D-QNQL-QNRXP-59 (§ 34 d Abs. 1 GewO)</div>
          <div>D-F-133-YLPZ-51 (§ 34 f Abs. 1 GewO)</div>
        </div>
      </div>

      <div>
        <h2>Verbraucherinformationen</h2>
        <div>
          <div>Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO:</div>
          <div>
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit, die Sie hier finden können:
          </div>
          <div>
            <a href="https://ec.europa.eu/consumers/odr">
              ec.europa.eu/consumers/odr
            </a>
          </div>
        </div>
      </div>

      <div>
        <h2>Schlichtungsstellen</h2>
        <div>
          Zur außergerichtlichen Beilegung von Streitigkeiten zwischen
          Versicherungsnehmer und Vermittler im Zusammenhang mit der Vermittlung
          von Versicherungsverträgen die folgenden unabhängigen
          Schlichtungsstellen gemäß § 214 VVG angerufen werden.
        </div>
        <div>
          <h3>Versicherungsombudsmann e.V.</h3>
          <div>Postfach 08 06 32</div>
          <div>10006 Berlin</div>

          <div>
            <a href="https://www.versicherungsombudsmann.de">
              www.versicherungsombudsmann.de
            </a>
          </div>
        </div>
        <div>
          <h3>Ombudsmann für private Kranken- und Pflegeversicherung</h3>
          <div>Kronenstraße 13</div>
          <div>10117 Berlin</div>
          <div>
            <a href="https://www.pkv-ombudsmann.de">www.pkv-ombudsmann.de</a>
          </div>
        </div>
        <div>
          <h3>Ombudsfrau der privaten Bausparkassen</h3>
          <div>Postfach 30 30 79</div>
          <div>10730 Berlin</div>
          <div>
            <a href="https://www.bausparkassen.de">www.bausparkassen.de</a>
          </div>
        </div>
      </div>

      <div>
        <h2>Beteiligungen</h2>
        <div>
          Die myNORM GmbH besitzt keine direkten oder indirekten Beteiligungen
          von über 10 % an den Stimmrechten oder am Kapital eines
          Versicherungsunternehmens. Darüber hinaus besitzt kein
          Versicherungsunternehmen eine direkte oder indirekte Beteiligung an
          den Stimmrechten oder am Kapital der myNORM GmbH.
        </div>
      </div>
      <div>
        <hr />
      </div>

      <h2>Haftung für Inhalte:</h2>
      <div>
        Als Dienstanbieter sind wir gemäß § 7 Abs. 1 TMG für eigene Inhalte auf
        diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
        bis 10 TMG sind wir als Dienstanbieter jedoch nicht verpflichtet,
        übermittelte oder gespeicherte fremde Informationen zu überwachen oder
        nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
        hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
        Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
        entfernen.
      </div>

      <h2>Haftung für Links:</h2>
      <div>
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
        Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
        Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
        Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
        waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
        inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
        von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      </div>

      <h2>Urheberrecht:</h2>
      <div>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
        sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
        werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Inhalte umgehend entfernen.
      </div>
    </LegalDoc>
  );
};
