import styles from "components/user-portal/user-overview/UserOverviewContent.module.scss";

import React, { ReactElement } from "react";
import { Box } from "components/atoms/Box";
import { dashboardSidebarItems } from "config/dashboardSidebarItems";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "config/routes";

const UserOverviewContent = (): ReactElement => {
  const navigate = useNavigate();

  return (
    <Box className={styles.navigationContainer}>
      {dashboardSidebarItems
        .filter((d) => d.url !== RouteUrls.userPortalStart)
        .map((item) => {
          const Icon = item.Icon;
          return (
            <Box
              key={item.title}
              onClick={() => navigate(item.url)}
              className={styles.navigationCard}
            >
              <Box>
                <Icon className={styles.icon} />
              </Box>
              <Box>{item.title}</Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default UserOverviewContent;
