import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/unfall_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/unfall_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/unfall_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Unfallversicherung";

export const unfallProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,

  introduction:
    "Wer Opfer eines Unfalls wird, hat daraus oftmals erhebliche einmalige oder dauerhafte finanzielle Belastungen zu tragen – zum Beispiel aufgrund von langfristigen körperlichen oder geistigen Beeinträchtigungen oder durch das Abzahlen/Nachzahlen von erbrachten Rettungsmaßnahmen. Die private Unfallversicherung macht es möglich, derartige Vermögensschäden abzusichern.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title: "... wenn du Opfer eines Unfalls wirst.",
      text: icon1,
    },
    {
      title:
        "... wenn zu deiner Rettung- oder Behandlungen Maßnahmen erforderlich werden, die du selbst finanziell nicht stemmen kannst.",
      text: icon1,
    },
    {
      title:
        "... wenn du aufgrund eines Unfalls für einen bestimmten Zeitraum Pflege- oder Unterstützungsleistungen in Anspruch nehmen musst.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Unter einer Unfallversicherung versteht man im Gesundheitssystem eine Versicherung gegen die Folgen eines Unfalls, sowohl die akuten (medizinischer Notfall) als auch die längerfristigen Folgen in Form einer leichten oder schweren Invalidität, sowie teilweise auch der Todesfolge.",
        "Du bist beim Joggen hingefallen, oder wurdest auf deinem Heimweg von einem Roller angefahren. Kleinere Verletzungen sollten normalerweise schneller wieder heilen, doch Unfälle können auch schwere Folgen mit sich bringen.",
        "So kann es schon passieren das du nach einem schweren Unfall dein Haus behindertengerecht umbauen musst. Dabei können recht schnell sehr hohe kosten Anfallen. Davor schützt dich eine Unfallversicherung.",
      ],
      image: descriptionImage1,
    },

    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Besonders wichtig ist eine private Unfallversicherung für Selbstständige, Personen ohne Beruf, Hausfrauen und Hausmänner, für Personen mit einem sehr hohen Unfallrisiko und für Erwerbstätige mit Vorerkrankungen, die keine Berufsunfähigkeitsversicherung abschließen können.",
        "Für Arbeitnehmer, sowie Kinder und Jugendliche greift die gesetzliche Unfallversicherung.  Als Arbeitnehmer ist man durch seinen Arbeitgeber für Arbeits- und Wegeunfälle und bei Berufskrankheiten geschützt.",
        "Kinder und Jugendliche genießen den gleichen Schutz durch den Kindergarten, die Schule bzw. die Universität.",
        "Sobald die Fürsorgepflicht durch den Arbeitgeber, die Schule oder den Kindergarten nicht gewährleistet werden kann, ist es nicht gewiss, dass die gesetzliche Unfallversicherung greift.",
        "Daher kann es ratsam sein, eine private Unfallversicherung abzuschließen, da diese weltweit und 24 Stunden am Tag gilt und neben Arbeitsunfällen auch Freizeitaktivitäten, Unfälle im Haushalt Straßenverkehr und Urlaub abdeckt.",
        "So hat man die Gewissheit, überall und zu jederzeit Schutz zu genießen und kann sich sorgenfrei auf seinen Alltag konzentrieren.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " zahlt in der Regel:",
      accepted: [
        "wenn Invalidität besteht, d.h. wenn die Folgen des Unfalls deine körperlichen und/oder geistigen Fähigkeiten dauerhaft einschränken.",
        "wenn das Schadensereignis plötzlich und ohne längere Vorgeschichte eingetreten ist.",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "wenn das Ereignis nicht durch äußere Kräfte entsteht. So werden z. B. Ursachen wie ein Herzinfarkt nicht abgedeckt",
        "Suizid und Selbstverstümmelung",
        "teilweise kann es sein, dass die Unfallversicherung nur körperliche Schäden und keine geistigen Beeinträchtigungen abdeckt. Informiere dich hierbei bitte nochmal im Kleingedruckten.",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  checklist: [
    {
      title: "Einhaltung der vertraglichen Fristen",
      text: "Im Vertrag über die private Unfallversicherung sind verschiedene Fristen vereinbart, die für die Inanspruchnahme deiner Versicherungsleistung notwendig sind. Dies betrifft insbesondere den Eintritt, die ärztliche Feststellung und die Geltendmachung einer Invalidität: Die Versicherungsbedingungen legen dabei die für dich geltenden Fristen individuell fest und können sich von Versicherer zu Versicherer unterscheiden. Hältst du diese Fristen nicht ein, hast du im besten Fall nur noch einen begrenzten Anspruch auf die Versicherungsleistung.",
    },
    {
      title: "Versicherungssumme",
      text: "Die Versicherungssumme sollte hoch genug sein, um alle notwendigen Kosten abdecken zu können, wie z. B. Umbaumaßnahmen in deinem Haus oder eine Bergrettung durch einen Helikopter bei einem möglichen Skiunfall.",
    },
    {
      title: "Gliedertaxe und Progression",
      text: "Hierbei kommt es immer auf deine individuelle Situation an, so kannst du zwischen einer einfachen oder zwischen einer erweiterten Gliedertaxe wählen, welche dir anhand des festgestellten Invaliditätsgrades an einem bestimmten Körperteil einen bestimmten Geldbetrag auszahlt.",
    },
    {
      title: "Beitragsrückgewähr",
      text: "Bestimmte Unfallversicherungen zahlen dir einen Teil deiner Beiträge am Ende der Vertragslaufzeit zurück, sofern du die Unfallversicherung nicht in Anspruch nehmen musstest.",
    },
  ],

  link: RouteUrls.unfallversicherung,
};
