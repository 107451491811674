import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/pferdehaftpflicht_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/pferdehaftpflicht_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/pferdehaftpflicht_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

const productName = "Pferde­haft­pflicht­versicherung";

export const pferdehaftpflichtProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Tiere verpflichten: Pferde sind Fluchttiere und sehr schreckhaft. Du haftest in unbegrenzter Höhe für alle Personen-, Sach- und Vermögensschäden, die dein Tier verursacht – auch wenn du selbst keine Schuld trägst.",

  headerButtons: [
    { label: "Vergleichen", link: RouteUrls.pferdehaftpflichtVergleich },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title:
        "... dein Pferd auf der Weide ausschlägt und damit ein anderes Pferd verletzt.",
      text: icon1,
    },
    {
      title:
        "... dein Pferd in der Reitschule ausschlägt und die Reit­lehrerin trifft, die aufgrund ihrer Verletzung monatelang keinen Reit­unterricht mehr geben kann",
      text: icon1,
    },
    {
      title:
        "... du eine private Kutschfahrt machst, bei der sich dein Pferd vor einem lauten Geräusch so sehr erschreckt, dass es durchgeht und einen Verkehrsunfall verursacht.",
      text: icon1,
    },
    {
      title:
        "... du mit deinem Pferd im Transportanhänger zu einem Tunier fährst. Nach einer stärkeren Bremsung tritt dein Pferd aus und beschädigt den gemieteten Anhänger.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Pferde sind Fluchttiere und sehr schreckhaft. Ein lautes Geräusch oder ein unvorhergesehenes Ereignis kann dazu führen, dass ein Pferd in Panik gerät, steigt oder durchgeht.",
        "Allein durch ihre Größe und Kraft können Pferde in diesen Situationen erhebliche Schäden verursachen. Sie können den angemieteten Stall beschädigen oder bei ihrer Flucht große Schäden anrichten.",
        "Dabei können Schäden in Millionenhöhe entstehen, für die du als Pferdehalter in voller Höhe haftest – und zwar unabhängig davon, ob dich ein Verschulden trifft.",
        "Die Haftpflicht Versicherung für Pferde schützt Sie vor den Kosten sowie den Folgekosten eines Schadens. Andernfalls haftest du mit deinem gesamten Privatvermögen.",
      ],
      image: descriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Allein die Tatsache, dass du ein Pferd besitzen, macht dich für dessen Verhalten verantwortlich.",
        "Die Pferdehalterhaftpflicht bietet Schutz vor den finanziellen Folgen eines Schades den dein Pferd verursacht.",
        "Sie ist zwar nicht gesetzlich vorgeschrieben, Pferde können jedoch allein aufgrund ihrer Größe erhebliche Schäden verursachen, die eine private Haftpflichtversicherung nicht miteinschließt.",
        "Das kann schnell in einem finanziellen Desaster enden. Eine Pferdehaftpflichtversicherung ist daher für jeden privaten Halter eines Pferdes unverzichtbar.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Sachschäden an gemieteten oder geliehenen Dingen, wie z. B. Mietsachschäden an Pferdeboxen, Stallungen, Reithallen, Koppeln und Transportanhängern.",
        "Personenschäden und daraus resultierende Vermögensschäden, wenn dein Pferd einen Menschen verletzt hat",
        "Schäden, die in der Reitschule, bei Turnieren oder Schauvorführungen entstehen.",
        "Schäden, die bei einer privaten Fahrt mit einer Kutsche oder einem Planwagen entstehen.",
        "Flurschäden: Schäden an einer Weide, in Gärten oder einer landwirtschaftlich genutzten Fläche.",
        "Schäden durch ungewollte Deckakte.",
        "Prüfung der Schadensersatzforderungen und gegebenenfalls Vertretung vor Gericht im Leistungsfall.",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: ["Schäden, die dem Tierhalter selbst entstehen."],
    },
  ],

  checklist: [
    {
      title: "Abwehr unberechtigter Forderungen",
      text: "Die Versicherungen prüfen, inwiefern für die Schadenersatzforderungen ein berechtigter Anspruch durch die Betroffenen besteht und wehren unberechtigte oder zu hohe Forderungen kostenlos für dich ab. Du erhältst daher mit Abschluss einer der benannten Tierhalterhaftpflichtversicherungen auch einen passiven Rechtsschutz.",
    },
    {
      title: "Gewerbliche Nutzung",
      text: "Wenn Pferde gewerblich genutzt werden, beispielsweise als Therapie- oder Schulpferd, benötigst du einen speziellen Tarif. In der Pferdehalterhaftplicht für Privatpersonen ist daher eine gewerbliche Nutzung der Pferde vom Versicherungsschutz meist ausgeschlossen.",
    },
    {
      title: "Deckschäden",
      text: "Wer einen Hengst hat, sollte darauf achten, dass auch sogenannte Deckschäden von der Versicherung übernommen werden. Denn wenn eine fruchtbare Stute unabsichtlich gedeckt wird, kann das unter Umständen teuer werden – zum Beispiel, wenn die Stute ein professionelles Reitpferd ist und dann einige Zeit ausfällt",
    },
    {
      title: "Fohlen",
      text: "Halter von Stuten sollten darauf achten, dass auch Fohlen in den ersten Monaten vom Versicherungsschutz erfasst werden. Ansonsten sind deren Schäden vom Versicherungsschutz der Mutter ausgeschlossen.",
    },
    {
      title: "Reitbeteiligungen",
      text: "Auch bei einer Reitbeteiligung gelten Besonderheiten: Damit die Versicherung auch bei anderen Reitern greift, muss der Pferdehalter darauf achten, dass fremde Reiter mitversichert sind.",
    },
  ],

  link: RouteUrls.pferdehaftpflicht,
};
