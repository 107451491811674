import styles from "components/form-inputs/RadioGroup/RadioGroup.module.scss";

import { Field, useField } from "formik";
import React, { ReactElement } from "react";
import { Option } from "../Selections/SingleSelect";
import { Checkbox } from "../Checkbox/Checkbox";
import { FormInputLabel } from "../FormInputLabel";
import clsx from "clsx";
import { FormInputSubText } from "../FormInputSubText";
import {
  FormInputCustomStyleProperties,
  FormInputProperties,
} from "../FormInputTypes";
import { STRINGS } from "../../../language/de_DE/strings";

type RadioOption = Omit<Option, "options">;

export type RadioGroupProperties = Omit<
  FormInputProperties,
  "form" | "field"
> & {
  name: string;
  labelText?: string;
  options: RadioOption[];
  optionLabelClassName?: string;
  orientation?: "horizontal" | "vertical";
  required?: boolean;
};

const CustomizableRadioGroup = ({
  name,
  labelText,
  labelClassName,
  options,
  optionLabelClassName,
  orientation = "horizontal",
  helpText,
  helpTextClassName,
  validationClassName,
  inputClassName,
  disabled,
  lightColor,
  required,
}: RadioGroupProperties & FormInputCustomStyleProperties): ReactElement => {
  const [, meta] = useField(name);
  return (
    <div>
      <FormInputLabel
        labelText={labelText}
        labelClassName={labelClassName}
        lightColor={lightColor}
      />
      <div
        className={clsx(
          styles.radioGroup,
          orientation === "horizontal" ? styles.horizontal : styles.vertical
        )}
      >
        {options.map((o) => {
          return (
            <Field
              key={o.value}
              type="radio"
              name={name}
              component={Checkbox}
              labelText={o.label}
              labelClassName={optionLabelClassName}
              inputClassName={inputClassName}
              value={o.value}
              disabled={disabled}
              lightColor={lightColor}
              validate={(value: string | boolean) => {
                if (
                  required &&
                  (value === undefined || value === "" || value === null)
                ) {
                  return STRINGS.formInputs.validation.required(labelText);
                }
              }}
            />
          );
        })}
      </div>
      <FormInputSubText
        meta={meta}
        helpText={helpText}
        helpTextClassName={helpTextClassName}
        validationClassName={validationClassName}
      />
    </div>
  );
};

export const RadioGroup = (props: RadioGroupProperties) => {
  return <CustomizableRadioGroup {...props} />;
};
