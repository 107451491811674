type EventHandler = (args?: unknown) => void;

interface Registry {
  unregister: EventHandler;
}

interface Callable {
  [key: string]: EventHandler;
}

interface Subscriber {
  [key: string]: Callable;
}

export default class EventBus {
  private eventId = 1;

  private readonly subscribers: Subscriber = {};

  public dispatch<T>(event: string, arg?: T): void {
    const subscriber = this.subscribers[event];
    if (subscriber === undefined) {
      return;
    }

    Object.keys(subscriber).forEach((key) => subscriber[key](arg));
  }

  public register(event: string, callback: EventHandler): Registry {
    const eventId = this.getNextId();

    if (!this.subscribers[event]) {
      this.subscribers[event] = {};
    }

    this.subscribers[event][eventId] = callback;

    return {
      unregister: () => {
        delete this.subscribers[event][eventId];
        if (Object.keys(this.subscribers[event]).length === 0) {
          delete this.subscribers[event];
        }
      },
    };
  }

  private getNextId(): number {
    return this.eventId++;
  }
}
