import React, { FunctionComponent } from "react";
import styles from "./CampaignInsureNow.module.scss";
import PageSection from "../../page-section/PageSection";
import UnstyledLink from "../../unstyle-link/UnstyledLink";
import { Button } from "../../form-inputs/Button/Button";
import { STRINGS } from "../../../language/de_DE/strings";
import { IoCheckmarkSharp } from "react-icons/io5";

type Props = {
  title: string;
  prose: string;
  checklistIntroduction: string;
  checklistItems: string[];
  image: string;
  buttonLink: string;
};

export const CampaignInsureNow: FunctionComponent<Props> = ({
  title,
  prose,
  checklistIntroduction,
  checklistItems,
  image,
  buttonLink,
}) => (
  <PageSection>
    <div className={styles.content}>
      <div className={styles.textSide}>
        <h2>{title}</h2>
        <p>{prose}</p>
        <p>{checklistIntroduction}</p>
        {checklistItems.map((item) => (
          <div key={item} className={styles.checklistItem}>
            <IoCheckmarkSharp className={styles.checkmark} />
            {item}
          </div>
        ))}
      </div>
      <div className={styles.imageSide}>
        <img src={image} alt={title} className={styles.comparisonImage} />
      </div>
    </div>
    <UnstyledLink to={buttonLink} className={styles.compareNowLink}>
      <Button
        label={STRINGS.campaigns.insureNowButtonLabel}
        className={styles.compareNowButton}
      />
    </UnstyledLink>
  </PageSection>
);
