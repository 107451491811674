import "components/accordion/TextAccordion.scss";

import React, { Component } from "react";

/**
 * TextAccordion
 *
 * @param title - title of the accordion
 * @param content - content of the accordion (text)
 *
 * @author Dominique Börner (kontakt@dominiqueboerner.me)
 */
class TextAccordion extends Component {
  state = {
    visible: false,
  };

  render() {
    return (
      <div className="accordion">
        <div
          className="accordionHead"
          onClick={() => this.setState({ visible: !this.state.visible })}
        >
          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/*@ts-ignore*/}
          <h2 className="accordionTitle">{this.props.title || ""}</h2>
          <div className="accordionIcon">+</div>
        </div>
        <p
          className={`accordionContent ${
            this.state.visible ? "visible" : "hidden"
          }`}
        >
          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/*@ts-ignore*/}
          {this.props.content || ""}
        </p>
      </div>
    );
  }
}

export default TextAccordion;
