import { RouteUrls } from "config/routes";
import { NavigateFunction } from "react-router-dom";

const redirectAfterLogin = (
  navigate: NavigateFunction,
  where: string = RouteUrls.userPortalStart
): void => {
  navigate(where);
};

export default redirectAfterLogin;
