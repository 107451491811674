import { createContext } from "react";

export type AuthenticationCodeFormContextType = {
  email: string;
  password: string;
};

const AuthenticationCodeFormContext =
  createContext<AuthenticationCodeFormContextType>({
    email: "",
    password: "",
  });

export default AuthenticationCodeFormContext;
