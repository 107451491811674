import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/auslandsreisekranken_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/auslandsreisekranken_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/auslandsreisekranken_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

const productName = "Lang­zeit­reise­unfall­versicherung";

export const langzeitreiseunfallProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction: "tbd",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title: "... tbd.",
      text: icon1,
    },
    {
      title: "... tbd.",
      text: icon1,
    },
    {
      title: "... tbd.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine " + productName + "?",
      paragraphs: ["tbd", "tbd", "tbd"],
      image: descriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: ["tbd", "tbd", "tbd"],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: ["tbd", "tbd", "tbd"],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: ["tbd", "tbd", "tbd"],
    },
  ],

  link: RouteUrls.langzeitreiseunfallversicherung,
};
