import common from "components/form-inputs/FormInputCommons.module.scss";

import React from "react";
import clsx from "clsx";
import { v4 } from "uuid";
import { format } from "date-fns";
import { inputDateFormat } from "../../../utils/formInputUtils";
import { FormInputLabel } from "../FormInputLabel";
import { FormInputSubText } from "../FormInputSubText";
import {
  FormInputCustomStyleProperties,
  FormInputProperties,
} from "../FormInputTypes";

type DateInputFieldProperties = FormInputProperties & {
  minDate?: Date;
  maxDate?: Date;
};

const CustomizableDateField = ({
  field,
  form,
  labelText,
  helpText,
  disabled,
  tabIndex,
  placeholder,
  lightColor,
  minDate,
  maxDate,
  inputClassName,
  labelClassName,
  validationClassName,
  helpTextClassName,
}: DateInputFieldProperties & FormInputCustomStyleProperties) => {
  const id = field.name + "-" + v4();
  const meta = form.getFieldMeta(field.name);
  return (
    <div>
      <FormInputLabel
        labelText={labelText}
        labelClassName={labelClassName}
        id={id}
        lightColor={lightColor}
      />
      <input
        {...field}
        id={id}
        type={"date"}
        className={clsx(common.input, inputClassName)}
        placeholder={placeholder}
        tabIndex={tabIndex}
        disabled={disabled}
        min={minDate ? format(minDate, inputDateFormat) : undefined}
        max={maxDate ? format(maxDate, inputDateFormat) : undefined}
      />
      <FormInputSubText
        meta={meta}
        helpText={helpText}
        helpTextClassName={helpTextClassName}
        validationClassName={validationClassName}
      />
    </div>
  );
};

export const DateField = (props: DateInputFieldProperties) => {
  return <CustomizableDateField {...props} />;
};
