import React from "react";
import AuthenticatedPage from "components/AuthenticatedPage";
import PortalContent from "components/user-portal/user-add-contract/UserAddContract";

const UserAddContractPage = () => (
  <AuthenticatedPage>
    <PortalContent />
  </AuthenticatedPage>
);

export default UserAddContractPage;
