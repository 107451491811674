import { FilterBoxOptions } from "components/molecules/FilterBox";

export const sortByPriceMock: Array<FilterBoxOptions> = [
  {
    id: "1",
    name: "höchster zuerst",
  },
  {
    id: "2",
    name: "niedrigster zuerst",
  },
];

export const sortByRatingMock: Array<FilterBoxOptions> = [
  {
    id: "1",
    name: "beste zuerst",
  },
  {
    id: "2",
    name: "schlechteste zuerst",
  },
];
