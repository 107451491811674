import React, { ReactElement } from "react";
import Page from "components/page/Page";
import RegistrationStepWizard from "components/step-wizard/registration/RegistrationStepWizard";
import PageSection from "../../components/page-section/PageSection";

const AdditionalRegistrationDataPage = (): ReactElement => {
  return (
    <Page>
      <PageSection>
        <RegistrationStepWizard initialStep={3} />
      </PageSection>
    </Page>
  );
};

export default AdditionalRegistrationDataPage;
