import { Auth, I18n } from "aws-amplify";
import { ISignUpResult } from "amazon-cognito-identity-js";
import createUsernameFromEmail from "utils/auth/createUsernameFromEmail";

type ErrorWithCode = Error & {
  code: unknown;
};

export type AwsUserPoolData = {
  email: string;
  password: string;
  gender: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

const getErrorMessage = (error: Error | ErrorWithCode | string): string => {
  // AuthError from cognito response
  if (
    typeof error !== "string" &&
    "code" in error &&
    typeof error.code === "string"
  ) {
    return I18n.get(error.code, error.message);
  }
  if (typeof error !== "string") {
    return I18n.get(error.message, error.message);
  }

  return error as string;
};

const getErrorCode = (error: Error | ErrorWithCode | string): string | null => {
  // AuthError from cognito response
  if (
    typeof error !== "string" &&
    "code" in error &&
    typeof error.code === "string"
  ) {
    return error.code;
  }

  return null;
};

export type ApiResponse<TSuccess, TError = string> =
  | {
      ok: true;
      data: TSuccess;
    }
  | {
      ok: false;
      message: TError;
      code: string | null;
    };

export const deleteUser = async (): Promise<ApiResponse<void>> => {
  try {
    await Auth.deleteUser();
    return { ok: true, data: undefined };
  } catch (error) {
    return {
      ok: false,
      message: getErrorMessage(error as Error),
      code: getErrorCode(error as Error),
    };
  }
};

const registerUser = async ({
  email,
  password,
  gender,
  firstName,
  lastName,
  phoneNumber,
}: AwsUserPoolData): Promise<ApiResponse<ISignUpResult>> => {
  const username = createUsernameFromEmail(email);

  try {
    const result = await Auth.signUp({
      username,
      password: password,
      attributes: {
        email: email,
        gender,
        name: firstName,
        family_name: lastName,
        phone_number: phoneNumber,
      },
    });

    return {
      ok: true,
      data: result,
    };
  } catch (error) {
    return {
      ok: false,
      message: getErrorMessage(error as Error),
      code: getErrorCode(error as Error),
    };
  }
};

const confirmRegistration = async (
  email: string,
  code: string
): Promise<ApiResponse<string>> => {
  const username = createUsernameFromEmail(email);

  try {
    const result: "SUCCESS" = await Auth.confirmSignUp(username, code);

    return {
      ok: true,
      data: result,
    };
  } catch (error) {
    return {
      ok: false,
      message: getErrorMessage(error as Error),
      code: getErrorCode(error as Error),
    };
  }
};

type UseUserRegistrationReturnType = {
  confirmRegistration: (
    email: string,
    code: string
  ) => Promise<ApiResponse<string>>;
  registerUser: ({
    email,
    password,
    gender,
    firstName,
    lastName,
    phoneNumber,
  }: AwsUserPoolData) => Promise<ApiResponse<ISignUpResult>>;
  deleteUser: () => Promise<ApiResponse<void>>;
};

const useUserRegistration = (): UseUserRegistrationReturnType => {
  return {
    registerUser,
    confirmRegistration,
    deleteUser,
  };
};
export default useUserRegistration;

export { getErrorMessage, getErrorCode };
