import React, { FunctionComponent } from "react";
import styles from "./OurPromise.module.scss";
import { STRINGS } from "../../../../language/de_DE/strings";
import ourPromiseIMG from "gfx/images/ourpromise.jpg";
import PageSection from "../../../page-section/PageSection";

const sectionStrings = STRINGS.aboutUs.ourPromise;

export const OurPromise: FunctionComponent = () => (
  <PageSection>
    <div className={styles.content}>
      <div className={styles.text}>
        <h1 className={styles.title}>
          {sectionStrings.titleFirstLine}
          <span className={styles.highlighted}>
            {sectionStrings.titleSecondLine}
          </span>
        </h1>
        {sectionStrings.paragraphs.map((paragraph: string) => (
          <p key={paragraph}>{paragraph}</p>
        ))}
      </div>
      <img
        src={ourPromiseIMG}
        alt={sectionStrings.titleSecondLine}
        className={styles.image}
      />
    </div>
  </PageSection>
);
