import React from "react";
import AuthenticatedPage from "components/AuthenticatedPage";
import UserContact from "components/user-portal/user-contact/UserContact";

const UserContactPage = () => (
  <AuthenticatedPage>
    <UserContact />
  </AuthenticatedPage>
);

export default UserContactPage;
