import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/krankenhaustagegeld_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/krankenhaustagegeld_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/krankenhaustagegeld_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Kranken­haus­tagegeld­versicherung";

export const krankenhaustagegeldProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Deine Kranken­haus­tage­geld­versicherung ist dein Taschengeld während eines Krankenhausaufenthaltes – komplett zur eigenen Verfügung.",

  insuranceSituation: [
    {
      title: "... du chronisch erkrankt und dadurch oft im Krankenhaus bist.",
      text: icon1,
    },
    {
      title:
        "... du dich absichern möchtest für den Fall, dass du langfristig ins Krankenhaus musst.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Eine Kranken­haus­tage­geld­versicherung zahlt einen vereinbarten Betrag für jeden Tag, den du im Krankenhaus verbringst.",
        "Damit kann die versicherte Person beispielsweise den Betrag von 10 € pro Tag abdecken, welcher bei Krankenhausaufenthalten fällig wird.",
        "Man kann das Geld aus der Kranken­haus­tage­geld­versicherung darüber hinaus auch für andere Zwecke nutzen: Telefonkosten, Betreuungskosten der Kinder und anderes sind valide Nutzungszwecke der Versicherung.",
      ],
      image: descriptionImage1,
    },

    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Eine Kranken­haus­tage­geld­versicherung ergibt Sinn für Menschen, die viel Zeit im Krankenhaus verbringen und für welche sich eine monatliche Rate in eine solche Versicherung rentiert.",
        "Dazu zählen chronisch erkrankte Patienten und auch Personen, die wissen, dass sie in naher Zukunft häufig das Krankenhaus besuchen werden müssen.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: ["Aufenthalte bei medizinisch notwendigen Behandlungen"],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Schönheitschirurgische Eingriffe, die medizinisch nicht als notwendig zu erachten sind",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.krankenhaustagegeld,
};
