import React, { ReactElement } from "react";
import { createOptions } from "../../../../../utils/formInputUtils";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { AddContractSelection } from "./base/AddContractSelection";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { VertragTyp } from "../../../../../types/backend/VertragTyp";
import { shouldDisplayField } from "./util/displayInputUtil";

const options = createOptions(STRINGS.vertrag.scheduleOfFees);

const requiredTypes: VertragTyp[] = ["KRANKENZUSATZVERSICHERUNG"];

type GebuehrenordnungSelectionProps = {
  name:
    | "ambulantGebuehrenordnung"
    | "stationaerGebuehrenordnung"
    | "zahnGebuehrenordnung";
  labelText: string;
};

export const GebuehrenordnungSelection = ({
  vertragTyp,
  labelText,
  name,
}: AddContractInputBaseType &
  GebuehrenordnungSelectionProps): ReactElement | null => {
  if (!shouldDisplayField(vertragTyp, requiredTypes)) return null;
  return (
    <AddContractSelection name={name} options={options} labelText={labelText} />
  );
};
