import React, { ReactElement } from "react";
import styles from "./UserContact.module.scss";
import UserPortal from "components/user-portal/UserPortal";

import { STRINGS } from "language/de_DE/strings";
import CalendlyPopupButton from "../../calendly-popup-button/CalendlyPopupButton";
import contactImg from "gfx/images/contact/contact.jpg";
import {
  IoBusinessSharp,
  IoCallOutline,
  IoGlobeOutline,
} from "react-icons/io5";
import { MdAlternateEmail } from "react-icons/md";

const sectionStrings = STRINGS.userContact;

const OnlineContactBox = () => (
  <div className={styles.contactOptionsWeb}>
    <h2>{sectionStrings.contactOptionsWeb.title}</h2>
    <div>
      <IoGlobeOutline className={styles.icon} />
    </div>
    {sectionStrings.contactOptionsWeb.prose}

    <CalendlyPopupButton
      className={styles.calendlyButton}
      buttonLabel={sectionStrings.userContactCalendlyButton}
    />
  </div>
);

const OtherContactBox = () => (
  <div className={styles.contactOptionsOther}>
    <h2>{sectionStrings.contactOptionsOther.title}</h2>
    {sectionStrings.contactOptionsOther.prose}

    <div className={styles.otherContactLines}>
      <IoCallOutline className={styles.icon} />
      <a href={"tel:" + STRINGS.footer.contact.phoneLink}>
        {STRINGS.footer.contact.phone}
      </a>

      <MdAlternateEmail className={styles.icon} />
      <a
        href={"mailto:" + STRINGS.footer.contact.email}
        className={styles.contactLine}
      >
        {STRINGS.footer.contact.email}
      </a>

      <IoBusinessSharp className={styles.icon} />
      <div>
        {sectionStrings.contactOptionsOther.addressLines.map((line: string) => (
          <div key={line}>{line}</div>
        ))}
      </div>
    </div>
  </div>
);

const UserContact = (): ReactElement => {
  return (
    <UserPortal>
      <section className={styles.section}>
        <h1>{sectionStrings.title}</h1>
        <div className={styles.contactIntroduction}>
          <img
            src={contactImg}
            alt={sectionStrings.title}
            className={styles.contactIntroductionImage}
          />
          <div className={styles.contactIntroductionText}>
            <h2>{sectionStrings.subtitle}</h2>
            {sectionStrings.introduction.paragraphs.map((paragraph: string) => (
              <p key={paragraph}>{paragraph}</p>
            ))}
          </div>
        </div>

        <div className={styles.contactOptions}>
          <OnlineContactBox />
          <OtherContactBox />
        </div>
      </section>
    </UserPortal>
  );
};

export default UserContact;
