import React, { FunctionComponent, useCallback, useState } from "react";
import { PopupModal } from "react-calendly";
import { calendlyUrl } from "../../config/CalendlyConfig";
import { Button } from "../form-inputs/Button/Button";

type Props = {
  buttonLabel: string;
  className?: string;
};

const CalendlyPopupButton: FunctionComponent<Props> = ({
  buttonLabel,
  className,
}) => {
  const [calendlyDialogOpen, setCalendlyDialogOpen] = useState(false);

  const closeCalendlyDialog = useCallback(
    () => setCalendlyDialogOpen(false),
    []
  );
  const openCalendlyDialog = useCallback(() => setCalendlyDialogOpen(true), []);

  return (
    <>
      <PopupModal
        onModalClose={closeCalendlyDialog}
        open={calendlyDialogOpen}
        rootElement={document.getElementById("root") as HTMLElement}
        url={calendlyUrl}
      />

      <Button
        label={buttonLabel}
        onClick={openCalendlyDialog}
        className={className}
      />
    </>
  );
};

export default CalendlyPopupButton;
