import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/dummy_0.jpg";

const productName = "Eigenkapital Immobilienfinanzierung";

export const eigenkapitalimmofinanzierungProductTree: ProductTreeInsuranceType =
  {
    title: productName,
    image: descriptionImage0,
    introduction: "tbd",

    headerButtons: [
      { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
      { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
    ],

    link: RouteUrls.eigenkapitalimmofinanzierung,
  };
