import React, { FunctionComponent, useEffect, useRef } from "react";
import Page from "components/page/Page";
import PageSection from "../../components/page-section/PageSection";
import styles from "./CovomoIframePage.module.scss";

type Props = {
  covomoLink: string;
  title: string;
};

const CovomoIframePage: FunctionComponent<Props> = ({ covomoLink, title }) => {
  const ref = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const handler = (e: MessageEvent) => {
      if (Object.prototype.hasOwnProperty.call(e.data, "frameHeight")) {
        if (ref.current) {
          ref.current.style.height = e.data.frameHeight + "px";
        }
      }
    };
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  });

  return (
    <Page>
      <PageSection>
        <h1 className={styles.sectionTitle}>{title}</h1>
        <iframe
          id="covomo-iframe"
          src={covomoLink}
          className={styles.covomoIframe}
          scrolling="yes"
          ref={ref}
        />
      </PageSection>
    </Page>
  );
};

export default CovomoIframePage;
