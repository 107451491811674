import {
  RegistrationWizardContextState,
  UserGender,
} from "components/step-wizard/registration/RegistrationWizardContext";
import { Anrede } from "types/backend/Anrede";
import { KundeRequest } from "types/backend/KundeRequest";
import { KundeResponse } from "types/backend/KundeResponse";
import { AdditionalRegistrationDataFormValues } from "types/registration/AdditionalRegistrationDataFormValues";
import { valueOrEmpty } from "utils/formatUtils";

export const convertGender = (gender: UserGender): Anrede => {
  switch (gender) {
    case "female":
      return "FRAU";
    case "male":
      return "HERR";
    case "nonBinary":
    default:
      return "DIVERS";
  }
};

export const formatKundeName = (kunde?: KundeResponse): string => {
  return `${valueOrEmpty(kunde?.titel)} ${valueOrEmpty(
    kunde?.vorname
  )} ${valueOrEmpty(kunde?.name)}`;
};

export const createUpdateKundeRequest = (
  kunde: KundeResponse,
  formValues: AdditionalRegistrationDataFormValues
): KundeRequest => {
  const id =
    kunde.adressen && kunde.adressen.length > 0
      ? kunde.adressen[0].id
      : undefined;
  return {
    ...kunde,
    geb: formValues.birthday,
    gebOrt: formValues.birthPlace,
    adressen: [
      {
        id,
        strasse: formValues.street,
        plz: formValues.postalCode,
        ort: formValues.city,
      },
    ],
  };
};

export const createNewKundeRequest = (
  registrationValues: RegistrationWizardContextState,
  additionalRegistrationValues: AdditionalRegistrationDataFormValues
): KundeRequest => {
  const { gender, firstName, lastName, phoneNumber, email } =
    registrationValues;
  const { street, postalCode, city, birthday, birthPlace } =
    additionalRegistrationValues;
  return {
    anrede: convertGender(gender),
    vorname: firstName,
    name: lastName,
    geb: birthday,
    gebOrt: birthPlace,
    kontaktdaten: [
      {
        art: "E_MAIL",
        wert: email,
      },
      {
        art: "TELEFON",
        wert: phoneNumber,
      },
    ],
    adressen: [
      {
        strasse: street,
        plz: postalCode,
        ort: city,
      },
    ],
    bankverbindungen: [],
    identifizierungen: [],
  };
};
