import styles from "components/user-portal/portal-sideboard/PortalSideboard.module.scss";

import React, { ReactElement } from "react";
import { Box } from "components/atoms/Box";
import { DashboardListContainer } from "components/user-portal/portal-sideboard/DashboardListContainer";
import { DashboardStatsContainer } from "components/user-portal/portal-sideboard/DashboardStatsContainer";
import { STRINGS } from "language/de_DE/strings";
import { formatKundeName } from "utils/kundeUtils";
import { PortalSideboardHeader } from "./PortalSideboardHeader";
import { usePortalState } from "../../../hooks/usePortalState";
import useUserState from "../../../hooks/useUserState";

const PortalSideboard = (): ReactElement => {
  const { kunde } = useUserState();
  const { versicherungen, geldanlagen } = usePortalState();

  return (
    <Box className={styles.portalSideboard}>
      <Box>
        <h2 className={styles.kundeName}>{formatKundeName(kunde)}</h2>
      </Box>
      <PortalSideboardHeader text={STRINGS.sideboard.score.header} />
      <DashboardStatsContainer />
      <Box className={styles.lists}>
        <PortalSideboardHeader text={STRINGS.sideboard.contracts.header} />
        <Box className={styles.versicherungenList}>
          <DashboardListContainer
            header={STRINGS.sideboard.contracts.insurances}
            vertraege={versicherungen || []}
            produktKategories={[
              "SACH_UND_HAFTPFLICHTVERSICHERUNGEN",
              "KFZ_VERSICHERUNGEN",
              "KRANKENVERSICHERUNGEN",
              "LEBENS_UND_RENTENVERSICHERUNGEN",
              "SONSTIGE",
            ]}
          />
        </Box>
        <Box>
          <DashboardListContainer
            header={STRINGS.sideboard.contracts.investments}
            vertraege={geldanlagen || []}
            produktKategories={["SPAREN", "KAPITALANLAGEN"]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PortalSideboard;
