import styles from "components/user-portal/user-profile/UserProfileItem.module.scss";

import React from "react";

interface UserProfileItemProps {
  label: string;
  value: any;
}

const UserProfileItem: React.FC<UserProfileItemProps> = ({ label, value }) => {
  return (
    <div className={styles.userProfileItem}>
      <div className={styles.label}>{label}:</div>
      <div className={styles.valueContainer}>
        <div className={styles.value}>
          {value === undefined || value === null ? "-" : value}
        </div>
      </div>
    </div>
  );
};

export default UserProfileItem;
