import React, { ReactElement } from "react";
import { Field } from "formik";
import { AddContractTextInput } from "./base/AddContractTextInput";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { shouldDisplayField } from "./util/displayInputUtil";

export const KfzBezeichnungInput = ({
  vertragTyp,
}: AddContractInputBaseType): ReactElement | null => {
  if (!shouldDisplayField(vertragTyp, ["KFZ_VERSICHERUNG"])) return null;
  return (
    <Field
      component={AddContractTextInput}
      name="kfzBezeichnung"
      labelText={STRINGS.addContract.step2.vehicleTypeLabel}
    />
  );
};
