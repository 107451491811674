import common from "components/form-inputs/FormInputCommons.module.scss";
import styles from "components/form-inputs/NumberField/NumberField.module.scss";

import React, { ReactElement } from "react";
import clsx from "clsx";
import NumberFormat from "react-number-format";
import { v4 } from "uuid";
import { FormInputLabel } from "../FormInputLabel";
import { FormInputSubText } from "../FormInputSubText";
import {
  FormInputCustomStyleProperties,
  FormInputProperties,
} from "../FormInputTypes";

type NumberFieldProperties = FormInputProperties & {
  integersOnly?: boolean;
};

const CustomizableNumberField = ({
  field,
  form,
  labelText,
  helpText,
  disabled,
  tabIndex,
  placeholder,
  lightColor,
  integersOnly,
  inputClassName,
  labelClassName,
  validationClassName,
  helpTextClassName,
}: NumberFieldProperties & FormInputCustomStyleProperties): ReactElement => {
  const id = field.name + "-" + v4();
  const meta = form.getFieldMeta(field.name);
  return (
    <div>
      <FormInputLabel
        labelText={labelText}
        labelClassName={labelClassName}
        id={id}
        lightColor={lightColor}
      />
      <NumberFormat
        {...field}
        id={id}
        allowedDecimalSeparators={[".", ","]}
        decimalSeparator="."
        decimalScale={integersOnly ? 0 : undefined}
        value={field.value}
        placeholder={placeholder}
        className={clsx(common.input, styles.number, inputClassName)}
        onChange={field.onChange}
        disabled={disabled}
        tabIndex={tabIndex}
      />
      <FormInputSubText
        meta={meta}
        helpText={helpText}
        helpTextClassName={helpTextClassName}
        validationClassName={validationClassName}
      />
    </div>
  );
};

export const NumberField = (props: NumberFieldProperties) => {
  return <CustomizableNumberField {...props} />;
};
