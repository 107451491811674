import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/privatekranken_0.jpg";

export const privatekrankenProductTree: ProductTreeInsuranceType = {
  title: "Private Krankenversicherung",
  image: descriptionImage0,
  introduction:
    "Diese Versicherungen ist besonders gut für dich geeignet, da sie dich in jeder Situation vollumfänglich absichert",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  link: RouteUrls.pkv,
};
