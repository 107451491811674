import React from "react";
import AuthenticatedPage from "components/AuthenticatedPage";
import PortalContent from "components/user-portal/user-profile/UserPortalProfile";

const UserProfilePage = () => (
  <AuthenticatedPage>
    <PortalContent />
  </AuthenticatedPage>
);

export default UserProfilePage;
