import React, { FunctionComponent, ReactNode } from "react";
import styles from "./MyNormBoasting.module.scss";
import { STRINGS } from "../../../../../language/de_DE/strings";
import clsx from "clsx";
import PageSection from "../../../../page-section/PageSection";

const sectionStrings = STRINGS.startpage.boasting;

type TileProps = {
  size: "large" | "small";
  background: "light" | "dark";
  children: ReactNode;
};

const Tile: FunctionComponent<TileProps> = ({ children, size, background }) => (
  <div
    className={clsx(styles.tile, {
      [styles.darkBackground]: background === "dark",
      [styles.lightBackground]: background === "light",
      [styles.large]: size === "large",
      [styles.small]: size === "small",
    })}
  >
    {children}
  </div>
);

const DinTile = () => (
  <Tile size={"large"} background={"light"}>
    <h3>{sectionStrings.dinTile.title}</h3>
    {sectionStrings.dinTile.prose}
  </Tile>
);
const ConsumerProtectionTile = () => (
  <Tile size={"large"} background={"dark"}>
    <h3>{sectionStrings.consumerProtectionTile.title}</h3>
    {sectionStrings.consumerProtectionTile.prose}
  </Tile>
);

const AssetUnderManagementTile = () => (
  <Tile size={"small"} background={"dark"}>
    <h3>{sectionStrings.assetUnderManagementTile.title}</h3>
    {sectionStrings.assetUnderManagementTile.prose}
  </Tile>
);

const ExperienceTile = () => (
  <Tile size={"small"} background={"light"}>
    <h3>{sectionStrings.experienceTile.title}</h3>
    {sectionStrings.experienceTile.prose}
  </Tile>
);

const ManpowerTile = () => (
  <Tile size={"small"} background={"dark"}>
    <h3>{sectionStrings.manpowerTile.title}</h3>
    {sectionStrings.manpowerTile.prose}
  </Tile>
);

export const MyNormBoasting: FunctionComponent = () => (
  <PageSection contentClassName={styles.section}>
    <h1 className={styles.title}>
      <span className={styles.highlighted}>
        {sectionStrings.titleHighlightedPrefix}
      </span>
      {sectionStrings.title}
    </h1>
    <div className={styles.upperTiles}>
      <DinTile />
      <ConsumerProtectionTile />
    </div>
    <div className={styles.lowerTiles}>
      <AssetUnderManagementTile />
      <ExperienceTile />
      <ManpowerTile />
    </div>
  </PageSection>
);
