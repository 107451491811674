import React, { FunctionComponent } from "react";
import { ContractToggleableContent } from "components/user-portal/contract/ContractToggleableContent";
import useUserState from "hooks/useUserState";
import { STRINGS } from "language/de_DE/strings";
import { ProduktKategorie } from "types/backend/ProduktKategorie";
import { usePortalState } from "../../../hooks/usePortalState";

export const UserInvestmentContracts: FunctionComponent = () => {
  const { kunde } = useUserState();
  const { geldanlagen } = usePortalState();

  const produktKategories: ProduktKategorie[] = ["SPAREN", "KAPITALANLAGEN"];
  if (!kunde) return <></>;
  return (
    <ContractToggleableContent
      title={STRINGS.vertrag.investments.header}
      vertraege={geldanlagen || []}
      kunde={kunde}
      produktKategories={produktKategories}
    />
  );
};
