import axios from "axios";
import api from "api/index";
import { appConfig } from "config/appConfig";
import { isOk } from "utils/axiosHelpers";
import { SendNachrichtRequest } from "types/userportal/SendNachrichtRequest";

export const sendNachricht = async (
  sendNachrichtRequest: SendNachrichtRequest
): Promise<void> => {
  const url = `${appConfig.apiUrl}/api/kunden/${sendNachrichtRequest.kid}/nachrichten`;
  const header = await api.getHeaderWithAuthorization();
  await axios.post(url, sendNachrichtRequest.nachrichtRequest, {
    headers: header,
    validateStatus: isOk,
  });
};
