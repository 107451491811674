import styles from "components/demand-calculator/ProductPropositionEntry.module.scss";

import React, { ReactElement } from "react";
import { STRINGS } from "language/de_DE/strings";
import { ProductProposition } from "./DemandCalculator";
import { formatCurrency } from "../../utils/formatUtils";

const getInsuranceSumText = ({
  sollWert,
  optimumSollWert,
  product,
}: ProductProposition): string => {
  if (
    optimumSollWert &&
    optimumSollWert !== 1 &&
    optimumSollWert !== sollWert
  ) {
    return STRINGS.demandCalculator.result.optimumInsuranceSum(
      product?.recommendedSumLabel ||
        STRINGS.demandCalculator.result.defaultRecommendedSumLabel,
      formatCurrency(optimumSollWert, "", 0),
      product?.recommendedSumInterval || ""
    );
  }
  if (sollWert && sollWert !== 1) {
    return STRINGS.demandCalculator.result.minimumInsuranceSum(
      product?.recommendedSumLabel ||
        STRINGS.demandCalculator.result.defaultRecommendedSumLabel,
      formatCurrency(sollWert, "", 0),
      product?.recommendedSumInterval || ""
    );
  }
  return "";
};
interface ProductPropositionEntryProps {
  productProposition?: ProductProposition;
}
export const ProductPropositionEntry = ({
  productProposition,
}: ProductPropositionEntryProps): ReactElement | null => {
  if (!productProposition?.product) return null;
  const insuranceSumText = productProposition
    ? getInsuranceSumText(productProposition)
    : undefined;

  return (
    <div className={styles.productEntry}>
      <div>
        <div className={styles.header}>
          <h4 className={styles.title}>{productProposition.product?.title}</h4>
          {productProposition.ratio === 1 && (
            <div className={styles.ownershipStatusContainer}>
              <span className={styles.ownershipStatus}>✓</span>
            </div>
          )}
        </div>
        <div>
          <img
            className={styles.image}
            src={productProposition.product?.image}
          />
        </div>
        <div className={styles.description}>
          {productProposition.product?.introduction}
        </div>
      </div>
      {insuranceSumText && (
        <div className={styles.insuranceSum}>{insuranceSumText}</div>
      )}
    </div>
  );
};
