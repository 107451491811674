import { SignUpResponseInterface } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import {
  ApiResponse,
  AwsUserPoolData,
  getErrorCode,
  getErrorMessage,
} from "hooks/useUserRegistration";
import createUsernameFromEmail from "utils/auth/createUsernameFromEmail";

type ResendCodeResponse = {
  CodeDeliveryDetails: SignUpResponseInterface["codeDeliveryDetails"];
};

const updateUser = async (
  user: CognitoUser,
  attributes: Partial<AwsUserPoolData>
): Promise<ApiResponse<unknown>> => {
  try {
    const result = await Auth.updateUserAttributes(user, attributes);

    return {
      ok: true,
      data: result,
    };
  } catch (error) {
    return {
      ok: false,
      message: getErrorMessage(error as Error),
      code: getErrorCode(error as Error),
    };
  }
};

const resendConfirmationCode = async (
  email: string
): Promise<ApiResponse<ResendCodeResponse>> => {
  const username = createUsernameFromEmail(email);

  try {
    const result: ResendCodeResponse = await Auth.resendSignUp(username);

    return {
      ok: true,
      data: result,
    };
  } catch (error) {
    return {
      ok: false,
      message: getErrorMessage(error as Error),
      code: getErrorCode(error as Error),
    };
  }
};

type UseUserUpdateReturnType = {
  updateUser: (
    user: CognitoUser,
    attributes: Partial<AwsUserPoolData>
  ) => Promise<ApiResponse<unknown>>;
  resendConfirmationCode: (
    email: string
  ) => Promise<ApiResponse<ResendCodeResponse>>;
};

const useUserUpdate = (): UseUserUpdateReturnType => {
  return {
    resendConfirmationCode,
    updateUser,
  };
};

export default useUserUpdate;
