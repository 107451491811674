import React, { ReactElement } from "react";
import Page from "components/page/Page";
import LoginStepWizard from "components/step-wizard/login/LoginStepWizard";
import useAsyncEffect from "hooks/useAsyncEffect";
import useUserLogin from "hooks/useUserLogin";
import redirectAfterLogin from "utils/redirectAfterLogin";
import { useNavigate } from "react-router-dom";
import PageSection from "../../components/page-section/PageSection";

export const LOGIN_REDIRECT_STATE = "continue";

const LoginPage = (): ReactElement => {
  const { isLoggedIn } = useUserLogin();
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    if (await isLoggedIn()) {
      // User tried to visit login-page while already being logged in.
      redirectAfterLogin(navigate);
    }
  });

  return (
    <Page>
      <PageSection>
        <LoginStepWizard />
      </PageSection>
    </Page>
  );
};

export default LoginPage;
