import * as yup from "yup";
import { STRINGS } from "../../../../language/de_DE/strings";
import { ProduktTyp } from "../../../../types/backend/ProduktTyp";
import { VertragTyp } from "../../../../types/backend/VertragTyp";

export const addContractStep1ValidationSchema = yup.object().shape({
  produktTyp: yup
    .mixed<ProduktTyp>()
    .oneOf(["VERSICHERUNG", "GELDANLAGE", "SONSTIGE"]),
  typ: yup
    .mixed<VertragTyp>()
    .when("produktTyp", {
      is: (p: string) => {
        return p !== "SONSTIGE";
      },
      then: yup
        .string()
        .required(STRINGS.addContract.step1.contractTypSelectionError),
    })
    .when("produktTyp", {
      is: "SONSTIGE",
      then: yup
        .string()
        .required(STRINGS.addContract.step1.customContractTypError),
    }),
  customTyp: yup.string().when("typ", {
    is: "SONSTIGE_VERTRAEGE",
    then: yup
      .string()
      .required(STRINGS.addContract.step1.customContractTypError),
  }),
  institut: yup
    .string()
    .required(STRINGS.addContract.step1.instituteSelectionError),
  customInstitut: yup.string().when("institut", {
    is: "SONSTIGE",
    then: yup.string().required(STRINGS.addContract.step1.customInstituteError),
  }),
  files: yup.mixed<Array<File>>(),
});
