import styles from "pages/hausratversicherung/HausratversicherungVergleichErgebnis.module.scss";

import React, { FunctionComponent, useState } from "react";
import { useLocation } from "react-router-dom";
import { fetchHausratComparisonResult } from "api/requests/fetchComparisonResult";
import { Box } from "components/atoms/Box";
import Page from "components/page/Page";
import {
  HausratversicherungVergleichErgebnisTemplate,
  InsuranceComparisonResultTemplate,
} from "components/templates/HausratversicherungVergleichErgebnisTemplate";
import useAsyncEffect from "hooks/useAsyncEffect";
import { STRINGS } from "language/de_DE/strings";
import { insuranceFilterMock } from "mockData/insuranceFilterMock";
import { insuranceOptionsMock } from "mockData/insuranceOptionsMock";
import { insuranceSelbstbeteiligungMock } from "mockData/InsuranceSelbstbeteiligungMock";
import { insuranceZahlweiseMock } from "mockData/InsuranceZahlweiseMock";
import { sortByPriceMock, sortByRatingMock } from "mockData/sortByMocks";
import { ComparisonOptions } from "types/insuranceComparisonData/ComparisonOptions";
import { HausratVersicherungComparisonRequest } from "types/insuranceComparisonData/Hausratversicherug/HausratVersicherungComparisonRequest";
import { HausratVersicherungComparisonResponse } from "types/insuranceComparisonData/Hausratversicherug/HausratVersicherungComparisonResponse";

interface ComparisonLocationState {
  zipCode: string;
  residentialSize: string;
  birthday: string;
}

export const HausratversicherungVergleichErgebnis: FunctionComponent = () => {
  const [comparisonResult, setComparisonResult] =
    useState<HausratVersicherungComparisonResponse>();

  const location = useLocation();
  const { zipCode, residentialSize, birthday } =
    location.state as ComparisonLocationState;
  const [comparisonOptions, setComparisonOptions] = useState<ComparisonOptions>(
    { plz: zipCode, wohnflaeche: +residentialSize, geburtsdatum: birthday }
  );

  useAsyncEffect(async () => {
    if (!comparisonResult) {
      const request: HausratVersicherungComparisonRequest = {
        versicherungsbeginn: new Date().toISOString(),
        tarifauswahl: "EINFAMILIENHAUS",
        adresse: {
          plz: comparisonOptions.plz,
        },
        versicherungsnehmer: {
          geburtsdatum: comparisonOptions.geburtsdatum,
          isBeamter: false,
        },
        versicherungsobjekt: {
          wohnflaeche: {
            gesamt: comparisonOptions.wohnflaeche,
          },
          isStaendigBewohnt: true,
        },
        absicherung: {
          hasElementarschaedenVersicherung: false,
        },
        selbstbeteiligung: comparisonOptions.selbstbeteiligung,
        zahlweise: comparisonOptions.zahlweise,
      };
      const response = await fetchHausratComparisonResult(request);
      setComparisonResult(response);
    }
  }, [comparisonResult]);

  const handleComparisonInfoChange = (options: ComparisonOptions) => {
    setComparisonOptions({ ...options });
    setComparisonResult(undefined);
  };

  const templateData: InsuranceComparisonResultTemplate = {
    pageTitle: STRINGS.hausrat.hausratversicherungVergleichErgebnisPageTitle,
    subtitle: `${STRINGS.hausrat.hausratversicherungVergleichErgebnisSubTitle1} ${comparisonOptions.wohnflaeche} ${STRINGS.hausrat.hausratversicherungVergleichErgebnisSubTitle2} ${comparisonOptions.plz}`,
  };

  return (
    <Page>
      {comparisonResult && (
        <Box flex className={styles.pageWrapper}>
          <HausratversicherungVergleichErgebnisTemplate
            templateData={templateData}
            insuranceOptions={insuranceOptionsMock}
            insuranceFilter={insuranceFilterMock}
            insurancePaymentOption={insuranceZahlweiseMock}
            insuranceDeductibleOption={insuranceSelbstbeteiligungMock}
            sortByPrice={sortByPriceMock}
            sortByRating={sortByRatingMock}
            comparisonResult={comparisonResult}
            comparisonOptions={comparisonOptions}
            onComparisonInfoChange={handleComparisonInfoChange}
          />
        </Box>
      )}
    </Page>
  );
};
