import * as yup from "yup";
import { STRINGS } from "../../../language/de_DE/strings";

const inputStrings = STRINGS.hausrat.comparison.inputs;

export const hausratTarifeRequestSchema = yup.object({
  postleitzahl: yup
    .string()
    .trim()
    .matches(/^[0-9]{5}$/, inputStrings.postleitzahlFormatError)
    .required(inputStrings.genericRequired),
  wohnflaeche: yup
    .number()
    .positive(inputStrings.wohnflaecheFormatError)
    .lessThan(1000, inputStrings.wohnflaecheFormatError)
    .required(inputStrings.genericRequired)
    .typeError(inputStrings.wohnflaecheFormatError),
  geburtsdatum: yup
    .date()
    .max(new Date(), inputStrings.geburtsdatumFutureError)
    .required(inputStrings.genericRequired),
  versicherungssumme: yup
    .number()
    .positive(inputStrings.versicherungssummeNegativeError)
    .required(inputStrings.genericRequired)
    .typeError(inputStrings.versicherungssummeTypeError),
  fahrlaessigkeitAbgedeckt: yup.string().matches(/(yes)|(no)/),
  arbeitInOeffentlicherDienst: yup.string().matches(/(yes)|(no)/),
  erstinformationErhalten: yup
    .bool()
    .isTrue(inputStrings.erstinformationErhaltenNotCheckedError)
    .required(),
});
