import React, { ReactElement } from "react";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { useVertragMapping } from "./useVertragMapping";
import { shouldDisplayField } from "./util/displayInputUtil";
import { VertragTyp } from "../../../../../types/backend/VertragTyp";
import { AddContractSelection } from "./base/AddContractSelection";
import { createVertragBeitragIntervalOptions } from "../../../../../utils/vertragUtils";

const options = createVertragBeitragIntervalOptions();

export const EinzahlungInput = ({
  vertragTyp,
}: AddContractInputBaseType): ReactElement | null => {
  const { sparenTypes } = useVertragMapping();
  const requiredTypes: VertragTyp[] = [...sparenTypes];
  if (!shouldDisplayField(vertragTyp, requiredTypes)) return null;
  return (
    <AddContractSelection
      name={"einzahlung"}
      options={options}
      labelText={STRINGS.addContract.step2.depositLabel}
    />
  );
};
