import React, { ReactElement } from "react";
import clsx from "clsx";
import common from "./FormInputCommons.module.scss";
import { FieldMetaProps } from "formik";

interface FormInputSubTextProps {
  helpText?: string;
  meta: FieldMetaProps<unknown>;
  validationClassName?: string;
  helpTextClassName?: string;
}

export const FormInputSubText = ({
  helpText,
  meta,
  validationClassName,
  helpTextClassName,
}: FormInputSubTextProps): ReactElement | null => {
  const isError = meta && meta.touched && meta.error;
  let displayText = helpText;
  if (isError) {
    displayText = meta.error;
  }
  if ((!isError && helpText === undefined) || helpText === null) {
    return null;
  }
  return (
    <div
      className={clsx(
        common.subText,
        isError
          ? [common.error, validationClassName]
          : [common.help, helpTextClassName]
      )}
    >
      {displayText}
    </div>
  );
};
