export const STRINGS_INSTITUTES: any = {
  vertrag: {
    institut: {
      A1_1_ASSEKURANZSERVICE_AG: "1:1 Assekuranzservice AG",
      AACHENMUENCHENER_LEBENSVERSICHERUNG_AG:
        "AachenMünchener Lebensversicherung AG",
      AACHENMUENCHENER_VERSICHERUNG_AG: "AachenMünchener Versicherung AG",
      ADAC_AUTOVERSICHERUNG_AG: "ADAC Autoversicherung AG",
      ADAC_RECHTSSCHUTZ_VERSICHERUNGS_AG: "ADAC-Rechtsschutz Versicherungs-AG",
      ADAC_SCHUTZBRIEF_VERSICHERUNGS_AG: "ADAC-Schutzbrief Versicherungs-AG",
      ADLER_VERSICHERUNG_AG: "ADLER Versicherung AG",
      ADVOCARD_RECHTSSCHUTZVERSICHERUNG_AG:
        "ADVOCARD Rechtsschutzversicherung AG",
      AGILA_HAUSTIERVERSICHERUNG_AG: "AGILA Haustierversicherung AG",
      AIOI_NISSAY_DOWA_LIFE_INSURANCE_OF_EUROPE_AG:
        "Aioi Nissay Dowa Life Insurance of Europe AG",
      ALLCURA_VERSICHERUNGS_AG: "Allcura Versicherungs-AG",
      ALLFINANZTESTDE: "allfinanztest.de",
      ALLGEMEINE_RENTENANSTALT_PENSIONSKASSE_AG:
        "Allgemeine Rentenanstalt Pensionskasse AG",
      ALLIANZ_LEBENSVERSICHERUNGS_AG: "Allianz Lebensversicherungs-AG",
      ALLIANZ_PENSIONSFONDS_AG: "Allianz Pensionsfonds AG",
      ALLIANZ_PENSIONSKASSE_AG: "Allianz Pensionskasse AG",
      ALLIANZ_PRIVATE_KRANKENVERSICHERUNGS_AG:
        "Allianz Private Krankenversicherungs-AG",
      ALLIANZ_SE: "Allianz SE",
      ALLIANZ_VERSICHERUNGS_AG: "Allianz Versicherungs-AG",
      ALLIANZ_VERSORGUNGSKASSE_VAG: "Allianz Versorgungskasse VaG",
      ALLSECUR_DEUTSCHLAND_AG: "AllSecur Deutschland AG",
      ALTE_LEIPZIGER_LEBENSVERSICHERUNG_VAG:
        "ALTE LEIPZIGER Lebensversicherung VaG",
      ALTE_LEIPZIGER_PENSIONSFONDS_AG: "ALTE LEIPZIGER Pensionsfonds AG",
      ALTE_LEIPZIGER_PENSIONSKASSE_AG: "ALTE LEIPZIGER Pensionskasse AG",
      ALTE_LEIPZIGER_VERSICHERUNG_AG: "Alte Leipziger Versicherung AG",
      ALTE_OLDENBURGER_KRANKENVERSICHERUNG_AG:
        "ALTE OLDENBURGER Krankenversicherung AG",
      ALTE_OLDENBURGER_KRANKENVERSICHERUNG_VON_1927_VERSICHERUNGSVEREIN_VAG:
        "ALTE OLDENBURGER Krankenversicherung von 1927 Versicherungsverein VaG",
      ALTERS__UND_HINTERBLIEBENEN_VERSICHERUNG_DER_TECHNISCHEN_UEBERWACHUNGS_VEREINE_VVAG:
        "Alters- und Hinterbliebenen-Versicherung der Technischen Überwachungs-Vereine-VVaG",
      AMEXPOOL: "Amexpool",
      AMMERLAENDER_VERSICHERUNG_VVAG: "AMMERLÄNDER VERSICHERUNG VVaG",
      APELLA_AG: "Apella AG",
      ARAG_ALLGEMEINE_VERSICHERUNGS_AG: "ARAG Allgemeine Versicherungs-AG",
      ARAG_KRANKENVERSICHERUNGS_AG: "ARAG Krankenversicherungs-AG",
      ARAG_LEBENSVERSICHERUNGS_AG: "ARAG Lebensversicherungs-AG",
      ARAG_SE: "ARAG SE",
      ARUNA_GMBH: "Aruna GmbH",
      ASSTEL_LEBENSVERSICHERUNG_AG: "Asstel Lebensversicherung AG",
      ASSTEL_SACHVERSICHERUNG_AG: "ASSTEL Sachversicherung AG",
      AUXILIA_RECHTSSCHUTZ_VERSICHERUNGS_AG:
        "AUXILIA Rechtsschutz-Versicherungs-AG",
      AXA_ART_VERSICHERUNG_AG: "AXA ART Versicherung AG",
      AXA_EASY_VERSICHERUNG_AG: "AXA easy Versicherung AG",
      AXA_KRANKENVERSICHERUNG_AG: "AXA Krankenversicherung AG",
      AXA_LEBENSVERSICHERUNG_AG: "AXA Lebensversicherung AG",
      AXA_VERSICHERUNG_AG: "AXA Versicherung AG",
      BADEN_BADENER_PENSIONSKASSE_VVAG: "Baden-Badener Pensionskasse VVaG",
      BADEN_BADENER_VERSICHERUNG_AG: "Baden-Badener Versicherung AG",
      BADISCHE_ALLGEMEINE_VERSICHERUNG_AG:
        "Badische Allgemeine Versicherung AG",
      BADISCHE_RECHTSSCHUTZVERSICHERUNG_AG:
        "Badische Rechtsschutzversicherung AG",
      BADISCHER_GEMEINDE_VERSICHERUNGS_VERBAND:
        "Badischer Gemeinde-Versicherungs-Verband",
      BARMENIA_ALLGEMEINE_VERSICHERUNGS_AG:
        "Barmenia Allgemeine Versicherungs-AG",
      BARMENIA_KRANKENVERSICHERUNG_VAG: "Barmenia Krankenversicherung VaG",
      BARMENIA_LEBENSVERSICHERUNG_VAG: "Barmenia Lebensversicherung VaG",
      BASLER__LEBENSVERSICHERUNGS_AG: "Basler  Lebensversicherungs-AG",
      BASLER_LEBEN_AG_DIREKTION_FUER_DEUTSCHLAND:
        "Basler Leben AG Direktion für Deutschland",
      BASLER_SACHVERSICHERUNGS_AG: "Basler Sachversicherungs-AG",
      BASLER_VERSICHERUNG_AG: "Basler Versicherung AG",
      BAVARIA_VERSICHERUNGSVEREIN_VAG: "BAVARIA Versicherungsverein VaG",
      BAYERISCHE_BEAMTEN_LEBENSVERSICHERUNG_VAG:
        "Bayerische Beamten Lebensversicherung VaG",
      BAYERISCHE_BEAMTEN_VERSICHERUNG_AG: "Bayerische Beamten Versicherung AG",
      BAYERISCHE_BEAMTENKRANKENKASSE_AG: "Bayerische Beamtenkrankenkasse AG",
      BAYERISCHE_HAUSBESITZER_VERSICHERUNGS_GESELLSCHAFT_VAG:
        "Bayerische Hausbesitzer-Versicherungs-Gesellschaft VaG",
      BAYERISCHE_LANDESBRANDVERSICHERUNG_AG:
        "Bayerische Landesbrandversicherung AG",
      BAYERISCHER_VERSICHERUNGSVERBAND_VERSICHERUNGSAG:
        "Bayerischer Versicherungsverband VersicherungsAG",
      BAYERN_VERSICHERUNG_LEBENSVERSICHERUNG_AG:
        "Bayern-Versicherung Lebensversicherung AG",
      BCA: "BCA",
      BD24_BERLIN_DIREKT_VERSICHERUNG_AG: "BD24 Berlin Direkt Versicherung AG",
      BERGISCHE_BRANDVERSICHERUNG_ALLGEMEINE_FEUERVERSICHERUNG_VVAG:
        "Bergische Brandversicherung Allgemeine Feuerversicherung VVaG",
      BGV_VERSICHERUNG_AG: "BGV-Versicherung AG",
      BLAU_DIREKT: "Blau Direkt",
      BRUDERHILFE_SACHVERSICHERUNG_AG_IM_RAUM_DER_KIRCHEN:
        "Bruderhilfe Sachversicherung AG im Raum der Kirchen",
      BVAG_BERLINER_VERSICHERUNG_AG: "BVAG Berliner Versicherung AG",
      CANADA_LIFE_ASSURANCE_EUROPE_LIMITED:
        "Canada Life Assurance Europe Limited",
      CENTRAL_KRANKENVERSICHERUNG_AG: "Central Krankenversicherung AG",
      CG_CAR_GARANTIE_VERSICHERUNGS_AG: "CG Car-Garantie Versicherungs-AG",
      CONCEPTIF: "ConceptIF",
      CONCORDIA_KRANKENVERSICHERUNGS_AG: "Concordia Krankenversicherungs-AG",
      CONCORDIA_LEBENSVERSICHERUNGS_AG: "Concordia Lebensversicherungs-AG",
      CONCORDIA_RECHTSSCHUTZ_VERSICHERUNGS_AG:
        "Concordia Rechtsschutz-Versicherungs-AG",
      CONCORDIA_VERSICHERUNGS_GESELLSCHAFT_VAG:
        "Concordia Versicherungs-Gesellschaft VaG",
      CONDOR_ALLGEMEINE_VERSICHERUNGS_AG: "Condor Allgemeine Versicherungs-AG",
      CONDOR_LEBENSVERSICHERUNGS_AG: "Condor Lebensversicherungs-AG",
      CONSTANTIA_VERSICHERUNGEN_VAG: "CONSTANTIA Versicherungen VaG",
      CONTINENTALE_KRANKENVERSICHERUNG_VAG:
        "Continentale Krankenversicherung VaG",
      CONTINENTALE_LEBENSVERSICHERUNG_AG: "Continentale Lebensversicherung AG",
      CONTINENTALE_SACHVERSICHERUNG_AG: "Continentale Sachversicherung AG",
      COSMOS_LEBENSVERSICHERUNGS_AG: "COSMOS Lebensversicherungs-AG",
      COSMOS_VERSICHERUNG_AG: "Cosmos Versicherung AG",
      CREDIT_LIFE_AG: "Credit Life AG",
      CREDIT_LIFE_INTERNATIONAL_LEBENSVERSICHERUNG_AG:
        "Credit Life International Lebensversicherung AG",
      CREDIT_LIFE_INTERNATIONAL_VERSICHERUNG_AG:
        "Credit Life International Versicherung AG",
      DAS_DEUTSCHER_AUTOMOBIL_SCHUTZ_ALLGEMEINE_RECHTSSCHUTZ_VERSICHERUNGS_AG:
        "D.A.S. Deutscher Automobil Schutz Allgemeine Rechtsschutz-Versicherungs-AG",
      DA_DEUTSCHE_ALLGEMEINE_VERSICHERUNG_AG:
        "DA Deutsche Allgemeine Versicherung AG",
      DARAG_DEUTSCHE_VERSICHERUNGS_UND_RUECKVERSICHERUNGS_AG:
        "DARAG Deutsche Versicherungs-und Rückversicherungs-AG",
      DBV_DEUTSCHE_BEAMTENVERSICHERUNG_AG:
        "DBV Deutsche Beamtenversicherung AG",
      DEBEKA_ALLGEMEINE_VERSICHERUNG_AG_SITZ_KOBLENZ_AM_RHEIN:
        "Debeka Allgemeine Versicherung AG Sitz Koblenz am Rhein",
      DEBEKA_KRANKENVERSICHERUNGSVEREIN_VAG_SITZ_KOBLENZ_AM_RHEIN:
        "Debeka Krankenversicherungsverein VaG Sitz Koblenz am Rhein",
      DEBEKA_LEBENSVERSICHERUNGSVEREIN_VAG_SITZ_KOBLENZ_AM_RHEIN:
        "Debeka Lebensversicherungsverein VaG Sitz Koblenz am Rhein",
      DEBEKA_PENSIONSKASSE_AG: "Debeka Pensionskasse AG",
      DEBEKA_ZUSATZVERSORGUNGSKASSE_VAG: "Debeka Zusatzversorgungskasse VaG",
      DEGENIA_VERSICHERUNGSDIENST_AG: "Degenia Versicherungsdienst AG",
      DELTA_DIREKT_LEBENSVERSICHERUNG_AG_MUENCHEN:
        "Delta Direkt Lebensversicherung AG München",
      DELTA_LLOYD_LEBENSVERSICHERUNG_AG: "Delta Lloyd Lebensversicherung AG",
      DELTA_LLOYD_PENSIONSKASSE_AG: "Delta Lloyd Pensionskasse AG",
      DELVAG_LUFTFAHRTVERSICHERUNGS_AG: "Delvag Luftfahrtversicherungs-AG",
      DELVAG_RUECKVERSICHERUNGS_AG: "Delvag Rückversicherungs-AG",
      DEURAG_DEUTSCHE_RECHTSSCHUTZ_VERSICHERUNG_AG:
        "DEURAG Deutsche Rechtsschutz-Versicherung AG",
      DEUTSCHE_ASSISTANCE_VERSICHERUNG_AG:
        "Deutsche Assistance Versicherung AG",
      DEUTSCHE_LEBENSVERSICHERUNGS_AG: "Deutsche Lebensversicherungs-AG",
      DEUTSCHE_RUECKVERSICHERUNG_AG: "Deutsche Rückversicherung AG",
      DEUTSCHE_STEUERBERATER_VERSICHERUNG___PENSIONSKASSE_DES_STEUERBERATENDEN_BERUFS_VVAG:
        "Deutsche Steuerberater-Versicherung - Pensionskasse des steuerberatenden Berufs VVaG",
      DEUTSCHE_AERZTEVERSICHERUNG_AG: "Deutsche Ärzteversicherung AG",
      DEUTSCHER_PENSIONSFONDS_AG: "Deutscher Pensionsfonds AG",
      DEUTSCHER_RING_KRANKENVERSICHERUNGSVEREIN_VAG:
        "DEUTSCHER RING Krankenversicherungsverein VaG",
      DEVK_ALLGEMEINE_LEBENSVERSICHERUNGS_AG:
        "DEVK Allgemeine Lebensversicherungs-AG",
      DEVK_ALLGEMEINE_VERSICHERUNGS_AG: "DEVK Allgemeine Versicherungs-AG",
      DEVK_DEUTSCHE_EISENBAHN_VERSICHERUNG_LEBENSVERSICHERUNGSVEREIN_VAG_BETR_SOZIALEINRICHTUNG_DER_DEUTSCHEN_BAHN:
        "DEVK Deutsche Eisenbahn Versicherung Lebensversicherungsverein VaG Betr. Sozialeinrichtung der Deutschen Bahn",
      DEVK_DEUTSCHE_EISENBAHN_VERSICHERUNG_SACH__UND_HUK_VERSICHERUNGSVEREIN_VAG_BETRIEBLICHE_SOZIALEINRICHTUNG:
        "DEVK Deutsche Eisenbahn Versicherung Sach- und HUK-Versicherungsverein VaG Betriebliche Sozialeinrichtung",
      DEVK_KRANKENVERSICHERUNGS_AG: "DEVK Krankenversicherungs-AG",
      DEVK_PENSIONSFONDS_AG: "DEVK Pensionsfonds AG",
      DEVK_RECHTSSCHUTZ_VERSICHERUNGS_AG: "DEVK Rechtsschutz-Versicherungs-AG",
      DFV_DEUTSCHE_FAMILIENVERSICHERUNG_AG:
        "DFV Deutsche Familienversicherung AG",
      DIALOG_LEBENSVERSICHERUNGS_AG: "Dialog Lebensversicherungs-AG",
      DIRECT_LINE_VERSICHERUNG_AG: "Direct Line Versicherung AG",
      DIREKTE_LEBEN_VERSICHERUNG_AG: "Direkte Leben Versicherung AG",
      DKV_DEUTSCHE_KRANKENVERSICHERUNG_AG:
        "DKV Deutsche Krankenversicherung AG",
      DMB_RECHTSSCHUTZ_VERSICHERUNG_AG: "DMB Rechtsschutz-Versicherung AG",
      DOCURA_VVAG: "DOCURA VVaG",
      DOMCURA_AG: "Domcura AG",
      E_S_RUECKVERSICHERUNG_AG: "E+S Rückversicherung AG",
      ENVIVAS_KRANKENVERSICHERUNG_AG: "ENVIVAS Krankenversicherung AG",
      ERGO_DIREKT_KRANKENVERSICHERUNG_AG: "ERGO Direkt Krankenversicherung AG",
      ERGO_DIREKT_LEBENSVERSICHERUNG_AG: "ERGO Direkt Lebensversicherung AG",
      ERGO_DIREKT_VERSICHERUNG_AG: "ERGO Direkt Versicherung AG",
      ERGO_LEBENSVERSICHERUNG_AG: "ERGO Lebensversicherung AG",
      ERGO_PENSIONSFONDS_AG: "ERGO Pensionsfonds AG",
      ERGO_PENSIONSKASSE_AG: "ERGO Pensionskasse AG",
      ERGO_VERSICHERUNG_AG: "ERGO Versicherung AG",
      EULER_HERMES_DEUTSCHLAND_AG: "Euler Hermes Deutschland AG",
      EURO_AVIATION_VERSICHERUNGS_AG: "Euro-Aviation Versicherungs-AG",
      EUROP_ASSISTANCE_VERSICHERUNGS_AG: "EUROP ASSISTANCE Versicherungs-AG",
      EUROPA_LEBENSVERSICHERUNG_AG: "EUROPA Lebensversicherung AG",
      EUROPA_VERSICHERUNG_AG: "EUROPA Versicherung AG",
      EUROPEAN_WARRANTY_PARTNERS_SE: "European Warranty Partners SE",
      EXTREMUS_VERSICHERUNGS_AG: "EXTREMUS Versicherungs-AG",
      F_LAEISZ_VERSICHERUNG_AG: "F. Laeisz Versicherung AG",
      FAHRLEHRERVERSICHERUNG_VEREIN_VAG: "Fahrlehrerversicherung Verein VaG",
      FAMILIENFUERSORGE_LEBENSVERSICHERUNG_AG_IM_RAUM_DER_KIRCHEN:
        "Familienfürsorge Lebensversicherung AG im Raum der Kirchen",
      FEUER__UND_EINBRUCHSCHADENKASSE_DER_BBBANK_IN_KARLSRUHE:
        "Feuer- und Einbruchschadenkasse der BBBank in Karlsruhe",
      FEUERSOZIETAET_BERLIN_BRANDENBURG_VERSICHERUNG_AG:
        "Feuersozietät Berlin Brandenburg Versicherung AG",
      FONDS_FINANZ_MAKLERSERVICE: "Fonds Finanz Maklerservice",
      FONDSKONZEPT: "Fondskonzept",
      FONDSNET: "Fondsnet",
      FREIE_ARZT__UND_MEDIZINKASSE_DER_ANGEHOERIGEN_DER_BERUFSFEUERWEHR_UND_DER_POLIZEI_VVAG:
        "Freie Arzt- und Medizinkasse der Angehörigen der Berufsfeuerwehr und der Polizei VVaG",
      GARANTA_VERSICHERUNGS_AG: "GARANTA Versicherungs-AG",
      GARTENBAU_VERSICHERUNG_VVAG: "Gartenbau-Versicherung VVaG",
      GEMEINNUETZIGE_HAFTPFLICHTVERSICHERUNGSANSTALT_KASSEL:
        "Gemeinnützige Haftpflichtversicherungsanstalt Kassel",
      GENERAL_REINSURANCE_AG: "General Reinsurance AG",
      GENERALI_DEUTSCHLAND_HOLDING_AG: "Generali Deutschland Holding AG",
      GENERALI_DEUTSCHLAND_PENSIONSKASSE_AG:
        "Generali Deutschland Pensionskasse AG",
      GENERALI_DEUTSCHLAND_PENSOR_PENSIONSFONDS_AG:
        "Generali Deutschland Pensor Pensionsfonds AG",
      GENERALI_LEBENSVERSICHERUNG_AG: "Generali Lebensversicherung AG",
      GENERALI_VERSICHERUNG_AG: "Generali Versicherung AG",
      GERLING_VERSORGUNGSKASSE: "Gerling Versorgungskasse",
      GERMAN_ASSISTANCE_VERSICHERUNG_AG: "GERMAN ASSISTANCE VERSICHERUNG AG",
      GERMANBROKERNET: "Germanbroker.net",
      GOTHAER_ALLGEMEINE_VERSICHERUNG_AG: "Gothaer Allgemeine Versicherung AG",
      GOTHAER_KRANKENVERSICHERUNG_AG: "Gothaer Krankenversicherung AG",
      GOTHAER_LEBENSVERSICHERUNG_AG: "Gothaer Lebensversicherung AG",
      GOTHAER_PENSIONSKASSE_AG: "Gothaer Pensionskasse AG",
      GOTHAER_VERSICHERUNGSBANK_VVAG: "Gothaer Versicherungsbank VVaG",
      GRUNDEIGENTUEMER_VERSICHERUNG_VERSICHERUNGSVEREIN_VAG:
        "GRUNDEIGENTÜMER-VERSICHERUNG Versicherungsverein VaG",
      GVO_GEGENSEITIGKEIT_VERSICHERUNG_OLDENBURG_VVAG:
        "GVO Gegenseitigkeit Versicherung Oldenburg VVaG",
      GVV_KOMMUNALVERSICHERUNG_VERSICHERUNGSVEREIN_VAG:
        "GVV-Kommunalversicherung Versicherungsverein VaG",
      GVV_PRIVATVERSICHERUNG_AG: "GVV-Privatversicherung AG",
      HAFTPFLICHTGEMEINSCHAFT_DEUTSCHER_NAHVERKEHRS__UND_VERSORGUNGSUNTERNEHMEN_ALLGEMEIN_VVAG:
        "Haftpflichtgemeinschaft Deutscher Nahverkehrs- und Versorgungsunternehmen Allgemein (HDNA) VVaG",
      HAFTPFLICHTKASSE_DARMSTADT_HAFTPFLICHTVERSICHERUNG_DES_DEUTSCHEN_HOTEL__UND_GASTSTAETTENGEWERBES_VVAG:
        "Haftpflichtkasse Darmstadt-Haftpflichtversicherung des Deutschen Hotel- und Gaststättengewerbes-VVaG",
      HAGELGILDE_VERSICHERUNGSVEREIN_VAG: "Hagelgilde Versicherungsverein VaG",
      HALLESCHE_KRANKENVERSICHERUNG_VAG: "HALLESCHE Krankenversicherung VaG",
      HAMBURG_MANNHEIMER_PENSIONSKASSE_AG:
        "Hamburg-Mannheimer Pensionskasse AG",
      HAMBURGER_FEUERKASSE_VERSICHERUNGS_AG:
        "Hamburger Feuerkasse Versicherungs-AG",
      HAMBURGER_INTERNATIONALE_RUECKVERSICHERUNG_AG:
        "Hamburger Internationale Rückversicherung AG",
      HAMBURGER_LEBENSVERSICHERUNG_AG: "Hamburger Lebensversicherung AG",
      HANNOVERSCHE_ALTERSKASSE_VVAG: "Hannoversche Alterskasse VVaG",
      HANNOVERSCHE_DIREKTVERSICHERUNG_AG: "Hannoversche Direktversicherung AG",
      HANNOVERSCHE_LEBENSVERSICHERUNG_AG: "Hannoversche Lebensversicherung AG",
      HANNOVERSCHE_PENSIONSKASSE_VVAG: "Hannoversche Pensionskasse VVaG",
      HANSE_MARINE_VERSICHERUNG_AG: "Hanse-Marine-Versicherung AG",
      HANSEATICA_RUECKVERSICHERUNGS_AG: "Hanseatica Rückversicherungs-AG",
      HANSEMERKUR_ALLGEMEINE_VERSICHERUNG_AG:
        "HanseMerkur Allgemeine Versicherung AG",
      HANSEMERKUR_KRANKENVERSICHERUNG_AG: "HanseMerkur Krankenversicherung AG",
      HANSEMERKUR_KRANKENVERSICHERUNG_VAG:
        "HanseMerkur Krankenversicherung VaG",
      HANSEMERKUR_LEBENSVERSICHERUNG_AG: "HanseMerkur Lebensversicherung AG",
      HANSEMERKUR_REISEVERSICHERUNG_AG: "HanseMerkur Reiseversicherung AG",
      HANSEMERKUR_SPEZIALE_KRANKENVERSICHERUNG_AG:
        "HanseMerkur Speziale Krankenversicherung AG",
      HANSEMERKUR24_LEBENSVERSICHERUNG_AG:
        "HanseMerkur24 Lebensversicherung AG",
      HARSEWINKELER_VERSICHERUNG_VAG: "Harsewinkeler Versicherung VaG",
      HCI_CAPITAL_AG: "HCI Capital AG",
      HDI__VERSICHERUNG_AG: "HDI  Versicherung AG",
      HDI_HAFTPFLICHTVERBAND_DER_DEUTSCHEN_INDUSTRIE_VERSICHERUNGSVEREIN_VAG:
        "HDI Haftpflichtverband der Deutschen Industrie Versicherungsverein VaG",
      HDI_LEBENSVERSICHERUNG_AG: "HDI Lebensversicherung AG",
      HDI_PENSIONSFONDS_AG: "HDI Pensionsfonds AG",
      HDI_GERLING_INDUSTRIE_VERSICHERUNG_AG:
        "HDI-Gerling Industrie Versicherung AG",
      HEIDELBERGER_LEBENSVERSICHERUNG_AG: "Heidelberger Lebensversicherung AG",
      HELVETIA_INTERNATIONAL_VERSICHERUNGS_AG:
        "HELVETIA INTERNATIONAL Versicherungs-AG",
      HELVETIA_SCHWEIZERISCHE_LEBENSVERSICHERUNGS_AG:
        "HELVETIA schweizerische Lebensversicherungs-AG",
      HELVETIA_SCHWEIZERISCHE_VERSICHERUNGSGESELLSCHAFT_AG_DIREKTION_FUER_DEUTSCHLAND:
        "Helvetia Schweizerische Versicherungsgesellschaft AG Direktion für Deutschland",
      HUK_COBURG_HAFTPFLICHT_UNTERSTUETZUNGS_KASSE_KRAFTFAHRENDER_BEAMTER_DEUTSCHLANDS_VAG_IN_COBURG:
        "HUK-COBURG Haftpflicht-Unterstützungs-Kasse kraftfahrender Beamter Deutschlands VaG in Coburg",
      HUK_COBURG_HOLDING_AG: "HUK-COBURG Holding AG",
      HUK_COBURG_LEBENSVERSICHERUNG_AG: "HUK-COBURG Lebensversicherung AG",
      HUK_COBURG_ALLGEMEINE_VERSICHERUNG_AG:
        "HUK-COBURG-Allgemeine Versicherung AG",
      HUK_COBURG_KRANKENVERSICHERUNG_AG: "HUK-COBURG-Krankenversicherung AG",
      HUK_COBURG_RECHTSSCHUTZVERSICHERUNG_AG:
        "HUK-COBURG-Rechtsschutzversicherung AG",
      HUK24_AG: "HUK24 AG",
      HAEGER_VERSICHERUNGSVEREIN_VAG: "HÄGER VERSICHERUNGSVEREIN VaG",
      HUEBENER_VERSICHERUNGS_AG: "Hübener Versicherungs-AG",
      IDEAL_LEBENSVERSICHERUNG_VAG: "Ideal Lebensversicherung VaG",
      IDEAL_VERSICHERUNG_AG: "IDEAL Versicherung AG",
      IDUNA_VEREINIGTE_LEBENSVERSICHERUNG_AG_FUER_HANDWERK:
        "IDUNA Vereinigte Lebensversicherung aG für Handwerk",
      INTER_ALLGEMEINE_VERSICHERUNG_AG: "INTER Allgemeine Versicherung AG",
      INTER_KRANKENVERSICHERUNG_AG: "INTER Krankenversicherung aG",
      INTER_LEBENSVERSICHERUNG_AG: "INTER Lebensversicherung AG",
      INTERLLOYD_VERSICHERUNGS_AG: "Interlloyd Versicherungs-AG",
      INTERRISK_LEBENSVERSICHERUNGS_AG_VIENNA_INSURANCE_GROUP:
        "InterRisk Lebensversicherungs-AG Vienna Insurance Group",
      INTERRISK_VERSICHERUNGS_AG: "InterRisk Versicherungs-AG",
      ISSELHORSTER_VERSICHERUNG_VVAG: "ISSELHORSTER Versicherung V.VaG",
      ITZEHOER_LEBENSVERSICHERUNGS_AG: "Itzehoer Lebensversicherungs-AG",
      ITZEHOER_VERSICHERUNG_BRANDGILDE_VON_1691_VERSICHERUNGSVEREIN_VAG:
        "Itzehoer Versicherung/Brandgilde von 1691 Versicherungsverein VaG",
      JANITOS_VERSICHERUNG_AG: "Janitos Versicherung AG",
      JUNG_DMS_UND_CIE: "Jung DMS und Cie.",
      JURPARTNER_RECHTSSCHUTZ_VERSICHERUNG_AG:
        "Jurpartner Rechtsschutz-Versicherung AG",
      KAB_MAKLERSERVICE: "KAB Maklerservice",
      KARLSRUHER_LEBENSVERSICHERUNG_AG: "Karlsruher Lebensversicherung AG",
      KRAVAG_ALLGEMEINE_VERSICHERUNGS_AG: "KRAVAG-ALLGEMEINE Versicherungs-AG",
      KRAVAG_LOGISTIC_VERSICHERUNGS_AG: "KRAVAG-LOGISTIC Versicherungs-AG",
      KRAVAG_SACH_VERSICHERUNG_DES_DEUTSCHEN_KRAFTVERKEHRS_VERSICHERUNGSVEREIN_VAG:
        "KRAVAG-SACH Versicherung des Deutschen Kraftverkehrs Versicherungsverein VaG",
      KS_VERSICHERUNGS_AG: "KS Versicherungs-AG",
      KOELNER_PENSIONSKASSE_VERSICHERUNGSVEREIN_VAG:
        "Kölner Pensionskasse Versicherungsverein VaG",
      LANDESKRANKENHILFE_VVVAG: "Landeskrankenhilfe V.V.VaG",
      LANDESLEBENSHILFE_VVVAG: "Landeslebenshilfe V.V.VaG",
      LANDESSCHADENHILFE_VERSICHERUNG_VAG:
        "Landesschadenhilfe Versicherung VaG",
      LBN_VERSICHERUNGSVEREIN_VAG: "LBN-Versicherungsverein VaG (VVaG)",
      LEBENSVERSICHERUNG_VON_1871_VAG_MUENCHEN:
        "Lebensversicherung von 1871 VaG München",
      LIGA_KRANKENVERSICHERUNG_KATHOLISCHER_PRIESTER_VERSICHERUNGSVEREIN_VAG_REGENSBURG:
        "LIGA Krankenversicherung katholischer Priester Versicherungsverein VaG Regensburg",
      LIPPISCHE_PENSIONSFONDS_AG: "Lippische Pensionsfonds AG",
      LVM_KRANKENVERSICHERUNGS_AG: "LVM Krankenversicherungs-AG",
      LVM_LANDWIRTSCHAFTLICHER_VERSICHERUNGSVEREIN_MUENSTER_VAG:
        "LVM Landwirtschaftlicher Versicherungsverein Münster VaG",
      LVM_LEBENSVERSICHERUNGS_AG: "LVM Lebensversicherungs-AG",
      LVM_PENSIONSFONDS_AG: "LVM Pensionsfonds-AG",
      MAMAX_LEBENSVERSICHERUNG_AG: "mamax Lebensversicherung AG",
      MANNHEIMER_AG_HOLDING: "Mannheimer AG Holding",
      MANNHEIMER_KRANKENVERSICHERUNG_AG: "Mannheimer Krankenversicherung AG",
      MANNHEIMER_VERSICHERUNG_AG: "Mannheimer Versicherung AG",
      MAXPOOL: "Maxpool",
      MECKLENBURGISCHE_KRANKENVERSICHERUNGS_AG:
        "Mecklenburgische Krankenversicherungs-AG",
      MECKLENBURGISCHE_LEBENSVERSICHERUNGS_AG:
        "Mecklenburgische Lebensversicherungs-AG",
      MECKLENBURGISCHE_VERSICHERUNGS_GESELLSCHAFT_VAG:
        "Mecklenburgische Versicherungs-Gesellschaft VaG",
      MEDIEN_VERSICHERUNG_AG_KARLSRUHE_VORM_BUCHGEWERBE_FEUERVERSICHERUNG:
        "MEDIEN-VERSICHERUNG aG KARLSRUHE vorm. Buchgewerbe-Feuerversicherung",
      MIDEMA_GMBH: "Midema GmbH",
      MINERVA_VERSICHERUNGS_AG: "Minerva Versicherungs-AG",
      MSIG_INSURANCE_EUROPE_AG: "MSIG Insurance Europe AG",
      MYLIFE_LEBENSVERSICHERUNG_AG: "myLife Lebensversicherung AG",
      MUENCHENER_RUECK_VERSORGUNGSKASSE: "Münchener Rück Versorgungskasse",
      MUENCHENER_RUECKVERSICHERUNGS_GESELLSCHAFT_AG_IN_MUENCHEN:
        "Münchener Rückversicherungs-Gesellschaft AG in München",
      MUENCHENER_UND_MAGDEBURGER_AGRARVERSICHERUNG_AG:
        "Münchener und Magdeburger Agrarversicherung AG",
      MUENCHENER_VEREIN_ALLGEMEINE_VERSICHERUNGS_AG:
        "MÜNCHENER VEREIN Allgemeine Versicherungs-AG",
      MUENCHENER_VEREIN_KRANKENVERSICHERUNG_VAG:
        "MÜNCHENER VEREIN Krankenversicherung VaG",
      MUENCHENER_VEREIN_LEBENSVERSICHERUNG_VAG:
        "MÜNCHENER VEREIN Lebensversicherung VaG",
      NECKERMANN_VERSICHERUNG_AG: "Neckermann Versicherung AG",
      NETFONDS_AG: "Netfonds AG",
      NEUE_BAYERISCHE_BEAMTEN_LEBENSVERSICHERUNG_AG:
        "Neue Bayerische Beamten Lebensversicherung AG",
      NEUE_LEBEN_LEBENSVERSICHERUNG_AG: "neue leben Lebensversicherung AG",
      NEUE_LEBEN_PENSIONSKASSE_AG: "neue leben Pensionskasse AG",
      NEUE_LEBEN_UNFALLVERSICHERUNG_AG: "neue leben Unfallversicherung AG",
      NEUE_RECHTSSCHUTZ_VERSICHERUNGSGESELLSCHAFT_AG:
        "Neue Rechtsschutz-Versicherungsgesellschaft-AG",
      NEUENDORFER_BRAND_BAU_GILDE: "Neuendorfer Brand-Bau-Gilde",
      NORDHEMMER_VERSICHERUNGSVEREIN_VAG: "Nordhemmer Versicherungsverein VaG",
      NV_VERSICHERUNGEN_VVAG: "NV-Versicherungen VVaG",
      NUERNBERGER_ALLGEMEINE_VERSICHERUNGS_AG:
        "NÜRNBERGER Allgemeine Versicherungs-AG",
      NUERNBERGER_BEAMTEN_ALLGEMEINE_VERSICHERUNG_AG:
        "NÜRNBERGER BEAMTEN ALLGEMEINE VERSICHERUNG AG",
      NUERNBERGER_BEAMTEN_LEBENSVERSICHERUNG_AG:
        "Nürnberger Beamten Lebensversicherung AG",
      NUERNBERGER_KRANKENVERSICHERUNG_AG: "NÜRNBERGER Krankenversicherung AG",
      NUERNBERGER_LEBENSVERSICHERUNG_AG: "NÜRNBERGER Lebensversicherung AG",
      NUERNBERGER_PENSIONSFONDS_AG: "NÜRNBERGER Pensionsfonds AG",
      NUERNBERGER_PENSIONSKASSE_AG: "NÜRNBERGER Pensionskasse AG",
      OECO_CAPITAL_LEBENSVERSICHERUNG_AG: "oeco capital Lebensversicherung AG",
      OKV___OSTDEUTSCHE_KOMMUNALVERSICHERUNG_VAG:
        "OKV - Ostdeutsche Kommunalversicherung VaG",
      OPTIMA_PENSIONSKASSE_AG: "Optima Pensionskasse AG",
      OSTANGLER_BRANDGILDE_VVAG: "OSTANGLER BRANDGILDE VVaG",
      OSTBEVERNER_VVAG: "OSTBEVERNER VVaG",
      OVAG___OSTDEUTSCHE_VERSICHERUNG_AG: "OVAG - Ostdeutsche Versicherung AG",
      PALLAS_VERSICHERUNG_AG: "Pallas Versicherung AG",
      PAX_FAMILIENFUERSORGE_KRANKENVERSICHERUNG_AG:
        "PAX-FAMILIENFÜRSORGE Krankenversicherung AG",
      PB_LEBENSVERSICHERUNG_AG: "PB Lebensversicherung AG",
      PB_PENSIONSFONDS_AG: "PB Pensionsfonds AG",
      PB_PENSIONSKASSE_AG: "PB Pensionskasse AG",
      PB_VERSICHERUNG_AG: "PB Versicherung AG",
      PENSIONS_SICHERUNGS_VEREIN_VERSICHERUNGSVEREIN_VAG:
        "PENSIONS-SICHERUNGS-VEREIN Versicherungsverein VaG",
      PENSIONSKASSE_DER_WUERTTEMBERGISCHEN:
        "Pensionskasse der Württembergischen",
      PENSIONSKASSE_SIGNAL_VERSICHERUNGEN:
        "Pensionskasse SIGNAL Versicherungen",
      PMA_GMBH: "PMA GmbH",
      PRISMALIFE_AG: "PrismaLife AG",
      PRO_BAV_PENSIONSKASSE_AG: "Pro bAV Pensionskasse AG",
      PROTECT_VERSICHERUNG_AG: "ProTect Versicherung AG",
      PROTEKTOR_LEBENSVERSICHERUNGS_AG: "Protektor Lebensversicherungs-AG",
      PROVINZIAL_KRANKENVERSICHERUNG_HANNOVER_AG:
        "Provinzial Krankenversicherung Hannover AG",
      PROVINZIAL_LEBENSVERSICHERUNG_HANNOVER:
        "Provinzial Lebensversicherung Hannover",
      PROVINZIAL_NORD_BRANDKASSE_AG: "Provinzial Nord Brandkasse AG",
      PROVINZIAL_NORDWEST_HOLDING_AG: "Provinzial NordWest Holding AG",
      PROVINZIAL_NORDWEST_LEBENSVERSICHERUNG_AG:
        "Provinzial NordWest Lebensversicherung AG",
      PROVINZIAL_PENSIONSKASSE_HANNOVER_AG:
        "Provinzial Pensionskasse Hannover AG",
      PROVINZIAL_RHEINLAND_HOLDING: "Provinzial Rheinland Holding",
      PROVINZIAL_RHEINLAND_LEBENSVERSICHERUNG_AG_DIE_VERSICHERUNG_DER_SPARKASSEN:
        "Provinzial Rheinland Lebensversicherung AG Die Versicherung der Sparkassen",
      PROVINZIAL_RHEINLAND_VERSICHERUNG_AG_DIE_VERSICHERUNG_DER_SPARKASSEN:
        "Provinzial Rheinland Versicherung AG Die Versicherung der Sparkassen",
      PRUDENTIA_LEBENSVERSICHERUNGS_AG: "PRUDENTIA-Lebensversicherungs-AG",
      PVAG_POLIZEIVERSICHERUNGS_AG: "PVAG Polizeiversicherungs-AG",
      QUALITYPOOL_GMBH: "Qualitypool GmbH",
      R___V_LEBENSVERSICHERUNG_VAG: "R + V Lebensversicherung VaG",
      R___V_LEBENSVERSICHERUNG_AG: "R + V LEBENSVERSICHERUNG AG",
      R_V_ALLGEMEINE_VERSICHERUNG_AG: "R+V ALLGEMEINE VERSICHERUNG AG",
      R_V_DIREKTVERSICHERUNG_AG: "R+V Direktversicherung AG",
      R_V_KRANKENVERSICHERUNG_AG: "R+V Krankenversicherung AG",
      R_V_PENSIONSFONDS_AG: "R+V Pensionsfonds AG",
      R_V_PENSIONSKASSE_AG: "R+V PENSIONSKASSE AG",
      R_V_PENSIONSVERSICHERUNG_VAG: "R+V PENSIONSVERSICHERUNG VaG",
      R_V_VERSICHERUNG_AG: "R+V Versicherung AG",
      RHEINLAND_LEBENSVERSICHERUNG_AG: "RheinLand Lebensversicherung AG",
      RHEINLAND_VERSICHERUNGS_AG: "RheinLand Versicherungs AG",
      RHION_VERSICHERUNG_AG: "Rhion Versicherung AG",
      ROLAND_RECHTSSCHUTZ_VERSICHERUNGS_AG:
        "ROLAND Rechtsschutz-Versicherungs-AG",
      ROLAND_SCHUTZBRIEF_VERSICHERUNG_AG: "ROLAND Schutzbrief-Versicherung AG",
      SAARLAND_FEUERVERSICHERUNG_AG: "SAARLAND Feuerversicherung AG",
      SAARLAND_LEBENSVERSICHERUNG_AG: "SAARLAND Lebensversicherung AG",
      SCHLESWIGER_VERSICHERUNGSVEREIN_VAG:
        "Schleswiger Versicherungsverein VaG",
      SCHNEVERDINGER_VERSICHERUNGSVEREIN_VAG:
        "SCHNEVERDINGER Versicherungsverein VaG",
      SCHWARZMEER_UND_OSTSEE_VERSICHERUNGS_AG_SOVAG:
        "SCHWARZMEER UND OSTSEE Versicherungs-AG SOVAG",
      SCHWEIZER_NATIONAL_VERSICHERUNGS_AG_IN_DEUTSCHLAND:
        "Schweizer-National Versicherungs-AG in Deutschland",
      SHB_ALLGEMEINE_VERSICHERUNG_VVAG: "SHB Allgemeine Versicherung VVaG",
      SIGNAL_IDUNA_ALLGEMEINE_VERSICHERUNG_AG:
        "SIGNAL IDUNA Allgemeine Versicherung AG",
      SIGNAL_IDUNA_PENSIONSKASSE_AG: "SIGNAL IDUNA Pensionskasse AG",
      SIGNAL_KRANKENVERSICHERUNG_VAG: "SIGNAL Krankenversicherung VaG",
      SIGNAL_UNFALLVERSICHERUNG_VAG: "SIGNAL Unfallversicherung VaG",
      SKANDIA_LEBENSVERSICHERUNG_AG: "Skandia Lebensversicherung AG",
      SONO_KRANKENVERSICHERUNG_VAG: "SONO Krankenversicherung VaG",
      SONO_STERBEGELDVERSICHERUNG_VAG: "SONO Sterbegeldversicherung VaG",
      SPARKASSEN_PENSIONSKASSE_AG: "Sparkassen Pensionskasse AG",
      SPARKASSEN_VERSICHERUNG_SACHSEN_ALLGEMEINE_VERSICHERUNG_AG:
        "Sparkassen-Versicherung Sachsen Allgemeine Versicherung AG",
      SPARKASSEN_VERSICHERUNG_SACHSEN_LEBENSVERSICHERUNG_AG:
        "Sparkassen-Versicherung Sachsen Lebensversicherung AG",
      STUTTGARTER_LEBENSVERSICHERUNG_VAG: "Stuttgarter Lebensversicherung VaG",
      STUTTGARTER_VERSICHERUNG_AG: "Stuttgarter Versicherung AG",
      SV_SPARKASSENVERSICHERUNG_GEBAEUDEVERSICHERUNG_AG:
        "SV SparkassenVersicherung Gebäudeversicherung AG",
      SV_SPARKASSENVERSICHERUNG_HOLDING_AG:
        "SV SparkassenVersicherung Holding AG",
      SV_SPARKASSENVERSICHERUNG_LEBENSVERSICHERUNG_AG:
        "SV SparkassenVersicherung Lebensversicherung AG",
      SWISS_LIFE_AG: "Swiss Life AG",
      SWISS_LIFE_PENSIONSFONDS_AG: "Swiss Life Pensionsfonds AG",
      SWISS_LIFE_PENSIONSKASSE_AG: "Swiss Life Pensionskasse AG",
      SUEDDEUTSCHE_ALLGEMEINE_VERSICHERUNG_VAG:
        "Süddeutsche Allgemeine Versicherung VaG",
      SUEDDEUTSCHE_KRANKENVERSICHERUNG_VAG:
        "Süddeutsche Krankenversicherung VaG",
      SUEDDEUTSCHE_LEBENSVERSICHERUNG_VAG: "Süddeutsche Lebensversicherung VaG",
      TARGO_LEBENSVERSICHERUNG_AG: "TARGO Lebensversicherung AG",
      TARGO_VERSICHERUNG_AG: "TARGO Versicherung AG",
      TRANSATLANTIC_REINSURANCE_COMPANY: "Transatlantic Reinsurance Company",
      TRIAS_VERSICHERUNG_AG: "TRIAS Versicherung AG",
      UELZENER_ALLGEMEINE_VERSICHERUNGS_GESELLSCHAFT_VAG:
        "Uelzener Allgemeine Versicherungs-Gesellschaft VaG",
      UELZENER_LEBENSVERSICHERUNGS_AG: "Uelzener Lebensversicherungs-AG",
      UNION_KRANKENVERSICHERUNG_AG: "UNION KRANKENVERSICHERUNG AG",
      UNION_REISEVERSICHERUNG_AG: "Union Reiseversicherung AG",
      UNIVERSA_ALLGEMEINE_VERSICHERUNG_AG:
        "uniVersa Allgemeine Versicherung AG",
      UNIVERSA_KRANKENVERSICHERUNG_VAG: "uniVersa Krankenversicherung VaG",
      UNIVERSA_LEBENSVERSICHERUNG_VAG: "uniVersa Lebensversicherung VaG",
      VEREINIGTE_HAGELVERSICHERUNG_VVAG: "Vereinigte Hagelversicherung VVaG",
      VEREINIGTE_POSTVERSICHERUNG_VVAG: "VEREINIGTE POSTVERSICHERUNG VVaG",
      VEREINIGTE_SCHIFFS_VERSICHERUNG_VVAG:
        "Vereinigte Schiffs-Versicherung V.VaG",
      VEREINIGTE_TIERVERSICHERUNG_GESELLSCHAFT_VAG:
        "VEREINIGTE TIERVERSICHERUNG GESELLSCHAFT VaG",
      VERKA_PK_KIRCHLICHE_PENSIONSKASSE_AG:
        "VERKA PK Kirchliche Pensionskasse AG",
      VERKA_VK_KIRCHLICHE_VORSORGE_VVAG: "VERKA VK Kirchliche Vorsorge VVaG",
      VERSICHERUNGSKAMMER_BAYERN_KONZERN_RUECKVERSICHERUNG_AG:
        "Versicherungskammer Bayern Konzern-Rückversicherung AG",
      VERSICHERUNGSKAMMER_BAYERN_PENSIONSKASSE_AG:
        "Versicherungskammer Bayern Pensionskasse AG",
      VERSICHERUNGSKAMMER_BAYERN_VERSICHERUNGSANSTALT_DES_OEFFENTLICHEN_RECHTS:
        "Versicherungskammer Bayern Versicherungsanstalt des öffentlichen Rechts",
      VFM_GRUPPE: "Vfm-Gruppe",
      VFV_GMBH: "VFV GmbH",
      VHV_ALLGEMEINE_VERSICHERUNG_AG: "VHV Allgemeine Versicherung AG",
      VHV_LEBENSVERSICHERUNG_AG: "VHV Lebensversicherung AG",
      VHV_VEREINIGTE_HANNOVERSCHE_VERSICHERUNG_VAG:
        "VHV Vereinigte Hannoversche Versicherung VaG",
      VICTORIA_LEBENSVERSICHERUNG_AG: "Victoria Lebensversicherung AG",
      VIFA_PENSIONSFONDS_AG: "VIFA Pensionsfonds AG",
      VIGO_KRANKENVERSICHERUNG_VVAG: "vigo Krankenversicherung VVaG",
      VOLKSWAGEN_AUTOVERSICHERUNG_AG: "Volkswagen Autoversicherung AG",
      VOLKSWAGEN_VERSICHERUNG_AG: "Volkswagen Versicherung AG",
      VOLKSWOHL_BUND_LEBENSVERSICHERUNG_VAG:
        "Volkswohl-Bund Lebensversicherung VaG",
      VOLKSWOHL_BUND_SACHVERSICHERUNG_AG: "VOLKSWOHL-BUND SACHVERSICHERUNG AG",
      VORSORGE_LEBENSVERSICHERUNG_AG: "VORSORGE Lebensversicherung AG",
      VPV_ALLGEMEINE_VERSICHERUNGS_AG: "VPV Allgemeine Versicherungs-AG",
      VPV_LEBENSVERSICHERUNGS_AG: "VPV Lebensversicherungs-AG",
      VRK_VERSICHERUNGSVEREIN_VAG_IM_RAUM_DER_KIRCHEN:
        "VRK Versicherungsverein VaG im Raum der Kirchen",
      WALDENBURGER_VERSICHERUNG_AG: "Waldenburger Versicherung AG",
      WERTGARANTIE_AG: "WERTGARANTIE AG",
      WESTFAELISCHE_PROVINZIAL_VERSICHERUNG_AG:
        "Westfälische Provinzial Versicherung AG",
      WGV_LEBENSVERSICHERUNG_AG: "WGV-Lebensversicherung AG",
      WGV_VERSICHERUNG_AG: "WGV-Versicherung AG",
      WIFO_WIRTSCHAFTS__UND_FONDSANLAGENBERATUNG_UND_VERSICHERUNGSMAKLER_GMBH:
        "WIFO Wirtschafts- und Fondsanlagenberatung und Versicherungsmakler GmbH",
      WWK_ALLGEMEINE_VERSICHERUNG_AG: "WWK Allgemeine Versicherung AG",
      WWK_LEBENSVERSICHERUNG_VAG: "WWK Lebensversicherung VaG",
      WWK_PENSIONSFONDS_AG: "WWK Pensionsfonds AG",
      WUERTTEMBERGISCHE_GEMEINDE_VERSICHERUNG_VAG:
        "Württembergische Gemeinde-Versicherung VaG",
      WUERTTEMBERGISCHE_KRANKENVERSICHERUNG_AG:
        "Württembergische Krankenversicherung AG",
      WUERTTEMBERGISCHE_LEBENSVERSICHERUNG_AG:
        "Württembergische Lebensversicherung AG",
      WUERTTEMBERGISCHE_VERSICHERUNG_AG: "Württembergische Versicherung AG",
      WUERZBURGER_VERSICHERUNGS_AG: "Würzburger Versicherungs-AG",
      WUESTENROT_UND_WUERTTEMBERGISCHE_AG: "Wüstenrot und Württembergische AG",
      ZURICH_DEUTSCHER_HEROLD_LEBENSVERSICHERUNG_AG:
        "Zurich Deutscher Herold Lebensversicherung AG",
      OEFFENTLICHE_LEBENSVERSICHERUNG_BERLIN_BRANDENBURG_AG:
        "Öffentliche Lebensversicherung Berlin Brandenburg AG",
      OERAG_RECHTSSCHUTZVERSICHERUNGS_AG: "ÖRAG Rechtsschutzversicherungs-AG",
      SONSTIGE: "Sonstiges",
    },
  },
};
