import styles from "pages/insurances/InsuranceInfoPage.module.scss";

import React, { FunctionComponent, ReactNode } from "react";
import { Button } from "components/form-inputs/Button/Button";
import Page from "components/page/Page";

import { InsuranceHeader } from "../../components/insurances/InsuranceHeader/InsuranceHeader";
import { InsuranceChecklist } from "../../components/insurances/InsuranceChecklist/InsuranceChecklist";
import { InsuranceCompareTeaser } from "../../components/insurances/InsuranceCompareTeaser/InsuranceCompareTeaser";
import { InsuranceLongDescription } from "../../components/insurances/InsuranceLongDescription/InsuranceLongDescription";
import { ProductTreeInsuranceType } from "../../content/productTreeDefinition";
import { InsuranceCompanyCarousel } from "../../components/insurances/InsuranceCompanyCarousel/InsuranceCompanyCarousel";
import UnstyledLink from "../../components/unstyle-link/UnstyledLink";
import { InsuranceSituation } from "components/insurances/InsuranceSituation/InsuranceSituation";
import { InsuranceActionBox } from "../../components/insurances/InsuranceBoxes/InsuranceActionBoxes/InsuranceActionBox/InsuranceActionBox";
import { InsuranceActionBoxes } from "../../components/insurances/InsuranceBoxes/InsuranceActionBoxes/InsuranceActionBoxes";
import { InsuranceBenefitComparisonBox } from "../../components/insurances/InsuranceBoxes/InsuranceBenefitComparisonBoxes/InsuranceBenefitComparisonBox/InsuranceBenefitComparisonBox";
import { InsuranceBenefitComparisonBoxes } from "../../components/insurances/InsuranceBoxes/InsuranceBenefitComparisonBoxes/InsuranceBenefitComparisonBoxes";
import { InsuranceBenefitBox } from "../../components/insurances/InsuranceBoxes/InsuranceBenefitBoxes/InsuranceBenefitBox/InsuranceBenefitBox";
import { InsuranceBenefitBoxes } from "../../components/insurances/InsuranceBoxes/InsuranceBenefitBoxes/InsuranceBenefitBoxes";
import useMatchMedia from "hooks/useMatchMedia";
import appBreakpoints from "config/pageBreakpoints";

type InsurancePageType = {
  insurance: ProductTreeInsuranceType;
  children?: ReactNode;
};

const InsuranceInfoPage: FunctionComponent<InsurancePageType> = ({
  insurance,
  children,
}) => {
  const isMobile = useMatchMedia(`(max-width: ${appBreakpoints.md})`);
  return (
    <Page contentClassName={styles.content}>
      <InsuranceHeader image={insurance.image} insuranceName={insurance.title}>
        {insurance.introduction}
        {insurance.headerButtons && (
          <div className={styles.callToActionBox}>
            {insurance.headerButtons.map((button) => (
              <UnstyledLink
                to={button.link}
                key={button.label + button.link}
                className={styles.actionButtonWrapper}
              >
                <Button label={button.label} className={styles.actionButton} />
              </UnstyledLink>
            ))}
          </div>
        )}
      </InsuranceHeader>

      {insurance.insuranceSituation && (
        <InsuranceSituation
          situations={insurance.insuranceSituation}
          insuranceName={insurance.title}
        />
      )}

      {insurance.longDescriptions && (
        <InsuranceLongDescription
          insuranceName={insurance.title}
          descriptions={insurance.longDescriptions}
        />
      )}

      {insurance.insuranceBenefits && (
        <InsuranceBenefitBoxes insuranceName={insurance.title}>
          {insurance.insuranceBenefits.map((benefitBox) => (
            <InsuranceBenefitBox
              key={benefitBox.titleAccepted}
              titleAccepted={benefitBox.titleAccepted}
              titleNotAccepted={benefitBox.titleNotAccepted}
              insuranceName={insurance.title}
              acceptedItem={benefitBox.accepted.map((bullet) => bullet)}
              notAcceptedItem={benefitBox.notAccepted.map((bullet) => bullet)}
            />
          ))}
        </InsuranceBenefitBoxes>
      )}

      {insurance.comparisonBoxes && (
        <>
          <InsuranceBenefitComparisonBoxes>
            {insurance.comparisonBoxes.map((box) => (
              <InsuranceBenefitComparisonBox
                key={box.title}
                image={box.image}
                title={box.title}
                acceptedItems={box.accepted.items}
                acceptedTitle={box.accepted.title}
                notAcceptedItems={box.notAccepted.items}
                notAcceptedTitle={box.notAccepted.title}
              />
            ))}
          </InsuranceBenefitComparisonBoxes>
          <InsuranceCompareTeaser />
        </>
      )}
      {!isMobile && (
        <>
          {insurance.insuranceCompanies && (
            <InsuranceCompanyCarousel
              insurances={insurance.insuranceCompanies}
            />
          )}
        </>
      )}

      {children}

      {insurance.checklist && (
        <InsuranceChecklist bullets={insurance.checklist} />
      )}

      {insurance.actionBoxes && (
        <InsuranceActionBoxes insuranceName={insurance.title}>
          {insurance.actionBoxes.map((actionBox) => (
            <InsuranceActionBox
              key={actionBox.title + actionBox.action}
              image={actionBox.image}
              title={actionBox.title}
              bullets={actionBox.bullets.map((bullet) => bullet.text)}
              action={actionBox.action}
              actionHref={actionBox.actionHref}
            />
          ))}
        </InsuranceActionBoxes>
      )}
    </Page>
  );
};
export default InsuranceInfoPage;
