import React, { ReactElement } from "react";
import clsx from "clsx";
import { v4 } from "uuid";
import styles from "./Checkbox.module.scss";
import { FormInputLabel } from "../FormInputLabel";
import {
  FormInputCustomStyleProperties,
  FormInputProperties,
} from "../FormInputTypes";
import { FormInputSubText } from "../FormInputSubText";

export type CheckboxProperties = Omit<FormInputProperties, "placeholder"> & {
  type: "radio" | "checkbox";
  value: string;
};

export const CustomizableCheckbox = ({
  field,
  form,
  labelText,
  helpText,
  disabled,
  tabIndex,
  value,
  type,
  lightColor,
  inputClassName,
  labelClassName,
  validationClassName,
  helpTextClassName,
}: CheckboxProperties & FormInputCustomStyleProperties): ReactElement => {
  const id = field.name + "-" + v4();
  const meta = form.getFieldMeta(field.name);
  return (
    <div>
      <div className={styles.inputContainer}>
        <input
          {...field}
          type={type}
          id={id}
          className={inputClassName}
          value={value}
          disabled={disabled}
          tabIndex={tabIndex}
        />
        <FormInputLabel
          labelText={labelText}
          labelClassName={clsx(styles.inputLabel, labelClassName)}
          id={id}
          lightColor={lightColor}
        />
      </div>
      {type === "checkbox" && (
        <FormInputSubText
          meta={meta}
          helpText={helpText}
          helpTextClassName={helpTextClassName}
          validationClassName={validationClassName}
        />
      )}
    </div>
  );
};

export const Checkbox = (props: CheckboxProperties) => {
  return <CustomizableCheckbox {...props} />;
};
