import styles from "components/user-portal/user-profile/Profile.module.scss";

import React, { ReactElement, useState } from "react";
import EditRisksDialog from "components/user-portal/user-profile/dialogs/risks/EditRisksDialog";
import ProfileCard from "components/user-portal/user-profile/ProfileCard";
import UserProfileItem from "components/user-portal/user-profile/UserProfileItem";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { formatBoolean } from "utils/formatUtils";

interface RisksCardProps {
  kunde: KundeResponse;
}

export const RisksCard = ({ kunde }: RisksCardProps): ReactElement => {
  const [isRisksDialogOpen, setIsRisksDialogOpen] = useState(false);

  return (
    <ProfileCard
      title={STRINGS.profile.risks.header}
      className={styles.profileCard}
      onEdit={() => setIsRisksDialogOpen(true)}
    >
      <UserProfileItem
        label={STRINGS.profile.risks.foreignTravel}
        value={formatBoolean(kunde.auslandsReisen)}
      />
      <UserProfileItem
        label={STRINGS.profile.risks.dogOrHorse}
        value={formatBoolean(kunde.hundOderPferd)}
      />
      <UserProfileItem
        label={STRINGS.profile.risks.motorVehicle}
        value={formatBoolean(kunde.kraftfahrzeug)}
      />
      <UserProfileItem
        label={STRINGS.profile.risks.airVehicle}
        value={formatBoolean(kunde.luftfahrzeug)}
      />
      <UserProfileItem
        label={STRINGS.profile.risks.waterVehicle}
        value={formatBoolean(kunde.wasserfahrzeug)}
      />
      <UserProfileItem
        label={STRINGS.profile.risks.hunting}
        value={formatBoolean(kunde.jagen)}
      />
      <UserProfileItem
        label={STRINGS.profile.risks.oilTank}
        value={formatBoolean(kunde.oeltank)}
      />
      <UserProfileItem
        label={STRINGS.profile.risks.photovoltaic}
        value={formatBoolean(kunde.photovoltaikAnlage)}
      />
      <UserProfileItem
        label={STRINGS.profile.risks.buildOrSanitize}
        value={formatBoolean(kunde.bauenOderSanieren)}
      />
      <EditRisksDialog
        kunde={kunde}
        open={isRisksDialogOpen}
        onClose={() => setIsRisksDialogOpen(false)}
      />
    </ProfileCard>
  );
};
