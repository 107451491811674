import styles from "components/molecules/FilterBox.module.scss";

import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useField } from "formik";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";

export type FilterBoxOptions = {
  id: string;
  name: string;
};

export type FilterBoxProperties = {
  inputLabel?: string;
  options: Array<FilterBoxOptions>;
  withLabelReplace?: boolean;
  boxClass?: string;
  contentPosition?: "left" | "right";
  inputName: string;
  selectHandler?: (value: string) => void;
  withFormik?: boolean;
  boldLabel?: boolean;
};

export const FilterBox: FunctionComponent<FilterBoxProperties> = ({
  inputLabel,
  options,
  withLabelReplace = false,
  boxClass,
  contentPosition = "right",
  inputName,
  selectHandler,
  withFormik = false,
  boldLabel = true,
}) => {
  const [open, setOpen] = useState(false);
  const [labelReplacement, setLabelReplacement] = useState<string>("");
  const ref = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,react-hooks/rules-of-hooks
  const [field, meta, helpers] = withFormik ? useField(inputName) : [];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", (e) => handleClickOutside(e), true);
    return () => {
      document.removeEventListener("click", (e) => handleClickOutside(e), true);
    };
  }, []);

  const clickHandler = (value?: string) => {
    setOpen(false);
    withLabelReplace && value && setLabelReplacement(value);
  };

  return (
    <Box flex column className={clsx(boxClass)} reference={ref}>
      <Box
        flex
        inline
        className={clsx(
          contentPosition === "left" ? styles.textLeft : styles.textRight,
          styles.filterButton
        )}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Text
          size={"small"}
          inline
          bold={boldLabel}
          className={styles.inputLabel}
        >
          {labelReplacement === "" ? inputLabel : labelReplacement}
        </Text>
        <FontAwesomeIcon
          icon={!open ? faAngleDown : faAngleUp}
          className={styles.arrowIcon}
        />
      </Box>
      <Box
        flex
        column
        className={clsx(
          styles.filterCriteriaBox,
          open && styles.filterCriteriaBoxOpen,
          contentPosition === "left" ? styles.contentLeft : styles.contentRight
        )}
      >
        {options.map((option, index) => (
          <label
            className={clsx(
              contentPosition === "left"
                ? styles.contentLeft
                : styles.contentRight,
              styles.filterCriteriaItem
            )}
            key={index}
            onClick={() => {
              clickHandler(option.name);
              withFormik && helpers && helpers.setValue(option.name);
              selectHandler && selectHandler(option.name);
            }}
          >
            <Text inline size={"xSmall"}>
              {option.name}
            </Text>
          </label>
        ))}
      </Box>
    </Box>
  );
};
