import { FinanzthemaResult } from "../../../types/backend/FinanzthemaResult";
import { ProductProposition } from "../DemandCalculator";
import { GenericProduktType } from "../../../types/backend/GenericProduktType";
import { ProductTreeInsuranceType } from "../../../content/productTreeDefinition";
import { InsuranceProducts } from "../../../content/productTree";

const mapProduct = (
  produktType?: GenericProduktType
): ProductTreeInsuranceType | undefined => {
  switch (produktType) {
    case "KFZ_KASKO_VERSICHERUNG":
      return InsuranceProducts.KFZ_VERSICHERUNG;
  }
  if (produktType) {
    return InsuranceProducts[produktType];
  }
  return undefined;
};

const findByGenericProductType = (
  finanzthemaResults: FinanzthemaResult[],
  type?: GenericProduktType
) => {
  return finanzthemaResults.filter((f) => f.produkt?.type === type);
};

export const mapEssentialProductPropositions = (
  finanzthemaResults: FinanzthemaResult[]
): ProductProposition[] => {
  return finanzthemaResults
    .filter(
      (f: FinanzthemaResult) => f.notwendig && f.finanzthema.bedarfsstufe === 1
    )
    .map((f: FinanzthemaResult) => {
      const resultsWithEqualThemaType = findByGenericProductType(
        finanzthemaResults,
        f.produkt?.type
      );

      const sollWert = resultsWithEqualThemaType.find(
        (f) => f.finanzthema.bedarfsstufe === 1
      )?.score?.sollWert;
      const optimumSollWert = resultsWithEqualThemaType.find(
        (f) => f.finanzthema.bedarfsstufe === 2
      )?.score?.sollWert;
      const product = mapProduct(f.produkt?.type);
      return {
        rang: f.finanzthema.rang,
        product,
        sollWert,
        ratio: f.score?.ratio,
        optimumSollWert,
      };
    });
};

export const mapImportantProductPropositions = (
  finanzthemaResults: FinanzthemaResult[]
): ProductProposition[] => {
  return finanzthemaResults
    .filter(
      (d: FinanzthemaResult) =>
        d.notwendig &&
        d.finanzthema.bedarfsstufe === 2 &&
        findByGenericProductType(finanzthemaResults, d.produkt?.type).length ===
          1
    )
    .map((d: FinanzthemaResult) => {
      const sollWert = d.score?.sollWert;
      const product = mapProduct(d.produkt?.type);
      return {
        rang: d.finanzthema.rang,
        product,
        sollWert,
        ratio: d.score?.ratio,
      };
    });
};

export const mapRecommendedProductPropositions = (
  finanzthemaResults: FinanzthemaResult[]
): ProductProposition[] => {
  return finanzthemaResults
    .filter(
      (d: FinanzthemaResult) => d.notwendig && d.finanzthema.bedarfsstufe === 3
    )
    .map((d: FinanzthemaResult) => {
      const sollWert = d.score?.sollWert;
      const product = mapProduct(d.produkt?.type);
      return {
        rang: d.finanzthema.rang,
        product,
        sollWert,
        ratio: d.score?.ratio,
      };
    });
};
