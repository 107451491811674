import axios from "axios";
import api from "api/index";
import { appConfig } from "config/appConfig";
import { AddDokumenteRequest } from "types/userportal/AddDokumenteRequest";
import { isAccepted } from "utils/axiosHelpers";

const addDokumente = async (request: AddDokumenteRequest): Promise<void> => {
  const url = `${appConfig.apiUrl}/api/kunden/${request.kid}/vertraege/${request.vertragId}/dokumente`;
  const header = await api.getHeaderWithAuthorization();
  const formData = new FormData();
  request.files.forEach((file) => formData.append("files", file));
  await axios.post(url, formData, {
    headers: header,
    validateStatus: isAccepted,
  });
};

export default addDokumente;
