import { createContext } from "react";
import { ISignUpResult } from "amazon-cognito-identity-js";

export type UserGender = "" | "female" | "male" | "nonBinary";

export type RegistrationWizardContextState = {
  email: string;
  password: string;
  phoneNumber: string;
  gender: UserGender;
  firstName: string;
  lastName: string;
  awsResult?: ISignUpResult;
};

export type RegistrationWizardContextType = {
  state: RegistrationWizardContextState;
  setState: (state: RegistrationWizardContextState) => void;
};

const RegistrationWizardContext = createContext<
  RegistrationWizardContextType | undefined
>(undefined);

export default RegistrationWizardContext;
