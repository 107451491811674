import "react-phone-number-input/style.css";
import styles from "components/organisms/ForgotPasswordForm.module.scss";

import React, { ReactElement, useContext, useState } from "react";
import { Field, Form, Formik } from "formik";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";
import LoadingOverlay from "components/loading-overlay/LoadingOverlay";
import StepContainer from "components/step-wizard/components/StepContainer";
import ForgotPasswordWizardContext from "components/step-wizard/forgot-password/ForgotPasswordWizardContext";
import useTabIndex from "hooks/useTabIndex";
import useUserForgotPassword from "hooks/useUserForgotPassword";
import { STRINGS } from "language/de_DE/strings";
import { forgetPasswordFormSchema } from "utils/validators/auth/forgetPasswordForm";
import { Button } from "../form-inputs/Button/Button";
import { TextField } from "../form-inputs/TextField/TextField";

interface Props {
  nextStep?: () => void;
}

const ForgotPasswordForm = ({ nextStep }: Props): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { setState: updateForgotPasswordState } = useContext(
    ForgotPasswordWizardContext
  )!;

  const [forgotPasswordError, setForgotPasswordError] = useState<string | null>(
    null
  );
  const { getTabIndex } = useTabIndex();
  const { forgotPasswordUser } = useUserForgotPassword();
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <StepContainer>
      <LoadingOverlay isVisible={isSubmitting} />

      <Formik
        initialValues={{ email: "" }}
        onSubmit={async (values) => {
          setForgotPasswordError(null);
          setIsSubmitting(true);
          const result = await forgotPasswordUser(values.email);

          if (!result.ok) {
            setForgotPasswordError(result.message);
            setIsSubmitting(false);
            return;
          }
          const awsResult = result.data;
          updateForgotPasswordState({
            email: values.email,
            password: "",
            code: "",
            awsResult,
          });
          nextStep?.();
          return;
        }}
        enableReinitialize
        validationSchema={forgetPasswordFormSchema}
      >
        <Form>
          <Box flex column className={styles.forgotPasswordBox}>
            <Text size={"xLarge"} className={styles.title} inline>
              {STRINGS.account.forgotPassword.title}
            </Text>

            {forgotPasswordError !== null && (
              <Box flex column className={styles.formErrorContainer}>
                <Text size="medium" className={styles.formError} inline>
                  {forgotPasswordError}
                </Text>
              </Box>
            )}

            <Box flex column>
              <Field
                component={TextField}
                name={"email"}
                labelText={STRINGS.account.forgotPassword.inputEmailLabel}
                placeholder={
                  STRINGS.account.forgotPassword.inputEmailPlaceholder
                }
                tabIndex={getTabIndex()}
              />
              <Button
                primary
                label={STRINGS.account.forgotPassword.forgotPasswordButtonLabel}
                type={"submit"}
                className={styles.submitButton}
              />
            </Box>
          </Box>
        </Form>
      </Formik>
    </StepContainer>
  );
};

export default ForgotPasswordForm;
