import React, { ReactElement } from "react";
import { Field } from "formik";
import { AddContractTextInput } from "./base/AddContractTextInput";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { useVertragMapping } from "./useVertragMapping";
import { shouldDisplayField } from "./util/displayInputUtil";
import { VertragTyp } from "../../../../../types/backend/VertragTyp";

export const VertragsnummerInput = ({
  vertragTyp,
}: AddContractInputBaseType): ReactElement | null => {
  const { versicherungenTypes } = useVertragMapping();
  const requiredTypes: VertragTyp[] = [
    "SONSTIGE_VERTRAEGE",
    ...versicherungenTypes,
  ];
  if (!shouldDisplayField(vertragTyp, requiredTypes)) return null;
  return (
    <Field
      component={AddContractTextInput}
      name="vertragsnummer"
      labelText={STRINGS.addContract.step2.contractNumberLabel}
    />
  );
};
