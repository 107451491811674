import styles from "components/molecules/startpage/sections/Overview/Overview.module.scss";

import React, { FunctionComponent } from "react";
import { Button } from "components/form-inputs/Button/Button";
import { Image } from "components/atoms/Image";
import UnstyledLink from "components/unstyle-link/UnstyledLink";
import { RouteUrls } from "config/routes";
import Bulletpoint from "gfx/images/startpage/bulletpoint.png";
import Teaser1 from "gfx/images/startpage/teaser_overview.png";
import { STRINGS } from "language/de_DE/strings";
import PageSection from "../../../../page-section/PageSection";

export const Overview: FunctionComponent = () => (
  <PageSection background={"blue"} contentClassName={styles.paddinglessHeader}>
    <div className={styles.overview}>
      <div className={styles.content}>
        <div className={styles.uspBox}>
          <div className={styles.uspHeadline}>
            {STRINGS.startpage.overview.title}
          </div>

          <div className={styles.uspStatement}>
            <div>
              <Image
                className={styles.uspBulletpoint}
                alt={"Bullet"}
                src={Bulletpoint}
              />
            </div>
            <div className={styles.uspStatementText}>
              <div className={styles.bold}>
                {STRINGS.startpage.overview.uspTitle1}
              </div>
              <div className={styles.light}>
                {STRINGS.startpage.overview.uspText1}
              </div>
            </div>
          </div>
          <div className={styles.uspStatement}>
            <div>
              <Image
                className={styles.uspBulletpoint}
                alt={"Bullet"}
                src={Bulletpoint}
              />
            </div>
            <div className={styles.uspStatementText}>
              <div className={styles.bold}>
                {STRINGS.startpage.overview.uspTitle2}
              </div>
              <div className={styles.light}>
                {STRINGS.startpage.overview.uspText2}
              </div>
            </div>
          </div>
          <div className={styles.uspStatement}>
            <div>
              <Image
                className={styles.uspBulletpoint}
                alt={"Bullet"}
                src={Bulletpoint}
              />
            </div>
            <div className={styles.uspStatementText}>
              <div className={styles.bold}>
                {STRINGS.startpage.overview.uspTitle3}
              </div>
              <div className={styles.light}>
                {STRINGS.startpage.overview.uspText3}
              </div>
            </div>
          </div>
          <div className={styles.callToActionBox}>
            <div>
              <UnstyledLink to={RouteUrls.demandCalculator}>
                <Button
                  label={STRINGS.startpage.overview.actionButton1}
                  className={styles.callToActionButton1}
                />
              </UnstyledLink>
            </div>
            <div>
              <UnstyledLink to={RouteUrls.logIn}>
                <Button
                  label={STRINGS.startpage.overview.actionButton2}
                  className={styles.callToActionButton2}
                />
              </UnstyledLink>
            </div>
          </div>
        </div>

        <div className={styles.overviewImageBox}>
          <Image
            className={styles.overviewImage}
            alt={"Dein digitaler Finanzmanager"}
            src={Teaser1}
          />
        </div>
      </div>
    </div>
  </PageSection>
);
