import { appConfig } from "config/appConfig";
import api from "../index";
import axios from "axios";
import { isOk } from "utils/axiosHelpers";
import { Score } from "types/backend/Score";

const fetchScore = async (kid?: number): Promise<Score | undefined> => {
  if (!kid) return undefined;
  const url = `${appConfig.apiUrl}/api/kunden/{kid}/score`;
  const header = await api.getHeaderWithAuthorization();
  const result = await axios.get<Score>(url, {
    headers: header,
    validateStatus: isOk,
  });
  return result.data;
};
export default fetchScore;
