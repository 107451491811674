import React, { FunctionComponent, ReactNode } from "react";
import styles from "./InsuranceHeader.module.scss";
import { STRINGS } from "../../../language/de_DE/strings";
import PageSection from "../../page-section/PageSection";

type InsuranceHeaderProps = {
  image: string;
  insuranceName: string;
  children: ReactNode;
};

export const InsuranceHeader: FunctionComponent<InsuranceHeaderProps> = ({
  image,
  insuranceName,
  children,
}) => (
  <PageSection background={"blue"} contentClassName={styles.paddinglessHeader}>
    <div className={styles.imageHeadline}>
      <div className={styles.insuranceImageWrapper}>
        <img
          src={image}
          alt={insuranceName}
          className={styles.insuranceImage}
        />
      </div>
      <h1 className={styles.insuranceTitle}>
        {STRINGS.insurancePage.yourPrefix + insuranceName}
      </h1>
    </div>
    <div className={styles.prose}>{children}</div>
  </PageSection>
);
