import styles from "components/form-inputs/FileDrop/FileDrop.module.scss";

import React, { ReactElement } from "react";
import { useDropzone } from "react-dropzone";
import { GrDocumentUpdate } from "react-icons/gr";
import { STRINGS } from "language/de_DE/strings";
import { FieldInputProps, FormikProps } from "formik";

interface FileDropProps {
  field: FieldInputProps<string>;
  form: FormikProps<unknown>;
}

export const FileDrop = ({ field, form }: FileDropProps): ReactElement => {
  const onFilesDropped = (files: File[]) => {
    form.setFieldValue(field.name, files);
  };

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ onDrop: onFilesDropped, multiple: true });

  return (
    <div className={styles.dropField}>
      <div className={styles.dropIt}>
        <div className={styles.zone} {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <div className={styles.onDrag}>
              <GrDocumentUpdate className={styles.icon} />
              <h4>{STRINGS.addContract.addContractUpload}</h4>
            </div>
          ) : (
            <div className={styles.noDragOuter}>
              {acceptedFiles.length > 0 ? (
                <div className={styles.noDrag}>
                  <GrDocumentUpdate className={styles.icon} />
                  <h4>{STRINGS.addContract.addContractDragDropFile}</h4>
                  <span>
                    {acceptedFiles.length > 1
                      ? STRINGS.addContract.addContractUploadedDocPlural
                      : STRINGS.addContract.addContractUploadedDoc}
                  </span>
                  <ul>
                    {acceptedFiles.map((file) => (
                      <li key={file.name}>{file.name}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className={styles.noDrag}>
                  <GrDocumentUpdate className={styles.icon} />
                  <h4>{STRINGS.addContract.addContractDragDropFile}</h4>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
