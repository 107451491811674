import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { Nachricht } from "../types/backend/Nachricht";
import fetchNachrichten from "./requests/fetchNachrichten";
import api from "./index";
import { SendNachrichtRequest } from "types/userportal/SendNachrichtRequest";

const getNachrichtenKey = (kid: number) => {
  return `/api/kunden/${kid}/nachrichten`;
};

export const useNachrichten = (kid: number): UseQueryResult<Nachricht[]> => {
  return useQuery(getNachrichtenKey(kid), () => fetchNachrichten(kid));
};

export const useReadNachricht = (): UseMutationResult<
  unknown,
  unknown,
  { kid: number; messageId: number }
> => {
  const queryClient = useQueryClient();
  return useMutation(api.readNachricht, {
    onSuccess: (_, { kid }) =>
      queryClient.invalidateQueries(getNachrichtenKey(kid)),
  });
};

export const useSendNachricht = (): UseMutationResult<
  void,
  unknown,
  SendNachrichtRequest
> => {
  const queryClient = useQueryClient();
  return useMutation(api.sendNachricht, {
    onSuccess: (_, { kid }) =>
      queryClient.invalidateQueries(getNachrichtenKey(kid)),
  });
};
