import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/sterbegeld_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/sterbegeld_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/sterbegeld_2.jpg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Sterbegeldversicherung";

export const sterbegeldProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Eine Sterbegeldversicherung übernimmt im Falle deines Versterbens deine Bestattungskosten und schützt deine Hinterbliebenen vor einer hohen finanziellen Belastung.",

  headerButtons: [
    { label: "Vergleichen", link: RouteUrls.sterbegeldversicherungVergleich },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Eine Sterbegeldversicherung deckt die Kosten für die eigene Bestattung ab, diese kann schon recht schnell sehr teuer werden. In der Regel liegt die Versicherungssumme zwischen 4.000 € und 10.000 €.",
      ],
      image: descriptionImage1,
    },

    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Da sich die Beiträge einer Sterbegeldversicherung anhand der Wahrscheinlichkeit deines Ablebens errechnen, lohnt sich der Abschluss einer Sterbegeldversicherung kurz vor dem Renteneinstieg meist nicht mehr.",
        "Genau aus diesem Grund gibt es bei zahlreichen Versicherungsgesellschaften die Möglichkeit, ab Vollendung des 40. Lebensjahres eine Sterbegeldversicherung abzuschließen.",
        "Insbesondere, wenn Du schon im Rentenalter bist, kann es mehr Sinn ergeben, das für die Beerdigung anfallende Geld Deinen Hinterbliebenen direkt zur Verfügung zu stellen.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Kosten für einen Bestatter",
        "Kosten für die Bestattung, Grabstein, Blumen",
        "Kosten der anschließende Trauerfeier",
        "Kommunale Grabnutzungsgebühren und Grabpflege",
        "Mietnachzahlungen, Kosten für eine Wohnungsauflösung",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Kosten für den Ruhestand",
        "Versicherungssumme kann ausgezahlen werden",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.sterbegeldversicherung,
};
