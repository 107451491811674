import { InsuranceDeductibleOption } from "types/insuranceComparisonData/Options";

export const insuranceSelbstbeteiligungMock: Array<InsuranceDeductibleOption> =
  [
    {
      id: 1,
      content: "Unwichtig",
      value: "UNWICHTIG",
    },
    {
      id: 2,
      content: "Keine",
      value: "KEINE",
    },
    {
      id: 3,
      content: "Bis 150 €",
      value: "BIS_150",
    },
    {
      id: 4,
      content: "Bis 250 €",
      value: "BIS_250",
    },
    {
      id: 5,
      content: "Bis 500 €",
      value: "BIS_500",
    },
    {
      id: 6,
      content: "Bis 1000 €",
      value: "BIS_1000",
    },
  ];
