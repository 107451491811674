import React, { ReactElement } from "react";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { AddContractCheckbox } from "./base/AddContractCheckbox";
import { shouldDisplayField } from "./util/displayInputUtil";

type KrankenzusatzversicherungCheckboxProps = {
  name: string;
  labelText: string;
};

export const KrankenzusatzversicherungCheckbox = ({
  vertragTyp,
  labelText,
  name,
}: AddContractInputBaseType &
  KrankenzusatzversicherungCheckboxProps): ReactElement | null => {
  if (!shouldDisplayField(vertragTyp, ["KRANKENZUSATZVERSICHERUNG"])) {
    return null;
  }
  return <AddContractCheckbox name={name} labelText={labelText} />;
};
