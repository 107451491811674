import styles from "components/molecules/PopUpMessage.module.scss";

import React, { ReactElement } from "react";
import { Modal } from "react-responsive-modal";
import { Box } from "components/atoms/Box";
import { Button } from "components/form-inputs/Button/Button";
import { Text } from "components/atoms/Text";
import { STRINGS } from "language/de_DE/strings";

interface PopUpMessageProps {
  open: boolean;
  title: string;
  message: string;
  onClose: () => void;
  buttonLabel?: string;
}

export const PopUpMessage = ({
  open,
  onClose,
  title,
  message,
  buttonLabel,
}: PopUpMessageProps): ReactElement => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose} center>
      <Box className={styles.contentContainer}>
        <Text size={"large"} bold>
          {title}
        </Text>
        <Text size={"medium"}>{message}</Text>
        <Box flex className={styles.buttonContainer}>
          <Button
            label={buttonLabel || STRINGS.popUp.okButton}
            className={styles.button}
            onClick={handleClose}
          />
        </Box>
      </Box>
    </Modal>
  );
};
