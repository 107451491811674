import * as yup from "yup";

export const hausratTarifeComparisonStep3Schema = yup.object({
  ibanInput: yup
    .string()
    .nullable()
    .matches(/^DE\d{2}\s?([0-9a-zA-Z]{4}\s?){4}[0-9a-zA-Z]{2}$/)
    .required(),
  mandate: yup.boolean().isTrue().required(),
});
