import React, { ReactElement } from "react";
import DesktopNavigation from "components/navigation-bar/DesktopNavigation";
import MobileNavigation from "components/navigation-bar/MobileNavigation";
import appBreakpoints from "config/pageBreakpoints";
import useMatchMedia from "hooks/useMatchMedia";

const NavigationBar = (): ReactElement => {
  const showMobileNavigation = useMatchMedia(
    `(max-width: ${appBreakpoints.md})`
  );
  if (showMobileNavigation) {
    return <MobileNavigation />;
  }

  return <DesktopNavigation />;
};

export default NavigationBar;
