import styles from "components/user-portal/user-profile/Profile.module.scss";

import React, { ReactElement, useState } from "react";
import EditAssetsDialog from "components/user-portal/user-profile/dialogs/assets/EditAssetsDialog";
import ProfileCard from "components/user-portal/user-profile/ProfileCard";
import UserProfileItem from "components/user-portal/user-profile/UserProfileItem";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { formatBoolean, formatCurrency, formatDate } from "utils/formatUtils";

interface AssetsCardProps {
  kunde: KundeResponse;
}

export const AssetsCard = ({ kunde }: AssetsCardProps): ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <ProfileCard
      title={STRINGS.profile.assets.header}
      className={styles.profileCard}
      onEdit={() => setIsDialogOpen(true)}
    >
      <UserProfileItem
        label={STRINGS.profile.assets.marketValueProperty}
        value={formatCurrency(kunde.verkehrswertImmobilie, undefined, 0)}
      />
      <UserProfileItem
        label={STRINGS.profile.assets.liabilitiesProperty}
        value={formatCurrency(kunde.verbindlichkeitenImmobilie, undefined, 0)}
      />
      <UserProfileItem
        label={STRINGS.profile.assets.capitalAssets}
        value={formatCurrency(kunde.anlageVermoegen, undefined, 0)}
      />
      <UserProfileItem
        label={STRINGS.profile.assets.otherAssets}
        value={formatCurrency(kunde.sonstigeVermoegenswerte, undefined, 0)}
      />
      <UserProfileItem
        label={STRINGS.profile.assets.loans}
        value={formatCurrency(kunde.sonstigeDarlehen, undefined, 0)}
      />

      <UserProfileItem
        label={STRINGS.profile.assets.consumerCredits}
        value={formatCurrency(kunde.konsumentenKredite, undefined, 0)}
      />
      <UserProfileItem
        label={STRINGS.profile.assets.dispositionCredit}
        value={formatCurrency(kunde.genommenerDispositionskredit, undefined, 0)}
      />
      <UserProfileItem
        label={STRINGS.profile.assets.investmentGoal}
        value={formatBoolean(kunde.investitionsZiel)}
      />
      {kunde.investitionsZiel && (
        <>
          <UserProfileItem
            label={STRINGS.profile.assets.targetSavingsAmount}
            value={formatCurrency(
              kunde.investitionsZielWunschbetrag,
              undefined,
              0
            )}
          />
          <UserProfileItem
            label={STRINGS.profile.assets.targetSavingsDate}
            value={formatDate(kunde.investitionsZielZeitpunkt)}
          />
        </>
      )}

      <UserProfileItem
        label={STRINGS.profile.assets.ownPropertyIntended}
        value={formatBoolean(kunde.eigeneImmobilieGeplant)}
      />
      {kunde.eigeneImmobilieGeplant && (
        <>
          <UserProfileItem
            label={STRINGS.profile.assets.costOfProperty}
            value={formatCurrency(kunde.eigeneImmobilieWert, undefined, 0)}
          />
          <UserProfileItem
            label={STRINGS.profile.assets.intendedDateOfAcquisition}
            value={formatDate(kunde.eigeneImmobilieErwerbZeitpunkt)}
          />
        </>
      )}
      <UserProfileItem
        label={STRINGS.profile.assets.replacementInvestment}
        value={formatBoolean(kunde.ersatzInvestition)}
      />
      {kunde.ersatzInvestition && (
        <>
          <UserProfileItem
            label={STRINGS.profile.assets.replacementInvestmentValue}
            value={formatCurrency(kunde.ersatzInvestitionBetrag, undefined, 0)}
          />
          <UserProfileItem
            label={STRINGS.profile.assets.replacementInvestmentDate}
            value={formatDate(kunde.ersatzInvestitionZeitpunkt)}
          />
        </>
      )}

      <EditAssetsDialog
        kunde={kunde}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </ProfileCard>
  );
};
