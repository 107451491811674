import { DemandCalculatorInputFormType } from "../input-form/DemandCalculatorInputFormType";
import { BedarfsCheckRequest } from "../../../types/backend/BedarfsCheckRequest";

export const convertToRequest = (
  formValues: DemandCalculatorInputFormType
): BedarfsCheckRequest => {
  return {
    geburtstag: formValues.geburtstag,
    familienstand: formValues.familienstand || undefined,
    anzahlKinder: Number(formValues.anzahlKinder) || 0,
    wohnsituation: formValues.wohnsituation || undefined,
    wohnflaeche: Number(formValues.wohnflaeche) || 0,
    fremdgenutzteImmobilien: formValues.fremdgenutzteImmobilien,
    immobilieBaujahr: Number(formValues.immobilieBaujahr) || 0,
    neubauSummeImmobilie: Number(formValues.neubauSummeImmobilie) || 0,
    berufsstatus: formValues.berufsstatus || undefined,
    besondereTaetigkeit: formValues.besondereTaetigkeit || undefined,
    nettogehalt: Number(formValues.nettogehalt) || 0,
    einnahmenKapital: Number(formValues.einnahmenKapital) || 0,
    sonstigeEinnahmen: Number(formValues.sonstigeEinnahmen) || 0,
    gesamtAusgaben: Number(formValues.gesamtAusgaben) || 0,
    krankenversicherung: formValues.krankenversicherung || undefined,
    privateKrankenversicherungBeitrag:
      Number(formValues.privateKrankenversicherungBeitrag) || 0,
    rentenVersicherungBeitrag:
      Number(formValues.rentenVersicherungBeitrag) || 0,
    auslandsReisen: formValues.auslandsReisen,
    hundOderPferd: formValues.hundOderPferd,
    kraftfahrzeug: formValues.kraftfahrzeug,
    luftfahrzeug: formValues.luftfahrzeug,
    wasserfahrzeug: formValues.wasserfahrzeug,
    jagen: formValues.jagen,
    oeltank: formValues.oeltank,
    photovoltaikAnlage: formValues.photovoltaikAnlage,
    bauenOderSanieren: formValues.bauenOderSanieren,
    verkehrswertImmobilie: Number(formValues.verkehrswertImmobilie) || 0,
    verbindlichkeitenImmobilie:
      Number(formValues.verbindlichkeitenImmobilie) || 0,
    anlageVermoegen: Number(formValues.anlageVermoegen) || 0,
    sonstigeVermoegenswerte: Number(formValues.sonstigeVermoegenswerte) || 0,
    sonstigeDarlehen: Number(formValues.sonstigeDarlehen) || 0,
    konsumentenKredite: Number(formValues.konsumentenKredite) || 0,
    genommenerDispositionskredit:
      Number(formValues.genommenerDispositionskredit) || 0,
    investitionsZiel: formValues.investitionsZiel,
    investitionsZielWunschbetrag:
      Number(formValues.investitionsZielWunschbetrag) || 0,
    investitionsZielZeitpunkt: formValues.investitionsZielZeitpunkt,
    eigeneImmobilieGeplant: formValues.eigeneImmobilieGeplant,
    eigeneImmobilieWert: Number(formValues.eigeneImmobilieWert) || 0,
    eigeneImmobilieErwerbZeitpunkt: formValues.eigeneImmobilieErwerbZeitpunkt,
    ersatzInvestition: formValues.ersatzInvestition,
    ersatzInvestitionBetrag: Number(formValues.ersatzInvestitionBetrag) || 0,
    ersatzInvestitionZeitpunkt: formValues.ersatzInvestitionZeitpunkt,
  };
};
