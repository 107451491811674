import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import DescriptionImage0 from "gfx/images/insurance-images/berufshaftpflicht_0.jpg";
import DescriptionImage1 from "gfx/images/insurance-images/berufshaftpflicht_1.jpg";
import DescriptionImage2 from "gfx/images/insurance-images/berufshaftpflicht_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Berufs­haft­pflicht";

export const berufshaftpflichtProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: DescriptionImage0,
  introduction:
    "Kommen Dritte durch deine berufliche Tätigkeit oder den Fehler eines Mitarbeiters zu Schaden, können hohe Schadenersatzforderungen auf dich zukommen, die im schlimmsten Fall deine Existenz gefährden. Eine Berufshaftpflichtversicherung ist deshalb für einige Berufe sogar Pflicht und schützt im Schadenfall vor den finanziellen Folgen.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title:
        "... du als Arzt eine falsche Diagnose stellst und den Patienten dadurch Schäden entstehen.",
      text: icon1,
    },
    {
      title:
        "... du als Anwalt Fristen verpasst und deine Klienten Schadensersatz fordern.",
      text: icon1,
    },
    {
      title:
        "... du als Steuerberater deine Kunden falsch berätst und diese dadurch Geld verlieren.",
      text: icon1,
    },
    {
      title:
        "... du als Architekt einen Planungsfehler machst und daraus teure Folgeschäden entstehen.",
      text: icon1,
    },
    {
      title:
        "... du als Dolmetscher einen Übersetzungsfehler machst und Schäden entstehen.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine " + productName + "?",
      paragraphs: [
        "Eine Berufshaftpflichtversicherung schützt dich als Gewerbetreibenden vor Schadensersatzforderungen durch Dritte, denen durch dein Verschulden oder das Verschulden deines Unternehmens im Rahmen einer beruflichen Tätigkeit ein Schaden entstanden ist.",
      ],
      image: DescriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Einige Berufsgruppen sind gesetzlich oder durch Bestimmungen der Berufskammer dazu verpflichtet, eine Berufshaftpflichtversicherung abzuschließen.",
        "Zu diesen Gruppen zählen Personen mit sehr hohen berufsbedingten Risiken wie z. B. Ärzte, Architekten und Rechtsanwälte.",
        "Da alle Freiberufler und Selbstständige grundsätzlich für die von Ihnen verursachten Schäden haften, kann der Abschluss einer Berufshaftpflichtversicherung auch für andere Berufsgruppen sinnvoll sein, wie etwa für Grafikdesigner und Unternehmensberater.",
        "Angestellte Mitarbeiter benötigen hingegen keine Berufshaftpflichtversicherung, da man als Angestellter, Praktikant und Werkstudent über die Versicherung des Arbeitsgebers mitversichert ist.",
        "Angestellte in einer Organ- oder Leitungsfunktion sollten jedoch über eine Berufshaftpflichtversicherung nachdenken, da eine Absicherung aufgrund des persönlichen Haftungsrisikos durchaus von Nutzen sein kann.",
      ],

      image: DescriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Schäden, die anderen Personen durch dein berufliches Handeln entstanden sind",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [""],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  checklist: [
    {
      title: "Abwehr unberechtigter Forderungen:",
      text: "Die Versicherungen prüfen, inwiefern für die Schadenersatzforderungen ein berechtigter Anspruch durch die Betroffenen besteht und wehren unberechtigte oder zu hohe Forderungen kostenlos für dich ab. Du erhältst daher mit Abschluss einer Berufshaftpflichtversicherung auch einen passiven Rechtsschutz.",
    },
  ],

  link: RouteUrls.berufsHaftpflicht,
};
