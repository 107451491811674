import styles from "components/molecules/Price.module.scss";

import React, { FunctionComponent } from "react";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";

export type PriceProperties = {
  value: string;
  period: string | PaymentPeriod;
  variant?: "regular" | "highlight";
};

export enum PaymentPeriod {
  JAEHRLICH = "jährlich",
  HALBJAEHRLICH = "halbjährlich",
  VIERTELJAEHRLICH = "vierteljährlich",
  MONATLICH = "monatlich",
}

export const Price: FunctionComponent<PriceProperties> = ({
  value,
  period,
  variant = "regular",
}) => {
  return (
    <Box flex inline>
      <Box className={styles.periodText}>
        <Text inline size={"small"}>
          {period}
        </Text>
      </Box>
      {variant === "highlight" ? (
        <Box className={styles.highlightValue}>
          <Text inline size={"medium"} bold>
            {value} €
          </Text>
        </Box>
      ) : (
        <Box className={styles.regularValue}>
          <Text inline size={"small"}>
            {value} €
          </Text>
        </Box>
      )}
    </Box>
  );
};
