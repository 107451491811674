import {
  QueryClient,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import api from "api/index";
import {
  fetchGeldanlagen,
  fetchVersicherungen,
} from "api/requests/fetchVertraege";
import { AddDokumenteRequest } from "types/userportal/AddDokumenteRequest";
import { DeleteDokumentRequest } from "types/userportal/DeleteDokumentRequest";
import { DeleteResult } from "types/backend/DeleteResult";
import { VertragDokumentRequest } from "types/backend/VertragDokumentRequest";
import { VertragKapitalanlage } from "types/backend/VertragKapitalanlage";
import { VertragSparanlage } from "types/backend/VertragSparanlage";
import { VertragVersicherung } from "types/backend/VertragVersicherung";
import { VertragVersicherungKfz } from "types/backend/VertragVersicherungKfz";

const getVersicherungQueryKey = (kid: number | undefined) => {
  return `/api/kunden/${kid}/vertraege?produktTyp=VERSICHERUNG`;
};

const getGeldanlageQueryKey = (kid: number | undefined) => {
  return `/api/kunden/${kid}/vertraege?produktTyp=GELDANLAGE`;
};

const invalidateVertragQueries = (
  queryClient: QueryClient,
  kid: number | undefined
) => {
  return Promise.all([
    queryClient.invalidateQueries(getGeldanlageQueryKey(kid)),
    queryClient.invalidateQueries(getVersicherungQueryKey(kid)),
  ]);
};

export const useVersicherungen = (
  kid: number | undefined
): UseQueryResult<Array<VertragVersicherung | VertragVersicherungKfz>> => {
  return useQuery(getVersicherungQueryKey(kid), () => fetchVersicherungen(kid));
};

export const useGeldanlagen = (
  kid: number | undefined
): UseQueryResult<Array<VertragSparanlage | VertragKapitalanlage>> => {
  return useQuery(getGeldanlageQueryKey(kid), () => fetchGeldanlagen(kid));
};

export const useCreateVertrag = (): UseMutationResult<
  unknown,
  unknown,
  { kid: number | undefined } & VertragDokumentRequest
> => {
  const queryClient = useQueryClient();
  return useMutation(api.createVertrag, {
    onSuccess: (_, { kid }) => invalidateVertragQueries(queryClient, kid),
  });
};

export const useAddDokumente = (): UseMutationResult<
  unknown,
  unknown,
  AddDokumenteRequest
> => {
  const queryClient = useQueryClient();
  return useMutation(api.addDokumente, {
    onSuccess: (_, { kid }) => invalidateVertragQueries(queryClient, kid),
  });
};

export const useDeleteDokument = (
  onDeleteFinished: (result: DeleteResult) => void
): UseMutationResult<unknown, unknown, DeleteDokumentRequest> => {
  const queryClient = useQueryClient();
  return useMutation(api.deleteDokument, {
    onSuccess: (_, { kid, dokument }) => {
      onDeleteFinished({ success: true, dokumentName: dokument.name });
      return invalidateVertragQueries(queryClient, kid);
    },
    onError: (_, { dokument }) => {
      onDeleteFinished({ success: false, dokumentName: dokument.name });
    },
  });
};
