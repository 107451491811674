import styles from "components/molecules/AuthenticationCodeInput.module.scss";

import React, {
  ChangeEvent,
  createRef,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import clsx from "clsx";

// Detected using https://keycode.info/
const keyBackspace = "Backspace";
const keyDelete = "Delete";
const keyArrowLeft = "ArrowLeft";
const keyArrowRight = "ArrowRight";
const keyTab = "Tab";

export type AuthenticationCodeInputProps = {
  amountDigits?: number;
  focusFirstInput?: boolean;
  onAuthenticationCodeChange: (code: string) => void;
};

const AuthenticationCodeInput = ({
  amountDigits = 6,
  focusFirstInput = false,
  onAuthenticationCodeChange,
}: AuthenticationCodeInputProps): ReactElement => {
  const [authenticationCode, setAuthenticationCode] = useState("");
  const inputStateList = new Array(amountDigits).fill("").map(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = useState<number | string>("");

    return {
      value,
      setValue,
      ref: createRef<HTMLInputElement>(),
    };
  });
  const containerStateList = inputStateList.map(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isFocused, setIsFocused] = useState(false);

    return {
      isFocused,
      setIsFocused,
    };
  });

  const clearContainerFocus = () =>
    containerStateList.forEach((state) => state.setIsFocused(false));
  const focusContainer = (index: number) => {
    clearContainerFocus();
    containerStateList[index].setIsFocused(true);
  };

  const focusInput = (index: number) => {
    inputStateList[index].ref.current?.focus();
    inputStateList[index].ref.current?.select();
  };
  const hasNextInput = (index: number) => index + 1 < inputStateList.length;
  const focusPreviousInput = (index: number) =>
    index - 1 >= 0 && focusInput(index - 1);
  const focusNextInput = (index: number) =>
    hasNextInput(index) && focusInput(index + 1);
  const clearInputFocus = () =>
    inputStateList.forEach((state) => state.ref.current?.blur());

  const handleNonDigitKey = (key: string, inputIndex: number) => {
    // Deletion will be handled by the input itself
    if (key === keyBackspace || key === keyDelete) {
      return;
    }

    // Focus previous or next input
    if (key === keyArrowLeft) {
      focusPreviousInput(inputIndex);
    }
    if (key === keyArrowRight || key === keyTab) {
      focusNextInput(inputIndex);
    }
  };

  const createDigitInputKeyDownHandler = (index: number) => {
    return (event: KeyboardEvent<HTMLInputElement>) => {
      const inputValue = Number(event.key);

      // Only accept number input
      if (isNaN(inputValue)) {
        handleNonDigitKey(event.key, index);

        event.preventDefault();
        event.stopPropagation();
        return;
      }
    };
  };

  const createDigitInputChangeHandler = (index: number) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue = Number(event.target.value);
      if (isNaN(inputValue)) {
        return;
      }

      inputStateList[index].setValue(inputValue);

      // Number given, proceed to next input
      if (hasNextInput(index)) {
        focusNextInput(index);
        return;
      }

      clearInputFocus();
    };
  };

  // Once any value has been changed, update the auth code
  const alleInputValues = inputStateList.map((state) => state.value);
  useEffect(() => {
    const authenticationCode = inputStateList
      .map((state) => state.value)
      .join("");
    setAuthenticationCode(authenticationCode);
  }, [alleInputValues]);

  useEffect(() => {
    onAuthenticationCodeChange(authenticationCode);
  }, [authenticationCode]);

  // Focus first input on mount
  useEffect(() => {
    if (!focusFirstInput) {
      return;
    }

    // Gave the animation some time
    window.setTimeout(() => {
      inputStateList[0].ref.current?.focus();
    }, 500);
  }, [focusFirstInput]);

  return (
    <div className={styles.authenticationCodeInput}>
      {inputStateList.map((inputState, index) => (
        <div
          className={clsx(
            styles.digitContainer,
            containerStateList[index].isFocused && styles.focused
          )}
          key={index}
        >
          <input
            ref={inputState.ref}
            value={inputState.value}
            type="text"
            className={styles.digitInput}
            size={1}
            onKeyDown={createDigitInputKeyDownHandler(index)}
            onChange={createDigitInputChangeHandler(index)}
            onFocus={() => focusContainer(index)}
            onBlur={() => clearContainerFocus()}
          />
        </div>
      ))}
    </div>
  );
};

export default AuthenticationCodeInput;
