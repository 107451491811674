import React, { ReactElement } from "react";
import UserData from "components/user-portal/user-profile/Profile";
import UserPortal from "components/user-portal/UserPortal";

const UserPortalProfile = (): ReactElement => {
  return (
    <UserPortal>
      <UserData />
    </UserPortal>
  );
};

export default UserPortalProfile;
