import React, { FunctionComponent } from "react";
import styles from "./InsuranceCompareTeaser.module.scss";

import { STRINGS } from "../../../language/de_DE/strings";
import { RouteUrls } from "../../../config/routes";
import UnstyledLink from "../../unstyle-link/UnstyledLink";
import { Button } from "../../form-inputs/Button/Button";
import PageSection from "../../page-section/PageSection";

const sectionStrings = STRINGS.insurancePage.checkTeaser;

export const InsuranceCompareTeaser: FunctionComponent = () => (
  <PageSection contentClassName={styles.section}>
    <h2>{sectionStrings.title}</h2>
    <div className={styles.subTitle}>{sectionStrings.subTitle}</div>
    <UnstyledLink to={RouteUrls.insurances} className={styles.buttonLink}>
      <Button
        label={sectionStrings.checkButtonLabel}
        className={styles.checkButton}
      />
    </UnstyledLink>
  </PageSection>
);
