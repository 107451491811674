import styles from "components/navigation-bar/NavigationItemLink.module.scss";

import React, { PropsWithChildren, ReactElement } from "react";
import clsx from "clsx";
import { Link, LinkProps } from "react-router-dom";

type Props = {
  isActive?: boolean;
  isMobile?: boolean;
} & LinkProps;

const NavigationItemLink = ({
  children,
  isActive = false,
  isMobile = false,
  ...rest
}: PropsWithChildren<Props>): ReactElement => (
  <Link
    {...rest}
    className={clsx(
      styles.link,
      rest.className,
      isActive && styles.active,
      isMobile && styles.mobile
    )}
  >
    {children}
  </Link>
);

export default NavigationItemLink;
