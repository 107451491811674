import React, { ReactElement } from "react";
import UserInsuranceContracts from "components/user-portal/user-insurance/UserInsuranceContracts";
import UserPortal from "components/user-portal/UserPortal";

const UserInsurance = (): ReactElement => {
  return (
    <UserPortal>
      <UserInsuranceContracts />
    </UserPortal>
  );
};

export default UserInsurance;
