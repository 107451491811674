import React from "react";
import { Route, Routes } from "react-router-dom";
import { RouteUrls } from "config/routes";
import DemandCalculatorPage from "pages/demand-calculator/DemandCalculatorPage";
import Insurances from "pages/insurances/Insurances";
import LoginPage from "pages/login/LoginPage";
import RegisterPage from "pages/register/RegisterPage";
import ForgotPasswordPage from "pages/forgot-password/ForgotPasswordPage";
import AdditionalRegistrationDataPage from "pages/register/AdditionalRegistrationDataPage";
import UserPortalOverview from "pages/user-portal/UserOverviewPage";
import UserPortalProfile from "pages/user-portal/UserProfilePage";
import UserPortalInsurance from "pages/user-portal/UserInsurancePage";
import UserPortalInvestments from "pages/user-portal/UserInvestmentsPage";
import UserMessageBoxPage from "pages/user-portal/UserMessageBoxPage";
import UserManagementPage from "pages/user-portal/UserManagementPage";
import UserContactPage from "pages/user-portal/UserContactPage";
import AddContract from "pages/user-portal/UserAddContractPage";
import Logout from "pages/logout/Logout";
import { Datenschutz } from "pages/legal-documents/Datenschutz";
import { Impressum } from "pages/legal-documents/Impressum";
import { AboutUs } from "pages/about-us/AboutUs";
import { CookiePolicy } from "pages/legal-documents/CookiePolicy";
import { Nachhaltigkeit } from "pages/legal-documents/Nachhaltigkeit";
import { ErstInformation } from "pages/legal-documents/ErstInformation";
import { AGB } from "pages/legal-documents/AGB";
import { Startpage } from "pages/startpage/Startpage";
import InsuranceInfoPage from "pages/insurances/InsuranceInfoPage";
import { InsuranceProducts } from "content/productTree";
import TravelCampaignPage from "pages/campaigns/travel-campaign/TravelCampaignPage";
import { HausratversicherungVergleichIndex } from "pages/hausratversicherung/HausratversicherungVergleichIndex";
import { HausratversicherungVergleichErgebnis } from "pages/hausratversicherung/HausratversicherungVergleichErgebnis";
import { HausratversicherungVergleichAbschlussStep2 } from "pages/hausratversicherung/HausratversicherungVergleichAbschlussStep2";
import { HausratversicherungVergleichAbschlussStep3 } from "pages/hausratversicherung/HausratversicherungVergleichAbschlussStep3";
import Error404 from "pages/Error/Error404";
import { comovoRoutes } from "routes/ComovoRoutes";

export const MyNormRoutes = () => {
  return (
    <Routes>
      <Route
        path={RouteUrls.demandCalculator}
        element={<DemandCalculatorPage />}
      />
      <Route path={RouteUrls.insurances} element={<Insurances />} />
      <Route path={RouteUrls.logIn} element={<LoginPage />} />
      <Route path={RouteUrls.register} element={<RegisterPage />} />
      <Route path={RouteUrls.forgotPassword} element={<ForgotPasswordPage />} />
      <Route
        path={RouteUrls.additionalRegistrationData}
        element={<AdditionalRegistrationDataPage />}
      />
      <Route
        path={RouteUrls.userPortalStart}
        element={<UserPortalOverview />}
      />
      <Route
        path={RouteUrls.userPortalProfile}
        element={<UserPortalProfile />}
      />
      <Route
        path={RouteUrls.userPortalInsurance}
        element={<UserPortalInsurance />}
      />
      <Route
        path={RouteUrls.userPortalInvestments}
        element={<UserPortalInvestments />}
      />
      <Route
        path={RouteUrls.userPortalMessageBox}
        element={<UserMessageBoxPage />}
      />
      <Route
        path={RouteUrls.userPortalManagement}
        element={<UserManagementPage />}
      />
      <Route path={RouteUrls.userPortalContact} element={<UserContactPage />} />
      <Route path={RouteUrls.userAddContract} element={<AddContract />} />
      <Route path={RouteUrls.logOut} element={<Logout />} />
      <Route path={RouteUrls.datenschutz} element={<Datenschutz />} />
      <Route path={RouteUrls.impressum} element={<Impressum />} />
      <Route path={RouteUrls.aboutUs} element={<AboutUs />} />
      <Route path={RouteUrls.cookiePolicy} element={<CookiePolicy />} />
      <Route path={RouteUrls.nachhaltigkeit} element={<Nachhaltigkeit />} />
      <Route path={RouteUrls.erstInformation} element={<ErstInformation />} />

      <Route path={RouteUrls.userAddContract} element={<AddContract />} />
      <Route path={RouteUrls.logOut} element={<Logout />} />
      <Route path={RouteUrls.datenschutz} element={<Datenschutz />} />
      <Route path={RouteUrls.impressum} element={<Impressum />} />

      <Route path={RouteUrls.AGB} element={<AGB />} />
      <Route path={RouteUrls.homepage} element={<Startpage />} />
      <Route
        path={RouteUrls.hausratversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.HAUSRATVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.wohngebaeudeversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.WOHNGEBAEUDEVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.kunstversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.KUNSTVERSICHERUNG_PRIVAT}
          />
        }
      />

      <Route
        path={RouteUrls.privatHaftpflicht}
        element={
          <InsuranceInfoPage insurance={InsuranceProducts.PRIVAT_HAFTPFLICHT} />
        }
      />

      <Route
        path={RouteUrls.berufsHaftpflicht}
        element={
          <InsuranceInfoPage insurance={InsuranceProducts.BERUFSHAFTPFLICHT} />
        }
      />

      <Route
        path={RouteUrls.bauherrenHaftpflicht}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.BAUHERREN_HAFTPFLICHT}
          />
        }
      />

      <Route
        path={RouteUrls.hausGrundbesitzerHaftpflicht}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.HAUS_GRUNDBESITZER_HAFTPFLICHT}
          />
        }
      />

      <Route
        path={RouteUrls.tierhalterHaftpflicht}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.TIERHALTER_HAFTPFLICHT}
          />
        }
      />

      <Route
        path={RouteUrls.jagdHaftpflicht}
        element={
          <InsuranceInfoPage insurance={InsuranceProducts.JAGD_HAFTPFLICHT} />
        }
      />

      <Route
        path={RouteUrls.gewaesserschadenHaftpflicht}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.GEWAESSERSCHADEN_HAFTPFLICHT}
          />
        }
      />

      <Route
        path={RouteUrls.betreiberHaftpflicht}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.BETREIBER_HAFTPFLICHT}
          />
        }
      />

      <Route
        path={RouteUrls.luftfahrzeugHaftpflicht}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.LUFTFAHRZEUG_HAFTPFLICHT}
          />
        }
      />

      <Route
        path={RouteUrls.wasserfahrzeugHaftpflicht}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.WASSERFAHRZEUG_HAFTPLICHT}
          />
        }
      />

      <Route
        path={RouteUrls.kfzversicherung}
        element={
          <InsuranceInfoPage insurance={InsuranceProducts.KFZ_VERSICHERUNG} />
        }
      />

      <Route
        path={RouteUrls.motorradversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.MOTORRAD_MOPED_VERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.fahrradversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.FAHRRADVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.mopedversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.MOPED_ROLLER_VERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.auslandsreisekrankenversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.AUSLANDSREISE_KRANKENVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.reiseruecktrittsversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.REISERUECKTRITTVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.reisegepaeckversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.REISEGEPAECKVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.langzeitreiseversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.LANGZEITREISE_VERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.langzeitreisekrankenversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.LANGZEITREISE_KRANKENVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.langzeitreisehaftpflichtversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.LANGZEITREISE_HAFTPFLICHTVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.langzeitreiseunfallversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.LANGZEITREISE_UNFALLVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.erntehelferkrankenversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.ERNTEHELFERKRANKEN_VERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.pferdehaftpflicht}
        element={
          <InsuranceInfoPage insurance={InsuranceProducts.PFERDE_HAFTPFLICHT} />
        }
      />

      <Route
        path={RouteUrls.hundehaftpflicht}
        element={
          <InsuranceInfoPage insurance={InsuranceProducts.HUNDE_HAFTPFLICHT} />
        }
      />

      <Route
        path={RouteUrls.tierkrankenversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.TIERKRANKENVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.drohnenversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.DROHNEN_VERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.rechtsschutzversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.RECHTSSCHUTZVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.gkv}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.GESETZLICHE_KRANKENVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.pkv}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.PRIVATE_KRANKENVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.pflegepflichtversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.PFLEGEPFLICHTVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.pflegezusatzversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.PFLEGEZUSATZVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.krankenhaustagegeld}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.KRANKENHAUS_TAGEGELD}
          />
        }
      />

      <Route
        path={RouteUrls.krankenhauszusatz}
        element={
          <InsuranceInfoPage insurance={InsuranceProducts.KRANKENHAUS_ZUSATZ} />
        }
      />

      <Route
        path={RouteUrls.krankentagegeld}
        element={
          <InsuranceInfoPage insurance={InsuranceProducts.KRANKEN_TAGEGELD} />
        }
      />

      <Route
        path={RouteUrls.berufsunfaehigkeitsversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.BERUFSUNFAEHIGKEITS_VERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.dienstunfaehigkeitsversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.DIENSTUNFAEHIGKEITS_VERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.erwerbsunfaehigkeitsversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.ERWERBSUNFAEHIGKEITS_VERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.risikolebensversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.RISIKOLEBENSVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.unfallversicherung}
        element={
          <InsuranceInfoPage insurance={InsuranceProducts.UNFALLVERSICHERUNG} />
        }
      />

      <Route
        path={RouteUrls.sterbegeldversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.STERBEGELDVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.altersvorsorge}
        element={
          <InsuranceInfoPage insurance={InsuranceProducts.ALTERSVORSORGE} />
        }
      />

      <Route
        path={RouteUrls.privaterentenversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.PRIVATE_RENTENVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.kapitallebensversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.KAPITAL_LEBENSVERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.riesterrente}
        element={
          <InsuranceInfoPage insurance={InsuranceProducts.RIESTER_RENTE} />
        }
      />

      <Route
        path={RouteUrls.rueruprente}
        element={
          <InsuranceInfoPage insurance={InsuranceProducts.RUERUP_RENTE} />
        }
      />

      <Route
        path={RouteUrls.startkapitalausbildung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.STARTKAPITAL_AUSBILDUNG}
          />
        }
      />

      <Route
        path={RouteUrls.aufbauliquiditaetsreserve}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.AUFBAU_LIQUIDITAETSRESERVE}
          />
        }
      />

      <Route
        path={RouteUrls.drohnenversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.DROHNEN_VERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.hochzeitsversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.HOCHZEITS_VERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.mietkautionsversicherung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.MIETKAUTIONS_VERSICHERUNG}
          />
        }
      />

      <Route
        path={RouteUrls.ersatzinvestitionsachwerte}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.AUFBAU_LIQUIDITAETSRESERVE}
          />
        }
      />

      <Route
        path={RouteUrls.ueberschuldungsrisiko}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.UEBERSCHULDUNGS_RISIKO}
          />
        }
      />
      <Route
        path={RouteUrls.zinsaenderungsrisiko}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.ZINSAENDERUNGS_RISIKO}
          />
        }
      />
      <Route
        path={RouteUrls.instandhaltungimmo}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.INSTANDHALTUNG_IMMO}
          />
        }
      />
      <Route
        path={RouteUrls.eigenkapitalimmofinanzierung}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.EIGENKAPITAL_IMMO_FINANZIERUNG}
          />
        }
      />
      <Route
        path={RouteUrls.investitionswunsch}
        element={
          <InsuranceInfoPage
            insurance={InsuranceProducts.INVESTITIONS_WUNSCH}
          />
        }
      />

      <Route
        path={RouteUrls.reiseVersicherungCampaign}
        element={<TravelCampaignPage />}
      />

      {comovoRoutes}

      <Route
        path={RouteUrls.hausratversicherungVergleichIndex}
        element={<HausratversicherungVergleichIndex />}
      />
      <Route
        path={RouteUrls.hausratversicherungVergleichErgebnis}
        element={<HausratversicherungVergleichErgebnis />}
      />
      <Route
        path={RouteUrls.hausratversicherungVergleichAbschlussStep2}
        element={<HausratversicherungVergleichAbschlussStep2 />}
      />
      <Route
        path={RouteUrls.hausratversicherungVergleichAbschlussStep3}
        element={<HausratversicherungVergleichAbschlussStep3 />}
      />

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};
