import styles from "components/user-portal/portal-content/PortalContent.module.scss";

import React, { ReactElement, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const PortalContent = ({ children }: Props): ReactElement => {
  return <div className={styles.contentContainer}>{children}</div>;
};

export default PortalContent;
