import styles from "components/molecules/InsuranceFeatureList.module.scss";

import React, { FunctionComponent } from "react";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";
import { featureMap } from "mappings/hausratVersicherung/FeatureMap";
import { HausratVersicherungDetailDeckungssummen } from "types/insuranceComparisonData/Hausratversicherug/HausratVersicherungDetails";

export type InsuranceFeatureListProperties = {
  features: HausratVersicherungDetailDeckungssummen;
};

export const InsuranceFeatureList: FunctionComponent<
  InsuranceFeatureListProperties
> = ({ features }) => {
  const featureArray = Object.entries(features);

  return (
    <Box flex column>
      {featureArray.map((feature, index) => (
        <Box key={index} inline>
          <FontAwesomeIcon
            icon={faCircleCheck}
            className={styles.featureIcon}
          />
          <Box inline className={styles.featureText}>
            <Text inline size={"xSmall"}>
              {featureMap.get(feature[0])}:{" "}
            </Text>
            <Text inline size={"xSmall"} bold>
              {feature[1]} €
            </Text>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
