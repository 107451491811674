/* eslint-disable react/no-unescaped-entities */
import React, { ReactElement } from "react";
import { LegalDoc } from "./LegalDoc";

export const CookiePolicy = (): ReactElement => {
  return (
    <LegalDoc title={"Cookie Richtlinie der myNORM GmbH"}>
      <div>Version 1.0, Juni 2022</div>

      <div>
        <h2>Was sind Cookies?</h2>
        <div>
          Für unseren Internetauftritt nutzen wir die Technik der Cookies.
          Cookies sind kleine Textdateien, die im Rahmen deines Besuchs unserer
          Webseiten von unserem Webserver an deinen Browser gesandt und von
          diesem auf deinen Rechner für einen späteren Abruf vorgehalten werden.
          Ob Cookies gesetzt und abgerufen werden können, kannst du durch die
          Einstellungen in deinem Browser selbst bestimmen. Du kannst in deinem
          Browser etwa das Speichern von Cookies gänzlich deaktivieren, es auf
          bestimmte Webseiten beschränken oder deinen Browser so konfigurieren,
          dass er dich automatisch benachrichtigt, sobald ein Cookie gesetzt
          werden soll und dich um Rückmeldung dazu bittet. Cookies können keine
          Programme ausführen oder Viren auf deinen Computer übertragen. Als
          Rechtsgrundlage für die Verwendung von Cookies dienen hauptsächlich
          Art. 6 Abs. 1 S. 1 lit. a) DS-GVO (Einwilligung) sowie Art. 6 Abs. 1
          S. 1 lit. f) DS-GVO (berechtigtes Interesse), soweit nicht anderweitig
          angegeben.
        </div>
      </div>
      <div>
        <h2>Welche Cookies verwenden wir?</h2>
        <div>
          Wir setzen beinahe ausschließlich sogenannte Session-Cookies (auch als
          temporäre Cookies bezeichnet) ein, also solche, die ausschließlich für
          die Dauer deiner Nutzung einer unserer Webseiten zwischengespeichert
          werden. Für den vollen Funktionsumfang unseres Internetauftritts ist
          es aus technischen Gründen erforderlich, die genannten Session Cookies
          zuzulassen. Denn Zweck dieser Cookies ist, deinen Rechner während
          eines Besuchs unseres Internetauftritts beim Wechsel von einer unserer
          Webseiten zu einer anderen unserer Webseiten weiterhin zu
          identifizieren und das Ende deines Besuchs feststellen zu können.
          Rechtsgrundlage für die Verwendung temporärer Cookies sind § 25 Abs. 2
          TTDSG (notwendige Techniken) sowie Art. 6 Abs. 1 S. 1 lit. f) DS-GVO.
          (berechtigtes Interesse). Persistente Cookies werden automatisiert
          nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie
          unterscheiden kann. Du kannst die Cookies in den
          Sicherheitseinstellungen deines Browsers jederzeit löschen.
        </div>
      </div>
      <div>
        <h2>Welche Cookies können wir ohne deine Einwilligung setzen?</h2>
        <div>
          Die Verwendung von Cookies, die die Funktionalität und Sicherheit
          unserer Webseite gewährleisten ist gem. Art. 6 1 f) DSGVO
          gerechtfertigt. Das heißt, für diese Cookies ist deine Einwilligung
          nicht erforderlich. Denn bei der Nutzung von Cookies zur
          Funktionalität und Sicherheit unseres Produktes, dürfen wir davon
          ausgehen, dass es dir ebenfalls daran gelegen ist, dass wir unsere
          Webseite vor Störungen und mutwilligen Eingriffen schützen, die
          potenziell die Vertraulichkeit deiner Daten beeinträchtigen könnten.
        </div>
      </div>
    </LegalDoc>
  );
};
