import React from "react";
import { RadioGroup, RadioGroupProperties } from "../RadioGroup/RadioGroup";

export type BooleanRadioGroupValue = "yes" | "no";

const yesNoRadioOptions = [
  { label: "Ja", value: "yes" },
  { label: "Nein", value: "no" },
];

export const BooleanRadioGroup = ({
  ...props
}: Omit<RadioGroupProperties, "options">) => {
  return (
    <div>
      <RadioGroup {...props} options={yesNoRadioOptions} />
    </div>
  );
};
