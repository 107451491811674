import React, { ReactElement } from "react";
import { Field, useFormikContext } from "formik";
import { AddContractTextInput } from "./base/AddContractTextInput";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { useVertragMapping } from "./useVertragMapping";
import { AddContractFormType } from "../AddContractFormType";
import { validateRequiredOnTypes } from "./util/validationUtil";
import { shouldDisplayField } from "./util/displayInputUtil";

export const Vertragsname = ({
  vertragTyp,
  activeStep,
}: AddContractInputBaseType): ReactElement | null => {
  const { kapitalanlagenTypes } = useVertragMapping();
  const { values } = useFormikContext<AddContractFormType>();

  const validate = () => {
    if (activeStep !== 2) return;
    return validateRequiredOnTypes(
      kapitalanlagenTypes,
      STRINGS.addContract.step2.contractNameError,
      vertragTyp,
      values.vertragsname
    );
  };

  if (!shouldDisplayField(vertragTyp, kapitalanlagenTypes)) return null;
  return (
    <Field
      component={AddContractTextInput}
      name="vertragsname"
      labelText={STRINGS.addContract.step2.contractNameLabel}
      validate={validate}
    />
  );
};
