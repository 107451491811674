import React, { FunctionComponent } from "react";
import PageSection from "../../page-section/PageSection";
import styles from "./CampaignUsp.module.scss";

type Usp = {
  image: string;
  title: string;
  prose: string;
};

type Props = {
  usps: Usp[];
};

export const CampaignUsp: FunctionComponent<Props> = ({ usps }) => (
  <PageSection background={"grey"} contentClassName={styles.usps}>
    {usps.map((usp) => (
      <div key={usp.title} className={styles.usp}>
        <img src={usp.image} alt={usp.title} className={styles.icon} />
        <div className={styles.title}>{usp.title}</div>
        <div>{usp.prose}</div>
      </div>
    ))}
  </PageSection>
);
