import { Field } from "formik";

import React, { ReactElement } from "react";
import { NumberField } from "../../../../../form-inputs/NumberField/NumberField";

interface AddContractNumberInputProps {
  name: string;
  labelText?: string;
  validate?: () => string | undefined | void | Promise<string>;
}

export const AddContractNumberInput = ({
  name,
  labelText,
  validate,
}: AddContractNumberInputProps): ReactElement => {
  return (
    <Field
      component={NumberField}
      name={name}
      labelText={labelText}
      validate={validate}
    />
  );
};
