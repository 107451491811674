import { appConfig } from "config/appConfig";
import api from "../index";
import axios from "axios";
import { isOk } from "utils/axiosHelpers";
import { BedarfsCheckResponse } from "../../types/backend/BedarfsCheckResponse";
import { BedarfsCheckRequest } from "../../types/backend/BedarfsCheckRequest";
import { KundeResponse } from "../../types/backend/KundeResponse";

const fetchDemandCalculator = async (
  request: BedarfsCheckRequest,
  kunde: KundeResponse | undefined
): Promise<BedarfsCheckResponse> => {
  const url = `${appConfig.apiUrl}/api/bedarfscheck/check`;
  const header = kunde
    ? await api.getHeaderWithAuthorization()
    : api.getHeaderWithoutAuthorization();
  const result = await axios.post<BedarfsCheckResponse>(url, request, {
    headers: header,
    validateStatus: isOk,
  });
  return result.data;
};
export default fetchDemandCalculator;
