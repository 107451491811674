import styles from "components/user-portal/user-profile/Profile.module.scss";

import React, { ReactElement, useState } from "react";
import EditIncomeDialog from "components/user-portal/user-profile/dialogs/income/EditIncomeDialog";
import ProfileCard from "components/user-portal/user-profile/ProfileCard";
import UserProfileItem from "components/user-portal/user-profile/UserProfileItem";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { formatCurrency, formatEnum, valueOrEmpty } from "utils/formatUtils";

interface IncomeCardProps {
  kunde: KundeResponse;
}

export const IncomeCard = ({ kunde }: IncomeCardProps): ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <ProfileCard
      title={STRINGS.profile.income.header}
      className={styles.profileCard}
      onEdit={() => setIsDialogOpen(true)}
    >
      <UserProfileItem
        label={STRINGS.profile.income.occupation}
        value={valueOrEmpty(kunde.beruf)}
      />

      <UserProfileItem
        label={STRINGS.profile.income.occupationState}
        value={formatEnum(
          kunde.berufsstatus,
          STRINGS.profile.income.occupationStateTypes
        )}
      />
      <UserProfileItem
        label={STRINGS.profile.income.specialOccupation}
        value={formatEnum(
          kunde.besondereTaetigkeit,
          STRINGS.profile.income.specialOccupationTypes
        )}
      />
      <UserProfileItem
        label={STRINGS.profile.income.nettoIncome}
        value={formatCurrency(kunde.nettoGehalt, undefined, 0)}
      />
      <UserProfileItem
        label={STRINGS.profile.income.capitalIncome}
        value={formatCurrency(kunde.einnahmenKapital, undefined, 0)}
      />
      <UserProfileItem
        label={STRINGS.profile.income.otherIncome}
        value={formatCurrency(kunde.sonstigeEinnahmen, undefined, 0)}
      />
      <UserProfileItem
        label={STRINGS.profile.income.totalExpenses}
        value={formatCurrency(kunde.gesamtAusgaben, undefined, 0)}
      />
      <UserProfileItem
        label={STRINGS.profile.income.healthInsurance}
        value={formatEnum(
          kunde.krankenversicherung,
          STRINGS.profile.income.healthInsuranceTypes
        )}
      />
      {kunde.krankenversicherung === "PRIVAT" && (
        <>
          <UserProfileItem
            label={STRINGS.profile.income.healthInsuranceFee}
            value={formatCurrency(
              kunde.privateKrankenversicherungBeitrag,
              undefined,
              0
            )}
          />
        </>
      )}
      <UserProfileItem
        label={STRINGS.profile.income.pensionInsuranceFee}
        value={formatCurrency(kunde.rentenVersicherungBeitrag, undefined, 0)}
      />
      <EditIncomeDialog
        kunde={kunde}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </ProfileCard>
  );
};
