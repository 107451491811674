import React from "react";
import AuthenticatedPage from "components/AuthenticatedPage";
import PortalContent from "components/user-portal/user-message-box/UserMessageBox";

const UserMessageBoxPage = () => (
  <AuthenticatedPage>
    <PortalContent />
  </AuthenticatedPage>
);

export default UserMessageBoxPage;
