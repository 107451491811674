import React, { ReactElement } from "react";
import { STRINGS } from "../../language/de_DE/strings";
import { LegalDoc } from "./LegalDoc";

export const Nachhaltigkeit = (): ReactElement => {
  return (
    <LegalDoc title={"Nachhhaltigkeit"}>
      <h2>
        Informationen zur Einbeziehung von Nachhaltigkeitsrisiken und der
        Berücksichtigung nachteiliger Nachhaltigkeitsauswirkungen Gesellschaft
      </h2>

      <div>
        <h2>Gesellschaft</h2>
        <div>
          <div>
            <strong>myNORM GmbH</strong>
          </div>
          <div>Rendsburger Straße 14</div>
          <div>30659 Hannover</div>
          <div>Deutschland</div>
        </div>
        <div>
          <div>Tel: {STRINGS.footer.contact.phone}</div>
          <div>E-Mail: {STRINGS.footer.contact.email}</div>
        </div>
      </div>

      <div>
        <h2>Hintergrund</h2>
        <div>
          Gem.der VERORDNUNG (EU) 2019/2088 (Transparenzverordnung oder TVO) ist
          myNORM als Versicherungsmakler beim Vertrieb von
          Versicherungsanlageprodukten dazu verpflichtet, bestimmte
          Informationen zum Umgang mit Nachhaltigkeitsrisiken sowie negativen
          Einflüssen auf Nachhaltigkeitsfaktoren zu veröffentlichen.
          <ul>
            <li>
              Nachhaltigkeitsrisiken bezeichnen dabei bestimmte Risiken, die
              eine Auswirkung auf die Leistung deiner Versicherung haben
              könnten. Im Fall einer Lebensversicherung etwa könnte eine
              Naturkatastrophe den variablen Leistungsanteil der Versicherung
              verringern. Außer im Bereich Umwelt können sich
              Nachhaltigkeitsrisiken aus sozialen oder wirtschaftlichen
              Ereignissen ergeben.
            </li>
          </ul>
          <ul>
            <li>
              Nachteilige Auswirkungen auf Nachhaltigkeitsfaktoren beschreibt
              Ereignisse, die sich negativ auf den Umweltschutz, Sozial- und
              Arbeitnehmerbelange, die Achtung der Menschenrechte und die
              Bekämpfung von Korruption und Bestechung auswirken. Sofern
              Lebensversicherungen z. B. durch Investments in fossile Energien
              gesichert sind, könnten dies eine Nachteilige Auswirkung auf
              Umweltschutzziele haben.
            </li>
          </ul>
        </div>
      </div>

      <div>
        <h2>
          Informationen zu Strategien zur Einbeziehung von
          Nachhaltigkeitsrisiken bei oder Versicherungsberatungstätigkeiten,
          Art. 3 Abs. 2, Art. 6 Abs. 2 lit. a) TVO
        </h2>
        <div>
          Im Rahmen der Auswahl von Versicherungsgesellschaften und
          Versicherungsprodukten berücksichtigen wir die von den Versicherern
          zur Verfügung gestellten Informationen. Sofern sich aus diesen ein für
          den Kunden relevantes Nachhaltigkeitsrisiko ergibt, informieren wir
          ihn im Rahmen unserer Beratungspflicht als Makler.
        </div>
      </div>
      <div>
        <h2>
          Typischerweise zu erwartende Auswirkungen von Nachhaltigkeitsrisiken
          auf deine Versicherungsanlageprodukte, Art. 6 Abs. 2 lit. b) TVO
        </h2>
        <div>
          {" "}
          Die von uns vertriebenen Versicherungsanlageprodukte haben in der
          Regel einen garantierten und einen variablen Leistungsbestandteil.
          Sofern sich relevante Nachhaltigkeitsrisiken realisieren kann dies zu
          einer Verringerung oder einem Totalverlust des variablen
          Leistungsanteils führen. Eine negative Auswirkung auf die garantierten
          Leistungen halten wir aufgrund der Sicherungsmechanismen des
          Solvency-II Rahmenwerks zum aktuellen Zeitpunkt für unwahrscheinlich.
        </div>
      </div>
      <div>
        <h2>
          Informationen zur Vergütungspolitik hinsichtlich
          Nachhaltigkeitsrisiken, Art. 5 bs. 2 TVO
        </h2>
        <div>
          {" "}
          Im Rahmen unserer Vergütungspolitik analysieren wir die erhaltenen und
          gewähr- ten Vergütungen. Unser oberstes Ziel ist dabei die
          Zufriedenheit unserer Kunden. Deswegen empfehlen wir im Einklang mit
          unseren Beratungspflichten als Makler ausschließlich Produkte, die mit
          den Wünschen und Bedürfnissen unserer Kunden in Einklang stehen.
          Deswegen empfehlen wir grundsätzlich nicht nur ein einzelnes Produkt,
          sondern stellen dem Kunden mehrere kuratierte Produkte zur Auswahl.
          Gegebenenfalls bestehende Nachhaltigkeitsrisiken einzelner dieser
          Produkte werden dem Kunden in der Beratung offengelegt und alternative
          Angebote aufgezeigt.
        </div>
      </div>
      <div>
        <h2>
          Informationen zum Umgang mit negativen Auswirkungen auf
          Nachhaltigkeitsfaktoren
        </h2>
        <div>
          Wir arbeiten aktuell daran, negative Auswirkungen auf
          Nachhaltigkeitsfaktoren in die Beratung einzubeziehen und unsere
          Mitarbeiter entsprechend zu schulen. Derzeit fehlen allerdings noch
          die Technischen Regulierungsstandards der Europäischen
          Aufsichtsbehörden sowie Informationen der Versicherungsgesellschaften,
          um detailliert prüfen zu können, welche nachteiligen Auswirkungen auf
          Nachhaltigkeitsfaktoren hinsichtlich einzelner Produkte bestehen
          können.
        </div>
      </div>
    </LegalDoc>
  );
};
