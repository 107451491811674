import styles from "components/form/SmallHint.module.scss";

import React, { ReactElement, ReactNode } from "react";

interface SmallHintProps {
  children: ReactNode;
}

const SmallHint = ({ children }: SmallHintProps): ReactElement => (
  <div className={styles.smallHint}>{children}</div>
);

export default SmallHint;
