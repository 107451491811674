/* eslint-disable react/no-unescaped-entities */
import React, { ReactElement } from "react";
import { STRINGS } from "../../language/de_DE/strings";
import { LegalDoc } from "./LegalDoc";

export const Datenschutz = (): ReactElement => {
  return (
    <LegalDoc title={"Datenschutzerklärung der myNORM GmbH"}>
      <div>Version 1.2, Juni 2022</div>

      <div>
        <h2>A. Allgemeine Hinweise</h2>
        <div>
          Vielen Dank für Ihren Besuch und Ihr damit verbundenes Interesse an
          unserem Internetauftritt. Der Schutz Ihrer personenbezogenen Daten
          (kurz auch „Daten“) ist uns ein sehr wichtiges Anliegen und daher von
          größter Bedeutung. Im Folgenden möchten wir Sie daher über Erhebung,
          Verarbeitung und Nutzung Ihrer personenbezogenen Daten bei uns
          informieren.
        </div>
        <div>
          <h3>1. Gültigkeit und Änderungen</h3>
          <div>
            Diese Datenschutzerklärung ist aktuell gültig und wurde zuletzt im
            Juni 2022 aktualisiert. Wir behalten uns vor, diese
            Datenschutzerklärung zu ändern, wenn sich die Rechtslage oder dieses
            Online-Angebot oder die Art der Datenerfassung ändert. Dies gilt
            jedoch nur bezüglich Erklärungen zur Datenverarbeitung.
            Selbstverständlich werden wir bei allen Änderungen stets Ihre
            Interessen angemessenen berücksichtigen. Sofern für eine
            Datenverarbeitung Ihre Einwilligung notwendig ist oder Bestandteile
            der Datenschutzerklärung eine Regelung des Vertragsverhältnisses mit
            Ihnen enthalten, erfolgt die Änderung der Datenschutzerklärung nur
            nach Ihrer Zustimmung.
          </div>
        </div>
        <div>
          <h3>2. Verantwortlicher, Kontakt, Datenschutzbeauftragter</h3>
          <div>
            Verantwortliche gemäß Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung
            („DS-GVO“) ist die
            <div>myNORM GmbH</div>
            <div>Rendsburger Straße 14</div>
            <div>30659 Hannover</div>
            <div>Deutschland</div>
            <div>
              vertreten durch die Geschäftsführer, Michael Rublack und Dr.
              Thorsten Dylla.
            </div>
            <p>
              Sie erreichen die myNORM GmbH (im Folgenden als
              myNORM/uns/wir/etc. vereinfacht) per
              <div>Tel: {STRINGS.footer.contact.phone}</div>
              <div>E-Mail: {STRINGS.footer.contact.email}</div>
            </p>
            <div>
              Bei Fragen oder Anmerkungen zu dieser Datenschutzerklärung oder
              allgemein zum Datenschutz wenden Sie sich bitte an unseren
              Datenschutz. Sie erreichen unseren Datenschutz per E-Mail:
              datenschutz@mynorm.de oder unter
              <div>myNORM GmbH</div>
              <div>z. H. Datenschutz</div>
              <div>Rendsburger Straße 14</div>
              <div>30659 Hannover</div>
              <div>Deutschland</div>
            </div>
          </div>
          <div>
            <h3>
              3. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
            </h3>
            <div>
              Sofern die Rechtsgrundlage in der Datenschutzerklärung nicht
              genannt wird, gilt Folgendes:
              <ul>
                <li>
                  Soweit wir für Verarbeitungsvorgänge personenbezogener Daten
                  eine Einwilligung der betroffenen Person einholen, dient Art.
                  6 Abs.1 S. 1 lit. a) DS-GVO als Rechtsgrundlage.
                </li>
                <li>
                  Bei der Verarbeitung von personenbezogenen Daten, die zur
                  Erfüllung eines Vertrages, dessen Vertragspartei die
                  betroffene Person ist, erforderlich ist, dient Art. 6 Abs.1 S.
                  1 lit. b) DS-GVO als Rechtsgrundlage. Dies gilt auch für
                  Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher
                  Maßnahmen erforderlich sind.
                </li>
                <li>
                  Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung
                  einer rechtlichen Verpflichtung erforderlich ist, der unser
                  Unternehmen unterliegt, dient Art. 6 Abs.1 S. 1 lit. c) DS-GVO
                  als Rechtsgrundlage.
                </li>
                <li>
                  Ist die Verarbeitung zur Wahrung eines berechtigten Interesses
                  unseres Unternehmens oder eines Dritten erforderlich und
                  überwiegen die Interessen, Grundrechte und Grundfreiheiten des
                  Betroffenen das erstgenannte Interesse nicht, so dient Art. 6
                  Abs.1 S. 1 lit. f) DS-GVO als Rechtsgrundlage für die
                  Verarbeitung.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h3>4. Allgemeine Hinweise zur Speicherdauer</h3>
            <div>
              Die von uns verarbeiteten Daten werden unter Einhaltung der
              gesetzlichen Vorschriften, insbesondere nach Art. 17 und 18 DS-GVO
              gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht im
              Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, löschen
              wir bei uns gespeicherte Daten, sobald sie für ihre
              Zweckbestimmung nicht mehr benötigt werden. Über den Zeitpunkt des
              Zweckfortfalls hinaus werden Daten nur dann aufgehoben, wenn sie
              für andere und gesetzlich zulässige Zwecke erforderlich sind oder
              die Daten aufgrund gesetzlicher Aufbewahrungspflichten weiter
              vorgehalten werden müssen. In diesen Fällen wird die Verarbeitung
              eingeschränkt, das betroffene Datum gesperrt und nicht für andere
              Zwecke verarbeitet.
            </div>
          </div>
        </div>
      </div>

      <div>
        <h2>
          B. Erhebung personenbezogener Daten bei dem Besuch unserer Webseite
        </h2>
        <div>
          Umfang und Art der Erhebung und Verwendung Ihrer Daten unterscheiden
          sich danach, ob Sie unseren Internetauftritt nur zum Abruf von
          Informationen, also z. B. zur Ansicht unserer Angebote besuchen oder
          von uns angebotene Leistungen in Anspruch nehmen oder mit uns Kontakt
          aufnehmen bzw. Informationen anfordern.
        </div>
        <div>
          <h3>1. Informatorische Nutzung</h3>
          <div>
            Für die informatorische Nutzung unseres Internetauftritts ist es
            grundsätzlich nicht erforderlich, dass Sie aktiv personenbezogene
            Daten angeben. Vielmehr erheben und verwenden wir in diesem Fall nur
            diejenigen Ihrer Daten, die uns Ihr Internetbrowser automatisch
            übermittelt und die dazu nötig sind Ihnen unsere Informationen
            anzeigen zu können. Dazu gehören:
            <ul>
              <li> IP-Adresse</li>
              <li> Datum und Uhrzeit des Abrufs unserer Website</li>
              <li> Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
              <li> Inhalt der Anforderung (konkrete Seite)</li>
              <li> Zugriffsstatus/HTTP-Statuscode</li>
              <li> jeweils übertragene Datenmenge</li>
              <li>
                Website, von der die Anforderung kommt und zuletzt besuchte
                Seite
              </li>
              <li> Browsertyp</li>
              <li> Sprache und Version der Browsersoftware</li>
              <li> Verwendetes Betriebssystem und dessen Oberfläche.</li>
            </ul>
          </div>
          <div>
            Die Verarbeitung dieser Daten erfolgt, um die Nutzung der von Ihnen
            abgerufenen Internetseiten überhaupt zu ermöglichen, zu
            statistischen Zwecken sowie zur Verbesserung unseres
            Internetangebots und letztlich zur Detektion und Nachverfolgbarkeit
            möglichen Missbrauchs. In diesem Zusammenhang verarbeitete
            personenbezogene Daten werden in jedem Fall nach 180 Tagen
            automatisiert von unseren Servern gelöscht. Die Verarbeitung der
            vorgenannten Daten ist für das Angebot einer Internetseite gemäß
            Art. 6 Abs.1 S. 1 S.1 lit. b) DS-GVO zwingend technisch
            erforderlich, um Ihnen unsere Webseite korrekt anzuzeigen. Log-Files
            speichern wir, um die Stabilität und Sicherheit unseres
            Internetangebots zu gewährleisten. Insbesondere wird mit der
            Erstellung von Log-Files bezweckt, Angriffe auf unsere Systeme
            nachweisen zu können. Rechtsgrundlage hierzu ist Art. 6 Abs. 1 S.1 ,
            lit. c), lit. f) DS-GVO.
          </div>
        </div>
        <div>
          <h3>2. Anmeldung zu den Newslettern</h3>
          <div>
            Wir nutzen dieses Kommunikationsmedium, um unsere Abonnenten über
            Neuigkeiten zu dem ausgewählten Interessenskomplex informieren zu
            können. Rechtsgrundlage für den Versand des Newsletters ist Ihre
            Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a) DS-GVO, die wir im
            Double-Opt-In-Verfahren einholen. Grundsätzlich benötigen wir für
            den Versand der informatorischen E-Mail lediglich Ihre
            E-Mail-Adresse. Wenn Sie uns über die Personalisierungsfunktion
            Ihren Namen und die Anrede mitteilen möchten, nutzen wir diese
            Angaben, um Sie persönlich anzusprechen. Wir haben uns verschiedene
            Newsletteranbieter angesehen und uns aber unter anderem wegen guter
            Zustellraten und der durchgehend guten Bedienbarkeit für Mailchimp
            entschieden. In den USA wird somit zumindest Ihre E-Mail-Adresse
            verarbeitet und, sofern Sie die Option nutzen, Ihr Name. In den USA
            besteht aktuell kein angemessenes Datenschutzniveau, und es ist
            nicht ausgeschlossen, dass staatliche Sicherheitsbehörden
            entsprechende Anordnungen gegenüber den Newsletter-Providern
            treffen, um Zugriff zu Daten zu erhalten. Auch sind
            Rechtsschutzmöglichkeiten derzeit eingeschränkt. Da wir jedoch nur
            mit E-Mail-Adressen und (auf freiwilliger Basis) Namen arbeiten,
            halten wir nach Durchführung eines Transfer Impact Assessments das
            Risiko allerdings für vertretbar und die Wahrscheinlich für einen
            tatsächlichen Zugriff für eher gering.
          </div>
          <div>
            Wir verarbeiten Ihre Daten überdies nur so lange, wie Sie den
            jeweiligen Newsletter abonniert haben. Nach einer Beendigung sichern
            wir Ihre E-Mail-Adresse noch für einen Zeitraum von 3 Jahren, um
            durch Abgleich sicherstellen zu können, dass Sie nicht doch einen
            ungewollten Newsletter erhalten. Überdies können wir so auch das
            Einhalten von § 7 UWG nachhalten. Abbestellen können Sie den
            Newsletter jederzeit über den im Newsletter enthaltenen Link oder in
            dem Sie sich an uns wenden. Wir tracken Öffnungs- und Klickraten
            bisher eigentlich nicht. Ausnahmen hiervon kann es geben, wenn
            unsererseits vergessen wird, die Checkbox beim Versand des
            Newsletters, dass ein Tracking nicht erfolgen soll, anzuhaken.
          </div>
          <div>
            Bei besonderen Aktionen kann es ausnahmsweise zudem vorkommen, dass
            wir Klicks auf Links tracken. Das beschreiben wir dann im
            betreffenden Newsletter.
          </div>
        </div>
        <div>
          <h3>3. Anfragen per Telefonat und E-Mail</h3>
          <div>
            Per E-Mail, Post und via Telefon wollen wir Ihnen die Möglichkeit
            geben, sich mit uns in Verbindung zu setzen. Die Kontaktwege können
            Sie einerseits zur Anforderung eines Angebots, andererseits für
            generelle Anfragen nutzen. Wir benötigen zur Bearbeitung Ihrer
            Anfrage grundsätzlich sowohl Ihren Namen als auch Ihre
            E-Mail-Adresse oder Telefonnummer, sowie Angaben zu Ihrem Anliegen.
            Die Verarbeitung Ihrer Daten im Rahmen einer Kontaktaufnahme per
            Post, E-Mail oder Telefon erfolgt je nach Anfrageinhalt bei rein
            informatorischen Anfragen auf Grundlage Ihrer (mutmaßlichen)
            Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a) DS-GVO, oder gemäß
            Art. 6 Abs. 1 S. 1 lit. b) DS-GVO, soweit die Kontaktaufnahmen im
            Zusammenhang mit vertraglichen Erfüllungspflichten stehen. Neben
            grundsätzlichen Angaben wie des gewünschten Weges der
            Kontaktaufnahme durch uns, können Sie freiwillig Informationen zu
            Ihrer Firma oder auch andere Inhalte, die Sie für relevant halten,
            angeben. Ihre Angaben können in einem
            Customer-Relationship-Management System ("CRM System") gespeichert
            werden. Rechtsgrundlage hierzu ist Art. 6 Abs. 1 S. 1 lit. f)
            DS-GVO. Wir können ein solches System einsetzen, um die
            Kommunikation mit unseren Kunden und Interessenten nachhalten und
            deren (mutmaßlichen) Wünsche nachvollziehen zu können. Sie haben
            jederzeit die Möglichkeit, Ihre Einwilligung zur Verarbeitung der
            personenbezogenen Daten zu widerrufen, ohne dass die Rechtmäßigkeit,
            der aufgrund der Einwilligung bis zum Widerruf erfolgten
            Verarbeitung berührt wird. Nehmen Sie zu informatorischen Zwecken
            per E-Mail, Post oder Telefon Kontakt mit uns auf, so können Sie der
            Speicherung Ihrer personenbezogenen Daten jederzeit widersprechen.
            In einem solchen Fall kann die Konversation nicht fortgeführt
            werden. Ferner haben Sie das Recht, der Speicherung Ihrer
            personenbezogenen Daten in einem CRM System zu widersprechen. Ihre
            Kontaktanfragen werden wir nach abschließender Bearbeitung umgehend
            aus unseren aktiven Systemen löschen, soweit nicht gesetzliche
            Erlaubnistatbestände oder Aufbewahrungspflichten eine weitergehende
            Aufbewahrung gestatten oder erfordern.
          </div>
        </div>
        <div>
          <h3>4. Datenverarbeitung im Rahmen Ihres Nutzeraccounts</h3>
          <div>
            Wenn Sie einen Nutzeraccount online bei uns anlegen, weil Sie unsere
            Dienste nutzen möchten, verarbeiten wir Ihre Daten. Diese Daten sind
            Grundlage der Accountverwaltung und Nutzung und tragen einerseits
            dazu bei die Effektivität unseres Angebotes zu verbessern und
            andererseits, um dieses aus Datenschutzsicht und
            IT-Sicherheitsaspekten gegen Missbrauch oder andere schädigende
            Einflüsse abzusichern. Rechtsgrundlage für die Anlage des Accounts
            ist Art. 6 Abs. 1 S. 1 lit. a) DS-GVO, Ihre Einwilligung. Die
            Verarbeitung von Daten im Verlauf der Accountnutzung stützen wir auf
            die Vornahme (vor-)vertraglicher Maßnahmen auf Ihre Initiative hin,
            Art. 6 Abs. 1 S. 1 lit. b) DS-GVO. Die Verarbeitung im Hinblick auf
            Verbesserungs- und Absicherungsmaßnahmen hingegen stützen wir auf
            Art. 6 Abs. 1 S. 1 lit. f) DS-GVO wobei unser Interesse darin
            begründet ist, Ihnen reibungslos laufende und vor allem sichere
            Dienste anbieten zu können. Hierzu verarbeiten wir im Regelfall:
            <ul>
              <li>Visitenkartendaten</li>
              <li>Abrechnungsdaten</li>
              <li>Identifikationsdaten</li>
              <li>
                Einstellungsdaten wie Newsletter, Cookie- und Trackingoptionen
              </li>
              <li>Metadaten wie IP-Adressen, Zeitstempel, Logdaten</li>
            </ul>
            In die Accountverwaltung sind auch Dritte als Dienstleister
            involviert. Diese sind unter 5. Auftragsverarbeitung und Empfänger
            von Daten aufgeführt. Unsere Auswahl bevorzugt Dienste mit
            europäischen Sitzen. Grundsätzlich bewahren wir die so erhaltenen
            Daten so lange auf, bis Sie Ihren Account löschen oder uns bitten
            dies zu tun. Einzelne Daten aus diesen Accounts, zum Beispiel
            Rechnungsdaten oder Identifikationsdaten, können anderen
            Aufbewahrungspflichten von bis zu 10 Jahren nach Tätigung des
            letzten Auftrags unterliegen.
          </div>
        </div>
        <div>
          <h3>5. Einsatz von Tracking Technologien</h3>
          <div>
            Wir haben auf unseren Webseiten Google Analytics und Google Signals
            eingesetzt. Sofern Sie mit Ihrem Google-Account angemeldet sind,
            personalisierter Werbung zugestimmt haben und wir Ihre Einwilligung
            erhalten haben, bekommen wir von Google zusammengefasste und
            anonymisierte Informationen zu Ihren Bewegungen im Internet. Es
            handelt sich hierbei um Cross-Device-Tracking, das heißt, dass Ihre
            Daten geräteübergreifend analysiert werden. Durch Google Signals
            werden diese Daten erfasst und mit Ihrem Google-Account verknüpft.
            Google verarbeitet Ihre Daten auf den Servern, die es dafür
            vorsieht. Diese sind weltweit verteilt. Wir verwenden außerdem die
            Remarketing-Funktion von Google. Dadurch können wir Ihnen auf
            geeigneten Werbeplätzen auf anderen Websites personalisierte Werbung
            ausspielen, die darauf basiert, welche Interessen Sie auf unserer
            Website gezeigt haben. Diese Möglichkeit ist auf maximal 18 Monate
            beschränkt. Weitere Informationen dazu finden Sie in der
            Datenschutzerklärung von Google.
          </div>
          <div>
            Das Tracking durch Google Analytics kann durch verschiedene
            Maßnahmen unterbunden werden:
            <ul>
              <li>
                Verweigerung der Zustimmung: haben wir Ihre Einwilligung nicht,
                wird kein Tracking ausgeführt. Google Analytics 4 und Google
                Signals finden keine Anwendung.
              </li>
              <li>
                Deaktivieren von JavaScript: durch Deaktivieren von JavaScript
                kann Google Analytics keine Daten erheben und nutzen. Wir weisen
                darauf hin, dass das Deaktivieren von JavaScript dazu führt,
                dass Ihnen mynorm.de nicht mehr angezeigt werden kann.
              </li>
              <li>
                Durch die Installation eines Plug-ins von{" "}
                <a href="https://support.google.com/ads/answer/7395996">
                  (https://support.google.com/ads/answer/7395996),
                </a>{" "}
                kann das Tracking dauerhaft unterbunden werden.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3>6. Auftragsverarbeitung und Empfänger von Daten</h3>
          <div>
            Teilweise bedienen wir uns zur Verarbeitung Ihrer Daten externer
            Dienstleister, welche an unsere Weisungen gebunden sind. Diese
            wurden sorgfältig von uns ausgewählt, beauftragt und werden
            regelmäßig kontrolliert. Den Beauftragungen liegen Vereinbarungen
            über die Auftragsverarbeitung nach Maßgabe des Art. 28 DS-GVO
            zugrunde. Sofern eine Datenübertragung in unsichere Drittländer zu
            befürchten ist, haben wir entsprechende Transfer Impact Assessments
            durchgeführt und die entsprechenden Verträge, vorliegend die
            Standardvertragsklauseln der Europäischen Kommission{" "}
            <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?uri=CELEX:32021D0914&locale=de">
              (https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?uri=CELEX:32021D0914&locale=de),
            </a>{" "}
            kurz SCC, abgeschlossen.
          </div>
        </div>
        <div>
          <h3>7. Datensicherheit</h3>
          <div>
            <p>
              Diese Seite nutzt eine SSL Verschlüsselung zur sicheren
              Datenübertragung, insbesondere mit Hinblick auf die Übertragung
              Ihrer Kommunikationsdaten aus dem Kontaktformular. Wir verwenden
              innerhalb des Website-Besuchs das verbreitete SSL-Verfahren
              (Secure Socket Layer). Das verwendete Zertifikat erlaubt dabei
              eine Verschlüsselung mit bis zu 256 Bit. Ob verschlüsselt
              übertragen wird, erkennen Sie üblicherweise an der geschlossenen
              Darstellung des verschlossenen Schlüssel- beziehungsweise
              Schloss-Symbols in der Adresszeile Ihres Browsers.
            </p>
            <p>
              Wir bedienen uns im Übrigen geeigneter technischer und
              organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen
              zufällige oder vorsätzliche Manipulationen, teilweisen oder
              vollständigen Verlust, Zerstörung oder gegen den unbefugten
              Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden
              entsprechend der technologischen Entwicklung fortlaufend
              verbessert.
            </p>
          </div>
        </div>
        <div>
          <h3>8. Eingesetzte Technologien auf unserer Webseite</h3>
          <div>
            <h4>Allgemeine Hinweise</h4>
            <div>
              Für unseren Internetauftritt nutzen wir die Technik der Cookies.
              Cookies sind kleine Textdateien, die im Rahmen Ihres Besuchs
              unserer Webseiten von unserem Webserver an Ihren Browser gesandt
              und von diesem auf Ihrem Rechner für einen späteren Abruf
              vorgehalten werden. Ob Cookies gesetzt und abgerufen werden
              können, können Sie durch die Einstellungen in Ihrem Browser selbst
              bestimmen. Sie können in Ihrem Browser etwa das Speichern von
              Cookies gänzlich deaktivieren, es auf bestimmte Webseiten
              beschränken oder Ihren Browser so konfigurieren, dass er Sie
              automatisch benachrichtigt, sobald ein Cookie gesetzt werden soll
              und Sie um Rückmeldung dazu bittet. Cookies können keine Programme
              ausführen oder Viren auf Ihren Computer übertragen. Als
              Rechtsgrundlage für die Verwendung von Cookies dienen
              hauptsächlich Art. 6 Abs. 1 S. 1 lit. a) DS-GVO (Einwilligung)
              sowie Art. 6 Abs. 1 S. 1 lit. f) DS-GVO (berechtigtes Interesse),
              soweit nicht anderweitig angegeben.
            </div>
            <h4>Session/temporäre Cookies</h4>
            <div>
              Wir setzen beinahe ausschließlich sogenannte Session-Cookies (auch
              als temporäre Cookies bezeichnet) ein, also solche, die
              ausschließlich für die Dauer Ihrer Nutzung einer unserer Webseiten
              zwischengespeichert werden. Für den vollen Funktionsumfang unseres
              Internetauftritts ist es aus technischen Gründen erforderlich, die
              genannten Session Cookies zuzulassen. Denn Zweck dieser Cookies
              ist, Ihren Rechner während eines Besuchs unseres Internetauftritts
              beim Wechsel von einer unserer Webseiten zu einer anderen unserer
              Webseiten weiterhin zu identifizieren und das Ende Ihres Besuchs
              feststellen zu können. Rechtsgrundlage für die Verwendung
              temporärer Cookies sind § 25 Abs. 2 TTDSG (notwendige Techniken)
              sowie Art. 6 Abs. 1 S. 1 lit. f) DS-GVO. (berechtigtes Interesse).
            </div>
            <h4>Persistente Cookies</h4>
            <div>
              Persistente Cookies werden automatisiert nach einer vorgegebenen
              Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie
              können die Cookies in den Sicherheitseinstellungen Ihres Browsers
              jederzeit löschen.
            </div>
            <h4>Cookie-Banner</h4>
            <div>
              Für den Einsatz von Google Analytics 4 benötigen wir Ihre
              Einwilligung. Um diese wirksam erhalten zu können, setzen wir ein
              Consent-Banner ein. Mithilfe dieses Banners erfüllen wir unsere
              gesetzlichen Nachweispflichten.
            </div>
          </div>
        </div>
      </div>

      <div>
        <h2>C. Erhebung von Daten bei einer Bewerbung</h2>
        <div>
          <h3>1. Allgemeine Informationen zur Datenverarbeitung</h3>
          <div>
            Zweck der Verarbeitung personenbezogener Daten im Rahmen einer
            Bewerbung ist die Auswahl eines geeigneten Bewerbers für die jeweils
            ausgeschriebene Stelle. Hierzu ist es notwendig, dass uns der
            Bewerber diejenigen Datenarten mitteilt, die wir in der Bewerbung
            explizit angefordert haben, oder die er möglicherweise zusätzlich
            für entscheidungserheblich hält. Mit Übermittlung dieser
            personenbezogenen Daten an uns erklärt sich der Bewerber mit der
            korrespondierenden Verarbeitung seiner personenbezogenen Daten
            einverstanden. Im Fall einer erfolgreichen Bewerbung können erhobene
            Daten zu Zwecken der Ausgestaltung des Beschäftigungsverhältnisses
            von uns weiterverarbeitet werden. Rechtsgrundlage für die
            Verarbeitung personenbezogener Daten im Rahmen Ihrer Bewerbung ist
            Art. 6 Abs. 1 S. 1 lit. b) DS-GVO in Verbindung mit § 26 BDSG. Über
            den ursprünglichen Zweck (Auswahl eines geeigneten Bewerbers) hinaus
            bewahrt Bröskamp Consulting zur Verteidigung von Rechtsansprüchen
            Ihre Bewerbungsdaten für die Dauer von 6 Monaten ab Ablehnung Ihrer
            Bewerbung auf. Grundlage dieser Verarbeitung ist Art. 17 Abs. 3 lit.
            e), Art. 6 Abs. 1 S. 1 lit. f) DS-GVO.
          </div>
        </div>
        <div>
          Sollten Sie als Bewerber darüber hinaus eine Aufbewahrung der
          Bewerbungsdaten in unserem Bewerber-Pool wünschen, um gegebenenfalls
          zu einem späteren Zeitpunkt bei der Besetzung vakanter Positionen
          berücksichtigt zu werden, ist Ihre Einwilligung erforderlich. Sprechen
          Sie uns hierzu bitte gesondert an. Rechtsgrundlage für eine
          verlängerte Speicherung ist Art. 6 Abs. 1 S. 1 lit. a), Art. 7 DS-GVO.
          Diese Einwilligung bezieht sich auch auf besondere Kategorien von
          Daten nach Art. 9 DS-GVO (bspw. Fotos, Angaben über
          Schwerbehinderteneigenschaft u. s. w.), soweit diese von Ihnen an uns
          übermittelt wurden. Ihre Einwilligung können Sie jederzeit widerrufen.
          In diesem Fall werden Ihre Daten unverzüglich gelöscht. Die
          individuell verlängerte Aufbewahrung der Bewerber-Daten führt zu einer
          Löschung der nach 3 Jahren. Bitte beachten Sie, dass diese Option nur
          für Bewerber möglich ist, die über 16 Jahre alt sind. Bewerbungsdaten
          von unter 16-Jährigen werden nach Ablauf oben genannter Fristen
          vernichtet. Eine Übermittlung oder Weitergabe von Bewerberdaten an
          Dritte ist nicht geplant.
        </div>
      </div>

      <div>
        <h2>D. Ihre Rechte</h2>
        <div>
          Sie haben gegenüber uns nach Maßgabe der gesetzlichen Vorschriften
          folgende Rechte hinsichtlich der Verarbeitung der Sie betreffenden
          personenbezogenen Daten:
          <ul>
            <li>Recht auf Widerruf einer Einwilligung (Art. 7 DS-GVO)</li>
            <li>Recht auf Auskunft (Art. 15 DS-GVO),</li>
            <li>
              Recht auf Berichtigung oder Löschung (Art. 16 und Art. 17 DS-GVO),
            </li>
            <li>Recht auf Einschränkung der Verarbeitung (Art. 18 DS-GVO),</li>
            <li>Recht auf Datenübertragbarkeit (Art. 20 DS-GVO),</li>
            <li>
              Recht auf Widerspruch gegen die Verarbeitung (Art. 21 DS-GVO).
            </li>
          </ul>
        </div>
        <div>
          <h2>3. Bewerbung per Post</h2>
          <div>
            Ihre initiative Bewerbung können Sie grundsätzlich auch per Post an
            uns richten:
            <div>myNORM GmbH</div>
            <div>z. Hd. HR</div>
            <div>Rendsburger Straße 14</div>
            <div>30659 Hannover</div>
            <div>Deutschland</div>
            <p>
              Erhalten wir Bewerbungen auf postalischem Weg, digitalisieren wir
              diese für die Nutzung innerhalb unserer Infrastruktur. Bezüglich
              der Datenerfassung und -haltung ergeben sich keine Abweichungen
              zur obigen Zusammenfassung.
            </p>
          </div>
        </div>
        <div>
          <h2>D. Ihre Rechte</h2>
          <div>
            Sie haben gegenüber uns nach Maßgabe der gesetzlichen Vorschriften
            folgende Rechte hinsichtlich der Verarbeitung der Sie betreffenden
            personenbezogenen Daten:
            <ul>
              <li>Recht auf Widerruf einer Einwilligung (Art. 7 DS-GVO),</li>
              <li>Recht auf Auskunft (Art. 15 DS-GVO),</li>
              <li>
                Recht auf Berichtigung oder Löschung (Art. 16 und Art. 17
                DS-GVO),
              </li>
              <li>
                Recht auf Einschränkung der Verarbeitung (Art. 18 DS-GVO),
              </li>
              <li>Recht auf Datenübertragbarkeit (Art. 20 DS-GVO),</li>
              <li>
                Recht auf Widerspruch gegen die Verarbeitung (Art. 21 DS-GVO).
              </li>
            </ul>
          </div>
          <div>
            Zur Geltendmachung Ihrer Rechte richten Sie bitte Ihre Anfrage an
            die oben aufgeführten Kontakte: Verantwortlicher oder Datenschutz.
          </div>
          <div>
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
            gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
            einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
            Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
            Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der
            Sie betreffenden personenbezogenen Daten gegen die DS-GVO verstößt.
            Die Aufsichtsbehörde, bei der Sie die Beschwerde einreichen,
            unterrichtet Sie über den Stand und die Ergebnisse Ihrer Beschwerde
            einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs
            nach Art. 78 DS-GVO.
          </div>
          <div>
            Unter{" "}
            <a href="https://www.datenschutzkonferenz-online.de/datenschutzaufsichtsbehoerden.html">
              www.datenschutzkonferenz-online.de/datenschutzaufsichtsbehoerden
            </a>
            , können Sie sich die in der BRD aktiven Aufsichtsbehörden anzeigen
            lassen.
          </div>
        </div>
      </div>
    </LegalDoc>
  );
};
