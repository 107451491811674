import styles from "components/user-portal/UserPortal.module.scss";

import React, { FunctionComponent, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PortalContent from "components/user-portal/portal-content/PortalContent";
import { RouteUrls } from "config/routes";
import useUserState from "hooks/useUserState";
import { Box } from "../atoms/Box";
import PortalSideboard from "./portal-sideboard/PortalSideboard";

type UserPortalProps = {
  children: ReactNode;
};

const UserPortal: FunctionComponent<UserPortalProps> = ({ children }) => {
  const { kunde, isRequiredKundenDataMissing } = useUserState();
  const navigate = useNavigate();

  useEffect(() => {
    if (isRequiredKundenDataMissing) {
      navigate(RouteUrls.additionalRegistrationData);
    }
  }, [isRequiredKundenDataMissing, navigate]);
  if (!kunde || isRequiredKundenDataMissing) return null;
  return (
    <Box className={styles.portal}>
      <Box className={styles.portalSideboard}>
        <PortalSideboard />
      </Box>
      <Box className={styles.portalMain}>
        <PortalContent>{children}</PortalContent>
      </Box>
    </Box>
  );
};

export default UserPortal;
