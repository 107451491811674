import React, { FunctionComponent } from "react";
import styles from "./CampaignHeader.module.scss";
import PageSection from "../../page-section/PageSection";
import UnstyledLink from "../../unstyle-link/UnstyledLink";
import { Button } from "../../form-inputs/Button/Button";
import { STRINGS } from "../../../language/de_DE/strings";
import { IoCheckmarkSharp } from "react-icons/io5";

type Props = {
  image: string;
  title: string;
  checklistItems: string[];
  buttonLink?: string;
};

const sectionStrings = STRINGS.campaigns;

export const CampaignHeader: FunctionComponent<Props> = ({
  image,
  title,
  checklistItems,
  buttonLink,
}) => (
  <PageSection contentClassName={styles.paddinglessHeader}>
    <div className={styles.imageHeadline}>
      <img src={image} alt={title} className={styles.insuranceImage} />
      <div className={styles.text}>
        <h1 className={styles.insuranceTitle}>{title}</h1>
        <div className={styles.checklist}>
          {checklistItems.map((item) => (
            <div key={item} className={styles.item}>
              <IoCheckmarkSharp className={styles.checkmark} />
              {item}
            </div>
          ))}
        </div>
        {buttonLink && (
          <UnstyledLink to={buttonLink}>
            <Button label={sectionStrings.insureNowButtonLabel} />
          </UnstyledLink>
        )}
      </div>
    </div>
  </PageSection>
);
