import React, { FunctionComponent } from "react";
import styles from "./ContractCheckTeaser.module.scss";
import { STRINGS } from "../../../../../language/de_DE/strings";
import UnstyledLink from "../../../../unstyle-link/UnstyledLink";
import { RouteUrls } from "../../../../../config/routes";
import { Button } from "../../../../form-inputs/Button/Button";
import teaserImage from "../../../../../gfx/images/startpage/teaser-portal-phone.jpg";
import PageSection from "../../../../page-section/PageSection";

const sectionStrings = STRINGS.startpage.ContractCheckTeaser;

export const ContractCheckTeaser: FunctionComponent = () => (
  <PageSection contentClassName={styles.section}>
    <div className={styles.sectionContent}>
      <div className={styles.teaserImage}>
        <img src={teaserImage} alt={sectionStrings.altText} />
      </div>
      <div className={styles.teaserText}>
        <h1>
          <span className={styles.highlightedTitle}>
            {sectionStrings.titleFirstLine}
          </span>
          <br />
          {sectionStrings.titleSecondLine}
        </h1>
        {sectionStrings.prose}
        <UnstyledLink
          to={RouteUrls.userAddContract}
          className={styles.buttonLink}
        >
          <Button
            label={sectionStrings.portalButtonLabel}
            className={styles.portalButton}
          />
        </UnstyledLink>
      </div>
    </div>
  </PageSection>
);
