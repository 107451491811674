import React, { ReactElement, useState } from "react";
import StepWizard from "react-step-wizard";
import AdditionalRegistrationDataForm from "components/organisms/AdditionalRegistrationDataForm";
import AuthenticationCodeForm from "components/organisms/AuthenticationCodeForm";
import RegistrationForm from "components/organisms/RegistrationForm";
import AuthenticationCodeFormContext from "components/step-wizard/components/AuthenticationCodeFormContext";
import { WizardStep } from "components/step-wizard/components/WizardStep";
import RegistrationWizardContext, {
  RegistrationWizardContextState,
  RegistrationWizardContextType,
} from "components/step-wizard/registration/RegistrationWizardContext";

interface RegistrationStepWizardProps {
  initialStep?: number;
}

const RegistrationStepWizard = ({
  initialStep = 1,
}: RegistrationStepWizardProps): ReactElement => {
  const [wizardContextState, setWizardContextState] =
    useState<RegistrationWizardContextState>({
      email: "",
      gender: "",
      firstName: "",
      lastName: "",
      password: "",
      phoneNumber: "",
    });

  const wizardProviderValue: RegistrationWizardContextType = {
    state: wizardContextState,
    setState: setWizardContextState,
  };

  return (
    <RegistrationWizardContext.Provider value={wizardProviderValue}>
      <StepWizard isHashEnabled={false} initialStep={initialStep}>
        <WizardStep>
          {({ nextStep }) => <RegistrationForm nextStep={nextStep} />}
        </WizardStep>
        <WizardStep>
          {({ isActive, previousStep, nextStep }) => (
            <AuthenticationCodeFormContext.Provider
              value={{
                email: wizardProviderValue.state.email,
                password: wizardProviderValue.state.password,
              }}
            >
              <AuthenticationCodeForm
                previousStep={previousStep}
                nextStep={nextStep}
                isActive={isActive ?? false}
              />
            </AuthenticationCodeFormContext.Provider>
          )}
        </WizardStep>
        <WizardStep>{() => <AdditionalRegistrationDataForm />}</WizardStep>
      </StepWizard>
    </RegistrationWizardContext.Provider>
  );
};

export default RegistrationStepWizard;
