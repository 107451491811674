type UseTabIndexReturnType = { getTabIndex: () => number };

const useTabIndex = (): UseTabIndexReturnType => {
  let tabIndex = 1;

  return {
    getTabIndex: () => {
      return tabIndex++;
    },
  };
};

export default useTabIndex;
