/* eslint-disable import/no-unused-modules */
import React, { FunctionComponent } from "react";
import styles from "./InsuranceSituation.module.scss";
import { STRINGS } from "../../../language/de_DE/strings";

import { TitledBulletpointType } from "../../../content/productTreeDefinition";
import PageSection from "../../page-section/PageSection";

const sectionStrings = STRINGS.insurancePage.insuranceSituation;

type InsuranceSituationProps = {
  situations: TitledBulletpointType[];
  insuranceName: string;
};

export const InsuranceSituation: FunctionComponent<InsuranceSituationProps> = ({
  situations,
  insuranceName,
}) => (
  <PageSection background={"grey"}>
    <h1 className={styles.sectionTitle}>
      {sectionStrings.titlePart1}
      <span className={styles.highlighted}>{insuranceName}</span>{" "}
      {sectionStrings.titlePart2}
    </h1>
    <div className={styles.situationBox}>
      {situations.map((entry: TitledBulletpointType) => (
        <div key={entry.title} className={styles.situations}>
          <img
            src={entry.text}
            alt={entry.title}
            className={styles.situationIconImage}
          />
          <div>{entry.title}</div>
        </div>
      ))}
    </div>
  </PageSection>
);
