import styles from "components/molecules/InsuranceComparisonBox.module.scss";

import React, { FunctionComponent } from "react";
import { Box } from "components/atoms/Box";
import { Button } from "components/form-inputs/Button/Button";
import { Text } from "components/atoms/Text";
import { InsuranceComparisonBoxItem } from "components/molecules/InsuranceComparisonBoxItem";
import { STRINGS } from "language/de_DE/strings";

export const InsuranceComparisonBox: FunctionComponent = () => {
  return (
    <Box flex column className={styles.insuranceComparisonBox}>
      <Box flex inline className={styles.insuranceComparisonHeadlineBox}>
        <Box className={styles.insuranceComparisonHeadline}>
          <Text size={"medium"} inline>
            {
              STRINGS.insuranceComparison
                .insuranceComparisonInsuranceComparisonBoxHeadline1
            }
          </Text>
        </Box>
        <Text size={"xSmall"} inline>
          {
            STRINGS.insuranceComparison
              .insuranceComparisonInsuranceComparisonBoxHeadline2
          }
        </Text>
      </Box>
      <Box flex className={styles.insuranceComparisonListBox}>
        <InsuranceComparisonBoxItem
          number={1}
          insuranceName={"Versicherung 1"}
          logoSrc={"https://via.placeholder.com/108x87/515A80/FFFFFF?text=Logo"}
          logoAlt={"insuranceLogo"}
        />
        <InsuranceComparisonBoxItem
          number={2}
          insuranceName={"Versicherung 2"}
          logoSrc={"https://via.placeholder.com/108x87/515A80/FFFFFF?text=Logo"}
          logoAlt={"insuranceLogo"}
        />
        <InsuranceComparisonBoxItem
          number={3}
          insuranceName={"Versicherung 3"}
          logoSrc={"https://via.placeholder.com/108x87/515A80/FFFFFF?text=Logo"}
          logoAlt={"insuranceLogo"}
        />
        <Box flex className={styles.insuranceComparisonButtonBox}>
          <Button
            label={
              STRINGS.insuranceComparison
                .insuranceComparisonInsuranceComparisonBoxButtonLabel
            }
            primary
          />
        </Box>
      </Box>
    </Box>
  );
};
