import styles from "components/user-portal/user-profile/Profile.module.scss";

import React from "react";
import { Box } from "components/atoms/Box";
import PortalContentHeader from "components/user-portal/portal-content/PortalContentHeader";
import { AssetsCard } from "components/user-portal/user-profile/dialogs/assets/AssetsCard";
import { BankingAccountCard } from "components/user-portal/user-profile/dialogs/banking-account/BankingAccountCard";
import { ContactCard } from "components/user-portal/user-profile/dialogs/contact/ContactCard";
import { IdentificationCard } from "components/user-portal/user-profile/dialogs/identification/IdentificationCard";
import { IncomeCard } from "components/user-portal/user-profile/dialogs/income/IncomeCard";
import { PersonalDataCard } from "components/user-portal/user-profile/dialogs/personal-data/PersonalDataCard";
import { RisksCard } from "components/user-portal/user-profile/dialogs/risks/RisksCard";
import useUserState from "hooks/useUserState";
import { STRINGS } from "language/de_DE/strings";

const Profil: React.FC = () => {
  const { kunde } = useUserState();
  return (
    <Box className={styles.grid}>
      <PortalContentHeader title={STRINGS.profile.header} />
      {kunde && (
        <Box className={styles.profile}>
          <Box>
            <PersonalDataCard kunde={kunde} />
          </Box>
          <Box>
            <ContactCard kunde={kunde} />
          </Box>
          <Box>
            <IncomeCard kunde={kunde} />
          </Box>
          <Box>
            <AssetsCard kunde={kunde} />
          </Box>
          <Box>
            <BankingAccountCard kunde={kunde} />
          </Box>
          <Box>
            <RisksCard kunde={kunde} />
          </Box>
          <Box>
            <IdentificationCard kunde={kunde} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Profil;
