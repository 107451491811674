import React, { ReactElement } from "react";
import PortalContentHeader from "../portal-content/PortalContentHeader";
import { STRINGS } from "../../../language/de_DE/strings";
import { MailBox } from "./Mailbox";
import { useNachrichten } from "../../../api/useNachrichten";
import { useKunde } from "../../../hooks/useKunde";

export const UserMessageBoxContent = (): ReactElement => {
  const kunde = useKunde();
  const { data: nachrichten } = useNachrichten(kunde.kid);
  return (
    <>
      <PortalContentHeader title={STRINGS.messageBox.header} />
      <MailBox messages={nachrichten || []} kid={kunde.kid} />
    </>
  );
};
