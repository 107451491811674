import React from "react";
import { EditableUserProfileBoolean } from "components/user-portal/user-profile/dialogs/item/EditableUserProfileItem";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";
import { KundeRequest } from "../../../../../types/backend/KundeRequest";

interface EditRisksFormProps {
  formId: string;
  kunde: KundeResponse;
  mutation: UseMutationResult<unknown, unknown, KundeRequest>;
}

const EditRisksForm: React.FC<EditRisksFormProps> = ({
  formId,
  kunde,
  mutation,
}) => {
  return (
    <Formik
      initialValues={{
        ...kunde,
        hundOderPferd: kunde?.hundOderPferd || false,
        kraftfahrzeug: kunde?.kraftfahrzeug || false,
        luftfahrzeug: kunde?.luftfahrzeug || false,
        wasserfahrzeug: kunde?.wasserfahrzeug || false,
        jagen: kunde?.jagen || false,
        oeltank: kunde?.oeltank || false,
        photovoltaikAnlage: kunde?.photovoltaikAnlage || false,
        bauenOderSanieren: kunde?.bauenOderSanieren || false,
      }}
      onSubmit={(values) => mutation.mutate(values)}
      enableReinitialize={true}
    >
      {() => {
        return (
          <Form id={formId}>
            <EditableUserProfileBoolean
              fieldName="auslandsReisen"
              labelText={STRINGS.profile.risks.foreignTravel}
            />
            <EditableUserProfileBoolean
              fieldName="hundOderPferd"
              labelText={STRINGS.profile.risks.dogOrHorse}
            />
            <EditableUserProfileBoolean
              fieldName="kraftfahrzeug"
              labelText={STRINGS.profile.risks.motorVehicle}
            />
            <EditableUserProfileBoolean
              fieldName="luftfahrzeug"
              labelText={STRINGS.profile.risks.airVehicle}
            />
            <EditableUserProfileBoolean
              fieldName="wasserfahrzeug"
              labelText={STRINGS.profile.risks.waterVehicle}
            />
            <EditableUserProfileBoolean
              fieldName="jagen"
              labelText={STRINGS.profile.risks.hunting}
            />
            <EditableUserProfileBoolean
              fieldName="oeltank"
              labelText={STRINGS.profile.risks.oilTank}
            />
            <EditableUserProfileBoolean
              fieldName="photovoltaikAnlage"
              labelText={STRINGS.profile.risks.photovoltaic}
            />
            <EditableUserProfileBoolean
              fieldName="bauenOderSanieren"
              labelText={STRINGS.profile.risks.buildOrSanitize}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditRisksForm;
