import React, { ReactElement } from "react";
import baseStyles from "../form-sections/AddContractFormBase.module.scss";
import styles from "../form-sections/AddContractFormStep2.module.scss";
import { Field, FormikProps } from "formik";
import { STRINGS } from "../../../../language/de_DE/strings";
import { AddContractFormType } from "./AddContractFormType";
import { FileDrop } from "../../../form-inputs/FileDrop/FileDrop";
import { AuszahlungEinmaligPrognoseInput } from "./inputs/AuszahlungEinmaligPrognoseInput";
import { AktuellerWertInput } from "./inputs/AktuellerWertInput";
import { BezeichnungInput } from "./inputs/BezeichnungInput";
import { EinzahlungInput } from "./inputs/EinzahlungInput";
import { SparbetragInput } from "./inputs/SparbetragInput";
import { ZielsparsummeInput } from "./inputs/ZielsparsummeInput";
import { BausparsummeInput } from "./inputs/BausparsummeInput";
import { VertragsnummerInput } from "./inputs/VertragsnummerInput";
import { IsinInput } from "./inputs/IsinInput";
import { KaufwertInput } from "./inputs/KaufwertInput";
import { VerwendungszweckSelection } from "./inputs/VerwendungszweckSelection";
import { Vertragsname } from "./inputs/VertragsnameInput";
import { BeitragInput } from "./inputs/BeitragInput";
import { BeitragIntervallInput } from "./inputs/BeitragIntervallInput";
import { VertragslaufzeitInput } from "./inputs/VertragslaufzeitInput";
import { RisikoArtSelection } from "./inputs/RisikoartSelection";
import { LeistungRenteErwerbsunfaehigkeitInput } from "./inputs/LeistungRenteErwerbsunfaehigkeitInput";
import { PflegeleistungInput } from "./inputs/PflegeleistungInput";
import { RisikoInput } from "./inputs/RisikoInput";
import { LeistungRenteBerufsunfaehigkeitInput } from "./inputs/LeistungRenteBerufsunfaehigkeitInput";
import { LeistungKapitalUnfallInput } from "./inputs/LeistungKapitalUnfallInput";
import { LeistungKapitalTodesfallInput } from "./inputs/LeistungKapitalTodesfallInput";
import { KfzBeitragKaskoInput } from "./inputs/KfzBeitragKaskoInput";
import { LeistungRentePflege } from "./inputs/LeistungRentePflegeInput";
import { GebuehrenordnungSelection } from "./inputs/GebuehrenordnungSelection";
import { KrankenzusatzversicherungCheckbox } from "./inputs/KrankenzusatzversicherungCheckbox";
import { VertragTyp } from "../../../../types/backend/VertragTyp";
import { SehhilfeSelection } from "./inputs/SehhilfeSelection";
import { KrankentagegeldInput } from "./inputs/KrankentagegeldInput";
import { StationaereUnterkunftSelection } from "./inputs/StationaereUnterkunftSelection";
import { ZahnanspruchInput } from "./inputs/ZahnersatzInput";
import { KfzHerstellerInput } from "./inputs/KfzHerstellerInput";
import { KfzBezeichnungInput } from "./inputs/KfzBezeichnungInput";
import { AuszahlungPrognoseInputGroup } from "./inputs/AuszahlungPrognoseInputGroup";

interface AddContractFormStep2Props {
  formProps: FormikProps<AddContractFormType>;
  activeStep: number;
}

export const AddContractFormStep2 = ({
  formProps,
  activeStep,
}: AddContractFormStep2Props): ReactElement => {
  const vertragTyp = formProps.values.typ;
  return (
    <div className={styles.contractBaseSection}>
      <div className={styles.contractDataContainer}>
        <h4 className={baseStyles.sectionHeader}>
          {STRINGS.addContract.step2.contractData}
        </h4>
        <div className={styles.contractData}>
          <BezeichnungInput vertragTyp={vertragTyp} />
          <VertragsnummerInput
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <Vertragsname vertragTyp={vertragTyp} activeStep={activeStep} />
          <BeitragInput vertragTyp={vertragTyp} activeStep={activeStep} />
          <BeitragIntervallInput vertragTyp={vertragTyp} />
          <VertragslaufzeitInput vertragTyp={vertragTyp} />
          <IsinInput vertragTyp={vertragTyp} />
          <KaufwertInput vertragTyp={vertragTyp} />
          <SparbetragInput vertragTyp={vertragTyp} />
          <EinzahlungInput vertragTyp={vertragTyp} />
          <ZielsparsummeInput vertragTyp={vertragTyp} />
          <BausparsummeInput vertragTyp={vertragTyp} activeStep={activeStep} />
          <AktuellerWertInput vertragTyp={vertragTyp} activeStep={activeStep} />
          <AuszahlungEinmaligPrognoseInput
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <AuszahlungPrognoseInputGroup
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <VerwendungszweckSelection
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <LeistungRenteErwerbsunfaehigkeitInput
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <LeistungRenteBerufsunfaehigkeitInput
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <RisikoArtSelection vertragTyp={vertragTyp} activeStep={activeStep} />
          <PflegeleistungInput
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <RisikoInput vertragTyp={vertragTyp} activeStep={activeStep} />
          <LeistungKapitalUnfallInput
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <LeistungKapitalTodesfallInput
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <KfzHerstellerInput vertragTyp={vertragTyp} />
          <KfzBezeichnungInput vertragTyp={vertragTyp} />
          <KfzBeitragKaskoInput
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <LeistungRentePflege
            name="leistungRentePflege1"
            pflegeStufe={1}
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <LeistungRentePflege
            name="leistungRentePflege2"
            pflegeStufe={2}
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <LeistungRentePflege
            name="leistungRentePflege3"
            pflegeStufe={3}
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <LeistungRentePflege
            name="leistungRentePflege4"
            pflegeStufe={4}
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <LeistungRentePflege
            name="leistungRentePflege5"
            pflegeStufe={5}
            vertragTyp={vertragTyp}
            activeStep={activeStep}
          />
          <KrankenzusatzversicherungFormSection vertragTyp={vertragTyp} />
        </div>
      </div>
      <div className={styles.uploadContractContainer}>
        <h4 className={baseStyles.sectionHeader}>
          {STRINGS.addContract.step2.uploadContract}
        </h4>
        <div className={styles.uploadContract}>
          <div>
            {STRINGS.addContract.step2.fileUploadText}{" "}
            <span className={styles.bold}>
              {STRINGS.addContract.step2.fileUploadTextBullets}
            </span>
          </div>
          <div>
            <Field component={FileDrop} name="files" />
          </div>
        </div>
        <div className={styles.uploadContractLater}>
          {STRINGS.addContract.step2.uploadDocumentsLater}
        </div>
      </div>
    </div>
  );
};

interface KrankenzusatzversicherungFormSectionProps {
  vertragTyp: VertragTyp | "";
}

const KrankenzusatzversicherungFormSection = ({
  vertragTyp,
}: KrankenzusatzversicherungFormSectionProps): ReactElement | null => {
  if (vertragTyp !== "KRANKENZUSATZVERSICHERUNG") return null;
  return (
    <>
      <KrankentagegeldInput vertragTyp={vertragTyp} />
      <h4 className={baseStyles.sectionHeader}>
        {STRINGS.addContract.step2.ambulant}
      </h4>
      <GebuehrenordnungSelection
        name={"ambulantGebuehrenordnung"}
        labelText={STRINGS.addContract.step2.ambulantScheduleOfFeesLabel}
        vertragTyp={vertragTyp}
      />
      <KrankenzusatzversicherungCheckbox
        name={"ambulantErstattung"}
        labelText={STRINGS.addContract.step2.ambulantRefundLabel}
        vertragTyp={vertragTyp}
      />
      <KrankenzusatzversicherungCheckbox
        name={"ambulantHeilpraktiker"}
        labelText={STRINGS.addContract.step2.ambulantNaturopathLabel}
        vertragTyp={vertragTyp}
      />
      <KrankenzusatzversicherungCheckbox
        name={"ambulantHufeland"}
        labelText={STRINGS.addContract.step2.ambulantHufelandLabel}
        vertragTyp={vertragTyp}
      />
      <KrankenzusatzversicherungCheckbox
        name={"ambulantVorsorge"}
        labelText={STRINGS.addContract.step2.ambulantCheckUpLabel}
        vertragTyp={vertragTyp}
      />
      <SehhilfeSelection vertragTyp={vertragTyp} />
      <h4 className={baseStyles.sectionHeader}>
        {STRINGS.addContract.step2.stationary}
      </h4>
      <GebuehrenordnungSelection
        name={"stationaerGebuehrenordnung"}
        labelText={STRINGS.addContract.step2.stationaryScheduleOfFeesLabel}
        vertragTyp={vertragTyp}
      />
      <StationaereUnterkunftSelection vertragTyp={vertragTyp} />
      <KrankenzusatzversicherungCheckbox
        name={"stationaerKrankenhauswahl"}
        labelText={STRINGS.addContract.step2.stationaryHospitalChoiceLabel}
        vertragTyp={vertragTyp}
      />
      <KrankenzusatzversicherungCheckbox
        name={"stationaerChefarzt"}
        labelText={STRINGS.addContract.step2.stationaryChiefDoctorLabel}
        vertragTyp={vertragTyp}
      />
      <h4 className={baseStyles.sectionHeader}>
        {STRINGS.addContract.step2.dental}
      </h4>
      <GebuehrenordnungSelection
        name={"zahnGebuehrenordnung"}
        labelText={STRINGS.addContract.step2.dentalScheduleOfFeesLabel}
        vertragTyp={vertragTyp}
      />
      <ZahnanspruchInput vertragTyp={vertragTyp} />
      <KrankenzusatzversicherungCheckbox
        name={"zahnbehandlung"}
        labelText={STRINGS.addContract.step2.dentalTreatmentLabel}
        vertragTyp={vertragTyp}
      />
      <KrankenzusatzversicherungCheckbox
        name={"zahnersatz"}
        labelText={STRINGS.addContract.step2.dentalReplacementLabel}
        vertragTyp={vertragTyp}
      />
      <KrankenzusatzversicherungCheckbox
        name={"zahnersatzOhneVorleistung"}
        labelText={
          STRINGS.addContract.step2.dentalReplacementWithoutAdvanceLabel
        }
        vertragTyp={vertragTyp}
      />
      <KrankenzusatzversicherungCheckbox
        name={"zahnimplantate"}
        labelText={STRINGS.addContract.step2.dentalImplantsLabel}
        vertragTyp={vertragTyp}
      />
      <KrankenzusatzversicherungCheckbox
        name={"zahnKeineStaffelung"}
        labelText={STRINGS.addContract.step2.dentalNoScaleLabel}
        vertragTyp={vertragTyp}
      />
      <KrankenzusatzversicherungCheckbox
        name={"zahnKieferorthopaedie"}
        labelText={STRINGS.addContract.step2.dentalOrthodonticsLabel}
        vertragTyp={vertragTyp}
      />
    </>
  );
};
