import styles from "components/molecules/InsuranceComparisonBoxItem.module.scss";

import React, { FunctionComponent } from "react";
import { Box } from "components/atoms/Box";
import { Image } from "components/atoms/Image";
import { Text } from "components/atoms/Text";

export type InsuranceComparisonBoxItemProperties = {
  number: number;
  logoSrc: string;
  logoAlt: string;
  insuranceName: string;
};

export const InsuranceComparisonBoxItem: FunctionComponent<
  InsuranceComparisonBoxItemProperties
> = ({ number, logoSrc, logoAlt, insuranceName }) => {
  return (
    <Box flex column>
      <Box flex inline>
        <Box className={styles.insuranceComparisonListBoxItemNumber}>
          {number}
        </Box>
        <Box column className={styles.insuranceComparisonListBoxItemImageBox}>
          <Image
            className={styles.insuranceComparisonListBoxItemImage}
            src={logoSrc}
            alt={logoAlt}
          />
          <Box className={styles.insuranceComparisonListBoxItemInsuranceName}>
            <Text size={"xSmall"} inline>
              {insuranceName}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
