import common from "components/form-inputs/FormInputCommons.module.scss";

import React, { ReactElement } from "react";
import clsx from "clsx";
import Select, { SingleValue } from "react-select";
import { STRINGS } from "language/de_DE/strings";
import { v4 } from "uuid";
import { Option } from "./SingleSelect";
import { FormInputLabel } from "../FormInputLabel";
import {
  FormInputCustomStyleProperties,
  FormInputProperties,
} from "../FormInputTypes";
import { FormInputSubText } from "../FormInputSubText";

const CustomizableBooleanSelect = ({
  field,
  form,
  labelText,
  helpText,
  disabled,
  tabIndex,
  placeholder,
  lightColor,
  inputClassName,
  labelClassName,
  validationClassName,
  helpTextClassName,
}: FormInputProperties & FormInputCustomStyleProperties): ReactElement => {
  const id = field.name + "-" + v4();
  const meta = form.getFieldMeta(field.name);

  const noOption: Option = {
    label: STRINGS.profile.booleanOptions.no,
    value: false,
  };
  const yesOption: Option = {
    label: STRINGS.profile.booleanOptions.yes,
    value: true,
  };
  const options = [noOption, yesOption];
  const selected = field.value ? yesOption : noOption;

  const handleChange = (newValue: SingleValue<Option | undefined>) => {
    form.setFieldValue(field.name, newValue?.value);
    return field.onChange;
  };

  return (
    <div>
      <FormInputLabel
        labelText={labelText}
        labelClassName={labelClassName}
        id={id}
        lightColor={lightColor}
      />
      <Select
        {...field}
        id={id}
        value={selected}
        onChange={(newValue) => handleChange(newValue)}
        defaultValue={selected}
        options={options}
        tabIndex={tabIndex}
        isDisabled={disabled}
        placeholder={placeholder}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
        className={clsx(common.selectInput, inputClassName)}
      />
      <FormInputSubText
        meta={meta}
        helpText={helpText}
        helpTextClassName={helpTextClassName}
        validationClassName={validationClassName}
      />
    </div>
  );
};

export const BooleanSelect = (props: FormInputProperties) => {
  return <CustomizableBooleanSelect {...props} />;
};
