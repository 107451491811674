import React, { Component } from "react";
import { Button } from "../../components/form-inputs/Button/Button";
import Page from "../../components/page/Page";
import UnstyledLink from "../../components/unstyle-link/UnstyledLink";
import { RouteUrls } from "../../config/routes";
import PageSection from "../../components/page-section/PageSection";

export default class Error404 extends Component {
  render() {
    return (
      <Page>
        <PageSection>
          <h1>404 - Seite nicht gefunden</h1>
          <h2>
            Die Seite, die Sie aufrufen wollten, konnte leider nicht gefunden
            werden.
          </h2>
          <UnstyledLink to={RouteUrls.homepage}>
            <Button primary label={"Zurück zur Startseite"} />
          </UnstyledLink>
        </PageSection>
      </Page>
    );
  }
}
