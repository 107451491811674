import styles from "components/development-hint/DevelopmentHint.module.scss";

import React, { ReactElement } from "react";
import { appConfig } from "config/appConfig";

const DevelopmentHint = (): ReactElement | null => {
  if (appConfig.developmentHint !== false) {
    return <div className={styles.warning}>{appConfig.developmentHint}</div>;
  }
  return null;
};

export default DevelopmentHint;
