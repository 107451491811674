import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import DescriptionImage0 from "gfx/images/insurance-images/tierkranken_0.jpg";
import DescriptionImage1 from "gfx/images/insurance-images/tierkranken_1.jpg";
import DescriptionImage2 from "gfx/images/insurance-images/tierkranken_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Tierkranken­versicherung";

export const tierkrankenProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: DescriptionImage0,
  introduction:
    "Die Krankenversicherung für Tiere übernimmt bestimmte Kosten beim Tierarzt oder in der Tierklinik, damit dein Tier schnell wieder gesund wird. Gleichzeitig sichert sie so deine finanzielle Sicherheit.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title: "... du häufige Routinebesuche beim Tierarzt durchführen musst.",
      text: icon1,
    },
    {
      title: "... deinem (Haus-)Tier eine teure Operation bevorsteht.",
      text: icon1,
    },
    {
      title: "... du lange Wartezeiten beim Tierarzt umgehen möchtest.",
      text: icon1,
    },
    {
      title:
        "... du zusätzliche unvorhersehbare Kosten rund um dein Tier vermeiden möchtest.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine " + productName + " ?",
      paragraphs: [
        "Eine Tierkrankenversicherung schützt dich vor unvorhersehbaren Kosten und versorgt dein Tier, je nach Police, mit einem Paket von Komplettschutz bis hin zu einer Versicherung für einzelne Operationen.",
        "Dabei nimmt eine Tierkrankenversicherung dem Halter die Last, sich zwischen der finanziellen Belastung, aufgrund von teuren Operationen und dem Wohlbefinden des eigenen (Haus-)Tiers entscheiden zu müssen.",
        "Alternativ kann es ratsam sein, sich mit einer Tier-OP-Versicherung auseinanderzusetzen, um Kosten zu sparen.",
      ],
      image: DescriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + " ?",
      paragraphs: [
        "Eine Tierkrankenversicherung ist für jeden Tierhalter eine sinnvolle Absicherung, da sich die Versicherung direkt an den anfallenden Tierarztkosten beteiligt.",
        "Nicht nur langjährige Krankheitsbilder, sondern auch eine gründliche Vorsorge kann schnell kostspielig werden.",
        "Mit einer Tierkrankenversicherung leistest du Abhilfe!",
      ],

      image: DescriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Ambulante und stationäre Heilbehandlungen",
        "Vorsorgemaßnahmen",
        "Kastrationen",
        "Operationen",
        "Vorübergehende Auslandsaufenthalte",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Gewisse, rassenspezifische Erbkrankheiten",
        "Unterbringungskosten",
        "Physiotherapie nach Operationen",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],
  link: RouteUrls.tierkrankenversicherung,
};
