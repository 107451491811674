import React, { ReactElement } from "react";
import { createOptions } from "../../../../../utils/formInputUtils";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { AddContractSelection } from "./base/AddContractSelection";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { VertragTyp } from "../../../../../types/backend/VertragTyp";
import { validateRequiredOnTypes } from "./util/validationUtil";
import { useFormikContext } from "formik";
import { AddContractFormType } from "../AddContractFormType";
import { shouldDisplayField } from "./util/displayInputUtil";

const options = createOptions(STRINGS.vertrag.riskTypes);

const requiredTypes: VertragTyp[] = ["HAFTPFLICHTVERSICHERUNG"];

export const RisikoArtSelection = ({
  vertragTyp,
  activeStep,
}: AddContractInputBaseType): ReactElement | null => {
  const { values } = useFormikContext<AddContractFormType>();

  const validate = () => {
    if (activeStep !== 2) return;
    return validateRequiredOnTypes(
      requiredTypes,
      STRINGS.addContract.step2.riskTypeError,
      vertragTyp,
      values.risikoart
    );
  };

  if (!shouldDisplayField(vertragTyp, requiredTypes)) return null;
  return (
    <AddContractSelection
      name="risikoart"
      options={options}
      labelText={STRINGS.addContract.step2.riskTypeLabel}
      validate={validate}
    />
  );
};
