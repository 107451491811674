import styles from "components/navigation-bar/DesktopNavigation.module.scss";

import React, { ReactElement } from "react";
import { Box } from "components/atoms/Box";
import LogoImage from "components/logo/LogoImage";
import { isActivePage } from "components/navigation-bar/isActivePage";
import NavigationItemButton from "components/navigation-bar/NavigationItemButton";
import NavigationItemLink from "components/navigation-bar/NavigationItemLink";
import NavigationItemLogoutButton from "components/navigation-bar/NavigationItemLogoutButton";
import NavigationItemUserIcon from "components/navigation-bar/NavigationItemUserIcon";
import { navigationItems } from "config/navigationItems";
import { RouteUrls } from "config/routes";
import useUserState from "hooks/useUserState";
import { Link } from "react-router-dom";

const DesktopNavigation = (): ReactElement => {
  const { isLoggedIn } = useUserState();

  return (
    <nav className={styles.desktopNavigation}>
      <Box flex className={styles.desktopNavigationBox}>
        <Box className={styles.logo}>
          <Link to={RouteUrls.homepage}>
            <LogoImage size={"small"} color={"white"} />
          </Link>
        </Box>

        <Box className={styles.navigation}>
          {navigationItems.map((link) => (
            <NavigationItemLink
              key={link.url}
              to={link.url}
              isActive={isActivePage(link.url)}
            >
              {link.title}
            </NavigationItemLink>
          ))}
        </Box>

        <Box className={styles.navigation}>
          {isLoggedIn && (
            <>
              <NavigationItemLink
                to={RouteUrls.userPortalStart}
                isActive={isActivePage(RouteUrls.userPortalStart)}
              >
                <NavigationItemUserIcon />
              </NavigationItemLink>

              <NavigationItemLogoutButton />
            </>
          )}

          {!isLoggedIn && (
            <NavigationItemButton
              to={RouteUrls.logIn}
              title="Login"
              buttonClass={styles.desktopNavigationButton}
            />
          )}
        </Box>
      </Box>
    </nav>
  );
};

export default DesktopNavigation;
