import React, { ReactElement } from "react";
import { useFormikContext } from "formik";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { VertragTyp } from "../../../../../types/backend/VertragTyp";
import { validateNumber, validateRequiredOnTypes } from "./util/validationUtil";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { AddContractFormType } from "../AddContractFormType";
import { shouldDisplayField } from "./util/displayInputUtil";
import { AddContractNumberInput } from "./base/AddContractNumberInput";

const requiredTypes: VertragTyp[] = ["UNFALLVERSICHERUNG"];

export const LeistungKapitalUnfallInput = ({
  vertragTyp,
  activeStep,
}: AddContractInputBaseType): ReactElement | null => {
  const { values } = useFormikContext<AddContractFormType>();

  const validate = () => {
    if (activeStep !== 2) return;
    return (
      validateRequiredOnTypes(
        requiredTypes,
        STRINGS.addContract.step2.paymentOnAccidentError,
        vertragTyp,
        values.leistungKapitalUnfall
      ) || validateNumber(values.leistungKapitalUnfall)
    );
  };

  if (!shouldDisplayField(vertragTyp, requiredTypes)) return null;
  return (
    <AddContractNumberInput
      name="leistungKapitalUnfall"
      labelText={STRINGS.addContract.step2.paymentOnAccidentLabel}
      validate={validate}
    />
  );
};
