import { VertragTyp } from "../../../../../../types/backend/VertragTyp";

export const shouldDisplayField = (
  vertragTyp?: VertragTyp | "",
  displayOn?: VertragTyp[]
): boolean => {
  if (!vertragTyp) return false;
  if (!displayOn) return true;
  return (displayOn || []).includes(vertragTyp);
};
