export const NATIONS: Record<string, string> = {
  DEUTSCH: "deutsch",
  ABCHASISCH: "abchasisch",
  AFGHANISCH: "afghanisch",
  AEGYPTISCH: "ägyptisch",
  ALBANISCH: "albanisch",
  ALGERISCH: "algerisch",
  ANDORRISCH: "andorrisch",
  ANGOLISCH: "angolisch",
  ANTIGUISCH: "antiguisch",
  ARGENTINISCH: "argentinisch",
  ARMENISCH: "armenisch",
  ASERBAIDSCHANIS: "aserbaidschanis",
  AETHIOPISCH: "äthiopisch",
  AUSTRALISCH: "australisch",
  BAHAMISCH: "bahamisch",
  BAHRAINISCH: "bahrainisch",
  BANGLADESCHISCH: "bangladeschisch",
  BELGISCH: "belgisch",
  BELIZISCH: "belizisch",
  BENINISCH: "beninisch",
  BHUTANISCH: "bhutanisch",
  BOLIVISCH: "bolivisch",
  BOSNISCH: "bosnisch",
  BRASILIANISCH: "brasilianisch",
  BRITISCH: "britisch",
  BULGARISCH: "bulgarisch",
  CHILENISCH: "chilenisch",
  CHINESISCH: "chinesisch",
  DAENISCH: "daenisch",
  DOMINIKANISCH: "dominikanisch",
  ECUADORIANISCH: "ecuadorianisch",
  ESTLAENDISCH: "estländisch",
  FINNISCH: "finnisch",
  FRANZOESISCH: "französisch",
  GABUNISCH: "gabunisch",
  GAMBIANISCH: "gambianisch",
  GEORGISCH: "georgisch",
  GHANISCH: "ghanisch",
  GRIECHISCH: "griechisch",
  HONGKONG_CHIN: "hongkong-chin",
  INDISCH: "indisch",
  IRAKISCH: "irakisch",
  IRANISCH: "iranisch",
  IRISCH: "irisch",
  ISLAENDISCH: "isländisch",
  ISRAELISCH: "israelisch",
  ITALIENISCH: "italienisch",
  JAMAIKANISCH: "jamaikanisch",
  JAPANISCH: "japanisch",
  JEMENITISCH: "jemenitisch",
  JORDANISCH: "jordanisch",
  JUGOSLAWISCH: "jugoslawisch",
  KAMBOTSCHANISCH: "kambotschanisch",
  KAMERUNISCH: "kamerunisch",
  KANADISCH: "kanadisch",
  KASACHISCH: "kasachisch",
  KENIANISCH: "kenianisch",
  KOLUMBIANISCH: "kolumbianisch",
  KOREANISCH: "koreanisch",
  KOSOVARISCH: "kosovarisch",
  KROATISCH: "kroatisch",
  KUBANISCH: "kubanisch",
  KUWAITISCH: "kuwaitisch",
  LETTISCH: "lettisch",
  LIBANESISCH: "libanesisch",
  LICHTENSTEINISCH: "lichtensteinisch",
  LITAUISCH: "litauisch",
  LUXEMBURGISCH: "luxemburgisch",
  MALTESISCH: "maltesisch",
  MAROKKANISCH: "marokkanisch",
  MAZEDONISCH: "mazedonisch",
  MEXIKANISCH: "mexikanisch",
  MOLDAWISCH: "moldawisch",
  MONEGASSISCH: "monegassisch",
  NAMIBIANISCH: "namibianisch",
  NEPALESISCH: "nepalesisch",
  NEUSEELAENDISCH: "neuseeländisch",
  NIEDERLAENDISCH: "niederländisch",
  NIGERIANISCH: "nigerianisch",
  NORWEGISCH: "norwegisch",
  OESTERREICHISCH: "österreichisch",
  PAKISTANISCH: "pakistanisch",
  PALAESTINENSISCH: "palaestinensisch",
  PARAGUAYISCH: "paraguayisch",
  PERUANISCH: "peruanisch",
  PHILIPPINISCH: "philippinisch",
  POLNISCH: "polnisch",
  PORTUGISISCH: "portugisisch",
  RUMAENISCH: "rumänisch",
  RUSSISCH: "russisch",
  SAUDIARABISCH: "saudiarabisch",
  SCHWEDISCH: "schwedisch",
  SCHWEIZERISCH: "schweizerisch",
  SENEGALESISCH: "senegalesisch",
  SERBISCH: "serbisch",
  SLOWAKISCH: "slowakisch",
  SLOWENISCH: "slowenisch",
  SOMALISCH: "somalisch",
  SPANISCH: "spanisch",
  SUEDAFRIKANISCH: "südafrikanisch",
  SYRISCH: "syrisch",
  THAILAENDISCH: "thailändisch",
  TSCHECHISCH: "tschechisch",
  TUNESISCH: "tunesisch",
  TUERKISCH: "türkisch",
  UKRAINISCH: "ukrainisch",
  UNGARISCH: "ungarisch",
  URUGUAYISCH: "uruguayisch",
  US_AMERIKANISCH: "us-amerikanisch",
};
