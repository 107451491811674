import styles from "components/molecules/InsuranceCompletionSteps.module.scss";

import React, { Fragment, FunctionComponent } from "react";
import clsx from "clsx";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";

export type InsuranceCompletionSteps = {
  names: Array<string>;
};

export type InsuranceCompletionStepsProperties = {
  steps: InsuranceCompletionSteps;
  currentStep: number;
  dividerClass: string;
};

export const InsuranceCompletionSteps: FunctionComponent<
  InsuranceCompletionStepsProperties
> = ({ steps, currentStep, dividerClass }) => {
  return (
    <Box flex inline className={styles.completionStepBox}>
      {steps.names.map((step, index) => (
        <Fragment key={index}>
          <Box flex column>
            <Box
              className={clsx([
                styles.circle,
                index + 2 <= currentStep && styles.done,
              ])}
            />
            <Text inline size={"medium"} className={styles.textSpan}>
              {step}
            </Text>
          </Box>
          {steps.names.length !== index + 1 && <Box className={dividerClass} />}
        </Fragment>
      ))}
    </Box>
  );
};
