import axios from "axios";
import api from "api/index";
import { appConfig } from "config/appConfig";
import { HausratVersicherungComparisonRequest } from "types/insuranceComparisonData/Hausratversicherug/HausratVersicherungComparisonRequest";
import { HausratVersicherungComparisonResponse } from "types/insuranceComparisonData/Hausratversicherug/HausratVersicherungComparisonResponse";
import { isOk } from "utils/axiosHelpers";

export const fetchHausratComparisonResult = async <
  R = HausratVersicherungComparisonResponse
>(
  requestData: HausratVersicherungComparisonRequest
): Promise<R> => {
  const url = `${appConfig.apiUrl}/hausrat?withDocuments=false`;
  const header = await api.getHeaderWithAuthorization();
  const result = await axios.post<R>(url, requestData, {
    headers: header,
    validateStatus: isOk,
  });

  return result.data;
};
