import styles from "pages/hausratversicherung/HausratversicherungVergleichAbschlussStep2.module.scss";

import React, { FunctionComponent } from "react";
import Page from "components/page/Page";
import { HausratversicherungVergleichAbschlussStep2Template } from "components/templates/HausratversicherungVergleichAbschlussStep2Template";
import { selectedInsuranceMock } from "mockData/insuranceBadgeDataMock";

export const HausratversicherungVergleichAbschlussStep2: FunctionComponent =
  () => {
    return (
      <Page contentClassName={styles.pageWrapper}>
        <HausratversicherungVergleichAbschlussStep2Template
          selectedInsurance={selectedInsuranceMock}
        />
      </Page>
    );
  };
