import styles from "./PageSection.module.scss";

import React, { FunctionComponent, ReactElement, ReactNode } from "react";
import clsx from "clsx";

export type Props = {
  children: ReactNode;
  background?: "white" | "grey" | "blue";
  fullWidthClassName?: string;
  contentClassName?: string;
};

const PageSection: FunctionComponent<Props> = ({
  children,
  background,
  fullWidthClassName,
  contentClassName,
}): ReactElement => (
  <div
    className={clsx(styles.fullHorizontalWrapper, fullWidthClassName, {
      [styles.greyBackground]: background === "grey",
      [styles.blueBackground]: background === "blue",
    })}
  >
    <section className={clsx(styles.section, contentClassName)}>
      {children}
    </section>
  </div>
);

export default PageSection;
