import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/erntehelfer_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/erntehelfer_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/erntehelfer_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

const productName = "Erntehelferkrankenversicherung";

export const erntehelferkrankenversicherungProductTree: ProductTreeInsuranceType =
  {
    title: productName,
    image: descriptionImage0,
    introduction:
      "Agrarbetriebe, die keine Lust auf finanzielle Experimente haben, können ihre Erntehelfer versichern, um sowohl nachhaltige finanzielle Risiken zu vermeiden als auch eine medizinische Grundversorgung gewährleisten zu können.",

    headerButtons: [
      {
        label: "Vergleichen",
        link: RouteUrls.erntehelferkrankenVergleich,
      },
      { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
    ],

    insuranceSituation: [
      {
        title:
          "... ein Erntehelfer starke Zahnschmerzen hat und schmerzlindernde Behandlungen erforderlich sind.",
        text: icon1,
      },
      {
        title:
          "... sich einer deiner Erntehelfer verletzt und eine stationäre Behandlung notwendig wird.",
        text: icon1,
      },
      {
        title:
          "... eine Erntehelferin schwanger wird und Kosten für eine medizinische Grundversorgung entstehen.",
        text: icon1,
      },
      {
        title:
          "... bei einer saisonalen Arbeitskraft Rehabilitationsmaßnahmen notwendig werden.",
        text: icon1,
      },
      {
        title:
          "... Erntehelfer aufgrund eines traumatischen Ereignisses psychotherapeutische Behandlungen brauchen.",
        text: icon1,
      },
    ],

    longDescriptions: [
      {
        title: "Was ist eine  " + productName + "?",
        paragraphs: [
          "Die Erntehelferversicherung zählt in Deutschland zu den Nischen-Versicherungen. Sie fokussiert sich auf die Absicherung der Erntehelfer und wird durch den jeweiligen Agrarbetrieb für die saisonalen Arbeitskräfte abgeschlossen.",
          "Für Arbeitnehmer, die eine saisonale Hilfstätigkeit in der Landwirtschaft ausüben und im Anschluss daran wieder zurück in ihr Heimatland gehen, ist keine Arbeitserlaubnis für die Ausübung einer Saisontätigkeit in der Land- und Forstwirtschaft mehr notwendig. Agrarbetriebe können als Folge Erntehelfer aus dem Ausland einfach für die Unterstützung in der Erntezeit einsetzen – diese sind jedoch oftmals nach deutschem Standard nicht ausreichend versichert.",
          "Wenn eine Arbeitskraft erkranken sollte und eine ambulante oder sogar stationäre Behandlung benötigt, können die Kosten ohne entsprechende Versicherung auf dich als Arbeitgeber zurückfallen. Um dich davor zu bewahren, empfehlen wir dir den Abschluss einer Krankenversicherung für Erntehelfer",
        ],
        image: descriptionImage1,
      },
      {
        title: "Wer braucht eine " + productName + "?",
        paragraphs: [
          "Erntehelferversicherungen sind für Agrarbetriebe zu empfehlen, welche auf Erntehelfer setzen. Aufgrund der oftmals fehlenden Krankenversicherung der saisonalen Arbeitskräfte aus dem Ausland, müssen die Betriebe für mögliche medizinische Behandlungen aufkommen.",
          "Da die Erntehelferversicherung eine kostengünstige Alternative für Agrarbetriebe mit Erntehelfern darstellt und die finanziellen Unwägbarkeiten planbar macht, empfehlen wir diese Versicherung allen betroffenen Agrarbetrieben.",
        ],
        image: descriptionImage2,
      },
    ],
    link: RouteUrls.erntehelferkrankenversicherung,
  };
