import backpackIcon from "gfx/images/icons/backpack.svg";
import boyIcon from "gfx/images/icons/boy.svg";
import doctorIcon from "gfx/images/icons/doctor.svg";
import fatherIcon from "gfx/images/icons/father.svg";
import homeIcon from "gfx/images/icons/home.svg";
import insuranceIcon from "gfx/images/icons/insurance.svg";
import oldManIcon from "gfx/images/icons/old-man.svg";
import { kfzProductTree } from "./products/kfz-versicherung";
import { hausratProductTree } from "./products/hausrat-versicherung";
import { wohngebaeudeProductTree } from "./products/wohngebaeude-versicherung";
import { privatkunstProductTree } from "./products/privatkunst-versicherung";
import { privathaftpflichtProductTree } from "./products/privathaftpflicht-versicherung";
import { berufshaftpflichtProductTree } from "./products/berufshaftpflicht-versicherung";
import { bauherrenhaftpflichtProductTree } from "./products/bauherrenhaftpflicht-versicherung";
import { hausgrundbesitzerhaftpflichtProductTree } from "./products/hausgrundbesitzerhaftpflicht-versicherung";
import { gewaesserschadenhaftpflichtProductTree } from "./products/gewaesserschadenhaftpflicht-versicherung";
import { tierhalterhaftpflichtProductTree } from "./products/tierhalterhaftpflicht-versicherung";
import { jagdhaftpflichtProductTree } from "./products/jagdhaftpflicht-versicherung";
import { motorradProductTree } from "./products/motorrad-versicherung";
import { mopedrollerProductTree } from "./products/moped-roller-versicherung";
import { fahrradProductTree } from "./products/fahrrad-versicherung";
import { auslandsreisekrankenProductTree } from "./products/auslandsreisekranken-versicherung";
import { reiseruecktrittProductTree } from "./products/reiseruecktritt-versicherung";
import { reisegepaeckProductTree } from "./products/reisegepaeck-versicherung";
import { langzeitreiseProductTree } from "./products/langzeitreise-versicherung";
import { langzeitreisekrankenProductTree } from "./products/langzeitreisekranken-versicherung";
import { langzeitreisehaftpflichtProductTree } from "./products/langzeitreisehaftpflicht-versicherung";
import { langzeitreiseunfallProductTree } from "./products/langzeitreiseunfall-versicherung";
import { tierkrankenProductTree } from "./products/tierkranken-versicherung";
import { rechtsschutzProductTree } from "./products/rechtsschutz-versicherung";
import { gesetzlichekrankenProductTree } from "./products/gesetzlichekranken-versicherung";
import { privatekrankenProductTree } from "./products/privatekranken-versicherung";
import { pflegepflichtProductTree } from "./products/pflegepflicht-versicherung";
import { pflegezusatzProductTree } from "./products/pflegezusatz-versicherung";
import { krankenhaustagegeldProductTree } from "./products/krankenhaustagegeld-versicherung";
import { krankenhauszusatzProductTree } from "./products/krankenhauszusatz-versicherung";
import { krankentagegeldProductTree } from "./products/krankentagegeld-versicherung";
import { berufsunfaehigkeitProductTree } from "./products/berufsunfaehigkeit-versicherung";
import { dienstunfaehigkeitProductTree } from "./products/dienstunfaehigkeit-versicherung";
import { erwerbsunfaehigkeitProductTree } from "./products/erwerbsunfaehigkeit-versicherung";
import { invaliditaetProductTree } from "./products/invaliditaet-versicherung";
import { risikolebenProductTree } from "./products/risikoleben-versicherung";
import { unfallProductTree } from "./products/unfall-versicherung";
import { sterbegeldProductTree } from "./products/sterbegeld-versicherung";
import { altersvorsorgeProductTree } from "./products/altersvorsorge";
import { privaterentenProductTree } from "./products/privaterenten-versicherung";
import { kapitallebenProductTree } from "./products/kapitalleben-versicherung";
import { riesterrenteProductTree } from "./products/riesterrente";
import { rueruprenteProductTree } from "./products/rueruprente";
import { startkapitalausbildungProductTree } from "./products/startkapitalausbildung";
import { betreiberhaftpflichtProductTree } from "./products/betreiberhaftpflicht-versicherung";
import { luftfahrzeughaftpflichtProductTree } from "./products/luftfahrzeughaftpflicht-versicherung";
import { wasserfahrzeughaftpflichtProductTree } from "./products/wasserfahrzeughaftpflicht-versicherung";
import { pferdehaftpflichtProductTree } from "./products/pferdehaftpflicht-versicherung";
import { hundehaftpflichtProductTree } from "./products/hundehaftpflicht-versicherung";
import { drohnenversicherungProductTree } from "./products/drohnen-versicherung";
import { aufbauliquiditaetsreserveProductTree } from "./products/aufbauliquiditaetsreserve";
import { ueberschuldungsrisikoProductTree } from "./products/ueberschuldungsrisiko";
import { zinsaenderungsrisikoProductTree } from "./products/zinsaenderungsrisiko";
import { instandhaltungimmoProductTree } from "./products/instandhaltungimmo";
import { eigenkapitalimmofinanzierungProductTree } from "./products/eigenkapitalimmofinanzierung";
import { ersatzinvestitionsachwerteProductTree } from "./products/ersatzinvestitionsachwerte";
import { investitionswunschProductTree } from "./products/investitionswunsch";
import { erntehelferkrankenversicherungProductTree } from "./products/erntehelfer-versicherung";
import { hochzeitsversicherungProductTree } from "./products/hochzeit-versicherung";
import { mietkautionsversicherungProductTree } from "./products/mietkaution-versicherung";

import {
  InsuranceNames,
  ProductTreeInsuranceType,
  ProductTreeType,
} from "./productTreeDefinition";

export const InsuranceProducts: Record<
  InsuranceNames,
  ProductTreeInsuranceType
> = {
  [InsuranceNames.HAUSRATVERSICHERUNG]: hausratProductTree,
  [InsuranceNames.WOHNGEBAEUDEVERSICHERUNG]: wohngebaeudeProductTree,
  [InsuranceNames.KUNSTVERSICHERUNG_PRIVAT]: privatkunstProductTree,

  [InsuranceNames.PRIVAT_HAFTPFLICHT]: privathaftpflichtProductTree,
  [InsuranceNames.BERUFSHAFTPFLICHT]: berufshaftpflichtProductTree,
  [InsuranceNames.BAUHERREN_HAFTPFLICHT]: bauherrenhaftpflichtProductTree,
  [InsuranceNames.HAUS_GRUNDBESITZER_HAFTPFLICHT]:
    hausgrundbesitzerhaftpflichtProductTree,
  [InsuranceNames.GEWAESSERSCHADEN_HAFTPFLICHT]:
    gewaesserschadenhaftpflichtProductTree,
  [InsuranceNames.TIERHALTER_HAFTPFLICHT]: tierhalterhaftpflichtProductTree,
  [InsuranceNames.JAGD_HAFTPFLICHT]: jagdhaftpflichtProductTree,
  [InsuranceNames.BETREIBER_HAFTPFLICHT]: betreiberhaftpflichtProductTree,
  [InsuranceNames.LUFTFAHRZEUG_HAFTPFLICHT]: luftfahrzeughaftpflichtProductTree,
  [InsuranceNames.WASSERFAHRZEUG_HAFTPLICHT]:
    wasserfahrzeughaftpflichtProductTree,

  [InsuranceNames.KFZ_VERSICHERUNG]: kfzProductTree,
  [InsuranceNames.MOTORRAD_MOPED_VERSICHERUNG]: motorradProductTree,
  [InsuranceNames.MOPED_ROLLER_VERSICHERUNG]: mopedrollerProductTree,
  [InsuranceNames.FAHRRADVERSICHERUNG]: fahrradProductTree,

  [InsuranceNames.AUSLANDSREISE_KRANKENVERSICHERUNG]:
    auslandsreisekrankenProductTree,
  [InsuranceNames.REISERUECKTRITTVERSICHERUNG]: reiseruecktrittProductTree,
  [InsuranceNames.REISEGEPAECKVERSICHERUNG]: reisegepaeckProductTree,

  [InsuranceNames.LANGZEITREISE_VERSICHERUNG]: langzeitreiseProductTree,
  [InsuranceNames.LANGZEITREISE_KRANKENVERSICHERUNG]:
    langzeitreisekrankenProductTree,
  [InsuranceNames.LANGZEITREISE_HAFTPFLICHTVERSICHERUNG]:
    langzeitreisehaftpflichtProductTree,
  [InsuranceNames.LANGZEITREISE_UNFALLVERSICHERUNG]:
    langzeitreiseunfallProductTree,
  [InsuranceNames.ERNTEHELFERKRANKEN_VERSICHERUNG]:
    erntehelferkrankenversicherungProductTree,

  [InsuranceNames.TIERKRANKENVERSICHERUNG]: tierkrankenProductTree,
  [InsuranceNames.RECHTSSCHUTZVERSICHERUNG]: rechtsschutzProductTree,

  [InsuranceNames.GESETZLICHE_KRANKENVERSICHERUNG]:
    gesetzlichekrankenProductTree,
  [InsuranceNames.PRIVATE_KRANKENVERSICHERUNG]: privatekrankenProductTree,
  [InsuranceNames.PFLEGEPFLICHTVERSICHERUNG]: pflegepflichtProductTree,

  [InsuranceNames.PFLEGEZUSATZVERSICHERUNG]: pflegezusatzProductTree,
  [InsuranceNames.KRANKENHAUS_TAGEGELD]: krankenhaustagegeldProductTree,
  [InsuranceNames.KRANKENHAUS_ZUSATZ]: krankenhauszusatzProductTree,
  [InsuranceNames.KRANKEN_TAGEGELD]: krankentagegeldProductTree,

  [InsuranceNames.BERUFSUNFAEHIGKEITS_VERSICHERUNG]:
    berufsunfaehigkeitProductTree,
  [InsuranceNames.DIENSTUNFAEHIGKEITS_VERSICHERUNG]:
    dienstunfaehigkeitProductTree,
  [InsuranceNames.ERWERBSUNFAEHIGKEITS_VERSICHERUNG]:
    erwerbsunfaehigkeitProductTree,
  [InsuranceNames.INVALDITAETUNFAEHIGKEITS_VERSICHERUNG]:
    invaliditaetProductTree,

  [InsuranceNames.RISIKOLEBENSVERSICHERUNG]: risikolebenProductTree,
  [InsuranceNames.UNFALLVERSICHERUNG]: unfallProductTree,
  [InsuranceNames.STERBEGELDVERSICHERUNG]: sterbegeldProductTree,

  [InsuranceNames.ALTERSVORSORGE]: altersvorsorgeProductTree,
  [InsuranceNames.PRIVATE_RENTENVERSICHERUNG]: privaterentenProductTree,
  [InsuranceNames.KAPITAL_LEBENSVERSICHERUNG]: kapitallebenProductTree,
  [InsuranceNames.RIESTER_RENTE]: riesterrenteProductTree,
  [InsuranceNames.RUERUP_RENTE]: rueruprenteProductTree,

  [InsuranceNames.STARTKAPITAL_AUSBILDUNG]: startkapitalausbildungProductTree,

  [InsuranceNames.PFERDE_HAFTPFLICHT]: pferdehaftpflichtProductTree,
  [InsuranceNames.HUNDE_HAFTPFLICHT]: hundehaftpflichtProductTree,

  [InsuranceNames.DROHNEN_VERSICHERUNG]: drohnenversicherungProductTree,
  [InsuranceNames.HOCHZEITS_VERSICHERUNG]: hochzeitsversicherungProductTree,
  [InsuranceNames.MIETKAUTIONS_VERSICHERUNG]:
    mietkautionsversicherungProductTree,

  [InsuranceNames.AUFBAU_LIQUIDITAETSRESERVE]:
    aufbauliquiditaetsreserveProductTree,
  [InsuranceNames.UEBERSCHULDUNGS_RISIKO]: ueberschuldungsrisikoProductTree,
  [InsuranceNames.ZINSAENDERUNGS_RISIKO]: zinsaenderungsrisikoProductTree,
  [InsuranceNames.INSTANDHALTUNG_IMMO]: instandhaltungimmoProductTree,
  [InsuranceNames.EIGENKAPITAL_IMMO_FINANZIERUNG]:
    eigenkapitalimmofinanzierungProductTree,
  [InsuranceNames.ERSATZINVESTITION_SACHWERTE]:
    ersatzinvestitionsachwerteProductTree,
  [InsuranceNames.INVESTITIONS_WUNSCH]: investitionswunschProductTree,
};
export type ProductTreeLeafType = {
  icon: string;
  title: string;
  options: {
    icon: string;
    title: string;
    insurances: ProductTreeInsuranceType[];
  }[];
};

export const productTree: Record<ProductTreeType, ProductTreeLeafType> = {
  [ProductTreeType.HAUS_EIGENTUM_UND_FREIZEIT]: {
    icon: boyIcon,
    title: "Haus, Eigentum und Mobilität",
    options: [
      {
        icon: insuranceIcon,
        title: "Haus und Wohnung",
        insurances: [
          InsuranceProducts.HAUSRATVERSICHERUNG,
          InsuranceProducts.WOHNGEBAEUDEVERSICHERUNG,
          InsuranceProducts.KUNSTVERSICHERUNG_PRIVAT,
        ],
      },
      {
        icon: oldManIcon,
        title: "Haftpflicht",
        insurances: [
          InsuranceProducts.PRIVAT_HAFTPFLICHT,
          InsuranceProducts.BERUFSHAFTPFLICHT,
          InsuranceProducts.BAUHERREN_HAFTPFLICHT,
          InsuranceProducts.HAUS_GRUNDBESITZER_HAFTPFLICHT,
          InsuranceProducts.TIERHALTER_HAFTPFLICHT,
          InsuranceProducts.JAGD_HAFTPFLICHT,
          InsuranceProducts.GEWAESSERSCHADEN_HAFTPFLICHT,
        ],
      },
      {
        icon: fatherIcon,
        title: "KFZ und Mobilität",
        insurances: [
          InsuranceProducts.KFZ_VERSICHERUNG,
          InsuranceProducts.MOTORRAD_MOPED_VERSICHERUNG,
          InsuranceProducts.MOPED_ROLLER_VERSICHERUNG,
          InsuranceProducts.FAHRRADVERSICHERUNG,
        ],
      },
      {
        icon: backpackIcon,
        title: "Rechtsschutz",
        insurances: [InsuranceProducts.RECHTSSCHUTZVERSICHERUNG],
      },
    ],
  },
  [ProductTreeType.GESUNDHEIT_PFLEGE_EXISTENZ]: {
    icon: doctorIcon,
    title: "Gesundheit, Pflege, Existenz",
    options: [
      {
        icon: oldManIcon,
        title: "Kranken- und Pflegeversicherung",
        insurances: [
          InsuranceProducts.GESETZLICHE_KRANKENVERSICHERUNG,
          InsuranceProducts.PFLEGEZUSATZVERSICHERUNG,
          InsuranceProducts.KRANKENHAUS_TAGEGELD,
          InsuranceProducts.KRANKEN_TAGEGELD,
        ],
      },
      {
        icon: backpackIcon,
        title: "Berufs- und Existenzsicherung",
        insurances: [
          InsuranceProducts.BERUFSUNFAEHIGKEITS_VERSICHERUNG,
          InsuranceProducts.DIENSTUNFAEHIGKEITS_VERSICHERUNG,
          InsuranceProducts.RISIKOLEBENSVERSICHERUNG,
          InsuranceProducts.UNFALLVERSICHERUNG,
          InsuranceProducts.STERBEGELDVERSICHERUNG,
        ],
      },
    ],
  },
  [ProductTreeType.VORSORGE_UND_RENTE]: {
    icon: backpackIcon,
    title: "Rente und Vorsorge",
    options: [
      {
        icon: homeIcon,
        title: "Altersvorsorge",
        insurances: [
          InsuranceProducts.PRIVATE_RENTENVERSICHERUNG,
          InsuranceProducts.KAPITAL_LEBENSVERSICHERUNG,
          InsuranceProducts.RIESTER_RENTE,
          InsuranceProducts.RUERUP_RENTE,
        ],
      },
      {
        icon: homeIcon,
        title: "Vorsorge",
        insurances: [InsuranceProducts.STARTKAPITAL_AUSBILDUNG],
      },
    ],
  },
  [ProductTreeType.REISE_TIER_UND_FREIZEIT]: {
    icon: backpackIcon,
    title: "Reise, Tiere und Freizeit",
    options: [
      {
        icon: backpackIcon,
        title: "Urlaub und Reisen",
        insurances: [
          InsuranceProducts.AUSLANDSREISE_KRANKENVERSICHERUNG,
          InsuranceProducts.REISERUECKTRITTVERSICHERUNG,
          InsuranceProducts.REISEGEPAECKVERSICHERUNG,
        ],
      },
      {
        icon: backpackIcon,
        title: "Langzeitreisen",
        insurances: [
          InsuranceProducts.LANGZEITREISE_KRANKENVERSICHERUNG,
          InsuranceProducts.ERNTEHELFERKRANKEN_VERSICHERUNG,
        ],
      },
      {
        icon: backpackIcon,
        title: "Tiere",
        insurances: [
          InsuranceProducts.HUNDE_HAFTPFLICHT,
          InsuranceProducts.PFERDE_HAFTPFLICHT,
          InsuranceProducts.TIERKRANKENVERSICHERUNG,
        ],
      },
      {
        icon: backpackIcon,
        title: "Sonstige",
        insurances: [
          InsuranceProducts.DROHNEN_VERSICHERUNG,
          InsuranceProducts.HOCHZEITS_VERSICHERUNG,
          InsuranceProducts.MIETKAUTIONS_VERSICHERUNG,
        ],
      },
    ],
  },
};
