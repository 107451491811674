import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { DemandCalculatorInputFormType } from "./DemandCalculatorInputFormType";
import styles from "./DemandCalculatorInputForm.module.scss";
import {
  DemandCalculatorInputField,
  DemandCalculatorSelectField,
} from "./DemandCalculatorInputField";
import { STRINGS } from "../../../language/de_DE/strings";
import { NumberField } from "../../form-inputs/NumberField/NumberField";
import { createOptions } from "../../../utils/formInputUtils";

const occupationOptions = createOptions(
  STRINGS.profile.income.occupationStateTypes
);

const specialOccupationOptions = createOptions(
  STRINGS.profile.income.specialOccupationTypes
);

const healthInsuranceOptions = createOptions(
  STRINGS.profile.income.healthInsuranceTypes
);

interface DemandCalculatorInputFormIncomeProps {
  formProps: FormikProps<DemandCalculatorInputFormType>;
}

export const DemandCalculatorInputFormIncome = ({
  formProps,
}: DemandCalculatorInputFormIncomeProps): ReactElement => {
  return (
    <div className={styles.formGrid}>
      <DemandCalculatorSelectField
        name="berufsstatus"
        label={STRINGS.demandCalculator.income.occupationState}
        options={occupationOptions}
        withNoneOption
      />
      <DemandCalculatorSelectField
        name="besondereTaetigkeit"
        label={STRINGS.demandCalculator.income.specialOccupation}
        options={specialOccupationOptions}
        withNoneOption
      />
      <DemandCalculatorInputField
        name="nettogehalt"
        label={STRINGS.demandCalculator.income.nettoIncome}
        component={NumberField}
        integersOnly
      />
      <DemandCalculatorInputField
        name="einnahmenKapital"
        label={STRINGS.demandCalculator.income.capitalIncome}
        component={NumberField}
        integersOnly
      />
      <DemandCalculatorInputField
        name="sonstigeEinnahmen"
        label={STRINGS.demandCalculator.income.otherIncome}
        component={NumberField}
        integersOnly
      />
      <DemandCalculatorInputField
        name="gesamtAusgaben"
        label={STRINGS.demandCalculator.income.totalExpenses}
        component={NumberField}
        integersOnly
      />
      <DemandCalculatorSelectField
        name="krankenversicherung"
        label={STRINGS.demandCalculator.income.healthInsurance}
        options={healthInsuranceOptions}
        withNoneOption
      />
      {formProps.values.krankenversicherung === "PRIVAT" && (
        <DemandCalculatorInputField
          name="privateKrankenversicherungBeitrag"
          label={STRINGS.demandCalculator.income.healthInsuranceFee}
          component={NumberField}
        />
      )}
      <DemandCalculatorInputField
        name="rentenVersicherungBeitrag"
        label={STRINGS.demandCalculator.income.pensionInsuranceFee}
        component={NumberField}
      />
    </div>
  );
};
