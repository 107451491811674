import styles from "components/user-portal/contract/ContractTableCells.module.scss";

import React, { FunctionComponent, ReactNode, useState } from "react";
import { faBoxArchive, faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { EditDokumenteDialog } from "components/user-portal/contract/edit-dokumente-dialog/EditDokumenteDialog";
import { Dokument } from "types/backend/Dokument";
import formatNumber from "utils/formatNumber";
import { valueOrEmpty } from "utils/formatUtils";

interface TextCellProps {
  value?: string | number | ReactNode;
  fixedWidth?: boolean;
  className?: string;
}

export const TextCell: React.FC<TextCellProps> = ({
  value,
  fixedWidth,
  className,
}) => {
  return (
    <td className={className}>
      <div
        className={
          fixedWidth
            ? clsx(styles.fixedWidth, className)
            : clsx(styles.fullWidth)
        }
      >
        {valueOrEmpty(value)}
      </div>
    </td>
  );
};

interface DokumentEnvelopeCellProps {
  dokumente: Dokument[];
  vertragId: number;
  kid: number;
}

export const DokumentBoxArchiveCell: FunctionComponent<
  DokumentEnvelopeCellProps
> = ({ dokumente, vertragId, kid }) => {
  const [open, setOpen] = useState(false);
  return (
    <td className={styles.boxArchive}>
      <div className={styles.boxArchive} onClick={() => setOpen(true)}>
        {dokumente.length > 0 ? (
          <FontAwesomeIcon icon={faBoxArchive} />
        ) : (
          <FontAwesomeIcon icon={faBoxOpen} />
        )}
      </div>
      <EditDokumenteDialog
        open={open}
        onClose={() => setOpen(false)}
        kid={kid}
        vertragId={vertragId}
        dokumente={dokumente}
      />
    </td>
  );
};

interface YieldPercentValueProps {
  value?: number;
  defaultValue?: string;
}

export const YieldPercentValue: React.FC<YieldPercentValueProps> = ({
  value,
  defaultValue,
}) => {
  if (value === undefined || value === null)
    return <span>{defaultValue || "-"}</span>;
  let colorClass = "";
  const sign = value > 0 ? "+" : "";
  if (value > 1) colorClass = styles.textPositiv;
  if (value < -1) colorClass = styles.textNegativ;

  return (
    <div className={clsx(colorClass, styles.yield)}>
      {sign}
      {formatNumber(value, 2)}&nbsp;%
    </div>
  );
};
