import { appConfig } from "config/appConfig";
import api from "../index";
import axios from "axios";
import { isOk } from "utils/axiosHelpers";
import { Nachricht } from "types/backend/Nachricht";

const fetchNachrichten = async (kid: number): Promise<Nachricht[]> => {
  const url = `${appConfig.apiUrl}/api/kunden/${kid}/nachrichten`;
  const header = await api.getHeaderWithAuthorization();
  const result = await axios.get<Nachricht[]>(url, {
    headers: header,
    validateStatus: isOk,
  });
  return result.data;
};
export default fetchNachrichten;
