import styles from "components/accordion/AccordionBox.module.scss";

import React, { ReactElement, ReactNode } from "react";
import clsx from "clsx";
import { FaMinus, FaPlus } from "react-icons/fa";

interface StyleClasses {
  accordionClass?: string;
  iconClass?: string;
  titleClass?: string;
  subtitleClass?: string;
  actionIconClass?: string;
  toggleIconClass?: string;
  contentClass?: string;
}

interface AccordionBoxProps {
  title: ReactElement | string;
  subtitle?: ReactElement | string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isOpen?: boolean;
  toggleable?: boolean;
  onHeaderClick?: () => void;
  actionIcon?: ReactNode;
  styleClasses?: StyleClasses;
  children: ReactNode;
}

const AccordionBox = ({
  title,
  subtitle,
  Icon = undefined,
  isOpen = false,
  onHeaderClick = undefined,
  toggleable = true,
  actionIcon = undefined,
  styleClasses = undefined,
  children,
}: AccordionBoxProps): ReactElement => {
  return (
    <div
      className={clsx(
        styles.accordionBox,
        styleClasses?.accordionClass,
        toggleable && styles.toggleable
      )}
      aria-expanded={isOpen}
    >
      <div className={styles.header} onClick={onHeaderClick}>
        {Icon && (
          <div>
            <Icon className={clsx(styles.icon, styleClasses?.iconClass)} />
          </div>
        )}
        <div className={styles.titleContainer}>
          <div className={clsx(styles.title, styleClasses?.titleClass)}>
            {title}
          </div>
          {subtitle && (
            <div className={clsx(styles.subtitle, styleClasses?.subtitleClass)}>
              {subtitle}
            </div>
          )}
        </div>
        <div>
          {actionIcon && (
            <div
              className={clsx(styles.actionIcon, styleClasses?.actionIconClass)}
            >
              {actionIcon}
            </div>
          )}
        </div>
        <div>
          {toggleable && (
            <div
              className={clsx(styles.toggleIcon, styleClasses?.toggleIconClass)}
            >
              {isOpen ? <FaMinus /> : <FaPlus />}
            </div>
          )}
        </div>
      </div>

      <div className={styles.contentContainer}>
        <div className={clsx(styles.content, styleClasses?.contentClass)}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AccordionBox;
