import styles from "components/user-portal/portal-content/PortalContentHeader.module.scss";

import React, { FunctionComponent } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ViewStyleToggle } from "components/molecules/ViewStyleToggle";
import UnstyledLink from "components/unstyle-link/UnstyledLink";
import appBreakpoints from "config/pageBreakpoints";
import { RouteUrls } from "config/routes";
import useMatchMedia from "hooks/useMatchMedia";

interface ToggleProps {
  onCardView: () => void;
  onListView: () => void;
}

interface HeaderActionProps {
  routeUrl: string;
  icon: IconProp;
  text: string;
}

interface PortalContentHeaderProps {
  title: string;
  toggleProps?: ToggleProps;
  headerActionProps?: HeaderActionProps;
}

const PortalContentHeader: FunctionComponent<PortalContentHeaderProps> = ({
  title,
  toggleProps,
  headerActionProps,
}) => {
  const isMobile = useMatchMedia(`(max-width: ${appBreakpoints.md})`);

  return (
    <div className={styles.heading}>
      <div className={styles.headlineContainer}>
        <h2 className={styles.headerText}>{title}</h2>
        {headerActionProps && (
          <div className={styles.iconContainer}>
            <UnstyledLink to={RouteUrls.userAddContract}>
              <p className={styles.icon}>
                <FontAwesomeIcon icon={faPlus} />{" "}
                <span className={styles.addInsuranceText}>
                  {headerActionProps.text}
                </span>
              </p>
            </UnstyledLink>
          </div>
        )}
      </div>
      {!isMobile && toggleProps && (
        <div className={styles.toggleContainer}>
          <ViewStyleToggle
            listActiveInitially={false}
            onCardView={toggleProps.onCardView}
            onListView={toggleProps.onListView}
          />
        </div>
      )}
    </div>
  );
};

export default PortalContentHeader;
