import "react-phone-number-input/style.css";
import styles from "components/organisms/LoginForm.module.scss";

import React, { ReactElement, useContext, useState } from "react";
import { Field, Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "components/atoms/Box";
import { Button } from "components/form-inputs/Button/Button";
import { TextField } from "components/form-inputs/TextField/TextField";
import { Text } from "components/atoms/Text";
import StepContainer from "components/step-wizard/components/StepContainer";
import LoginWizardContext from "components/step-wizard/login/LoginWizardContext";
import { RouteUrls } from "config/routes";
import useTabIndex from "hooks/useTabIndex";
import useUserLogin from "hooks/useUserLogin";
import useUserUpdate from "hooks/useUserUpdate";
import { STRINGS } from "language/de_DE/strings";
import isNotConfirmed from "utils/auth/isNotConfirmed";
import redirectAfterLogin from "utils/redirectAfterLogin";
import { PasswordField } from "../form-inputs/PasswordField/PasswordField";
import { useLocation } from "react-router";
import { LOGIN_REDIRECT_STATE } from "../../pages/login/LoginPage";
import * as yup from "yup";

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.login.inputEmailLabel
      )
    ),
  password: yup
    .string()
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.login.inputPasswordLabel
      )
    ),
});

interface Props {
  nextStep?: () => void;
  goToStep?: (nr: number) => void;
}

type ExpectedStateType = {
  [LOGIN_REDIRECT_STATE]: string | undefined;
} | null;

const LoginForm = ({ nextStep, goToStep }: Props): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { setState } = useContext(LoginWizardContext)!;
  const [authError, setAuthError] = useState<string | null>(null);
  const { getTabIndex } = useTabIndex();
  const { loginUser, rememberUser } = useUserLogin();
  const { resendConfirmationCode } = useUserUpdate();
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as ExpectedStateType;
  const redirectTo = state?.[LOGIN_REDIRECT_STATE];

  return (
    <StepContainer>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setAuthError(null);
          const result = await loginUser(values.email, values.password);

          if (!result.ok) {
            if (isNotConfirmed(result.code)) {
              setState({
                email: values.email,
                password: values.password,
              });

              await resendConfirmationCode(values.email);
              nextStep?.();
              return;
            }
            if (result.code === "NotAuthorizedException") {
              setAuthError(STRINGS.account.login.authError);
            } else {
              setAuthError(STRINGS.account.login.genericAuthError);
            }
            return;
          }

          const { challengeParam, attributes } = result.data;
          const phone_number =
            attributes?.phone_number ||
            challengeParam?.userAttributes?.phone_number;
          if (!phone_number) {
            goToStep?.(3);
            return;
          }

          redirectAfterLogin(navigate, redirectTo);
          await rememberUser();
        }}
      >
        <Form>
          <Box flex column className={styles.loginBox}>
            <Text size={"xLarge"} className={styles.title} inline>
              {STRINGS.account.login.title}
            </Text>

            {authError !== null && (
              <Box flex column className={styles.formError}>
                {authError}
              </Box>
            )}

            <Box flex column className={styles.loginFormContainer}>
              <Field
                component={TextField}
                name={"email"}
                labelText={STRINGS.account.login.inputEmailLabel}
                placeholder={STRINGS.account.login.inputEmailPlaceholder}
                tabIndex={getTabIndex()}
              />
              <Field
                component={PasswordField}
                name={"password"}
                labelText={STRINGS.account.login.inputPasswordLabel}
                placeholder={STRINGS.account.login.inputPasswordPlaceholder}
                tabIndex={getTabIndex()}
              />
              <Button
                primary
                label={STRINGS.account.login.loginButtonLabel}
                type={"submit"}
                className={styles.submitButton}
              />
              <Text size={"small"} inline className={styles.loginHint}>
                {STRINGS.account.login.loginHint1}{" "}
                <Link to={RouteUrls.register}>
                  {STRINGS.account.login.loginHint2}
                </Link>
              </Text>
              <Text size={"small"} inline className={styles.loginHint}>
                <Link to={RouteUrls.forgotPassword}>
                  {STRINGS.account.login.forgotPasswordHint}
                </Link>
              </Text>
            </Box>
          </Box>
        </Form>
      </Formik>
    </StepContainer>
  );
};

export default LoginForm;
