import { ReactComponent as icon1796 } from "gfx/icons/icon1796.svg";
import { ReactComponent as icon1676 } from "gfx/icons/icon1676.svg";
import { ReactComponent as icon2013 } from "gfx/icons/icon2013.svg";
import { ReactComponent as icon2014 } from "gfx/icons/icon2014.svg";
import { ReactComponent as icon96 } from "gfx/icons/icon96.svg";

import { RouteUrls } from "config/routes";

export const dashboardSidebarItems = [
  {
    title: "Übersicht",
    url: RouteUrls.userPortalStart,
    Icon: icon1796,
  },
  {
    title: "Mein Profil",
    url: RouteUrls.userPortalProfile,
    Icon: icon2013,
  },
  {
    title: "Meine Versicherungen",
    url: RouteUrls.userPortalInsurance,
    Icon: icon1796,
  },
  {
    title: "Meine Geldanlagen",
    url: RouteUrls.userPortalInvestments,
    Icon: icon1676,
  },
  {
    title: "Nachrichtenbox",
    url: RouteUrls.userPortalMessageBox,
    Icon: icon2014,
  },
  {
    title: "Kontakt",
    url: RouteUrls.userPortalContact,
    Icon: icon96,
  },
];
