import { InsurancePaymentOption } from "types/insuranceComparisonData/Options";

export const insuranceZahlweiseMock: Array<InsurancePaymentOption> = [
  {
    id: 1,
    content: "jährlich",
    value: "JAEHRLICH",
  },
  {
    id: 2,
    content: "halbjährlich",
    value: "HALBJAEHRLICH",
  },
  {
    id: 3,
    content: "vierteljährlich",
    value: "VIERTELJAEHRLICH",
  },
  {
    id: 4,
    content: "monatlich",
    value: "MONATLICH",
  },
];
