import React from "react";
import { useUpdateSelf } from "api/useSelf";
import EditProfileDialog from "components/user-portal/user-profile/dialogs/EditProfileDialog";
import EditPersonalDataForm from "components/user-portal/user-profile/dialogs/personal-data/EditPersonalDataForm";
import { KundeResponse } from "types/backend/KundeResponse";

interface EditPersonalDataDialogProps {
  kunde: KundeResponse;
  open: boolean;
  onClose: () => void;
}

const EditPersonalDataDialog: React.FC<EditPersonalDataDialogProps> = ({
  kunde,
  open,
  onClose,
}) => {
  const formId = "personalDataForm";
  const mutation = useUpdateSelf();

  return (
    <EditProfileDialog
      onClose={onClose}
      open={open}
      formId={formId}
      mutation={mutation}
    >
      <EditPersonalDataForm formId={formId} kunde={kunde} mutation={mutation} />
    </EditProfileDialog>
  );
};

export default EditPersonalDataDialog;
