import React, { ReactElement } from "react";
import { useFormikContext } from "formik";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { useVertragMapping } from "./useVertragMapping";
import { AddContractFormType } from "../AddContractFormType";
import { validateNumber } from "./util/validationUtil";
import { shouldDisplayField } from "./util/displayInputUtil";
import { AddContractNumberInput } from "./base/AddContractNumberInput";
import { VertragTyp } from "../../../../../types/backend/VertragTyp";

export const BeitragInput = ({
  vertragTyp,
  activeStep,
}: AddContractInputBaseType): ReactElement | null => {
  const { versicherungenTypes } = useVertragMapping();
  const { values } = useFormikContext<AddContractFormType>();
  const requiredTypes: VertragTyp[] = [
    "SONSTIGE_VERTRAEGE",
    ...versicherungenTypes,
  ];
  const validate = async () => {
    if (activeStep !== 2) {
      return;
    }
    return validateNumber(values.beitrag);
  };

  if (!shouldDisplayField(vertragTyp, requiredTypes)) return null;
  return (
    <AddContractNumberInput
      name="beitrag"
      labelText={STRINGS.addContract.step2.amountLabel}
      validate={validate}
    />
  );
};
