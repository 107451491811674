import React, { ReactElement } from "react";
import { useFormikContext } from "formik";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { VertragTyp } from "../../../../../types/backend/VertragTyp";
import { useVertragMapping } from "./useVertragMapping";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { validateNumber, validateRequiredOnTypes } from "./util/validationUtil";
import { AddContractFormType } from "../AddContractFormType";
import { shouldDisplayField } from "./util/displayInputUtil";
import { AddContractNumberInput } from "./base/AddContractNumberInput";

const requiredTypes: VertragTyp[] = [
  "AKTIE",
  "ANLEIHE",
  "ENTNAHMEPLAN",
  "FESTGELD",
  "GIROKONTO",
  "INVESTMENTFONDS",
];

export const AktuellerWertInput = ({
  vertragTyp,
  activeStep,
}: AddContractInputBaseType): ReactElement | null => {
  const { geldanlagenTypes } = useVertragMapping();
  const { values } = useFormikContext<AddContractFormType>();

  const validate = () => {
    if (activeStep !== 2) return;
    return (
      validateRequiredOnTypes(
        requiredTypes,
        STRINGS.addContract.step2.currentValueError,
        vertragTyp,
        values.aktuellerWert
      ) || validateNumber(values.aktuellerWert)
    );
  };

  if (!shouldDisplayField(vertragTyp, geldanlagenTypes)) return null;
  return (
    <AddContractNumberInput
      name="aktuellerWert"
      labelText={STRINGS.addContract.step2.currentValueLabel}
      validate={validate}
    />
  );
};
