import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/gesetzlichekranken_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/gesetzlichekranken_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/gesetzlichekranken_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Gesetzliche Kranken­versicherung";

export const gesetzlichekrankenProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Deine gesetzliche Krankenversicherung hat die gesetzliche Aufgabe, dich bei der Erhaltung, Wiederherstellung oder Verbesserung deines Gesundheitszustandes zu unterstützen.",

  insuranceSituation: [
    {
      title:
        "... du Azubi oder Arbeitnehmer mit einem Gehalt unter 64.400 € brutto bist.",
      text: icon1,
    },
    {
      title: "... du Arbeitslosengeld und Hartz IV beziehst.",
      text: icon1,
    },
    {
      title:
        "... du Student über 25 Jahren bist oder ein monatliches Einkommen von über 470 € hast.",
      text: icon1,
    },
    {
      title:
        "... du Rentner bist und in der zweiten Hälfte deiner Erwerbszeit mindestens 90 % gesetzlich versichert warst.",
      text: icon1,
    },
    {
      title:
        "... du Künstler oder Publizist bist und über die Künstlersozialkasse versichert bist.",
      text: icon1,
    },
    {
      title:
        "... du keinen anderweitigen Schutz auf Absicherung im Krankheitsfall hast.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Die gesetzliche Krankenversicherung gibt es schon seit 1883. Die Leistungen sowie der Versichertenkreis dieser Versicherungen wurden seither stets an die sich verändernden Rahmenbedingungen angepasst.",
        "Im Krankheitsfall haben die Versicherten Anspruch auf Leistungen, welche die Gesundheit des Patienten verbessern, wiederherstellen oder erhalten.",
        "Finanziert werden die Versicherungsbeiträge dabei aus einem fixen Prozentteil des Einkommens, welches sich Arbeitgeber und Arbeitnehmer aufteilen.",
        "Durch die große Gemeinschaft der Versicherten können auch Menschen ohne oder mit sehr geringem eigenen Einkommen kostenfrei versichert werden und so Zugang zur lebenswichtigen Gesundheitsversorgung erhalten.",
      ],
      image: descriptionImage1,
    },

    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "In Deutschland gilt grundsätzlich die allgemeine Krankenversicherungspflicht.",
        "Jeder Mensch mit Wohnsitz in Deutschland, ist dazu verpflichtet, entweder eine gesetzliche oder private Krankenversicherung haben.",
        "Zu dieser Regel gibt es nur sehr wenige Ausnahmen.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Leistungen zur Behandlung von Krankheiten",
        "Leistungen zur Früherkennung von Krankheiten",
        "Leistungen, die zur Minderung von Schmerzen & Krankheiten dienen",
        "Leistungen zur Empfängnisverhütung & Schwangerschaftsabbruch",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Leistungen, die über das medizinisch Notwendige hinaus gehen (z. B. kosmetische Eingriffe, Vorsorge für Auslandsreisen, etc.)",
        "Leistungen von Spezialisten auf ihrem Gebiet, die zu höheren Gebührensätzen abrechnen.",
        "Patentgeschützte Medizin, solange es Alternativen gibt mit zumutbar größeren Nebenwirkungen und diese die Krankheit zumindest bremsen.",
        "Ein- und Zweibettzimmer im Krankenhaus",
        "Hochwertige Materialien und Methoden bei Zahnbehandlungen",
        "Zahnersatz (wird lediglich bezuschusst)",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.gkv,
};
