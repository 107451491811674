import styles from "components/hamburger-icon/HamburgerIcon.module.scss";

import React, { ReactElement } from "react";
import clsx from "clsx";

type Props = {
  isActive: boolean;
  onClick: () => void;
};

const HamburgerIcon = ({ onClick, isActive }: Props): ReactElement => (
  <div
    className={clsx(styles.hamburgerIcon, isActive && styles.active)}
    onClick={onClick}
  >
    <span className={styles.burgerLayer}></span>
  </div>
);

export default HamburgerIcon;
