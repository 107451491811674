import { RouteUrls } from "config/routes";
import insuranceCarImage from "gfx/images/insurance-images/kfz_0.jpg";
import DescriptionImage1 from "gfx/images/insurance-images/kfz_1.jpg";
import DescriptionImage2 from "gfx/images/insurance-images/kfz_2.jpg";
import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

import kfzIcon from "gfx/kfzicon.png";

import { ProductTreeInsuranceType } from "../productTreeDefinition";

const productName = "KFZ-Versicherung";

export const kfzProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: insuranceCarImage,
  introduction:
    "Die Kfz-Haftpflichtversicherung haftet bei Personen-, Sach- und Vermögensschäden, die du mit deinem Auto verursachst. Da es bei Personenschäden schnell teuer werden kann, ist diese Versicherung gesetzlich vorgeschrieben. Darüber hinaus kannst du mit einer Kaskoversicherung Schäden am eigenen Fahrzeug versichern.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  longDescriptions: [
    {
      title: "Was ist eine " + productName + "?",
      paragraphs: [
        "Eine Kfz-Versicherung besteht meistens aus 1 bis 3 Bausteinen (Kfz-Haftpflicht-, Teilkasko-, Vollkaskoversicherung) und neben Schäden an anderen Verkehrsteilnehmern kannst du auch dein eigenes Kraftfahrzeug versichern (z. B. dein Auto oder Motorrad).",
        "Die Haftpflichtversicherung haftet bei Personen-, Sach- und Vermögensschäden, die du mit deinem Auto verursachst (dein eigenes Auto ausgenommen). Da es bei Personenschäden schnell teuer werden kann, ist diese Versicherung gesetzlich vorgeschrieben.",
        "Die Kaskoversicherung ist eine freiwillige Versicherung gegen Diebstahl und Schäden am eigenen Fahrzeug. Grundsätzlich unterscheidet man zwischen einer Teilkasko- und Vollkaskoversicherung.",
      ],
      image: DescriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Jedes Kraftfahrzeug, das auf deutschen Straßen gefahren werden soll, benötigt gesetzlich eine Kfz-Versicherung. Durch den Abschluss einer Kfz-Haftpflichtversicherung erhältst du eine elektronische Versicherungsbestätigung (eVB-Nummer) und nur mit dieser kannst du dein Fahrzeug zulassen.",
        "Du brauchst also eine Kfz-Versicherung, sobald du ein Fahrzeug in Deutschland zulassen möchtest oder ein nichtversichertes Fahrzeug in Deutschland benutzen möchtest.",
      ],

      image: DescriptionImage2,
    },
  ],
  comparisonBoxes: [
    {
      title: "Kfz-Haftpflichtversicherung",
      image: kfzIcon,
      accepted: {
        title: "Die Kfz-Haftpflichtversicherung deckt in der Regel:",
        items: [
          "Personen-, Sach- und Vermögensschäden, welche du durch dein Fahrverhalten bei anderen Verkehrsteilnehmern verursachst.",
          "Schäden, die durch den Gebrauch deines Fahrzeugs an der Umwelt entstehen, z. B. durch auslaufendes Öl oder Treibstoff.",
        ],
      },
      notAccepted: {
        title: "Was wird NICHT versichert?",
        items: [
          "Schäden an deinem eigenen Fahrzeug",
          "Schäden im Zusammenhang mit genehmigten oder auch ungenehmigten Rennen",
          "Schäden, welche durch vorsätzliche Handlungen entstanden sind",
          "100 % der Schäden, welche du durch Trunkenheit verursacht hast",
        ],
      },
    },
    {
      title: "Teilkaskoversicherung",
      image: kfzIcon,
      accepted: {
        title:
          "Die Teilkaskoversicherung deckt in der Regel Schäden am eigenen Fahrzeug durch:",

        items: [
          "Steinschlag am eigenen Auto",
          "Fahrzeugdiebstahl/Einbruchsversuche",
          "Naturgefahren (Hagel, Sturm, Überschwemmung)",
          "Wildunfälle",
          "Feuer",
        ],
      },
      notAccepted: {
        title: "Was wird NICHT versichert?",
        items: [
          "Schäden, welche durch einen selbst verschuldeten Unfall entstanden sind",
          "Schäden, wenn jemand mutwillig dein Fahrzeug beschädigt hat (Vandalismus)",
        ],
      },
    },
    {
      title: "Vollkaskoversicherung",
      image: kfzIcon,
      accepted: {
        title:
          "Die Vollkaskoversicherung deckt in der Regel zusätzlich zur Teilkasko:",
        items: [
          "Schäden am eigenen Fahrzeug durch einen selbst verursachten Unfall",
          "Schäden durch Vandalismus (z. B. Beulen und Kratzer)",
        ],
      },
      notAccepted: {
        title: "Was wird NICHT versichert?",
        items: [
          "Schäden, welche durch vorsätzliche Handlungen entstanden sind",
          "Schäden, welche du durch grobe Fahrlässigkeit oder vorsätzlich verursacht hast (z. B. unter Alkohol- und Drogeneinfluss)",
        ],
      },
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],
  checklist: [
    {
      title: "Grobe Fahrlässigkeit und Vorsatz",
      text: "Während von der Kfz-Versicherung meist auch Schäden gedeckt sind, welche durch leichte Fahrlässigkeit von dir verursacht wurden, sind Schäden durch grobe Fahrlässigkeit meistens und Schäden durch Vorsatz grundsätzlich ausgeschlossen.",
    },
  ],
  link: RouteUrls.kfzversicherung,
};
