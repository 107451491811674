import React, { FunctionComponent } from "react";
import styles from "./ProductTeaser.module.scss";
import insurIcon from "../../../../../gfx/icons/icon173.svg";
import savingIcon from "../../../../../gfx/icons/icon1676.svg";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { Button } from "../../../../form-inputs/Button/Button";
import UnstyledLink from "../../../../unstyle-link/UnstyledLink";
import { RouteUrls } from "../../../../../config/routes";
import PageSection from "../../../../page-section/PageSection";

const sectionStrings = STRINGS.startpage.productTeaser;

export const ProductTeaser: FunctionComponent = () => (
  <PageSection background={"grey"} contentClassName={styles.section}>
    <div className={styles.contentLeft}>
      <div className={styles.leftHeaderBox}>
        <img src={insurIcon} alt={""} className={styles.leftIcon} />
        <h1>
          <span className={styles.highlighted}>
            {sectionStrings.leftTitleFirstLine}
          </span>
          <br />
          {sectionStrings.leftTitleSecondLine}
        </h1>
      </div>
      {sectionStrings.leftProse}
      <UnstyledLink
        to={RouteUrls.userPortalInsurance}
        className={styles.buttonLink}
      >
        <Button
          label={sectionStrings.leftInvestmentPlannerButtonLabel}
          className={styles.actionButton}
        />
      </UnstyledLink>
    </div>

    <div className={styles.contentRight}>
      <div className={styles.rightHeaderBox}>
        <h1>
          <span className={styles.highlighted}>
            {sectionStrings.rightTitleFirstLine}
          </span>
          <br />
          {sectionStrings.rightTitleSecondLine}
        </h1>
        <img src={savingIcon} alt={""} className={styles.rightIcon} />
      </div>
      {sectionStrings.rightProse}

      <UnstyledLink
        to={RouteUrls.userPortalInvestments}
        className={styles.buttonLink}
      >
        <Button
          label={sectionStrings.rightInvestmentPlannerButtonLabel}
          className={styles.actionButton}
        />
      </UnstyledLink>
    </div>
  </PageSection>
);
