import React, { Dispatch, FunctionComponent, useState } from "react";
import styles from "./Bubbles.module.scss";
import bubbleCanvas from "gfx/images/startpage/bubble-template.png";
import { STRINGS } from "../../../../../language/de_DE/strings";
import clsx from "clsx";
import { RouteUrls } from "../../../../../config/routes";
import { Link } from "react-router-dom";
import PageSection from "../../../../page-section/PageSection";

const sectionStrings = STRINGS.startpage.bubbles;
type BubbleDefinition = {
  x: number;
  y: number;
  /* Size in percent */
  size: number;
  text: string;
  link: string;
};

const bubbles: BubbleDefinition[] = [
  {
    x: 26.65,
    y: 44.35,
    size: 14.2,
    text: sectionStrings.bubbles.insurance,
    link: RouteUrls.insurances,
  },
  {
    x: 6.85,
    y: 52.3,
    size: 10.85,
    text: sectionStrings.bubbles.accident,
    link: RouteUrls.unfallversicherung,
  },
  {
    x: 18.5,
    y: 74,
    size: 9.2,
    text: sectionStrings.bubbles.disability,
    link: RouteUrls.berufsunfaehigkeitsversicherung,
  },
  {
    x: 32.57,
    y: 75.85,
    size: 12.6,
    text: sectionStrings.bubbles.liability,
    link: RouteUrls.privatHaftpflicht,
  },
  {
    x: 13.6,
    y: 17,
    size: 10.7,
    text: sectionStrings.bubbles.household,
    link: RouteUrls.hausratversicherung,
  },
  {
    x: 30.68,
    y: 16.5,
    size: 9,
    text: sectionStrings.bubbles.more,
    link: RouteUrls.insurances,
  },
  {
    x: 68.6,
    y: 44.35,
    size: 14.2,
    text: sectionStrings.bubbles.saving,
    link: RouteUrls.userPortalInvestments,
  },
  {
    x: 74.15,
    y: 16,
    size: 8.5,
    text: sectionStrings.bubbles.shares,
    link: RouteUrls.userPortalInvestments,
  },

  {
    x: 85.805,
    y: 31.6,
    size: 11.05,
    text: sectionStrings.bubbles.indexFunds,
    link: RouteUrls.userPortalInvestments,
  },

  {
    x: 81.3,
    y: 63.7,
    size: 7.55,
    text: sectionStrings.bubbles.property,
    link: RouteUrls.userPortalInvestments,
  },

  {
    x: 69.59,
    y: 77.9,
    size: 11,
    text: sectionStrings.bubbles.participations,
    link: RouteUrls.userPortalInvestments,
  },
];

const HiddenBubble: FunctionComponent<{
  isHidden: boolean;
  setIsHidden: Dispatch<boolean>;
}> = ({ isHidden, setIsHidden }) => {
  const hideSelf = () => {
    setIsHidden(true);
  };

  return (
    <div
      className={clsx(styles.bubble, styles.hideableBubble, {
        [styles.hidden]: isHidden,
      })}
      onClick={hideSelf}
    >
      <h1>
        <span className={styles.highlighted}>
          {sectionStrings.hiddenBubbleTitleHighlighted}
        </span>
        <br />
        {sectionStrings.hiddenBubbleTitle}
      </h1>
      <div className={styles.hiddenBubbleText}>
        {sectionStrings.hiddenBubbleText}
      </div>
    </div>
  );
};

export const Bubbles: FunctionComponent = () => {
  const [isHideableBubbleHidden, setIsHideableBubbleHidden] = useState(true);
  const showHiddenBubble = () => setIsHideableBubbleHidden(false);

  return (
    <PageSection>
      <h1 className={styles.title}>
        {sectionStrings.titleFirstLine}
        <br />
        <span className={styles.highlighted}>
          {sectionStrings.titleSecondLine}
        </span>
      </h1>
      <p className={styles.subTitle}>{sectionStrings.subTitle}</p>
      <div
        className={clsx(styles.canvas, {
          [styles.movedToBackground]: !isHideableBubbleHidden,
        })}
      >
        <img
          src={bubbleCanvas}
          alt={sectionStrings.altText}
          className={styles.canvasImage}
        />
        <HiddenBubble
          isHidden={isHideableBubbleHidden}
          setIsHidden={setIsHideableBubbleHidden}
        />
        <div
          className={clsx(styles.masterBubble, styles.bubble)}
          onClick={showHiddenBubble}
        >
          {sectionStrings.bubbles.centerBubble}
        </div>
        {bubbles.map((bubble) => (
          <Link
            className={clsx(
              styles.fadeableBubble,
              styles.bubble,
              styles.enlarging
            )}
            key={bubble.link + bubble.text}
            style={{
              width: bubble.size + "%",
              left: bubble.x + "%",
              top: bubble.y + "%",
            }}
            to={bubble.link}
          >
            {bubble.text}
          </Link>
        ))}
      </div>
    </PageSection>
  );
};
