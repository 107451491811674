import React, { ReactElement } from "react";
import styles from "./DemandCalculatorInputForm.module.scss";
import { DemandCalculatorSelectField } from "./DemandCalculatorInputField";
import { STRINGS } from "../../../language/de_DE/strings";

export const DemandCalculatorInputFormRisk = (): ReactElement => {
  return (
    <div className={styles.formGrid}>
      <DemandCalculatorSelectField
        name="auslandsReisen"
        label={STRINGS.demandCalculator.risks.foreignTravel}
        booleanSelect
      />
      <DemandCalculatorSelectField
        name="hundOderPferd"
        label={STRINGS.demandCalculator.risks.dogOrHorse}
        booleanSelect
      />
      <DemandCalculatorSelectField
        name="kraftfahrzeug"
        label={STRINGS.demandCalculator.risks.motorVehicle}
        booleanSelect
      />
      <DemandCalculatorSelectField
        name="luftfahrzeug"
        label={STRINGS.demandCalculator.risks.airVehicle}
        booleanSelect
      />
      <DemandCalculatorSelectField
        name="wasserfahrzeug"
        label={STRINGS.demandCalculator.risks.waterVehicle}
        booleanSelect
      />
      <DemandCalculatorSelectField
        name="jagen"
        label={STRINGS.demandCalculator.risks.hunting}
        booleanSelect
      />
      <DemandCalculatorSelectField
        name="oeltank"
        label={STRINGS.demandCalculator.risks.oilTank}
        booleanSelect
      />
      <DemandCalculatorSelectField
        name="photovoltaikAnlage"
        label={STRINGS.demandCalculator.risks.photovoltaic}
        booleanSelect
      />
      <DemandCalculatorSelectField
        name="bauenOderSanieren"
        label={STRINGS.demandCalculator.risks.buildOrSanitize}
        booleanSelect
      />
    </div>
  );
};
