import React from "react";
import {
  ColumnConfig,
  GeldanlagenTableConfig,
  VersicherungenTableConfig,
} from "components/user-portal/contract/ContractDataConfig";
import {
  DokumentBoxArchiveCell,
  TextCell,
} from "components/user-portal/contract/ContractTableCells";
import { KundeResponse } from "types/backend/KundeResponse";
import { VertragKapitalanlage } from "types/backend/VertragKapitalanlage";
import { VertragSparanlage } from "types/backend/VertragSparanlage";
import { VertragVersicherung } from "types/backend/VertragVersicherung";
import { VertragVersicherungKfz } from "types/backend/VertragVersicherungKfz";

interface ContractTableRowProps {
  vertrag:
    | VertragKapitalanlage
    | VertragSparanlage
    | VertragVersicherung
    | VertragVersicherungKfz;
  kunde: KundeResponse;
  config: GeldanlagenTableConfig | VersicherungenTableConfig;
}

export const ContractTableRow: React.FC<ContractTableRowProps> = ({
  vertrag,
  kunde,
  config,
}) => {
  return (
    <tr>
      {Object.entries(config).map(
        ([key, columnConfig]: [string, ColumnConfig]) => {
          return (
            <TextCell
              key={key}
              {...columnConfig}
              value={columnConfig.value?.(vertrag, kunde)}
            />
          );
        }
      )}
      <DokumentBoxArchiveCell
        dokumente={vertrag.dokumente || []}
        vertragId={vertrag.id}
        kid={kunde.kid}
      />
    </tr>
  );
};
