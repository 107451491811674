import React, { FunctionComponent, ReactElement } from "react";

export type ImageProperties = {
  className?: string;
  src: string;
  alt: string;
};

export const Image: FunctionComponent<ImageProperties> = ({
  className,
  src,
  alt,
}): ReactElement => {
  return <img className={className} src={src} alt={alt} />;
};
