import styles from "components/user-portal/portal-sideboard/DashboardListContainer.module.scss";

import React, { ReactElement } from "react";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";
import { STRINGS } from "language/de_DE/strings";
import { ProduktKategorie } from "types/backend/ProduktKategorie";
import { VertragBase } from "types/backend/VertragBase";
import { formatEnum } from "utils/formatUtils";

interface DashboardListContainerProps {
  header: string;
  produktKategories: ProduktKategorie[];
  vertraege: VertragBase[];
}

export const DashboardListContainer = ({
  header,
  produktKategories,
  vertraege,
}: DashboardListContainerProps): ReactElement => {
  const produktKategoriesCount = produktKategories.map((pK) => {
    return {
      produktKategorie: pK,
      count: vertraege.filter((v) => v.produktKategorie === pK).length,
    };
  });
  return (
    <Box className={styles.dashboardListContainer}>
      <Box className={styles.header}>
        <Text size="small" inline bold>
          {header}
        </Text>
      </Box>
      <Box>
        {produktKategoriesCount.map((v) => {
          return (
            <DashboardListEntry
              key={v.produktKategorie}
              produktKategorie={v.produktKategorie}
              count={v.count}
            />
          );
        })}
      </Box>
    </Box>
  );
};

interface DashboardListEntryProps {
  produktKategorie: ProduktKategorie;
  count: number;
}

const DashboardListEntry = ({
  produktKategorie,
  count,
}: DashboardListEntryProps): ReactElement => {
  return (
    <Box flex className={styles.dashboardListEntry}>
      <Box>
        <Text size="xSmall" inline>
          {formatEnum(produktKategorie, STRINGS.vertrag.productCategory)}
        </Text>
      </Box>
      <Box>
        <Text size="xSmall" inline>
          {count}
        </Text>
      </Box>
    </Box>
  );
};
