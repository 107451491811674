import { CognitoUserInterface } from "@aws-amplify/ui-components";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import {
  ApiResponse,
  getErrorCode,
  getErrorMessage,
} from "hooks/useUserRegistration";
import createUsernameFromEmail from "utils/auth/createUsernameFromEmail";

const rememberUser = async (): Promise<boolean> => {
  try {
    const rememberResult = await Auth.rememberDevice();
    return rememberResult === "SUCCESS";
  } catch (error) {
    return false;
  }
};

const loginUser = async (
  email: string,
  password: string
): Promise<ApiResponse<CognitoUserInterface>> => {
  const username = createUsernameFromEmail(email);

  try {
    // Trigger AWS login
    // Note: Throws an exception on any failure; so its fine to not check the result
    const result: CognitoUserInterface = await Auth.signIn(username, password);

    return {
      ok: true,
      data: result,
    };
  } catch (error) {
    return {
      ok: false,
      message: getErrorMessage(error as Error),
      code: getErrorCode(error as Error),
    };
  }
};

const isLoggedIn = async (): Promise<boolean> => {
  try {
    const user = await Auth.currentAuthenticatedUser();

    return user !== null;
  } catch (error) {
    return false;
  }
};

const logOut = async (): Promise<boolean> => {
  try {
    await Auth.signOut();

    return true;
  } catch (error) {
    return false;
  }
};

const getCurrentLoggedInUser = async (): Promise<CognitoUser | null> => {
  try {
    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    return user;
  } catch (error) {
    return null;
  }
};

type UseUserLoginReturnType = {
  loginUser: (
    email: string,
    password: string
  ) => Promise<ApiResponse<CognitoUserInterface>>;
  isLoggedIn: () => Promise<boolean>;
  logOut: () => Promise<boolean>;
  rememberUser: () => Promise<boolean>;
  getCurrentLoggedInUser: () => Promise<CognitoUser | null>;
};

const useUserLogin = (): UseUserLoginReturnType => {
  return {
    rememberUser,
    loginUser,
    isLoggedIn,
    logOut,
    getCurrentLoggedInUser,
  };
};
export default useUserLogin;
