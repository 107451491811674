import React from "react";
import { InsuranceHeader } from "../../components/insurances/InsuranceHeader/InsuranceHeader";
import { InsuranceNames } from "../../content/productTreeDefinition";
import insuranceHouseholdImage from "gfx/images/insurance-images/hausrat/title.png";
import { HausratversicherungIndexForm } from "./forms/HausratversicherungIndexForm";
import AuthenticatedPage from "../../components/AuthenticatedPage";

export const HausratversicherungVergleichIndex = () => {
  return (
    <AuthenticatedPage darkPage>
      <InsuranceHeader
        image={insuranceHouseholdImage}
        insuranceName={InsuranceNames.HAUSRATVERSICHERUNG}
      >
        <div>
          <HausratversicherungIndexForm />
        </div>
      </InsuranceHeader>
    </AuthenticatedPage>
  );
};
