import { Auth } from "aws-amplify";
import addDokumente from "api/requests/addDokumente";
import createKunde from "api/requests/createKunde";
import deleteDokument from "api/requests/deleteDokument";
import fetchDokumentFile from "api/requests/fetchDokumentFile";
import fetchKunde from "api/requests/fetchKunde";
import {
  fetchGeldanlagen,
  fetchVersicherungen,
} from "api/requests/fetchVertraege";
import updateKunde from "api/requests/updateKunde";
import { AxiosRequestHeaders } from "axios";
import { createVertrag } from "api/requests/createVertrag";
import { readNachricht } from "./requests/readNachricht";
import { sendNachricht } from "./requests/sendNachricht";

const getHeaderWithAuthorization = async (): Promise<AxiosRequestHeaders> => {
  const userSession = await Auth.currentSession();
  const jsonWebToken = userSession.getAccessToken().getJwtToken();

  return {
    Authorization: `Bearer ${jsonWebToken}`,
    "Content-Type": "application/json",
  };
};

const getHeaderWithoutAuthorization = () => {
  return {
    "Content-Type": "application/json",
  };
};

const api = {
  getHeaderWithAuthorization,
  getHeaderWithoutAuthorization,
  createKunde,
  updateKunde,
  createVertrag,
  addDokumente,
  deleteDokument,
  fetchKunde,
  fetchVersicherungen,
  fetchGeldanlagen,
  fetchDokumentFile,
  readNachricht,
  sendNachricht,
};

export default api;
