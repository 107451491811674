import { Field } from "formik";

import React, { ReactElement } from "react";
import {
  Option,
  SingleSelect,
} from "../../../../../form-inputs/Selections/SingleSelect";

interface AddContractSelectionProps {
  name: string;
  options: Option[];
  labelText?: string;
  validate?: () => string | undefined | void;
}

export const AddContractSelection = ({
  name,
  options,
  labelText,
  validate,
}: AddContractSelectionProps): ReactElement => {
  return (
    <Field
      component={SingleSelect}
      name={name}
      labelText={labelText}
      options={options}
      validate={validate}
    />
  );
};
