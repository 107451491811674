import styles from "components/step-wizard/components/StepContainer.module.scss";

import React, { ReactElement, ReactNode } from "react";

interface StepContainerProps {
  children: ReactNode;
}

const StepContainer = ({ children }: StepContainerProps): ReactElement => (
  <div className={styles.stepContainer}>{children}</div>
);

export default StepContainer;
