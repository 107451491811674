import axios from "axios";
import api from "api/index";
import { appConfig } from "config/appConfig";
import { VertragDokumentRequest } from "types/backend/VertragDokumentRequest";
import { isCreated } from "utils/axiosHelpers";
import { AddContractFormType } from "../../components/user-portal/user-add-contract/form-sections/AddContractFormType";
import { STRINGS_INSTITUTES } from "../../language/de_DE/institutes";

export const createVertrag = async (
  request: { kid: number | undefined } & VertragDokumentRequest
): Promise<void> => {
  const url = `${appConfig.apiUrl}/api/kunden/${request.kid}/vertraege`;
  const header = await api.getHeaderWithAuthorization();
  const formData = new FormData();
  const vertragRequest = { ...request, files: [] };
  const vertragBlob = new Blob([JSON.stringify(vertragRequest)], {
    type: "application/json",
  });
  formData.append("request", vertragBlob);
  if (request.files) {
    request.files.forEach((file) => formData.append("files", file));
  }
  await axios.post(url, formData, {
    headers: header,
    validateStatus: isCreated,
  });
};

const getInstitut = (values: AddContractFormType) => {
  const institut = values.institut;
  if (!institut || institut === "SONSTIGE") {
    return values.customInstitut;
  }
  return STRINGS_INSTITUTES.vertrag.institut[institut];
};

export const createVertragRequest = (
  formValues: AddContractFormType
): VertragDokumentRequest => {
  return {
    typ: formValues.typ || undefined,
    customTyp: formValues.customTyp,
    beitrag: Number(formValues.beitrag),
    beitragsIntervall: formValues.beitragsIntervall || undefined,
    institut: getInstitut(formValues),
    files: formValues.files,
    produktTyp: formValues.produktTyp || undefined,
    vertragsnummer: formValues.vertragsnummer,
    vertragslaufzeit: formValues.vertragslaufzeit,
    nameVersicherter: formValues.nameVersicherter,
    vertragsname: formValues.vertragsname,
    isin: formValues.isin,
    kaufwert: Number(formValues.kaufwert),
    aktuellerWert: Number(formValues.aktuellerWert),
    verwendungszweck: formValues.verwendungszweck || undefined,
    zielsparsumme: Number(formValues.zielsparsumme),
    sparbetrag: Number(formValues.sparbetrag),
    auszahlungEinmaligPrognose: Number(formValues.auszahlungEinmaligPrognose),
    auszahlungLaufendPrognose: Number(formValues.auszahlungLaufendPrognose),
    bezeichnung: formValues.bezeichnung,
    einzahlung: formValues.einzahlung || undefined,
    bausparsumme: Number(formValues.bausparsumme),
    leistungRenteErwerbsunfaehigkeit: Number(
      formValues.leistungRenteErwerbsunfaehigkeit
    ),
    leistungRenteBerufsunfaehigkeit: Number(
      formValues.leistungRenteBerufsunfaehigkeit
    ),
    risikoart: formValues.risikoart || undefined,
    pflegeleistung: formValues.pflegeleistung || undefined,
    risikoWert: Number(formValues.risikoWert),
    leistungKapitalUnfall: Number(formValues.leistungKapitalUnfall),
    leistungKapitalTodesfall: Number(formValues.leistungKapitalTodesfall),
    kfzHersteller: formValues.kfzHersteller,
    kfzBezeichnung: formValues.kfzBezeichnung,
    kfzBeitragKasko: Number(formValues.kfzBeitragKasko),
    leistungRentePflege1: Number(formValues.leistungRentePflege1),
    leistungRentePflege2: Number(formValues.leistungRentePflege2),
    leistungRentePflege3: Number(formValues.leistungRentePflege3),
    leistungRentePflege4: Number(formValues.leistungRentePflege4),
    leistungRentePflege5: Number(formValues.leistungRentePflege5),
    krankentagegeld: Number(formValues.krankentagegeld),
    ambulantGebuehrenordnung: formValues.ambulantGebuehrenordnung || undefined,
    ambulantErstattung: formValues.ambulantErstattung,
    ambulantHeilpraktiker: formValues.ambulantHeilpraktiker,
    ambulantHufeland: formValues.ambulantHufeland,
    ambulantSehhilfe: formValues.ambulantSehhilfe || undefined,
    ambulantVorsorge: formValues.ambulantVorsorge,
    stationaerGebuehrenordnung:
      formValues.stationaerGebuehrenordnung || undefined,
    stationaerUnterkunft: formValues.stationaerUnterkunft || undefined,
    stationaerKrankenhauswahl: formValues.stationaerKrankenhauswahl,
    stationaerChefarzt: formValues.stationaerChefarzt,
    zahnGebuehrenordnung: formValues.zahnGebuehrenordnung || undefined,
    zahnbehandlung: formValues.zahnbehandlung,
    zahnanspruch: Number(formValues.zahnanspruch),
    zahnersatz: formValues.zahnersatz,
    zahnersatzOhneVorleistung: formValues.zahnersatzOhneVorleistung,
    zahnimplantate: formValues.zahnimplantate,
    zahnKeineStaffelung: formValues.zahnKeineStaffelung,
    zahnKieferorthopaedie: formValues.zahnKieferorthopaedie,
  };
};
