/**
 * Must be keep in sync with SASS variable "$app-breakpoints"
 * @see src/App.scss
 */

type AppBreakpointName = "xs" | "sm" | "md" | "lg" | "xl";

const appBreakpoints: Record<AppBreakpointName, string> = {
  xs: "0",
  sm: "576px",
  md: "992px",
  lg: "1200px",
  xl: "1400px",
};

export default appBreakpoints;
