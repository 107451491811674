import styles from "./HausratversicherungIndexForm.module.scss";

import React from "react";
import { Field, Form, Formik } from "formik";
import { Checkbox } from "components/form-inputs/Checkbox/Checkbox";
import { TextField } from "components/form-inputs/TextField/TextField";
import { STRINGS } from "language/de_DE/strings";
import { hausratTarifeRequestSchema } from "utils/validators/hausrat/hausratTarifeRequestValidator";
import { DateField } from "../../../components/form-inputs/DateField/DateField";
import { Button } from "../../../components/form-inputs/Button/Button";
import {
  BooleanRadioGroup,
  BooleanRadioGroupValue,
} from "../../../components/form-inputs/BooleanRadioGroup/BooleanRadioGroup";
import useUserState from "../../../hooks/useUserState";
import { KundeResponse } from "../../../types/backend/KundeResponse";
import { Berufsstatus } from "../../../types/backend/Berufsstatus";
import { Link } from "react-router-dom";
import { RouteUrls } from "../../../config/routes";

const sectionStrings = STRINGS.hausrat.comparison;

function isOeffentlicherDienst(status?: Berufsstatus): BooleanRadioGroupValue {
  if (!status) return "no";
  const oeffentlich: Berufsstatus[] = [
    "ANGESTELLT_IM_OEFFENTLICHEN_DIENST",
    "BEAMTER",
  ];
  if (oeffentlich.indexOf(status) > -1) return "yes";
  return "no";
}

function initialValues(kunde?: KundeResponse) {
  if (kunde) {
    return {
      postleitzahl: kunde?.adressen[0]?.plz || "",
      wohnflaeche: kunde?.wohnflaeche || "",
      geburtsdatum: kunde?.geb || "",
      versicherungssumme: (kunde?.wohnflaeche || 0) * 650,
      fahrlaessigkeitAbgedeckt: "yes",
      arbeitInOeffentlicherDienst: isOeffentlicherDienst(kunde?.berufsstatus),
      erstinformationErhalten: false,
    };
  }
  return {
    postleitzahl: "",
    wohnflaeche: "",
    geburtsdatum: "",
    versicherungssumme: "",
    fahrlaessigkeitAbgedeckt: "yes",
    arbeitInOeffentlicherDienst: "no",
    erstinformationErhalten: false,
  };
}

export const HausratversicherungIndexForm = () => {
  const { kunde } = useUserState();
  return (
    <div className={styles.formBox}>
      <Formik
        initialValues={initialValues(kunde)}
        validationSchema={hausratTarifeRequestSchema}
        onSubmit={(val) => {
          alert("Du bist klasse!\n" + JSON.stringify(val));
        }}
        enableReinitialize
      >
        {() => (
          <Form className={styles.form}>
            <div className={styles.firstInputBlock}>
              <Field
                component={TextField}
                name={"postleitzahl"}
                placeholder={sectionStrings.inputs.postleitzahlPlaceholder}
                labelText={sectionStrings.inputs.postleitzahlLabel}
                lightColor
              />
              <Field
                component={TextField}
                name={"wohnflaeche"}
                placeholder={sectionStrings.inputs.wohnflaechePlaceholder}
                labelText={sectionStrings.inputs.wohnflaecheLabel}
                lightColor
              />
              <Field
                component={DateField}
                name={"geburtsdatum"}
                placeholder={sectionStrings.inputs.geburtsdatumPlaceholder}
                labelText={sectionStrings.inputs.geburtsdatumLabel}
                lightColor
              />
            </div>
            <div className={styles.secondInputBlock}>
              <Field
                component={TextField}
                name={"versicherungssumme"}
                placeholder={
                  sectionStrings.inputs.versicherungssummePlaceholder
                }
                labelText={sectionStrings.inputs.versicherungssummeLabel}
                lightColor
              />

              <BooleanRadioGroup
                labelText={sectionStrings.inputs.fahrlaessigkeitAbgedecktLabel}
                name={"fahrlaessigkeitAbgedeckt"}
                lightColor
              />
              <BooleanRadioGroup
                labelText={
                  sectionStrings.inputs.arbeitInOeffentlicherDienstLabel
                }
                name={"arbeitInOeffentlicherDienst"}
                lightColor
              />
            </div>
            <div className={styles.actions}>
              <Field
                component={Checkbox}
                type={"checkbox"}
                name={"erstinformationErhalten"}
                labelText={sectionStrings.inputs.erstinformationErhaltenLabel}
                lightColor
              />
              <Link to={RouteUrls.notFound} className={styles.downloadLink}>
                Informationen herunterladen
              </Link>
              <Button
                label={sectionStrings.startCompareButtonLabel}
                type={"submit"}
                className={styles.startCompareButton}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
