import React, { useEffect, useMemo } from "react";
import { Field, useFormikContext } from "formik";
import {
  createVertragTypGeldanlageOptions,
  createVertragTypVersicherungenOptions,
} from "utils/vertragUtils";
import { AddContractTextInput } from "./AddContractTextInput";
import { AddContractSelection } from "./AddContractSelection";
import { AddContractFormType } from "../../AddContractFormType";

type ContractSelectionProps = {
  name: string;
};

export const ContractSelection = ({ name }: ContractSelectionProps) => {
  const vertragTypGeldanlageOptions = useMemo(
    () => createVertragTypGeldanlageOptions(),
    []
  );
  const vertragTypVersicherungenOptions = useMemo(
    () => createVertragTypVersicherungenOptions(),
    []
  );

  const {
    values: { produktTyp },
    setFieldValue,
  } = useFormikContext<AddContractFormType>();

  useEffect(() => {
    if (produktTyp === "SONSTIGE") {
      setFieldValue(name, "SONSTIGE_VERTRAEGE");
      return;
    }
    setFieldValue(name, "");
  }, [produktTyp, name, setFieldValue]);

  const determineOptions = () => {
    if (produktTyp === "GELDANLAGE") {
      return vertragTypGeldanlageOptions;
    }
    if (produktTyp === "VERSICHERUNG") {
      return vertragTypVersicherungenOptions;
    }
    return [];
  };

  if (produktTyp === "SONSTIGE") {
    return <Field component={AddContractTextInput} name="customTyp" />;
  }
  return <AddContractSelection name={name} options={determineOptions()} />;
};
