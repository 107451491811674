import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/pflegezusatz_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/pflegezusatz_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/pflegezusatz_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Pflege­zusatz­versicherung";

export const pflegezusatzProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Deine Pflegezusatzversicherung schützt dich vor steigenden Pflegekosten und schließt aufkommende Versorgungslücken, solltest du eines Tages auf Pflege- oder Betreuungsleistungen angewiesen sein.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title:
        "... dein Vermögen nicht ausreicht, um die Versorgungslücke der gesetzlichen Pflegepflichtversicherung von durchschnittlich 1800 € pro Monat zu schließen.",
      text: icon1,
    },
    {
      title:
        "... du dir mehr Selbstbestimmung wünscht, wie und wo du gepflegt werden möchtest.",
      text: icon1,
    },
    {
      title: "... du deine Angehörigen finanziell nicht belasten möchtest.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Eine Pflegezusatzversicherung unterstützt dich dabei, eine frühzeitige Absicherung für eine mögliche Pflegebedürftigkeit zu treffen.",
        "Wenn du dir Sorgen machst, dass deine Pflegekosten dein Budget übersteigen könnten oder du deiner Familie nicht zur Last fallen möchtest, könnte eine Pflegezusatzversicherung genau das Richtige für dich sein.",
        "Es gibt vier verschiedene Arten der Pflegezusatzversicherung: Pflegetagegeld-Tarife, Pflegekostenversicherungen, Pflegerentenversicherungen und Pflege-Bahr. Jeder dieser unterschiedlichen Versicherungstypen kommt mit unterschiedlichen Leistungen und Risiken, die abgedeckt werden.",
        "Solltest du mit dem Gedanken spielen, dich gegen ein Pflegekostenrisiko in der Zukunft abzusichern und dir unsicher sein, welcher Tariftyp für dich passt oder was du sonst noch berücksichtigen musst, melde dich gerne kostenfrei bei unseren Beratern, die sich mit deiner individuellen Situation auseinandersetzen.",
      ],
      image: descriptionImage1,
    },

    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Eine Pflegezusatzversicherung macht für Menschen in den unterschiedlichsten Lebensphasen Sinn.",
        "Kinder können so schon früh gegen dieses Kostenrisiko abgesichert werden und profitieren von niedrigen Beiträgen.",
        "Eltern und Senioren (bis zu einem Maximalbeitrittsalter) fallen ebenso in das Raster an möglichen abzusichernden Personen.",
        "Außerdem ergibt es Sinn, sich als verbeamtete Person oder als Person mit wenig Erspartem mit einer Pflegezusatzversicherung zu beschäftigen und den Notfall abzusichern.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.pflegezusatzversicherung,
};
