import { Option } from "components/form-inputs/Selections/SingleSelect";
import { STRINGS_INSTITUTES } from "language/de_DE/institutes";
import { STRINGS } from "language/de_DE/strings";
import { VertragTyp } from "types/backend/VertragTyp";
import { createAlphabeticallyGroupedOptions } from "utils/formInputUtils";

export const createVertragInstitutOptions = (): Option[] => {
  return createAlphabeticallyGroupedOptions(
    STRINGS_INSTITUTES.vertrag.institut
  );
};

export const createVertragTypGeldanlageOptions = (): Option[] => {
  return createAlphabeticallyGroupedOptions(STRINGS.vertrag.typ.geldanlagen);
};

export const createVertragTypVersicherungenOptions = (): Option[] => {
  return createAlphabeticallyGroupedOptions(STRINGS.vertrag.typ.versicherungen);
};

export const determineProgress = (
  institut: string | undefined,
  typ: VertragTyp | undefined,
  beitrag: number | undefined,
  beitragsIntervall: string | undefined,
  files: File[] | undefined
): number => {
  let newProgress = 0;
  const incrementalProgress = 20;
  if (typ) {
    newProgress += incrementalProgress;
  }
  if (institut) {
    newProgress += incrementalProgress;
  }
  if (beitrag) {
    newProgress += incrementalProgress;
  }
  if (beitragsIntervall) {
    newProgress += incrementalProgress;
  }
  if (files) {
    newProgress += incrementalProgress;
  }
  return newProgress;
};

export const createVertragBeitragIntervalOptions = (): Option[] => {
  const types = STRINGS.vertrag.interval as Record<string, any>;
  return Object.keys(types).map((typeKey) => {
    return {
      label: types[typeKey],
      value: typeKey,
    };
  });
};
