import common from "components/form-inputs/FormInputCommons.module.scss";

import React, { ReactElement } from "react";
import clsx from "clsx";

interface FormInputLabelProps {
  labelText?: string;
  id?: string;
  labelClassName?: string;
  lightColor?: boolean;
}

export const FormInputLabel = ({
  labelText,
  id,
  labelClassName,
  lightColor,
}: FormInputLabelProps): ReactElement | null => {
  if (labelText === undefined) return null;
  return (
    <label
      htmlFor={id}
      className={clsx(
        common.label,
        lightColor ? common.labelLight : "",
        labelClassName
      )}
    >
      {labelText}
    </label>
  );
};
