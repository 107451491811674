import styles from "components/footer/Footer.module.scss";

import React, { FunctionComponent } from "react";
import { RouteUrls } from "config/routes";
import { STRINGS } from "../../language/de_DE/strings";
import LogoImage from "../logo/LogoImage";
import { Link } from "react-router-dom";

const sectionStrings = STRINGS.footer;

const linkLists = [
  {
    title: sectionStrings.linkLists.company,
    links: [
      {
        text: sectionStrings.linkLists.aboutUs,
        href: RouteUrls.aboutUs,
      },
    ],
  },
  {
    title: sectionStrings.linkLists.legal,
    links: [
      {
        text: sectionStrings.linkLists.termsOfService,
        href: RouteUrls.AGB,
      },
      {
        text: sectionStrings.linkLists.dataProtection,
        href: RouteUrls.datenschutz,
      },
      {
        text: sectionStrings.linkLists.erstInformation,
        href: RouteUrls.erstInformation,
      },
      {
        text: sectionStrings.linkLists.imprint,
        href: RouteUrls.impressum,
      },
      {
        text: sectionStrings.linkLists.nachhaltigkeit,
        href: RouteUrls.nachhaltigkeit,
      },
      {
        text: sectionStrings.linkLists.cookiePolicy,
        href: RouteUrls.cookiePolicy,
      },
    ],
  },
];

export const Footer: FunctionComponent = () => {
  return (
    <footer className={styles.footer}>
      <section>
        <div className={styles.myNormContactData}>
          <LogoImage size={"large"} color={"white"} />
        </div>
        <div className={styles.linkLists}>
          <div className={styles.linkList}>
            <h3>{sectionStrings.contact.contact}</h3>
            <a href={"mailto:" + sectionStrings.contact.email}>
              {sectionStrings.contact.email}
            </a>
            <p>
              <a href={"tel:" + sectionStrings.contact.phoneLink}>
                {sectionStrings.contact.phone} <br />
                {sectionStrings.contact.times}
              </a>
            </p>
          </div>
          {linkLists.map((list) => (
            <div key={list.title} className={styles.linkList}>
              <h3>{list.title}</h3>
              {list.links.map((link) => (
                <Link to={link.href} key={link.href + link.text}>
                  {link.text}
                </Link>
              ))}
            </div>
          ))}
        </div>
        <div className={styles.footline}>{sectionStrings.footline}</div>
      </section>
    </footer>
  );
};
