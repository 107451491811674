import styles from "components/user-portal/user-message-box/Mailbox.module.scss";

import React, { ReactElement, useState } from "react";
import { Nachricht } from "types/backend/Nachricht";
import { isToday } from "date-fns";
import { STRINGS } from "language/de_DE/strings";
import AccordionBox from "components/accordion/AccordionBox";
import { ReactComponent as Icon2014 } from "gfx/icons/icon2014.svg";
import { formatDate, formatTime } from "utils/formatUtils";
import clsx from "clsx";
import { useReadNachricht } from "../../../api/useNachrichten";
import { MessageDialog } from "./MessageDialog";

const sortByDate = (a: Nachricht, b: Nachricht) => {
  return Date.parse(b.gesendetAm) - Date.parse(a.gesendetAm);
};

interface MailBoxProps {
  kid: number;
  messages: Array<Nachricht>;
}
type TabVariant = "incoming" | "outgoing";

export const MailBox = ({ kid, messages }: MailBoxProps): ReactElement => {
  const [selectedMessageId, setSelectedMessageId] = useState<number>();
  const mutation = useReadNachricht();
  const [selectedTabVariant, setSelectedTabVariant] =
    useState<TabVariant>("incoming");

  const selectMessage = (messageId: number) => {
    if (messageId === selectedMessageId) {
      setSelectedMessageId(undefined);
    } else {
      setSelectedMessageId(messageId);
    }
    if (selectedTabVariant === "incoming") {
      const message = messages.find((m) => m.id === messageId);
      if (message && !message.gelesenVonKunde) {
        mutation.mutate({ kid, messageId: message.id });
      }
    }
  };

  const toggleMailbox = (tabVariant: TabVariant) => {
    setSelectedTabVariant(tabVariant);
  };

  return (
    <div className={styles.mailbox}>
      <div className={styles.mailboxHeader}>
        <div className={styles.switch}>
          <h3
            className={selectedTabVariant === "incoming" ? styles.selected : ""}
            onClick={() => toggleMailbox("incoming")}
          >
            {STRINGS.messageBox.incomingMessages}
          </h3>
          <h3
            className={selectedTabVariant === "outgoing" ? styles.selected : ""}
            onClick={() => toggleMailbox("outgoing")}
          >
            {STRINGS.messageBox.outgoingMessages}
          </h3>
        </div>
        <MessageDialog kid={kid} />
      </div>
      <div className={styles.mailboxContent}>
        {messages.length === 0 && (
          <div className={styles.noMessages}>
            {STRINGS.messageBox.noMessages}
          </div>
        )}
        <div className={styles.messageList}>
          {messages
            .sort(sortByDate)
            .filter((n) => {
              if (selectedTabVariant === "incoming") {
                return n.senderKid !== kid;
              }
              return n.senderKid === kid;
            })
            .map((n) => (
              <MessageListEntry
                key={n.id}
                message={n}
                selectedMessageId={selectedMessageId}
                onSelectMessage={selectMessage}
                readMessageEnabled={selectedTabVariant === "incoming"}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

interface MessageListEntryProps {
  message: Nachricht;
  selectedMessageId?: number;
  onSelectMessage: (messageId: number) => void;
  readMessageEnabled?: boolean;
}

const MessageListEntry = ({
  message,
  selectedMessageId,
  onSelectMessage,
  readMessageEnabled,
}: MessageListEntryProps): ReactElement => {
  return (
    <AccordionBox
      title={<MessageHeader message={message} />}
      styleClasses={{
        titleClass: clsx(
          styles.accordionTitle,
          !message.gelesenVonKunde && readMessageEnabled
            ? styles.messageUnread
            : ""
        ),
      }}
      isOpen={message.id === selectedMessageId}
      onHeaderClick={() => onSelectMessage(message.id)}
    >
      <MessageDetails message={message} />
    </AccordionBox>
  );
};

interface MessageHeaderProps {
  message: Nachricht;
}

const MessageHeader = ({ message }: MessageHeaderProps): ReactElement => {
  return (
    <div className={styles.messageHeader}>
      <div className={styles.subject}>
        <div className={styles.icon}>
          <Icon2014 />
        </div>
        {message.betreff && (
          <div
            className={styles.subjectText}
            dangerouslySetInnerHTML={{ __html: message.betreff }}
          ></div>
        )}
      </div>
      <div className={styles.sentDate}>
        <span className={styles.sentDateText}>
          {isToday(Date.parse(message.gesendetAm))
            ? STRINGS.messageBox.today
            : formatDate(message.gesendetAm, "")}
          {", "}
          {formatTime(message.gesendetAm)}
        </span>
      </div>
    </div>
  );
};

interface MessageDetailsProps {
  message: Nachricht;
}

const MessageDetails = ({ message }: MessageDetailsProps): ReactElement => {
  return (
    <div className={styles.messageDetails}>
      <div className={styles.body}>
        {message.text && (
          <div
            className={styles.bodyText}
            dangerouslySetInnerHTML={{ __html: message.text }}
          ></div>
        )}
      </div>
    </div>
  );
};
