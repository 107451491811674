import React, { ReactElement } from "react";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { useVertragMapping } from "./useVertragMapping";
import { shouldDisplayField } from "./util/displayInputUtil";
import { AddContractNumberInput } from "./base/AddContractNumberInput";

export const KaufwertInput = ({
  vertragTyp,
}: AddContractInputBaseType): ReactElement | null => {
  const { kapitalanlagenTypes } = useVertragMapping();

  if (!shouldDisplayField(vertragTyp, kapitalanlagenTypes)) return null;
  return (
    <AddContractNumberInput
      name="kaufwert"
      labelText={STRINGS.addContract.step2.acquisitionValueLabel}
    />
  );
};
