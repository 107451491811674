import styles from "components/user-portal/portal-sideboard/ScoreProgressBar.module.scss";

import React, { ReactElement } from "react";
import { Box } from "components/atoms/Box";
import clsx from "clsx";

interface ScoreProgressBarProps {
  score: number;
  backgroundColorClass: string;
}

export const ScoreProgressBar = ({
  score,
  backgroundColorClass,
}: ScoreProgressBarProps): ReactElement => {
  const cssVariables = {
    "--width": `${score}%`,
  } as React.CSSProperties;
  return (
    <Box className={styles.progressBar}>
      <div
        className={clsx(styles.progressBarFull, backgroundColorClass)}
        style={cssVariables}
      >
        <Box className={styles.progressText}>{`${score}%`}</Box>
      </div>
    </Box>
  );
};
