import common from "components/form-inputs/FormInputCommons.module.scss";

import React, { ReactElement } from "react";
import clsx from "clsx";
import { v4 } from "uuid";
import { FormInputLabel } from "../FormInputLabel";
import { FormInputSubText } from "../FormInputSubText";
import {
  FormInputCustomStyleProperties,
  FormInputProperties,
} from "../FormInputTypes";

type TextFieldProperties = FormInputProperties & {
  formatFn?: (value: string) => string;
  textarea?: boolean;
};

const CustomizableTextField = ({
  field,
  form,
  labelText,
  helpText,
  disabled,
  tabIndex,
  placeholder,
  lightColor,
  formatFn,
  inputClassName,
  labelClassName,
  validationClassName,
  helpTextClassName,
  textarea,
}: TextFieldProperties & FormInputCustomStyleProperties): ReactElement => {
  const id = field.name + "-" + v4();
  const meta = form.getFieldMeta(field.name);
  const formatValue = (value: string) => {
    if (!formatFn) return value || "";
    return formatFn(value) || "";
  };
  return (
    <div>
      <FormInputLabel
        labelText={labelText}
        labelClassName={labelClassName}
        id={id}
        lightColor={lightColor}
      />
      {!textarea && (
        <input
          {...field}
          value={formatValue(field.value)}
          type="text"
          className={clsx(common.input, inputClassName)}
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          tabIndex={tabIndex}
        />
      )}
      {textarea && (
        <textarea
          {...field}
          value={formatValue(field.value)}
          className={clsx(common.input, common.textarea, inputClassName)}
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          tabIndex={tabIndex}
          rows={10}
        />
      )}
      <FormInputSubText
        meta={meta}
        validationClassName={validationClassName}
        helpTextClassName={helpTextClassName}
        helpText={helpText}
      />
    </div>
  );
};

export const TextField = (props: TextFieldProperties): ReactElement => {
  return <CustomizableTextField {...props} />;
};
