import React, { ReactElement } from "react";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { useVertragMapping } from "./useVertragMapping";
import { AddContractDateInput } from "./base/AddContractDateInput";
import { shouldDisplayField } from "./util/displayInputUtil";
import { VertragTyp } from "../../../../../types/backend/VertragTyp";

export const VertragslaufzeitInput = ({
  vertragTyp,
}: AddContractInputBaseType): ReactElement | null => {
  const { versicherungenTypes } = useVertragMapping();
  const requiredTypes: VertragTyp[] = [
    "SONSTIGE_VERTRAEGE",
    ...versicherungenTypes,
  ];
  if (!shouldDisplayField(vertragTyp, requiredTypes)) return null;
  return (
    <AddContractDateInput
      name={"vertragslaufzeit"}
      labelText={STRINGS.addContract.step2.contractDurationLabel}
    />
  );
};
