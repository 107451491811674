import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/betreiberhaftpflicht_0.jpg";

const productName = "Betreiberhaftpflicht";

export const betreiberhaftpflichtProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Mit einer Betreiberhaftpflichtversicherung für Photovoltaikanlagen lassen sich Schäden abdecken, die durch den Betrieb der Anlage gegenüber Dritten entstehen können. ",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  link: RouteUrls.betreiberHaftpflicht,
};
