import styles from "components/molecules/InsuranceBadge.module.scss";

import React, { FunctionComponent } from "react";
import { Box } from "components/atoms/Box";
import { Image } from "components/atoms/Image";
import { Text } from "components/atoms/Text";
import { InsuranceBadgeData } from "types/InsuranceData/InsuranceBadgeData";

export type InsuranceBadgeProperties = {
  insuranceBadgeData: InsuranceBadgeData;
};

export const InsuranceBadge: FunctionComponent<InsuranceBadgeProperties> = ({
  insuranceBadgeData,
}) => {
  return (
    <Box flex inline className={styles.badgeBox}>
      <Image src={insuranceBadgeData.logo} alt={insuranceBadgeData.name} />
      <Text size={"large"}>{insuranceBadgeData.name}</Text>
      <Box flex column className={styles.priceBox}>
        <Box flex className={styles.price}>
          <Text inline size={"large"} bold>
            {insuranceBadgeData.price} €
          </Text>
        </Box>
        <Box flex className={styles.period}>
          <Text inline size={"medium"}>
            {insuranceBadgeData.period}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
