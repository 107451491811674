import styles from "components/user-portal/user-profile/Profile.module.scss";

import React, { ReactElement, useState } from "react";
import EditContactDialog from "components/user-portal/user-profile/dialogs/contact/EditContactDialog";
import ProfileCard from "components/user-portal/user-profile/ProfileCard";
import UserProfileItem from "components/user-portal/user-profile/UserProfileItem";
import UserProfileMultiItem from "components/user-portal/user-profile/UserProfileMultiItem";
import { NATIONS } from "language/de_DE/nations";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { formatEnum, formatPhone } from "utils/formatUtils";

interface ContactCardProps {
  kunde: KundeResponse;
}

export const ContactCard = ({ kunde }: ContactCardProps): ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <ProfileCard
      title={STRINGS.profile.contact.header}
      className={styles.profileCard}
      onEdit={() => setIsDialogOpen(true)}
    >
      <UserProfileItem
        label={formatEnum("E_MAIL", STRINGS.profile.contact.contactTypes)}
        value={kunde.kontaktdaten.find((k) => k.art === "E_MAIL")?.wert}
      />
      <UserProfileItem
        label={formatEnum("TELEFON", STRINGS.profile.contact.contactTypes)}
        value={formatPhone(
          kunde.kontaktdaten.find((k) => k.art === "TELEFON")?.wert
        )}
      />
      <div className={styles.verticalSpace} />
      <UserProfileMultiItem
        label={STRINGS.profile.contact.registrationAddress}
        values={[
          kunde.adressen[0]?.strasse,
          `${kunde.adressen[0]?.plz || ""} ${kunde.adressen[0]?.ort || ""}`,
        ]}
      />
      <UserProfileItem
        label={STRINGS.profile.contact.nation}
        value={formatEnum(kunde.nation, NATIONS)}
      />
      <EditContactDialog
        kunde={kunde}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </ProfileCard>
  );
};
