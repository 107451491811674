import styles from "components/molecules/InsuranceOptionCard.module.scss";

import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "components/atoms/Box";
import { Checkbox } from "components/form-inputs/Checkbox/Checkbox";
import { Text } from "components/atoms/Text";

import { ToolTip } from "components/molecules/ToolTip";
import { STRINGS } from "language/de_DE/strings";
import { InsuranceOption } from "types/insuranceComparisonData/Options";
import { Field } from "formik";

export type InsuranceOptionCardProperties = {
  option: InsuranceOption;
};
export const InsuranceOptionCard: FunctionComponent<
  InsuranceOptionCardProperties
> = ({ option }) => {
  return (
    <Box flex column className={styles.insuranceOptionCardBox}>
      <Box flex className={styles.insuranceOptionPriceText}>
        <Text inline size={"xxSmall"}>
          {STRINGS.insuranceComparison.insuranceComparisonOptionCardPriceInfo1}{" "}
          {option.price.toFixed(2)}
          {STRINGS.insuranceComparison.insuranceComparisonOptionCardPriceInfo2}
        </Text>
      </Box>
      <Box flex inline>
        <Field
          component={Checkbox}
          name={"titleName"}
          inputClass={styles.insuranceOptionCardCheckbox}
          withoutFormik
        />
        <Box flex column className={styles.insuranceOptionCardContent}>
          <Box flex inline>
            <Text inline size={"small"}>
              {option.title}
            </Text>
            <FontAwesomeIcon
              icon={option.icon}
              className={styles.insuranceOptionCardIcon}
            />
          </Box>
          <Box flex className={styles.insuranceOptionCardDescriptionText}>
            <Text inline size={"xxSmall"}>
              {option.description}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box className={styles.toolTipBox}>
        <ToolTip
          toolTipData={{
            icon: option.toolTipData.icon,
            content: option.toolTipData.content,
          }}
        />
      </Box>
    </Box>
  );
};
