import styles from "components/navigation-bar/MobileNavigation.module.scss";

import React, { ReactElement, useCallback, useState } from "react";
import HamburgerIcon from "components/hamburger-icon/HamburgerIcon";
import LogoImage from "components/logo/LogoImage";
import MobileNavigationOverlay from "components/navigation-bar/MobileNavigationOverlay";
import { RouteUrls } from "config/routes";
import { Link } from "react-router-dom";

const MobileNavigation = (): ReactElement => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const handleBurgerClick = useCallback(() => {
    setIsOverlayVisible((prevState) => !prevState);
  }, [isOverlayVisible]);

  return (
    <nav className={styles.mobileNavigation}>
      <Link to={RouteUrls.homepage}>
        <LogoImage size={"small"} color={"white"} />
      </Link>

      <MobileNavigationOverlay isVisible={isOverlayVisible} />

      <div className={styles.menuOpener}>
        <HamburgerIcon
          isActive={isOverlayVisible}
          onClick={handleBurgerClick}
        />
      </div>
    </nav>
  );
};

export default MobileNavigation;
