import { RouteUrls } from "config/routes";
import useAsyncEffect from "hooks/useAsyncEffect";
import useUserLogin from "hooks/useUserLogin";
import { useNavigate } from "react-router-dom";

const Logout = (): null => {
  const { logOut } = useUserLogin();
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    await logOut();

    navigate(RouteUrls.homepage);
  });

  return null;
};

export default Logout;
