import { useEffect, useState } from "react";

const useMatchMedia = (query: string): boolean => {
  const [doMatch, setDoMatch] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== doMatch) {
      setDoMatch(media.matches);
    }

    const listener = () => setDoMatch(media.matches);
    media.addEventListener("change", listener);

    return () => media.removeEventListener("change", listener);
  }, [query]);

  return doMatch;
};

export default useMatchMedia;
