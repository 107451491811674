import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import DescriptionImage0 from "gfx/images/insurance-images/privathaftpflicht_0.jpg";
import DescriptionImage1 from "gfx/images/insurance-images/privathaftpflicht_1.jpg";
import DescriptionImage2 from "gfx/images/insurance-images/privathaftpflicht_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Privathaftpflicht";

export const privathaftpflichtProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: DescriptionImage0,
  introduction:
    "Die Privathaftpflicht gehört zu den wichtigsten freiwilligen Versicherungen. Sie schützt dich vor den teilweise schwerwiegenden finanziellen Folgen von Personen- und Sachschäden. Denn wer anderen Schaden zufügt, ist laut Gesetz grundsätzlich verpflichtet, diesen zu ersetzen.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title:
        "... du als Radfahrer einen Fußgänger übersiehst und einen Unfall verursachst.",
      text: icon1,
    },
    {
      title:
        "... du dich ehrenamtlich in einem Sportverein engagierst und dabei einen Schaden verursachst.",
      text: icon1,
    },
    {
      title:
        "... du als Spaziergänger zwischen zwei Autos hervortrittst und einen schweren Unfall verursachst.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Du haftest gesetzlich für alle Schäden, die du jemand anderem schuldhaft zufügst. Gerade bei Personenschäden können diese mehrere Millionen Euro betragen.",
        "Eine Haftpflichtversicherung bietet dir und deiner Familie Schutz vor Schadensersatzansprüchen und deckt dabei jede Art an Personen-, Sach- und Vermögensschäden.",
        "Da dein Versicherer stets prüfen wird, ob ein berechtigter Anspruch für einen verpflichtenden Schadensersatz besteht, handelt es sich hierbei ebenfalls um eine passive Rechtsschutzversicherung.",
      ],
      image: DescriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Bei der privaten Haftpflichtversicherung handelt es sich um eine freiwillige Versicherung, die jedem Versicherungsnehmer dringend empfohlen wird.",
        "Aufgrund der uneingeschränkten gesetzlichen Haftung gegenüber Dritten in unbegrenzter Höhe kann dich ein selbstverschuldeter Unfall schnell in finanzielle Bedrängnis bringen.",
      ],

      image: DescriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Kosten der Wiederherstellung bzw. des Ersatzes von beschädigten Gegenständen",
        "Kosten für Folgeschäden wie bei einem Nutzungsausfall",
        "Kosten für Bergung, Behandlung, Verdienstausfall, Umbau von Haus & Wohnung, lebenslange Rente und Schmerzensgeld bei Personenschäden",
        "Abwehr von unberechtigten Ansprüchen",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Schäden, die du vorsätzlich herbeigeführt hast",
        "Schäden, die durch Angehörige deines Haushalts oder Personen, die im Vertrag mitversichert sind, verschuldet wurden",
        "Schäden, an gemieteten, gepachteten oder geliehenen Gegenständen",
        "Schäden, die dir durch Geldstrafen und Bußgelder entstehen",
        "Schäden, die im Zusammenhang mit versicherungspflichtigen Fahrzeugen entstanden sind (z. B. mit Kraftfahrzeugen) – hierfür gibt es eigenständige Haftpflichtversicherungen.",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],
  checklist: [
    {
      title: "Versicherungssumme",
      text: "Insbesondere bei Personenschäden können schnell Schadenersatzansprüche in Millionenhöhe auf dich zukommen. Achte daher stets auf eine ausreichende Versicherungssumme. Du solltest nicht deinen wichtigsten Schutz für eine kleine Ersparnis im Jahresbetrag aufgeben. An deinem wichtigsten finanziellen Schutz für den Ernstfall solltest du keinerlei Abstriche machen.",
    },
    {
      title: "Forderungsausfall",
      text: "Wirst du bei  einem Unfall schwer verletzt und der Unfallverursacher kann nicht für die entstandenen Schadenersatzansprüche, Schmerzensgelder und Folgekosten aufkommen, gehst du ohne eigene Privathaftpflichtversicherung leer aus. Einige Versicherer bieten genau für diesen Fall die Forderungsausfalldeckung an und entschädigen dich als Versicherungsnehmer selbst. Dies beinhaltet im Bedarfsfall auch Kosten für einen behindertengerechten Umbau deines Hauses und vergleichbare Kosten.",
    },
    {
      title: "Gefälligkeitsschäden",
      text: "Hilfst du einem Freund beim Umzug als Gefälligkeit und lässt dabei etwa seinen teuren Fernseher fallen, so unterliegt der entstandene Schaden in der Regel nicht deiner privaten Haftung. Manche Versicherer bieten heute jedoch auch für diesen Fall eine passende Versicherung an, welche Gefälligkeitsschäden abdeckt.",
    },
    {
      title: "Schmerzensgeld",
      text: "Auch wenn eine Situation, welche du verursachst, zu keinem unmittelbaren Sach- oder Personenschaden führt, kannst du beispielsweise aufgrund eines Leidens durch  entstandene psychische Folgen  zu Schmerzensgeld verklagt werden. Auch hier springt deine private Haftpflichtversicherung für dich ein.",
    },
    {
      title: "Selbstbeteiligung",
      text: "Einige Versicherer bieten Produkte/Tarife  an, welche eine Selbstbeteiligung bis zu einem bestimmten Schadenswert fordern und dadurch günstiger werden können. Ob sich das für dich lohnt, hängt stark von deinem Risikoverhalten und deiner Schadenfrequenz ab. Wenn du dir unsicher bist, kannst du dich gerne  kostenlos an unsere Berater wenden.",
    },
  ],
  link: RouteUrls.privatHaftpflicht,
};
