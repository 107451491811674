import { STRINGS } from "language/de_DE/strings";

export const featureMap = new Map<string, string>();

featureMap.set(
  "versicherungssumme",
  STRINGS.featureMap.hausratVersicherungSumme
);
featureMap.set(
  "versicherungssummeFahrrad",
  STRINGS.featureMap.hausratVersicherungSummeFahrrad
);
featureMap.set(
  "versicherungssummeUeberspannungsschutz",
  STRINGS.featureMap.hausratVersicherungSummeUeberspannungsschutz
);
featureMap.set(
  "versicherungssummeWertsachen",
  STRINGS.featureMap.hausratVersicherungSummeWertsachen
);
