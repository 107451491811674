import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/reiseruecktritt_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/reiseruecktritt_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/reiseruecktritt_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Reiserücktrittversicherung";

export const reiseruecktrittProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Eine Reiserücktrittversicherung schützt dich vor hohen Stornogebühren bei Nichtantritt einer bereits gebuchten Urlaubsreise.",

  headerButtons: [
    {
      label: "Vergleichen",
      link: RouteUrls.reiseruecktrittsversicherungVergleich,
    },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title: "... ein Angehöriger oder Mitreisender spontan erkrankt.",
      text: icon1,
    },
    {
      title: "... eine Schwangerschaft zur Absage einer Reise führt.",
      text: icon1,
    },
    {
      title:
        "... die politische Lage der Destination zur Absage der Reise führt.",
      text: icon1,
    },
    {
      title:
        "... bei weiteren unvorhersehbaren Umständen, die eine Reise unmöglich machen.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Aufgrund von Covid-19 und der damit verbundenen Pandemie kam es immer wieder dazu, dass geplante familiäre Zusammenkünfte oder Reisen wegen Infektionen und vorgeschriebenen Quarantänebestimmungen abgesagt werden mussten.",
        "Eine Reiserücktrittversicherung schützt an dieser Stelle vor den hohen Stornierungskosten von eben solchen Reisen.",
        "Reiserücktrittversicherungen gibt es bereits ab einem Jahresbeitrag von ca. 100 Euro im Jahr, wobei sich der Preis daran bemisst, ob man den Tarif für die ganze Familie oder nur für sich selbst abschließen möchte.",
      ],
      image: descriptionImage1,
    },

    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Besonders geeignet ist eine Reiserücktrittversicherung für Menschen, die ein erhöhtes Risiko haben, dass eine Reise storniert werden muss. Dies ist besonders ausgeprägt bei Senioren und Familien mit Kleinkindern.",
        "Außerdem eignet sich eine Reiserücktrittsversicherung auch für  besonders teure Reisen, welche  mit viel Vorlauf gebucht werden.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Unerwartete, schwere Erkrankung – inkl. Covid-19",
        "Unfall",
        "Tod",
        "Schwangerschaft",
        "Schaden am Eigentum",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Bestehende Erkrankungen, die in den letzten 6 Monaten behandelt wurden",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.reiseruecktrittsversicherung,
};
