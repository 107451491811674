import { VertragTyp } from "../../../../../types/backend/VertragTyp";

export const useVertragMapping = () => {
  const kapitalanlagenTypes: VertragTyp[] = [
    "AKTIE",
    "ANLEIHE",
    "BUNDESSCHATZBRIEF",
    "INVESTMENTFONDS",
    "SONDERVERMOEGEN",
    "ZERTIFIKAT",
  ];

  const sparenTypes: VertragTyp[] = [
    "BAUSPARVERTRAG",
    "ENTNAHMEPLAN",
    "FESTGELD",
    "GIROKONTO",
    "SPARBRIEF",
    "SPARBUCH",
    "SPARPLAN",
  ];

  const kfzVersicherungen: VertragTyp[] = ["KFZ_VERSICHERUNG"];
  const krankenversicherungenTypes: VertragTyp[] = [
    "AUSLANDSREISEKRANKENVERSICHERUNG",
    "KRANKENVERSICHERUNG",
    "KRANKENVOLLVERSICHERUNG",
    "KRANKENZUSATZVERSICHERUNG",
    "PFLEGEVERSICHERUNG",
  ];

  const lebensRentenversicherungenTypes: VertragTyp[] = [
    "BASISRENTE",
    "BAV_LEBENSVERSICHERUNG",
    "BAV_RENTENVERSICHERUNG",
    "BEAMTENPENSION",
    "BERUFSSTAENDISCHES_VERSORGUNGSWERK",
    "BERUFSUNFAEHIGKEITSVERSICHERUNG",
    "DREAD_DISEASE",
    "ERWERBSUNFAEHIGKEITSVERSICHERUNG",
    "GESETZLICHE_RENTENVERSICHERUNG",
    "LEBENSVERSICHERUNG",
    "RENTENVERSICHERUNG",
    "RIESTERRENTE",
    "RISIKOLEBENSVERSICHERUNG",
    "UNFALLVERSICHERUNG",
  ];

  const sachHaftpflichtVersicherungenTypes: VertragTyp[] = [
    "BETRIEBSHAFTPFLICHTVERSICHERUNG",
    "BETRIEBSUNTERBRECHUNGSVERSICHERUNG",
    "BERUFSHAFTPFLICHT",
    "ELEKTRONIKVERSICHERUNG",
    "GEWAESSERHAFTPFLICHTVERSICHERUNG",
    "GLASVERSICHERUNG",
    "HAFTPFLICHTVERSICHERUNG",
    "HAUSRATVERSICHERUNG",
    "INHALTSVERSICHERUNG",
    "LUFTUNDWASSERFAHRZEUGSVERSICHERUNG",
    "PHOTOVOLTAIK",
    "RECHTSSCHUTZVERSICHERUNG",
    "TRANSPORTVERSICHERUNG",
    "WOHNGEBAEUDEVERSICHERUNG",
  ];
  const geldanlagenTypes: VertragTyp[] = [
    ...kapitalanlagenTypes,
    ...sparenTypes,
  ];
  const versicherungenTypes: VertragTyp[] = [
    ...kfzVersicherungen,
    ...krankenversicherungenTypes,
    ...lebensRentenversicherungenTypes,
    ...sachHaftpflichtVersicherungenTypes,
  ];

  return {
    geldanlagenTypes,
    versicherungenTypes,
    kapitalanlagenTypes,
    sparenTypes,
    kfzVersicherungen,
    krankenversicherungenTypes,
    lebensRentenversicherungenTypes,
    sachHaftpflichtVersicherungenTypes,
  };
};
