import styles from "components/user-portal/user-profile/dialogs/EditProfileDialog.module.scss";

import React, { ReactNode } from "react";
import { UseMutationResult } from "react-query";
import { Modal } from "react-responsive-modal";
import { Box } from "components/atoms/Box";
import { Button } from "components/form-inputs/Button/Button";
import { Toast } from "components/message/Toast";
import { STRINGS } from "language/de_DE/strings";
import { KundeRequest } from "types/backend/KundeRequest";

interface EditProfileDialogProps {
  open: boolean;
  onClose: () => void;
  formId: string;
  mutation: UseMutationResult<unknown, unknown, KundeRequest>;
  children: ReactNode;
}

const EditProfileDialog: React.FC<EditProfileDialogProps> = ({
  open: isPopUpOpen,
  onClose,
  formId,
  mutation,
  children,
}) => {
  const handleCloseEdit = () => {
    onClose();
  };

  return (
    <Modal
      open={isPopUpOpen}
      onClose={handleCloseEdit}
      center
      onAnimationEnd={mutation.reset}
    >
      <Box className={styles.contentContainer}>
        <h3>{STRINGS.profile.edit.title}</h3>
        {mutation.isSuccess && (
          <div>
            <p>{STRINGS.profile.edit.success}</p>
            <div className={styles.infoDialogButton}>
              <Button
                label={STRINGS.profile.edit.ok}
                onClick={handleCloseEdit}
              />
            </div>
          </div>
        )}
        {!mutation.isSuccess && (
          <>
            {children}
            <div className={styles.buttonContainer}>
              <Button
                label={STRINGS.profile.edit.cancel}
                type="button"
                onClick={handleCloseEdit}
                className={styles.cancelButton}
                primary={false}
              />
              <Button
                label={STRINGS.profile.edit.save}
                type="submit"
                className={styles.submitButton}
                form={formId}
              />
            </div>
            {mutation.isError && (
              <div className={styles.errorMessage}>
                <Toast text={STRINGS.profile.edit.error} type="error" />
              </div>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default EditProfileDialog;
