import styles from "components/demand-calculator/input-form/DemandCalculatorInputForm.module.scss";

import React, { ReactElement, useState } from "react";
import AccordionBox from "../../accordion/AccordionBox";
import { STRINGS } from "../../../language/de_DE/strings";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { Button } from "../../form-inputs/Button/Button";
import { useDemandCalculatorFormValues } from "../useDemandCalculatorFormValues";
import fetchDemandCalculator from "../../../api/requests/fetchDemandCalculator";
import { DemandCalculatorInputFormType } from "./DemandCalculatorInputFormType";
import { BedarfsCheckResponse } from "../../../types/backend/BedarfsCheckResponse";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import useDebounce from "../../../hooks/useDebounce";
import { FormValues } from "../../form-inputs/FormValues";
import useUserState from "../../../hooks/useUserState";
import { convertToRequest } from "../util/demandCalculatorUtil";
import { DemandCalculatorInputFormPersonal } from "./DemandCalculatorInputFormPersonal";
import { DemandCalculatorInputFormIncome } from "./DemandCalculatorInputFormIncome";
import { DemandCalculatorInputFormRisk } from "./DemandCalculatorInputFormRisk";
import { DemandCalculatorInputFormAssets } from "./DemandCalculatorInputFormAssets";

type FormObserverType = {
  onSubmit: (values: DemandCalculatorInputFormType) => void;
};

const FormObserver = ({ onSubmit }: FormObserverType): ReactElement | null => {
  const { values } = useFormikContext<DemandCalculatorInputFormType>();
  const debouncedValues = useDebounce(values, 500);

  useUpdateEffect(() => {
    onSubmit(values);
  }, [debouncedValues]);
  return null;
};

type AccordionType = "personal" | "income" | "risk" | "assets";

type DemandCalculatorInputFormProps = {
  formId: string;
  onSubmit: (response: BedarfsCheckResponse, formValues: FormValues) => void;
  onReset: () => void;
};

export const DemandCalculatorInputForm = ({
  formId,
  onSubmit,
  onReset,
}: DemandCalculatorInputFormProps): ReactElement | null => {
  const { kunde } = useUserState();
  const initialValues = useDemandCalculatorFormValues();
  const [openAccordion, setOpenAccordion] = useState<AccordionType>("personal");

  const handleSubmit = (values: DemandCalculatorInputFormType) => {
    fetchDemandCalculator(convertToRequest(values), kunde).then((response) => {
      onSubmit(response, values);
    });
  };

  const resetForm = (formProps: Partial<FormValues>) => {
    formProps.resetForm(initialValues);
    onReset();
    setOpenAccordion("personal");
  };

  if (!initialValues) return null;
  return (
    <Formik
      initialValues={{ ...initialValues }}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {(formProps: FormikProps<DemandCalculatorInputFormType>) => {
        return (
          <Form id={formId}>
            <FormObserver onSubmit={(values) => handleSubmit(values)} />
            <div className={styles.accordionContainer}>
              <AccordionBox
                title={STRINGS.demandCalculator.personal.title}
                isOpen={openAccordion === "personal"}
                onHeaderClick={() => setOpenAccordion("personal")}
              >
                <DemandCalculatorInputFormPersonal formProps={formProps} />
              </AccordionBox>
              <AccordionBox
                title={STRINGS.demandCalculator.income.title}
                isOpen={openAccordion === "income"}
                onHeaderClick={() => setOpenAccordion("income")}
              >
                <DemandCalculatorInputFormIncome formProps={formProps} />
              </AccordionBox>
              <AccordionBox
                title={STRINGS.demandCalculator.risks.title}
                isOpen={openAccordion === "risk"}
                onHeaderClick={() => setOpenAccordion("risk")}
              >
                <DemandCalculatorInputFormRisk />
              </AccordionBox>
              <AccordionBox
                title={STRINGS.demandCalculator.assets.title}
                isOpen={openAccordion === "assets"}
                onHeaderClick={() => setOpenAccordion("assets")}
              >
                <DemandCalculatorInputFormAssets formProps={formProps} />
              </AccordionBox>
              <div className={styles.controlContainer}>
                <Button
                  label={STRINGS.demandCalculator.control.reset}
                  onClick={() => resetForm(formProps)}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
