import React, { FunctionComponent } from "react";
import PageSection from "../../page-section/PageSection";
import UnstyledLink from "../../unstyle-link/UnstyledLink";
import { Button } from "../../form-inputs/Button/Button";
import { STRINGS } from "../../../language/de_DE/strings";
import styles from "./CampaignInsuranceEnumeration.module.scss";
import { IoCheckmarkSharp } from "react-icons/io5";

export type InsuranceEnumerationDescription = {
  name: string;
  image: string;
  description: string;
  checklistItems: string[];
  checklistIntroduction: string;
  buttonLink: string;
};

type Props = {
  title: string;
  insurances: InsuranceEnumerationDescription[];
};

export const CampaignInsuranceEnumeration: FunctionComponent<Props> = ({
  insurances,
  title,
}) => (
  <PageSection background={"grey"}>
    <h1 className={styles.title}>{title}</h1>
    <div className={styles.insurances}>
      {insurances.map((insurance) => (
        <div key={insurance.name} className={styles.insuranceWrapper}>
          <div className={styles.insurance}>
            <h2 className={styles.insuranceTitle}>{insurance.name}</h2>
            <img
              src={insurance.image}
              alt={insurance.name}
              className={styles.insuranceImage}
            />
            <p>{insurance.description}</p>
            <p>{insurance.checklistIntroduction}</p>
            <div>
              {insurance.checklistItems.map((item) => (
                <div key={item} className={styles.checklistItem}>
                  <IoCheckmarkSharp className={styles.checkmark} />
                  {item}
                </div>
              ))}
            </div>
          </div>
          <UnstyledLink
            to={insurance.buttonLink}
            className={styles.compareNowLink}
          >
            <Button
              label={STRINGS.campaigns.compareAndSignButtonLabel}
              className={styles.compareNowButton}
            />
          </UnstyledLink>
        </div>
      ))}
    </div>
  </PageSection>
);
