import styles from "pages/startpage/Startpage.module.scss";

import React, { FunctionComponent } from "react";
import { Overview } from "components/molecules/startpage/sections/Overview/Overview";
import Page from "components/page/Page";
import { MyNormCarousel } from "../../components/molecules/startpage/sections/MyNormCarousel/MyNormCarousel";
import { Bubbles } from "../../components/molecules/startpage/sections/Bubbles/Bubbles";
import { ProductTeaser } from "../../components/molecules/startpage/sections/ProductTeaser/ProductTeaser";
import { ContractCheckTeaser } from "../../components/molecules/startpage/sections/ContractCheckTeaser/ContractCheckTeaser";
import { MyNormBoasting } from "../../components/molecules/startpage/sections/MyNormBoasting/MyNormBoasting";
import { Faq } from "../../components/molecules/startpage/sections/Faq/Faq";
import { RegisterNow } from "../../components/molecules/startpage/sections/RegisterNow/RegisterNow";

export const Startpage: FunctionComponent = () => (
  <Page contentClassName={styles.content}>
    <Overview />
    <MyNormCarousel />
    <Bubbles />
    <ProductTeaser />
    <ContractCheckTeaser />
    <MyNormBoasting />
    <RegisterNow />
    <Faq />
  </Page>
);
