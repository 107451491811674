import React, { FunctionComponent, ReactNode } from "react";
import styles from "./InsuranceBox.module.scss";

type BoxProps = {
  image: string;
  title: string;
  children: ReactNode;
};

export const InsuranceBox: FunctionComponent<BoxProps> = ({
  image,
  title,
  children,
}) => (
  <div className={styles.box}>
    <div className={styles.upperWrapper}>
      <h3 className={styles.boxTitle}>{title}</h3>
      <img src={image} alt={title} className={styles.boxIconImage} />
    </div>

    {children}
  </div>
);
