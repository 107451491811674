import React, { ReactElement } from "react";
import { createOptions } from "../../../../../utils/formInputUtils";
import { STRINGS } from "../../../../../language/de_DE/strings";
import { AddContractSelection } from "./base/AddContractSelection";
import { AddContractInputBaseType } from "./AddContractInputBaseType";
import { useVertragMapping } from "./useVertragMapping";
import { validateRequiredOnTypes } from "./util/validationUtil";
import { useFormikContext } from "formik";
import { AddContractFormType } from "../AddContractFormType";
import { shouldDisplayField } from "./util/displayInputUtil";

const options = createOptions(STRINGS.vertrag.usageType);

export const VerwendungszweckSelection = ({
  vertragTyp,
  activeStep,
}: AddContractInputBaseType): ReactElement | null => {
  const { geldanlagenTypes } = useVertragMapping();
  const { values } = useFormikContext<AddContractFormType>();

  const validate = () => {
    if (activeStep !== 2) return;
    return validateRequiredOnTypes(
      geldanlagenTypes,
      STRINGS.addContract.step2.usageError,
      vertragTyp,
      values.verwendungszweck
    );
  };

  if (!shouldDisplayField(vertragTyp, geldanlagenTypes)) return null;
  return (
    <AddContractSelection
      name="verwendungszweck"
      options={options}
      labelText={STRINGS.addContract.step2.usageLabel}
      validate={validate}
    />
  );
};
