import React, { ReactElement } from "react";
import baseStyles from "../form-sections/AddContractFormBase.module.scss";
import styles from "../form-sections/AddContractFormStep1.module.scss";
import { Field, FormikProps } from "formik";
import { Checkbox } from "../../../form-inputs/Checkbox/Checkbox";
import { ContractSelection } from "./inputs/base/ContractSelection";
import { AddContractTextInput } from "./inputs/base/AddContractTextInput";
import { createVertragInstitutOptions } from "../../../../utils/vertragUtils";
import { STRINGS } from "../../../../language/de_DE/strings";
import { AddContractFormType } from "./AddContractFormType";
import { AddContractSelection } from "./inputs/base/AddContractSelection";

interface AddContractFormStep1Props {
  formProps: FormikProps<AddContractFormType>;
}

const institutOptions = createVertragInstitutOptions();

export const AddContractFormStep1 = ({
  formProps,
}: AddContractFormStep1Props): ReactElement => {
  return (
    <div className={styles.contractBaseSection}>
      <div>
        <h4 className={baseStyles.sectionHeader}>
          {STRINGS.addContract.step1.productTypSelection}
        </h4>
        <div className={styles.produktTypOptions}>
          <Field
            component={Checkbox}
            type={"radio"}
            labelText={
              STRINGS.addContract.step1.productTypSelectionOptions.insurance
            }
            name="produktTyp"
            value="VERSICHERUNG"
            inputClass={styles.produktTypOptionInput}
            labelClass={styles.produktTypOptionLabel}
          />
          <Field
            component={Checkbox}
            type={"radio"}
            labelText={
              STRINGS.addContract.step1.productTypSelectionOptions
                .financialInvestments
            }
            name="produktTyp"
            value="GELDANLAGE"
            inputClass={styles.produktTypOptionInput}
            labelClass={styles.produktTypOptionLabel}
          />
          <Field
            component={Checkbox}
            type={"radio"}
            labelText={
              STRINGS.addContract.step1.productTypSelectionOptions.other
            }
            name={"produktTyp"}
            value="SONSTIGE"
            inputClass={styles.produktTypOptionInput}
            labelClass={styles.produktTypOptionLabel}
          />
        </div>
      </div>
      <div className={styles.contractTypeSelection}>
        <div>
          <h4 className={baseStyles.sectionHeader}>
            {formProps.values.produktTyp !== "SONSTIGE"
              ? STRINGS.addContract.step1.contractTypSelection
              : STRINGS.addContract.step1.customContractTyp}
          </h4>
          <ContractSelection name="typ" />
          {formProps.values.produktTyp !== "SONSTIGE" &&
            formProps.values.typ === "SONSTIGE_VERTRAEGE" && (
              <>
                <h4 className={baseStyles.sectionHeader}>
                  {STRINGS.addContract.step1.customContractTyp}
                </h4>
                <Field component={AddContractTextInput} name="customTyp" />
              </>
            )}
        </div>
        <div>
          <h4 className={baseStyles.sectionHeader}>
            {STRINGS.addContract.step1.instituteSelection}
          </h4>
          <AddContractSelection name="institut" options={institutOptions} />
          {formProps.values.institut === "SONSTIGE" && (
            <>
              <h4 className={baseStyles.sectionHeader}>
                {STRINGS.addContract.step1.customInstitute}
              </h4>
              <Field component={AddContractTextInput} name="customInstitut" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
