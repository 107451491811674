import React from "react";
import {
  EditableUserProfileSingleSelect,
  EditableUserProfileTextfield,
} from "components/user-portal/user-profile/dialogs/item/EditableUserProfileItem";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { formatIban } from "utils/formatUtils";
import { createOptions } from "utils/formInputUtils";
import { Form, Formik } from "formik";
import { FormValues } from "../../../../form-inputs/FormValues";
import { UseMutationResult } from "react-query";
import { KundeRequest } from "../../../../../types/backend/KundeRequest";

interface EditBankingAccountFormProps {
  formId: string;
  kunde: KundeResponse;
  mutation: UseMutationResult<unknown, unknown, KundeRequest>;
}

const kontoArtOptions = createOptions(
  STRINGS.profile.bankingAccount.accountTypes
);

const EditBankingAccountForm: React.FC<EditBankingAccountFormProps> = ({
  formId,
  kunde,
  mutation,
}) => {
  const isDepot = (formValues: FormValues) => {
    return (
      formValues.bankverbindungen &&
      formValues.bankverbindungen.length > 0 &&
      formValues.bankverbindungen[0].kontoArt === "DEPOT"
    );
  };

  return (
    <Formik
      initialValues={{ ...(kunde || {}) }}
      onSubmit={(values) => mutation.mutate(values)}
      enableReinitialize={true}
    >
      {(formProps) => {
        return (
          <Form id={formId}>
            <EditableUserProfileSingleSelect
              fieldName="bankverbindungen.0.kontoArt"
              options={kontoArtOptions}
              labelText={STRINGS.profile.bankingAccount.accountType}
            />
            <EditableUserProfileTextfield
              fieldName="bankverbindungen.0.inhaber"
              labelText={STRINGS.profile.bankingAccount.owner}
            />
            <EditableUserProfileTextfield
              fieldName="bankverbindungen.0.institut"
              labelText={STRINGS.profile.bankingAccount.institut}
            />
            <EditableUserProfileTextfield
              fieldName="bankverbindungen.0.iban"
              labelText={STRINGS.profile.bankingAccount.iban}
              formatFn={formatIban}
            />
            <EditableUserProfileTextfield
              fieldName="bankverbindungen.0.bic"
              labelText={STRINGS.profile.bankingAccount.bic}
            />
            {isDepot(formProps.values) && (
              <EditableUserProfileTextfield
                fieldName="bankverbindungen.0.depot"
                labelText={STRINGS.profile.bankingAccount.depot}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditBankingAccountForm;
