import axios from "axios";
import api from "api/index";
import { appConfig } from "config/appConfig";
import { isOk } from "utils/axiosHelpers";

const fetchDokumentFile = async (
  kid: number,
  vertragId: number,
  dokumentId: number
): Promise<Blob> => {
  const url = `${appConfig.apiUrl}/api/kunden/${kid}/vertraege/${vertragId}/dokumente/${dokumentId}`;
  const header = await api.getHeaderWithAuthorization();
  const result = await axios.get<Blob>(url, {
    headers: header,
    responseType: "blob",
    validateStatus: isOk,
  });
  return result.data;
};
export default fetchDokumentFile;
