import styles from "components/user-portal/user-message-box/MessageDialog.module.scss";

import React, { ReactElement, useEffect, useState } from "react";
import { STRINGS } from "../../../language/de_DE/strings";
import { Button } from "../../form-inputs/Button/Button";
import { Field, Form, Formik } from "formik";
import { TextField } from "../../form-inputs/TextField/TextField";
import { useSendNachricht } from "../../../api/useNachrichten";
import { FormValues } from "../../form-inputs/FormValues";
import { Modal } from "react-responsive-modal";

interface MessageDialogProps {
  kid: number;
  messageTitle?: string;
  messageBody?: string;
}

export const MessageDialog = ({
  kid,
  messageTitle,
  messageBody,
}: MessageDialogProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { mutate, isSuccess, isError } = useSendNachricht();
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
    if (isError) {
      setShowErrorMessage(true);
    }
  }, [isSuccess, isError]);

  const handleSave = (formValues: FormValues) => {
    mutate({
      kid,
      nachrichtRequest: {
        betreff: formValues.title,
        text: formValues.body,
      },
    });
  };

  const resetDialog = () => {
    setShowErrorMessage(false);
  };

  return (
    <>
      <Button
        label={STRINGS.messageBox.controls.newMessage}
        onClick={() => setIsOpen(true)}
      />
      <Modal
        open={isOpen}
        onClose={handleClose}
        center
        classNames={{ modal: styles.modalDialog }}
        onAnimationEnd={resetDialog}
      >
        <h2>{STRINGS.messageBox.messageDialog.header}</h2>
        <Formik
          initialValues={{
            title: messageTitle || "",
            body: messageBody || "",
          }}
          onSubmit={handleSave}
          enableReinitialize={true}
          validateOnBlur={false}
        >
          {() => {
            return (
              <Form>
                <div className={styles.messageForm}>
                  <div className={styles.fields}>
                    <Field
                      component={TextField}
                      name={"title"}
                      labelText={STRINGS.messageBox.messageDialog.title}
                      validate={(value: string) =>
                        !value
                          ? STRINGS.messageBox.messageDialog.titleValidation
                          : undefined
                      }
                    />
                    <Field
                      component={TextField}
                      name={"body"}
                      labelText={STRINGS.messageBox.messageDialog.body}
                      textarea={true}
                      validate={(value: string) =>
                        !value
                          ? STRINGS.messageBox.messageDialog.bodyValidation
                          : undefined
                      }
                    />
                  </div>
                  {showErrorMessage && (
                    <div className={styles.errorMessage}>
                      {STRINGS.messageBox.messageDialog.errorMessage}
                    </div>
                  )}
                  <div className={styles.controls}>
                    <Button
                      label={STRINGS.messageBox.messageDialog.controls.cancel}
                      primary={false}
                      onClick={handleClose}
                    />
                    <Button
                      label={STRINGS.messageBox.messageDialog.controls.send}
                      type="submit"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
