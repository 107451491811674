import axios from "axios";
import api from "api/index";
import { appConfig } from "config/appConfig";
import { KundeRequest } from "types/backend/KundeRequest";
import { isCreated } from "utils/axiosHelpers";

const createKunde = async (kunde: KundeRequest): Promise<void> => {
  const url = `${appConfig.apiUrl}/api/kunden`;
  const header = await api.getHeaderWithAuthorization();
  await axios.post(url, kunde, { headers: header, validateStatus: isCreated });
};

export default createKunde;
