import React, { FunctionComponent, ReactNode } from "react";
import styles from "./InsuranceBenefitComparisonBox.module.scss";
import { InsuranceBox } from "../../InsuranceBox/InsuranceBox";
import clsx from "clsx";
import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";

type ActionBoxProps = {
  image: string;
  title: string;
  acceptedTitle: string;
  acceptedItems: string[];
  notAcceptedTitle: string;
  notAcceptedItems: string[];
};

type ListItemProps = {
  isPositive: boolean;
  children: ReactNode;
};

const ListItem: FunctionComponent<ListItemProps> = ({
  isPositive,
  children,
}) => (
  <li
    className={clsx(styles.listItem, {
      [styles.positive]: isPositive,
      [styles.negative]: !isPositive,
    })}
  >
    <span className={styles.listIcon}>
      {isPositive ? (
        <IoCheckmarkSharp size={"2em"} />
      ) : (
        <IoCloseSharp size={"2em"} />
      )}
    </span>
    <span>{children}</span>
  </li>
);

export const InsuranceBenefitComparisonBox: FunctionComponent<
  ActionBoxProps
> = ({
  image,
  title,
  acceptedTitle,
  acceptedItems,
  notAcceptedItems,
  notAcceptedTitle,
}) => (
  <InsuranceBox image={image} title={title}>
    <hr className={styles.separator} />
    <div className={styles.upperWrapper}>
      <h4 className={styles.smallTitle}>{acceptedTitle}</h4>
      <ul className={clsx(styles.bullets, styles.positive)}>
        {acceptedItems.map((bullet) => (
          <ListItem key={bullet} isPositive={true}>
            {bullet}
          </ListItem>
        ))}
      </ul>
    </div>
    <hr className={styles.separator} />
    <h4 className={styles.smallTitle}>{notAcceptedTitle}</h4>
    <ul className={clsx(styles.bullets, styles.negative)}>
      {notAcceptedItems.map((bullet) => (
        <ListItem key={bullet} isPositive={false}>
          {bullet}
        </ListItem>
      ))}
    </ul>
  </InsuranceBox>
);
