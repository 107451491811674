import axios from "axios";
import api from "api/index";
import { appConfig } from "config/appConfig";
import { DeleteDokumentRequest } from "types/userportal/DeleteDokumentRequest";
import { isAccepted } from "utils/axiosHelpers";

const deleteDokument = async (
  request: DeleteDokumentRequest
): Promise<void> => {
  const url = `${appConfig.apiUrl}/api/kunden/${request.kid}/vertraege/${request.vertragId}/dokumente/${request.dokument.id}`;
  const header = await api.getHeaderWithAuthorization();
  await axios.delete(url, {
    headers: header,
    validateStatus: isAccepted,
  });
};

export default deleteDokument;
