import { groupBy } from "lodash";
import { Option } from "../components/form-inputs/Selections/SingleSelect";

export const inputDateFormat = "yyyy-MM-dd";

export const createAlphabeticallyGroupedOptions = (
  types: Record<string, string>
): Option[] => {
  const grouped = groupBy(Object.keys(types), (value) => value[0]);
  return Object.keys(grouped).map((groupKey) => {
    return {
      label: groupKey,
      options: grouped[groupKey].map((value: string) => {
        return {
          label: types[value],
          value: value,
        };
      }),
    };
  });
};

export const createOptions = (types: Record<string, string>): Option[] => {
  return Object.keys(types).map((type) => {
    return {
      label: types[type],
      value: type,
    };
  });
};
