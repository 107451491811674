import React, { FunctionComponent } from "react";
import { UseMutationResult } from "react-query";
import { STRINGS } from "language/de_DE/strings";
import { AddDokumenteRequest } from "types/userportal/AddDokumenteRequest";
import { DeleteDokumentRequest } from "types/userportal/DeleteDokumentRequest";
import { DeleteResult } from "types/backend/DeleteResult";

interface EditDokumenteResultProps {
  deleteResult: DeleteResult[];
  addDokumentMutation: UseMutationResult<unknown, unknown, AddDokumenteRequest>;
  deleteDokumentMutation: UseMutationResult<
    unknown,
    unknown,
    DeleteDokumentRequest
  >;
}

export const EditDokumenteResult: FunctionComponent<
  EditDokumenteResultProps
> = ({ deleteResult, addDokumentMutation }) => {
  const deleteSuccess = deleteResult.filter((result) => result.success);
  const deleteErrors = deleteResult.filter((result) => !result.success);

  return (
    <div>
      {addDokumentMutation.isError && (
        <div>{STRINGS.vertrag.documents.dialog.messages.addError}</div>
      )}
      {addDokumentMutation.isSuccess && (
        <div>{STRINGS.vertrag.documents.dialog.messages.addSuccess}</div>
      )}
      <EditDokumentsResultDeleteMessage
        deleteResult={deleteErrors}
        message={STRINGS.vertrag.documents.dialog.messages.deleteError}
      />
      <EditDokumentsResultDeleteMessage
        deleteResult={deleteSuccess}
        message={STRINGS.vertrag.documents.dialog.messages.deleteSuccess}
      />
    </div>
  );
};

interface EditDokumentsResultDeleteMessageProps {
  deleteResult: DeleteResult[];
  message: (name: string) => string;
}

const EditDokumentsResultDeleteMessage: FunctionComponent<
  EditDokumentsResultDeleteMessageProps
> = ({ deleteResult, message }) => {
  if (deleteResult.length === 0) return <></>;
  return (
    <div>
      {deleteResult.map((result: DeleteResult) => {
        return (
          <div key={result.dokumentName}>
            {message(result.dokumentName)}
            <br />
          </div>
        );
      })}
    </div>
  );
};
