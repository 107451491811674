import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import insuranceTermLifeImage from "gfx/images/insurance-images/risikoleben_0.jpg";
import DescriptionImage1 from "gfx/images/insurance-images/risikoleben_1.jpg";
import DescriptionImage2 from "gfx/images/insurance-images/risikoleben_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Risikolebensversicherung";

export const risikolebenProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: insuranceTermLifeImage,
  introduction:
    "Deine Risikolebensversicherung schützt Hinterbliebene vor hohen finanziellen Belastungen im Falle deines Ablebens. Sie sichert die weitere finanzielle Stabilität und dadurch das Nachkommen fortlaufender wirtschaftlicher Verbindlichkeiten.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title:
        "... durch deinen Todesfall ein enormer finanzieller Druck für deine Familie entsteht.",
      text: icon1,
    },
    {
      title:
        "... du nicht mit deinem Partner verheiratet bist und im Todesfall keine Witwen- oder Witwerrente erhältst.",
      text: icon1,
    },
    {
      title:
        "... beide Partner zum Einkommen beitragen und ihr euch durch eine verbundene Risikolebensversicherung absichern wollt.",
      text: icon1,
    },
    {
      title:
        "... du unabhängig von deinem Vermögen und deinem möglichen Ableben eine finanzielle Stabilität für die Entwicklung deiner Kinder schaffen möchtest.",
      text: icon1,
    },
    {
      title:
        "... du und dein Partner/deine Partnerin zusammen euer Eigenheim abzahlen und du unerwartet verstirbst.",
      text: icon1,
    },
  ],
  longDescriptions: [
    {
      title: "Was ist eine " + productName + " ?",
      paragraphs: [
        "Eine Risikolebensversicherung dient als Todesfallschutz innerhalb der Familie – kann aber auch unter Geschäftspartnern relevant werden.",
        "Es wird das Risiko abgesichert, dass Hinterbliebene in finanzielle Notsituationen geraten, weil sie zum Beispiel hinterlassene Kredite nicht tilgen können oder das Einkommen des verstorbenen Angehörigen einen entscheidenden Anteil an den Lebenserhaltungskosten im Haushalt beigetragen hat.",
        "Da sich die Risikolebensversicherung aus mehreren individuellen Faktoren errechnet, ist es sinnvoll diese früh abzuschließen.",
        "Junge, gesunde Menschen zahlen oftmals wesentlich geringere Beiträge als ältere Personen und tun sich auch mit den geforderten Gesundheitsfragen der Gesellschaften weniger schwer, da sie statistisch ein geringeres Erkrankungsrisiko aufweisen und häufig noch wenige bis keine Vorerkrankungen bestehen.",
        "Es ist sinnvoll, eine Risikolebensversicherung abzuschließen, wenn man erwägt, risikoreiche Freizeitaktivitäten wie z. B. Fallschirmsprünge oder Motorradrennfahrten zu verfolgen",
      ],
      image: DescriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + " ?",
      paragraphs: [
        "Eine Risikolebensversicherung eignet sich vor allem für Familien, in denen ein finanzielles Risiko besteht, sollte der Hauptverdiener plötzlich und unerwartet versterben.",
        "Ist der hinterbliebene Elternteil stark beruflich gefordert, kommen aufgrund der neuen Situation neue finanzielle Verbindlichkeiten auf, z. B. das Engagieren und Einstellen einer Teilzeitkraft für die Nachmittagsbetreuung der Kinder oder neben den Kosten für die Beerdigung auch die Kosten der Grabpflege und den Friedhofsstellplatz.",
        "In Geschäftsbeziehungen kann diese Art von Versicherung unter Geschäftspartnern verwendet werden, beispielsweise um einen Kredit abzusichern oder die wirtschaftliche Stabilität eines Unternehmens auch bei Tod eines der Geschäftspartner weiterhin zu sichern.",
        "Zusätzlich schafft dieses Versicherungsprodukt auch mehr Lebensqualität und stärkt die Resilienz. Mit dem Abschluss einer Risikolebensversicherung schafft man sich und seinen Angehörigen die Sicherheit und Gewissheit, dass selbst im Falle des eigenen Todes der Lebensstandard wie bisher weitergeführt werden kann.",
      ],

      image: DescriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "ausschließlich bei Todesfall des Versicherten wird die vereinbarte Todesfallsumme per Einmalauszahlung fällig",
        "bei begrenzter Lebenserwartung aufgrund von akuten lebensbedrohlichen Krankheitsbildern ein Teil oder auch manchmal die vollständige Todesfallsumme vorab auszahlbar (abhängig von jeweiliger Gesellschaft)",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "Verletzungen",
        "Suizid (wenn Vertragsschluss weniger als 3 Jahre her ist)",
        "Gewaltsamer Tod durch Bezugsberechtigten",
      ],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  link: RouteUrls.risikolebensversicherung,
};
