import styles from "components/molecules/InsuranceDataBox.module.scss";

import React, { FunctionComponent } from "react";
import { Box } from "components/atoms/Box";
import { Button } from "components/form-inputs/Button/Button";
import { Checkbox } from "components/form-inputs/Checkbox/Checkbox";
import { Image } from "components/atoms/Image";
import { Text } from "components/atoms/Text";
import { InsuranceFeatureList } from "components/molecules/InsuranceFeatureList";
import { InsuranceRating } from "components/molecules/InsuranceRating";
import { PaymentPeriod, Price } from "components/molecules/Price";
import UnstyledLink from "components/unstyle-link/UnstyledLink";
import { RouteUrls } from "config/routes";
import { STRINGS } from "language/de_DE/strings";
import { HausratVersicherungDetails } from "types/insuranceComparisonData/Hausratversicherug/HausratVersicherungDetails";
import { Field } from "formik";

export type InsuranceDataBoxProperties = {
  insuranceData: HausratVersicherungDetails;
};
export const InsuranceDataBox: FunctionComponent<
  InsuranceDataBoxProperties
> = ({ insuranceData }) => {
  return (
    <Box flex column className={styles.insuranceDataBox}>
      <Box flex column>
        <Box flex inline>
          <Box flex column className={styles.insuranceInfoBox}>
            <Box column>
              <Text inline size={"medium"}>
                {insuranceData.tarif.gesellschaft}
              </Text>
            </Box>
            <Box column className={styles.detailsLink}>
              <Text inline size={"small"}>
                {
                  STRINGS.insuranceComparison
                    .insuranceComparisonInsuranceDataBoxDetails
                }
              </Text>
            </Box>
            <Box column>
              <Image
                className={styles.insuranceImage}
                src={
                  "https://via.placeholder.com/108x87/515A80/FFFFFF?text=Logo"
                }
                alt={"insuranceLogo"}
              />
            </Box>
          </Box>
          <Box flex className={styles.featureListBox}>
            <InsuranceFeatureList features={insuranceData.deckungssummen} />
          </Box>
          <Box flex className={styles.verticalRule} />
          <Box flex column className={styles.priceInformation}>
            <Box flex column className={styles.insurancePriceBox}>
              {insuranceData.praemien.bruttopraemien
                .filter(
                  (rates) =>
                    rates.zahlungsart === "LASTSCHRIFT" &&
                    rates.zahlweise === "MONATLICH" &&
                    rates.zahlweiseteilung
                )
                .map((rate, index) => (
                  <Price
                    key={index}
                    variant={"highlight"}
                    value={new Intl.NumberFormat("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(rate.praemie)}
                    period={PaymentPeriod.MONATLICH}
                  />
                ))}
              {insuranceData.praemien.bruttopraemien
                .filter(
                  (rates) =>
                    rates.zahlungsart === "LASTSCHRIFT" &&
                    rates.zahlweise === "JAEHRLICH" &&
                    !rates.zahlweiseteilung
                )
                .map((rate, index) => (
                  <Price
                    key={index}
                    variant={"regular"}
                    value={new Intl.NumberFormat("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(rate.praemie)}
                    period={PaymentPeriod.JAEHRLICH}
                  />
                ))}
            </Box>
            <Box flex className={styles.callToActionBtn}>
              <UnstyledLink
                to={RouteUrls.hausratversicherungVergleichAbschlussStep2}
              >
                <Button
                  label={
                    STRINGS.insuranceComparison
                      .insuranceComparisonInsuranceDataBoxButton
                  }
                  primary
                />
              </UnstyledLink>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box flex inline className={styles.ratingBox}>
        <Box flex className={styles.compareBox}>
          <Field
            component={Checkbox}
            name={"compare"}
            labelText={
              STRINGS.insuranceComparison
                .insuranceComparisonInsuranceDataBoxCompareLabel
            }
            inputClass={styles.ratingCompareNow}
            labelClass={styles.ratingCompareNowLabel}
            withoutFormik
          />
        </Box>
        <Box flex>
          <InsuranceRating rating={insuranceData.rating.leistung} />
        </Box>
      </Box>
    </Box>
  );
};
