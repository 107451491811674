import axios from "axios";
import api from "api/index";
import { appConfig } from "config/appConfig";
import { KundeRequest } from "types/backend/KundeRequest";
import { isAccepted } from "utils/axiosHelpers";

const updateKunde = async (kunde: KundeRequest): Promise<void> => {
  const url = `${appConfig.apiUrl}/api/kunden/${kunde.kid}`;
  const header = await api.getHeaderWithAuthorization();
  await axios.patch(url, kunde, {
    headers: header,
    validateStatus: isAccepted,
  });
};

export default updateKunde;
