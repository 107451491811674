import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import api from "api/index";
import { KundeRequest } from "types/backend/KundeRequest";
import { KundeResponse } from "types/backend/KundeResponse";

export const KUNDE_SELF_KEY = "/api/kunden/self";

export const useSelf = (enabled: boolean): UseQueryResult<KundeResponse> => {
  return useQuery(KUNDE_SELF_KEY, api.fetchKunde, {
    enabled,
    refetchOnWindowFocus: false,
  });
};

export const useUpdateSelf = (): UseMutationResult<
  unknown,
  unknown,
  KundeRequest
> => {
  const queryClient = useQueryClient();
  return useMutation(api.updateKunde, {
    onSuccess: () => queryClient.invalidateQueries(KUNDE_SELF_KEY),
  });
};

export const useCreateSelf = (): UseMutationResult<
  unknown,
  unknown,
  KundeRequest
> => {
  const queryClient = useQueryClient();
  return useMutation(api.createKunde, {
    onSuccess: () => queryClient.invalidateQueries(KUNDE_SELF_KEY),
  });
};
