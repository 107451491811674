import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/drohne_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/drohne_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/drohne_2.jpg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Drohnenversicherung";

export const drohnenversicherungProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Drohnen sind heute nicht mehr wegzudenken und können besondere Aufgaben für die gewerbliche Nutzung oder die private Freizeitgestaltung übernehmen. Gleichzeitig gehen von ihnen erhebliche Risiken für Mensch und Umwelt aus, weshalb die Drohnenversicherung in Deutschland gesetzlich verpflichtend ist.",

  headerButtons: [
    { label: "Vergleichen", link: RouteUrls.drohnenversicherungVergleich },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],
  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Die Drohnenversicherung schützt dich als Versicherungsnehmer vor den finanziellen Konsequenzen, wenn dein Flugmodell fremde Gegenstände beschädigt oder andere Personen verletzt. Ohne Haftpflichtschutz bist du als Drohnenpilot selbst für alle Unfälle und Schäden haftbar, die deine Drohne verursacht. Das gilt auch, wenn du die Kontrolle über die Drohne unverschuldet verlierst – zum Beispiel, weil eine Windböe sie erfasst und zum Absturz bringt. ",
      ],
      image: descriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Wer in Deutschland unter freiem Himmel eine Drohne fliegen lassen will, ist gesetzlich verpflichtet, vor dem ersten Start eine Haftpflichtversicherung abzuschließen. Dies liegt unter anderem an dem Schadenspotential, dass von den Drohnen ausgeht.",
        "Einige Versicherer bieten in den neuen Versicherungsprodukten der Privathaftpflicht zusätzlichen Versicherungsschutz für Drohnenhalter. Möchtest du dir eine Drohne für die private Nutzung anschaffen, solltest du entweder eine neuere Privathaftpflichtversicherung mit entsprechender Drohnenklausel abschließen oder eine kostengünstige Drohnenversicherung wählen.",
        "Bei gewerblicher Nutzung ist grundsätzlich eine eigenständige Drohnenversicherung erforderlich.",
      ],
      image: descriptionImage2,
    },
  ],
  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  checklist: [
    {
      title: "Abwehr unberechtigter Forderungen",
      text: "Die Versicherungen prüfen, inwiefern für die Schadenersatzforderungen ein berechtigter Anspruch durch die Betroffenen besteht und wehren unberechtigte oder zu hohe Forderungen kostenlos für dich ab. Du erhältst daher mit Abschluss einer Bauherrenhaftpflichtversicherung auch einen passiven Rechtsschutz.",
    },
    {
      title: "Passende Versicherung für deine Drohne",
      text: "Für den Versicherungsschutz ist der Abschluss einer passenden Versicherung erforderlich. Dies ist abhängig von Faktoren wie Drohnengewicht, Reichweite und Transportfähigkeit. Wenn du auf Nummer sichergehen möchtest, ob bzw. welcher Versicherungsschutz für dein Flugmodell vorgeschrieben ist, empfehlen wir direkt beim Hersteller oder der zuständigen Luftfahrtbehörde nachzufragen.",
    },
  ],
  link: RouteUrls.drohnenversicherung,
};
