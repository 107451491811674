import React from "react";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { createOptions } from "utils/formInputUtils";
import { Form, Formik } from "formik";
import {
  EditableUserProfileNumberField,
  EditableUserProfileSingleSelect,
  EditableUserProfileTextfield,
} from "../item/EditableUserProfileItem";
import { UseMutationResult } from "react-query";
import { KundeRequest } from "../../../../../types/backend/KundeRequest";

interface EditIncomeFormProps {
  formId: string;
  kunde: KundeResponse;
  mutation: UseMutationResult<unknown, unknown, KundeRequest>;
}

const occupationOptions = createOptions(
  STRINGS.profile.income.occupationStateTypes
);
const specialOccupationOptions = createOptions(
  STRINGS.profile.income.specialOccupationTypes
);
const healthInsuranceOptions = createOptions(
  STRINGS.profile.income.healthInsuranceTypes
);

const EditIncomeForm: React.FC<EditIncomeFormProps> = ({
  formId,
  kunde,
  mutation,
}) => {
  return (
    <Formik
      initialValues={{ ...(kunde || {}) }}
      onSubmit={(values) => mutation.mutate(values)}
      enableReinitialize={true}
    >
      {(formProps) => {
        return (
          <Form id={formId}>
            <EditableUserProfileTextfield
              fieldName="beruf"
              labelText={STRINGS.profile.income.occupation}
            />
            <EditableUserProfileSingleSelect
              fieldName={"berufsstatus"}
              options={occupationOptions}
              labelText={STRINGS.profile.income.occupationState}
            />
            <EditableUserProfileSingleSelect
              fieldName="besondereTaetigkeit"
              options={specialOccupationOptions}
              labelText={STRINGS.profile.income.specialOccupation}
            />
            <EditableUserProfileNumberField
              fieldName="nettoGehalt"
              labelText={STRINGS.profile.income.nettoIncome}
              integersOnly
            />
            <EditableUserProfileNumberField
              fieldName="einnahmenKapital"
              labelText={STRINGS.profile.income.capitalIncome}
              integersOnly
            />
            <EditableUserProfileNumberField
              fieldName="sonstigeEinnahmen"
              labelText={STRINGS.profile.income.otherIncome}
              integersOnly
            />
            <EditableUserProfileNumberField
              fieldName="gesamtAusgaben"
              labelText={STRINGS.profile.income.totalExpenses}
              integersOnly
            />
            <EditableUserProfileSingleSelect
              fieldName="krankenversicherung"
              options={healthInsuranceOptions}
              labelText={STRINGS.profile.income.healthInsurance}
            />
            {formProps.values.krankenversicherung === "PRIVAT" && (
              <>
                <EditableUserProfileNumberField
                  fieldName="privateKrankenversicherungBeitrag"
                  labelText={STRINGS.profile.income.healthInsuranceFee}
                />
              </>
            )}
            <EditableUserProfileNumberField
              fieldName="rentenVersicherungBeitrag"
              labelText={STRINGS.profile.income.pensionInsuranceFee}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditIncomeForm;
