import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/tierhalterhaftpflicht_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/hundehaftpflicht_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/hundehaftpflicht_2.jpg";

const productName = "Hunde­haft­pflicht­versicherung";

export const hundehaftpflichtProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Auch wenn dein Hund noch so gut erzogen ist – manchmal lässt es sich nicht verhindern, dass er Schäden verursacht. Als Hunde­halter haftest du für alle Schäden, die dein Hund verursacht.",

  headerButtons: [
    {
      label: "Vergleichen",
      link: RouteUrls.hundehaftpflichtVergleich,
    },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Eine Hunde­halterhaft­pflicht­versicherung schützt dich vor den finanziellen Risiken, die aus der Haltung deines Hundes entstehen. Der Versicherer übernimmt für dich berechtigte Schadens­ersatz­ansprüche und wehrt unberechtigte Forderungen ab – notfalls auch vor Gericht.",
        "Je nach individuellen Sicherheits­bedürfnis kannst du mit Hilfe des weitreichenden Versicherungsschutzes einiger Versicherer Hunde­haft­pflicht Schäden auch in einer Höhe von 100 Millionen Euro absichern.",
        "Die Hunde­haft­pflicht­versicherung sichert neben dir auch mögliche Miteigentümer, Mithalter oder Hüter ab. Beispiels­weise wenn Freunde mit deinem Hund Gassi gehen und dein Hund einen Schaden verursacht.",
      ],
      image: descriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Eine Hunde­halter-Haft­pflicht­versicherung ist für jeden privaten Hunde­besitzer eine unverzichtbare Absicherung. Während deine Privathaftpflicht auch für Schäden aufkommt, welche durch kleinere Haustiere wie Katzen verursacht wurden, sind Hunde von diesem Versicherungsschutz meistens explizit ausgeschlossen.",
        "Zusätzlich zu den wirtschaftlichen Vorteilen existiert in einzelnen Bundesländern eine gesetzliche Pflicht zum Abschluss einer Hundehalterhaftpflichtversicherung. Ob eine Hunde­haft­pflicht abgeschlossen werden muss, hängt dabei jedoch von den gesetzlichen Bestimmungen in den verschiedenen Bundes­ländern ab. Informiere dich daher stets über die bei dir geltenden lokalen Anforderungen!",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "... wenn dein Hund an einer von dir zu privaten Zwecken gemieteten oder gepachteten Immobilie (z. B. einer Miet­wohnung) Schäden verursacht. Dies gilt auch für Vermögens­schäden, die als Folge dessen entstanden sind.",
        "... wenn dein Hund bei der privaten Teilnahme an einer Hunde­sport­veranstaltung oder in der Hunde­schule einen Schaden verursacht. Der Versicherungs­schutz umfasst auch das Training und die Vorbereitung.",
        "... wenn du deinen Hund ohne Leine und Maul­korb führst und dieser aufgrund dessen einen Schaden verursacht.",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: [
        "... wenn du Hunde zu gewerblichen oder beruflichen Zwecken hältst. Das Halten dieser Tiere ist über die Betriebs­haft­pflicht­versicherung zu versichern.",
        "... der Schaden vorsätzlich herbeigeführt wurde, um einen Versicherungs­anspruch zu erzeugen.",
        "... du selbst oder Angehörige, die mit dir in häuslicher Gemeinschaft leben, einen Schaden erleidest.",
      ],
    },
  ],

  checklist: [
    {
      title: "Auslandsschutz",
      text: "Willst du mit deinem Hund ins Ausland reisen, solltest du auf einen Auslandsschutz bei deiner Hundehalterhaftpflichtversicherung achten. Auch wenn die meisten Versicherer einen Auslandsschutz anbieten, gilt dies noch nicht als Standard. Achte vor Reiseantritt daher sowohl auf die lokalen Anforderungen am Zielort als auch auf mögliche Einschränkungen zu Reisedauer und weltweiter Deckung durch deinen Versicherer, um deinen Urlaub mit deinem Hund sorglos genießen zu können.",
    },
    {
      title: "Forderungsausfalldeckung",
      text: "Erleidest du durch den Hund eines Dritten einen Personen- oder Sach­schaden, den der Halter des verursachenden Tieres wegen Zahlungs­unfähig­keit oder fehlender Versicherung nicht begleichen kann, kommen einige Versicherer für deine Ansprüche auf. Deine Hundehalterhaftpflichtversicherung schützt dich daher auch vor den finanziellen Risiken Dritter.",
    },
    {
      title: "Deckschäden",
      text: "Kommt es durch deinen Hund zu einem Deckakt bei einem anderen Tier, können dessen Halter erhebliche Schäden entstehen. Diese werden bei Abschluss einer entsprechenden Versicherung durch deinen Versicherer übernommen.",
    },
    {
      title: "Welpenschutz",
      text: "Hundewelpen können neben viel Freude auch finanzielle Risiken bedeuten. Solltest du für deinen Hund Nachwuchs planen oder dich gegen die Risiken eines ungeplanten Deckakts absichern wollen, bieten Versicherer meist den Versicherungsschutz für die Welpen des versicherten Elterntiers für bis zu 12 Monate automatisch mit an. Achte vor Abschluss deiner Versicherung daher auf das Vorhandensein einer entsprechenden Klausel, wenn du auf einen entsprechenden Welpenschutz Wert legst.",
    },
  ],

  link: RouteUrls.hundehaftpflicht,
};
