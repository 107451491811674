import focusOnlineLogo from "gfx/images/campaigns/focus-online-logo.png";
import redaktionsnetzwerkLogo from "gfx/images/campaigns/RND_RedaktionsNetzwerk_Deutschland_Logo.png";
import tOnlineLogo from "gfx/images/campaigns/t-online-logo.png";
import reiseRuecktrittImg from "gfx/images/campaigns/jeshoots-com-mSESwdMZr-A-unsplash.jpg";
import reiseKrankenImg from "gfx/images/campaigns/bambi-corro-VSnHXBD2Thc-unsplash.jpg";
import reiseGepaeckImg from "gfx/images/campaigns/convertkit-iGXIjzloB2k-unsplash.jpg";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const STRINGS: any = {
  companyName: "myNORM",

  formInputs: {
    booleanRadioButtonYes: "Ja",
    booleanRadioButtonNo: "Nein",
    pleaseSelectOne: "Bitte auswählen",
    noOptions: "Keine Auswahl",
    numberError: "Bitte gib einen Zahlenwert ein.",
    createOption: (newOption: string) => `'${newOption}' auswählen`,
    noneOption: "keine Angabe",
    validation: {
      required: (label?: string) =>
        `${label ? label : "Dieses Feld"} ist ein Pflichtfeld.`,
      number: (label: string) => `${label} muss eine Zahl sein.`,
      phone: (label?: string) =>
        `${
          label ? label : "Dieses Feld"
        } muss eine gültige Telefonnummer sein.`,
      email: (label: string) =>
        `${label} muss eine gültige E-Mail Adresse sein.`,
      date: (label: string) =>
        `${label} muss ein gültiges Datum (jjjj-mm-dd) sein.`,
    },
  },
  campaigns: {
    compareAndSignButtonLabel: "Vergleichen & Abschließen",
    insureNowButtonLabel: "Jetzt absichern",
    reise: {
      title: "Jetzt deinen Urlaub absichern",
      checklistItems: [
        "Zur passenden Versicherung in unter 2 Minuten",
        "Keine Überraschungen am Abflugstag",
        "Sorgenfrei in den Sommer",
      ],
      boldClaim:
        "Die aktuelle Lage an den Flughäfen sorgt bei vielen Urlaubern für Stress",
      quotes: [
        {
          text: "In München steht ein Berg von mehreren Tausend Koffern, die zugeordnet werden müssen.",
          logo: redaktionsnetzwerkLogo,
          logoAlt: "RedationsNetzwerk Deutschland",
        },
        {
          text: "Wie an vielen anderen Flughäfen in Deutschland kann es aufgrund von Personalengpässen bei Dienstleistern und Airlines zu längeren Wartezeiten oder zu Flugausfällen kommen.",
          logo: tOnlineLogo,
          logoAlt: "t-online - Nachrichten für Deutschland",
        },
        {
          text: "Mit regelmäßig mehr als 100.000 Passagieren am Tag bei den Abflügen haben wir Phasen gehabt, in denen der Service unter ein akzeptables Niveau gerutscht ist.",
          logo: focusOnlineLogo,
          logoAlt: "Focus Online",
        },
      ],
      insureNow: {
        title: "Jetzt deine Reise mit der myNORM absichern",
        prose:
          "Mit den Reiseversicherungen der myNORM kannst du dich und deine Liebsten jetzt gegen alle Unvorhersehbarkeiten absichern. Egal ob dich eine Krankheit, ein Unfall oder die Fluggesellschaft an der Reise hindert - mit uns bist du richtig abgesichert.",
        checklistItems: [
          "…du vor der Abreise unerwartet erkrankst (z.B. an COVID)",
          "…deine Gepäcksstücke auf der Reise verloren gehen oder beschädigt werden",
          "…du während der Reise medizinische Behandlung brauchst",
          "…du die Reise aufgrund einer Schwangerschaft nicht antreten kannst",
        ],
        checklistIntroduction: "Eine Reiseversicherung hilft dir wenn…",
      },
      insuranceComparison: {
        title: "Auf jede Situation vorbereitet",
        insurances: [
          {
            name: "Reiserücktrittversicherung",
            image: reiseRuecktrittImg,
            description:
              "Eine Reiserücktrittversicherung schützt vor den hohen Stornierungskosten, wenn du eine Reise ins Ausland vor Abflug absagen musst.",
            checklistItems: [
              "Unerwartete Erkrankung, Unfall & Tod",
              "Schwangerschaft",
            ],
            checklistIntroduction: "Abgedeckt sind dabei normalerweise:",
          },
          {
            name: "Reisekrankenversicherung",
            image: reiseKrankenImg,
            description:
              "Die Auslandsreisekrankenversicherung schützt dich davor, hohe, unvorhergesehene Krankheitskosten auf der Reise selbst tragen zu müssen.",
            checklistItems: [
              "Ärztliche Behandlung",
              "Krankenhausaufenthalte, Rettungs- & Bergungskosten, sowie Rücktransporte",
            ],
            checklistIntroduction: "Abgedeckt sind dabei normalerweise:",
          },
          {
            name: "Reisegepäckversicherung",
            image: reiseGepaeckImg,
            description:
              "Eine Reisegepäckversicherung schützt dich, wenn dein Gepäck unterwegs abhandenkommt, gestohlen oder beschädigt wird.",
            checklistItems: [
              "Verlust, Diebstahl & Beschädigung",
              "Schäden durch Feuer & Überschwemmung",
            ],
            checklistIntroduction: "Abgedeckt sind dabei normalerweise:",
          },
        ],
      },
      usps: {
        fast: {
          title: "Schneller Abschluss",
          prose:
            "Schließe deine Versicherung einfach und unkompliziert in weniger als 7 Minuten ab.",
        },
        quality: {
          title: "Qualität & Transparenz",
          prose:
            "Wir setzen auf qualitativ hochwertige Anbieter und führen Kosten detailliert auf.",
        },
        individual: {
          title: "Individuelle Lösungen",
          prose:
            "Durch unser breites Angebot findest du die Versicherung, die zu deiner Situation passt.",
        },
        consulting: {
          title: "Unabhängige Beratung",
          prose:
            "Wir haben die Produkte vieler Versicherer im Angebot und beraten dich daher unabhängig.",
        },
      },
    },
  },
  aboutUs: {
    overview: {
      titleFirstLine: "myNORM",
      titleSecondLine: " - Unsere Mission",
      paragraphs: [
        "Wir haben es uns zur Aufgabe gemacht, kompetente Beratung auf dem Versicherungsmarkt für Jedermann zugänglich zu machen.",
        "Unsere Mission ist es, dass vermögende Privatpersonen nicht die einzigen sind, die individuell beraten werden, sondern wir durch die Digitalisierung Kosten sparen und somit jedem eine qualifizierte Beratung zur Verfügung stellen.",
      ],
    },
    usp: {
      title: "myNORM - Management Team",
      titleFirstLine: "Unsere ",
      titleSecondLine: "Werte",
    },
    team: {
      titleFirstLine: "Unser ",
      titleSecondLine: "starkes Gründerteam",
      subTitle:
        "Das Gründungsteam vereint langjährige Erfahrung in der Finanz- und Versicherungindustrie, der IT.Technologie Branche sowie im Aufbau von Start-ups.",
    },
    ourPromise: {
      titleFirstLine: "Unser ",
      titleSecondLine: "Versprechen an dich",
      paragraphs: [
        "Deine Kundenzufriedenheit steht für uns immer im Vordergrund.",
        "Wir optimieren deshalb fortwährend unsere digitale Lösung, sodass du immer eine volle Übersicht über deine Versicherungen hast und sichergestellt ist, dass wir dich bestmöglich beraten.",
        "Dabei handeln wir immer unabhängig um dir nur auf deine Bedürfnis passende Tarife vorzuschlagen. Überzeuge dich selbst und registriere dich noch heute in unserem Portal.",
      ],
    },
    lookingForYou: {
      titleFirstLine: "Werde auch du ",
      titleSecondLine: "Teil der myNORM",
      paragraphs: [
        "Wir sind ein schnell wachsendes Team mit ambitionierten Zielen auf der Suche nach engagierten Leuten, die mit uns die Versicherungsbranche einfacher und transparenter machen wollen.",
        "Wir freuen uns auf deine Bewerbung und dir in diesem Zusammenhang das Team vorzustellen. Schicke uns gerne deine Bewerbung an bewerbung@mynorm.de.",
      ],
    },
  },
  navigation: {
    logout: "Abmelden",
    login: "Anmelden",
  },
  insuranceOverview: {
    title: "Alle Versicherungen in einem Blick",
  },
  insurancePage: {
    yourPrefix: "Deine ",
    checklist: {
      title: "Was musst du beachten?",
    },
    insuranceSituation: {
      titlePart1: "Die ",
      titlePart2: " hilft dir, wenn ...",
    },
    actionBoxes: {
      title: "Mit diesen Zusatz­bau­steinen passt du den Schutz individuell an",
    },
    insuranceBenefitBoxes: {
      titlePart1: "Was wird ",
      titlePart2: "versichert und ",
      titlePart3: "was nicht",
    },
    longDescription: {
      upperSectionTitlePart1: "Was ist eine ",
      upperSectionTitlePart2: "?",
      lowerSectionTitlePart1: "Wer braucht eine ",
      lowerSectionTitlePart2: "?",
    },
    comparison: {
      title: "Was wird versichert und was nicht:",
    },
    companyCarousel: {
      title: "Den gesamten Versicherungs­markt für dich im Vergleich",
    },
    checkTeaser: {
      title: "Du bist dir noch nicht sicher, welcher Tarif zu dir passt?",
      subTitle: "Einfach vergleichen und das perfekte Angebot finden",
      checkButtonLabel: "Tarife vergleichen",
    },
  },
  footer: {
    contact: {
      contact: "Kontakt",
      email: "service@mynorm.de",
      phone: "+49 (0) 511 5468 4540",
      phoneLink: "+4951154684540",
      times: "(Mo. - Fr. 8 - 20 Uhr, Sa. 9 - 16 Uhr)",
    },
    linkLists: {
      company: "Unternehmen",
      aboutUs: "Über uns",
      jobs: "Karriere",
      legal: "Rechtliches",
      termsOfService: "AGB",
      imprint: "Impressum",
      dataProtection: "Datenschutz",
      cookiePolicy: "Cookie-Richtlinie",
      erstInformation: "Erstinformation",
      nachhaltigkeit: "Nachhaltigkeitserklärung",
    },
    footline: "myNORM GmbH 2022 © All rights reserved.",
  },
  profile: {
    header: "Persönliche Daten",
    booleanOptions: {
      yes: "ja",
      no: "nein",
    },
    notSpecified: "keine Angabe",
    personalData: {
      header: "Persönliche Daten",
      salutationTypes: {
        FRAU: "Frau",
        HERR: "Herr",
        DIVERS: "Divers",
        FIRMA: "Firma",
      },
      academicTitle: "Titel",
      salutation: "Anrede",
      firstname: "Vorname",
      lastname: "Nachname",
      birthday: "Geburtsdatum",
      birthPlace: "Geburtsort",
      customerName: "Name",
      customerNumber: "Kundennummer",
      maritalStatus: "Familienstand",
      maritalStatusTypes: {
        EHEAEHNLICHE_GEMEINSCHAFT: "eheähnliche Gemeinschaft",
        GESCHIEDEN: "geschieden",
        GETRENNT_LEBEND: "getrennt lebend",
        LEDIG: "ledig",
        VERHEIRATET: "verheiratet",
        VERWITWET: "verwitwet",
      },
      numberOfChildren: "Anzahl Kinder",
      livingSituation: "Wohnsituation",
      livingSituationTypes: {
        MIETE: "Miete",
        EIGENTUM: "Eigentum",
      },
      livingSpace: "Wohnfläche (m²)",
      rentedProperty: "Fremdgenutzte Immobilie",
      yearOfConstruction: "Baujahr",
      initialConstructionCosts: "Neubausumme Immobilie",
    },

    edit: {
      title: "Daten ändern",
      save: "Speichern",
      cancel: "Abbrechen",
      success: "Dein Profil wurde erfolgreich gespeichert.",
      error: "Etwas ist schief gelaufen.",
      ok: "OK",
    },

    contact: {
      header: "Kontakt",
      nation: "Nationalität",
      contactTypes: {
        E_MAIL: "E-Mail",
        TELEFON: "Telefon",
        FAX: "Fax",
        INTERNET: "Internet",
        MOBIL: "Mobil",
      },
      contactDetails: "Kontaktdaten",
      registrationAddress: "Meldeadresse",
      street: "Straße",
      postalCode: "PLZ",
      city: "Ort",
    },

    bankingAccount: {
      header: "Bankverbindung",
      accountType: "Kontoart",
      accountTypes: {
        KONTO: "Konto",
        DEPOT: "Depot",
      },
      iban: "IBAN",
      owner: "Inhaber",
      institut: "Institut",
      bic: "BIC",
      depot: "Depot",
    },
    identification: {
      header: "Identifizierung",
      identificationType: "Ausweisart",
      identificationTypes: {
        PERSONALAUSWEIS: "Personalausweis",
        REISEPASS: "Reisepass",
        FUEHRERSCHEIN: "Führerschein",
      },
      authority: "Behörde",
      number: "Ausweisnummer",
      issuedAt: "Ausgestellt am",
      validUntil: "Gültig bis",
    },
    risks: {
      header: "Risiken und Hobbies",
      foreignTravel: "Auslandsreisen",
      dogOrHorse: "Halter Hund oder Pferd",
      motorVehicle: "Halter Kfz",
      airVehicle: "Luftfahrzeug",
      waterVehicle: "Wasserfahrzeug",
      hunting: "Jagen Sie",
      oilTank: "Öltank",
      photovoltaic: "Photovoltaik",
      buildOrSanitize: "Bauen oder Sanieren",
    },
    income: {
      header: "Beruf und Einkommen",
      occupation: "Beruf",
      occupationState: "Berufsstatus",
      occupationStateTypes: {
        ANGESTELLT: "Angestellt",
        ANGESTELLT_IM_OEFFENTLICHEN_DIENST: "Angestellt im öffentlichen Dienst",
        ARBEITER: "Arbeiter",
        ARBEITSSUCHEND: "Arbeitssuchend",
        AUSZUBILDENDER: "Auszubildender",
        BEAMTER: "Beamter",
        BEHERRSCHENDER_GGF: "Beherrschender GGF",
        ELTERNZEIT: "Elternzeit",
        FREIBERUFLER: "Freiberufler",
        GESCHAEFTSFUEHRER: "Geschäftsführer",
        NICHT_BERUFTAETIG: "Nicht berufstätig",
        RENTNER: "Rentner",
        SELBSTSTAENDIG: "Selbstständig",
        STUDENT: "Student",
        VORSTAND: "Vorstand",
      },
      specialOccupation: "Besondere Tätigkeiten",
      specialOccupationTypes: {
        ANGESTELLT: "Angestellte mit besond. Aufgaben / Betriebsangestellte",
        ORGANE: "Organ von juristischer Person / Körperschaft / Verein",
        SYNDIKUS_ANWAELTE: "Syndikusanwalt / Angestellter Arzt",
        EHRENAMTLICH: "Ehrenamtlich",
      },
      nettoIncome: "Nettogehalt",
      capitalIncome: "Kapitaleinnahmen",
      otherIncome: "Sonstige Einnahmen",
      totalExpenses: "Gesamtausgaben",
      healthInsurance: "Krankenversicherung",
      healthInsuranceTypes: {
        GESETZLICH: "gesetzlich",
        PRIVAT: "privat",
      },
      healthInsuranceFee: "Monatlicher Beitrag KV/PV",
      pensionInsuranceFee: "Monatlicher Beitrag Rentenversicherung",
    },
    assets: {
      header: "Vermögen und Sparen",
      marketValueProperty: "Verkehrswert Immobilie",
      liabilitiesProperty: "Verbindlichkeiten Immobilie",
      capitalAssets: "Kapitalanlagen",
      otherAssets: "Sonstige Vermögenswerte",
      loans: "Darlehen",
      consumerCredits: "Konsumentenkredit",
      dispositionCredit: "Dispositionskredit",
      investmentGoal: "Investitionsziel",
      targetSavingsAmount: "Wunschbetrag für Sparziel",
      targetSavingsDate: "Datum Sparziel erreicht",
      ownPropertyIntended: "Eigene Immobilie geplant",
      costOfProperty: "Kosten der Immobilie",
      intendedDateOfAcquisition: "Geplantes Datum für Kauf",
      replacementInvestment: "Ersatzinvestition",
      replacementInvestmentValue: "Ersatzinvestition Betrag",
      replacementInvestmentDate: "Datum der Ersatzinvestition",
    },
  },
  vertrag: {
    add: "Versicherung hinzufügen",
    header: "Deine Versicherungen",
    investments: {
      header: "Deine Geld­anlagen",
      cardHeaders: {
        type: "Typ",
        name: "Bezeichnung",
        deposit: "Einzahlung",
        saveValue: "Spar­betrag",
        targetValue: "Ziel­sparsumme",
        assetType: "WP-Typ",
        assetName: "Name / Basiswert",
        assetIdentification: "WKN / ISIN",
        acquisitionValue: "Kaufwert",
        currentValue: "aktueller Wert",
        winLoss: "Gewinn / Verlust",
      },
    },
    documents: {
      dialog: {
        header: "Dokumente",
        save: "Speichern",
        abort: "Abbrechen",
        ok: "Ok",
        messages: {
          addError: "Beim Hinzufügen der Dokumente ist ein Fehler aufgetreten.",
          addSuccess: "Die Dokumente wurden erfolgreich hinzugefügt.",
          deleteError: (dokumentName: string) =>
            `Beim Löschen von '${dokumentName}' ist ein Fehler aufgetreten.`,
          deleteSuccess: (dokumentName: string) =>
            `'${dokumentName}' wurde erfolgreich gelöscht`,
        },
      },
    },
    productCards: {
      headers: {
        type: "Typ",
        insured: "Versicherte Person",
        institute: "Gesellschaft",
        amount: "Beitrag",
        amountYearly: "Jahres­beitrag",
        vehicleManufacturerAndType: "Hersteller (Bezeichnung)",
        vehicleManufacturer: "Hersteller",
        vehicleType: "Fahrzeugtyp",
      },
    },
    interval: {
      MONATLICH: "monatlich",
      VIERTELJAEHRIG: "vierteljährlich",
      HALBJAEHRIG: "halbjährlich",
      JAEHRIG: "jährlich",
      EINMALIG: "einmalig",
    },
    typ: {
      geldanlagen: {
        AKTIE: "Aktie",
        ANLEIHE: "Anleihe",
        BAUSPARVERTRAG: "Bausparvertrag",
        BUNDESSCHATZBRIEF: "Bundesschatzbrief",
        ENTNAHMEPLAN: "Entnahmeplan",
        FESTGELD: "Festgeld",
        GIROKONTO: "Girokonto",
        INVESTMENTFONDS: "Investmentfonds",
        SONDERVERMOEGEN: "Sondervermögen",
        SPARBRIEF: "Sparbrief",
        SPARBUCH: "Sparbuch",
        SPARPLAN: "Sparplan",
        ZERTIFIKAT: "Zertifikat",
        SONSTIGE_VERTRAEGE: "Sonstiger Vertrag",
      },
      versicherungen: {
        AUSLANDSREISEKRANKENVERSICHERUNG: "Auslandskrankenversicherung",
        BASISRENTE: "Basisrente",
        BAV_LEBENSVERSICHERUNG: "BAV Lebensversicherung",
        BAV_RENTENVERSICHERUNG: "BAV Rentenversicherung",
        BEAMTENPENSION: "Beamtenpension",
        BERUFSSTAENDISCHES_VERSORGUNGSWERK: "Berufsständisches Versorgungswerk",
        BERUFSUNFAEHIGKEITSVERSICHERUNG: "Berufsunfähigkeitsversicherung",
        BERUFSHAFTPFLICHT: "Berufshaftpflicht",
        BETRIEBSHAFTPFLICHTVERSICHERUNG: "Betriebshaftpflichtversicherung",
        BETRIEBSUNTERBRECHUNGSVERSICHERUNG:
          "Betriebsunterbrechungsversicherung",
        DREAD_DISEASE: "Dread Disease",
        ELEKTRONIKVERSICHERUNG: "Elektronikversicherung",
        ERWERBSUNFAEHIGKEITSVERSICHERUNG: "Erwerbsunfähigkeitsversicherung",
        GESETZLICHE_RENTENVERSICHERUNG: "Gesetzliche Rentenversicherung",
        GEWAESSERHAFTPFLICHTVERSICHERUNG: "Gewässerhaftpflichtversicherung",
        GLASVERSICHERUNG: "Glasversicherung",
        HAFTPFLICHTVERSICHERUNG: "Haftpflichtversicherung",
        HAUSRATVERSICHERUNG: "Hausratversicherung",
        INHALTSVERSICHERUNG: "Inhaltsversicherung",
        KFZ_VERSICHERUNG: "KFZ Versicherung",
        KRANKENVERSICHERUNG: "Krankenversicherung",
        KRANKENVOLLVERSICHERUNG: "Krankenvollversicherung",
        KRANKENZUSATZVERSICHERUNG: "Krankenzusatzversicherung",
        LEBENSVERSICHERUNG: "Lebensversicherung",
        LUFTUNDWASSERFAHRZEUGSVERSICHERUNG:
          "Luft- und Wasserfahrzeugversicherung0",
        PFLEGEVERSICHERUNG: "Pflegeversicherung",
        PHOTOVOLTAIK: "Photovoltaik",
        RECHTSSCHUTZVERSICHERUNG: "Rechtsschutzversicherung",
        RENTENVERSICHERUNG: "Rentenversicherung",
        RIESTERRENTE: "Riesterrente",
        RISIKOLEBENSVERSICHERUNG: "Risikolebensversicherung",
        TRANSPORTVERSICHERUNG: "Transportversicherung",
        UNFALLVERSICHERUNG: "Unfallversicherung",
        WOHNGEBAEUDEVERSICHERUNG: "Wohngebäudeversicherung",
        SONSTIGE_VERTRAEGE: "Sonstiger Vertrag",
      },
      SONSTIGE_VERTRAEGE: "Sonstiger Vertrag",
    },
    productCategory: {
      SACH_UND_HAFTPFLICHTVERSICHERUNGEN: "Sach- und Haftpflichtversicherungen",
      KFZ_VERSICHERUNGEN: "Kfz-Versicherungen",
      KRANKENVERSICHERUNGEN: "Krankenversicherungen",
      LEBENS_UND_RENTENVERSICHERUNGEN: "Lebens-/Rentenversicherungen",
      SPAREN: "Sparen",
      KAPITALANLAGEN: "Kapitalanlagen",
      SONSTIGE: "Sonstige",
    },
    productType: {
      GELDANLAGE: "Geldanlagen",
      VERSICHERUNG: "Versicherungen",
    },
    usageType: {
      AUFBAU_LIQUIDITAETSRESERVE: "Aufbau einer Liquiditätsreserve",
      EIGENKAPITAL_FUER_IMMOBILIE: "Eigenkapital für Immobilie",
      INSTANDHALTUNG_IMMOBILIEN: "Instandhaltung von Immobilien",
      WESENTLICHE_ERSATZINVESTITIONEN_SACHWERTE:
        "Wesentliche Ersatzinvestitionen für Sachwerte",
      STARTKAPITAL_FUER_AUSBILDUNG: "Startkapital für Ausbildung",
      WEITERE_INDIVIDUELLE_ZIELE: "Weitere individuelle Ziele",
      PRIVATE_NUTZUNG_AUSSSCHLIESSLICH_PRIVAT: "Private Nutzung",
    },
    riskTypes: {
      BAUHANDWERKER: "Bauhandwerker",
      BAUHERRENHAFTPFLICHT: "Bauherrenhaftpflicht",
      BERUFSHAFTPFLICHT: "Berufshaftpflicht",
      BETRIEBSHAFTPFLICHT: "Betriebshaftpflicht",
      BUEROHAFTPFLICHT: "Bürohaftpflicht",
      DIENSTHAFTPFLICHT: "Diensthaftpflicht",
      GEWAESSERSCHADENHAFTPFLICHT: "Gewässerschadenhaftpflicht",
      HAUS_UND_GRUNDBESITZERHAFTPFLICHT: "Haus- und Grundbesitzerhaftpflicht",
      JAGDHAFTPFLICHT: "Jagdhapftpflicht",
      PRIVATHAFTPFLICHT: "Privathaftpflicht",
      PRODUKTHAFTPFLICHT: "Produkt-Haftpflicht",
      TIERHALTERHAFTPFLICHT: "Tierhalterhaftpflicht",
      VERANSTALTERHAFTPFLICHT: "Veranstalterhaftpflicht",
      VERMOEGENSSCHADENHAFTPFLICHT: "Vermögensschadenhaftpflicht",
      SONSTIGES: "Sonstiges",
    },
    scheduleOfFees: {
      KEINE_LEISTUNG: "Keine Leistung",
      BIS_ZUM_HOECHSTSATZ: "Bis zum Höchstsatz",
      UEBER_DEN_HOECHSTSATZ: "Über den Höchstsatz",
    },
    visualAidTypes: {
      KEINE_SEHHILFENERSTATTUNG: "Keine Sehhilfenerstattung",
      ERSTATTUNG_BIS_25: "Erstattung bis 25 EUR",
      ERSTATTUNG_BIS_50: "Erstattung bis 50 EUR",
      ERSTATTUNG_BIS_75: "Erstattung bis 75 EUR",
      ERSTATTUNG_BIS_100: "Erstattung bis 100 EUR",
      ERSTATTUNG_BIS_125: "Erstattung bis 125 EUR",
      ERSTATTUNG_BIS_150: "Erstattung bis 150 EUR",
      ERSTATTUNG_BIS_175: "Erstattung bis 175 EUR",
      ERSTATTUNG_BIS_200: "Erstattung bis 200 EUR",
      ERSTATTUNG_BIS_225: "Erstattung bis 225 EUR",
      ERSTATTUNG_BIS_250: "Erstattung bis 250 EUR",
      ERSTATTUNG_BIS_275: "Erstattung bis 275 EUR",
      ERSTATTUNG_BIS_300: "Erstattung bis 300 EUR",
    },
    careServiceTypes: {
      KEINE_LEISTUNG: "Keine Leistung",
      ERHOEHUNG_DER_GESETZLICHEN_LEISTUNG: "Erhöhung der gesetzlichen Leistung",
      RESTKOSTENUEBERNAHME: "Restkostenübernahme",
      RESTKOSTENUEBERNAHEM_BIS_OBERGRENZE: "Restkostenübernahme bis Obergrenze",
      PFLEGETAGEGELD: "Pflegetagegeld",
      GESETZLICHE_LEISTUNG: "Gesetzliche Leistung",
    },
    stationaryAccomodationTypes: {
      PRIVATPATIENT_EINBETTZIMMER: "Einbettzimmer",
      PRIVATPATIENT_EIN_ODER_ZWEIBETTZIMMER: "Ein- oder Zweibettzimmer",
      PRIVATPATIENT_ZWEIBETTZIMMER: "Zweibettzimmer",
      PRIVATPATIENT_MEHRBETTZIMMER: "Mehrbettzimmer",
    },
  },
  startpage: {
    overview: {
      title: "Dein digitaler Finanzmanager",
      subTitle: "Persönlicher Coach für Deine Versicherungen und Finanzen",
      uspTitle1: "Verwalte alle deine Finanzen auf einen Blick",
      uspText1: "Versicherungs­verträge, Finanz­verträge, Miet­verträge, etc.",
      uspTitle2: "Welche Versicherungen sind für dich notwendig?",
      uspText2: "BedarfsCheck nach DIN und Verbraucher­schutz",
      uspTitle3: "Kostenloser Wechsel­service",
      uspText3: "In nur 3 Minuten kündigen wir kostenlos deinen Alt­vertrag",
      actionButton1: "Bedarf prüfen",
      actionButton2: "Jetzt registrieren",
    },
    carousel: {
      titlePart1: "Mit myNORM",
      titlePart2: " deine Finanzen managen",
      subTitle:
        "Wir setzen auf einen ganzheitlichen Ansatz, damit du deine Finanzen optimal managen kannst.",
      pages: {
        information: {
          title: "Informieren",
          headline: "Wir machen das Thema Finanzen greifbar.",
          prose:
            "Der Finanzmarkt ist für die meisten ein undurchsichtiges Thema und damit kaum greifbar. Mit myNORM bringen wir Klarheit in dieses wichtige Thema und zeigen dir auf, welche Stellschrauben für deine Finanzen gestellt werden müssen.",
        },
        comparison: {
          title: "Vergleichen",
          headline: "Welcher ist der Richtige für dich?",
          prose:
            "Durch unseren Zugriff auf den gesamten Markt hast du die Möglichkeit, von über 500 Versicherern den richtigen für dich zu finden. Falls du dir noch nicht sicher bist, welche Versicherung für dich notwendig ist, kannst du das mit unserem BedarfsCheck innerhalb von 3 Minuten herausfinden. Das Beste daran: Wir arbeiten nach DIN und Verbraucherschutz!",
        },
        administer: {
          title: "Verwalten",
          headline: "Perfekt organisiert - ohne Papierkram.",
          prose:
            "Deinen Alltag hast du bereits komplett digitalisiert, aber für deine Finanzen und Versicherungen hast du noch Aktenordner? Wir sortieren alle Dokumente und Ereignisse chronologisch in deiner digitalen Akte. Somit siehst du nicht nur wann dein Vertrag ausläuft, sondern erhältst sogar eine Nachricht, falls es vergleichbar günstigere Angebote für dich gibt.",
        },
        complete: {
          title: "Abschließen",
          headline: "Du weißt, was du willst.",
          prose:
            "Du hast bereits einen guten Überblick über den Finanz- und Versicherungs­markt und willst ein spezifisches Produkt verbraucher­schutzkonform abschließen? Komm zu uns – komm zu myNORM!",
        },
      },
    },
    bubbles: {
      titleFirstLine: "Deine Plattform für",
      titleSecondLine: "ganzheitliches Finanz­management",
      hiddenBubbleTitleHighlighted: "myNORM ",
      hiddenBubbleTitle: "an deiner Seite",
      hiddenBubbleText:
        "Wir setzen auf einen ganzheitlichen Ansatz, damit du deine Finanzen immer im Blick hast und optimal managen kannst.",
      subTitle:
        "Mit myNORM kannst du deine Versicherungen und Finanzprodukte managen und optimieren.",
      altText:
        "Mit dem myNORM Finanzmanager hast du deine Sparanlagen, Versicherungen und Vorsorgethemen abgedeckt.",
      bubbles: {
        centerBubble: "myNORM Finanzmanager",
        insurance: "Versichern & Vorsorgen",
        household: "Hausrat",
        accident: "Unfall",
        disability: "Berufsunfähigkeit",
        liability: "Haftpflicht",
        more: "uvm.",
        saving: "Sparen & Anlegen",
        shares: "Aktien",
        indexFunds: "ETF",
        property: "Sachwerte",
        participations: "Beteiligungen",
      },
    },
    productTeaser: {
      leftTitleFirstLine: "Versichern &",
      leftTitleSecondLine: "Vorsorgen",
      leftProse:
        "Mit unserem myNORM Finanzmanager kannst du alle deine Versicherungen an einem Ort erfassen, ablegen und verwalten. In deinem personalisierten Portal kannst du außerdem deine bestehenden Verträge vergleichen und dir kostenfrei und einfach Vergleichsangebote einholen lassen. Somit begleiten wir dich auf deinem Weg zur finanziellen Absicherung.",
      leftInvestmentPlannerButtonLabel: "Versicherungsmanager",
      leftaltText:
        "Jetzt den Investmentplanner nutzen um deine Rendite zu erhöhen.",
      rightTitleFirstLine: "Sparen &",
      rightTitleSecondLine: "Anlegen",
      rightProse:
        "Mit unserem Investment Planer findest du genau die richtige Vermögensstrategie, die auf deine individuelle Situation zugeschnitten ist. Dank unseres Partners easyfolio kannst du in ein oder mehr spezialisierte ETF-Portfolios investieren und dein Vermögen diversifiziert aufbauen - ganz deiner Risikoaffinität entsprechend!",
      rightInvestmentPlannerButtonLabel: "Investmentplanner",
      rightAltText:
        "Jetzt den Investmentplanner nutzen um deine Rendite zu erhöhen.",
    },
    ContractCheckTeaser: {
      titleFirstLine: "Kostenloser Vertragscheck",
      titleSecondLine: "Lohnt sich ein Wechsel für dich?",
      prose:
        "Wir haben Zugriff auf über 500 Versicherer. So können wir deinen aktuellen Vertrag prüfen und Vergleichsangebote zu besseren Konditionen für dich finden. Falls unser Vergleich bestätigt, dass dein vorhandener Vertrag bereits die besten Konditionen für dich darstellt, kannst du ihn im myNORM Dashboard verwalten und hast somit alle deine Verträge zentral auf einen Blick.",
      portalButtonLabel: "Verträge prüfen",
      altText: "Das myNORM-Portal steht überall und jederzeit zur Verfügung.",
    },
    actionCards: {
      title: "Einfach. Unabhängig. Digital.",
      subTitle:
        "Mit der individuellen Bedarfsanalyse holen wir das beste aus deinen Finanzen raus.",
    },
    dinTeaser: {
      title: [
        { text: "Verbraucherschutzkonforme Produktauswahl", highlight: false },
        { text: "und Beratung auf", highlight: true },
        { text: " Basis der ", highlight: false },
        { text: "DIN 77230", highlight: true },
      ],

      prose:
        "Versicherungen unterscheiden sich stark in Leistung und Qualität. Darum setzten wir ausschließlich auf verbraucherschutzkonforme Produkte für unsere Kunden. Damit stellen wir sicher, dass du stets die besten Vorschläge von uns erhältst und wir dich langfristig glücklich machen.",
      altText:
        "Durch die verbraucherschutzkonforme Beratung nach DIN 77230 kann nichts mehr schief gehen.",
    },
    boasting: {
      titleHighlightedPrefix: "myNORM",
      title: " als integrierter Teil eines erfahrenen Netzwerks",
      dinTile: {
        title: "DIN basierte Beratung",
        prose:
          "Wir haben die DIN NORM 77230 mit entwickelt und sind setzen uns dafür ein, diesen Qualitätsstandard auch online jedem Privathaushalt zur Verfügung zu stellen. ",
      },
      consumerProtectionTile: {
        title: "100% Verbraucherschutz konform",
        prose:
          "Damit wollen wir sicherstellen, dass deine Verträge im Schadensfall verlässlich leisten und wir dich langfristig glücklich machen.",
      },
      assetUnderManagementTile: {
        title: "17,1 Mrd. € AuM",
        prose:
          "Unsere Partner kümmern sich seit Jahren erfolgreich um den Vermögensaufbau von privaten Kunden.",
      },
      experienceTile: {
        title: ">20 Jahre Erfahrung",
        prose:
          "Das Gründerteam der myNORM arbeitet seit vielen Jahren in der Finanzbranche. Dein Partner mit dem du heute stark in die Zukunft startest.",
      },
      manpowerTile: {
        title: ">280 Kollegen",
        prose:
          "arbeiten im Hintergrund daran, dass du als Kunde den bestmöglichen Service und die bestmögliche Qualität erhältst.",
      },
    },
    checkTeaser: {
      title: "Lohnt sich ein Wechsel für dich? Finde es heraus.",
      titleBubble: "Kostenloser VertragsCheck",
      prose:
        "Durch den Zugriff auf über 500 Versicherer können wir deinen aktuellen Vertrag mit dem gesamten Markt vergleichen und Vergleichsangebote zu besseren Konditionen für dich finden. Falls unser Vergleich bestätigt, dass dein vorhandener Vertrag bereits die besten Konditionen für dich darstellt, kannst du ihn im myNORM Dashboard verwalten und hast somit alle deine Verträge zentral auf einem Blick.",
      checkButtonLabel: "Vorhandene Verträge vergleichen",
      altText:
        "Nutze den kostenlosen VertragsCheck um herauszufinden ob sich ein Wechsel lohnt.",
    },
    faq: {
      titleFirstLine: "FAQ ",
      titleSecondLine: "",
      entries: [
        {
          question: "Was ist myNORM?",
          answer:
            "Die myNORM ist ein digitaler Manager für deine Versicherungen und Finanzen. Hierbei agieren wir als Versicherungsmakler und stellen sicher, dass du eine Versicherungsberatung erhältst, welche nicht von einer spezifischen Versicherungsgesellschaft abhängig ist. Mithilfe unseres Vergleichstools können wir dir helfen, deinen Versicherungsschutz so aufzubauen, wie er am besten zu dir und deiner Situation passt.",
        },
        {
          question: "Was ist ein Maklermandat und wieso benötigen wir dieses?",
          answer:
            "Die Maklervollmacht erlaubt es uns, deine Interessen gegenüber den Versicherungsgesellschaften zu vertreten. Das heißt, dass wir für dich Verträge schließen, bestehende Verträge ändern und bestehende Verträge kündigen dürfen - natürlich immer nur mit deiner vorherigen Zustimmung. So können wir sicherstellen, dass du dich so wenig wie möglich selber um deine Versicherungen kümmern musst.",
        },
        {
          question: "Wieso ist myNORM kostenfrei?",
          answer:
            "Die myNORM ist für dich kostenfrei, weil wir als myNORM eine Provision von deiner Versicherungsgesellschaft bekommen. Diese erhalten wir für das Management deiner Versicherungen und stellen für dich keine zusätzlichen Kosten dar. Egal ob du deine Versicherungen selbst verwaltest oder ein Makler das für dich tut - die Prämie deiner Versicherung bleibt dieselbe.",
        },
        {
          question:
            "Was tun, wenn ich meine Versicherung beim Hochlade-Prozess nicht finde?",
          answer:
            "Das soll natürlich nicht passieren. Falls es doch mal vorkommt, dass du deine Versicherung nicht in das Portal laden kannst, setze dich gerne mit unseren Versicherungsexperten in Verbindung. Diese können dir sowohl beim Einpflegen deiner Versicherung als auch in der anschließenden Beratung helfen.",
        },
        {
          question:
            "Ich habe mein Passwort vergessen. Wo kann ich dieses zurücksetzen?",
          answer:
            "Dein Passwort kannst du jederzeit im Login-Bereich mithilfe der von dir angegebenen E-Mail-Adresse ändern. Klicke dafür lediglich auf 'Passwort vergessen?' und folge den Anweisungen auf der Seite.",
        },
      ],
    },
    registerNow: {
      title: "Jetzt in nur 2 Minuten registrieren und direkt Bedarf ermitteln",
      subTitle:
        "Keine Zeit mehr verlieren – All deine Finanzen auf einem Blick – Perfekt organisiert, ohne Papierkram",
      registerButtonLabel: "Kostenlos registrieren!",
    },
  },
  sideboard: {
    score: {
      header: "Dein aktueller Finanzscore",
      security: "Sicherung des finanziellen Grundbedarfs",
      provision: "Erhaltung des Lebenstandards",
      wealthPlanning: "Verbesserung des Lebensstandards",
    },
    contracts: {
      header: "Deine Verträge im Überblick",
      insurances: "Versicherungen",
      investments: "Geldanlagen",
    },
  },
  userPortal: {
    userPortalWelcome: "Herzlich Willkommen",
  },
  userProfile: {
    userProfileHeaderStammdaten: "Persönliche Daten",
    userProfileChangeMyData: "Ändere hier deine Daten",
    userProfileName: "Versicherte Person:",
    userProfileBirthday: "Geburtstag:",
    userProfileContactData: "Kontaktdaten:",
    userProfileAddress: "Meldeadresse:",
    userProfileBankAccount: "Bankverbindung",
    userProfileBank: "Bank:",
    userProfileIBAN: "IBAN:",
  },
  addContract: {
    controls: {
      backButtonLabel: "Zurück",
      continueButtonLabel: "Weiter",
      submitLabel: "Hinzufügen",
    },
    form: {
      success: {
        title: "Hochladen erfolgreich",
        message: "Dein Vertrag wurde erfolgreich hoch­geladen.",
      },
      error: {
        title: "Hochladen fehlgeschlagen",
        message: "Beim Hochladen des Vertrags ist ein Fehler aufgetreten.",
      },
    },
    step1: {
      title: "Vertragsart und Gesellschaft",
      productTypSelection: "Um was für einen Vertrag handelt es sich?",
      productTypSelectionOptions: {
        insurance: "Versicherung",
        financialInvestments: "Spar- oder Kapitalanlagen",
        other: "Sonstiger Vertrag",
      },
      contractTypSelection: "Wähle einen Vertrag aus:",
      contractTypSelectionError:
        "Bitte wähle einen Vertrag oder 'Sonstiges' aus.",
      customContractTyp: "Beschreibe deinen Vertrag:",
      customContractTypError: "Bitte gib einen Namen für den Vertrag ein.",
      instituteSelection: "Wähle eine Gesellschaft aus:",
      instituteSelectionError:
        "Bitte wähle eine Gesellschaft oder 'Sonstiges' aus.",
      customInstitute: "Name der Gesellschaft:",
      customInstituteError: "Bitte gib einen Namen ein.",
    },
    step2: {
      title: "Vertragsdaten und -dokumente",
      contractData: "Vertragsdaten",
      uploadContract: "Vertrag hochladen",
      contractNumberLabel: "Vertragsnummer:",
      amountLabel: "Beitrag in €:",
      amountError: "Bitte gib einen Zahlenwert ein.",
      amountIntervallLabel: "Beitragsintervall:",
      contractDurationLabel: "Vertragslaufzeit:",
      nameOfInsuredPersonLabel:
        "Name der versicherten Person (sofern nicht selbst):",
      fileUploadText:
        "Lade abschließend noch deinen Vertrag hoch. Achte darauf, dass folgende Informationen gut zu lesen sind:",
      fileUploadTextBullets:
        "Versicherungsgesellschaft, Vertragsnummer, Vertragslaufzeit & Aktueller Betrag",
      fileUploadDragDropFile: "Ziehe ein Dokument in dieses Feld",
      uploadDocumentsLater:
        "Dokumente können auch später noch hochgeladen werden.",
      contractNameLabel: "Name:",
      contractNameError: "Bitte gib einen Namen für den Vertrag ein.",
      isinLabel: "ISIN/WKN:",
      acquisitionValueLabel: "Kaufwert:",
      currentValueLabel: "Aktueller Wert:",
      currentValueError: "Bitte gib den aktuellen Wert ein.",
      usageLabel: "Verwendungszweck:",
      usageError: "Bitte wähle einen Verwendungszweck aus.",
      descriptionLabel: "Bezeichnung:",
      descriptionError: "Bitte gib eine Bezeichnung ein.",
      depositLabel: "Einzahlung:",
      savingsLabel: "Sparbetrag:",
      targetValueLabel: "Zielsparsumme:",
      expectedOneTimePayoutLabel: "Prognostizierte einmalige Auszahlung:",
      expectedRecurringPayoutLabel: "Prognostizierte laufende Auszahlung:",
      expectedPayoutError: "Bitte gib einen Prognosewert ein.",
      expectedPayoutGroupLabel: "Prognostizierte Auszahlung:",
      expectedPayoutGroupRecurringLabel: "monatl. Rente",
      expectedPayoutGroupOneTimeLabel: "einmalig",
      homeSavingsContractLabel: "Bausparsumme:",
      homeSavingsContractError: "Bitte gib eine Bausparsumme ein.",
      pensionIncomeDisabilityLabel: "Rente bei Erwerbsunfähigkeit:",
      pensionIncomeDisabilityError: "Bitte gib einen Rentenwert ein.",
      pensionWorkDisabilityLabel: "Rente bei Berufsunfähigkeit:",
      pensionWorkDisabilityError: "Bitte gib einen Rentenwert ein.",
      riskTypeLabel: "Risikoart:",
      riskTypeError: "Bitte wähle eine Risikoart aus.",
      careServiceLabel: "Pflegeleistung:",
      careServiceError: "Bitte gib eine Pflegeleistung ein.",
      riskValueLabel: "Versicherungssumme:",
      riskValueError: "Bitte gib eine Versicherungssumme ein.",
      paymentOnAccidentLabel: "Leistung bei Unfall:",
      paymentOnAccidentError: "Bitte gib einen Wert ein.",
      paymentOnDeathLabel: "Leistung bei Todesfall:",
      paymentOnDeathError: "Bitte gib einen Wert ein.",
      kaskoValueVehicleLabel: "Kfz Beitrag Kasko:",
      kaskoValueVehicleError: "Bitte gib einen Beitrag ein.",
      pensionCareLabel: (stufe: number) => `Rente Pflegestufe ${stufe}:`,
      pensionCareError: "Bitte gib einen Wert ein.",
      ambulantScheduleOfFeesLabel: "Gebührenordnung (ambulant):",
      stationaryScheduleOfFeesLabel: "Gebührenordnung (stationär):",
      dentalScheduleOfFeesLabel: "Gebührenordnung (Zahn):",
      scheduleOfFeesError: "Bitte wähle eine Gebührenordnung",
      sickPayLabel: "Krankentagegeld:",
      stationaryChiefDoctorLabel: "Chefarztbehandlung",
      stationaryHospitalChoiceLabel: "Krankenhauswahl",
      stationaryAccomodationLabel: "Unterkunft:",
      ambulantRefundLabel: "Erstattung",
      ambulantNaturopathLabel: "Heilpraktiker",
      ambulantHufelandLabel: "Hufeland",
      ambulantVisualAidLabel: "Sehhilfe:",
      ambulantCheckUpLabel: "Vorsorgeuntersuchung",
      dentalTreatmentLabel: "Zahnbehandlung",
      dentalClaimLabel: "Zahnanspruch:",
      dentalReplacementLabel: "Zahnersatz",
      dentalReplacementWithoutAdvanceLabel: "Zahnersatz (ohne Vorleistung)",
      dentalImplantsLabel: "Zahnimplantate",
      dentalNoScaleLabel: "Keine Staffelung",
      dentalOrthodonticsLabel: "Kieferorthopädie",
      ambulant: "Ambulant:",
      stationary: "Stationär:",
      dental: "Zahn:",
      vehicleManufacturerLabel: "Hersteller:",
      vehicleTypeLabel: "Fahrzeugtyp:",
    },
    addContractHeader: "Jetzt einen Vertrag hinzufügen",
    addContractStep1Title: "Vertragsart und Gesellschaft",
    addContractStep2Title: "Vertragsdaten und -dokumente",
    addContractUpload: "Hochladen",
    addContractDragDropFile:
      "Klicke hier oder ziehe ein Dokument in dieses Feld",
    addContractUploadedDoc: "Hochgeladenes Dokument:",
    addContractUploadedDocPlural: "Hochgeladene Dokumente:",
    addContractStep2Institute: "Wähle die Gesellschaft aus:",
    addContractStep2Content: "Lade jetzt nur noch deinen Vertrag hoch.",
    addContractStep2Remark: "Folgende Angaben sollten gut lesbar sein.",
    addContractStep2Bullet1: "Name der Versicherungs",
    addContractStep2Bullet2: "Vertrags- und Kundennummer",
    addContractStep2Bullet3: "Laufzeit des Vertrages",
    addContractStep2Bullet4: "Aktueller Beitag",
  },
  userContact: {
    title: "Kontakt",
    subtitle: "Komme gerne auf uns zu!",
    introduction: {
      paragraphs: [
        "Wir stehen dir bei Problemen oder Fragen gerne zur Verfügung. Unser kompetentes Team an Versicherungs- und Anlageexperten hilft dir gerne beim Vergleichen, Abschließen oder Ändern deiner Versicherungen.",
        "Das ist myNORM!",
      ],
    },
    contactOptionsWeb: {
      title: "Online Termin vereinbaren",
      prose:
        "Vereinbare jetzt einfach und unverbindlich einen Termin mit einem unserer Experten. Wähle einfach den Tag und die Uhrzeit und erhalte innerhalb weniger Sekunden eine Online-Einladung per Mail.",
    },
    contactOptionsOther: {
      title: "Weitere Möglichkeiten",
      prose:
        "Gerne kannst du uns auch auf anderem Wege kontaktieren. Du findest uns hier:",
      /* Email and Phone from footer */
      addressLines: ["Rendsburger Straße 14", "30659 Hannover"],
    },
    userContactCalendlyButton: "Termin vereinbaren",
  },
  messageBox: {
    header: "Nachrichtenbox",
    incomingMessages: "Empfangen",
    outgoingMessages: "Gesendet",
    today: "Heute",
    noMessages: "Keine Nachrichten",
    controls: {
      newMessage: "Neue Nachricht",
    },
    messageDialog: {
      header: "Neue Nachricht schreiben",
      title: "Betreff:",
      titleValidation: "Bitte gib einen Betreff ein.",
      body: "Nachricht:",
      bodyValidation: "Bitte gib eine Nachricht ein.",
      controls: {
        send: "Senden",
        cancel: "Abbrechen",
      },
      errorMessage: "Beim Senden der Nachricht ist ein Fehler aufgetreten.",
    },
  },
  hausrat: {
    comparison: {
      inputs: {
        genericRequired: "Dieses Feld wird benötigt.",
        postleitzahlLabel: "PLZ der versicherten Wohneinheit?",
        postleitzahlFormatError:
          "Die Postleitzahl scheint nicht korrekt zu sein.",
        postleitzahlPlaceholder: "PLZ",
        wohnflaecheLabel: "Wohnfläche der zu versichernden Wohneinheit?",
        wohnflaechePlaceholder: "Wohnfläche in qm",
        wohnflaecheFormatError:
          "Die Wohnfläche muss in ganzen Quadratmetern eingegeben werden.",
        geburtsdatumLabel: "Wie lautet Dein Geburtsdatum?",
        geburtsdatumPlaceholder: "TT.MM.JJJJ",
        geburtsdatumFutureError:
          "Das Geburtsdatum muss in der Vergangenheit liegen.",
        versicherungssummeLabel: "Höhe der Versicherungssumme?",
        versicherungssummePlaceholder: "XX.XXX €",
        versicherungssummeNegativeError:
          "Die Versicherungssumme muss positiv sein.",
        versicherungssummeTypeError:
          "Die Versicherungssumme muss eine Ganzzahl sein.",
        fahrlaessigkeitAbgedecktLabel: "Voller Schutz bei Fahrlässigkeit?",
        arbeitInOeffentlicherDienstLabel:
          "Arbeitest du im öffentlichen Dienst?",
        erstinformationErhaltenLabel:
          "Ich bestätige die Erstinformationen des Versicherungsmaklers myNORM für xxx gemäß §xx heruntergeladen und gelesen zu haben.",
        erstinformationErhaltenNotCheckedError:
          "Du musst bestätigen, dass du die Informationen erhalten hast.",
      },
      startCompareButtonLabel: "Vergleich jetzt starten",
    },

    hausratversicherungPrivacyCheckDownload: "Informationen herunterladen",

    hausratversicherungStartComparison: "Vergleichen",
    hausratversicherungReStartComparison: "Erneut suchen",
    hausratversicherungVergleichErgebnisPageTitle: "Hausratversicherung",
    hausratversicherungVergleichErgebnisSubTitle1: "Vergleich für",
    hausratversicherungVergleichErgebnisSubTitle2: "m\u{00B2} in",
  },
  covomoLinkName: {
    reiseVersicherungVergleich:
      "Deine Reise­versicherung in 5 Minuten abschließen und ganz entspannt in deinen Urlaub",
    mopedVersicherungVergleich: "Moped­versicherung im Vergleich",
    fahrradVersicherungVergleich: "Fahrrad­versicherung im Vergleich",
    gewaesserschadenhaftpflichtVergleich:
      "Gewässer­schadenhaft­pflicht im Vergleich",
    jagdhaftpflichtVergleich: "Jagd­schadenhaft­pflicht im Vergleich",
    hundehaftpflichtVergleich: "Hunde­schadenhaft­pflicht im Vergleich",
    pferdehaftpflichtVergleich: "Pferde­schadenhaft­pflicht im Vergleich",
    langzeitreisekrankenversicherungVergleich:
      "Lang­zeit­reise­kranken­versicherung im Vergleich",
    erntehelferkrankenVergleich:
      "Ernte­helfer­kranken­versicherung im Vergleich",
    drohnenversicherungVergleich: "Drohnen­versicherung im Vergleich",
    sterbegeldversicherungVergleich: "Sterbe­geld­versicherung im Vergleich",
    hochzeitsversicherungVergleich:
      "Hoch­zeits­versicherung­versicherung im Vergleich",
    mietkautionsversicherungVergleich: "Mietkautions­versicherung im Vergleich",
  },
  insuranceComparison: {
    insuranceComparisonOptionsHeadline: "Versicherungsschutz erweitern",
    insuranceComparisonFilterHeadline: "Filter",
    insuranceComparisonPaymentOptionHeadline: "Zahlweise",
    insuranceComparisonDeductibleOptionHeadline: "Selbstbeteiligung",
    insuranceComparisonSortingHeadline: "Sortieren nach:",
    insuranceComparisonCustomerInfoBirthDayLabel: "Geburtstag:",
    insuranceComparisonCustomerInfoButtonLabel: "Eingaben ändern",
    insuranceComparisonOptionCardPriceInfo1: "ab",
    insuranceComparisonOptionCardPriceInfo2: "€ zusätzlich",
    insuranceComparisonInsuranceDataBoxDetails: "Details",
    insuranceComparisonInsuranceDataBoxButton: "Jetzt absichern",
    insuranceComparisonInsuranceDataBoxCompareLabel: "Tarif vergleichen",
    insuranceComparisonInsuranceRatingLabel: "Bewertung:",
    insuranceComparisonInsuranceComparisonBoxHeadline1: "Vergleiche",
    insuranceComparisonInsuranceComparisonBoxHeadline2: "folgende Tarife",
    insuranceComparisonInsuranceComparisonBoxButtonLabel: "Vergleichen",
    insuranceComparisonSortByPrice: "Preis",
    insuranceComparisonSortByRating: "Bewertung",
  },
  insuranceAbschluss: {
    proceedButtonLabel: "Weiter >",
    backButtonLabel: "< Zurück",
    insuranceBreadcrumbs: {
      breadcrumbStep1: "Deine Daten",
      breadcrumbStep2: "Bisherige Versicherung",
      breadcrumbStep3: "Zahlungsdaten",
      breadcrumbStep4: "Übersicht",
    },
    selectedInsuranceBadge: {
      pricePeriod: "pro Jahr",
    },
    previousInsuranceStep: {
      headlinePreviousDamage: "Vorschäden",
      sublinePreviousDamage:
        "Hattest du in den letzten 5 Jahren Schäden in der Hausrat?",
      previousDamageNo: "Nein",
      previousDamageYes: "Ja, ich hatte Schäden",
      previousDamageCount:
        "Wie viele Schäden hat die Versicherung in den letzten 5 Jahren für Sie übernommen?",
      previousDamageInputLabel: "Anzahl der Schäden",
      previousDamageYearSum: "Jahr und Summe",
      previousDamageYearInputLabel: "Jahr",
      previousDamageAmountPlaceholder: "Betrag (in EUR)",
      headlineInsuranceBegin: "Gewünschter Versicherungsbeginn",
      insuranceBeginNow: "Nächster Werktag",
      insuranceBeginToDate: "Später",
      insuranceBeginInputPlaceholder: "TT.MM.JJJJ",
    },
    bankAccountStep: {
      headlineBankAccount: "Bankverbindung",
      iban: "IBAN",
      ibanPlaceholder: "DE00 0000 0000 0000 0000 00",
      ibanInputDescription:
        "Deine IBAN wird benötigt, um den Antrag für deine neue Versicherung vollständig auszufüllen. Sollte ein Vetrag zustande kommen, wird der Versicherungsbetrag von diesem Konto angebucht.",
      debitMandate:
        "Ich erteile das SEPA-Lastschriftmandat zum Einzug der fälligen Beiträge von meinem Konto.",
    },
  },
  featureMap: {
    hausratVersicherungSumme: "Versicherungssumme",
    hausratVersicherungSummeFahrrad: "Fahrrad",
    hausratVersicherungSummeUeberspannungsschutz: "Überspannung",
    hausratVersicherungSummeWertsachen: "Wertsachen",
  },
  account: {
    login: {
      title: "Anmeldung",
      inputEmailLabel: "E-Mail",
      inputEmailPlaceholder: "max.mustermann@aol.de",
      inputPasswordLabel: "Passwort",
      inputPasswordPlaceholder: "Passwort",
      loginButtonLabel: "Anmelden",
      loginHint1: "Noch keinen Account? Dann",
      loginHint2: "registriere dich jetzt.",
      forgotPasswordHint: "Passwort vergessen?",
      authError: "Anmeldung nicht möglich. E-Mail oder Passwort ist falsch.",
      genericAuthError:
        "Bei der Anmeldung ist ein Fehler aufgetreten. Versuche es später noch einmal.",
    },
    loginAdditionalData: {
      title: "Telefonnummer ergänzen",
      introduction:
        "Uns ist der Schutz deiner persönlichen Daten sehr wichtig. Deshalb bitten wir dich, dich mit deiner Telefonnummer zu verifizieren.",
      inputMobileNumberLabel: "Handynummer",
      inputMobileNumberPlaceholder: "Handynummer",
      loginAdditionalDataButtonLabel: "Speichern",
    },
    authenticationCode: {
      introduction:
        "Vielen Dank für deine Registrierung. Wir haben eine SMS an die von dir hinterlegte Handynummer mit einem Code gesendet. Um sich zu verifizieren gib den Code bitte in das untenstehende Feld ein.",
      title: "Sicherheitscode",
      resendCodeHint: "Code wurde erneut gesendet",
      resendCodeQuestion: "Kein Code erhalten?",
      resendCodeAction: "Erneut senden",
      codeMismatch:
        "Der eingegebene Sicherheitscode ist ungültig, bitte versuch es noch einmal.",
      genericCodeError:
        "Bei der Überprüfung ist etwas schiefgelaufen, bitte versuch es noch einmal.",
    },
    registration: {
      introduction:
        "Registriere dich in nur 2 Minuten und habe ab dann deine Verträge jederzeit im Blick.",
      title: "Registrierung",
      inputFirstNameLabel: "Vorname",
      inputFirstNamePlaceholder: "Max",
      inputLastNameLabel: "Nachname",
      inputLastNamePlaceholder: "Mustermann",
      inputEmailLabel: "E-Mail",
      inputEmailPlaceholder: "max.mustermann@aol.de",
      inputPasswordLabel: "Passwort",
      inputPasswordPlaceholder: "Passwort",
      inputPasswordHelp:
        "je 1 kleines, großes und numerisches Zeichen; min. Länge: 8",
      inputPasswordValidation: "Passwort erfüllt nicht alle Kriterien",
      inputMobileNumberLabel: "Handynummer",
      inputMobileNumberPlaceholder: "Handynummer",
      registerButtonLabel: "Nächster Schritt >",
      registerHint1: "Du hast schon einen Account?",
      registerHint2: "Dann",
      registerHint3: "log dich jetzt ein.",
      inputGenderLabel: "Anrede",
      inputTitleLabel: "Titel",
      genderTypes: {
        male: "Mann",
        female: "Frau",
        nonBinary: "Divers",
      },
      additional: {
        introduction: "Vielen Dank",
        introductionSubtext:
          "Fülle jetzt noch einige persönliche Informationen aus, um richtig loslegen zu können.",
        inputStreetLabel: "Straße",
        inputStreetPlaceholder: "Straße",
        inputPostalCodeLabel: "PLZ",
        inputPostalCodePlaceholder: "PLZ",
        inputCityLabel: "Ort",
        inputCityPlaceholder: "Ort",
        inputBirthdayLabel: "Geburtsdatum",
        inputBirthdayPlaceholder: "TT.MM.JJJJ",
        inputBirthPlaceLabel: "Geburtsort",
        inputBirthPlacePlaceholder: "Geburtsort",
        finishedButton: "Fertig >",
      },
      error: {
        title: "Fehler",
        message:
          "Die Registrierung ist vorübergehend nicht möglich. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.",
      },
    },
    forgotPassword: {
      title: "Passwort vergessen",
      inputEmailLabel: "E-Mail",
      inputEmailPlaceholder: "max.mustermann@aol.de",
      forgotPasswordButtonLabel: "Weiter",
    },
    forgotPasswordCode: {
      introduction1: "Wir haben Ihnen",
      introduction2:
        "mit einem Code gesendet. Um ein neues Passwort zu vergeben, geben Sie den Code bitte in das untenstehende Feld ein.",
      introductionSMS: "eine SMS an die von Ihnen hinterlegte Handynummer",
      introductionEmail:
        "eine E-Mail an die von Ihnen hinterlegte E-Mail-Adresse",
      title: "Sicherheitscode",
    },
    forgotPasswordConfirm: {
      title: "Neues Passwort vergeben",
      inputPasswordLabel: "Passwort",
      inputPasswordPlaceholder: "Passwort",
      inputPasswordValidation: "Passwort erfüllt nicht alle Kriterien",
      inputPasswordHelp:
        "je 1 kleines, großes und numerisches Zeichen; min. Länge: 8",
      forgotPasswordConfirmButtonLabel: "Absenden",
    },
  },
  popUp: {
    okButton: "OK",
  },
  demandCalculator: {
    control: {
      reset: "Zurücksetzen",
      calculate: "Jetzt berechnen",
    },
    personal: {
      title: "Persönliches und Familie",
      birthday: "Geburtstag",
      maritalStatus: "Familienstand",
      numberOfChildren: "Anzahl Kinder",
      livingSituation: "Wohnsituation",
      livingSpace: "Wohnfläche (m²)",
      rentedProperty: "Fremdgenutzte Immobilie",
      yearOfConstruction: "Baujahr",
      initialConstructionCosts: "Neubausumme Immobilie",
    },
    income: {
      title: "Einkommen",
      occupationState: "Berufsstatus",
      specialOccupation: "Besondere Tätigkeiten",
      nettoIncome: "Nettogehalt",
      capitalIncome: "Kapitaleinnahmen",
      otherIncome: "Sonstige Einnahmen",
      totalExpenses: "Gesamtausgaben",
      healthInsurance: "Krankenversicherung",
      healthInsuranceFee: "Monatlicher Beitrag KV/PV",
      pensionInsuranceFee: "Monatlicher Beitrag Rentenversicherung",
    },
    risks: {
      title: "Risiken und Hobbies",
      foreignTravel: "Auslandsreisen",
      dogOrHorse: "Halter Hund oder Pferd",
      motorVehicle: "Halter Kfz",
      airVehicle: "Luftfahrzeug",
      waterVehicle: "Wasserfahrzeug",
      hunting: "Jagen Sie",
      oilTank: "Öltank",
      photovoltaic: "Photovoltaik",
      buildOrSanitize: "Bauen oder Sanieren",
    },
    assets: {
      title: "Vermögen und Sparen",
      marketValueProperty: "Verkehrswert Immobilie",
      liabilitiesProperty: "Verbindlichkeiten Immobilie",
      capitalAssets: "Kapitalanlagen",
      otherAssets: "Sonstige Vermögenswerte",
      loans: "Sonstige Darlehen",
      consumerCredits: "Konsumentenkredit",
      dispositionCredit: "Dispositionskredit",
      investmentGoal: "Investitionsziel",
      targetSavingsAmount: "Wunschbetrag für Sparziel",
      targetSavingsDate: "Datum Sparziel erreicht",
      ownPropertyIntended: "Eigene Immobilie geplant",
      costOfProperty: "Kosten der Immobilie",
      intendedDateOfAcquisition: "Geplantes Datum für Kauf",
      replacementInvestment: "Ersatzinvestition",
      replacementInvestmentValue: "Ersatzinvestition Betrag",
      replacementInvestmentDate: "Datum der Ersatzinvestition",
    },
    result: {
      header: "Dein digitaler",
      headerEmphasis: "FinanzCheck",
      subHeader: "Finde heraus, was du wirklich brauchst.",
      essentialTitle: "Pflicht- und Basisversicherungen",
      importantTitle: "Wichtige Versicherungen",
      recommendedTitle: "Empfohlene Versicherungen",
      minimumInsuranceSum: (label: string, sum: string, interval: string) =>
        `Die empfohlene ${label} beträgt ${sum} ${interval}`.trim() + ".",
      optimumInsuranceSum: (label: string, sum: string, interval: string) =>
        `Die optimale ${label} beträgt ${sum} ${interval}`.trim() + ".",
      defaultRecommendedSumLabel: "Summe",
    },
  },
};
