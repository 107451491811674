/* eslint-disable react/no-unescaped-entities */
import styles from "pages/legal-documents/LegalDoc.module.scss";

import React, { FunctionComponent, ReactNode } from "react";
import PageSection from "../../components/page-section/PageSection";
import Page from "../../components/page/Page";

type Props = {
  children: ReactNode;
  title: string;
};

export const LegalDoc: FunctionComponent<Props> = ({ children, title }) => {
  return (
    <Page>
      <PageSection>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{children}</div>
      </PageSection>
    </Page>
  );
};
