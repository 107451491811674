import { createContext } from "react";
import { CodeDeliveryDetails } from "amazon-cognito-identity-js";

export type ForgotPasswordWizardContextState = {
  email: string;
  password: string;
  awsResult?: CodeDeliveryDetails;
  code: string;
};

export type ForgotPasswordWizardContextType = {
  state: ForgotPasswordWizardContextState;
  setState: (state: ForgotPasswordWizardContextState) => void;
};

const ForgotPasswordWizardContext = createContext<
  ForgotPasswordWizardContextType | undefined
>(undefined);

export default ForgotPasswordWizardContext;
