import "react-phone-number-input/style.css";
import styles from "components/organisms/LoginAdditionalDataForm.module.scss";

import React, { ReactElement } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Box } from "components/atoms/Box";
import { Text } from "components/atoms/Text";
import StepContainer from "components/step-wizard/components/StepContainer";
import useTabIndex from "hooks/useTabIndex";
import useUserLogin from "hooks/useUserLogin";
import useUserUpdate from "hooks/useUserUpdate";
import { STRINGS } from "language/de_DE/strings";
import redirectAfterLogin from "utils/redirectAfterLogin";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Button } from "components/form-inputs/Button/Button";
import { PhoneInputField } from "../form-inputs/PhoneInputField/PhoneInputField";

const LoginAdditionalDataForm = (): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { getTabIndex } = useTabIndex();
  const { rememberUser, getCurrentLoggedInUser } = useUserLogin();
  const { updateUser } = useUserUpdate();
  const navigate = useNavigate();

  return (
    <StepContainer>
      <Formik
        initialValues={{ phoneNumber: "" }}
        onSubmit={async (values) => {
          const user = await getCurrentLoggedInUser();

          if (user) {
            const result = await updateUser(user, {
              phoneNumber: values.phoneNumber,
            });
            if (!result.ok) {
              return;
            }
          }

          await rememberUser();
          redirectAfterLogin(navigate);
        }}
      >
        {() => (
          <Form>
            <Box flex column className={styles.loginBox}>
              <Text size={"xLarge"} className={styles.title} inline>
                {STRINGS.account.loginAdditionalData.title}
              </Text>

              <Text size={"medium"} inline className={styles.introductionText}>
                {STRINGS.account.loginAdditionalData.introduction}
              </Text>
              <Box>
                <Field
                  name={"phoneNumber"}
                  component={PhoneInputField}
                  labelText={
                    STRINGS.account.loginAdditionalData.inputMobileNumberLabel
                  }
                  placeholder={
                    STRINGS.account.loginAdditionalData
                      .inputMobileNumberPlaceholder
                  }
                  tabIndex={getTabIndex()}
                  validate={(value?: string) => {
                    if (!isValidPhoneNumber(value || "")) {
                      return STRINGS.formInputs.validation.phone(
                        STRINGS.account.loginAdditionalData
                          .inputMobileNumberLabel
                      );
                    }
                  }}
                />
                <Button
                  primary
                  label={
                    STRINGS.account.loginAdditionalData
                      .loginAdditionalDataButtonLabel
                  }
                  type={"submit"}
                  className={styles.submitButton}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </StepContainer>
  );
};

export default LoginAdditionalDataForm;
