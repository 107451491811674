import React, { ReactElement } from "react";
import { Checkbox } from "../../../../../form-inputs/Checkbox/Checkbox";
import { Field } from "formik";

interface AddContractCheckboxProps {
  name: string;
  labelText: string;
}

export const AddContractCheckbox = ({
  name,
  labelText,
}: AddContractCheckboxProps): ReactElement => {
  return (
    <Field
      component={Checkbox}
      type="checkbox"
      name={name}
      labelText={labelText}
    />
  );
};
