import React, { FunctionComponent } from "react";
import styles from "./USP.module.scss";
import { STRINGS } from "../../../../language/de_DE/strings";
import { USPs } from "content/features";
import PageSection from "../../../page-section/PageSection";

const sectionStrings = STRINGS.aboutUs.usp;

export const USP: FunctionComponent = () => (
  <PageSection>
    <h1 className={styles.title}>
      {sectionStrings.titleFirstLine}
      <span className={styles.highlighted}>
        {sectionStrings.titleSecondLine}
      </span>
    </h1>
    <div className={styles.content}>
      {USPs.map((feature) => (
        <div className={styles.listItem} key={`list_item_${feature.title}`}>
          <div className={styles.icon}>
            <img src={feature.img} alt={feature.title} />
          </div>
          <div>
            <h2 className={styles.featureTitle}>{feature.title}</h2>
            <p>{feature.text}</p>
          </div>
        </div>
      ))}
    </div>
  </PageSection>
);
