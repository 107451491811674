import React, { useMemo } from "react";
import {
  EditableUserProfilePhoneNumber,
  EditableUserProfileSingleSelect,
  EditableUserProfileTextfield,
} from "components/user-portal/user-profile/dialogs/item/EditableUserProfileItem";
import { NATIONS } from "language/de_DE/nations";
import { STRINGS } from "language/de_DE/strings";
import { KundeResponse } from "types/backend/KundeResponse";
import { formatEnum } from "utils/formatUtils";
import { createOptions } from "utils/formInputUtils";
import { Form, Formik } from "formik";
import { UseMutationResult } from "react-query";
import { KundeRequest } from "../../../../../types/backend/KundeRequest";

interface EditContactFormProps {
  formId: string;
  kunde: KundeResponse;
  mutation: UseMutationResult<unknown, unknown, KundeRequest>;
}

const EditContactForm: React.FC<EditContactFormProps> = ({
  formId,
  kunde,
  mutation,
}) => {
  const nationOptions = useMemo(() => createOptions(NATIONS), []);

  const initKunde = () => {
    const kontaktarten = kunde.kontaktdaten.map((k) => k.art);
    if (!kontaktarten.includes("E_MAIL")) {
      kunde.kontaktdaten.push({ art: "E_MAIL", wert: "" });
    }
    if (!kontaktarten.includes("TELEFON")) {
      kunde.kontaktdaten.push({ art: "TELEFON", wert: "" });
    }
    return kunde;
  };

  return (
    <Formik
      initialValues={{ ...(initKunde() || {}) }}
      onSubmit={(values) => mutation.mutate(values)}
      enableReinitialize={true}
    >
      {() => {
        return (
          <Form id={formId}>
            {kunde.kontaktdaten.map((kontaktdaten, index) => {
              if (kontaktdaten.art === "TELEFON") {
                return (
                  <EditableUserProfilePhoneNumber
                    key={kontaktdaten.art}
                    fieldName={`kontaktdaten.${index}.wert`}
                    labelText={formatEnum(
                      kontaktdaten.art,
                      STRINGS.profile.contact.contactTypes
                    )}
                    required
                  />
                );
              }
              if (kontaktdaten.art === "E_MAIL") {
                return (
                  <EditableUserProfileTextfield
                    key={kontaktdaten.art}
                    fieldName={`kontaktdaten.${index}.wert`}
                    labelText={formatEnum(
                      kontaktdaten.art,
                      STRINGS.profile.contact.contactTypes
                    )}
                    required
                  />
                );
              }
              return null;
            })}
            <EditableUserProfileTextfield
              fieldName="adressen.0.strasse"
              labelText={STRINGS.profile.contact.street}
              required
            />
            <EditableUserProfileTextfield
              fieldName="adressen.0.plz"
              labelText={STRINGS.profile.contact.postalCode}
              required
            />
            <EditableUserProfileTextfield
              fieldName="adressen.0.ort"
              labelText={STRINGS.profile.contact.city}
              required
            />
            <EditableUserProfileSingleSelect
              fieldName="nation"
              options={nationOptions}
              labelText={STRINGS.profile.contact.nation}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditContactForm;
