import { createContext } from "react";

export type LoginWizardContextState = {
  email: string;
  password: string;
};

export type LoginWizardContextType = {
  state: LoginWizardContextState;
  setState: (state: LoginWizardContextState) => void;
};

const LoginWizardContext = createContext<LoginWizardContextType | undefined>(
  undefined
);

export default LoginWizardContext;
