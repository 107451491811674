import { RouteUrls } from "config/routes";
import { ProductTreeInsuranceType } from "../productTreeDefinition";

import descriptionImage0 from "gfx/images/insurance-images/tierhalterhaftpflicht_0.jpg";
import descriptionImage1 from "gfx/images/insurance-images/tierhalterhaftpflicht_1.jpg";
import descriptionImage2 from "gfx/images/insurance-images/tierhalterhaftpflicht_2.jpg";
import icon1 from "gfx/icons/icon1796.svg";

import devkLogo from "gfx/images/insurance-logos/DEVK_logo.png";
import wurttLogo from "gfx/images/insurance-logos/Württembergische_Versicherung_Logo.png";
import allianzLogo from "gfx/images/insurance-logos/allianz-logo.png";
import allSecurLogo from "gfx/images/insurance-logos/AllSecur_Logo.png";
import hdiLogo from "gfx/images/insurance-logos/HDI-Logo.png";
import ruvLogo from "gfx/images/insurance-logos/R_u_V-Logo.png";
import lvmLogo from "gfx/images/insurance-logos/LVM_Versicherung_2010_logo.png";

const productName = "Tier­halter­haft­pflicht";

export const tierhalterhaftpflichtProductTree: ProductTreeInsuranceType = {
  title: productName,
  image: descriptionImage0,
  introduction:
    "Tiere verpflichten: Obwohl dein Hund oder Pferd sehr gut erzogen ist, lassen sich Schäden nicht immer verhindern. Als Tierhalter haftest du in un­begrenzter Höhe für alle Personen-, Sach- und Vermögens­schäden, die dein Tier verursacht – auch wenn du selbst keine Schuld trägst.",

  headerButtons: [
    { label: "Bedarf prüfen", link: RouteUrls.demandCalculator },
    { label: "Persönliche Beratung", link: RouteUrls.userPortalContact },
  ],

  insuranceSituation: [
    {
      title:
        "... dein Hund mit seinem Schwanz in der Wohnung der Freunde eine teure Vase zu Boden reißt.",
      text: icon1,
    },
    {
      title:
        "... eine Passantin vom Bellen deines Hundes erschrickt, vom Fahrrad stürzt und sich dabei ein Bein bricht.",
      text: icon1,
    },
  ],

  longDescriptions: [
    {
      title: "Was ist eine  " + productName + "?",
      paragraphs: [
        "Zu den Tierhalterhaftpflichtversicherungen gehören insbesondere die Hundehalterhaftpflicht- und die Pferdehalterhaftpflichtversicherung.",
        "Pferde sind Fluchttiere und können sich unter Stresssituationen losreißen.",
        "Bei ihrer Flucht können sie große Schäden anrichten, für die dann der  Tierhalter aufkommen muss  – und zwar unabhängig davon, ob ihn ein Verschulden trifft.",
        "Die Pferdehalterhaftpflicht bietet Schutz vor den finanziellen Folgen eines Unfalls durch dein Pferd und ist dir zu empfehlen, wenn du selbst ein Pferd hast.",
      ],
      image: descriptionImage1,
    },
    {
      title: "Wer braucht eine " + productName + "?",
      paragraphs: [
        "Während jedoch für die Hundehalterhaftpflicht zumindest in sechs Bundesländern die generelle Versicherungspflicht gilt, ist die Pferdehalterhaftpflicht bundesweit keine Pflichtversicherung.",
        "Achte daher stets auf die lokalen Gegebenheiten an deinem Wohnort.",
      ],
      image: descriptionImage2,
    },
  ],

  insuranceBenefits: [
    {
      titleAccepted: "Die " + productName + " deckt in der Regel:",
      accepted: [
        "Personenschäden (z. B. Schmerzensgeld, Behandlungskosten nach einem Biss)",
        "Sachschäden (z. B. ein Hund zerstört die teuren Schuhe eines Gastes)",
        "Vermögensschäden als Folge eines Personen- oder Sachschadens (z. B. der Verdienstausfall eines verletzten Tierarztes)",
      ],
      titleNotAccepted: "Was wird NICHT versichert?",
      notAccepted: ["Schäden, die dem Tierhalter selbst entstehen"],
    },
  ],

  insuranceCompanies: [
    { companyName: "DEVK", image: devkLogo },
    { companyName: "Württembergische", image: wurttLogo },
    { companyName: "Allianz", image: allianzLogo },
    { companyName: "AllSecur", image: allSecurLogo },
    { companyName: "HDI", image: hdiLogo },
    { companyName: "R+V", image: ruvLogo },
    { companyName: "LVM", image: lvmLogo },
  ],

  checklist: [
    {
      title: "Abwehr unberechtigter Forderungen:",
      text: "Die Versicherungen prüfen, inwiefern für die Schadenersatzforderungen ein berechtigter Anspruch durch die Betroffenen besteht und wehren unberechtigte oder zu hohe Forderungen kostenlos für dich ab. Du erhältst daher mit Abschluss einer der benannten Tierhalterhaftpflichtversicherungen auch einen passiven Rechtsschutz.",
    },
    {
      title: "Gewerbliche Nutzung:",
      text: "Wenn Pferde gewerblich genutzt werden, beispielsweise als Therapie- oder Schulpferd, benötigst du einen speziellen Tarif. In der Pferdehalterhaftplicht für Privatpersonen ist daher eine gewerbliche Nutzung der Pferde vom Versicherungsschutz meist ausgeschlossen.",
    },
    {
      title: "Deckschäden:",
      text: "Wer einen Hengst hat, sollte darauf achten, dass auch sogenannte Deckschäden von der Versicherung übernommen werden. Denn wenn eine fruchtbare Stute unabsichtlich gedeckt wird, kann das unter Umständen teuer werden – zum Beispiel, wenn die Stute ein professionelles Reitpferd ist und dann einige Zeit ausfällt. Bei einer Hundehalterhaftpflichtversicherung besteht hingegen meist  Versicherungsschutz für ungewollte Deckakte durch deinen Rüden. Die Hundehalterhaftpflicht übernimmt in diesem Fall den Vermögensschaden für Abtreibung, die Kosten für die Versorgung des Wurfs oder den Sachschaden, wenn beispielsweise die Zucht mit der Hündin durch den ungewollten Deckakt dauerhaft nicht mehr möglich ist.",
    },
    {
      title: "Fohlen:",
      text: "Halter von Stuten sollten darauf achten, dass auch Fohlen in den ersten Monaten vom Versicherungsschutz erfasst werden. Ansonsten sind deren Schäden vom Versicherungsschutz der Mutter ausgeschlossen.",
    },
    {
      title: "Reitbeteiligungen:",
      text: "Auch bei einer Reitbeteiligung gelten Besonderheiten: Damit die Versicherung auch bei anderen Reitern greift, muss der Pferdehalter darauf achten, dass fremde Reiter mitversichert sind.",
    },
  ],

  link: RouteUrls.tierhalterHaftpflicht,
};
