/* eslint-disable react/no-unescaped-entities */
import React, { ReactElement } from "react";
import { LegalDoc } from "./LegalDoc";

export const AGB = (): ReactElement => {
  return (
    <LegalDoc title={"Allgemeine Geschäftsbedingungen für den deutschen Markt"}>
      <div>Stand: 2022</div>

      <div>
        <h2>A. Allgemeine Hinweise</h2>
        <div>
          Wir, die myNORM GmbH, sind Betreiber der Internet-Plattform
          www.mynorm.de. Wir sind als Makler für Versicherungen im Sinne des §
          34d Abs. 1 Gewerbeordnung (GewO) sowie Finanzanlagenvermittler gemäß §
          34f Abs. 1 GewO tätig. Der Vermittler ist im Laufe der
          Investitionsabwicklung niemals im Besitz von Anlegergeldern und bietet
          keinerlei Anlageberatung im Sinne des § 1 Abs. 1a Satz 2 Nr. 1a des
          Kreditwesengesetzes (KWG) an. Wir sind im Handelsregister des
          Amtsgerichts Hannover unter der Nummer HRB 222889 B eingetragen.
        </div>
      </div>

      <div>
        <h2>B. Was tun wir für Sie?</h2>
        <div>
          Wir vertreten unsere Kunden aktiv und passiv gegenüber den
          Versicherern. Wir
          <li>geben alle notwendigen Willenserklärungen ab und</li>
          <li>nehmen diese auch entgegen,</li>
          <li>schließen neue Verträge ab,</li>
          <li>ändern bestehende Verträge und</li>
          <li>kündigen bestehende Verträge.</li>
        </div>
        <div>
          Wir handeln immer nur mit Ihrem ausdrücklichen Einverständnis. Ihre
          bestehenden Verträge können Sie auf unserem Portal online verwalten.
          Dies kann sowohl auf einem Desktop als auch auf mobilen Endgeräten
          erfolgen.
        </div>
        <div>
          <h2>§ 1 Geltungsbereich: Was regeln diese Bedingungen?</h2>
          <div>
            <ol>
              <li>
                Diese Bedingungen (im Folgenden „AGB“) regeln das gesamte
                vertragliche Verhältnis zwischen Ihnen (im Folgenden "Kunde")
                und uns (im Folgenden "Makler"). Die AGB gelten für sämtliche
                Geschäfte, die Sie über unsere Internet-Plattform, per App oder
                im direkten Kontakt mit uns tätigen, dies natürlich nur unter
                der zwingenden Voraussetzung, dass die AGB vor Abschluss des
                Geschäftes auch wirksam Bestandteil des Vertrages wurden. Die
                AGB werden nur dann Bestandteil des Vertrags zwischen Ihnen und
                uns, wenn wir Sie vor Vertragsschluss ausdrücklich auf die AGB
                hinweisen und Sie die Möglichkeit hatten, von deren Inhalt
                Kenntnis zu nehmen.
              </li>
              <li>
                Die AGB gelten in der zum Zeitpunkt der Bestellung gültigen
                Fassung. Sie finden diese AGB online unter:
                http://www.mynorm.de/agb
              </li>
              <li>
                Abweichende AGB gelten nur, wenn wir diesen schriftlich
                zugestimmt haben.
              </li>
              <li>
                Wir müssen dir nach § 15 Versicherungs-Vermittlungs-Verordnung
                Informationen beim ersten Kontakt geben. Diese Erst-Information
                kannst du unter https://www.mynorm.de/erstinformation abrufen
                und herunterladen.
              </li>
              <li>
                Wir sind bei der Vermittlung eines Darlehens außerdem
                verpflichtet Sie nach Maßgabe des Artikels 247 § 13 Abs. 2 und §
                13 b Abs. 1 EGBGB zu informieren. Diese Unterlagen erhalten Sie
                von uns, bevor Sie uns mit der Vermittlung beauftragen und bevor
                wir mit der Vermittlung Ihres Darlehens beginnen.
              </li>
            </ol>
          </div>
        </div>
        <div>
          <h2>§ 2 Leistungsgegenstand: Wie können Sie unser Portal nutzen?</h2>
          <ol>
            <li>
              Du kannst auf unserer Internet-Plattform, bzw. App bestehende
              Versicherungen einfach und digital verwalten. Du kannst deine
              Verträge auch optimieren oder neue Versicherungsverträge
              abschließen. Du kannst unsere Internet-Plattform bzw. App
              kostenlos nutzen und die Nutzung jederzeit beenden.
            </li>
            <li>
              Wenn du auf unserer Internet-Plattform bzw. App unsere Leistungen
              nutzt, ändert sich an deinen Verträgen nichts. Wir übernehmen
              nicht automatisch deine Versicherungsverträge oder verändern
              diese.
            </li>
            <li>
              Wir werden nur als Makler für dich tätig, wenn du uns ausdrücklich
              beauftragst. Soweit du uns entsprechend beauftragt hast, können
              wir wie folgt tätig werden:
              <ul>
                <li>
                  Bestehende Verträge in unsere Internet-Plattform bzw. App
                  aufnehmen und dir anzeigen,
                </li>
                <li>neue Verträge abschließen,</li>
                <li>bestehende Verträge ändern,</li>
                <li>Verträge kündigen.</li>
              </ul>
            </li>
            <li>
              Wir sind darum bemüht, dir unsere Dienste jederzeit bestmöglich
              über unsere Internet-Plattform zugänglich zu machen. Wir schulden
              oder garantieren dir aber nicht, dass du unsere Internet-Plattform
              bzw. App erreichen kannst. Wir haften nicht - außer im Fall eines
              vorsätzlichen oder grob fahrlässigen Handelns - für Ausfälle aus:
              <ul>
                <li>
                  technischen Gründen, z. B. gestörte Hardware, fehlerhafte
                  Software, Wartungs-Arbeiten.
                </li>
                <li>
                  Gründen, die wir nicht beeinflussen können, z. B. höhere
                  Gewalt, Verschulden durch Dritte.
                </li>
              </ul>
              <div>
                Die vorstehende Haftungsbeschränkung gilt nicht für Schäden an
                Gesundheit und Leben, oder für typischerweise vorhersehbare
                Schäden, die aufgrund einer Verletzung einer wesentlichen
                Vertragspflicht entstehen (sog. Kardinalpflichten).
              </div>
            </li>
          </ol>
        </div>
        <div>
          <h2>
            § 3 Nutzungsbedingungen: Welche Regeln musst du beachten, wenn du
            unser Portal nutzt?
          </h2>
          <ol>
            <li>
              Um unsere Dienste vollständig zu nutzen, musst du dich auf unserer
              Internet-Plattform bzw. App registrieren. Einzelne Funktionen
              stehen auch ohne Registrierung zur Verfügung.
            </li>
            <li>
              Zur Nutzung und Registrierung sind nur folgende unbeschränkt
              geschäftsfähige Personen berechtigt:
              <ul>
                <li>volljährige natürliche Personen,</li>
                <li>Personen-Gesellschaften,</li>
                <li>juristische Personen.</li>
              </ul>
            </li>
            <li>
              Personen-Gesellschaften und juristische Personen müssen sich durch
              eine vertretungsberechtigte Person unter Angabe des Vor- und
              Zunamens registrieren. 3.3 Wenn du unsere Dienste nutzt, musst du
              alle Fragen vollständig und wahr beantworten. Daten musst du
              ebenfalls vollständig und richtig angeben. Wenn wir Zweifel daran
              haben, dass die Angaben nicht richtig und vollständig sind, können
              wir die Zusammenarbeit jederzeit beenden und deine Registrierung
              rückgängig machen. Im Hinblick auf die Verarbeitung von Daten
              gelten unsere Datenschutzerklärungen. Diese kannst du unter
              www.mynorm.de/datenschutz einsehen und herunterladen. Du hast
              keinen Anspruch auf ein Nutzerkonto; wir können deinen Zugang
              übergangsweise sperren, sofern ein berechtigtes Interesse daran
              besteht. Dabei beachten wir deine und unsere Interessen in
              gleichen Maßen. Wenn wir dein Nutzerkonto sperren, informieren wir
              dich in Textform darüber. Textform bedeutet: E-Mail, SMS oder Fax,
              nicht unbedingt Brief.
            </li>
            <li>
              Durch die Nutzung unserer Dienste kommt zwischen uns ein
              Nutzungsvertrag zustande. Die Einzelheiten zur Nutzung sind in
              diesen AGB geregelt.
            </li>
            <li>
              Wenn unsere Internet-Plattform bzw. App ein entsprechendes Angebot
              vorsieht, ermöglicht sie dir Folgendes: Du kannst kostenlos und
              unverbindlich nach einer passenden Versicherung suchen. Außerdem
              kannst du bestehende Verträge übersichtlich verwalten und
              optimieren. Dabei werden alle objektiven und anerkannten
              Bewertungsmaßstäbe für Versicherungsprodukte berücksichtigt.
            </li>
            <li>
              Du kannst außerdem deine bestehenden Versicherungen auf Nutzen und
              Vollständigkeit prüfen. Dieses Prüfungsverfahren erfolgt anhand
              objektiver und allgemein anerkannter Maßstäbe. Bitte beachte
              Folgendes: Im Lauf der Zeit kann sich dein Bedarf verändern. Eine
              bestehende Versicherung kann zu einem späteren Zeitpunkt deines
              Lebens nicht mehr deinen Bedürfnissen entsprechen. Sie kann auch
              von den von der Software zugrunde gelegten Maßstäben abweichen.
              Wir übernehmen keine Gewähr dafür, dass bestehende Verträge, die
              wir übernehmen, bei Abschluss:
              <ul>
                <li>die geeignetste,</li>
                <li>die beste,</li>
                <li>die sinnvollste Wahl für dich dargestellt haben.</li>
              </ul>
            </li>
            <li>
              Die Nutzung unserer Internet-Plattform bzw. App kann eine
              persönliche Beratung nicht unbedingt ersetzen. Daher bieten wir
              dir auf Wunsch an, dich individuell zu beraten. Der notwendige
              Kontakt hierfür kann über verschiedene Kanäle erfolgen. Wir
              beraten dich entweder persönlich oder beauftragen damit einen
              kompetenten Dritten.
            </li>
            <li>
              Wenn wir deine Verträge verwalten sollen, musst du uns eine
              Vollmacht erteilen. Eine Vollmacht berechtigt uns unter anderem:
              <ul>
                <li>
                  Deine bestehenden Verträge bei den Versicherern einzusehen und{" "}
                </li>
                <li>
                  die Daten Ihrer bestehenden Verträge in unser System zu
                  übertragen.
                </li>
              </ul>
              Wir übernehmen keine Gewähr dafür, dass die Daten, die wir von den
              Versicherern erhalten, vollständig oder richtig sind. Soweit du
              uns mit der Vermittlung eines Darlehens beauftragt hast,
              berechtigt uns die Vollmacht Erklärungen gegenüber den Banken und
              Finanzdienstleistern abzugeben und entgegenzunehmen.
            </li>
            <li>
              Du verpflichtest dich, die angebotenen Dienste nur entsprechend
              deren Zweck zu verwenden. Du darfst die Software nicht in deine
              eigenen Computer-Programme einbringen oder mit diesen
              <ul>
                <li> zusammensetzen,</li>
                <li> verkaufen,</li>
                <li> vermieten,</li>
                <li> verleihen,</li>
                <li> vertreiben,</li>
                <li> unterlizenzieren,</li>
                <li> in weitere Programme integrieren,</li>
                <li> mit weiteren Programmen verknüpfen,</li>
                <li> für illegale Zwecke verwenden.</li>
              </ul>
            </li>
            <li>
              Wir sind jederzeit berechtigt:
              <ul>
                <li>
                  die Bereitstellung der Internet-Plattform bzw. App
                  einzustellen oder{" "}
                </li>
                <li> dein Recht zur Nutzung dieser zu beenden.</li>
              </ul>
              Dies gilt insbesondere dann, wenn du gegen die Nutzungsbedingungen
              verstößt.
            </li>
            <li>
              Du kannst jederzeit verlangen, dass wir dein Nutzerkonto löschen.
              Im Hinblick auf die Speicherung von Daten über die
              Geschäftsbeziehung hinaus gelten unsere Datenschutzerklärungen.
              Diese kannst du unter www.mynorm.de/de/datenschutz einsehen und
              herunterladen.
            </li>
            <li>
              Du bist für das sichere Aufbewahren deiner Zugangsdaten zuständig.
              Du musst die Zugangsdaten vertraulich behandeln. Das bedeutet,
              dass du die Daten geheim halten musst. Dritte dürfen diese nicht
              einsehen. Besteht ein Verdacht auf Missbrauch der Daten, musst du
              uns dies sofort mitteilen. Bitte schicke uns hierfür eine formlose
              Email an service@mynorm.de
            </li>
            <li>
              Du musst entsprechende Vorkehrungen treffen, deine auf unserer
              Internet-Plattform bzw. App verwendeten Daten zu sichern. Gemeint
              sind alle Daten, die von dir auf der Internet-Plattform:
              <ul>
                <li> eingegeben,</li>
                <li> hochgeladen,</li>
                <li> gespeichert,</li>
                <li> anderweitig an uns übersandt oder</li>
                <li>von uns an dich versandt wurden.</li>
              </ul>
              Diese Daten und Inhalte musst du regelmäßig und gefahrentsprechend
              sichern und eigene Sicherungskopien erstellen. Dies dient dazu,
              dass du bei Verlust von Daten und Informationen diese selbst
              wiederherstellen kannst.
            </li>
            <li>
              Wir dürfen telefonische Gespräche mit dir aufnehmen und speichern,
              wenn du dazu ausdrücklich einwilligst. Dies erfolgt aufgrund
              gesetzlicher Pflichten und zum Zweck des gesetzlich erforderlichen
              Nachweises. Auf Nachfrage am Beginn des Telefonats kannst du den
              Mitschnitt des Gesprächs explizit einwilligen. Unsere Mitarbeiter
              können die Gespräche nachträglich anhören. Wir sind berechtigt,
              Niederschriften der Aufzeichnungen anzufertigen. Sollte es zum
              Rechtsstreit kommen, können wir die Gespräche als Beweis
              verwenden, wenn du den Mitschnitt nicht ausdrücklich abgelehnt
              hast.
            </li>
          </ol>
        </div>
        <div>
          <h2>§ 4 Vollmacht: Was gilt für die Vollmacht?</h2>
          <ol>
            <li>
              Du erteilest uns deine Vollmacht auf zwei Wegen:
              <ul>
                <li>
                  Digitale Unterschrift: Wir erfassen deine Unterschrift digital
                  und tragen sie in die Vollmacht ein. Wir können auch andere
                  Verfahren verwenden, um deine Unterschrift zu digitalisieren.
                </li>
                <li>
                  Eigenhändige Unterschrift: Du unterschreibst die Vollmacht
                  eigenhändig und sendest uns die unterschriebene Vollmacht per
                  Post.
                </li>
              </ul>
            </li>
            <li>
              Wir speichern deine Unterschrift in unserem System. Wir nutzen
              deine Unterschrift auch für andere Dokumente, nachdem du der
              Nutzung zuvor ausdrücklich zugestimmt hast. Wir leiten die
              unterschriebene Vollmacht - falls erforderlich - an Versicherer
              oder andere berechtigte Dritte weiter.
            </li>
            <li>
              Wir haben das Recht, eine Vollmacht jederzeit und ohne Angabe von
              Gründen abzulehnen.
            </li>
            <li>
              Der Umfang der Vollmacht richtet sich nach deinem jeweiligen
              Auftrag. Sie entspricht in Ausmaß und Reichweite einer für diese
              Branche üblichen Vollmacht. Die Vollmacht erstreckt sich zum
              Beispiel auf:
              <ul>
                <li>Beschaffung deiner Daten,</li>
                <li>Betreuung und Verwaltung deiner Verträge,</li>
                <li>Vermittlung von neuen Verträgen,</li>
                <li>Unterstützung beim Regulieren von Versicherungsschäden.</li>
              </ul>
            </li>
            <li>
              Du kannst die Vollmacht jederzeit widerrufen. Auch wir haben
              dieses Recht. Allerdings dürfen wir die Vollmacht in für dich
              ungünstigen Zeiten nur widerrufen, wenn ein wichtiger Grund dafür
              vorliegt.
            </li>
          </ol>
        </div>
        <div>
          <h2>
            § 5 Vermittlung von Versicherungsverträgen: Wie vermitteln wir dir
            eine passende Versicherung?
          </h2>
          <ol>
            <li>
              Du kannst uns beauftragen, Versicherungsverträge für dich
              abzuschließen. Dann sind wir verpflichtet, unserem Vorschlag eine
              hinreichende Anzahl von auf dem Markt angebotenen Verträgen und
              Versicherern zugrunde zu legen. Darauf aufbauend geben wir dir
              einen fachlichen Rat, welcher Vertrag geeignet erscheint, deine
              Bedürfnisse zu erfüllen. Dies gilt nicht, wenn wir im Einzelfall
              vor Abgabe der Vertragserklärung auf eine begrenzte Auswahl von
              Unternehmen und Produkten hinweisen.
            </li>
            <li>
              Wenn wir dich auf eine begrenzte Auswahl hinweisen, teilen wir dir
              mit, auf welcher Markt- und Informationsgrundlage unser Vorschlag
              erfolgt. Außerdem teilen wir dir die Namen der zugrunde gelegten
              Unternehmen mit. Auf diese Pflicht kannst du durch eine gesonderte
              schriftliche Erklärung verzichten.
            </li>
            <li>
              Wir vermitteln dir nur Verträge, die deinen individuellen
              Bedürfnissen entsprechen. Unsere Vorschläge basieren unter anderem
              auf diesen Kriterien:
              <ul>
                <li>dem Verhältnis von Preis und Leistung,</li>
                <li>der Zuverlässigkeit beim Bearbeiten von Schäden und</li>
                <li>unserer Expertise.</li>
              </ul>
            </li>
            <li>
              Wir können Lebensversicherungen und Dienstleistungen mit
              Anlagezweck nur dann vermitteln, wenn du dich identifizierst. Dazu
              musst du deine Identität durch einen gültigen amtlichen Ausweis
              nachweisen. Der Ausweis muss ein Lichtbild enthalten und die
              Pass-und Ausweispflicht erfüllen.
            </li>
            <li>
              Wir vermitteln dir nur Verträge von Unternehmen:
              <ul>
                <li>
                  die unter Aufsicht der Bundesanstalt für
                  Finanzdienstleistungsaufsicht (BaFin) stehen und
                </li>
                <li>deren Regelwerk in deutscher Sprache verfasst ist.</li>
              </ul>
              Auch ausländische Versicherer sind in Deutschland tätig. Die
              Produkte dieser Unternehmen vermitteln wir nur, wenn deren Angebot
              und Zuverlässigkeit mit einem deutschen Unternehmen vergleichbar
              sind. Eine Pflicht hierzu besteht nicht. Einige Unternehmen,
              sogenannte Direktversicherer, vertreiben ihre Produkte nur selbst.
              Diese lassen den Abschluss von Verträgen durch Dritte nicht zu.
              Wir weisen darauf hin, dass wir diese Verträge nicht vermitteln
              können.
            </li>
            <li>
              Du musst alle Angaben, die wir für eine vertragsgemäße Beratung
              und Verwaltung benötigen, wahrheitsgemäß und innerhalb der
              geforderten Frist übermitteln.
            </li>
            <li>
              Wenn du mitwirken musst, zum Beispiel einen Antrag unterschreiben,
              musst du:
              <ul>
                <li>alle geforderten Erklärungen,</li>
                <li>
                  auf unsere Aufforderung oder auf Aufforderung des Versicherers
                </li>
                <li>ohne Verzug abgeben.</li>
              </ul>
            </li>
          </ol>
        </div>
        <div>
          <h2>§ 6 Darlehensvermittlung. Wie vermitteln wir dir Darlehen?</h2>
          <ol>
            <li>
              Wir vermitteln ausschließlich an Verbraucher Darlehen zur
              Finanzierung einer eigenen Immobilie nach §34 i GewO. Der
              Darlehensvertrag kommt nicht mit uns, sondern einer Bank zustande.
            </li>
            <li>
              Du kannst uns uneingeschränkt damit beauftragen:
              <ul>
                <li>
                  einen Darlehensvertrag oder eine entgeltliche
                  Finanzierungshilfe zu vermitteln;
                </li>
                <li>
                  die Gelegenheit zum Abschluss eines Darlehensvertrags oder
                  einer entgeltlichen Finanzierungshilfe derart nachzuweisen,
                  dass du in die Lage versetzt wirst, den Vertrag abzuschließen;
                </li>
                <li>
                  dir auf anderer Weise beim Abschluss eines Vertrags
                  Darlehensvertrags behilflich zu sein.
                </li>
              </ul>
            </li>
            <li>
              Wir sind hierbei unabhängig und nicht an einen bestimmten
              Darlehensgeber gebunden und berücksichtigen bei der
              Produktberatung und Produktauswahl eine ausreichende Anzahl von
              inländischen Darlehensgebern, mindestens jedoch zwei.
            </li>
            <li>
              Wir schulden allerdings nicht den erfolgreichen Abschluss eines
              Darlehensvertrages für den uns genannten Zweck. Soweit du es
              wünschst, betreuen wir dich aber auch bis zum rechtskräftigen
              Abschluss des Darlehensvertrages und Auszahlung des Darlehens auf
              dein Konto. In diesem Fall vertreten wir dich auch gegenüber dem
              darlehensgebenden Kreditinstitut.
            </li>
            <li>
              Wenn du uns beauftragst gilt dieser Auftrag aber uneingeschränkt
              zur alleinigen Beschaffung des benötigten Darlehens. Du
              beauftragst während der Dauer eines Darlehensvermittlungsauftrages
              keine weiteren Vermittler mit dem Nachweis und/oder der
              Vermittlung eines Darlehensvertrages für den in diesem Vertrag
              genannten Zweck.
            </li>
            <li>
              Um für dich ein passendes Darlehen zu finden, sind wir auf deine
              Mithilfe angewiesen. Du bist daher insbesondere zur zügigen und
              vollständigen Erteilung wahrheitsgemäßer Angaben und
              Zurverfügungstellung der zur Durchführung der Darlehensvermittlung
              notwendigen Informationen verpflichtet. Diesen uns so
              geschilderten Sachverhalt legen wir als Beratungsgrundlage
              zugrunde. Dieser Sachverhalt wird als vollständig, wahrheitsgemäß
              und abschließend bewertet.
            </li>
            <li>
              Du bist dabei insbesondere verpflichtet, uns nach Abschluss eines
              Darlehensvermittlungsvertrages alle Nachweise zur Verfügung zu
              stellen, die wir und der Darlehensgeber benötigen, um die in jedem
              Fall durchzuführende Kreditwürdigkeitsprüfung durchführen zu
              können. Solche Nachweise sind insbesondere alle bonitätsrelevanten
              Daten wie erzieltes Einkommen, einschließlich deren Entwicklung,
              Zahlungsverpflichtungen gegenüber Dritten, Personenstandsurkunden,
              Grundbuchauszüge sowie weitere geeignete Nachweise sein.
            </li>
            <li>
              Bitte informiere uns während der Laufzeit des Vertrages über alle
              Änderungen, die für die Vermittlung des Darlehens wesentlich sein
              können, oder alle Änderungen innerhalb der nach Ziff. 6.6. dieser
              AGB zur Kreditwürdigkeitsprüfung verlangten Nachweise.
            </li>
          </ol>
        </div>
        <div>
          <h2>§ 7 Kommunikation: Wie verständigen wir uns mit dir?</h2>
          <ol>
            <li>
              Wir verständigen uns mit dir elektronisch. Ausgenommen davon sind
              lediglich Erklärungen, die der gesetzlichen Schriftform
              unterliegen. Davon dürfen wir nicht abweichen.
            </li>
          </ol>
        </div>
        <div>
          <h2>
            § 8 Beraterhaftung: In welchen Fällen und in welcher Höhe haften wir
            bei der Versicherungsvermittlung?
          </h2>
          <ol>
            <li>
              Unsere Haftung ist mit Ausnahme der in den §§ 60, 61 VVG
              geregelten Beratungs- und Dokumentationspflichten wie folgt
              begrenzt: Wir haften bis zu einem Betrag von 1.230.000 Euro je
              Schadenfall, wenn wir vertragliche Pflichten infolge leichter
              Fahrlässigkeit verletzen. In diesen Fällen haften wir außerdem bis
              zu höchstens 1.850.000 Euro für alle Schadenfälle eines
              Versicherungsjahres. Wir beschränken unsere Haftung, weil in der
              genannten Höhe eine Vermögensschaden-Haftpflichtversicherung
              besteht.
            </li>
            <li>
              Wir haften aber in folgendem Fall: wenn wir wesentliche
              Vertragspflichten infolge leichter Fahrlässigkeit verletzen.
              Wesentliche sind Vertragspflichten,:
              <ul>
                <li>
                  deren Erfüllen die Durchführung des Vertrages erst ermöglichen
                  und
                </li>
                <li>
                  auf deren Erfüllen du dich deshalb regelmäßig verlassen
                  darfst.
                </li>
              </ul>
              Unsere Haftung ist aber wie folgt begrenzt: Wir haften höchstens
              bis zur Höhe des im Zeitpunkt des Vertragsschlusses typischen,
              vorhersehbaren Schadens. Wir haften nicht für ausbleibenden
              wirtschaftlichen Erfolg, entgangenen Gewinn und daraus entstandene
              mittelbare Schäden.
            </li>
            <li>
              In unbegrenzter Höhe haften wir nur für Schäden:
              <ul>
                <li>
                  die sich aus den in den §§ 60, 61 VVG geregelten Beratungs-
                  und Dokumentationspflichten ergeben,
                </li>
                <li>
                  die wir vorsätzlich und grob fahrlässig herbeigeführt haben
                  oder
                </li>
                <li>
                  aus der Verletzung des Lebens, des Körpers und der Gesundheit.
                </li>
              </ul>
            </li>
            <li>
              Für Mängel oder Fehler an der Internet-Plattform bzw. App haften
              wir nur, wenn wir diesen Mangel oder Fehler arglistig, vorsätzlich
              oder grob fahrlässig verschwiegen haben.
            </li>
            <li>
              Wir haften nicht für Störungen, die wir nicht beeinflussen können.
              Dazu gehören zum Beispiel:
              <ul>
                <li>überlastete Leitungen oder</li>
                <li> Ausfall von Telekommunikations-Verbindungen.</li>
              </ul>
            </li>
            <li>
              Die hier dargestellten Begrenzungen unserer Haftung gelten ebenso
              für:
              <ul>
                <li> unsere Mitarbeiter und Erfüllungsgehilfen und</li>
                <li>
                  sonstige Dritte, die uns helfen, unsere Pflichten zu erfüllen.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div>
          <h2>
            § 9 Beraterhaftung: In welchen Fällen und in welcher Höhe haften wir
            bei der Darlehensvermittlung?
          </h2>
          <ol>
            <li>
              Bei der Vermittlung von Darlehensverträgen richtet sich die
              Haftung nach den gesetzlichen Vorschriften. Unsere und die Haftung
              unserer Erfüllungsgehilfen auf Schadensersatz für die Verletzung
              von Beratungspflichten ist auf den vertragstypischen,
              vorhersehbaren Schaden begrenzt.
            </li>
            <li>
              Unsere und die Haftung unserer Erfüllungsgehilfen für leichte
              Fahrlässigkeit für eine Verletzung unserer Pflichten ist
              summenmäßig begrenzt auf einen Betrag von 460.000 Euro für jeden
              Schadenfall. Wir halten bis mindestens zu dieser Summe eine
              Vermögenshaftpflichtversicherung vor.
            </li>
            <li>
              Die zuvor genannten Haftungsbeschränkungen gelten nicht für die
              Haftung wegen vorsätzlichen Verhaltens oder grober Fahrlässigkeit,
              für garantierte Beschaffenheitsmerkmale, wegen Verletzung des
              Lebens, des Körpers oder der Gesundheit oder nach dem
              Produkthaftungsgesetz. Die zuvor genannten Haftungsbeschränkungen
              gelten auch nicht für die Verletzung wesentlicher
              Vertragspflichten (Kardinalpflichten). Wesentliche
              Vertragspflichten sind solche sich aus der Natur des
              Vermittlungsgeschäftes ergebenden Pflichten, deren Einschränkung
              die Erreichung des Vertragszwecks gefährden würde oder deren
              Erfüllung die ordnungsgemäße Durchführung des Vertragszwecks
              unmöglich machen.
            </li>
            <li>
              Soweit du uns die Informationen und Unterlagen nicht rechtzeitig
              mitteilst, die für eine sachgerechte Wahrnehmung deiner Interessen
              erforderlich sind, bist du nicht berechtigt, insoweit Schäden
              geltend zu machen, die dadurch entstehen, dass ein
              Darlehensvertrag nicht oder nicht mit den erforderlichen
              Konditionen abgeschlossen wird. Gleiches gilt, wenn du relevante
              Veränderungen oder notwendige Angaben nicht oder nicht rechtzeitig
              mitteilst.
            </li>
          </ol>
        </div>
        <div>
          <h2>§ 10 Schlussbestimmungen</h2>
          <ol>
            <li>
              Wenn eine Regelung dieser AGB unwirksam sein sollte, bleiben alle
              weiteren Abschnitte wirksam. Auch bereits bestehende Verträge
              bleiben unberührt. Die unwirksame Regelung entfällt ersatzlos, an
              ihre Stelle tritt die gesetzliche Regelung hierzu. Stellt sich
              heraus, dass in den AGB Regelungslücken bestehen, bleiben alle
              weiteren Abschnitte wirksam. Auch die bestehenden Verträge bleiben
              unberührt.
            </li>
            <li>
              Wenn wir diese AGB ändern, geschieht dies - sofern nicht lediglich
              rechtlich vorteilhaft für dich - wie folgt: Bevor die angepassten
              AGB gültig werden, senden wir dir die geänderten Abschnitte oder
              AGB in Textform zu. Wir weisen dich gesondert auf die neuen
              Passagen und das Datum des geplanten Inkrafttretens hin. Außerdem
              räumen wir dir eine sechswöchige Frist zum Widerspruch ein. Diese
              Frist beginnt, wenn du unsere Nachricht in Textform erhalten hast.
              Du hast dann sechs Wochen Zeit Widerspruch einzulegen. Wenn wir
              innerhalb dieser Frist keinen Widerspruch von dir erhalten, gelten
              die geänderten AGB als vereinbart. Wir weisen dich bei Fristbeginn
              gesondert auf die Folgen deines Schweigens hin.
            </li>
            <li>
              Es gilt das Recht der Bundesrepublik Deutschland. Das UN-Kaufrecht
              sowie alle internationalen Kollisionsnormen sind ausgeschlossen.
            </li>
          </ol>
        </div>
      </div>
    </LegalDoc>
  );
};
