import styles from "pages/insurances/Insurances.module.scss";

import React from "react";
import Page from "components/page/Page";
import UnstyledLink from "components/unstyle-link/UnstyledLink";
import { productTree } from "../../content/productTree";
import { ProductTreeType } from "../../content/productTreeDefinition";
import { STRINGS } from "../../language/de_DE/strings";
import PageSection from "../../components/page-section/PageSection";

const Insurances = () => {
  return (
    <Page>
      <PageSection>
        <h1>{STRINGS.insuranceOverview.title}</h1>

        <div className={styles.content}>
          {Object.keys(productTree.valueOf()).map((keyString: string) => (
            <div className={styles.productCategory} key={keyString}>
              {productTree[keyString as ProductTreeType].title}
              <div>
                <hr />
              </div>
              {productTree[keyString as ProductTreeType].options.map(
                (option) => (
                  <div
                    className={styles.productSubCategory}
                    key={option.title + option.icon}
                  >
                    {option.title}
                    {option.insurances.map((ins) => (
                      <div key={ins.title} className={styles.productLinks}>
                        <UnstyledLink to={ins.link}>{ins.title}</UnstyledLink>
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          ))}
        </div>
      </PageSection>
    </Page>
  );
};

export default Insurances;
