import React from "react";
import { useUpdateSelf } from "api/useSelf";
import EditProfileDialog from "components/user-portal/user-profile/dialogs/EditProfileDialog";
import EditIncomeForm from "components/user-portal/user-profile/dialogs/income/EditIncomeForm";
import { KundeResponse } from "types/backend/KundeResponse";

interface EditIncomeDialogProps {
  kunde: KundeResponse;
  open: boolean;
  onClose: () => void;
}

const EditIncomeDialog: React.FC<EditIncomeDialogProps> = ({
  kunde,
  open,
  onClose,
}) => {
  const formId = "incomeForm";
  const mutation = useUpdateSelf();

  return (
    <EditProfileDialog
      onClose={onClose}
      open={open}
      formId={formId}
      mutation={mutation}
    >
      <EditIncomeForm formId={formId} kunde={kunde} mutation={mutation} />
    </EditProfileDialog>
  );
};

export default EditIncomeDialog;
