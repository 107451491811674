import React, { FunctionComponent } from "react";
import styles from "./Faq.module.scss";
import { STRINGS } from "../../../../../language/de_DE/strings";
import TextAccordion from "../../../../accordion/TextAccordion";
import PageSection from "../../../../page-section/PageSection";

const sectionStrings = STRINGS.startpage.faq;

type FaqEntry = {
  question: string;
  answer: string;
};

export const Faq: FunctionComponent = () => {
  return (
    <PageSection contentClassName={styles.section}>
      <h1 className={styles.title}>
        <span className={styles.highlighted}>
          {sectionStrings.titleFirstLine}
        </span>
        {sectionStrings.titleSecondLine}
      </h1>
      {sectionStrings.entries.map((entry: FaqEntry) => (
        <TextAccordion
          key={entry.question}
          {...{ title: entry.question, content: entry.answer }}
        />
      ))}
    </PageSection>
  );
};
