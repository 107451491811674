import * as yup from "yup";
import { STRINGS } from "language/de_DE/strings";
import { AdditionalRegistrationDataFormValues } from "types/registration/AdditionalRegistrationDataFormValues";

export const registrationFormSchema = yup.object().shape({
  email: yup
    .string()
    .email(
      STRINGS.formInputs.validation.email(
        STRINGS.account.registration.inputEmailLabel
      )
    )
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.registration.inputEmailLabel
      )
    ),
  password: yup
    .string()
    .min(8, STRINGS.account.registration.inputPasswordValidation)
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
      STRINGS.account.registration.inputPasswordValidation
    )
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.registration.inputPasswordLabel
      )
    ),
  gender: yup
    .string()
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.registration.inputGenderLabel
      )
    ),
  title: yup.string(),
  firstName: yup
    .string()
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.registration.inputFirstNameLabel
      )
    ),
  lastName: yup
    .string()
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.registration.inputLastNameLabel
      )
    ),
  phoneNumber: yup
    .string()
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.registration.inputMobileNumberLabel
      )
    ),
});

export const additionalRegistrationDataFormSchema = yup.object().shape({
  street: yup
    .string()
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.registration.additional.inputStreetLabel
      )
    ),
  postalCode: yup
    .string()
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.registration.additional.inputPostalCodeLabel
      )
    ),
  city: yup
    .string()
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.registration.additional.inputCityLabel
      )
    ),
  birthday: yup
    .string()
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.registration.additional.inputBirthdayLabel
      )
    ),
  birthPlace: yup
    .string()
    .required(
      STRINGS.formInputs.validation.required(
        STRINGS.account.registration.additional.inputBirthPlaceLabel
      )
    ),
});

export const isAdditionalRegistrationDataValid = (
  values: AdditionalRegistrationDataFormValues
): boolean => {
  return additionalRegistrationDataFormSchema.isValidSync(values);
};
