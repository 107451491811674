import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import Page from "components/page/Page";
import ForgotPasswordStepWizard from "components/step-wizard/forgot-password/ForgotPasswordWizard";
import useAsyncEffect from "hooks/useAsyncEffect";
import useUserLogin from "hooks/useUserLogin";
import redirectAfterLogin from "utils/redirectAfterLogin";
import PageSection from "../../components/page-section/PageSection";

const ForgotPasswordPage = (): ReactElement => {
  const { isLoggedIn } = useUserLogin();
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    if (await isLoggedIn()) {
      redirectAfterLogin(navigate);
    }
  });

  return (
    <Page>
      <PageSection>
        <ForgotPasswordStepWizard />
      </PageSection>
    </Page>
  );
};

export default ForgotPasswordPage;
