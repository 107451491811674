import { DemandCalculatorInputFormType } from "./input-form/DemandCalculatorInputFormType";
import { format, subYears } from "date-fns";
import { inputDateFormat } from "../../utils/formInputUtils";
import useUserState from "../../hooks/useUserState";
import { KundeResponse } from "../../types/backend/KundeResponse";
import { useEffect, useState } from "react";

const now = new Date();
export const earliestBirthday = subYears(new Date(), 16);

const defaultValues: DemandCalculatorInputFormType = {
  geburtstag: format(earliestBirthday, inputDateFormat),
  familienstand: "",
  anzahlKinder: "0",
  wohnsituation: "",
  wohnflaeche: "0",
  fremdgenutzteImmobilien: false,
  immobilieBaujahr: "",
  neubauSummeImmobilie: "0",
  berufsstatus: "",
  besondereTaetigkeit: "",
  nettogehalt: "0",
  einnahmenKapital: "0",
  sonstigeEinnahmen: "0",
  gesamtAusgaben: "0",
  krankenversicherung: "",
  privateKrankenversicherungBeitrag: "0",
  rentenVersicherungBeitrag: "0",
  auslandsReisen: false,
  hundOderPferd: false,
  kraftfahrzeug: false,
  luftfahrzeug: false,
  wasserfahrzeug: false,
  jagen: false,
  oeltank: false,
  photovoltaikAnlage: false,
  bauenOderSanieren: false,
  verkehrswertImmobilie: "0",
  verbindlichkeitenImmobilie: "0",
  anlageVermoegen: "0",
  sonstigeVermoegenswerte: "0",
  sonstigeDarlehen: "0",
  konsumentenKredite: "0",
  genommenerDispositionskredit: "0",
  investitionsZiel: false,
  investitionsZielWunschbetrag: "0",
  investitionsZielZeitpunkt: format(now, inputDateFormat),
  eigeneImmobilieGeplant: false,
  eigeneImmobilieWert: "0",
  eigeneImmobilieErwerbZeitpunkt: format(now, inputDateFormat),
  ersatzInvestition: false,
  ersatzInvestitionBetrag: "0",
  ersatzInvestitionZeitpunkt: format(now, inputDateFormat),
};

const getValue = <T>(defaultValue: T, kundeValue?: T): T => {
  if (kundeValue === undefined || kundeValue === null) return defaultValue;
  return kundeValue;
};

const mergeDefaultValuesWithKunde = (kunde: KundeResponse) => {
  return {
    ...defaultValues,
    geburtstag: getValue(defaultValues.geburtstag, kunde.geb),
    familienstand: getValue(defaultValues.familienstand, kunde.familienstand),
    anzahlKinder: getValue(
      defaultValues.anzahlKinder,
      kunde.anzahlKinder?.toString()
    ),
    wohnsituation: getValue(defaultValues.wohnsituation, kunde.wohnsituation),
    wohnflaeche: getValue(
      defaultValues.wohnflaeche,
      kunde.wohnflaeche?.toString()
    ),
    fremdgenutzteImmobilien: getValue(
      defaultValues.fremdgenutzteImmobilien,
      kunde.fremdgenutzteImmobilien
    ),
    immobilieBaujahr: getValue(
      defaultValues.immobilieBaujahr,
      kunde.immobilieBaujahr?.toString()
    ),
    neubauSummeImmobilie: getValue(
      defaultValues.neubauSummeImmobilie,
      kunde.neubauSummeImmobilie?.toString()
    ),
    besondereTaetigkeit: getValue(
      defaultValues.besondereTaetigkeit,
      kunde.besondereTaetigkeit
    ),
    berufsstatus: getValue(defaultValues.berufsstatus, kunde.berufsstatus),
    nettogehalt: getValue(
      defaultValues.nettogehalt,
      kunde.nettoGehalt?.toString()
    ),
    einnahmenKapital: getValue(
      defaultValues.einnahmenKapital,
      kunde.einnahmenKapital?.toString()
    ),
    sonstigeEinnahmen: getValue(
      defaultValues.sonstigeEinnahmen,
      kunde.sonstigeEinnahmen?.toString()
    ),
    gesamtAusgaben: getValue(
      defaultValues.gesamtAusgaben,
      kunde.gesamtAusgaben?.toString()
    ),
    krankenversicherung: getValue(
      defaultValues.krankenversicherung,
      kunde.krankenversicherung
    ),

    privateKrankenversicherungBeitrag: getValue(
      defaultValues.privateKrankenversicherungBeitrag,
      kunde.privateKrankenversicherungBeitrag?.toString()
    ),

    rentenVersicherungBeitrag: getValue(
      defaultValues.rentenVersicherungBeitrag,
      kunde.rentenVersicherungBeitrag?.toString()
    ),
    auslandsReisen: getValue(
      defaultValues.auslandsReisen,
      kunde.auslandsReisen
    ),
    hundOderPferd: getValue(defaultValues.hundOderPferd, kunde.hundOderPferd),
    kraftfahrzeug: getValue(defaultValues.kraftfahrzeug, kunde.kraftfahrzeug),
    luftfahrzeug: getValue(defaultValues.luftfahrzeug, kunde.luftfahrzeug),
    wasserfahrzeug: getValue(
      defaultValues.wasserfahrzeug,
      kunde.wasserfahrzeug
    ),
    jagen: getValue(defaultValues.jagen, kunde.jagen),
    oeltank: getValue(defaultValues.oeltank, kunde.oeltank),
    photovoltaikAnlage: getValue(
      defaultValues.photovoltaikAnlage,
      kunde.photovoltaikAnlage
    ),
    bauenOderSanieren: getValue(
      defaultValues.bauenOderSanieren,
      kunde.bauenOderSanieren
    ),
    verkehrswertImmobilie: getValue(
      defaultValues.verkehrswertImmobilie,
      kunde.verkehrswertImmobilie?.toString()
    ),
    verbindlichkeitenImmobilie: getValue(
      defaultValues.verbindlichkeitenImmobilie,
      kunde.verbindlichkeitenImmobilie?.toString()
    ),
    anlageVermoegen: getValue(
      defaultValues.anlageVermoegen,
      kunde.anlageVermoegen?.toString()
    ),
    sonstigeVermoegenswerte: getValue(
      defaultValues.sonstigeVermoegenswerte,
      kunde.sonstigeVermoegenswerte?.toString()
    ),
    sonstigeDarlehen: getValue(
      defaultValues.sonstigeDarlehen,
      kunde.sonstigeDarlehen?.toString()
    ),
    konsumentenKredite: getValue(
      defaultValues.konsumentenKredite,
      kunde.konsumentenKredite?.toString()
    ),
    genommenerDispositionskredit: getValue(
      defaultValues.genommenerDispositionskredit,
      kunde.genommenerDispositionskredit?.toString()
    ),
    investitionsZiel: getValue(
      defaultValues.investitionsZiel,
      kunde.investitionsZiel
    ),
    investitionsZielWunschbetrag: getValue(
      defaultValues.investitionsZielWunschbetrag,
      kunde.investitionsZielWunschbetrag?.toString()
    ),
    investitionsZielZeitpunkt: getValue(
      defaultValues.investitionsZielZeitpunkt,
      kunde.investitionsZielZeitpunkt
    ),
    eigeneImmobilieGeplant: getValue(
      defaultValues.eigeneImmobilieGeplant,
      kunde.eigeneImmobilieGeplant
    ),
    eigeneImmobilieWert: getValue(
      defaultValues.eigeneImmobilieWert,
      kunde.eigeneImmobilieWert?.toString()
    ),
    eigeneImmobilieErwerbZeitpunkt: getValue(
      defaultValues.eigeneImmobilieErwerbZeitpunkt,
      kunde.eigeneImmobilieErwerbZeitpunkt
    ),
    ersatzInvestition: getValue(
      defaultValues.ersatzInvestition,
      kunde.ersatzInvestition
    ),
    ersatzInvestitionBetrag: getValue(
      defaultValues.ersatzInvestitionBetrag,
      kunde.ersatzInvestitionBetrag?.toString()
    ),
    ersatzInvestitionZeitpunkt: getValue(
      defaultValues.ersatzInvestitionZeitpunkt,
      kunde.ersatzInvestitionZeitpunkt
    ),
  };
};

export const useDemandCalculatorFormValues =
  (): DemandCalculatorInputFormType | null => {
    const {
      kunde,
      isLoadingKunde,
      isLoadingKundeSuccess,
      isLoadingKundeError,
      isLoggedIn,
    } = useUserState();
    const [values, setValues] = useState<DemandCalculatorInputFormType | null>(
      null
    );

    useEffect(() => {
      if (
        isLoggedIn === null ||
        (isLoggedIn && !isLoadingKundeError && !isLoadingKundeSuccess)
      ) {
        return;
      }
      if (kunde) {
        setValues(mergeDefaultValuesWithKunde(kunde));
        return;
      }
      setValues(defaultValues);
    }, [
      kunde,
      isLoadingKunde,
      isLoadingKundeSuccess,
      isLoadingKundeError,
      isLoggedIn,
    ]);
    return values;
  };
