export const insuranceFilterMock = [
  {
    id: 1,
    content: "Grobe Fahrlässigkeit",
  },
  {
    id: 2,
    content: "Wertsachen",
  },
  {
    id: 3,
    content: "Diebstahlschutz",
  },
];
