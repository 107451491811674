import styles from "components/user-portal/contract/ContractViews.module.scss";

import React, { FunctionComponent } from "react";
import ContractCard from "components/user-portal/contract/ContractCard";
import { ContractAccordion } from "components/user-portal/contract/ContractTable";
import { KundeResponse } from "types/backend/KundeResponse";
import { ProduktKategorie } from "types/backend/ProduktKategorie";
import { VertragKapitalanlage } from "types/backend/VertragKapitalanlage";
import { VertragSparanlage } from "types/backend/VertragSparanlage";
import { VertragVersicherung } from "types/backend/VertragVersicherung";
import { VertragVersicherungKfz } from "types/backend/VertragVersicherungKfz";

interface ContractViewProps {
  vertraege:
    | Array<
        | VertragVersicherung
        | VertragVersicherungKfz
        | VertragKapitalanlage
        | VertragSparanlage
      >
    | undefined;
  kunde: KundeResponse | undefined;
}

interface ContractListViewProps extends ContractViewProps {
  produktKategories: ProduktKategorie[];
}

export const ContractListView: FunctionComponent<ContractListViewProps> = ({
  vertraege,
  kunde,
  produktKategories,
}) => {
  if (!kunde) return <></>;
  return (
    <div className={styles.listView}>
      {produktKategories.map((kategorie) => {
        return (
          <ContractAccordion
            key={kategorie}
            vertraege={vertraege || []}
            kunde={kunde}
            produktKategorie={kategorie}
          />
        );
      })}
    </div>
  );
};

export const ContractCardView: FunctionComponent<ContractViewProps> = ({
  vertraege,
  kunde,
}) => {
  if (!kunde) return <></>;
  return (
    <div className={styles.cardView}>
      {(vertraege || []).map((vertrag) => (
        <ContractCard key={vertrag.id} vertrag={vertrag} kunde={kunde} />
      ))}
    </div>
  );
};
