import axios from "axios";
import api from "api/index";
import { appConfig } from "config/appConfig";
import { isOk } from "utils/axiosHelpers";

export const readNachricht = async ({
  messageId,
  kid,
}: {
  messageId: number;
  kid: number;
}): Promise<void> => {
  const url = `${appConfig.apiUrl}/api/kunden/${kid}/nachrichten/${messageId}/read`;
  const header = await api.getHeaderWithAuthorization();
  await axios.put(
    url,
    {},
    {
      headers: header,
      validateStatus: isOk,
    }
  );
};
