import styles from "./Team.module.scss";
import { STRINGS } from "../../../../language/de_DE/strings";

import React, { FunctionComponent } from "react";
import { team } from "content/team";
import PageSection from "../../../page-section/PageSection";

const sectionStrings = STRINGS.aboutUs.team;

const isNotBlank = (value: string | null | undefined): value is string =>
  typeof value === "string" && value.trim().length > 0;

export const Team: FunctionComponent = () => (
  <PageSection background={"grey"}>
    <div className={styles.teamHeader}>
      <h1 className={styles.title}>
        {sectionStrings.titleFirstLine}
        <span className={styles.highlighted}>
          {sectionStrings.titleSecondLine}
        </span>
      </h1>
      <h3 className={styles.subTitle}>{sectionStrings.subTitle}</h3>
    </div>
    <div className={styles.teamList}>
      {team.map((person, name) => (
        <div className={styles.teamCard} key={`person_${name}`}>
          <div className={styles.imgContainer}>
            <img className={styles.img} src={person.image} alt={person.name} />
          </div>
          <div className={styles.person}>{person.name}</div>
          {isNotBlank(person.position) && (
            <span className={styles.position}>{person.position}</span>
          )}
          {isNotBlank(person.email) && (
            <span className={styles.email}>{person.email}</span>
          )}
          {isNotBlank(person.text) && (
            <p className={styles.text}>»{person.text}«</p>
          )}
        </div>
      ))}
    </div>
  </PageSection>
);
