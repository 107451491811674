import styles from ".//DemandCalculatorResultAccordion.module.scss";
import React, { ReactElement } from "react";
import AccordionBox from "../accordion/AccordionBox";
import { ProductPropositionEntry } from "./ProductPropositionEntry";
import { ProductProposition } from "./DemandCalculator";

interface DemandCalculatorResultAccordionProps {
  title: string;
  productPropositions: ProductProposition[];
  onHeaderClick: () => void;
  isOpen: boolean;
}

export const DemandCalculatorResultAccordion = ({
  title,
  isOpen,
  productPropositions,
  onHeaderClick,
}: DemandCalculatorResultAccordionProps): ReactElement | null => {
  if (!productPropositions || productPropositions.length === 0) return null;
  return (
    <AccordionBox title={title} isOpen={isOpen} onHeaderClick={onHeaderClick}>
      <div className={styles.productGrid}>
        {productPropositions.map((p) => (
          <ProductPropositionEntry key={p.rang} productProposition={p} />
        ))}
      </div>
    </AccordionBox>
  );
};
