import styles from "components/molecules/ViewStyleToggle.module.scss";

import React, { FunctionComponent, useState } from "react";
import { faBars, faThLarge } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { Box } from "components/atoms/Box";

export interface ViewStyleToggleProps {
  listActiveInitially?: boolean;
  onListView?: () => void;
  onCardView?: () => void;
}

export const ViewStyleToggle: FunctionComponent<ViewStyleToggleProps> = ({
  listActiveInitially = true,
  onListView,
  onCardView,
}) => {
  const [listViewActive, setListViewActive] = useState(listActiveInitially);

  const listViewClicked = () => {
    setListViewActive(true);
    if (onListView) onListView();
  };

  const cardViewClicked = () => {
    setListViewActive(false);
    if (onCardView) onCardView();
  };

  return (
    <Box flex className={styles.itemContainer}>
      <Box
        flex
        onClick={cardViewClicked}
        className={clsx(
          styles.toggleItem,
          styles.left,
          listViewActive && styles.inactive
        )}
      >
        <FontAwesomeIcon icon={faThLarge} />
      </Box>
      <Box
        flex
        onClick={listViewClicked}
        className={clsx(
          styles.toggleItem,
          styles.right,
          !listViewActive && styles.inactive
        )}
      >
        <FontAwesomeIcon icon={faBars} />
      </Box>
    </Box>
  );
};
